import React, { Component } from 'react';
import { Drawer, Form, Input, Row, Col, Select, Button, Collapse } from 'antd';
import researchClient from '../../../api/client';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CompanyReview from '../review/CompanyReview';
import _ from 'lodash';
import { getNextQuarter } from 'common/utils/DateUtils';
import TickerUtils from 'common/utils/TickerUtils';
import LinkPannel from './LinkPannel';

const { Panel } = Collapse;
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class CompanyScoreDetailDrawerOnlyForDetail extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      scoreList: [1, 2, 3, 4, 5],
      loadNotesStatus: 'READY',
      conclusionList: ['Long', 'Short', 'No action'],
      competitionList: ['Intensifying', 'Weakening', 'No change'],
      marketViewList: ['High', 'Med', 'Low'],
      deltaList: ['Accelerate', 'Decelerate', 'No change'],
      companyBusinessList: [],
      data: {},
      submitStatus: 'READY',
      defaultSingleQData: [
        {
          id: 1,
          type: 'Revenue (million)',
          chineseIndex: '单季营收 (百万)',
          index: 'Revenue (million)',
          firstRow: true
        },
        {
          id: 2,
          type: 'Revenue (million)',
          index: 'YoY',
          disabled: true,
          isPercent: true
        },
        {
          id: 3,
          type: 'Revenue (million)',
          index: 'QoQ',
          disabled: true,
          isPercent: true
        },
        {
          id: 4,
          type: 'GP Margin',
          index: 'GP Margin',
          firstRow: true,
          isPercent: true
        },
        {
          id: 5,
          type: 'GP Margin',
          index: 'YoY(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 6,
          type: 'GP Margin',
          index: 'QoQ(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 7,
          type: 'Net profit',
          index: 'Net profit (million) ',
          firstRow: true,
          isPercent: true
        },
        {
          id: 8,
          type: 'Net profit',
          index: 'YoY',
          disabled: true
        },
        {
          id: 9,
          type: 'Net profit',
          index: 'QoQ',
          disabled: true
        },
        {
          id: 10,
          type: 'Other metrics',
          chineseIndex: '其他指标1',
          index: 'Other metrics 1'
        },
        {
          id: 11,
          type: 'Other metrics',
          chineseIndex: '其他指标2',
          index: 'Other metrics 2'
        },
        {
          id: 12,
          type: 'Other metrics',
          chineseIndex: '其他指标3',
          index: 'Other metrics 3'
        }
      ],
      defaultBottomData: [
        { id: 1, index: 'Sales expense' },
        { id: 2, index: 'Admin expense' },
        { id: 3, index: 'R&D (absolute value)' },
        {
          id: 4,
          type: 'Net interest expense',
          index: 'Net interest expense',
          excludeMarkets: ['CH']
        },
        {
          id: 5,
          type: 'Finance expense',
          index: 'Finance expense',
          markets: ['CH']
        }
      ],
      defaultFutureTrendData: [
        { id: 1, index: 1 },
        { id: 2, index: 2 },
        { id: 3, index: 3 },
        { id: 4, index: 4 }
      ],
      happenList: [
        'Preliminary results',
        'Results',
        'News',
        'Expert',
        'Company visit',
        'Event',
        'Other'
      ],
      notesDraw: {
        show: false,
        name: ''
      },
      isDraft: false,
      showChatModal: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this._getDetailData();
  }

  _getDetailData = () => {
    const { id } = this.props.data;
    researchClient
      .findCompanyScoreDetail({ id })
      .then(resp => {
        this.setState({
          data: resp.data
        });
      })
      .catch(err => console.log(err));
  };

  _isReadOnly = () => {
    return true;
  };

  _createCompanyDetailForm = () => {
    const { data } = this.state;
    const readOnly = this._isReadOnly();
    const tickerLabel = `Ticker${readOnly ? '' : '(BBG ticker + Press [Tab])'}`;
    return (
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={data}
      >
        <Row gutter={24}>
          {!readOnly && (
            <>
              <Col span={24} style={{ marginBottom: '8px', height: '45px' }}>
                <Form.Item
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 21 }}
                  name="ticker"
                  label={tickerLabel}
                >
                  <div className={'ui form field'}>
                    <div style={{ width: '35%', float: 'left' }}>
                      <Input
                        readOnly={true}
                        style={{ height: '38px' }}
                        value={data.ticker}
                      />
                    </div>
                    <div style={{ width: '64%', float: 'right' }}>
                      <Input
                        style={{ height: '38px' }}
                        value={data.name}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24} style={{ height: '30px' }}>
            <p
              style={{ color: 'white', fontWeight: 'bold' }}
              className="site-page-header"
            >
              Long-term Factors [
              <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
                Total Score:
                {readOnly ? (
                  <span
                    style={{
                      color: 'orangered',
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.score}
                  </span>
                ) : (
                  <Select
                    className="companyScoreSelect"
                    style={{ width: '60px' }}
                    value={data.score}
                  >
                    {_createUIOptions(this.state.scoreList)}
                  </Select>
                )}
              </span>
              <span style={{ color: '#FF9E28', marginLeft: '10px' }}>
                Investment direction:
                {readOnly ? (
                  <span
                    style={{
                      color: 'orangered',
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.longInvestmentConclusion}
                  </span>
                ) : (
                  <Select
                    className="companyScoreSelect"
                    style={{ width: '120px' }}
                    value={data.longInvestmentConclusion}
                  >
                    {_createUIOptions(this.state.conclusionList)}
                  </Select>
                )}
              </span>
              ]
            </p>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="Governance"
            >
              <div style={{ width: '10%', float: 'left' }}>
                <Select value={data.management} readOnly={readOnly}>
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input value={data.managementDesc} readOnly={readOnly} />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              label=""
            >
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: `24%`,
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      fontSize: '12px',
                      marginRight: '3px',
                      verticalAlign: 'middle'
                    }}
                  >
                    Shareholder Structure :{' '}
                  </span>
                </div>
                <div style={{ width: `76%`, float: 'left' }}>
                  <div style={{ width: `12%`, float: 'left' }}>
                    <Select
                      value={data.equityStructureScore}
                      readOnly={readOnly}
                    >
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85.7%`, float: 'right' }}>
                    <Input value={data.equityStructure} readOnly={readOnly} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: '17.25%',
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      fontSize: '12px',
                      marginRight: '3px',
                      verticalAlign: 'middle'
                    }}
                  >
                    Incentives :{' '}
                  </span>
                </div>
                <div style={{ width: `82.75%`, float: 'left' }}>
                  <div style={{ width: `12.5%`, float: 'left' }}>
                    <Select
                      value={data.stockBasedCompensationScore}
                      readOnly={readOnly}
                    >
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85%`, float: 'right' }}>
                    <Input
                      value={data.stockBasedCompensation}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              label=""
            >
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: `24%`,
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      marginRight: '3px',
                      verticalAlign: 'middle',
                      fontSize: '12px'
                    }}
                  >
                    Outside Ownership :{' '}
                  </span>
                </div>
                <div style={{ width: `76%`, float: 'left' }}>
                  <div style={{ width: `12%`, float: 'left' }}>
                    <Select value={data.assetsOfMajorScore} readOnly={readOnly}>
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85.7%`, float: 'right' }}>
                    <Input
                      value={data.assetsOfMajorShareholders}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: `17.25%`,
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      fontSize: '12px',
                      marginRight: '3px',
                      verticalAlign: 'middle'
                    }}
                  >
                    Punishment :{' '}
                  </span>
                </div>
                <div style={{ width: `82.75%`, float: 'left' }}>
                  <div style={{ width: `12.5%`, float: 'left' }}>
                    <Select value={data.punishmentScore} readOnly={readOnly}>
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85%`, float: 'right' }}>
                    <Input value={data.punishment} readOnly={readOnly} />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="competitionScore"
              label="Competitiveness"
            >
              <div style={{ width: '10%', float: 'left' }}>
                <Select value={data.competitionScore} readOnly={readOnly}>
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.competition}
                  style={{ height: '32px' }}
                  readOnly={readOnly}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  _createCompanyShortDetailForm = () => {
    const { data } = this.state;
    const readOnly = this._isReadOnly();
    return (
      <Form
        ref={this.form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={data}
      >
        <Row gutter={24}>
          <Col span={24}>
            <p
              style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
              className="site-page-header"
            >
              Short-term Factors [
              <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
                Investment Conclusion:
                {readOnly ? (
                  <span
                    style={{
                      color: 'orangered',
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.shortInvestmentConclusion}
                  </span>
                ) : (
                  <Select
                    className="companyConclusionSelect"
                    value={data.shortInvestmentConclusion}
                  >
                    {_createUIOptions(this.state.conclusionList)}
                  </Select>
                )}
              </span>
              ]
            </p>
          </Col>
          <Col span={8} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              name="valuation"
              label="Valuation"
            >
              <Select readOnly={readOnly} value={data.valuation}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="workingCapitalDivRevenue"
              label="W.C./Rev"
            >
              <Select readOnly={readOnly} value={data.workingCapitalDivRevenue}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginBottom: '8px', height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="competitionSecondDerivative"
              label="Competition"
            >
              <Select
                readOnly={readOnly}
                value={data.competitionSecondDerivative}
              >
                {_createUIOptions(this.state.competitionList)}
              </Select>
            </Form.Item>
          </Col>
          <Form.List name={['companyBusinessList']}>
            {(fields, { add, remove }) => {
              return (
                <Row style={{ width: '100%' }}>
                  {fields.map((field, index) => (
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={3}
                        style={{
                          color: '#FF9E28',
                          width: '100%',
                          textAlign: 'right',
                          paddingRight: '2px',
                          alignItems: 'center',
                          fontSize: '12px'
                        }}
                      >
                        <span>Revenue breakdown:</span>
                      </Col>
                      <Col span={21} style={{ height: '41px' }}>
                        <div style={{ width: '23%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'segment']}
                            name={[field.name, 'segment']}
                            fieldKey={[field.fieldKey, 'segment']}
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                            label=""
                          >
                            <Input
                              style={{ width: '98%', marginLeft: '5px' }}
                              readOnly={readOnly}
                              onPointerMove={this._onSegmentChange}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ width: '9%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'volume']}
                            name={[field.name, 'volume']}
                            fieldKey={[field.fieldKey, 'volume']}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            label="Volume"
                          >
                            <Select>
                              {_createUIOptions(this.state.scoreList)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div style={{ width: '15%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'volumeSecondDerivative']}
                            name={[field.name, 'volumeSecondDerivative']}
                            fieldKey={[
                              field.fieldKey,
                              'volumeSecondDerivative'
                            ]}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Delta"
                          >
                            <Select>
                              {_createUIOptions(this.state.deltaList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '9%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'price']}
                            name={[field.name, 'price']}
                            fieldKey={[field.fieldKey, 'price']}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            label="Price"
                          >
                            <Select>
                              {_createUIOptions(this.state.scoreList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '15%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'priceSecondDerivative']}
                            name={[field.name, 'priceSecondDerivative']}
                            fieldKey={[field.fieldKey, 'priceSecondDerivative']}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Delta"
                          >
                            <Select>
                              {_createUIOptions(this.state.deltaList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '9%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'margin']}
                            name={[field.name, 'margin']}
                            fieldKey={[field.fieldKey, 'margin']}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            label="Margin"
                          >
                            <Select>
                              {_createUIOptions(this.state.scoreList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '15%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'marginSecondDerivative']}
                            name={[field.name, 'marginSecondDerivative']}
                            fieldKey={[
                              field.fieldKey,
                              'marginSecondDerivative'
                            ]}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Delta"
                          >
                            <Select>
                              {_createUIOptions(this.state.deltaList)}
                            </Select>
                          </Form.Item>
                        </div>
                        {!readOnly && (
                          <div
                            style={{
                              width: '40px',
                              height: '41px',
                              float: 'left'
                            }}
                          >
                            <PlusOutlined
                              style={{
                                paddingTop: '9px',
                                marginLeft: '3px',
                                display: 'inline-block',
                                width: '15px'
                              }}
                              onClick={() => add()}
                            />
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{
                                  paddingTop: '9px',
                                  marginLeft: '3px',
                                  display: 'inline-block',
                                  width: '15px'
                                }}
                                onClick={() => {
                                  remove(field.name);
                                  this._onSegmentChange();
                                }}
                              />
                            ) : null}
                          </div>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Row>
              );
            }}
          </Form.List>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              name="otherFinancialIndex"
              label="Other Financial metrics"
            >
              <Input.TextArea rows={3} readOnly={readOnly} />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="catalyst"
              label="Catalyst"
            >
              <Input.TextArea rows={3} readOnly={readOnly} />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              name="risk"
              label="Risk"
            >
              <Input.TextArea rows={3} readOnly={readOnly} />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="tracking"
              label="Key things to follow"
            >
              <Input.TextArea rows={3} readOnly={readOnly} />
            </Form.Item>
          </Col>
          {this._createMoodData(data, readOnly)}
          {this._createChipFlow(data, readOnly)}
        </Row>
      </Form>
    );
  };

  _createChipFlow = (data, readOnly) => {
    return (
      <>
        <Col span={24} style={{ marginTop: '10px' }}>
          <div
            style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
            className="site-page-header"
          >
            Chip&flow [
            <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
              Score:
              {readOnly ? (
                <span
                  style={{
                    color: 'orangered',
                    fontSize: '14px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontWeight: 'bold'
                  }}
                >
                  {data.marketView}
                </span>
              ) : (
                <Select
                  readOnly={readOnly}
                  className="companyScoreSelect"
                  style={{ width: '60px' }}
                  value={data.marketView}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              )}
              <Input
                value={data.marketViewDesc}
                readOnly={readOnly}
                style={{ width: '500px', marginLeft: '10px' }}
              />
            </span>
            ]
          </div>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="dividends"
            label="Dividends"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select value={data.dividendsScore} readOnly={readOnly}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                value={data.dividendsDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name="shareBuyback"
            label="Share buyback/reduction"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select value={data.shareBuybackScore} readOnly={readOnly}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                value={data.shareBuybackDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
              />
            </div>
          </Form.Item>
        </Col>
      </>
    );
  };

  _createMoodData = (data, readOnly) => {
    return (
      <>
        <Col span={24}>
          <div
            style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
            className="site-page-header"
          >
            Market Sentiments [
            <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
              Score:
              {readOnly ? (
                <span
                  style={{
                    color: 'orangered',
                    fontSize: '14px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontWeight: 'bold'
                  }}
                >
                  {data.moodScore}
                </span>
              ) : (
                <Select
                  readOnly={readOnly}
                  className="companyScoreSelect"
                  style={{ width: '60px' }}
                  value={data.moodScore}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              )}
            </span>
            ]
          </div>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="publicFund"
            label="Mutual funds' view"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select value={data.publicFundScore} readOnly={readOnly}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                value={data.publicFundDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name="privateFund"
            label="Hedge funds' view"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select value={data.privateFundScore} readOnly={readOnly}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                value={data.privateFundDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="privateInvestor"
            label="Individual investors' view"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select value={data.privateInvestorScore} readOnly={readOnly}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                value={data.privateInvestorDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name="researchDeepth"
            label="Market research deepth"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select value={data.researchDepthScore} readOnly={readOnly}>
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                value={data.researchDepthDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
              />
            </div>
          </Form.Item>
        </Col>
      </>
    );
  };

  _closeDraw = () => {
    const { closeDrawer } = this.props;
    closeDrawer();
  };

  _createCompanyReview = () => {
    const {
      data,
      defaultSingleQData,
      defaultBottomData,
      defaultFutureTrendData,
      companyBusinessList
    } = this.state;
    if (!data.reviewDetail && !data.review) {
      const nextQuanter = getNextQuarter();
      data.review = {
        quanter: nextQuanter.quarterKey,
        singleQuarterData: defaultSingleQData,
        performanceSplit: [],
        bottomLineData: defaultBottomData,
        futureTrendData: defaultFutureTrendData,
        marketFeedback: [{ id: 1 }],
        investmentConclusion: [{ id: 1 }],
        followUp: [{ id: 1 }]
      };
    } else {
      data.review = data.review ? data.review : JSON.parse(data.reviewDetail);
    }
    return (
      <CompanyReview
        {...this.props}
        companyBusinessList={companyBusinessList}
        onReviewDataChange={this._onReviewDataChange}
        onCompanyScoreDataChange={this.onInputChange}
        readOnly={true}
        getTickerExch={this._getTickerExch}
        defaultTemplate={{
          defaultSingleQData,
          defaultBottomData,
          defaultFutureTrendData
        }}
        data={data}
      />
    );
  };

  _getTickerExch = () => {
    const { ticker } = this.state.data;
    if (!ticker) return '';
    return TickerUtils.parseExch(ticker);
  };

  _createAttachList = () => {
    const { data } = this.state;
    if (_.isEmpty(data)) return;
    return (
      <LinkPannel {...this.props} data={data} readOnly={this._isReadOnly()} />
    );
  };

  render() {
    const { data } = this.state;
    const readOnly = this._isReadOnly();
    const title = (
      <div>
        <div style={{ float: 'left', width: '40%', textAlign: 'left' }}>
          <span style={{ color: '#FF9E28' }}>
            {data.ticker ? data.ticker : ''}
          </span>
          <span>{data.name ? `(${data.name})` : ''}</span>
        </div>
        <div style={{ float: 'right', width: '60%', textAlign: 'right' }}>
          <Button
            size="small"
            onClick={this._closeDraw}
            style={{ marginLeft: '10px' }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    return (
      <>
        <style>
          {`
                      .planDetailDrawerClass {
                      }

                      .planDetailDrawerClass  .ant-drawer-content{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass  .ant-drawer-header{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass .ant-drawer-title {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-form-item-label > label{
                        color: #FF9E28;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                      }

                      .planDetailDrawerClass .ant-input-number-lg input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-group-addon{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ant-page-header-heading-title{
                        color: white;
                        font-size: 15px;
                      }

                      .planDetailDrawerClass .ant-page-header{
                        padding: 5px;
                      }

                      .planDetailDrawerClass .anticon{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-divider{
                        margin-top: 0px;
                      }

                      .planDetailDrawerClass .ant-divider-plain.ant-divider-with-text{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-col {
                        height: 48px;
                      }

                      .planDetailDrawerClass .ui.form input[type="text"] {
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ui.input {
                        width: 100%
                      }

                      .planDetailDrawerClass i.icon {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 1px solid #594e4a !important;
                        color: white !important;
                      }

                      .planDetailDrawerClass .companyScoreSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                      }

                      .planDetailDrawerClass .companyConclusionSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                        width: 120px;
                      }
                      .planDetailDrawerClass .derivativeCalss {
                        margin-left: 5px;
                        color: #FF9E28;
                        font-size: 12px;
                      }
                      .planDetailDrawerClass .ant-select-arrow{
                        color: gray !important;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                        width: 100%;
                      }

                      .lastVersionDataClass{
                        color: green;
                        margin-left: 10px;
                      }

                      .planDetailDrawerClass .ant-collapse-header{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-header .ant-collapse-arrow{
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content-box{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                        padding: 0px;
                      }

                      .planDetailDrawerClass .ant-collapse {
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9 !important;
                        border-bottom: 0;
                        border-radius: 2px;
                      }

                      .planDetailDrawerClass .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9 !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content {
                        overflow: hidden;
                        border-top: 0px solid #d9d9d9 !important;
                        background-color: #2d3436;
                      }
                    `}
        </style>
        <Drawer
          width={'100%'}
          title={title}
          placement="right"
          maskClosable={readOnly}
          onClose={this._closeDraw}
          closable={false}
          visible={true}
          className="planDetailDrawerClass"
        >
          {!_.isEmpty(data) && this._createCompanyDetailForm()}
          {!_.isEmpty(data) && this._createCompanyShortDetailForm()}
          <Collapse defaultActiveKey={['1', '2']}>
            <Panel header="Attach List" key="1">
              {this._createAttachList()}
            </Panel>
            <Panel header="Review" key="2">
              {this._createCompanyReview()}
            </Panel>
          </Collapse>
        </Drawer>
      </>
    );
  }
}

export default CompanyScoreDetailDrawerOnlyForDetail;
