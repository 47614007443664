import {
  OMS_SNAPSHOT,
  OMS_UPDATE,
  ORDERS_SELECT,
  ROUTES_SELECT,
  OMS_FUNDS_SELECT,
  OMS_FILTER_CHANGE,
  OMS_DATE_CHANGE,
  OMS_VIEW_MODE_CHANGE,
  OMS_ORDER_BLOTTER_GET_REQUEST,
  OMS_ORDER_BLOTTER_GET_SUCCESS,
  OMS_ORDER_BLOTTER_GET_FAIL,
  OMS_REQ_TYPE_CHANGE,
  OMS_VIEW_USESRV_MODE_CHANGE
} from './omsConstants';

import { notification } from 'antd';
import React from 'react';
import client from './api/client';
import { Howl } from 'howler';

export function initOms(payload) {
  return {
    type: OMS_SNAPSHOT,
    payload: payload
  };
}

export function updateOms(payload) {
  return {
    type: OMS_UPDATE,
    payload: payload
  };
}

export function selectOrders(orderKeys) {
  return {
    type: ORDERS_SELECT,
    payload: orderKeys
  };
}

export function selectRoutes(routeKeys) {
  return {
    type: ROUTES_SELECT,
    payload: routeKeys
  };
}

export function selectFunds(funds) {
  return {
    type: OMS_FUNDS_SELECT,
    payload: funds
  };
}

export function changeFilter(payload) {
  return {
    type: OMS_FILTER_CHANGE,
    payload
  };
}

const AUDIO_PENDING_ORDERS_URI = `${process.env.PUBLIC_URL}/audios/ring.wav`;
const pendingOrdersSound = new Howl({ src: [AUDIO_PENDING_ORDERS_URI] });

const pendingOrdersPayloadConverter = payload => {
  const style = {
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'italic'
  };

  const items = Object.entries(payload).map(([bookCode, bookSummary]) => {
    const items = Object.keys(bookSummary).map(
      type => `${type} ${bookSummary[type]} orders`
    );
    return (
      <div key={bookCode} style={style}>
        {bookCode}: {items.join(',')}
      </div>
    );
  });

  return items;
};

const notifictionConfig = {
  PENDING_ORDERS: {
    title: 'PENDING ORDERS',
    sound: pendingOrdersSound,
    payloadConverter: pendingOrdersPayloadConverter
  }
};

export function sendNotification({ type, payload }) {
  if (!(type in notifictionConfig)) return;

  const { title, sound, payloadConverter } = notifictionConfig[type];
  const items = payloadConverter(payload);

  // Send notification alert.
  notification['info']({
    message: title,
    description: <div>{items}</div>
  });

  // play sound.
  sound.play();
}

export function toggleViewMode() {
  return {
    type: OMS_VIEW_MODE_CHANGE
  };
}

export function changeViewDate(date) {
  return {
    type: OMS_DATE_CHANGE,
    payload: date
  };
}

export function changeRequestType(reqType) {
  return {
    type: OMS_REQ_TYPE_CHANGE,
    payload: reqType
  };
}

export function getOrderBlotterRequest() {
  return {
    type: OMS_ORDER_BLOTTER_GET_REQUEST
  };
}

export function getOrderBlotterSuccess(payload) {
  return {
    type: OMS_ORDER_BLOTTER_GET_SUCCESS,
    payload
  };
}

export function getOrderBlotterFail(err) {
  return {
    type: OMS_ORDER_BLOTTER_GET_FAIL,
    payload: err
  };
}

export function getOrderBlotter(query) {
  return function(dispatch) {
    dispatch(getOrderBlotterRequest());
    client
      .getOrderBlotter(query)
      .then(payload => dispatch(getOrderBlotterSuccess(payload)))
      .catch(err => dispatch(getOrderBlotterFail(err)));
  };
}

export function toggleUseOmsSrvMode() {
  return {
    type: OMS_VIEW_USESRV_MODE_CHANGE
  };
}
