const licenseKey = 'non-commercial-and-evaluation';

const createSettings = (overrides = {}) => {
  const colHeaders =
    overrides.colHeaders ||
    (overrides.columns || []).map(c => {
      if (!c.readOnly)
        return `<span style="color: #9b2be0;font-weight: 500">${c.headerName}</span>`;
      return c.headerName;
    });

  return {
    licenseKey,

    width: '100%',
    height: '100%',
    stretchH: 'all',
    // rowHeaders: true,

    colHeaders,
    ...overrides
  };
};

export default {
  createSettings
};
