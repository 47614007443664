// All Tickers
export const LOAD_ALL_TICKERS_SUCCESS = 'LOAD_ALL_TICKERS_SUCCESS';
export const LOAD_ALL_TICKERS_FAILURE = 'LOAD_ALL_TICKERS_FAILURE';

// Short Interest
export const LOAD_SHORT_INTEREST_SUCCESS = 'LOAD_SHORT_INTEREST_SUCCESS';
export const LOAD_SHORT_INTEREST_FAILURE = 'LOAD_SHORT_INTEREST_FAILURE';

// Axe list Tickers
export const LOAD_AXELIST_TICKERS_SUCCESS = 'LOAD_AXELIST_TICKERS_SUCCESS';
export const LOAD_AXELIST_TICKERS_FAILURE = 'LOAD_AXELIST_TICKERS_FAILURE';
// Shortable Tickers
export const LOAD_SHORTABLE_TICKERS_SUCCESS = 'LOAD_SHORTABLE_TICKERS_SUCCESS';
export const LOAD_SHORTABLE_TICKERS_FAILURE = 'LOAD_SHORTABLE_TICKERS_FAILURE';

// Research Company
export const LOAD_COMPANY_RESEARCH_SUCCESS = 'LOAD_COMPANY_RESEARCH_SUCCESS';
export const LOAD_COMPANY_RESEARCH_FAILURE = 'LOAD_COMPANY_RESEARCH_FAILURE';

export const DELETE_COMPANY_RESEARCH_SUCCESS =
  'DELETE_COMPANY_RESEARCH_SUCCESS';
export const DELETE_COMPANY_RESEARCH_FAILURE =
  'DELETE_COMPANY_RESEARCH_FAILURE';

export const COMPANY_RESEARCH_SUBMIT_REQUEST =
  'COMPANY_RESEARCH_SUBMIT_REQUEST';
export const COMPANY_RESEARCH_SUBMIT_SUCCESS =
  'COMPANY_RESEARCH_SUBMIT_SUCCESS';
export const COMPANY_RESEARCH_SUBMIT_FAILURE =
  'COMPANY_RESEARCH_SUBMIT_FAILURE';

export const OPEN_COMPANY_RESEARCH_NEW_DIALOG =
  'OPEN_COMPANY_RESEARCH_NEW_DIALOG';
export const OPEN_COMPANY_RESEARCH_UPDATE_DIALOG =
  'OPEN_COMPANY_RESEARCH_UPDATE_DIALOG';
export const COMPANY_RESEARCH_INPUT_CHANGE = 'COMPANY_RESEARCH_INPUT_CHANGE';

export const COMPANY_RESEARCH_VALIDATION_FAILURE =
  'COMPANY_RESEARCH_VALIDATION_FAILURE';

// Broker Score
export const LOAD_BROKER_SCORES_SUCCESS = 'LOAD_BROKER_SCORES_SUCCESS';
export const LOAD_BROKER_SCORES_FAILURE = 'LOAD_BROKER_SCORES_FAILURE';

export const DELETE_BROKER_SCORES_SUCCESS = 'DELETE_BROKER_SCORES_SUCCESS';
export const DELETE_BROKER_SCORES_FAILURE = 'DELETE_BROKER_SCORES_FAILURE';

export const BROKER_SCORES_SUBMIT_REQUEST = 'BROKER_SCORES_SUBMIT_REQUEST';
export const BROKER_SCORES_SUBMIT_SUCCESS = 'BROKER_SCORES_SUBMIT_SUCCESS';
export const BROKER_SCORES_SUBMIT_FAILURE = 'BROKER_SCORES_SUBMIT_FAILURE';

export const OPEN_BROKER_SCORES_NEW_DIALOG = 'OPEN_BROKER_SCORES_NEW_DIALOG';
export const OPEN_BROKER_SCORES_UPDATE_DIALOG =
  'OPEN_BROKER_SCORES_UPDATE_DIALOG';
export const BROKER_SCORES_INPUT_CHANGE = 'BROKER_SCORES_INPUT_CHANGE';

export const NEW_BROKER_SCORE_ROW_DATA = 'NEW_BROKER_SCORE_ROW_DATA';

export const OPEN_BROKER_SCORES_DETAIL_DIALOG =
  'OPEN_BROKER_SCORES_DETAIL_DIALOG';
export const OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG =
  'OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG';
export const RESET_BROKER_SCORES_DETAIL_DIALOG =
  'RESET_BROKER_SCORES_DETAIL_DIALOG';
export const RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG =
  'RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG';
export const LOAD_BROKER_SCORE_BY_BROKER_SUCCESS =
  'LOAD_BROKER_SCORE_BY_BROKER_SUCCESS';
export const LOAD_BROKER_SCORE_BY_BROKER_FAILURE =
  'LOAD_BROKER_SCORE_BY_BROKER_FAILURE';
export const LOAD_BROKER_SCORE_LIST_SUCCESS = 'LOAD_BROKER_SCORE_LIST_SUCCESS';
export const LOAD_BROKER_SCORE_LIST_FAILURE = 'LOAD_BROKER_SCORE_LIST_FAILURE';
export const SAVE_BROKER_SCORE_SUCCESS = 'SAVE_BROKER_SCORE_SUCCESS';
export const SAVE_BROKER_SCORE_FAILURE = 'SAVE_BROKER_SCORE_FAILURE';
export const REMOVE_BROKER_SCORE_SUCCESS = 'REMOVE_BROKER_SCORE_SUCCESS';
export const REMOVE_BROKER_SCORE_FAILURE = 'REMOVE_BROKER_SCORE_FAILURE';
export const LOAD_CORRESPONDENCE_LIST_SUCCESS =
  'LOAD_CORRESPONDENCE_LIST_SUCCESS';
export const LOAD_CORRESPONDENCE_LIST_FAILURE =
  'LOAD_CORRESPONDENCE_LIST_FAILURE';
export const INITIATE_WEIGHT_MAP_LOADER_STATUS =
  'INITIATE_WEIGHT_MAP_LOADER_STATUS';
export const CHANGE_BROKER_QUERY_PARAMS = 'CHANGE_BROKER_QUERY_PARAMS';
export const CHANGE_BROKER_SCORE_MODAL_INPUT =
  'CHANGE_BROKER_SCORE_MODAL_INPUT';

// Analyst Compliment
export const LOAD_ANALYST_COMPLIMENT_SUCCESS =
  'LOAD_ANALYST_COMPLIMENT_SUCCESS';
export const LOAD_ANALYST_COMPLIMENT_FAILURE =
  'LOAD_ANALYST_COMPLIMENT_FAILURE';

export const DELETE_ANALYST_COMPLIMENT_SUCCESS =
  'DELETE_ANALYST_COMPLIMENT_SUCCESS';
export const DELETE_ANALYST_COMPLIMENT_FAILURE =
  'DELETE_ANALYST_COMPLIMENT_FAILURE';

export const ANALYST_COMPLIMENT_SUBMIT_REQUEST =
  'ANALYST_COMPLIMENT_SUBMIT_REQUEST';
export const ANALYST_COMPLIMENT_SUBMIT_SUCCESS =
  'ANALYST_COMPLIMENT_SUBMIT_SUCCESS';
export const ANALYST_COMPLIMENT_SUBMIT_FAILURE =
  'ANALYST_COMPLIMENT_SUBMIT_FAILURE';

export const OPEN_ANALYST_COMPLIMENT_NEW_DIALOG =
  'OPEN_ANALYST_COMPLIMENT_NEW_DIALOG';
export const OPEN_ANALYST_COMPLIMENT_UPDATE_DIALOG =
  'OPEN_ANALYST_COMPLIMENT_UPDATE_DIALOG';
export const ANALYST_COMPLIMENT_INPUT_CHANGE =
  'ANALYST_COMPLIMENT_INPUT_CHANGE';

// Broker Names
export const LOAD_BROKER_NAMES_SUCCESS = 'LOAD_BROKER_NAMES_SUCCESS';
export const LOAD_BROKER_NAMES_FAILURE = 'LOAD_BROKER_NAMES_FAILURE';

// Boxed Position
export const LOAD_BOXED_POSITION_SUCCESS = 'LOAD_BOXED_POSITION_SUCCESS';
export const LOAD_BOXED_POSITION_FAILURE = 'LOAD_BOXED_POSITION_FAILURE';

// reset research control
export const RESEARCH_CONTROL_RESET = 'RESEARCH_CONTROL_RESET';

// reset broker control
export const BROKER_CONTROL_RESET = 'BROKER_CONTROL_RESET';

// reset analyst control
export const ANALYST_CONTROL_RESET = 'ANALYST_CONTROL_RESET';

// server error message
export const SERVER_ERROR_MESSAGE =
  'Server Error! Please contact system administrator.';

// initiate loader status
export const INITIATE_LOADER_STATUS = 'INITIATE_LOADER_STATUS';

export const TICKERS_SEARCH_REQUEST = 'TICKERS_SEARCH_REQUEST';
export const TICKERS_SEARCH_SUCCESS = 'TICKERS_SEARCH_SUCCESS';

//broker
export const LOAD_SHORTABLE_LIST_SUCCESS = 'LOAD_SHORTABLE_LIST_SUCCESS';
export const LOAD_SHORTABLE_LIST_FAILURE = 'LOAD_SHORTABLE_LIST_FAILURE';
export const LOAD_SHORTABLE_EXCHANGE_LIST_SUCCESS =
  'LOAD_SHORTABLE_EXCHANGE_LIST_SUCCESS';
export const LOAD_SHORTABLE_EXCHANGE_LIST_FAILURE =
  'LOAD_SHORTABLE_EXCHANGE_LIST_FAILURE';
export const INIT_SHORTABLE_EXCHANGE_STATUS = 'INIT_SHORTABLE_EXCHANGE_STATUS';

//analyst
export const LOAD_ANALYST_LIST_SUCCESS = 'LOAD_ANALYST_LIST_SUCCESS';
export const LOAD_ANALYST_LIST_FAILURE = 'LOAD_ANALYST_LIST_FAILURE';
export const SAVE_ANALYST_SUCCESS = 'SAVE_ANALYST_SUCCESS';
export const SAVE_ANALYST_FAILURE = 'SAVE_ANALYST_FAILURE';
export const DELETE_ANALYST_SUCCESS = 'DELETE_ANALYST_SUCCESS';
export const DELETE_ANALYST_FAILURE = 'DELETE_ANALYST_FAILURE';
export const CHANGE_ANALYST_QUERY_PARAMS = 'CHANGE_ANALYST_QUERY_PARAMS';
export const CHANGE_ANALYST_MODAL_INPUT = 'CHANGE_ANALYST_MODAL_INPUT';
export const CLOSE_ANALYST_MODAL_WIN = 'CLOSE_ANALYST_MODAL_WIN';
export const GET_BROKER_LIST_SUCCESS = 'GET_BROKER_LIST_SUCCESS';
export const GET_BROKER_LIST_ERROR = 'GET_BROKER_LIST_ERROR';
export const INIT_LOAD_BROKER_LIST_STATUS = 'INIT_LOAD_BROKER_LIST_STATUS';
export const CHANGE_ANALYST_UPDATE_MODAL_INPUT =
  'CHANGE_ANALYST_UPDATE_MODAL_INPUT';
export const GET_ANALYST_NAME_LIST_SUCCESS = 'GET_ANALYST_NAME_LIST_SUCCESS';
export const GET_ANALYST_NAME_LIST_ERROR = 'GET_ANALYST_NAME_LIST_ERROR';
export const INIT_LOAD_ANALYST_NAME_LIST_STATUS =
  'INIT_LOAD_ANALYST_NAME_LIST_STATUS';
export const GET_ANALYST_SECTOR_LIST_SUCCESS =
  'GET_ANALYST_SECTOR_LIST_SUCCESS';
export const GET_ANALYST_SECTOR_LIST_ERROR = 'GET_ANALYST_SECTOR_LIST_ERROR';
export const INIT_LOAD_ANALYST_SECTOR_LIST_STATUS =
  'INIT_LOAD_ANALYST_SECTOR_LIST_STATUS';
export const GET_ANALYST_DETAIL_SUCCESS = 'GET_ANALYST_DETAIL_SUCCESS';
export const GET_ANALYST_DETAIL_ERROR = 'GET_ANALYST_DETAIL_ERROR';
export const INIT_LOAD_ANALYST_DETAIL_STATUS =
  'INIT_LOAD_ANALYST_DETAIL_STATUS';

// monthly review benchmark performance load success
export const LOAD_MONTHLY_BENCHMARK_SUCCESS = 'LOAD_MONTHLY_BENCHMARK_SUCCESS';
export const LOAD_MONTHLY_BENCHMARK_FAILURE = 'LOAD_MONTHLY_BENCHMARK_FAILURE';
// monthly review my portfolio load success
export const LOAD_MONTHLY_MY_PORTFOLIO_SUCCESS =
  'LOAD_MONTHLY_MY_PORTFOLIO_SUCCESS';
export const LOAD_MONTHLY_MY_PORTFOLIO_FAILURE =
  'LOAD_MONTHLY_MY_PORTFOLIO_FAILURE';
// monthly review get detail info success
export const LOAD_MONTHLY_REVIEW_DETAIL_SUCCESS =
  'LOAD_MONTHLY_REVIEW_DETAIL_SUCCESS';
export const LOAD_MONTHLY_REVIEW_DETAIL_FAILURE =
  'LOAD_MONTHLY_REVIEW_DETAIL_FAILURE';
export const MONTHLY_REVIEW_DETAIL_INPUT_CHANGE =
  'MONTHLY_REVIEW_DETAIL_INPUT_CHANGE';
// query monthlyReview list
export const LOAD_MONTHLY_REVIEW_LIST_SUCCESS =
  'LOAD_MONTHLY_REVIEW_LIST_SUCCESS';
export const LOAD_MONTHLY_REVIEW_LIST_FAILURE =
  'LOAD_MONTHLY_REVIEW_LIST_FAILURE';
// save monthly Review
export const SAVE_MONTHLY_REVIEW_SUCCESS = 'SAVE_MONTHLY_REVIEW_SUCCESS';
export const SAVE_MONTHLY_REVIEW_FAILURE = 'SAVE_MONTHLY_REVIEW_FAILURE';
// submit monthly Review
export const SUBMIT_MONTHLY_REVIEW_SUCCESS = 'SUBMIT_MONTHLY_REVIEW_SUCCESS';
export const SUBMIT_MONTHLY_REVIEW_FAILURE = 'SUBMIT_MONTHLY_REVIEW_FAILURE';
// submit monthly Review
export const REMOVE_MONTHLY_REVIEW_SUCCESS = 'REMOVE_MONTHLY_REVIEW_SUCCESS';
export const REMOVE_MONTHLY_REVIEW_FAILURE = 'REMOVE_MONTHLY_REVIEW_FAILURE';
export const INITIATE_MONTHLY_REVIEW_LOADER_STATUS =
  'INITIATE_MONTHLY_REVIEW_LOADER_STATUS';
export const INITIATE_MONTHLY_REVIEW_DETAIL_DATA =
  'INITIATE_MONTHLY_REVIEW_DETAIL_DATA';
export const CHAGE_MONTHLY_REVIEW_QUERY_PARAMS =
  'CHAGE_MONTHLY_REVIEW_QUERY_PARAMS';
export const INITIATE_MONTHLY_REVIEW_LOADER_BENCHMARK_STATUS =
  'INITIATE_MONTHLY_REVIEW_LOADER_BENCHMARK_STATUS';
export const INITIATE_MONTHLY_REVIEW_LOADER_MYPERFORMACNE_STATUS =
  'INITIATE_MONTHLY_REVIEW_LOADER_MYPERFORMACNE_STATUS';
export const GET_GROSS_AND_BETA_BY_FUNDANDBOOK_SUCCESS =
  'GET_GROSS_AND_BETA_BY_FUNDANDBOOK_SUCCESS';
export const GET_GROSS_AND_BETA_BY_FUNDANDBOOK_FAILURE =
  'GET_GROSS_AND_BETA_BY_FUNDANDBOOK_FAILURE';
export const GET_MANAGER_USER_LIST_SUCCESS = 'GET_MANAGER_USER_LIST_SUCCESS';
export const GET_MANAGER_USER_LIST_FAILURE = 'GET_MANAGER_USER_LIST_FAILURE';
export const SAVE_BROKER_SCORE_RANK_SUCCESS = 'SAVE_BROKER_SCORE_RANK_SUCCESS';
export const SAVE_BROKER_SCORE_RANK_FAILURE = 'SAVE_BROKER_SCORE_RANK_FAILURE';
export const QUERY_BROKER_SCORE_RANK_SUCCESS =
  'QUERY_BROKER_SCORE_RANK_SUCCESS';
export const QUERY_BROKER_SCORE_RANK_FAILURE =
  'QUERY_BROKER_SCORE_RANK_FAILURE';
export const LOAD_YEAR_BROKER_SCORE_SUCCESS = 'LOAD_YEAR_BROKER_SCORE_SUCCESS';
export const LOAD_YEAR_BROKER_SCORE_FAILURE = 'LOAD_YEAR_BROKER_SCORE_FAILURE';

export const LOAD_RESEARCH_REPORT_LIST_SUCCESS =
  'LOAD_RESEARCH_REPORT_LIST_SUCCESS';
export const LOAD_RESEARCH_REPORT_LIST_FAILURE =
  'LOAD_RESEARCH_REPORT_LIST_FAILURE';
export const LOAD_RESEARCH_REPORT_DETAIL_SUCCESS =
  'LOAD_RESEARCH_REPORT_DETAIL_SUCCESS';
export const LOAD_RESEARCH_REPORT_DETAIL_FAILURE =
  'LOAD_RESEARCH_REPORT_DETAIL_FAILURE';
export const INIT_LOAD_RESEARCH_REPORT_STATUS =
  'INIT_LOAD_RESEARCH_REPORT_STATUS';
export const INIT_LOAD_RESEARCH_REPORT_DETAIL_STATUS =
  'INIT_LOAD_RESEARCH_REPORT_DETAIL_STATUS';
export const CHANGE_RESEARCH_REPORT_DETAIL_PARAMS =
  'CHANGE_RESEARCH_REPORT_DETAIL_PARAMS';
export const OPEN_RESEARCH_REPORT_MODAL = 'OPEN_RESEARCH_REPORT_MODAL';
export const CLOSE_RESEARCH_REPORT_MODAL = 'CLOSE_RESEARCH_REPORT_MODAL';
export const SEARCH_TICKER_SUCCESS = 'SEARCH_TICKER_SUCCESS';
export const SEARCH_TICKER_FAILURE = 'SEARCH_TICKER_FAILURE';
export const SAVE_RESERCH_REPORT_SUCCESS = 'SAVE_RESERCH_REPORT_SUCCESS';
export const SAVE_RESERCH_REPORT_FAILURE = 'SAVE_RESERCH_REPORT_FAILURE';
export const CHAGE_RESEARCH_REPORT_QUERY_PARAMS =
  'CHAGE_RESEARCH_REPORT_QUERY_PARAMS';
export const COPY_RESERCH_REPORT_SUCCESS = 'COPY_RESERCH_REPORT_SUCCESS';
export const COPY_RESERCH_REPORT_FAILURE = 'COPY_RESERCH_REPORT_FAILURE';
export const GET_RESERCH_PERSON_LIST_SUCCESS =
  'GET_RESERCH_PERSON_LIST_SUCCESS';
export const GET_RESERCH_PERSON_LIST_FAILURE =
  'GET_RESERCH_PERSON_LIST_FAILURE';
export const GET_RESERCH_GICS_LIST_SUCCESS = 'GET_RESERCH_GICS_LIST_SUCCESS';
export const GET_RESERCH_GICS_LIST_FAILURE = 'GET_RESERCH_GICS_LIST_FAILURE';
export const DELETE_RESERCH_ATTACH_SUCCESS = 'DELETE_RESERCH_ATTACH_SUCCESS';
export const DELETE_RESERCH_ATTACH_FAILURE = 'DELETE_RESERCH_ATTACH_FAILURE';
export const RESERCH_REPORT_CELL_CHANGE = 'RESERCH_REPORT_CELL_CHANGE';
export const LOAD_SHORT_INTEREST_PERCENT_SUCCESS =
  'LOAD_SHORT_INTEREST_PERCENT_SUCCESS';
export const LOAD_SHORT_INTEREST_PERCENT_FAILURE =
  'LOAD_SHORT_INTEREST_PERCENT_FAILURE';

export const GET_BLOCK_LIST_SUCCESS = 'GET_RESERCH_GICS_LIST_SUCCESS';
export const GET_BLOCK_LIST_FAILURE = 'GET_RESERCH_GICS_LIST_FAILURE';

export const ADD_COMPANY_KEY_DATA_SUCCESS = 'ADD_COMPANY_KEY_DATA_SUCCESS';
export const ADD_COMPANY_KEY_DATA_FAILURE = 'ADD_COMPANY_KEY_DATA_FAILURE';
export const UPDATE_COMPANY_KEY_DATA_SUCCESS =
  'UPDATE_COMPANY_KEY_DATA_SUCCESS';
export const UPDATE_COMPANY_KEY_DATA_FAILURE =
  'UPDATE_COMPANY_KEY_DATA_FAILURE';
export const GET_COMPANY_KEY_DATA_SUCCESS = 'GET_COMPANY_KEY_DATA_SUCCESS';
export const GET_COMPANY_KEY_DATA_FAILURE = 'GET_COMPANY_KEY_DATA_FAILURE';
export const ADD_COMPANY_MEETING_RECORD_SUCCESS =
  'ADD_COMPANY_MEETING_RECORD_SUCCESS';
export const ADD_COMPANY_MEETING_RECORD_FAILURE =
  'ADD_COMPANY_MEETING_RECORD_FAILURE';
export const UPDATE_COMPANY_MEETING_RECORD_SUCCESS =
  'UPDATE_COMPANY_MEETING_RECORD_SUCCESS';
export const UPDATE_COMPANY_MEETING_RECORD_FAILURE =
  'UPDATE_COMPANY_MEETING_RECORD_FAILURE';
export const GET_COMPANY_MEETING_RECORD_SUCCESS =
  'GET_COMPANY_MEETING_RECORD_SUCCESS';
export const GET_COMPANY_MEETING_RECORD_FAILURE =
  'GET_COMPANY_MEETING_RECORD_FAILURE';
export const ADD_COMPANY_REPLAY_SCORE_SUCCESS =
  'ADD_COMPANY_REPLAY_SCORE_SUCCESS';
export const ADD_COMPANY_REPLAY_SCORE_FAILURE =
  'ADD_COMPANY_REPLAY_SCORE_FAILURE';
export const UPDATE_COMPANY_REPLAY_SCORE_SUCCESS =
  'UPDATE_COMPANY_REPLAY_SCORE_SUCCESS';
export const UPDATE_COMPANY_REPLAY_SCORE_FAILURE =
  'UPDATE_COMPANY_REPLAY_SCORE_FAILURE';
export const GET_COMPANY_REPLAY_SCORE_SUCCESS =
  'GET_COMPANY_REPLAY_SCORE_SUCCESS';
export const GET_COMPANY_REPLAY_SCORE_FAILURE =
  'GET_COMPANY_REPLAY_SCORE_FAILURE';

export const GET_COMPANY_KEY_DETAIL_DATA_SUCCESS =
  'GET_COMPANY_KEY_DETAIL_DATA_SUCCESS';
export const GET_COMPANY_KEY_DETAIL_DATA_FAILURE =
  'GET_COMPANY_KEY_DETAIL_DATA_FAILURE';
export const OPEN_COMPANY_KEY_DETAIL_DATA_MODAL =
  'OPEN_COMPANY_KEY_DETAIL_DATA_MODAL';
export const CLOSE_COMPANY_KEY_DETAIL_DATA_MODAL =
  'CLOSE_COMPANY_KEY_DETAIL_DATA_MODAL';
export const GET_COMPANY_MEETING_RECORD_DETAIL_SUCCESS =
  'GET_COMPANY_MEETING_RECORD_DETAIL_SUCCESS';
export const GET_COMPANY_MEETING_RECORD_DETAIL_FAILURE =
  'GET_COMPANY_MEETING_RECORD_DETAIL_FAILURE';
export const OPEN_COMPANY_MEETING_RECORD_DETAIL_MODAL =
  'OPEN_COMPANY_MEETING_RECORD_DETAIL_MODAL';
export const CLOSE_COMPANY_MEETING_RECORD_DETAIL_MODAL =
  'CLOSE_COMPANY_MEETING_RECORD_DETAIL_MODAL';
export const GET_COMPANY_REPLAY_SCORE_DETAIL_SUCCESS =
  'GET_COMPANY_REPLAY_SCORE_DETAIL_SUCCESS';
export const GET_COMPANY_REPLAY_SCORE_DETAIL_FAILURE =
  'GET_COMPANY_REPLAY_SCORE_DETAIL_FAILURE';
export const OPEN_COMPANY_REPLAY_SCORE_MODAL =
  'OPEN_COMPANY_REPLAY_SCORE_MODAL';
export const CLOSE_COMPANY_REPLAY_SCORE_MODAL =
  'CLOSE_COMPANY_REPLAY_SCORE_MODAL';
export const FETCH_ALL_EXCELLENTCOMPANY_SUCCESS =
  'FETCH_ALL_EXCELLENTCOMPANY_SUCCESS';
export const FETCH_ALL_EXCELLENTCOMPANY_FAILURE =
  'FETCH_ALL_EXCELLENTCOMPANY_FAILURE';

//push votes
export const SET_PUSH_VOTES_RECORD_READED = 'SET_PUSH_VOTES_RECORD_READED';
export const OPEN_ADD_UPDATE_PUSH_VOTES_MODAL =
  'OPEN_ADD_UPDATE_PUSH_VOTES_MODAL';
export const CLOSE_ADD_UPDATE_PUSH_VOTES_MODAL =
  'CLOSE_ADD_UPDATE_PUSH_VOTES_MODAL';
export const CHANGE_PUSH_VOTES_MODAL_INPUT = 'CHANGE_PUSH_VOTES_MODAL_INPUT';
export const CHANGE_PUSH_VOTES_SELECT_ITEM = 'CHANGE_PUSH_VOTES_SELECT_ITEM';
export const LOAD_PUSH_VOTES_DATA_SUCCESS = 'LOAD_PUSH_VOTES_DATA_SUCCESS';
export const LOAD_PUSH_VOTES_DATA_FAILURE = 'LOAD_PUSH_VOTES_DATA_FAILURE';
export const LOAD_PUSH_VOTE_RETURN_DATA_SUCCESS =
  'LOAD_PUSH_VOTE_RETURN_DATA_SUCCESS';
export const LOAD_PUSH_VOTE_RETURN_DATA_FAILURE =
  'LOAD_PUSH_VOTE_RETURN_DATA_FAILURE';
export const SAVE_PUSH_VOTES_DATA_SUCCESS = 'SAVE_PUSH_VOTES_DATA_SUCCESS';
export const SAVE_PUSH_VOTES_DATA_FAILURE = 'SAVE_PUSH_VOTES_DATA_FAILURE';
export const OPEN_ADD_PUSH_VOTE_NOTE_MODAL = 'OPEN_ADD_PUSH_VOTE_NOTE_MODAL';
export const CHANGE_PUSH_VOTE_NOTE_MODAL_INPUT =
  'CHANGE_PUSH_VOTE_NOTE_MODAL_INPUT';
export const CLOSE_ADD_PUSH_VOTE_NOTE_MODAL = 'CLOSE_ADD_PUSH_VOTE_NOTE_MODAL';
export const GET_PUSH_VOTES_SUBSCRIPT_CONFIG_SUCCESS =
  'GET_PUSH_VOTES_SUBSCRIPT_CONFIG_SUCCESS';
export const GET_PUSH_VOTES_SUBSCRIPT_CONFIG_FAILURE =
  'GET_PUSH_VOTES_SUBSCRIPT_CONFIG_FAILURE';
export const GET_PUSH_VOTES_PM_USER_LIST_SUCCESS =
  'GET_PUSH_VOTES_PM_USER_LIST_SUCCESS';
export const GET_PUSH_VOTES_PM_USER_LIST_FAILURE =
  'GET_PUSH_VOTES_PM_USER_LIST_FAILURE';
export const GET_PUSH_VOTES_ANALYST_USER_LIST_SUCCESS =
  'GET_PUSH_VOTES_ANALYST_USER_LIST_SUCCESS';
export const GET_PUSH_VOTES_ANALYST_USER_LIST_FAILURE =
  'GET_PUSH_VOTES_ANALYST_USER_LIST_FAILURE';
export const ADJUST_PUSH_VOTES_DATA_SUCCESS = 'ADJUST_PUSH_VOTES_DATA_SUCCESS';
export const ADJUST_PUSH_VOTES_DATA_FAILURE = 'ADJUST_PUSH_VOTES_DATA_FAILURE';
export const INIT_PUSH_VOTES_STATUS = 'INIT_PUSH_VOTES_STATUS';

//portfolio plan
export const OPEN_ADD_UPDATE_PORTFOLIO_PLAN_MODAL =
  'OPEN_ADD_UPDATE_PORTFOLIO_PLAN_MODAL';
export const CLOSE_ADD_UPDATE_PORTFOLIO_PLAN_MODAL =
  'CLOSE_ADD_UPDATE_PORTFOLIO_PLAN_MODAL';
