import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_RESET,
  LOGOUT_SUCCESS,
  CHG_PWD_REQUEST,
  CHG_PWD_SUCCESS,
  CHG_PWD_FAILURE,
  CHG_PWD_RESET
} from './authConstants';
import client from './api/client';
import { sendUnSubscription } from '../portfolio/portfolioActions';
import { loadSettings } from '../settings/settingActions';
import { sleep } from 'common/utils/promiseUtils';

function loginRequest() {
  return {
    type: LOGIN_REQUEST
  };
}

function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload
  };
}

function loginFailure(err) {
  return {
    type: LOGIN_FAILURE,
    payload: err
  };
}

export function login(user) {
  return function(dispatch) {
    dispatch(loginRequest());
    client
      .login(user)
      .then(payload => {
        dispatch(loginSuccess(payload));
        dispatch(loadSettings());
      })
      .catch(err => dispatch(loginFailure(err)));
  };
}

export function resetLoginForm() {
  return {
    type: LOGIN_RESET
  };
}

function chgPwdRequest() {
  return {
    type: CHG_PWD_REQUEST
  };
}

function chgPwdSuccess() {
  return {
    type: CHG_PWD_SUCCESS
  };
}

function chgPwdFailure(err) {
  return {
    type: CHG_PWD_FAILURE,
    payload: err
  };
}

export function chgPwd(pwdObj) {
  return function(dispatch) {
    dispatch(chgPwdRequest());
    client
      .chgPwd(pwdObj)
      .then(_ => {
        dispatch(chgPwdSuccess());
      })
      .catch(err => dispatch(chgPwdFailure(err)));
  };
}

export function resetChgPwdForm() {
  return {
    type: CHG_PWD_RESET
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function logout(user) {
  return function(dispatch) {
    client.logout();
    if (user) dispatch(sendUnSubscription());
    sleep(1000)().then(_ => dispatch(logoutSuccess()));
  };
}
