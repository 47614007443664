import React, { Component } from 'react';
import SplitPane from 'react-split-pane';
import { Modal, Select, DatePicker, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../common/ui/agGridUtils';
import 'ag-grid-enterprise';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { DIALOG_REVIEW } from '../portfolioConstants';
import { orderReviewColumn } from './GridColumnMap';
import client from '../api/client';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

const Option = Select.Option;
const { RangePicker } = DatePicker;

class OrderReviewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadStatus: 'READY',
      trades: [],
      selectedTrade: null,
      query: {
        date: [moment(), moment()]
      },
      stockPriceData: [],
      selectedData: [],
      securityList: [],
      posDataList: [],
      gridSettings: agGridUtils.createSettings({
        columnDefs: orderReviewColumn,
        floatingFilter: false,
        defaultColDef: {
          enableCellChangeFlash: false,
          minWidth: 25,
          checkboxSelection: agGridUtils.isFirstColumn,
          headerCheckboxSelection: agGridUtils.isFirstColumn,
          headerCheckboxSelectionFilteredOnly: true,
          sortable: true,
          resizable: true,
          filter: true
        },
        groupIncludeTotalFooter: false,
        deltaRowDataMode: false,
        getRowNodeId: data => data.requestId,
        rowGroupPanelShow: false,
        pivotPanelShow: false,
        sideBar: {
          toolPanels: []
        }
      })
    };
  }

  handleTradeClick = trade => {
    this.setState({ selectedTrade: trade });
  };

  handleArrowHover = trade => {
    // Handle mouse hover logic here
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_REVIEW);
  };

  _loadDataOnDateChange = () => {
    const { query } = this.state;
    const params = {
      ...query
    };
    this._loadData(params);
  };

  _loadData = query => {
    const { securityIdList } = query;
    this.setState({
      loadStatus: 'LOADING'
    });
    Promise.all([client.reviewOrder(query)])
      .then(([rsp]) => {
        let priceList = [];
        let tradeList = [];
        let posList = [];
        if (!_.isEmpty(rsp)) {
          Object.keys(rsp).forEach(r => {
            const { marketData, orderList, positionList } = rsp[r];
            const market = _.isEmpty(marketData) ? [] : marketData;
            const orders = _.isEmpty(orderList) ? [] : orderList;
            const pos = _.isEmpty(positionList) ? [] : positionList;
            if (securityIdList.includes(parseInt(r))) {
              priceList.push(...market);
              tradeList.push(...orders);
              posList.push(...pos);
            }
          });
        }
        this.setState({
          stockPriceData: priceList,
          trades: tradeList,
          loadStatus: 'READY',
          posDataList: posList
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loadStatus: 'READY' });
      });
  };

  _loadSecurityData = query => {
    const params = {
      ...query,
      securityIdList: null
    };
    client
      .getSecurities(params)
      .then(resp => {
        this.setState({
          securityList: resp
        });
      })
      .catch(err => console.log(err));
  };

  componentDidMount() {
    const currentDate = new Date();
    let beginDate = new Date(currentDate);
    beginDate.setFullYear(currentDate.getFullYear() - 1);
    let securityIdList = [];
    const securityId = this.props.ui.selectedPosition.securityId;
    const ulySecurityId = this.props.ui.selectedPosition.undlSecurityId;
    const ticker = this.props.ui.selectedPosition.ticker;
    if (ticker.includes(' CFD ') || ticker.includes(' FFS ')) {
      securityIdList.push(ulySecurityId);
    } else if (securityId !== null && securityId !== undefined) {
      securityIdList.push(securityId);
    }
    const query = {
      fundCode: this.props.ui.selectedFund,
      bookCode: this.props.ui.selectedBook,
      beginDate: beginDate.toISOString().split('T')[0],
      endDate: currentDate.toISOString().split('T')[0],
      securityIdList: securityIdList,
      date: [moment(beginDate), moment(currentDate)]
    };
    this.setState({
      query
    });
    this._loadData(query);
    this._loadSecurityData(query);
  }

  onSecurityChange = securityId => {
    const { query } = this.state;
    const params = {
      ...query,
      securityIdList: [securityId]
    };
    this.setState({
      query: params
    });
    this._loadData(params);
  };

  _onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    let selectedData = [];
    if (!_.isEmpty(selectedRows)) {
      selectedData = selectedRows.map(r => r.requestId);
    }
    this.setState({ selectedData });
  };

  _onCellEditingStopped = params => {
    const requestId = params.data.requestId;
    const order = { requestId: requestId, pmReason: params.data.pmReason };
    client.updateOrderReason(order).then(rsp => console.info(rsp));
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  _createDataGrid = () => {
    const { trades, gridSettings } = this.state;
    const data = _.isEmpty(trades)
      ? []
      : _.orderBy(trades, ['tradeDate'], ['desc']);
    return (
      !_.isEmpty(trades) && (
        <div
          className={`ag-theme-balham-dark grid-wrapper`}
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            {...gridSettings}
            rowData={data}
            rowSelection="multiple"
            onGridReady={this.onGridReady}
            onSelectionChanged={this._onSelectionChanged}
            onCellEditingStopped={this._onCellEditingStopped}
          />
        </div>
      )
    );
  };

  _changeReviewDate = dates => {
    const { query } = this.state;
    this.setState(
      {
        query: {
          ...query,
          date: dates,
          beginDate: dates[0].format('YYYY-MM-DD'),
          endDate: dates[1].format('YYYY-MM-DD')
        }
      },
      this._loadDataOnDateChange
    );
  };

  _disabledTime = current => {
    const { date } = this.state.query;
    if (!date || date.length === 0) {
      return false;
    }
    const tooLate = date[0] && current <= moment('2020-01-01', 'YYYY-MM-DD');
    return false || tooLate;
  };

  render() {
    const {
      stockPriceData,
      trades,
      selectedData,
      securityList,
      query,
      loadStatus
    } = this.state;
    const currentSecurityId =
      _.isEmpty(query) || _.isEmpty(query.securityIdList)
        ? null
        : query.securityIdList[0];
    return (
      <Modal
        width={'92%'}
        maskClosable={false}
        visible={true}
        onCancel={this.closeDialog}
        className="orderReivewModalInnerClass"
        bodyStyle={{
          height: '80vh',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        footer={null}
      >
        <div>
          <style>
            {` 
                  .orderReivewModalInnerClass{
                    background-color: #2d3436 !important;
                    color: white;
                    border: solid 1px #54C8FF;
                  }

                  .orderReivewModalInnerClass .ant-modal-header,.ant-modal-footer{
                    background-color: #2d3436 !important;
                    color: white;
                  }

                  .orderReivewModalInnerClass .ant-modal-header,.ant-modal-footer{
                    background-color: #2d3436 !important;
                    color: white;
                  }

                  .orderReivewModalInnerClass .SplitPane {
                    width: 99% !important;
                  }



                  .orderReivewModalInnerClass .securityFilter {
                    display: flex;
                    justify-content: flex-start;
                     /* padding: 1px; */
                    line-height: 100%;
                    margin-top: 10px;
                  }

                  .orderReivewModalInnerClass .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .orderReivewModalInnerClass .ant-picker{
                    background-color:#FF9E28;
                  }

                  .orderReivewModalInnerClass .ant-select-arrow{
                    color: gray !important;
                  }

                  .orderReivewModalInnerClass .anticon {
                    color: gray !important;
                  }
                 
                `}
          </style>

          {loadStatus === 'LOADING' ? (
            <Spin spinning={true} />
          ) : (
            <SplitPane split="horizontal" defaultSize={`75%`} height={'100%'}>
              <div style={{ width: '100%', height: '100%' }}>
                <div className="securityFilter">
                  <Select
                    allowClear={false}
                    showSearch
                    onChange={this.onSecurityChange}
                    value={currentSecurityId}
                    optionFilterProp="label"
                    style={{ marginLeft: '5px', width: 180 }}
                  >
                    {securityList.map(t => (
                      <Option
                        value={t.securityId}
                        key={t.securityId}
                        label={t.bbTcm}
                      >
                        {t.bbTcm}
                      </Option>
                    ))}
                  </Select>
                  <RangePicker
                    size="small"
                    style={{ width: '220px', marginLeft: '5px' }}
                    onCalendarChange={this._changeReviewDate}
                    value={query.date}
                    format={'YYYY-MM-DD'}
                    disabledDate={this._disabledTime}
                    allowClear={false}
                    onChange={this._changeReviewDate}
                  />
                </div>
                <div style={{ height: '30%' }}>
                  <ResponsiveContainer height={'100%'} width={'100%'}>
                    <LineChart data={stockPriceData} syncId="anyId">
                      <XAxis dataKey="date" />
                      <YAxis domain={['dataMin', 'dataMax']} />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="last"
                        name="Price"
                        stroke="#8884d8"
                        strokeWidth={3}
                        dot={props => {
                          const { cx, cy, payload } = props;

                          if (
                            payload &&
                            trades.some(
                              trade => trade.tradeDate === payload.date
                            )
                          ) {
                            const trade = trades.find(
                              trade => trade.tradeDate === payload.date
                            );

                            const buyFlag =
                              trade.side.toUpperCase() === 'BUY' ||
                              trade.side.toUpperCase() === 'COVR';
                            const selected = selectedData.includes(
                              trade.requestId
                            );
                            const size = selected ? 15 : 10;
                            const color = buyFlag
                              ? selected
                                ? 'green'
                                : 'green'
                              : selected
                              ? 'red'
                              : 'red';
                            const angle = buyFlag ? 90 : -90;

                            return (
                              <g>
                                <path
                                  d={`M${size} 0 L${size} 10 L0 5 Z`}
                                  transform={`translate(${cx}, ${cy}) rotate(${angle})`}
                                  fill={color}
                                  stroke={selected && '#FF9E28'}
                                />
                                <text
                                  x={cx}
                                  y={cy - 10}
                                  dy={-8}
                                  fill={color}
                                  textAnchor="middle"
                                >
                                  {trade.quantity}
                                </text>
                              </g>
                            );
                          }

                          return null;
                        }}
                        onMouseEnter={this.handleArrowHover}
                        onMouseLeave={this.handleArrowHover}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div style={{ height: `30%`, width: '100%' }}>
                  <ResponsiveContainer height={'100%'} width={'100%'}>
                    <LineChart data={stockPriceData} syncId="anyId">
                      <XAxis dataKey="date" />
                      <YAxis
                        type="number"
                        domain={([dataMin, dataMax]) => {
                          const absMax = Math.max(
                            Math.abs(dataMin),
                            Math.abs(dataMax)
                          );
                          return [-absMax, absMax];
                        }}
                      />
                      <Tooltip
                        formatter={(value, name, props) => {
                          return [numeral(value).format('0,0'), name];
                        }}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="positionDTO.quantityEnd"
                        name={'Position'}
                        stroke="darkred"
                        strokeWidth={3}
                        dot={props => {
                          const { payload } = props;
                          console.info(payload);

                          return payload.positionDTO.quantityEnd;
                        }}
                        onMouseEnter={this.handleArrowHover}
                        onMouseLeave={this.handleArrowHover}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div style={{ height: `30%`, width: '100%' }}>
                  <ResponsiveContainer height={'100%'} width={'100%'}>
                    <LineChart data={stockPriceData} syncId="anyId">
                      <XAxis dataKey="date" />
                      <YAxis
                        domain={([dataMin, dataMax]) => {
                          const absMax = Math.max(
                            Math.abs(dataMin),
                            Math.abs(dataMax)
                          );
                          return [-absMax, absMax];
                        }}
                      />
                      <Tooltip
                        formatter={(value, name, props) => {
                          return [numeral(value).format('0,0'), name];
                        }}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="positionDTO.itdPnlTotal"
                        stroke="#16ab39"
                        name={'PNL'}
                        strokeWidth={3}
                        dot={false}
                        onMouseEnter={this.handleArrowHover}
                        onMouseLeave={this.handleArrowHover}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              {this._createDataGrid()}
            </SplitPane>
          )}
        </div>
      </Modal>
    );
  }
}

export default OrderReviewDialog;
