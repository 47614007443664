import {
  LOAD_NOTIFICATION_LIST_SUCCESS,
  LOAD_NOTIFICATION_LIST_ERROR,
  DELETE_NOTIFICATION_SUCCESS,
  // DELETE_NOTIFICATION_ERROR,
  INIT_LOAD_NOTIFICATION_STATUS,
  ADD_NOTIFICATION_TO_LIST,
  REMINDER_ME_LATER,
  REMINDER_ME_LATER_ALL,
  OPEN_OR_CLOSE_NOTIFICATION_MODAL
} from './notificationConstants';
import client from './api/client';

export function remindMeLater(obj) {
  return {
    type: REMINDER_ME_LATER,
    payload: obj
  };
}

export function remindMeLaterAll() {
  return {
    type: REMINDER_ME_LATER_ALL
  };
}

export function initLoadNotificationStatus() {
  return {
    type: INIT_LOAD_NOTIFICATION_STATUS
  };
}

export function addNotificationToList(obj) {
  return {
    type: ADD_NOTIFICATION_TO_LIST,
    payload: obj
  };
}

export function openOrCloseNotificationModal(obj) {
  return {
    type: OPEN_OR_CLOSE_NOTIFICATION_MODAL,
    payload: obj
  };
}

function removeNotificationToListSuccess(result) {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: result
  };
}

// function removeNotificationToListError(err) {
//   return {
//     type: DELETE_NOTIFICATION_ERROR,
//     payload: err
//   };
// }

export function removeNotificationToList(params) {
  return function(dispatch) {
    client
      .deleteNotification(params)
      .then(resp => dispatch(removeNotificationToListSuccess(params)))
      .catch(err => dispatch(removeNotificationToListSuccess(params)));
  };
}

function loadNoticationListSuccess(result) {
  return {
    type: LOAD_NOTIFICATION_LIST_SUCCESS,
    payload: result
  };
}

function loadNoticationListError(err) {
  return {
    type: LOAD_NOTIFICATION_LIST_ERROR,
    payload: err
  };
}

export function loadNotificationList(params) {
  return function(dispatch) {
    dispatch(initLoadNotificationStatus());
    client
      .queryNotification(params)
      .then(resp => dispatch(loadNoticationListSuccess(resp)))
      .catch(err => dispatch(loadNoticationListError(err)));
  };
}
