import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { Popover } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import PercentBarComponent from '../../../../common/ui/renderers/agGrid/PercentBarComponent';
import TableTooltipComponent from '../../../../common/ui/renderers/agGrid/TableTooltipComponent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';

import './AlgoCxlBar.css';
import _ from 'lodash';
import { crossInfoGridColumns } from './GridColumnMap';

class CrossInfoBar extends Component {
  state = {
    defaultColDef: {
      enableCellChangeFlash: true,
      minWidth: 50,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true
    },
    frameworkComponents: {
      percentBarComponent: PercentBarComponent,
      tableTooltipComponent: TableTooltipComponent,
      tagComponent: TagComponent
    }
  };

  _createStatisticItem = crossInfos => {
    const length = _.isEmpty(crossInfos) ? 0 : crossInfos.length;

    const crossInfosGrid = this._createCrossInfoGrid(crossInfos);
    return (
      <Popover
        title="Cross Info (B/S)"
        content={crossInfosGrid}
        trigger="click"
      >
        <div>
          <span style={{ color: '#FF9E28' }}>Cross:</span>
          <span style={{ marginLeft: '5px' }}>{length}</span>
        </div>
      </Popover>
    );
  };

  gridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createCrossInfoGrid = crossInfos => {
    const data = crossInfos;
    return (
      <div
        style={{ width: '1000px', height: '500px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={crossInfoGridColumns}
          defaultColDef={this.state.defaultColDef}
          rowData={data}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={true}
          frameworkComponents={this.state.frameworkComponents}
          getRowNodeId={data => data.key}
          rowSelection="single"
          suppressCellSelection={true}
          onGridReady={this.gridReady}
        />
      </div>
    );
  };

  render() {
    const { crossInfos } = this.props;
    const statistic = this._createStatisticItem(crossInfos);

    return (
      <Segment
        inverted
        style={{
          marginBottom: '2px',
          textAlign: 'right',
          marginTop: '1px'
        }}
      >
        {statistic}
      </Segment>
    );
  }
}

export default CrossInfoBar;
