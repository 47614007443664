import React, { Component } from 'react';
import { Button, DatePicker } from 'antd';
import './StudentPaperAnalystTab.css';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { riskGridColumns } from './GridColumn';
import client from '../../api/client';

class StudentPaperAnalystTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      date: moment()
        .add(-2, 'days')
        .format('YYYY-MM-DD')
    };
  }

  onInputChange = date => {
    this.setState({
      date
    });
  };

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    client
      .getPaperRiskAnalysts(this.state.date)
      .then(resp => {
        this.setState({
          data: resp.data
        });
      })
      .catch(err => console.log(err));
  };

  _createOpBar = () => {
    const { date } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        <DatePicker
          value={moment(date, 'YYYY-MM-DD')}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onChange={(date, dateString) => this.onInputChange(dateString)}
        />
        <Button
          size="small"
          type="primary"
          style={{ marginLeft: '5px' }}
          onClick={this._loadData}
        >
          Reload
        </Button>
      </div>
    );
  };

  onManageGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createDataGrid = () => {
    const { data } = this.state;
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '90%' }}
      >
        <AgGridReact
          // properties
          columnDefs={riskGridColumns}
          rowData={data}
          defaultColDef={this.state.defaultColDef}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={true}
          getRowNodeId={data => data.id}
          rowGroupPanelShow="always"
          rowSelection="single"
          suppressCellSelection={true}
          onGridReady={this.onManageGridReady}
        />
      </div>
    );
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {this._createOpBar()}
        {this._createDataGrid()}
      </div>
    );
  }
}

export default StudentPaperAnalystTab;
