import React, { Component } from 'react';
import { Modal, Button, Form, Row, Col, Input } from 'antd';
import { Message } from 'semantic-ui-react';
// import client from 'features/order/api/client';
// import researchClient from 'features/research/api/client';
import _ from 'lodash';

class AddNoteDialog extends Component {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    this.state = {
      modelOptions: [],
      errors: [],
      fileList: [],
      gridWrapperStyle: {
        width: '1250px',
        height: '150px',
        marginTop: '5px',
        overflow: 'auto'
      }
    };
  }

  componentDidMount() {}

  _getValidateMap = () => {
    return {
      note: true
    };
  };

  _closeModal = () => {
    this.props.closePushVoteNoteModal({ key: 'op', value: 'CLOSE' });
  };

  _initConfig = () => {
    const { pushVOtesPMList } = this.props;
    const pmList = pushVOtesPMList;
    return { pmList };
  };

  onInputChange = ({ name, value }) => {
    const { data } = this.props.pushVoteNoteModal;
    const updateData = {
      ...data,
      [name]: value
    };
    this.props.changePushVoteNoteModalInput(updateData);
  };

  // _readOnlyMode = field => {
  //   const { op } = this.props.pushVoteNoteModal;
  //   const { canEditFiledsMap } = this.state;
  //   if (canEditFiledsMap[op]) {
  //     if (op === 'CLOSE') return !(canEditFiledsMap[op].indexOf(field) >= 0);
  //   }
  //   return op === 'DETAIL';
  // };

  _validateRule = fn => {
    const { op } = this.props.pushVoteNoteModal;
    let require = true;
    let rules = false;
    if (fn) {
      const display = fn();
      require = display === 'block' || display === true;
    }
    if (require) {
      rules = !['CLOSE', 'ADJUST'].includes(op);
    }
    return rules;
  };

  _validateClosingModel = () => {
    const { op } = this.props.pushVoteNoteModal;
    return op === 'CLOSE';
  };

  onSubmitBtnClicked = () => {
    this.saveData();
  };

  saveData = () => {
    const { data } = this.props.pushVoteNoteModal;
    const submitData = {
      ...data
    };
    const errors = this._validateData(submitData);
    this.setState({
      errors
    });
    if (!_.isEmpty(errors)) {
      return;
    }
    try {
      this.props.addPushVoteNote(submitData);
    } catch (err) {
      console.log(err);
    }
  };

  _validateData = data => {
    const errors = [];
    const validateMap = this._getValidateMap();
    _.map(validateMap, (value, key) => {
      if (value && !data[key]) errors.push(`${key} cannot be null`);
    });
    return errors;
  };

  _createForm = () => {
    const { data } = this.props.pushVoteNoteModal;
    return (
      <Form id="pushVoteNoteForm" onFinish={this.saveData} labelAlign="left">
        <style>
          {`
                #pushVoteNoteForm .ui.input {
                  width: 100%
                }
                    
                #pushVoteNoteForm .ant-tabs-bar {
                  margin: 0 0 8px 0;
                  color: black;
                }

                #pushVoteNoteForm .ant-tabs-nav .ant-tabs-tab-active {
                  /* background-color: #FF9E28; */
                  color: black;
                }

                #pushVoteNoteForm .anticon{
                  color: black;
                }

                #pushVoteNoteForm .ant-input[disabled]{
                  color: black;
                }

                #pushVoteNoteForm .ant-select-selector{
                  color: black;
                }

                #pushVoteNoteForm .ant-input-number-disabled{
                  color: black;
                }
            `}
        </style>
        <Row gutter={24}>
          <Col span={22}>
            <Form.Item
              label="Note"
              labelCol={{
                flex: '80px'
              }}
              required={this._validateRule()}
            >
              <Input.TextArea
                rows={6}
                // autoSize="true"
                value={data.note}
                onChange={e => {
                  this.onInputChange({
                    name: 'note',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  onCloseBtnClicked = () => {
    this.saveData('SUBMIT', 0);
  };

  render() {
    const { pushVoteNoteModal } = this.props;
    const { errors } = this.state;
    const { data } = pushVoteNoteModal;

    return (
      <Modal
        size="large"
        visible={true}
        title={`${data.tickerLong}(Long), ${data.tickerShort}(Short)`}
        width={600}
        style={{ height: 500, top: 20 }}
        footer={[
          <div key="submitData" style={{ textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                this._closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                this.onSubmitBtnClicked();
              }}
            >
              Add
            </Button>
          </div>
        ]}
        closable={false}
      >
        {this._createForm()}
        {!_.isEmpty(errors) && <Message error header="Errors" list={errors} />}
      </Modal>
    );
  }
}

export default AddNoteDialog;
