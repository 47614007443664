import React, { Component } from 'react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { Header } from 'semantic-ui-react';
import SplitPane from 'react-split-pane';
import { Tree } from 'antd';
import Order from '../entities/Order';

const ROOT_TREE_NODE_FUND = 'PINPOINT';

const orderSummaryConfig = {
  bookSummary: {
    field: 'bookCode',
    header: 'Book'
  },
  tradeDateSummary: {
    field: 'tradeDate',
    header: 'Date'
  },
  orderStatusSummary: {
    field: 'orderStatus',
    header: 'Status'
  },
  traderSummary: {
    field: 'traderCode',
    header: 'Trader'
  }
};

const NULL_MARKER = 'N.A.';

class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        filter: 'agTextColumnFilter',
        sortable: true,
        resizable: true
      },
      rowClassRules: {
        flash: params =>
          params.data &&
          (params.data.pendingCount > 0 || params.data.abnormalCount > 0)
      }
    };
  }

  _generateFundTreeData = funds => {
    return [
      {
        title: ROOT_TREE_NODE_FUND,
        key: ROOT_TREE_NODE_FUND,
        children: (funds || []).map(f => ({ key: f.name, title: f.name }))
      }
    ];
  };

  _onCheckFunds = checkedFundKeys => {
    this.props.selectFunds(
      checkedFundKeys.filter(f => f !== ROOT_TREE_NODE_FUND)
    );
  };

  _generateOrdersSummary = fieldName => {
    const { orders } = this.props;
    const summary = orders.reduce((acc, o) => {
      const groupKey = o[fieldName] === null ? NULL_MARKER : o[fieldName];
      const value = acc[groupKey];
      const isPendingOrder = o.traderStatus === 'PENDING';
      const isAbnormal = Order.isAbnormal(o);

      acc[groupKey] =
        value === undefined
          ? {
              pendingCount: isPendingOrder ? 1 : 0,
              abnormalCount: isAbnormal ? 1 : 0,
              totalCount: 1
            }
          : {
              pendingCount: isPendingOrder
                ? value.pendingCount + 1
                : value.pendingCount,
              abnormalCount: isAbnormal
                ? value.abnormalCount + 1
                : value.abnormalCount,
              totalCount: value.totalCount + 1
            };
      return acc;
    }, {});

    return _.sortBy(
      Object.keys(summary).map(k => {
        const { pendingCount, abnormalCount, totalCount } = summary[k];
        return {
          key: k,
          pendingCount,
          abnormalCount,
          count: `${pendingCount} / ${abnormalCount} / ${totalCount}`
        };
      }),
      ['key']
    );
  };

  _generateSummaryColumnDef = headerName => {
    return [
      {
        field: 'key',
        headerName,
        cellClass: 'keyword',
        width: 75,
        menuTabs: []
      },
      {
        field: 'count',
        headerName: '#',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        width: 75,
        menuTabs: []
      }
    ];
  };

  _onSelectionChanged = (key, event) => {
    const selectedRows = event.api.getSelectedRows();
    const config = orderSummaryConfig[key];
    const values = selectedRows.map(r =>
      r.key === NULL_MARKER ? null : r.key
    );

    this.props.changeFilter({
      field: config.field,
      values
    });
  };

  _createSummaryGrid = (summaryDatas, key, rowClassRules) => {
    const sd = summaryDatas.find(d => d.key === key);
    return (
      <div
        key={sd.key}
        style={{ width: '100%' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={sd.columnDefs}
          defaultColDef={this.state.defaultColDef}
          rowClassRules={rowClassRules}
          rowData={sd.rows}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={true}
          getRowNodeId={data => data.key}
          rowSelection="multiple"
          rowMultiSelectWithClick={true}
          onSelectionChanged={evt => this._onSelectionChanged(sd.key, evt)}
          suppressCellSelection={true}
        />
      </div>
    );
  };

  render() {
    const { funds } = this.props.settings;
    const { selectedFunds } = this.props.ui;
    const fundTreeData = this._generateFundTreeData(funds);

    const summaryDatas = Object.keys(orderSummaryConfig).map(k => {
      const config = orderSummaryConfig[k];
      const rows = this._generateOrdersSummary(config.field);
      const columnDefs = this._generateSummaryColumnDef(config.header);

      return {
        key: k,
        rows,
        columnDefs
      };
    });

    return (
      <div className="filterBar">
        <SplitPane split="horizontal" defaultSize="10%">
          <div style={{ width: '100%' }}>
            <Header inverted block>
              Funds
            </Header>
            <Tree
              checkable
              onCheck={this._onCheckFunds}
              defaultExpandAll={false}
              checkedKeys={selectedFunds}
              treeData={fundTreeData}
            />
          </div>

          <SplitPane split="horizontal" defaultSize="15%">
            {this._createSummaryGrid(
              summaryDatas,
              'tradeDateSummary',
              this.state.rowClassRules
            )}
            <SplitPane split="horizontal" defaultSize="25%">
              {this._createSummaryGrid(
                summaryDatas,
                'traderSummary',
                this.state.rowClassRules
              )}
              <SplitPane split="horizontal" defaultSize="25%">
                {this._createSummaryGrid(
                  summaryDatas,
                  'orderStatusSummary',
                  this.state.rowClassRules
                )}
                {this._createSummaryGrid(
                  summaryDatas,
                  'bookSummary',
                  this.state.rowClassRules
                )}
              </SplitPane>
            </SplitPane>
          </SplitPane>
        </SplitPane>
      </div>
    );
  }
}

export default FilterBar;
