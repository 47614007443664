import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Popover } from 'antd';
import _ from 'lodash';
import PercentBarComponent from 'common/ui/renderers/agGrid/PercentBarComponent';
import HyperlinkCompent from 'common/ui/renderers/agGrid/HyperlinkCompent';
import TagComponent from 'common/ui/renderers/agGrid/TagComponent';
import RatingBarComponent from 'common/ui/renderers/agGrid/RatingBarComponent';
import { pmLevelStockPitchColumns, companyScoreColumns } from './GridColumnMap';

export default class ResearchInfoToolTipComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.valueFormatted || props.value,
      data: props.data || {},
      tooltipField: props.tooltipField,
      tooltipTitle: props.tooltipTitle,
      tooltipColumnDefs: props.tooltipColumnDefs,
      tooltipWidth: props.tooltipWidth || '300px',
      tooltipHeight: props.tooltipHeight || '150px',
      tooltiGridHeight: props.tooltiGridHeight || '100px',
      recordOpenTime: props.recordOpenTime,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        filter: 'agTextColumnFilter',
        sortable: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number',
        width: 120
      },
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        hyperlinkCompent: HyperlinkCompent,
        tagComponent: TagComponent,
        ratingBarComponent: RatingBarComponent
      },
      openTime: 0
    };
  }

  _createGrid = (rows, settings) => {
    const { tooltipWidth, tooltiGridHeight } = this.state;
    return (
      <div
        style={{ width: tooltipWidth, height: tooltiGridHeight }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          {...settings}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={rows}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={false}
          groupDefaultExpanded={-1}
          rowGroupPanelShow="always"
          // getRowNodeId={data => data.key}
          rowSelection="single"
          suppressCellSelection={true}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  };

  _createStockPitchGrid = data => {
    return this._createGrid(data, { columnDefs: pmLevelStockPitchColumns });
  };

  _createCompanyScoreGrid = data => {
    return this._createGrid(data, { columnDefs: companyScoreColumns });
  };

  _createGrids = () => {
    const { tooltipHeight, tooltipWidth } = this.state;
    const { stockPitches = [], companyScores = [] } = this.state.data.rsrchInfo;
    return (
      <div
        style={{
          height: tooltipHeight,
          width: tooltipWidth,
          marginTop: '15px'
        }}
      >
        {this._createStockPitchGrid(stockPitches)}
        {this._createCompanyScoreGrid(companyScores)}
      </div>
    );
  };

  _getDifSideStockPitchNum = (data, ticker, quantityDirection) => {
    if (_.isEmpty(data)) return;
    const field = quantityDirection === 'LONG' ? 'tickerShort' : 'tickerLong';
    const result = data.filter(r => r[field] === ticker);
    result.forEach(r => {
      r.difs = 1;
    });
    return result.length;
  };

  _getDifSideCompanyScoreNum = (data, quantityDirection) => {
    if (_.isEmpty(data)) return;
    const itemSide = quantityDirection === 'LONG' ? 'Short' : 'Long';
    const result = data.filter(r => {
      if (['Long', 'Short'].includes(r.longInvestmentConclusion)) {
        return itemSide === r.longInvestmentConclusion;
      } else {
        return (
          ['Long', 'Short'].includes(r.shortInvestmentConclusion) &&
          itemSide === r.shortInvestmentConclusion
        );
      }
    });
    result.forEach(r => {
      r.difc = 1;
    });
    return result.length;
  };

  render() {
    const { tooltipTitle, value, data, tooltipField } = this.state;
    const rsch = (data || {})[tooltipField] || {};

    if (_.isEmpty(rsch)) return <span>{value}</span>;
    const { stockPitches = [], companyScores = [] } = rsch;
    if (_.isEmpty(stockPitches) && _.isEmpty(companyScores))
      return <span>N.A.</span>;
    const { ticker, quantityDirection } = data || {};
    const companyScoreNums = _.isEmpty(companyScores)
      ? null
      : `C:${companyScores.length}`;
    const pmLevelStockPitchNums = _.isEmpty(stockPitches)
      ? null
      : `S:${stockPitches.length}`;
    let difStockPitchLength = this._getDifSideStockPitchNum(
      stockPitches,
      ticker,
      quantityDirection
    );
    difStockPitchLength = difStockPitchLength ? (
      <span style={{ color: 'red' }}>({difStockPitchLength})</span>
    ) : null;
    let difCompanyScoreLength = this._getDifSideCompanyScoreNum(
      stockPitches,
      quantityDirection
    );
    difCompanyScoreLength = difCompanyScoreLength ? (
      <span style={{ color: 'red' }}>({difCompanyScoreLength})</span>
    ) : null;
    const researchArr = [
      pmLevelStockPitchNums,
      difStockPitchLength,
      companyScoreNums,
      difCompanyScoreLength
    ].filter(r => r);
    // const showValue = _.isEmpty(researchArr) ? null: researchArr.join(',');
    const tabs = this._createGrids();
    return (
      <Popover
        title={tooltipTitle}
        content={tabs}
        onVisibleChange={this.onOpenChange}
      >
        {researchArr.map((r, index) => {
          if ((index + 1) % 2 === 0 && index !== researchArr.length - 1)
            return <span>{r},</span>;
          return r;
        })}
      </Popover>
    );
  }

  refresh(params) {
    this.setState({
      value: params.valueFormatted || params.value,
      data: params.data
    });
    return true;
  }
}
