import React, { Component } from 'react';
import { Tabs } from 'antd';
import ExpBorrowGrid from './ExpBorrowGrid';
import ShortListTab from 'features/research/components/short/ShortListTab';
import GcRateGrid from './GcRateGrid';

const TabPane = Tabs.TabPane;
class StockLoanPannel extends Component {
  render() {
    return (
      <div className="treasuryDashboardWrapper">
        <Tabs defaultActiveKey="5" tabPosition={'top'}>
          <TabPane tab="GC Rate" key="1">
            <GcRateGrid {...this.props} />
          </TabPane>
          <TabPane tab="Exp Borrow" key="2">
            <ExpBorrowGrid {...this.props} />
          </TabPane>
          {/* {/* <TabPane tab="Margin" key="1">
            <MaiginReportGrid {...this.props} />
          </TabPane> */}
          <TabPane tab="Short List" key="3">
            <ShortListTab {...this.props} heightOffset={210} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default StockLoanPannel;
