import React, { Component } from 'react';
import { Header, Modal, Button, Grid } from 'semantic-ui-react';
import { Tree, Input } from 'antd';

import EntityMap from 'entities/EntityMap';

const TreeNode = Tree.TreeNode;
const Search = Input.Search;

class HelpDialog extends Component {
  state = {
    expandedKeys: [],
    searchValue: '',
    autoExpandParent: true,
    selectedField: null
  };

  _onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  _onChange = e => {
    const { fieldsByCategory } = this.props.settings;

    const value = e.target.value;
    const expandedKeys = Object.keys(fieldsByCategory).filter(category => {
      return Object.values(fieldsByCategory[category].byKey).some(
        f => f.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    });

    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true
    });
  };

  _onSelect = (selectedKeys, info) => {
    const { fieldsByCategory } = this.props.settings;

    if (selectedKeys.length > 0) {
      const key = selectedKeys[0];
      const field = Object.values(fieldsByCategory)
        .map(fieldsEntity => EntityMap.get(fieldsEntity, key, null))
        .filter(f => f)
        .find(() => true);

      if (field) {
        this.setState({
          selectedField: field
        });
      }
    }
  };

  _generateLeafNodes = fields => {
    const { searchValue } = this.state;

    return fields
      .map(f => {
        const index = f.name.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = f.name.substring(0, index);
        const afterStr = f.name.substring(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: '#f50' }}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{f.name}</span>
          );

        return searchValue && index < 0 ? null : (
          <TreeNode key={f.field} title={title} />
        );
      })
      .filter(n => n);
  };

  render() {
    const { settings, resetHelpDialog } = this.props;
    const { fieldsByCategory, helpModal } = settings;

    const { expandedKeys, autoExpandParent, selectedField } = this.state;

    const treeNodes = Object.entries(fieldsByCategory).map(
      ([category, fieldsEntity]) => {
        return (
          <TreeNode key={category} title={category}>
            {this._generateLeafNodes(Object.values(fieldsEntity.byKey))}
          </TreeNode>
        );
      }
    );

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }
            .ui.basic.label {
              width: 55px;
            }
            .ui.modal .six {
              height: 350px;
            }
            .ui.modal .six {
              overflow-y: scroll;
            }
          `}
        </style>
        <Modal size="small" open={helpModal.isOpened}>
          <Header icon="question circle" content="Help Document" />
          <Modal.Content>
            <Grid>
              <Grid.Column width={6}>
                <Search
                  placeholder="Search"
                  onChange={this._onChange}
                  size="large"
                />
                <Tree
                  onSelect={this._onSelect}
                  onExpand={this._onExpand}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                >
                  {treeNodes}
                </Tree>
              </Grid.Column>

              <Grid.Column width={10}>
                {selectedField && (
                  <Modal.Description>
                    <Header as="h3" textAlign="center" dividing>
                      {selectedField.name}
                    </Header>

                    <div>
                      <p>{selectedField.desc}</p>
                    </div>

                    <br />
                    <br />

                    <div>
                      <Header as="h4" dividing>
                        Formula
                      </Header>
                      <p>{selectedField.formula || selectedField.name}</p>
                    </div>
                  </Modal.Description>
                )}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary content="OK" onClick={resetHelpDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default HelpDialog;
