import React, { Component } from 'react';
import {
  Modal,
  Button,
  Spin,
  Badge,
  Input,
  Tooltip,
  DatePicker,
  Switch
} from 'antd';
import { Message } from 'semantic-ui-react';
import {
  DIALOG_EXECUTE_IPO_ROUTES,
  DIALOG_EXECUTE_ROUTES_MODE
} from '../../omsConstants';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import client from '../../api/client';
import orderClient from 'features/order/api/client';
import _ from 'lodash';
import Route from '../../entities/Route';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import RouteValidator, {
  HARD_RULE,
  SOFT_RULE
} from 'features/oms/entities/validators/RouteValidator';
import { routeIPOGridColumns } from './GridColumnMap';
import hyperid from 'hyperid';
import moment from 'moment';
import Trade from 'features/order/entities/Trade';

const uniqidInstance = hyperid();
const routeGridColumns = routeIPOGridColumns;
class ExecuteRoutesIPODialog extends Component {
  venueSvcInfos = [];
  holdingsByTicker = {};
  options = {
    brokerMap: {},
    custodianMap: {}
  };

  state = {
    isInitialized: false,
    holdings: [],
    routes: [],
    selectedRoute: null,
    shares: null,
    price: null,
    lotsize: null,
    usdfxrate: 0,
    tradeDate: moment().format('YYYY-MM-DD'),

    submitStatus: 'READY',
    calculating: false,
    showAlgoParamsDialog: false,

    // summaryData: null,
    routesGridSettings: null,
    routesGridWrapperStyle: {
      width: '100%',
      height: '390px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    useOmsSrv: this.props.ui.useOmsSrv
  };

  constructor(props) {
    super(props);

    this.hotTblRef = React.createRef();
  }

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { routes, selectedRoute } = this.state;
    if (prevState.routes !== routes) {
      const route = routes.find(r => r.key === (selectedRoute || {}).key);
      this._setSelectedRoute(route);
    }
  }

  _cloneRoute = () => {
    const { routes, selectedRoute } = this.state;
    const key = `${selectedRoute.key}_${uniqidInstance()}`;

    const { leftQuantity = 0 } = selectedRoute.order || {};
    const aggRoutesQty = _.sumBy(
      routes.filter(r => r.orderRefId === selectedRoute.orderRefId),
      r => r.qty
    );

    const qty = Math.max(leftQuantity - (aggRoutesQty || 0), 0);

    const clonedRoute = {
      ...selectedRoute,
      key,
      qty
    };

    const row = routes.findIndex(r => r.key === selectedRoute.key);
    const updatedRoutes = this._validateRoutes([
      ...routes.slice(0, row + 1),
      clonedRoute,
      ...routes.slice(row + 1)
    ]);

    this.setState({ routes: updatedRoutes });
  };

  _createRoutesGridSettings = (brokerCodes, custodianCodes, reasonCodes) => {
    const optionsMapping = {
      broker: brokerCodes,
      custodian: custodianCodes,
      notes: ['', ...reasonCodes]
    };
    const columns = routeGridColumns.map(c => {
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu: {
        items: {
          remove_row: {},
          algo_params: {
            name: 'Update algo params',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this.setState({ showAlgoParamsDialog: true }), 100);
            }
          },
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneRoute(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _calRouteItemOtherProperties = item => {
    item['filledValue'] = Math.ceil(
      (item['filled'] / item['qty']) * item['order']['qtyUsd']
    );
    if (item['qty'] === 0) {
      item['filledPer'] = 0;
      item['filledValue'] = 0;
    } else {
      item['filledPer'] = item['filled'] / item['qty'];
    }
  };

  _createRoutes = (order, routeInfos, i, ctx) => {
    const {
      info: { mode }
    } = this.props;
    if (mode === DIALOG_EXECUTE_ROUTES_MODE.NEW) {
      return routeInfos.map((r, j) => {
        const data = {
          ...Route.emptyForm(order, r, ctx),
          key: `${i}-${j}`,
          algoCode: order.algoCode,
          algoParams: order.algoParams
        };
        data['handlingInstruction'] = 'HT';
        data['venue'] = 'MANUAL';
        data['notes'] = 'IPO';
        this._calRouteItemOtherProperties(data);
        return data;
      });
    } else if (mode === DIALOG_EXECUTE_ROUTES_MODE.UPDATE) {
      const { selectedRoutes } = this.props;
      return selectedRoutes
        .filter(
          r =>
            r.orderRefId === order.refId &&
            r.fundCode === order.fundCode &&
            r.bookCode === order.bookCode
        )
        .map(r => {
          const data = Route.toForm(r, order, ctx);
          this._calRouteItemOtherProperties(data);
          return data;
        });
    } else if (mode === DIALOG_EXECUTE_ROUTES_MODE.EXECUTE) {
      const { routes: existingRoutes } = this.props;
      return existingRoutes
        .filter(
          r =>
            r.orderRefId === order.refId &&
            r.status === 'PENDING' &&
            r.venue !== 'MANUAL' &&
            r.fundCode === order.fundCode &&
            r.bookCode === order.bookCode
        )
        .map(r => {
          const data = Route.toForm(r, order, ctx);
          this._calRouteItemOtherProperties(data);
          return data;
        });
    }

    return [];
  };

  _init = () => {
    let { selectedOrders } = this.props;
    if (_.isEmpty(selectedOrders)) return;
    selectedOrders = selectedOrders.filter(r => r.qtyUsd > 0);
    if (_.isEmpty(selectedOrders)) return;
    const ticker = selectedOrders[0]['ticker'];
    selectedOrders = selectedOrders.filter(r => r.ticker === ticker);
    selectedOrders = selectedOrders.map(item => {
      const qtyPct = _.round(item.qtyPct * 100, 3);
      return {
        ...item,
        qtyPct
      };
    });
    const splitOrderList = selectedOrders.map(item => {
      const orderRefId = item['refId'];
      return {
        orderRefId,
        ticker,
        useOmsSrv: this.state.useOmsSrv
      };
    });
    Promise.all([
      client.getExecutionOptions(),
      orderClient.getSecurities([{ ticker: ticker }], { type: 'IPO' }),
      client.splitOrders(splitOrderList)
    ])
      .then(
        ([
          { custodians, brokers, algoMapping, venueSvcInfos, reasons },
          data,
          orderList
        ]) => {
          let subOrderList = [];
          orderList.forEach(item => {
            const subOrders = item['subOrders'];
            if (!_.isEmpty(subOrders)) {
              subOrders.forEach(detail => {
                const filterOrder = selectedOrders.filter(
                  r => r.refId === detail['orderRefId']
                );
                detail['refId'] = detail['orderRefId'];
                detail['fundCode'] = detail['fund'];
                detail['bookCode'] = detail['book'];
                if (!_.isEmpty(filterOrder)) {
                  detail['algoCode'] = filterOrder[0]['algoCode'];
                  detail['algoParams'] = filterOrder[0]['algoParams'];
                  detail['traderCode'] = filterOrder[0]['traderCode'];
                }
              });
              subOrderList = subOrderList.concat(subOrders);
            }
          });
          this._initRoutes(
            { custodians, brokers, algoMapping, venueSvcInfos, reasons },
            data,
            subOrderList
          );
        }
      )
      .catch(ex => {
        console.log(ex);
        this.setState({
          submitStatus: 'ERROR',
          isInitialized: true,
          routes: []
        });
      });
  };

  _initRoutes = (
    { custodians, brokers, algoMapping, venueSvcInfos, reasons },
    data,
    orderList
  ) => {
    let usdfxrate = 0;
    if (data && data.length > 0) {
      data.forEach(item => {
        if (item) {
          usdfxrate = item['usdFxRate'];
        }
      });
    }
    const ctxs = this._getCtxs(orderList);
    const brokerMap = _.keyBy(brokers, b => b.brokerCode);
    const custodianMap = _.keyBy(custodians, c => c.custodianCode);

    // Init some datas into this.
    this.venueSvcInfos = venueSvcInfos;
    this.options = {
      custodianMap,
      brokerMap
    };

    const routes = this._validateRoutes(
      _.zip(orderList, ctxs).flatMap(
        ([order, { orderInfo, routeInfos }], i) => {
          return this._createRoutes({ ...order, ...orderInfo }, routeInfos, i, {
            brokerMap,
            algoMapping
          });
        }
      )
    );

    const routesGridSettings = this._createRoutesGridSettings(
      brokers.map(b => b.brokerCode),
      custodians.map(c => c.custodianCode),
      reasons.map(r => r.reasonCode)
    );

    _.delay(() => {
      this.setState({
        isInitialized: true,
        routes,
        routesGridSettings,
        usdfxrate
      });
    }, 150);
  };

  _getCtxs = data => {
    const ctxs = data.map(item => {
      const routeInfos = [
        {
          algoCode: item['algoCode'],
          bookCode: item['bookCode'] ? item['bookCode'] : item['book'],
          broker: null,
          cfdType: null,
          custodian: null,
          fundCode: item['fundCode'] ? item['fundCode'] : item['fund'],
          orderType: item['orderType'],
          qty: 0,
          filled: 0,
          settlementCcy: null,
          side: item['side'],
          ticker: item['ticker'],
          venue: 'EMSX',
          notes: 'IPO'
        }
      ];
      return { routeInfos: routeInfos };
    });
    return ctxs;
  };

  _validateRoutes = routes => {
    const {
      venueSvcInfos,
      options: { brokerMap, custodianMap },
      holdingsByTicker
    } = this;

    const {
      settings: { user }
    } = this.props;

    return routes.map(route => {
      const {
        order: { ticker }
      } = route;
      const holdings = holdingsByTicker[ticker] || [];

      const validateConfig = {
        errors: { ruleType: HARD_RULE, user },
        warnings: {
          ruleType: SOFT_RULE,
          venueSvcInfos,
          brokerMap,
          custodianMap,
          user,
          holdings,
          routes
        }
      };

      return Object.entries(validateConfig).reduce((prev, [k, ctx]) => {
        const validationResult = RouteValidator.validate(route, ctx);
        const hasValidationResultKey = _.camelCase(`has_${k}`);
        return {
          ...prev,
          [k]: validationResult,
          [hasValidationResultKey]: !_.isEmpty(validationResult)
        };
      }, route);
    });
  };

  _calcRoute = (route, name, value) => {
    return {
      ...route,
      [name]: value
    };
  };

  _handleChanges = (changes, holdingsByRouteKey = {}) => {
    const { routes } = this.state;

    const updatedRoutes = this._validateRoutes(
      changes.reduce((prev, c) => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return prev;
        return prev.map((r, i) => {
          if (i === row) {
            const data = this._calcRoute(r, field, newValue);
            this._calRouteItemOtherProperties(data);
            return data;
          }
          return r;
        });
      }, routes)
    );

    this.setState({
      routes: updatedRoutes
    });

    // Calc execution infos.
    //this._calcExecutionInfos(updatedRoutes, changes, holdingsByRouteKey);
  };

  _beforeCellChange = (changes, source) => {
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(realChanges);

    return false;
  };

  _afterLoadRoutes = initialLoad => {
    // console.log("HotTableRef_afterLoadRoutes", initialLoad, this.hotTblRef.current);
    if (!this.hotTblRef.current) return;

    const { routes, selectedRoute } = this.state;
    if (selectedRoute) return;
    if (_.isEmpty(routes)) return;

    const hotTbl = this.hotTblRef.current.hotInstance || {};
    hotTbl.selectCell(0, 'broker');
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    let { routes } = this.state;
    routes = this._validateRoutes(routes.filter((_, i) => !rows.includes(i)));

    this.setState({ routes });

    return false;
  };

  _beforeColumnSort = (currSortConfigs, destSortConfigs) => {
    const columnSortPlugin = (
      this.hotTblRef.current.hotInstance || {}
    ).getPlugin('columnSorting');
    columnSortPlugin.setSortConfig(destSortConfigs);

    if (_.isEmpty(destSortConfigs)) return false;
    const firstSortConfig = _.first(destSortConfigs);
    if (!firstSortConfig) return false;

    const { routes } = this.state;
    const { column, sortOrder } = firstSortConfig;
    const field = (routeGridColumns[column] || {}).data;
    const sortedRoutes = _.orderBy(routes, [field], [sortOrder]);

    this.setState({ routes: sortedRoutes });

    return false;
  };

  _onRouteSelectionChanged = row => {
    const { routes, selectedRoute } = this.state;
    const route = routes[row];

    if (route === selectedRoute) return;
    this._setSelectedRoute(route);
  };

  _setSelectedRoute = route => {
    const { holdingsByTicker } = this;

    if (!route) {
      this.setState({
        selectedRoute: null,
        holdings: []
      });
      return;
    }

    const {
      fundCode,
      bookCode,
      order: { ticker }
    } = route;

    // Sort the holdings and if has matched fund/book, would be the first one.
    const _sortInteratee = h => {
      if (h.fundCode === fundCode && h.bookCode === bookCode) {
        return 1;
      } else if (h.fundCode === fundCode) {
        return 2;
      } else {
        return 3;
      }
    };

    const holdings = _.sortBy(holdingsByTicker[ticker] || [], [_sortInteratee]);

    this.setState({
      selectedRoute: route,
      holdings
    });
  };

  _createRoutesGrid = () => {
    const { routesGridSettings, routesGridWrapperStyle, routes } = this.state;

    const {
      options: { brokerMap }
    } = this;

    const cells = (row, col, field) => {
      if (field === 'isDirectLine') {
        // console.log("HotTableRef_cells", this.hotTblRef.current);
        const r = routes[row] || {};

        const readOnly = Route.isDirectLineReadOnly(r, brokerMap);
        return { readOnly };
      }

      return {};
    };

    return (
      !_.isEmpty(routes) && (
        <div style={routesGridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={routes}
            beforeChange={this._beforeCellChange}
            afterLoadData={this._afterLoadRoutes}
            beforeRemoveRow={this._beforeRemoveRow}
            afterSelectionEnd={this._onRouteSelectionChanged}
            beforeColumnSort={this._beforeColumnSort}
            manualColumnResize={true}
            cells={cells}
            {...routesGridSettings}
          ></HotTable>
        </div>
      )
    );
  };

  _onHoldingsGridReady = params => {
    this.holdingsGridApi = params.api;

    const COLUMNS_KEY = 'routes-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onSubmit = () => {
    const { submitDialogSuccess, info, selectedOrders } = this.props;
    const { routes, tradeDate } = this.state;

    if (_.isEmpty(routes)) return;

    const validatedRoutes = this._validateRoutes(routes);
    if (validatedRoutes.some(r => !_.isEmpty(r.errors))) {
      this.setState({
        routes: validatedRoutes
      });
      return;
    }

    let routeModels = routes
      .map(r => {
        const data = {
          ...r,
          tradeDate
        };
        return Route.toModel(data);
      })
      .filter(r => r.qty > 0);
    const orders = [],
      orderMap = {};
    routes.forEach(r => {
      const order = r.order;
      const orderRefId = order['refId'] ? order['refId'] : order['orderRefId'];
      const orderInMap = orderMap[orderRefId];
      const orgOrder = selectedOrders.find(ele => ele.refId === orderRefId);
      const orderUpdate = {
        ...orgOrder,
        quantity: r['qty'],
        tradeDate: tradeDate
      };
      if (orderInMap) {
        orderInMap['quantity'] += orderUpdate['quantity'];
      } else {
        orderMap[orderRefId] = orderUpdate;
      }
    });
    for (let key in orderMap) {
      const order = orderMap[key];
      const qty = this._getQtyNotSelected(order.refId);
      order['quantity'] += qty;
      const orderModel = Trade.toModel(order, {}, false);
      orders.push(orderModel);
    }

    this.setState({ submitStatus: 'SUBMITTING' });

    Promise.all([client.submitRoutes(routeModels), client.updateOrders(orders)])
      .then(([results, returnCode]) => {
        routeModels = _.zip(routeModels, results).map(([r, { refId }]) => ({
          ...r,
          refId
        }));

        this.setState({ submitStatus: 'READY' });
        submitDialogSuccess(DIALOG_EXECUTE_IPO_ROUTES, {
          routes: routeModels,
          ...info
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _getQtyNotSelected = orderRefId => {
    const { routes, selectedRoutes } = this.props;
    const orderRoutes = routes.filter(
      r => r.orderRefId === orderRefId && r.status !== 'CANCEL'
    );
    const orderSelectedRoutes = selectedRoutes.filter(
      r => r.orderRefId === orderRefId
    );
    let orderQty = 0,
      orderSelectedQty = 0;
    orderRoutes.forEach(item => (orderQty += item['qty']));
    if (orderSelectedRoutes)
      orderSelectedRoutes.forEach(item => (orderSelectedQty += item['qty']));
    return orderQty - orderSelectedQty;
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, routes = [] } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    const count = routes.reduce(
      (prev, r) => prev + _.size(r.errors) + _.size(r.warnings),
      0
    );
    return (
      <Badge key="submitBadge" count={count} style={{ marginRight: '10px' }}>
        {submitBtn}
      </Badge>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_EXECUTE_IPO_ROUTES);
  };

  _createRoutesSummaryPanel = () => {
    const { routes, isCalculating } = this.state;
    if (!routes) return <></>;

    const errorCount = routes.filter(r => !_.isEmpty(r.errors)).length;
    const warningCount = routes.filter(r => !_.isEmpty(r.warnings)).length;

    return (
      <div className="summary">
        Totally
        <span className="keyword">{` ${routes.length} `}</span>
        routes,
        <span className="error-keyword">{` ${errorCount} `}</span>
        contain errors,
        <span className="warning-keyword">{` ${warningCount} `}</span>
        contain warnings.
        {isCalculating && <Spin size="small" />}
      </div>
    );
  };

  _createOrderSummaryPanel = () => {
    const { selectedRoute } = this.state;
    if (!selectedRoute) return <></>;

    const {
      fundCode,
      bookCode,
      ticker,
      cfdType,
      settlementCcy,
      side,
      qty,
      broker
    } = selectedRoute;
    const fullTicker = [ticker, cfdType, settlementCcy]
      .filter(Boolean)
      .join(' ');

    const sideStyle = ['BUY', 'COVR'].includes(side) ? 'long' : 'short';

    return (
      <div className="summary">
        <span className="comment">{`${fundCode}-${bookCode} `}</span>
        <span className={sideStyle}>{`${side} `}</span>
        <span className="keyword">{`${fullTicker} `}</span>
        with qty
        <span className={sideStyle}>{` ${qty || 0} `}</span>
        through
        <span className="comment">{` ${broker || 'NULL BROKER'}`}</span>
      </div>
    );
  };

  _createInputPannel = () => {
    const { shares, price, tradeDate, lotsize, usdfxrate } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        <Tooltip
          trigger={['focus']}
          title="input usdfxrate"
          placement="topLeft"
          overlayClassName="numeric-input"
        >
          <Input
            placeholder="usdfxrate"
            value={usdfxrate}
            style={{ width: '200px', marginLeft: '5px' }}
            onChange={e => {
              const value = e.target.value;
              this.onInputChange({ name: 'usdfxrate', value });
            }}
          />
        </Tooltip>
        <Input
          placeholder="shares"
          value={shares}
          style={{ width: '200px', marginLeft: '5px' }}
          onChange={e => {
            const value = e.target.value;
            this.onInputChange({ name: 'shares', value });
          }}
        />
        <Input
          placeholder="lotsize"
          value={lotsize}
          style={{ width: '200px', marginLeft: '5px' }}
          onChange={e => {
            const value = e.target.value;
            this.onInputChange({ name: 'lotsize', value });
          }}
        />
        <Input
          placeholder="price"
          value={price}
          style={{ width: '200px', marginLeft: '5px' }}
          onChange={e => {
            const value = e.target.value;
            this.onInputChange({ name: 'price', value });
          }}
        />
        <DatePicker
          value={moment(tradeDate, 'YYYY-MM-DD')}
          format="YYYY-MM-DD"
          style={{ width: '200px', marginLeft: '5px' }}
          onChange={(date, dateString) =>
            this.onInputChange({
              name: 'tradeDate',
              value: dateString
            })
          }
        />
        <Button
          key="calc"
          type="primary"
          onClick={this._calWeightQty}
          style={{ marginLeft: '10px' }}
        >
          Calc
        </Button>
      </div>
    );
  };

  onInputChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  };

  _calWeightQty = () => {
    const { shares, price, tradeDate, lotsize, routes, usdfxrate } = this.state;
    let updateData = routes,
      total = 0;
    const avgPrice = price;
    updateData = updateData.map(item => {
      const qtyUsd = item['order']['qtyUsd'];
      const qty = Math.floor(qtyUsd / (price * usdfxrate) / lotsize) * lotsize;
      total += qty;
      const order = {
        ...item.order,
        tradeDate
      };
      return {
        ...item,
        qty,
        order
      };
    });
    let totalFilled = 0;
    updateData = updateData.map((item, index) => {
      const qty = item['qty'];
      let filled = Math.floor((qty / total / lotsize) * shares) * lotsize;
      if (index === updateData.length - 1) {
        filled = shares - totalFilled;
      } else {
        totalFilled += filled;
      }
      const data = {
        ...item,
        filled,
        avgPrice,
        tradeDate
      };
      this._calRouteItemOtherProperties(data);
      return data;
    });
    const validatedRoutes = this._validateRoutes(updateData);
    this.setState({
      routes: validatedRoutes
    });
  };

  _createErrorsPanel = () => {
    const { selectedRoute } = this.state;
    const { errors = {}, warnings = {} } = selectedRoute || {};

    const errorMsgs = Object.values(errors);
    const warningMsgs = Object.values(warnings);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
        {!_.isEmpty(warningMsgs) && (
          <Message warning list={warningMsgs} style={{ marginTop: '3px' }} />
        )}
      </div>
    );
  };

  onSwitchChange = () => {
    this.setState(
      {
        useOmsSrv: !this.state.useOmsSrv,
        isInitialized: false
      },
      () => {
        _.delay(this._init, 500);
      }
    );
  };

  _createSwitchSrv = () => {
    const { useOmsSrv } = this.state;
    return (
      <div style={{ textAlign: 'right', float: 'right' }}>
        <Switch
          checked={useOmsSrv}
          checkedChildren="NEW"
          unCheckedChildren="OLD"
          onChange={this.onSwitchChange}
        ></Switch>
      </div>
    );
  };

  render() {
    const { isInitialized } = this.state;

    const {
      info: { mode }
    } = this.props;
    const modalTitle = `${mode} IPO Routes`;

    return (
      <Modal
        width={1800}
        maskClosable={false}
        title={modalTitle}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button
            key="close"
            type="primary"
            onClick={this.closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={!isInitialized}>
          {/*{this._createSwitchSrv()}*/}
          {this._createRoutesSummaryPanel()}
          {this._createOrderSummaryPanel()}
          {this._createInputPannel()}
          {this._createRoutesGrid()}

          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default ExecuteRoutesIPODialog;
