import {
  LOAD_REPORT_LIST_SUCCESS,
  LOAD_REPORT_LIST_FAILURE,
  LOAD_REPORT_ATTRIBUTION_SUCCESS,
  LOAD_REPORT_ATTRIBUTION_FAILURE,
  CHANGE_RISK_QUERY_PARAMS_INPUT,
  LOAD_FACTOR_ASSET_SUCCESS,
  LOAD_FACTOR_ASSET_FAILURE,
  LOAD_RETURN_REPORT_LIST_SUCCESS,
  LOAD_RETURN_REPORT_LIST_FAILURE,
  CHANGE_RETURN_REPORT_ATTRIBUTION,
  CHANGE_RETURN_QUERY_PARAMS_INPUT,
  LOAD_RETURN_FACTOR_ASSET_SUCCESS,
  LOAD_RETURN_FACTOR_ASSET_FAILURE,
  CHANGE_RISK_SELECTED_ITEM,
  CHANGE_RETURN_SELECTED_ITEM,
  INIT_RISK_REPORT_LOAD_STATUS,
  INIT_RISK_FACTOR_LOAD_STATUS,
  INIT_RISK_ASSET_LOAD_STATUS,
  INIT_RETURN_REPORT_LOAD_STATUS,
  INIT_RETURN_FACTOR_LOAD_STATUS,
  INIT_RETURN_ASSET_LOAD_STATUS
} from './riskConstants';

import client from './api/client';

export function loadReportList(params) {
  return function(dispatch) {
    dispatch(initiateRiskReportLoaderStatus());
    client
      .getReportList(params)
      .then(resp => dispatch(loadReportListSuccess(resp)))
      .catch(err => dispatch(loadReportListFailure(err)));
  };
}

function loadReportListSuccess(resp) {
  return {
    type: LOAD_REPORT_LIST_SUCCESS,
    payload: resp
  };
}

function loadReportListFailure(err) {
  return {
    type: LOAD_REPORT_LIST_FAILURE,
    payload: err
  };
}

export function loadReportAttribution(params) {
  return function(dispatch) {
    dispatch(initiateRiskFactorLoaderStatus());
    client
      .getAttributionReport(params)
      .then(resp => dispatch(loadReportAttributionSuccess(resp)))
      .catch(err => dispatch(loadReportAttributionFailure(err)));
  };
}

function loadReportAttributionSuccess(resp) {
  return {
    type: LOAD_REPORT_ATTRIBUTION_SUCCESS,
    payload: resp
  };
}

function loadReportAttributionFailure(err) {
  return {
    type: LOAD_REPORT_ATTRIBUTION_FAILURE,
    payload: err
  };
}

export function changeRiskQueryParamsInput(input) {
  return {
    type: CHANGE_RISK_QUERY_PARAMS_INPUT,
    payload: input
  };
}

export function changeRiskSelectItemInput(input) {
  return {
    type: CHANGE_RISK_SELECTED_ITEM,
    payload: input
  };
}

export function getFactorAsset(params) {
  return function(dispatch) {
    dispatch(initiateRiskAssetLoaderStatus());
    client
      .getFactorAsset(params)
      .then(resp => dispatch(getFactorAssetSuccess(resp)))
      .catch(err => dispatch(getFactorAssetFailure(err)));
  };
}

function getFactorAssetSuccess(resp) {
  return {
    type: LOAD_FACTOR_ASSET_SUCCESS,
    payload: resp
  };
}

function getFactorAssetFailure(err) {
  return {
    type: LOAD_FACTOR_ASSET_FAILURE,
    payload: err
  };
}

export function loadReturnReportList(params) {
  return function(dispatch) {
    dispatch(initiateReturnReportLoaderStatus());
    client
      .getReportList(params)
      .then(resp => dispatch(loadReturnReportListSuccess(resp)))
      .catch(err => dispatch(loadReturnReportListFailure(err)));
  };
}

function loadReturnReportListSuccess(resp) {
  return {
    type: LOAD_RETURN_REPORT_LIST_SUCCESS,
    payload: resp
  };
}

function loadReturnReportListFailure(err) {
  return {
    type: LOAD_RETURN_REPORT_LIST_FAILURE,
    payload: err
  };
}

export function changeReturnReportAttribution(data) {
  return {
    type: CHANGE_RETURN_REPORT_ATTRIBUTION,
    payload: data
  };
}

export function changeReturnQueryParamsInput(input) {
  return {
    type: CHANGE_RETURN_QUERY_PARAMS_INPUT,
    payload: input
  };
}

export function getReturnFactorAsset(params) {
  return function(dispatch) {
    dispatch(initiateReturnAssetLoaderStatus());
    client
      .getReturnFactorAsset(params)
      .then(resp => dispatch(getReturnFactorAssetSuccess(resp)))
      .catch(err => dispatch(getReturnFactorAssetFailure(err)));
  };
}

function getReturnFactorAssetSuccess(resp) {
  return {
    type: LOAD_RETURN_FACTOR_ASSET_SUCCESS,
    payload: resp
  };
}

function getReturnFactorAssetFailure(err) {
  return {
    type: LOAD_RETURN_FACTOR_ASSET_FAILURE,
    payload: err
  };
}

export function changeReturnSelectItemInput(input) {
  return {
    type: CHANGE_RETURN_SELECTED_ITEM,
    payload: input
  };
}

export function initiateRiskReportLoaderStatus() {
  return {
    type: INIT_RISK_REPORT_LOAD_STATUS
  };
}

export function initiateRiskFactorLoaderStatus() {
  return {
    type: INIT_RISK_FACTOR_LOAD_STATUS
  };
}

export function initiateRiskAssetLoaderStatus() {
  return {
    type: INIT_RISK_ASSET_LOAD_STATUS
  };
}

export function initiateReturnReportLoaderStatus() {
  return {
    type: INIT_RETURN_REPORT_LOAD_STATUS
  };
}

export function initiateReturnFactorLoaderStatus() {
  return {
    type: INIT_RETURN_FACTOR_LOAD_STATUS
  };
}

export function initiateReturnAssetLoaderStatus() {
  return {
    type: INIT_RETURN_ASSET_LOAD_STATUS
  };
}
