import React, { Component } from 'react';
import Editor from '../companyScore/drawer/plugins/Editor';
import { Select, Form, Row, Col, Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import _ from 'lodash';

const _createUIOptions = codes => {
  return (
    codes &&
    codes.map(c => (
      <Option key={c || 'default'} value={c}>
        {c}
      </Option>
    ))
  );
};
const _createSectorUIOptions = codes => {
  return (
    codes &&
    codes.map(c => (
      <Option key={c.sector || 'default'} value={c.sector}>
        {c.sector}
      </Option>
    ))
  );
};
const _createCompanyUIOptions = codes => {
  return (
    codes &&
    codes.map(c => (
      <Option key={c.ticker || 'default'} value={c.ticker + '-' + c.name}>
        {c.ticker}-{c.name}
      </Option>
    ))
  );
};
const Option = Select.Option;
class DailyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.contextOptions,
      companyList: this._initCompanyList(),
      readOnly: this.props.readOnly,
      data: this.props.data,
      sourceData: this.props.data,
      defaultContent:
        '<p><span style="color: red;"><strong>This is for your investment summary. Please don\'t put your meeting minutes and research work here.</strong></span></p><p><span style="color: rgb(0, 0, 0);"><strong>Summay for today:</strong></span></p><p><br></p><p><br></p><p><span style="color: rgb(0, 0, 0);"><strong>Reflection:</strong></span></p>'
    };
  }

  _initCompanyList = () => {
    const { data, contextOptions } = this.props;
    if (_.isEmpty(contextOptions) || _.isEmpty(data)) return [];
    let sector = data.sector ? data.sector : [];
    sector = _.isArray(sector) ? sector : [sector];
    const fiterIds = contextOptions.sectorList
      .filter(r => sector.includes(r.sector))
      .map(r => r.id);
    return contextOptions.companyList.filter(r => fiterIds.includes(r.id));
  };

  onInputChange = ({ name, value }) => {
    const { data, sourceData, options, companyList } = this.state;
    let comList = companyList;
    if (name === 'sector') {
      const selectedSectors = value ? value : [];
      const fiterIds = options.sectorList
        .filter(r => selectedSectors.includes(r.sector))
        .map(r => r.id);
      comList = options.companyList.filter(r => fiterIds.includes(r.id));
    }
    sourceData[name] = value;
    this.setState({
      companyList: comList,
      data: {
        ...data,
        [name]: value
      }
    });
  };

  _showCompanyDesc = flag => {
    const { company, events, sector } = this.state.data;
    var descriptionDiv = document.getElementById('dailyReportFloatingDiv');
    if (
      flag &&
      !_.isEmpty([company, events, sector].filter(r => !_.isEmpty(r)))
    ) {
      descriptionDiv.style.display = 'block';
      let content = `<p><b>Events:</b>${events}</p><p><b>Sector:</b>${sector}</p><p><b>Company:</b>${company}</p>`;
      descriptionDiv.innerHTML = content;
    } else {
      descriptionDiv.style.display = 'none';
    }
  };

  _createEditor = () => {
    const { data } = this.state;
    const { index } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <Editor
          initText={
            _.isEmpty(data.content)
              ? index === 0
                ? this.state.defaultContent
                : ''
              : data.content
          }
          showDesc={this._showCompanyDesc}
          onChange={value => this.onInputChange({ name: 'content', value })}
        />
      </div>
    );
  };

  render() {
    const { data, options, companyList } = this.state;
    const { removeData, index } = this.props;
    return (
      <div
        className="analystDailyItem"
        style={{ height: '100%', width: '100%' }}
      >
        <div
          id="dailyReportFloatingDiv"
          style={{
            display: 'none',
            marginRight: '2px',
            fontSize: '12px',
            color: 'red'
          }}
        >
          {data.company}
        </div>
        <style>
          {`
                .analystDailyItem .ant-form-item-label > label {
                    color: white;
                }

                #dailyReportFloatingDiv {  
                  position: fixed; /* 相对于浏览器窗口进行定位 */  
                  right: 10px; /* 距离浏览器窗口右侧的距离 */  
                  bottom: 10px; /* 距离浏览器窗口底部的距离 */  
                  width: 300px; /* 设置div的宽度 */ 
                  white-space: normal; 
                  background-color: #f0f0f0; /* 设置背景色，以便在页面中可见 */  
                  border: 1px solid #ccc; /* 设置边框 */  
                  padding: 10px; /* 设置内边距 */  
                  box-sizing: border-box; /* 使宽度和高度包括内边距和边框 */  
                  z-index: 999; /* 确保它位于其他内容之上（如果需要的话） */  
                }  
            `}
        </style>
        {index !== 0 && (
          <Form labelAlign="left" style={{ border: '1px' }}>
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Event" required={true}>
                  <Select
                    value={data.events}
                    style={{ width: '93%' }}
                    mode="tags"
                    onChange={value => {
                      this.onInputChange({
                        name: 'events',
                        value
                      });
                    }}
                  >
                    {_createUIOptions(options.eventList)}
                  </Select>
                  <Button
                    style={{ width: '7%' }}
                    type="primary"
                    icon={<MinusOutlined />}
                    onClick={() => {
                      removeData(index);
                    }}
                  ></Button>
                </Form.Item>
              </Col>
            </Row>
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Sector" required={true}>
                  <Select
                    value={data.sector}
                    mode={options.needCheck ? 'multiple' : 'tags'}
                    style={{ width: '100%' }}
                    onChange={value => {
                      this.onInputChange({
                        name: 'sector',
                        value
                      });
                    }}
                  >
                    {_createSectorUIOptions(options.sectorList)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row span={24}>
              <Col span={24}>
                <Form.Item label="Company" required={true}>
                  <Select
                    value={data.company}
                    mode={options.needCheck ? 'multiple' : 'tags'}
                    style={{ width: '100%' }}
                    onChange={value => {
                      this.onInputChange({
                        name: 'company',
                        value
                      });
                    }}
                  >
                    {_createCompanyUIOptions(companyList)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
        <div style={{ height: `${index !== 0 ? '72%' : '95%'}` }}>
          {this._createEditor()}
        </div>
      </div>
    );
  }
}

export default DailyItem;
