import React, { PureComponent } from 'react';
import { Header, Modal, Button, Loader } from 'semantic-ui-react';
import { DIALOG_VIEW_ORDER_CHANGES } from '../../omsConstants';
import client from '../../api/client';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { orderChangesGridColumns } from './GridColumnMap';
import _ from 'lodash';

class ViewOrderChangesDialog extends PureComponent {
  state = {
    isLoaded: false,
    changes: [],
    columnDefs: orderChangesGridColumns,
    defaultColDef: {
      minWidth: 50,
      sortable: true,
      filter: true,
      resizable: true
    },
    autoGroupColumnDef: {
      cellClass: 'non-number'
    },
    sideBar: {
      toolPanels: ['columns', 'filters']
    }
  };

  componentDidMount() {
    const { selectedOrders } = this.props;
    if (selectedOrders && selectedOrders.length > 0) {
      this.setState({
        isLoaded: false
      });

      client
        .getAuditChanges({ pks: [selectedOrders[0].tradeId] })
        .then(changes => {
          this.setState({ changes, isLoaded: true });
        })
        .catch(err => {
          this.setState({ isLoaded: true });
        });
    }
  }

  componentWillUnmount() {
    this.setState({
      isLoaded: false,
      changes: []
    });
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_VIEW_ORDER_CHANGES);
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  createOrderSummary = () => {
    const { selectedOrders } = this.props;

    const order = _.first(selectedOrders);
    if (!order) return null;

    const {
      fundCode,
      bookCode,
      side,
      ticker,
      quantity,
      limitPriceLocal
    } = order;
    const sideStyle = ['BUY', 'COVR'].includes(side) ? 'long' : 'short';

    return (
      <div className="summary">
        <span className="comment">{`${fundCode}-${bookCode} `}</span>
        <span className={sideStyle}>{`${side} `}</span>
        <span className="keyword">{`${ticker} `}</span>
        with qty
        <span className={sideStyle}>{` ${quantity || 0} `}</span>
        and limit price
        <span className={sideStyle}>{` ${limitPriceLocal || 0} `}</span>
      </div>
    );
  };

  createChangesGrid = () => {
    return (
      <div
        style={{ width: '100%', height: '500px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={this.state.changes}
          suppressAggFuncInHeader={true}
          animateRows={true}
          deltaRowDataMode={false}
          rowMultiSelectWithClick={true}
          suppressCellSelection={true}
          groupDefaultExpanded={-1}
          sideBar={this.state.sideBar}
          // events
          onGridReady={this.onGridReady}
        />
      </div>
    );
  };

  render() {
    const { isLoaded } = this.state;
    return (
      <div>
        <Modal size="small" open={true}>
          <Header content="View Order Changes" />
          <Modal.Content>
            {!isLoaded ? (
              <Loader inverted active content="Loading..." />
            ) : (
              <div>
                {this.createOrderSummary()}
                {this.createChangesGrid()}
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button secondary content="Close" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ViewOrderChangesDialog;
