import { dateFormatter } from 'common/utils/valueFormatters';
export const manageUserGridColumns = [
  {
    field: 'englishName',
    headerName: 'Name',
    cellClass: 'non-number'
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  }
];

export const userProcessGridColumns = [
  {
    field: 'englishName',
    headerName: 'Name',
    cellClass: 'non-number'
  },
  {
    field: 'op',
    headerName: 'Op',
    cellClass: 'non-number'
  },
  {
    field: 'originalBegin',
    headerName: 'Original Begin',
    cellClass: 'non-number'
  },
  {
    field: 'beginDate',
    headerName: 'Begin',
    cellClass: 'non-number'
  },
  {
    field: 'originalEnd',
    headerName: 'Original End',
    cellClass: 'non-number'
  },
  {
    field: 'endDate',
    headerName: 'End',
    cellClass: 'non-number'
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'approveBy',
    headerName: 'Approve By',
    cellClass: 'non-number'
  },
  {
    field: 'approveDate',
    headerName: 'Approve Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  },
  {
    field: 'createTime',
    headerName: 'Create Time',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  },
  {
    field: 'applyRemark',
    headerName: 'Remark',
    cellClass: 'non-number'
  },
  {
    field: 'approveRemark',
    headerName: 'Feedback',
    cellClass: 'non-number'
  }
];
