import { TOGGLE_SIDEBAR } from './layoutConstants';
import { createReducer } from 'common/utils/reducerUtils';

const _isMobile = () => window.matchMedia('(max-width: 768px)').matches;

const initialState = {
  isSidebarOpen: false,
  isMobile: _isMobile()
};

export function toggleSidebar(state, payload) {
  return {
    ...state,
    isSidebarOpen: !state.isSidebarOpen
  };
}

export default createReducer(initialState, {
  [TOGGLE_SIDEBAR]: toggleSidebar
});
