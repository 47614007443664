import { connect } from 'react-redux';

import ResearchDashboard from '../components/ResearchDashboard';
import {
  selectLivePositionMap,
  selectLiveRiskInfoMap,
  selectLiveRiskInfos
} from '../../portfolio/portfolioSelectors';
import EntityMap from 'entities/EntityMap';

import {
  loadShortableTickers,
  loadAxeListTickers,
  // Research Modal
  resetResearchControlModal,
  // Analyst Modal
  resetAnalystControlModal,
  // Broker Modal
  resetBrokerControlModal,
  // Company Research
  loadCompanyResearch,
  submitCompanyResearch,
  deleteCompanyResearch,
  openCompanyResearchNewDialog,
  openCompanyResearchUpdateDialog,
  companyResearchChangeInput,

  // Broker Score
  loadBrokerScores,
  openBrokerScoresNewDialog,
  openBrokerScoresUpdateDialog,
  submitBrokerScore,
  deleteBrokerScore,
  brokerScoreChangeInput,
  newBrokerScoreRowData,
  openBrokerScoresDetailDialog,
  resetBrokerDetailControlModal,
  openYearBrokerScoresDetailDialog,
  resetYearBrokerScoresDetailDialog,
  loadBrokerScoreListByScore,
  loadBrokerScoreList,
  saveBrokerScore,
  removeBrokerScore,
  loadCorrespondenceList,
  initiateWeightMapLoaded,
  changeBrokerQureyParams,
  changeBrokerScoreControlModalInput,

  // Analyst Compliment
  loadAnalystCompliment,
  openAnalystComplimentNewDialog,
  openAnalystComplimentUpdateDialog,
  submitAnalystCompliment,
  deleteAnalystCompliment,
  analystComplimentChangeInput,

  // Boxed Position
  loadBoxedPosition,
  sendValidationFailure,
  loadAllTickers,
  loadShortInterest,
  searchTickers,

  // monthly review
  loadMonthlyReviewBenchmark,
  loadMonthlyReviewMyPortfilio,
  loadMonthlyReviewDetail,
  monthlyReviewDetailChangeInput,
  loadMonthlyReviewList,
  saveMonthlyReview,
  submitMonthlyReview,
  removeMonthlyReview,
  monthlyReviewDetailDataInit,
  changeMonthlyReviewQueryParams,
  loadShortableList,

  //analyst
  changeAnalystQueryParams,
  deleteAnalyst,
  saveAnalyst,
  loadAnalystList,
  changeAnalystModalInput,
  closeAnalystModal,
  getBrokerList,
  changeAnalystUpdateModalInput,
  getAnalystNameEditList,
  getAnalystSectorList,
  getAnalystDetailList,
  loadShortExchangeList,
  getGrossAndBetaByFundAndBook,
  getManagerUserList,
  queryBrokerScoreRankListByScore,
  saveBrokerScoreRankList,
  loadYearBrokerScores,

  //research report
  loadResearchReportDetail,
  loadResearchReportList,
  changeResearchReportDetailParams,
  openResearchReportDetailModal,
  closeResearchReportDetailModal,
  searchTicker,
  saveResearchReport,
  changeResearchReportQueryParams,
  copyResearchReport,
  getPersonList,
  getGicsList,
  downloadResearchReportAttch,
  deleteResearchReportAttch,
  researchReportCellChange,
  loadShortInterestByPerenct,
  getBlockList,
  addCompanyKeyDataTrack,
  updateCompanyKeyDataTrack,
  getCompanyKeyDataTrackList,
  addCompanyMeetingRecord,
  updateCompanyMeetingRecord,
  getCompanyMeetingRecordList,
  addCompanyReplayScore,
  updateCompanyReplayScore,
  getCompanyReplayScoreList,
  getCompanyKeyDataTrackDetail,
  openCompanyKeyDataTrackModal,
  closeCompanyKeyDataTrackModal,
  getCompanyMeetingRecordDetail,
  openCompanyMeetingRecordModal,
  closeCompanyMeetingRecordModal,
  getCompanyReplayScoreDetail,
  openCompanyReplayScoreModal,
  closeCompanyReplayScoreModal,
  fetchAllExcellentCompany,
  copyCompanyReplayScoreDetail,
  copyCompanyKeyDataTrackDetail,
  //push votes
  openPushVotesModal,
  closePushVotesModal,
  changePushVotesModalInput,
  loadPushVotesData,
  loadPushVoteReturnData,
  savePushVotes,
  openPushVoteNoteModal,
  changePushVoteNoteModalInput,
  closePushVoteNoteModal,
  addPushVoteNote,
  querySubscriptionList,
  loadPushVotesPmList,
  loadPushVotesAnalystList,
  changePushVotesSelectItem,
  adjustPushVotes,
  setPushVotesRecordRead,

  //portfolio plan
  openPortfolioPlanDialog,
  closePortfolioPlanDialog
} from '../researchActions';

import {
  openDialog,
  closeDialog,
  submitDialogSuccess
} from '../../common/commonActions';
import { dialogsSelector } from '../../common/commonSelectors';
import { sendSubscription } from '../../portfolio/portfolioActions';

const mapStateToProps = state => {
  const user = state.settings.user;
  const settings = state.settings;
  const liveRiskInfoMap = selectLiveRiskInfoMap(state);
  const liveRiskInfos = selectLiveRiskInfos(state);
  const livePositions = EntityMap.map(selectLivePositionMap(state));
  const dialogs = dialogsSelector(state);

  return {
    user,
    livePositions,
    liveRiskInfoMap,
    settings,
    liveRiskInfos,
    dialogs,

    // Short Interest
    allTickers: state.research.allTickers,
    shortInterest: state.research.shortInterest,

    // Shortable Tickers
    shortableTickers: state.research.shortableTickers,
    shortableTickersGridColumns: state.research.shortableTickersGridColumns,

    // Shortable Tickers
    axeListTickers: state.research.axeListTickers,
    axeListGridColumns: state.research.axeListGridColumns,

    // company research
    companyResearchGridColumns: state.research.companyResearchGridColumns,
    companyResearchs: state.research.companyResearchs,
    researchControlModal: state.research.researchControlModal,

    // Broker Score
    brokerScoreGridColumns: state.research.brokerScoreGridColumns,
    brokerScores: state.research.brokerScores,
    brokerControlModal: state.research.brokerControlModal,
    brokerScoreControlModal: state.research.brokerScoreControlModal,
    brokerScoreYearControlModal: state.research.brokerScoreYearControlModal,
    brokerScoreListForDetail: state.research.brokerScoreListForDetail,
    brokerQueryParams: state.research.brokerQueryParams,

    // Analyst Compliment
    analystComplimentGridColumns: state.research.analystComplimentGridColumns,
    analystCompliments: state.research.analystCompliments,
    analystControlModal: state.research.analystControlModal,

    //monthly Review
    monthlyReviewBenchmarkGridColumns:
      state.research.monthlyReviewBenchmarkGridColumns,
    monthlyReviewPortfolioGridColumns:
      state.research.monthlyReviewPortfolioGridColumns,
    monthlyReviewGridColumns: state.research.monthlyReviewGridColumns,
    monthlyReviewBenchmarkData: state.research.monthlyReviewBenchmarkData,
    monthlyReviewPortfolioGridData:
      state.research.monthlyReviewPortfolioGridData,
    monthlyReviewDetailData: state.research.monthlyReviewDetailData,
    monthlyReviewBenchmarkDataReadonly:
      state.research.monthlyReviewBenchmarkDataReadonly,
    monthlyReviewPortfolioGridDataReadonly:
      state.research.monthlyReviewPortfolioGridDataReadonly,
    monthlyReviewDetailDataReadonly:
      state.research.monthlyReviewDetailDataReadonly,
    monthliReviewData: state.research.monthliReviewData,
    detailIsLoaded: state.research.detailIsLoaded,
    monthlyReviewRemoveCallback: state.research.monthlyReviewRemoveCallback,
    monthlyReviewQueryParams: state.research.monthlyReviewQueryParams,
    monthlyReviewPmSelectList: state.research.monthlyReviewPmSelectList,
    monthlyReviewBenchmarkStatus: state.research.monthlyReviewBenchmarkStatus,
    monthlyReviewMyPerformanceStatus:
      state.research.monthlyReviewMyPerformanceStatus,
    // Broker Names
    brokerNameOptions: state.research.brokerNameOptions,

    // Boxed Position
    boxedPositionGridColumns: state.research.boxedPositionGridColumns,
    boxedPositions: state.research.boxedPositions,

    // isLoaded
    isLoaded: state.research.isLoaded,
    // isMobile
    isMobile: state.layout.isMobile,

    //short
    shortListGridColumns: state.research.shortListGridColumns,
    shortableList: state.research.shortableList,
    shortableListTotal: state.research.shortableListTotal,
    shortExchangeList: state.research.shortExchangeList,
    isLoadedShortExchangeStatus: state.research.isLoadedShortExchangeStatus,

    //analyst
    analystList: state.research.analystList,
    analystQueryParams: state.research.analystQueryParams,
    analystListGridColumns: state.research.analystListGridColumns,
    analystListModalData: state.research.analystListModalData,
    brokerList: state.research.brokerList,
    brokerCodeList: state.research.brokerCodeList,
    loadBrokerListStatus: state.research.loadBrokerListStatus,
    analystUpdateModalParam: state.research.analystUpdateModalParam,
    brokerLabelValueList: state.research.brokerLabelValueList,
    analystNameList: state.research.analystNameList,
    analystSectorList: state.research.analystSectorList,
    loadAnalystNameListStatus: state.research.loadAnalystNameListStatus,
    loadAnalystSectorListStatus: state.research.loadAnalystSectorListStatus,
    loadAnalystDetailStatus: state.research.loadAnalystDetailStatus,
    brokerScoreYearRankList: state.research.brokerScoreYearRankList,
    brokerScoreYearGridColumns: state.research.brokerScoreYearGridColumns,
    analystMeetingReconGridColumns:
      state.research.analystMeetingReconGridColumns,

    //research report
    researchReportGridColumns: state.research.researchReportGridColumns,
    researchReportList: state.research.researchReportList,
    researchReportQueryParams: state.research.researchReportQueryParams,
    isResearchReportLoaded: state.research.isResearchReportLoaded,
    isResearchReportDetailLoaded: state.research.isResearchReportDetailLoaded,
    researchReportDetail: state.research.researchReportDetail,
    researchReportFirstPageDetail: state.research.researchReportFirstPageDetail,
    researchReportGicsList: state.research.researchReportGicsList,
    researchReportPersonList: state.research.researchReportPersonList,

    shortInterestPercent: state.research.shortInterestPercent,

    blockList: state.research.blockList,
    blockListGridColumns: state.research.blockListGridColumns,
    companyKeyDataTrackList: state.research.companyKeyDataTrackList,
    companyKeyDataTrackDetail: state.research.companyKeyDataTrackDetail,
    companyMeetingRecordList: state.research.companyMeetingRecordList,
    companyMeetingRecordDetail: state.research.companyMeetingRecordDetail,
    companyReplayScoreList: state.research.companyReplayScoreList,
    companyReplayScoreDetail: state.research.companyReplayScoreDetail,
    companyKeyDataTrackModal: state.research.companyKeyDataTrackModal,
    companyMeetingRecordModal: state.research.companyMeetingRecordModal,
    companyReplayScoreModal: state.research.companyReplayScoreModal,
    meetingRecordGridColumns: state.research.meetingRecordGridColumns,
    companyKeyDataTrackColumns: state.research.companyKeyDataTrackColumns,
    companyReplayScoreColumns: state.research.companyReplayScoreColumns,
    allExcellentCompanyList: state.research.allExcellentCompanyList,
    allExcellentCompanyColumns: state.research.allExcellentCompanyColumns,

    //push votes
    pushVotesGridColumns: state.research.pushVotesGridColumns,
    pushVotesModal: state.research.pushVotesModal,
    pushVotesDataList: state.research.pushVotesDataList,
    pushVoteNoteModal: state.research.pushVoteNoteModal,
    pushVOtesSubscriptionList: state.research.pushVOtesSubscriptionList,
    pushVOtesPMList: state.research.pushVOtesPMList,
    pushVOtesAnalystList: state.research.pushVOtesAnalystList,
    pushVotesSelectItem: state.research.pushVotesSelectItem,
    pushVotesSubmitErrMsg: state.research.pushVotesSubmitErrMsg,
    //portfolio plan
    portfolioPlanModal: state.research.portfolioPlanModal,
    pushVotesSubmitStatus: state.research.pushVotesSubmitStatus,
    //push vote return
    pushVoteReturnDataList: state.research.pushVoteReturnDataList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSubscription: payload => dispatch(sendSubscription(payload)),
    loadShortableTickers: date => dispatch(loadShortableTickers(date)),
    loadAxeListTickers: (date, ticker) =>
      dispatch(loadAxeListTickers(date, ticker)),
    // Research Modal
    resetResearchControlModal: () => dispatch(resetResearchControlModal()),

    // Analyst Modal
    resetAnalystControlModal: () => dispatch(resetAnalystControlModal()),
    openAnalystComplimentNewDialog: () =>
      dispatch(openAnalystComplimentNewDialog()),
    openAnalystComplimentUpdateDialog: payload =>
      dispatch(openAnalystComplimentUpdateDialog(payload)),

    // Broker Modal
    resetBrokerControlModal: () => dispatch(resetBrokerControlModal()),
    openBrokerScoresNewDialog: () => dispatch(openBrokerScoresNewDialog()),
    openBrokerScoresUpdateDialog: payload =>
      dispatch(openBrokerScoresUpdateDialog(payload)),
    openBrokerScoresDetailDialog: votingMonth =>
      dispatch(openBrokerScoresDetailDialog(votingMonth)),
    resetBrokerDetailControlModal: () =>
      dispatch(resetBrokerDetailControlModal()),
    openYearBrokerScoresDetailDialog: () =>
      dispatch(openYearBrokerScoresDetailDialog()),
    resetYearBrokerScoresDetailDialog: () =>
      dispatch(resetYearBrokerScoresDetailDialog()),

    // Company Research
    loadCompanyResearch: date => dispatch(loadCompanyResearch(date)),
    submitCompanyResearch: companyResearch =>
      dispatch(submitCompanyResearch(companyResearch)),
    deleteCompanyResearch: companyResearchId =>
      dispatch(deleteCompanyResearch(companyResearchId)),
    sendValidationFailure: fieldErrors =>
      dispatch(sendValidationFailure(fieldErrors)),

    openCompanyResearchNewDialog: () =>
      dispatch(openCompanyResearchNewDialog()),
    openCompanyResearchUpdateDialog: payload =>
      dispatch(openCompanyResearchUpdateDialog(payload)),
    companyResearchOnInputChange: input =>
      dispatch(companyResearchChangeInput(input)),

    brokerScoreChangeInput: input => dispatch(brokerScoreChangeInput(input)),
    analystComplimentChangeInput: input =>
      dispatch(analystComplimentChangeInput(input)),

    // Broker Score
    loadBrokerScores: date => dispatch(loadBrokerScores(date)),
    submitBrokerScore: brokerScore => dispatch(submitBrokerScore(brokerScore)),
    deleteBrokerScore: brokerScoreId =>
      dispatch(deleteBrokerScore(brokerScoreId)),
    newBrokerScoreRowData: brokerScoreRowData =>
      dispatch(newBrokerScoreRowData(brokerScoreRowData)),
    loadBrokerScoreListByScore: queryParams =>
      dispatch(loadBrokerScoreListByScore(queryParams)),
    loadBrokerScoreList: queryParams =>
      dispatch(loadBrokerScoreList(queryParams)),
    saveBrokerScore: (params, queryParams) =>
      dispatch(saveBrokerScore(params, queryParams)),
    removeBrokerScore: (params, queryParams) =>
      dispatch(removeBrokerScore(params, queryParams)),
    loadCorrespondenceList: params => dispatch(loadCorrespondenceList(params)),
    initiateWeightMapLoaded: () => dispatch(initiateWeightMapLoaded()),
    changeBrokerQureyParams: input => dispatch(changeBrokerQureyParams(input)),
    changeBrokerScoreControlModalInput: input =>
      dispatch(changeBrokerScoreControlModalInput(input)),

    // Analyst Compliment
    loadAnalystCompliment: date => dispatch(loadAnalystCompliment(date)),
    submitAnalystCompliment: analystCompliment =>
      dispatch(submitAnalystCompliment(analystCompliment)),
    deleteAnalystCompliment: analystComplimentId =>
      dispatch(deleteAnalystCompliment(analystComplimentId)),

    // Boxed Position
    loadBoxedPosition: () => dispatch(loadBoxedPosition()),

    loadAllTickers: () => dispatch(loadAllTickers()),
    loadShortInterest: payload => dispatch(loadShortInterest(payload)),

    searchTickers: term => dispatch(searchTickers(term)),

    loadShortableList: params => dispatch(loadShortableList(params)),
    loadShortExchangeList: () => dispatch(loadShortExchangeList()),

    //analyst
    changeAnalystQueryParams: input =>
      dispatch(changeAnalystQueryParams(input)),
    saveAnalyst: (params, queryParams) =>
      dispatch(saveAnalyst(params, queryParams)),
    deleteAnalyst: (params, queryParams) =>
      dispatch(deleteAnalyst(params, queryParams)),
    loadAnalystList: queryParams => dispatch(loadAnalystList(queryParams)),
    changeAnalystModalInput: input => dispatch(changeAnalystModalInput(input)),
    closeAnalystModal: () => dispatch(closeAnalystModal()),
    getBrokerList: params => dispatch(getBrokerList(params)),
    changeAnalystUpdateModalInput: input =>
      dispatch(changeAnalystUpdateModalInput(input)),
    getAnalystNameEditList: params => dispatch(getAnalystNameEditList(params)),
    getAnalystSectorList: params => dispatch(getAnalystSectorList(params)),
    getAnalystDetailList: params => dispatch(getAnalystDetailList(params)),

    //monthly review
    loadMonthlyReviewBenchmark: (queryParams, updateMode) =>
      dispatch(loadMonthlyReviewBenchmark(queryParams, updateMode)),
    loadMonthlyReviewMyPortfilio: (queryParams, updateMode) =>
      dispatch(loadMonthlyReviewMyPortfilio(queryParams, updateMode)),
    loadMonthlyReviewDetail: (id, updateMode) =>
      dispatch(loadMonthlyReviewDetail(id, updateMode)),
    monthlyReviewDetailChangeInput: input =>
      dispatch(monthlyReviewDetailChangeInput(input)),
    loadMonthlyReviewList: queryParams =>
      dispatch(loadMonthlyReviewList(queryParams)),
    saveMonthlyReview: (params, queryParams) =>
      dispatch(saveMonthlyReview(params, queryParams)),
    submitMonthlyReview: (params, queryParams) =>
      dispatch(submitMonthlyReview(params, queryParams)),
    removeMonthlyReview: (params, queryParams) =>
      dispatch(removeMonthlyReview(params, queryParams)),
    monthlyReviewDetailDataInit: () => dispatch(monthlyReviewDetailDataInit()),
    changeMonthlyReviewQueryParams: input =>
      dispatch(changeMonthlyReviewQueryParams(input)),
    getGrossAndBetaByFundAndBook: params =>
      dispatch(getGrossAndBetaByFundAndBook(params)),
    getManagerUserList: () => dispatch(getManagerUserList()),

    queryBrokerScoreRankListByScore: params =>
      dispatch(queryBrokerScoreRankListByScore(params)),
    saveBrokerScoreRankList: params =>
      dispatch(saveBrokerScoreRankList(params)),
    loadYearBrokerScores: params => dispatch(loadYearBrokerScores(params)),

    //research report
    loadResearchReportDetail: params =>
      dispatch(loadResearchReportDetail(params)),
    loadResearchReportList: params => dispatch(loadResearchReportList(params)),
    changeResearchReportDetailParams: params =>
      dispatch(changeResearchReportDetailParams(params)),
    openResearchReportDetailModal: params =>
      dispatch(openResearchReportDetailModal(params)),
    closeResearchReportDetailModal: params =>
      dispatch(closeResearchReportDetailModal(params)),
    searchTicker: params => dispatch(searchTicker(params)),
    saveResearchReport: (params, queryParams) =>
      dispatch(saveResearchReport(params, queryParams)),
    changeResearchReportQueryParams: input =>
      dispatch(changeResearchReportQueryParams(input)),
    copyResearchReport: (params, queryParams) =>
      dispatch(copyResearchReport(params, queryParams)),
    getPersonList: params => dispatch(getPersonList(params)),
    getGicsList: params => dispatch(getGicsList(params)),
    downloadResearchReportAttch: params =>
      dispatch(downloadResearchReportAttch(params)),
    deleteResearchReportAttch: params =>
      dispatch(deleteResearchReportAttch(params)),
    researchReportCellChange: () => dispatch(researchReportCellChange()),
    loadShortInterestByPerenct: params =>
      dispatch(loadShortInterestByPerenct(params)),
    getBlockList: params => dispatch(getBlockList(params)),
    addCompanyKeyDataTrack: params => dispatch(addCompanyKeyDataTrack(params)),
    updateCompanyKeyDataTrack: params =>
      dispatch(updateCompanyKeyDataTrack(params)),
    getCompanyKeyDataTrackList: params =>
      dispatch(getCompanyKeyDataTrackList(params)),
    addCompanyMeetingRecord: params =>
      dispatch(addCompanyMeetingRecord(params)),
    updateCompanyMeetingRecord: params =>
      dispatch(updateCompanyMeetingRecord(params)),
    getCompanyMeetingRecordList: params =>
      dispatch(getCompanyMeetingRecordList(params)),
    addCompanyReplayScore: params => dispatch(addCompanyReplayScore(params)),
    updateCompanyReplayScore: params =>
      dispatch(updateCompanyReplayScore(params)),
    getCompanyReplayScoreList: params =>
      dispatch(getCompanyReplayScoreList(params)),
    getCompanyKeyDataTrackDetail: params =>
      dispatch(getCompanyKeyDataTrackDetail(params)),
    openCompanyKeyDataTrackModal: params =>
      dispatch(openCompanyKeyDataTrackModal(params)),
    closeCompanyKeyDataTrackModal: params =>
      dispatch(closeCompanyKeyDataTrackModal(params)),
    getCompanyMeetingRecordDetail: params =>
      dispatch(getCompanyMeetingRecordDetail(params)),
    openCompanyMeetingRecordModal: params =>
      dispatch(openCompanyMeetingRecordModal(params)),
    closeCompanyMeetingRecordModal: params =>
      dispatch(closeCompanyMeetingRecordModal(params)),
    getCompanyReplayScoreDetail: params =>
      dispatch(getCompanyReplayScoreDetail(params)),
    openCompanyReplayScoreModal: params =>
      dispatch(openCompanyReplayScoreModal(params)),
    closeCompanyReplayScoreModal: params =>
      dispatch(closeCompanyReplayScoreModal(params)),
    fetchAllExcellentCompany: () => dispatch(fetchAllExcellentCompany()),
    copyCompanyReplayScoreDetail: (params, queryParams) =>
      dispatch(copyCompanyReplayScoreDetail(params, queryParams)),
    copyCompanyKeyDataTrackDetail: (params, queryParams) =>
      dispatch(copyCompanyKeyDataTrackDetail(params, queryParams)),
    openPushVotesModal: params => dispatch(openPushVotesModal(params)),
    closePushVotesModal: params => dispatch(closePushVotesModal(params)),
    changePushVotesModalInput: params =>
      dispatch(changePushVotesModalInput(params)),
    loadPushVotesData: params => dispatch(loadPushVotesData(params)),
    loadPushVoteReturnData: params => dispatch(loadPushVoteReturnData(params)),
    savePushVotes: params => dispatch(savePushVotes(params)),
    openPushVoteNoteModal: params => dispatch(openPushVoteNoteModal(params)),
    changePushVoteNoteModalInput: params =>
      dispatch(changePushVoteNoteModalInput(params)),
    closePushVoteNoteModal: params => dispatch(closePushVoteNoteModal(params)),
    addPushVoteNote: params => dispatch(addPushVoteNote(params)),
    querySubscriptionList: params => dispatch(querySubscriptionList(params)),
    loadPushVotesPmList: params => dispatch(loadPushVotesPmList(params)),
    loadPushVotesAnalystList: params =>
      dispatch(loadPushVotesAnalystList(params)),
    changePushVotesSelectItem: params =>
      dispatch(changePushVotesSelectItem(params)),
    adjustPushVotes: params => dispatch(adjustPushVotes(params)),
    setPushVotesRecordRead: params => dispatch(setPushVotesRecordRead(params)),
    //portfolio plan
    openPortfolioPlanDialog: () => dispatch(openPortfolioPlanDialog()),
    closePortfolioPlanDialog: () => dispatch(closePortfolioPlanDialog()),
    // common
    openDialog: (dialogCode, info) => dispatch(openDialog(dialogCode, info)),
    closeDialog: dialogCode => dispatch(closeDialog(dialogCode)),
    submitDialogSuccess: (dialogCode, info) =>
      dispatch(submitDialogSuccess(dialogCode, info))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResearchDashboard);
