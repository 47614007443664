import React, { Component } from 'react';
import CompanyScoreGrid from './CompanyScoreGrid';

import './CompanyScoreTab.css';

class CompanyScoreTab extends Component {
  render() {
    return (
      <div className="companyScoreTabWrapper">
        <CompanyScoreGrid {...this.props} />
      </div>
    );
  }
}

export default CompanyScoreTab;
