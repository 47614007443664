import React, { Component } from 'react';
import {
  InputNumber,
  Drawer,
  Layout,
  List,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Space,
  Tag
} from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import moment from 'moment';
import { localStorageClient } from 'common/api/localStorageClient';
import hyperid from 'hyperid';
import AddLinkModal from './AddLinkModal';

const { Content, Sider } = Layout;
const { Title } = Typography;
const size_key = 'company_score_notes_draw_size';
const uniqidInstance = hyperid();
class NotesDrawer extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.otherEditorRef = React.createRef();
    if (!props.data.noteList) {
      props.data.noteList = [
        {
          id: moment().unix(),
          content: '',
          createDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          modifyDate: moment().format('YYYY-MM-DD HH:mm:ss')
        }
      ];
    } else if (_.isEmpty(props.data.noteList)) {
      props.data.noteList.push({
        id: moment().unix(),
        content: '',
        createDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        modifyDate: moment().format('YYYY-MM-DD HH:mm:ss')
      });
    } else if (!_.isEmpty(props.data.noteList)) {
      props.data.noteList.forEach((e, index) => {
        e.id = index;
      });
    }
    this._initAttachList();

    const windowSize = localStorageClient.get(size_key);
    this.state = {
      data: props.data.noteList,
      attachList: props.data.attachList,
      currentData: {
        ...props.data.noteList[0],
        contentNotes: props.data.noteList[0].content,
        otherNotes: props.data.noteList[0].contentRemark
      },
      windowSize: windowSize ? windowSize : 30,
      linkModal: {
        show: false
      },
      colorMap: {
        xlsx: 'green',
        xls: 'green',
        ppt: '#cd201f',
        pptx: '#cd201f',
        docx: '#3b5999',
        doc: '#3b5999',
        oneNotes: 'purple'
      }
    };
  }

  _initAttachList = () => {
    const props = this.props;
    if (!props.data.attachList) {
      props.data.attachList = [];
    }
  };

  handleEditorChange = (content, editor) => {
    const { currentData } = this.state;
    const updateData = {
      ...currentData,
      content,
      modifyDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      status: 'U'
    };
    this.setState(
      {
        currentData: updateData
      },
      this._saveNote
    );
  };

  handleOtherEditorChange = (content, editor) => {
    const { currentData } = this.state;
    if (_.isEmpty(content) || '<p><br></p>' === content) return;
    const updateData = {
      ...currentData,
      contentRemark: content,
      modifyDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      status: 'U'
    };
    this.setState(
      {
        currentData: updateData
      },
      this._saveNote
    );
  };

  _newNotes = () => {
    this._saveNote();
    this.editorRef.current.editor.setContent('');
    this.otherEditorRef.current.editor.setContent('');
    const { data } = this.state;
    const currentData = {
      id: uniqidInstance(),
      createDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      modifyDate: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    data.push(currentData);
    this.setState({
      data,
      currentData
    });
  };

  _saveNote = (func, notRefresh) => {
    const { data, currentData } = this.state;
    const filterData = data.filter(r => r.id === currentData.id);
    if (_.isEmpty(filterData)) {
      data.push({
        ...currentData
      });
    } else {
      const current = filterData[0];
      current['title'] = currentData['title'];
      current['content'] = currentData['content'];
      current['contentRemark'] = currentData['contentRemark'];
      current['status'] = currentData['status'];
    }
    this.setState(
      {
        data
      },
      () => {
        if (func) func();
      }
    );
  };

  _rowClick = item => {
    const func = () => {
      const content = _.isEmpty(item.content) ? '' : item.content;
      const contentRemark = _.isEmpty(item.contentRemark)
        ? ''
        : item.contentRemark;
      this.editorRef.current.editor.setContent(content);
      this.otherEditorRef.current.editor.setContent(contentRemark);
      this.setState({
        currentData: {
          ...item,
          contentNotes: content,
          otherNotes: contentRemark
        }
      });
    };
    this._saveNote(func);
  };

  _createNotesList = () => {
    const { data } = this.state;
    return (
      <List
        style={{
          overflow: 'auto',
          height: '70%',
          borderBottom: '2px solid gray'
        }}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <div
              style={{ width: '100%', padding: '5px' }}
              onClick={() => {
                this._rowClick(item);
              }}
            >
              <Row style={{ height: '20px' }}>
                <Col span={24}>
                  <span style={{ color: 'lightgray', fontSize: '14px' }}>
                    <b>
                      {item.title && item.title.length > 20
                        ? `${item.title.substr(0, 20)}...`
                        : item.title}
                    </b>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '2px', paddingTop: '2px' }}>
                <Col span={24}>
                  <span style={{ color: 'lightgray' }}>{item.modifyDate}</span>
                </Col>
              </Row>
            </div>
          </List.Item>
        )}
      />
    );
  };

  _removeTags = item => {
    const { attachList } = this.state;
    const index = _.findIndex(attachList, r => r.url === item.url);
    attachList.splice(index, 1);
    this.setState({
      attachList
    });
  };

  _getColor = type => {
    const { colorMap } = this.state;
    return colorMap[type] ? colorMap[type] : 'gray';
  };

  _onLinkClick = r => {
    window.open(r.url);
  };

  _createUploadList = () => {
    const { readOnly } = this.props;
    const { attachList } = this.state;
    return (
      <div
        style={{
          marginTop: '10px',
          marginLeft: '5px',
          height: '25%',
          overflow: 'auto'
        }}
      >
        <Space size={[0, 8]} wrap></Space>
        {attachList.map(r => {
          return (
            <Tag
              key={r.url}
              closable={readOnly ? false : true}
              style={{ marginTop: '5px' }}
              onClose={() => this._removeTags(r)}
              color={this._getColor(r.type)}
              onClick={() => this._onLinkClick(r)}
            >
              <a href="#">
                {r.name.length > 15 ? `${r.name.substr(0, 15)}...` : r.name}
                {_.isEmpty(r.type) ? '' : `.${r.type}`}
              </a>
            </Tag>
          );
        })}
      </div>
    );
  };

  onInputChangeEventHandle = (event, name) => {
    const { currentData, data } = this.state;
    const filedValue = event.target.value;
    const updateData = {
      ...currentData,
      [name]: filedValue,
      status: 'U'
    };
    const filterData = data.filter(r => r.id === currentData.id);

    const current = filterData[0];
    current['title'] = updateData['title'];
    current['content'] = updateData['content'];
    current['status'] = 'U';
    current['modifyDate'] = moment().format('YYYY-MM-DD HH:mm:ss');

    this.setState({ currentData: updateData, data });
  };

  closeNotesDraw = () => {
    const { closeNotesDraw } = this.props;
    this._saveNote(closeNotesDraw);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.props;
    return {
      SUBMITTING: (
        <Button key="submit" size="small" type="primary" disabled loading>
          Saving
        </Button>
      ),
      ERROR: (
        <Button key="submit" size="small" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" size="small" type="primary" onClick={handleSubmit}>
          Save
        </Button>
      )
    }[submitStatus];
  };

  _save = () => {
    const { saveData } = this.props;
    this._saveNote(saveData);
  };

  _onSizeChange = value => {
    localStorageClient.save(size_key, value);
    this.setState({
      windowSize: value
    });
  };

  _addLink = () => {
    this._openLinkModal();
  };

  _openLinkModal = () => {
    this.setState({
      linkModal: {
        show: true
      }
    });
  };

  _submitLink = data => {
    const { attachList } = this.state;
    attachList.push(data);
    this.setState(
      {
        attachList
      },
      this._closeLinkModal
    );
  };

  _closeLinkModal = () => {
    this.setState({
      linkModal: {
        show: false
      }
    });
  };

  _createAddLinkModal = () => {
    const { linkModal } = this.state;
    return (
      linkModal.show && (
        <AddLinkModal
          closeModal={this._closeLinkModal}
          submitData={this._submitLink}
        />
      )
    );
  };

  render() {
    const { readOnly, name } = this.props;
    const { currentData, windowSize } = this.state;
    const title = (
      <div>
        <div style={{ width: '50%', height: '25px', float: 'left' }}>
          <div style={{ width: `500px`, float: 'left' }}>
            <Title level={4} style={{ color: 'white' }}>
              Notes <span style={{ fontSize: '14px' }}>{name}</span>
            </Title>
          </div>
        </div>
        <div style={{ width: '50%', height: '25px', float: 'right' }}>
          <div style={{ width: '100%', textAlign: 'right', marginTop: '10px' }}>
            {!readOnly && this._createSubmitBtn(this._save)}
            <Button
              size="small"
              onClick={this.closeNotesDraw}
              style={{ marginLeft: '10px' }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    );
    return (
      <Drawer
        width={'100%'}
        title={title}
        placement="right"
        closable={false}
        visible={true}
        bodyStyle={{
          padding: '5px'
        }}
        className="planDetailDrawerClass"
      >
        <Layout style={{ padding: '0px' }}>
          <Sider width={200}>
            {!readOnly && (
              <div style={{ width: '100%', textAlign: 'left', margin: '5px' }}>
                <Button size="small" type="primary" onClick={this._newNotes}>
                  New Notes
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  size="small"
                  type="primary"
                  onClick={this._addLink}
                >
                  Add Link
                </Button>
                {/* <Button style={{marginLeft: '5px'}} size='small' type='primary' onClick={this._saveNote}>Save</Button> */}
              </div>
            )}
            {this._createNotesList()}
            {this._createUploadList()}
          </Sider>
          <Content style={{ padding: '0', minHeight: 280 }}>
            <div style={{ height: '30px' }}>
              <div style={{ width: `calc(100% - 100px)`, float: 'left' }}>
                <Input
                  placeholder="Input your note title"
                  size="large"
                  value={currentData.title}
                  onChange={e => {
                    this.onInputChangeEventHandle(e, 'title');
                  }}
                />
              </div>
              <div
                style={{
                  width: '100px',
                  float: 'left',
                  backgroundColor: '#2d3436'
                }}
              >
                <InputNumber
                  placeholder="Right notes size"
                  size="large"
                  style={{ height: '30px' }}
                  min={0}
                  max={100}
                  value={windowSize}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  onChange={this._onSizeChange}
                />
              </div>
            </div>
            <div>
              <div style={{ width: `${100 - windowSize}%`, float: 'left' }}>
                <Editor
                  apiKey="p8o7go84lqcrt6gu7wwgc9oc69bqvontrq3j756o904fqfaa"
                  ref={this.editorRef}
                  initialValue={currentData.contentNotes}
                  plugins={[
                    'advlist',
                    'autolink',
                    'link',
                    'image',
                    'lists',
                    'preview',
                    'wordcount',
                    'fullscreen',
                    'table'
                  ]}
                  table_toolbar="tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter"
                  toolbar="undo redo | blocks |bold italic underline fontsize forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent  | table tabledelete | link image | print preview fullscreen"
                  contextmenu="tableprops tabledelete | cell row "
                  font_size_formats="8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt"
                  init={{
                    height: `calc(100vh - 100px)`,
                    menubar: 'true',
                    clean: true
                  }}
                  onEditorChange={this.handleEditorChange}
                />
              </div>
              <div style={{ width: `${windowSize}%`, float: 'left' }}>
                <Editor
                  apiKey="p8o7go84lqcrt6gu7wwgc9oc69bqvontrq3j756o904fqfaa"
                  ref={this.otherEditorRef}
                  initialValue={currentData.otherNotes}
                  plugins={[
                    'advlist',
                    'autolink',
                    'link',
                    'image',
                    'lists',
                    'preview',
                    'wordcount',
                    'fullscreen',
                    'table'
                  ]}
                  toolbar="bold italic underline | fontsize forecolor | backcolor | blocks  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent  |  print preview fullscreen"
                  font_size_formats="8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt"
                  init={{
                    height: `calc(100vh - 100px)`,
                    menubar: 'true',
                    clean: true
                  }}
                  onEditorChange={this.handleOtherEditorChange}
                />
              </div>
            </div>
          </Content>
        </Layout>
        {this._createAddLinkModal()}
      </Drawer>
    );
  }
}

export default NotesDrawer;
