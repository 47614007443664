import React, { Component } from 'react';
import { Modal, Button, DatePicker, Spin, message } from 'antd';
import client from '../../api/client';
import _ from 'lodash';
import moment from 'moment';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { companyStrategyDetailTableColumns } from './GridColumnMap';

const { MonthPicker } = DatePicker;
class CompanyStrategyModal extends Component {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    this.selectedCell = {
      row: null,
      col: null
    };
    this.state = {
      date: moment().format('YYYY-MM'),
      data: [],
      submitStatus: 'READY',
      isInited: false,
      gridWrapperStyle: {
        width: '100%',
        height: '350px',
        marginTop: '20px'
      },
      errors: []
    };
  }

  componentDidMount() {
    _.delay(this._init, 500);
  }

  _init = selectDate => {
    const { date } = this.state;
    const yearMonth = selectDate ? selectDate : date;
    client
      .getCompanyStrategyDetail(yearMonth)
      .then(resp => {
        const data = _.isEmpty(resp.data)
          ? this._initData(yearMonth, [])
          : this._initData(yearMonth, resp.data.data);
        this.setState({
          isInited: true,
          data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _initData = (date, input) => {
    const data = [...input];
    for (let i = 0; i < 5; i++) {
      if (i <= data.length - 1) {
        data[i].pairName = `PAIR${i + 1}`;
      } else {
        data.push({
          pairName: `PAIR${i + 1}`,
          date,
          pairData: [
            {
              type: 'LONG',
              ticker: null,
              tickerName: null,
              reason: null,
              isDel: 0
            },
            {
              type: 'SHORT',
              ticker: null,
              tickerName: null,
              reason: null,
              isDel: 0
            }
          ]
        });
      }
    }
    return data;
  };

  monthChange = (date, dataString) => {
    this.setState({
      date: dataString
    });
    this._init(dataString);
  };

  _createMonthSelect = () => {
    return (
      <div style={{ width: '100%', height: '36px' }}>
        <MonthPicker
          value={moment(this.state.date, 'YYYY-MM')}
          format={'YYYY-MM'}
          allowClear={false}
          onChange={this.monthChange}
        />
      </div>
    );
  };

  _buildShowData = () => {
    const { data } = this.state;
    if (_.isEmpty(data)) return [];
    const resultData = data ? data : [];
    const showData = [];
    resultData.forEach((r, index) => {
      const detail = r.pairData.filter(e => e.isDel !== 1);
      detail.forEach(e => (e.pairName = r.pairName));
      showData.push(...detail);
      if (index < resultData.length - 1) {
        showData.push({ type: '', pairName: r.pairName });
      }
    });
    return showData;
  };

  _tableOpContextMenu = () => {
    return {
      items: {
        add_long_company: {
          name: 'Add Long Company',
          callback: () => {
            // Must delay below operation, otherwise handsontable will throw exception.
            _.delay(() => this._addCompanyData('LONG'));
          }
        },
        add_short_company: {
          name: 'Add Short Company',
          callback: () => {
            // Must delay below operation, otherwise handsontable will throw exception.
            _.delay(() => this._addCompanyData('SHORT'));
          }
        },
        remove_company: {
          name: 'Remove Company',
          callback: () => {
            // Must delay below operation, otherwise handsontable will throw exception.
            _.delay(() => this._removeCompanyData());
          }
        }
      }
    };
  };

  _addCompanyData = type => {
    const { data } = this.state;
    const selectedData = this._getSelectData();
    if (selectedData) {
      const updateData = [...data];
      const filterData = updateData.filter(
        r => r.pairName === selectedData.pairName
      );
      if (!_.isEmpty(filterData)) {
        filterData[0].pairData.push({
          type,
          isDel: 0
        });
        _.delay(() => {
          this.setState({
            data: updateData
          });
        }, 300);
      }
    }
  };

  _removeCompanyData = () => {
    const { data } = this.state;
    const selectedData = this._getSelectData();
    if (selectedData) {
      const updateData = [...data];
      const filterData = updateData.filter(
        r => r.pairName === selectedData.pairName
      );
      if (!_.isEmpty(filterData)) {
        const filterExitsData = filterData[0].pairData.filter(
          e => e.isDel !== 1
        );
        if (filterExitsData.length === 1) {
          message.error('Current pair at least exists one record.');
          return;
        }
        const filterItem = filterData[0].pairData.filter(
          e =>
            e.ticker === selectedData.ticker &&
            e.isDel !== 1 &&
            e.type === selectedData.type
        );
        if (filterItem[0]) filterItem[0].isDel = 1;
        _.delay(() => {
          this.setState({
            data: updateData
          });
        }, 300);
      }
    }
  };

  transferDataObject = (data, cols) => {
    const columns = cols.map(r => r.data);
    const updateData = _.zipObject(columns, data);
    return updateData;
  };

  _getSelectData = () => {
    const { row } = this.selectedCell;
    if (_.isNil(row)) return;
    const tableData = this._buildShowData();
    return tableData[row];
  };

  _onTableCellMouseDown = (event, coords, TD) => {
    this.selectedCell = coords;
  };

  _createHotTable = () => {
    const { gridWrapperStyle } = this.state;
    const hotTableSettings = hotTableUtils.createSettings({
      columns: companyStrategyDetailTableColumns,
      contextMenu: this._tableOpContextMenu()
    });
    const data = this._buildShowData();
    let height = (data.length + 1) * 23.5;
    height = height > 500 ? 500 : height;
    const style = {
      ...gridWrapperStyle,
      height: `${height}px`
    };
    return (
      <div style={style}>
        <HotTable
          ref={this.hotTblRef}
          data={data}
          {...hotTableSettings}
          afterOnCellMouseDown={this._onTableCellMouseDown}
        ></HotTable>
      </div>
    );
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Saving
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Save
        </Button>
      )
    }[submitStatus];
  };

  closeDialog = () => {
    this.props.closeDialog();
  };

  _onSubmit = () => {
    const { date, data } = this.state;
    const params = {
      date,
      data
    };
    client
      .saveCompanyStrategy(params)
      .then(resp => {
        this.closeDialog();
        this.props.loadData();
      })
      .catch(error => {
        console.log(error);
        this.setState({
          submitStatus: 'ERROR'
        });
      });
  };

  _createNotes = () => {
    return (
      <div>
        <p style={{ marginTop: '5px', marginBottom: '5px' }}>
          <b style={{ fontSize: '18px' }}>Note: </b>
        </p>
        <p style={{ marginTop: '5px', marginBottom: '5px' }}>
          <b style={{ fontSize: '15px' }}>
            {
              '1. Click right button of your mouse to add more Long/short company. 点击鼠标右键以增加long/short公司。'
            }
          </b>
        </p>
      </div>
    );
  };

  render() {
    const { isInited } = this.state;
    return (
      <Modal
        width={1100}
        maskClosable={false}
        title="Long/short idea"
        visible={true}
        bodyStyle={{ paddingTop: '6px' }}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin spinning={!isInited}>
          {this._createMonthSelect()}
          {this._createHotTable()}
        </Spin>
        {this._createNotes()}
      </Modal>
    );
  }
}

export default CompanyStrategyModal;
