import { connect } from 'react-redux';

import ChgPwdForm from '../components/ChgPwdForm';
import { chgPwd, resetChgPwdForm } from '../authActions';

function mapStateToProps(state) {
  return {
    chgPwdStatus: state.auth.chgPwdStatus,
    user: state.settings.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: pwdObj => dispatch(chgPwd(pwdObj)),
    resetForm: () => dispatch(resetChgPwdForm())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChgPwdForm);
