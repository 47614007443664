import React, { Component } from 'react';
import { Button, Modal, Select, DatePicker, Tabs, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../common/ui/agGridUtils';
import {
  getCurrentWorkingDay,
  getPreviousWorkingDay
} from '../../../common/utils/DateUtils';
import 'ag-grid-enterprise';
import { DIALOG_FX_TRADES_REPORT } from '../portfolioConstants';
import { fxTradeReportColumn } from './GridColumnMap';
import moment from 'moment';
import client from '../api/client';
import _ from 'lodash';
import { moneyFormatter } from 'common/utils/valueFormatters';
import { numberStyle } from 'common/utils/styleChooser';

const Option = Select.Option;
const { RangePicker } = DatePicker;
class FxTradeReportDialog extends Component {
  constructor(props) {
    super(props);

    const lastWorkingDate = getCurrentWorkingDay();
    this.state = {
      loadStatus: 'READY',
      data: {},
      fundBooks: [],
      date: [lastWorkingDate, moment().add(30, 'days')],
      gridSettings: agGridUtils.createSettings({
        columnDefs: fxTradeReportColumn,
        floatingFilter: false,
        defaultColDef: {
          enableCellChangeFlash: false,
          minWidth: 25,
          sortable: true,
          resizable: true,
          filter: true
        },
        deltaRowDataMode: false,
        getRowNodeId: data => data.date,
        rowGroupPanelShow: false,
        pivotPanelShow: false,
        sideBar: {
          toolPanels: ['columns', 'filters']
        }
      }),
      isLoaded: false
    };
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_FX_TRADES_REPORT);
  };

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _init = () => {
    const { ui } = this.props;
    const { funds } = this.props.settings;
    const selectedFundBook = `${ui.selectedFund}-${ui.selectedBook}`;
    const fundBooks = [selectedFundBook];
    const fundBookOptions = [];
    funds.forEach(fundItem => {
      const books = fundItem.books ? fundItem.books : [];
      if (!_.isEmpty(books)) {
        let fundBook = {};
        fundBook.value = fundItem.name;
        fundItem.books.forEach(item => {
          fundBookOptions.push(fundItem.name + '-' + item.name);
        });
      }
    });

    if (_.isEmpty(fundBookOptions)) return;
    this.setState(
      {
        fundBooks,
        fundBookOptions
      },
      this._loadData
    );
  };

  _loadData = () => {
    const { fundBooks, date } = this.state;
    if (!date[0] || !date[1]) return;
    let preWorkingDate = moment().format('YYYY-MM-DD');
    const dateStart = date[0].format('YYYY-MM-DD');
    preWorkingDate = preWorkingDate > dateStart ? dateStart : preWorkingDate;
    const params = {
      fundBooks,
      lastWorkingDate: getPreviousWorkingDay(preWorkingDate).format(
        'YYYY-MM-DD'
      ),
      dateStart: date[0].format('YYYY-MM-DD'),
      dateTo: date[1].format('YYYY-MM-DD')
    };
    this.setState({
      isLoaded: true
    });
    client
      .loadFxTradeReport(params)
      .then(resp => {
        this.setState({
          data: resp,
          isLoaded: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoaded: false
        });
      });
  };

  onGridReady = (params, type) => {
    //this[type] = params.api;
    params.api.sizeColumnsToFit();
  };

  sizeColumnsToFit = type => {
    if (this[type]) {
      this[type].sizeColumnsToFit();
    }
  };

  onInputChange = value => {
    this.setState(
      {
        fundBooks: [value]
      },
      this._loadData
    );
  };

  _onDateChange = date => {
    this.setState(
      {
        date
      },
      this._loadData
    );
  };

  _onOpenChange = open => {
    if (open) {
      this._onDateChange([]);
    }
  };

  disabledDate = current => {
    const { date } = this.state;
    if (!date || date.length === 0) return false;
    return !(current.weekday() > 0 && current.weekday() < 6);
  };

  _createOpBar = () => {
    const { fundBooks, fundBookOptions, date } = this.state;
    if (_.isEmpty(fundBookOptions)) return;
    return (
      <div style={{ textAlign: 'right', width: '100%' }}>
        <Select
          mode="single"
          size="small"
          style={{ marginRight: '5px', width: '200px' }}
          onChange={value => {
            this.onInputChange(value);
          }}
          showSearch={true}
          value={fundBooks}
        >
          {fundBookOptions.map(t => (
            <Option value={t} key={t}>
              {t}
            </Option>
          ))}
        </Select>

        <RangePicker
          size="small"
          disabledDate={this.disabledDate}
          onCalendarChange={this._onDateChange}
          value={date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          style={{ marginRight: '10px' }}
          onOpenChange={this._onOpenChange}
          onChange={this._onDateChange}
        />
        <Button
          size="small"
          type="primary"
          onClick={this._loadData}
          style={{ marginLeft: '15px', marginRight: '30px' }}
        >
          Reload
        </Button>
      </div>
    );
  };

  _getData = type => {
    if (type === 'fixingData') return this._getFixingData();
    return this._getSettleData();
  };

  _getSettleData = () => {
    const { data, date } = this.state;
    if (_.isEmpty(date) || _.isEmpty(date[0]) || _.isEmpty(date[1]))
      return { data: [], columns: [] };
    if (_.isEmpty(data) || _.isEmpty(data['settleReport']))
      return { data: [], columns: fxTradeReportColumn };
    const dataMap = {};
    const { excludeCcyList, settleReport } = data;
    settleReport.forEach(e => {
      dataMap[moment(e.date).format('YYYY-MM-DD')] = e;
    });

    const firstDate = settleReport[0].date;
    const dateStart = moment(firstDate, 'YYYY-MM-DD');
    let result = [];
    for (let i = 0; ; i++) {
      const nextDay = dateStart.clone().add(i, 'days');
      const dateStr = nextDay.format('YYYY-MM-DD');
      if (dataMap[dateStr]) result.push(dataMap[dateStr]);
      if (!dataMap[dateStr] && ![0, 6].includes(nextDay.day()))
        result.push({ ...result[result.length - 1], date: dateStr });
      if (dateStr === date[1].format('YYYY-MM-DD')) break;
    }

    result = result.filter(
      r =>
        r.date >= date[0].format('YYYY-MM-DD') &&
        r.date <= date[1].format('YYYY-MM-DD')
    );

    const columns = _.isEmpty(result)
      ? []
      : Object.keys(result[result.length - 1])
          .filter(r => {
            const arr = _.map(result, r).filter(
              r => r !== 0 && !_.isNil(r) && _.isNumber(r)
            );
            return (
              !_.isEmpty(arr) &&
              !['date', 'total'].includes(r) &&
              !excludeCcyList.includes(r)
            );
          })
          .map(r => ({
            field: r,
            headerName: r,
            enableValue: true,
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle(),
            width: 80
          }));
    return {
      data: result,
      columns: [...fxTradeReportColumn, ...columns]
    };
  };

  _getFixingData = () => {
    const { data } = this.state;
    const ccyList = [];
    const dataByDate = {};
    if (_.isEmpty(data) || _.isEmpty(data['fixingData']))
      return { data: [], columns: fxTradeReportColumn };
    data['fixingData'].forEach(r => {
      if (!dataByDate[r.date]) dataByDate[r.date] = { date: r.date };
      dataByDate[r.date][r.currencyCode] = r.total;
      if (!ccyList.includes(r.currencyCode)) ccyList.push(r.currencyCode);
    });

    const contactCols = ccyList.map(r => ({
      field: r,
      headerName: r,
      enableValue: true,
      cellClass: 'number',
      valueFormatter: moneyFormatter,
      cellClassRules: numberStyle(),
      width: 100
    }));
    const columns = [...fxTradeReportColumn, ...contactCols];
    return {
      data: Object.values(dataByDate),
      columns
    };
  };

  _createDataGrid = type => {
    const { gridSettings, isLoaded } = this.state;
    const { data, columns } = this._getData(type);
    const rowData = _.orderBy(data, ['date'], ['asc']);
    return (
      <Spin tip="Loading..." spinning={isLoaded}>
        {!_.isEmpty(data) && (
          <div
            className={`ag-theme-balham-dark grid-wrapper`}
            style={{ height: '700px', width: '100%' }}
          >
            <AgGridReact
              {...gridSettings}
              rowData={rowData}
              columnDefs={columns}
              rowSelection="single"
              onGridReady={params => this.onGridReady(params, type)}
              getRowNodeId={data => data.date}
              groupIncludeTotalFooter={false}
              getContextMenuItems={[]}
            />
          </div>
        )}
      </Spin>
    );
  };

  render() {
    return (
      <Modal
        width={'1500px'}
        maskClosable={false}
        visible={true}
        onCancel={this.closeDialog}
        className="fxTradeReportDialog"
        bodyStyle={{
          height: '820px',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        footer={null}
      >
        <style>
          {` 
                  .fxTradeReportDialog .anticon {
                    color: gray !important;
                  }

                  .fxTradeReportDialog .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .fxTradeReportDialog .ant-picker{
                    background-color:#FF9E28;
                  }
                 
            `}
        </style>
        {this._createOpBar()}
        <Tabs tabPosition="top" key="settleCcyPosTabs">
          <Tabs.TabPane tab="Settle" key="settlePosTab">
            {this._createDataGrid('settleReport')}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Fixing(USD)" key="fixingTab">
            {this._createDataGrid('fixingData')}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    );
  }
}

export default FxTradeReportDialog;
