import React, { PureComponent } from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { Tabs } from 'antd';
import _ from 'lodash';
const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  'green',
  'red',
  'yellow',
  'coral',
  'crimson',
  'cadetblue',
  'darksalmon',
  'gold',
  'brown',
  'purple',
  'yellowgreen'
];
// const OTHER_COLORS = [
//   'crimson',
//   'cadetblue',
//   'darksalmon',
//   'gold',
//   'brown',
//   'purple',
//   'yellowgreen'
// ];

class GraphAnalysisPannel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      expenseDetailData: {
        innerData: [],
        outData: [],
        name: null
      },
      incomeDetailData: {
        outData: [],
        name: null
      }
    };
  }

  _getOutPieData = () => {
    const { data } = this.props;
    const all = data.filter(r => r.Broker === 'All');
    if (_.isEmpty(all)) return;
    return _.take(
      _.orderBy(
        data
          .filter(r => r.Broker !== 'All')
          .map(r => {
            return {
              name: r.Broker,
              value: _.round(Math.abs(r['Expense-All']), 2)
            };
          }),
        ['value'],
        ['desc']
      ),
      8
    );
  };

  _getOutPieIncomeData = () => {
    const { data } = this.props;
    const all = data.filter(r => r.Broker === 'All');
    if (_.isEmpty(all)) return;
    return _.take(
      _.orderBy(
        data
          .filter(r => r.Broker !== 'All')
          .map(r => {
            return {
              name: r.Broker,
              value: _.round(r['Income-All'], 2)
            };
          }),
        ['value'],
        ['desc']
      ),
      8
    );
  };

  _getOutPieAllData = () => {
    const { data } = this.props;
    const all = data.filter(r => r.Broker === 'All');
    if (_.isEmpty(all)) return;
    return _.take(
      _.orderBy(
        data
          .filter(r => r.Broker !== 'All')
          .map(r => {
            return {
              name: r.Broker,
              value: _.round(Math.abs(r['All-']), 2)
            };
          }),
        ['value'],
        ['desc']
      ),
      8
    );
  };

  onBrokerMouseOver = select => {
    const { data } = this.props;
    const { name } = select;
    const result = data.filter(r => r.Broker === name);
    if (_.isEmpty(result)) return;
    const e = result[0];
    const innerData = [];
    this.pushData(innerData, 'Commission', e['Expense-Commission']);
    this.pushData(innerData, 'SWAP', e['Expense-SWAP']);
    this.pushData(innerData, 'Cash stock Loan', e['Expense-Cash stock Loan']);

    const outData = [];
    this.pushData(outData, 'Commission', e['Expense-Commission']);
    this.pushData(
      outData,
      'Long Swap Benchmark',
      e['Expense-Long Swap Benchmark']
    );
    this.pushData(outData, 'Long Swap Spread', e['Expense-Long Swap Spread']);
    this.pushData(outData, 'FFS Benchmark', e['Expense-FFS Benchmark']);
    this.pushData(outData, 'FFS Spread', e['Expense-FFS Spread']);
    this.pushData(outData, 'Swap Stock Loan', e['Expense-Swap Stock Loan']);
    this.pushData(outData, 'CFD INT OLD', e['Expense-CFD INT OLD']);
    this.pushData(outData, 'Cash stock Loan', e['Expense-Cash stock Loan']);
    this.setState({
      expenseDetailData: {
        outData
      }
    });
  };

  onIncomeBrokerMouseOver = select => {
    const { data } = this.props;
    const { name } = select;
    const result = data.filter(r => r.Broker === name);
    if (_.isEmpty(result)) return;
    const e = result[0];
    const outData = [];
    this.pushData(outData, 'Income-Cash Interest', e['Income-Cash Interest']);
    this.pushData(outData, 'Income-MMF Interest', e['Income-MMF Interest']);
    this.pushData(
      outData,
      'Income-Short Sell Interest',
      e['Income-Short Sell Interest']
    );
    this.pushData(
      outData,
      'Income-Short Benchmark Rebate',
      e['Income-Short Benchmark Rebate']
    );
    this.pushData(outData, 'Income-CFD INT OLD', e['Income-CFD INT OLD']);
    this.setState({
      incomeDetailData: {
        outData,
        name
      }
    });
  };

  pushData = (arr, name, value) => {
    if (value) {
      arr.push({ name: name, value: _.round(Math.abs(value), 2) });
    }
  };

  _createGraphPanel = (outData, expenseDetailData) => {
    return (
      <>
        <div style={{ height: '100%', width: '100%', float: 'left' }}>
          <p style={{ color: 'white', textAlign: 'center', fontSize: '16px' }}>
            {expenseDetailData.name}
          </p>
          <ResponsiveContainer width="100%" height="100%" border="0">
            <PieChart width={400} height={400}>
              <Pie
                data={outData}
                dataKey="value"
                cx={140}
                cy={200}
                outerRadius={80}
                isAnimationActive={false}
                fill="#8884d8"
                label={data => {
                  return data.name;
                }}
                onClick={this.onBrokerMouseOver}
              >
                {/* <Pie data={innerData} dataKey="value" cx="50%" cy="50%" innerRadius={60} outerRadius={120} fill="#82ca9d" label /> */}
                {outData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Pie
                data={expenseDetailData.outData}
                dataKey="value"
                cx={390}
                cy={100}
                outerRadius={60}
                label={data => {
                  return data.name;
                }}
              >
                {expenseDetailData.outData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  };

  _createIncomeGraphPanel = () => {
    const { incomeDetailData } = this.state;
    const inComeData = this._getOutPieIncomeData();
    return (
      <>
        <div style={{ height: '100%', width: '100%', float: 'left' }}>
          <p style={{ color: 'white', textAlign: 'center', fontSize: '16px' }}>
            {incomeDetailData.name}
          </p>
          <ResponsiveContainer width="100%" height="100%" border="0">
            <PieChart width={400} height={400}>
              <Pie
                data={inComeData}
                dataKey="value"
                cx={150}
                cy={200}
                outerRadius={80}
                isAnimationActive={false}
                fill="#8884d8"
                label={data => {
                  return data.name;
                }}
                onClick={this.onIncomeBrokerMouseOver}
              >
                {/* <Pie data={innerData} dataKey="value" cx="50%" cy="50%" innerRadius={60} outerRadius={120} fill="#82ca9d" label /> */}
                {inComeData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Pie
                data={incomeDetailData.outData}
                dataKey="value"
                cx={400}
                cy={100}
                outerRadius={60}
                label={data => {
                  return data.name;
                }}
              >
                {incomeDetailData.outData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  };

  _createAllGraphPanel = () => {
    const allData = this._getOutPieAllData();
    return (
      <>
        <div style={{ height: '100%', width: '100%', float: 'left' }}>
          <ResponsiveContainer width="100%" height="100%" border="0">
            <PieChart width={400} height={400}>
              <Pie
                data={allData}
                dataKey="value"
                cx="50%"
                cy="40%"
                outerRadius={110}
                isAnimationActive={false}
                fill="#8884d8"
                label={data => {
                  return data.name;
                }}
              >
                {/* <Pie data={innerData} dataKey="value" cx="50%" cy="50%" innerRadius={60} outerRadius={120} fill="#82ca9d" label /> */}
                {allData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  };

  render() {
    const { expenseDetailData } = this.state;
    const outData = this._getOutPieData();
    return (
      <div id="graphAnalysisPannel" style={{ height: '100%' }}>
        <style>
          {`
            #graphAnalysisPannel .recharts-responsive-container {
              border: 0px !important;
            }
          `}
        </style>
        <Tabs tabPosition="top" key="TreasuryDashbordGraphTabs">
          <Tabs.TabPane tab="Expense" key="Expense">
            {this._createGraphPanel(outData, expenseDetailData)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Income" key="Income">
            {this._createIncomeGraphPanel()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="All" key="All">
            {this._createAllGraphPanel()}
          </Tabs.TabPane>
        </Tabs>

        {/* <div style={{ height: '50%', width: '50%', float:'left' }}>
                    <ResponsiveContainer width="100%" height="100%"  border="0">
                        <PieChart width={400} height={400}>
                            <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={150} fill="red" />
                            <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={0} outerRadius={90} fill="#82ca9d" label />
                        </PieChart>
                    </ResponsiveContainer>
                </div>

                <div style={{ height: '50%', width: '50%', float:'right' }}>
                    <ResponsiveContainer width="100%" height="100%" border="0">
                        <PieChart width={600} height={600}>
                            <Pie data={data01} dataKey="value" cx="50%" cy="50%" outerRadius={150} fill="blue" />
                            <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={0} outerRadius={90} fill="gray" label />
                        </PieChart>
                    </ResponsiveContainer>
                </div> */}
      </div>
    );
  }
}

export default GraphAnalysisPannel;
