import React, { PureComponent } from 'react';
import { Modal, Button, Spin, List } from 'antd';
import { DIALOG_VIEW_HELP } from '../../omsConstants';
import client from '../../api/client';

class ViewHelpDialog extends PureComponent {
  state = {
    isInitialized: false,
    help: []
  };

  componentDidMount() {
    client
      .getHelp()
      .then(res => this.setState({ isInitialized: true, help: res }));
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_VIEW_HELP);
  };

  render() {
    const { isInitialized, help } = this.state;

    return (
      <Modal
        width={900}
        maskClosable={false}
        title="View Help"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={!isInitialized}>
          {help.map(category => (
            <List
              size="small"
              header={<h5>{category.name}</h5>}
              key={category.name}
              bordered
              dataSource={category.steps}
              renderItem={item => <List.Item>{item}</List.Item>}
              style={{ marginBottom: '5px' }}
            />
          ))}
        </Spin>
      </Modal>
    );
  }
}

export default ViewHelpDialog;
