import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import MonthlyReviewItemComponent from './MonthlyReviewItemComponent';
import { Loader } from 'semantic-ui-react';

class MonthlyReviewGrid extends Component {
  constructor(props) {
    console.log('height:' + window.screen.height);
    super(props);
    this.state = {
      columnDefs: props.monthlyReviewGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      frameworkComponents: {
        monthlyReviewItemComponent: MonthlyReviewItemComponent
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      selectedItems: []
    };
  }

  getRowHeight = () => {
    return 130;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onRowClickedHandle = item => {
    const { monthlyReviewDetailDataReadonly } = this.props;
    const data = item['data'];
    if (
      monthlyReviewDetailDataReadonly &&
      monthlyReviewDetailDataReadonly['id'] === data.id
    ) {
      return;
    }
    this.props.loadMonthlyReviewDetail(data.id);
  };

  canEdit = data => {
    //get current user id
    const { user } = this.props;
    const userAccountId = user['userAccountId'];
    if (data['userId'] && data['userId'] === userAccountId) {
      if (data['status'] === 0) {
        return true;
      }
    }
    return false;
  };

  render() {
    const {
      monthliReviewData,
      isLoaded,
      className = 'grid-wrapper'
    } = this.props;

    const sortedRows = _.orderBy(monthliReviewData, ['yearMonth'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              frameworkComponents={this.state.frameworkComponents}
              context={this.state.context}
              sideBar={this.state.sideBar}
              getRowHeight={this.getRowHeight}
              onGridReady={this.onGridReady}
              onRowClicked={this.onRowClickedHandle}
            />
          )}
        </div>
      </div>
    );
  }
}

export default MonthlyReviewGrid;
