import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from 'app/reducers/rootReducer';
import io from 'socket.io-client';
import createSocketIoMiddleware from 'common/middlewares/socketIoMiddleware';
import thunkMiddleware from 'redux-thunk';
import config from 'common/config';

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware, configSocketIoMiddleware()];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];
  const composeEnhancer = composeWithDevTools(...storeEnhancers);

  const store = createStore(rootReducer, preloadedState, composeEnhancer);

  return store;
}

function configSocketIoMiddleware() {
  let socket = io(config.api.SOCKET_URL, {
    reconnectionDelay: 5000,
    forceNew: true,
    transports: ['polling', 'websocket']
  });
  return createSocketIoMiddleware(socket);
}
