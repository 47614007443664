import { createReducer } from 'common/utils/reducerUtils';
import EntityMap from 'entities/EntityMap';
import { LIVEDATA_SNAPSHOT, LIVEDATA_UPDATE } from './t0Constants';

const TABLE_ROUTE = 'ROUTE';
const extractTblData = (snapshot, tblName, converter = obj => obj) => {
  return snapshot
    .filter(row => row.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));
};

const mergeUpdates = (target, tblName, updates, converter = obj => obj) => {
  const upsertedEntities = updates
    .filter(u => ['Update', 'Add'].includes(u.type) && u.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));

  const deletedEntityKeys = updates
    .filter(u => u.type === 'Delete' && u.tableName === tblName)
    .map(u => u.key);

  return EntityMap.remove(
    EntityMap.merge(target, upsertedEntities),
    deletedEntityKeys
  );
};

export function initLiveData(state, payload) {
  const routes = extractTblData(payload, TABLE_ROUTE);

  return {
    ...state,
    isLoaded: true,
    routes: EntityMap.fromArray(routes)
  };
}

export function updateLiveData(state, payload) {
  const { isLoaded, routes } = state;

  const updatedRoutes = mergeUpdates(routes, TABLE_ROUTE, payload);

  return {
    ...state,
    isLoaded,
    routes: updatedRoutes
  };
}

const initialState = {
  isLoaded: true,
  routes: EntityMap.fromArray([])
};

export default createReducer(initialState, {
  [LIVEDATA_SNAPSHOT]: initLiveData,
  [LIVEDATA_UPDATE]: updateLiveData
});
