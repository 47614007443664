import React, { Component } from 'react';
import { Modal, Tabs, Select } from 'antd';
import 'ag-grid-enterprise';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import _ from 'lodash';

const TabPane = Tabs.TabPane;
const keyCols = ['fund', 'custodian', 'marketCountry'];
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class SpreadCompareDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadStatus: 'READY',
      data: [],
      fundMarketOptions: {},
      fundMarketMap: {}
    };
  }

  componentDidMount() {
    this._transferData();
  }

  _transferData = () => {
    const dataSet = {};
    const fundMarketOptions = {};
    _.orderBy(
      this.props.data,
      ['date', 'marketCountry'],
      ['desc', 'asc']
    ).forEach(r => {
      const key = this._genKey(r, keyCols);
      r.dataKey = `${r.custodian}_${r.marketCountry}`;
      if (!fundMarketOptions[r.fund]) fundMarketOptions[r.fund] = [];
      if (!fundMarketOptions[r.fund].includes(r.marketCountry))
        fundMarketOptions[r.fund].push(r.marketCountry);
      if (!dataSet[key]) dataSet[key] = r;
    });
    const data = _.orderBy(Object.values(dataSet), ['marketCountry'], ['asc']);
    this.setState({
      data,
      fundMarketOptions
    });
  };

  _genKey = (o, includeKeys) => {
    const entries = Object.entries(o);
    // 过滤掉需要排除的键
    const filteredEntries = entries.filter(([key]) =>
      includeKeys.includes(key)
    );
    // 将剩余的属性值拼成一个字符串，这里假设属性值是字符串或可以转换为字符串的类型
    const resultString = filteredEntries
      .map(([key, value]) => `${value}`)
      .join('_');
    return resultString;
  };

  _getRandomColor = count => {
    const brightnessThreshold = 150; // 亮度阈值，范围为 0 到 255
    const colors = [];

    while (colors.length < count) {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16); // 生成随机的颜色值，范围为 0 到 FFFFFF
      if (
        this.getBrightness(color) >= brightnessThreshold &&
        !colors.includes(color)
      ) {
        colors.push(color);
      }
    }

    return colors;
  };

  getBrightness = color => {
    const hexColor = color.replace('#', '');
    const red = parseInt(hexColor.substr(0, 2), 16); // 提取红色分量并转为十进制
    const green = parseInt(hexColor.substr(2, 2), 16); // 提取绿色分量并转为十进制
    const blue = parseInt(hexColor.substr(4, 2), 16); // 提取蓝色分量并转为十进制

    // 使用相对亮度算法计算亮度值
    return (red * 299 + green * 587 + blue * 114) / 1000;
  };

  _createTabs = () => {
    const { data } = this.state;
    if (!_.isEmpty(data)) {
      const fundMap = _.groupBy(data, 'fund');
      return (
        <Tabs defaultActiveKey="0" tabPosition={'top'}>
          {Object.keys(fundMap).map((r, index) => (
            <TabPane tab={r} key={index}>
              {this._createLineChart(fundMap[r].filter(r => r.spread < 100), r)}
            </TabPane>
          ))}
        </Tabs>
      );
    }
  };

  _onQueryChange = ({ fund, value }) => {
    this.setState({
      fundMarketMap: {
        ...this.state.fundMarketMap,
        [fund]: value
      }
    });
  };

  _createLineChart = (data, fund) => {
    const { fundMarketOptions, fundMarketMap } = this.state;
    if (_.isEmpty(fundMarketOptions)) return;
    const showData = data.filter(
      r =>
        _.isEmpty(fundMarketMap[fund]) ||
        r.marketCountry === fundMarketMap[fund]
    );
    return (
      <div style={{ height: '700px' }}>
        <div style={{ textAlign: 'right' }}>
          <Select
            allowClear
            mode="single"
            size="small"
            showSearch
            onChange={value => {
              this._onQueryChange({ fund, value });
            }}
            value={fundMarketMap[fund]}
            style={{ marginLeft: '5px', width: 250 }}
          >
            {_createUIOptions(fundMarketOptions[fund])}
          </Select>
        </div>
        <ResponsiveContainer width="90%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={showData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <XAxis
              dataKey={_.isEmpty(fundMarketMap[fund]) ? 'dataKey' : 'custodian'}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="spread"
              fill="#82ca9d"
              barSize={20}
              activeBar={<Rectangle fill="gold" stroke="purple" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  render() {
    return (
      <Modal
        width={'80%'}
        maskClosable={false}
        visible={true}
        onCancel={this.props.closeDialog}
        className="spreadCompareDialog"
        bodyStyle={{
          height: '80vh',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        footer={null}
      >
        {this._createTabs()}
      </Modal>
    );
  }
}

export default SpreadCompareDialog;
