import React, { Component } from 'react';
import _ from 'lodash';
import { riskReportAttributeColumns } from '../GridColumnMap';
import { AgGridReact } from 'ag-grid-react';
import { Spin } from 'antd';

class FactorGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: riskReportAttributeColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      context: {
        gridComponent: this
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      autoGroupColumnDef: {
        headerName: 'Risk Source',
        minWidth: 400,
        cellRendererParams: { suppressCount: true },
        sortable: false,
        pinned: 'left',
        lockPinned: true,
        cellClass: 'keyword'
      },
      groupDefaultExpanded: 4,
      getDataPath: function(data) {
        return data.orgHierarchy;
      }
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
    this.onRowDataChanged();
  };

  onSelectionChanged = () => {
    const {
      getFactorAsset,
      changeRiskSelectItemInput,
      riskReportQueryParams
    } = this.props;
    const selectedRows = this.gridApi.getSelectedRows();
    const dataMode = riskReportQueryParams.dataMode;
    const row = selectedRows[0];
    const exposure = row['activeResidualExposure'];
    const reportId = row['reportId'];
    const factor = row['source'];
    const activeVolatility = row['activeVolatility'];
    const selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedNode = selectedNodes[0];
    changeRiskSelectItemInput(row);
    getFactorAsset({ reportId, exposure, factor, activeVolatility, dataMode });
  };

  onRowDataChanged = () => {
    if (this.gridApi && this.selectedNode) {
      let nodes = this.gridApi.getRenderedNodes();
      const parent = this.selectedNode.parent;
      if (!_.isEmpty(nodes)) {
        nodes.forEach(element => {
          if (parent && element.id === parent.id) {
            element.setExpanded(true);
          }
        });
        nodes = this.gridApi.getRenderedNodes();
        nodes.forEach(element => {
          if (element.id === this.selectedNode.id) {
            this.gridApi.selectNode(element, true);
          }
        });
      }
    }
  };

  render() {
    const {
      riskReportFactorLoadingStatus,
      riskReportAttributionList,
      className = 'grid-wrapper'
    } = this.props;
    const screenHeight = window.screen.height;
    let height = '90';
    if (screenHeight <= 768) {
      height = 85;
    }
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {riskReportFactorLoadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{ width: '100%', height: `${height}%` }}
          >
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={riskReportAttributionList}
              suppressAggFuncInHeader={true}
              deltaRowDataMode={false}
              enableRangeSelection={true}
              //rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              getDataPath={this.state.getDataPath}
              treeData={true}
              animateRows={true}
              groupDefaultExpanded={this.state.groupDefaultExpanded}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              sideBar={this.state.sideBar}
              // events
              onSelectionChanged={this.onSelectionChanged}
              onGridReady={this.onGridReady}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FactorGrid;
