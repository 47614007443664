import React, { Component } from 'react';
import { Modal, Button, Checkbox, Input } from 'antd';
import { DIALOG_TAG_MODAL } from '../portfolioConstants';
import _ from 'lodash';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import { tagColumns } from './GridColumnMap';
import client from '../api/client';
import { isAdminRole } from 'common/utils/DomainUtils';

class TagDialog extends Component {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    this.state = {
      tagsGridWrapperStyle: {
        width: '100%',
        height: '450px',
        marginTop: '5px',
        border: 'solid 2px grey',
        padding: '2px'
      },
      hotTableSetting: this._createRoutesGridSettings(),
      submitStatus: 'READY',
      data: [],
      filterData: [],
      copyToAll: true,
      destSortConfigs: {}
    };
  }

  componentDidMount() {
    const params = this._initFundBook();
    this._initTags(params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_TAG_MODAL);
  };

  _initFundBook = () => {
    const { positions, ui, settings } = this.props;
    const { fundBooks, user } = settings;
    let selectedFundBook = `${ui.selectedFund}-${ui.selectedBook}`;
    const manageFundBookMap = {};

    const selectedFundBookInfo = fundBooks[selectedFundBook] || {};
    const bookManager = isAdminRole(user.role)
      ? selectedFundBookInfo['bookManager']
      : user.englishName;

    const values = _.values(fundBooks).filter(
      r =>
        (bookManager === r.bookManager &&
          r.masterFundBook === null &&
          (bookManager !== 'Wang Qiang' && r.fundBookType === null)) ||
        (bookManager === 'Wang Qiang' &&
          r.masterFundBook === null &&
          r.fundBookType === 'W') ||
        (r.bookCode === 'PIPI240424' &&
          ['chase.ding', 'jin.qian'].includes(user.englishName)) ||
        (r.bookCode === 'T96' &&
          ['chase.ding', 'ethan.du'].includes(user.englishName))
    );
    values.forEach(r => {
      const fundBook = r.fundBookType
        ? `${r.fundCode}-${r.fundBookType}`
        : r.code;
      selectedFundBook =
        selectedFundBook === r.code ? fundBook : selectedFundBook;
      manageFundBookMap[r.code] = {
        fundBook,
        fundCode: r.fundCode,
        bookCode: r.fundBookType ? r.fundBookType : r.bookCode
      };
    });

    const arr = selectedFundBook.split('-');
    const bookCode = arr[0] === arr[1] ? 'W' : arr[1];
    const currentFundBook = { fundCode: arr[0], bookCode };

    let filterPositionList = positions.filter(
      r =>
        manageFundBookMap[`${r.fundCode}-${r.bookCode}`] &&
        this._filterPosition(r) &&
        !_.isEmpty(r.ticker)
    );
    filterPositionList = filterPositionList.map(r => {
      const item = manageFundBookMap[`${r.fundCode}-${r.bookCode}`];
      return {
        ...r,
        extBookCode: item.bookCode
      };
    });
    filterPositionList = filterPositionList.filter(
      r => r.extBookCode === currentFundBook.bookCode
    );

    return {
      selectedFundBook,
      filterPositionList,
      manageFundBookMap,
      currentFundBook
    };
  };

  _filterPosition = p => {
    const { ui } = this.props;
    return (
      p.fundCode === ui.selectedFund &&
      p.ticker !== 'FLAT_POS' &&
      (!ui.excludeCashPos
        ? true
        : !['TRD_CSH', 'FX_PROP', 'FWRD_PROP', 'FWRD_HEDGE', 'PTH'].includes(
            p.positionTypeCode
          ))
    );
  };

  _initTags = positionData => {
    const {
      filterPositionList,
      currentFundBook,
      manageFundBookMap
    } = positionData;
    const { ui } = this.props;
    client
      .loadTagDataList(currentFundBook)
      .then(resp => {
        const tagMap = {};
        if (resp && resp.length > 0) {
          resp.forEach(item => {
            const key = `${item.Strategy}_${item.BbgTicker}_Tag${item.Level}`;
            tagMap[key] = item.Value;
          });
        }

        const selectedPosition = ui.selectedPosition;
        selectedPosition &&
          filterPositionList.forEach(item => {
            if (
              item.ticker === selectedPosition.ticker &&
              item.bookCode === selectedPosition.bookCode &&
              item.fundCode === selectedPosition.fundCode &&
              item.strategy === selectedPosition.strategy
            ) {
              item.sortWeight = 100;
            } else {
              item.sortWeight = 0;
            }
          });

        let data = _.orderBy(
          filterPositionList,
          [
            'sortWeight',
            'bookCode',
            'strategy',
            'quantityDirection',
            'quantityEnd'
          ],
          ['desc', 'asc', 'asc', 'asc', 'desc']
        );
        data.forEach(item => {
          item.tag1 = this.getTagValue(item, tagMap, 'Tag1');
          item.tag2 = this.getTagValue(item, tagMap, 'Tag2');
          item.tag3 = this.getTagValue(item, tagMap, 'Tag3');
          item.tag4 = this.getTagValue(item, tagMap, 'Tag4');
          item.tag5 = this.getTagValue(item, tagMap, 'Tag5');
        });
        this.setState({
          data,
          manageFundBookMap,
          currentFundBook,
          filterData: data
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  getTagValue = (item, tagMap, tag) => {
    let ticker = item.ticker;
    if (item.instrumentClass === 'EQTY_FT') {
      ticker = item.undlTicker;
    } else if (
      item.instrumentClass === 'CCY' &&
      item.positionTypeCode !== 'TRD_CSH'
    ) {
      ticker = item.securityCode;
    }
    const orgTicker = item.ticker;
    const tag1Key = `${item.strategy}_${ticker}_${tag}`;
    const orgTag1Key = `${item.strategy}_${orgTicker}_${tag}`;
    return tagMap[tag1Key] ? tagMap[tag1Key] : tagMap[orgTag1Key];
  };

  _createRoutesGridSettings = () => {
    const columns = tagColumns.map(c => {
      return {
        ...c
      };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _beforeColumnSort = (currSortConfigs, destSortConfigs) => {
    const columnSortPlugin = (
      this.hotTblRef.current.hotInstance || {}
    ).getPlugin('columnSorting');
    columnSortPlugin.setSortConfig(destSortConfigs);

    if (_.isEmpty(destSortConfigs)) return false;
    const firstSortConfig = _.first(destSortConfigs);
    if (!firstSortConfig) return false;

    const { filterData } = this.state;
    const { column, sortOrder } = firstSortConfig;
    const field = (tagColumns[column] || {}).data;
    const sortedDatas = _.orderBy(filterData, [field], [sortOrder]);

    this.setState({ filterData: sortedDatas, destSortConfigs });

    return false;
  };

  createHotTable = () => {
    const { tagsGridWrapperStyle, hotTableSetting, filterData } = this.state;
    return (
      <div style={tagsGridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={filterData}
          manualColumnResize={true}
          {...hotTableSetting}
          filters={true}
          beforeColumnSort={this._beforeColumnSort}
          columnSorting={{
            indicator: false,
            headerAction: true
          }}
        ></HotTable>
      </div>
    );
  };

  onCopyToAllChange = e => {
    this.setState({
      copyToAll: e.target.checked
    });
  };

  _createCopyToAllCheckBox = () => {
    return (
      <Checkbox
        key="copyToAllCheckBox"
        defaultChecked
        onChange={this.onCopyToAllChange}
      >
        Copy to all books
      </Checkbox>
    );
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, data } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    return _.isEmpty(data) ? '' : submitBtn;
  };

  _onSubmit = () => {
    const { data } = this.state;
    if (_.isEmpty(data)) return;
    const fundBookList = this._buildSubmitFundBookList();
    const submitData = [];
    data.forEach(r => {
      let securityId = r.securityId;
      let bbgTicker = r.ticker;
      if (r.instrumentClass === 'EQTY_FT') {
        securityId = r.undlSecurityId;
        bbgTicker = r.undlTicker;
      } else if (
        r.instrumentClass === 'CCY' &&
        r.positionTypeCode !== 'TRD_CSH'
      ) {
        securityId = null;
        bbgTicker = r.securityCode;
      }
      const strategy = r.strategy;
      const tag1 = r.tag1 ? r.tag1.toUpperCase() : null;
      const tag2 = r.tag2 ? r.tag2.toUpperCase() : null;
      const tag3 = r.tag3 ? r.tag3.toUpperCase() : null;
      const tag4 = r.tag4 ? r.tag4.toUpperCase() : null;
      const tag5 = r.tag5 ? r.tag5.toUpperCase() : null;

      const ele = {
        securityId,
        bbgTicker,
        strategy
      };
      submitData.push({ ...ele, level: 1, value: tag1 });
      submitData.push({ ...ele, level: 2, value: tag2 });
      submitData.push({ ...ele, level: 3, value: tag3 });
      submitData.push({ ...ele, level: 4, value: tag4 });
      submitData.push({ ...ele, level: 5, value: tag5 });
    });
    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .saveTagDataList({
        fundBookList,
        data: submitData
      })
      .then(() => {
        this.setState({ submitStatus: 'READY' });
        this.closeDialog();
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _buildSubmitFundBookList = () => {
    const { currentFundBook, manageFundBookMap, copyToAll } = this.state;
    if (copyToAll) {
      const fundBookMap = {};
      _.map(manageFundBookMap, (value, key) => {
        fundBookMap[value.fundBook] = {
          fundCode: value.fundCode,
          bookCode: value.bookCode
        };
      });
      return _.values(fundBookMap);
    } else {
      return [
        {
          ...currentFundBook
        }
      ];
    }
  };

  onFilterTickerChange = e => {
    const { data, destSortConfigs } = this.state;
    const value = e.target.value ? _.upperCase(e.target.value) : null;
    const filterData =
      data && value
        ? data.filter(r => _.upperCase(r.ticker).indexOf(value) >= 0)
        : data;
    this.setState(
      {
        filterTicker: value,
        filterData
      },
      () => {
        this._beforeColumnSort(null, destSortConfigs);
      }
    );
  };

  _filterData = value => {
    // if(!this.hotTblRef.current.hotInstance) return;
    // const handsontableInstance = this.hotTblRef.current.hotInstance || {};
    // const filters = handsontableInstance.getPlugin('filters');
    // if(filters != null){
    //   filters.removeConditions(4);
    //   filters.addCondition(4, 'contains', [value]);
    //   filters.filter();
    //   handsontableInstance.render();
    // }
  };

  _createFilterInput = () => {
    return (
      <div style={{ textAlign: 'right', width: '100%' }}>
        <Input
          placeholder="filter by ticker"
          style={{ width: '250px' }}
          onChange={this.onFilterTickerChange}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <style>
          {`
                .ui.modals {
                  display: flex !important;
                }
                .ui.modal {
                  margin-top: 0px !important;
                }
              `}
        </style>
        <Modal
          width={1600}
          maskClosable={false}
          title={`Tag`}
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            this._createCopyToAllCheckBox(),
            this._createSubmitBtn(this._onSubmit),
            <Button
              key="close"
              type="primary"
              onClick={this.closeDialog}
              style={{ marginLeft: '10px' }}
            >
              Close
            </Button>
          ]}
        >
          {this._createFilterInput()}
          {this.createHotTable()}
        </Modal>
      </div>
    );
  }
}

export default TagDialog;
