import React, { PureComponent } from 'react';
import { Modal, Button, DatePicker, Select, Spin } from 'antd';
import { DIALOG_CONTRACT_ROLLOVER } from '../../omsConstants';
import client from '../../api/client';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import _ from 'lodash';
import moment from 'moment';
import { contractRolloverTableColumns } from './GridColumnMap';
import { Message } from 'semantic-ui-react';
import { isInternalFund } from '../../../../common/utils/DomainUtils';
import { getCurrentWorkingDay } from '../../../../common/utils/DateUtils';
import { toExcel } from '../../../../common/utils/ExcelUtils';

const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};

class ContractRolloverDialog extends PureComponent {
  state = {
    isInited: true,
    submitStatus: 'READY',
    gridWrapperStyle: {
      width: '100%',
      height: '400px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    params: {
      date: moment().format('YYYY-MM-DD'),
      selectedFunds: ['CVF'],
      selectedCustodians: []
    },
    fundOptions: [],
    custodianOptions: [],
    contractList: [],
    warningMsgs: [],
    settings: hotTableUtils.createSettings({
      columns: contractRolloverTableColumns,
      rowHeaders: true,
      contextMenu: ['remove_row']
    }),
    excelConfig: {
      CITIC: [
        { headerName: '合约编号', field: 'contractId' },
        { headerName: '证券代码', field: 'ticker' },
        { headerName: '证券名称', field: 'tickerName' },
        { headerName: '展期数量', field: 'qty' },
        { headerName: '备注', field: 'remark' },
        { headerName: '展期日期', field: 'maturityDate' }
      ]
    }
  };

  componentDidMount() {
    this._init();
  }

  _init = () => {
    const { params } = this.state;
    const { selectedFunds, selectedCustodians } = params;
    this.setState({
      isInited: false
    });
    Promise.all([client.getRolloverContracts(params), client.getPthPos(params)])
      .then(([resp, holdingResp]) => {
        const holdings = this._getPthHoldings(holdingResp);
        const data = this._buildData(resp, holdings);
        const fundOptions = [];
        const custodianOptions = [];
        if (!_.isEmpty(data)) {
          data.forEach(r => {
            if (!fundOptions.includes(r.fund)) fundOptions.push(r.fund);
            if (
              selectedFunds.includes(r.fund) &&
              !custodianOptions.includes(r.custodian)
            )
              custodianOptions.push(r.custodian);
          });
        }
        const contractList = _.isEmpty(data)
          ? []
          : _.orderBy(
              data.filter(
                r =>
                  selectedFunds.includes(r.fund) &&
                  (_.isEmpty(selectedCustodians) ||
                    selectedCustodians.includes(r.custodian))
              ),
              ['ticker', 'contractId'],
              ['asc', 'asc']
            );
        this.setState({
          contractList,
          fundOptions,
          custodianOptions,
          isInited: true
        });
      })
      .catch(err => {
        this.setState({
          isInited: true
        });
        console.log(err);
      });
  };

  _buildData = (data, holdings) => {
    const { date } = this.state.params;
    if (_.isEmpty(data)) return [];
    const ctTradeDate = moment(date)
      .add(1, 'days')
      .format('YYYY-MM-DD');
    const dataMap = {};
    const workDate = moment(getCurrentWorkingDay(date)).format('YYYY-MM-DD');
    data.forEach(e => {
      const { fund, book, ticker, accountCode } = e;
      const key = `${fund}-${book}-${ticker}-${accountCode}`;
      if (!dataMap[key]) dataMap[key] = [];
      const item = {
        ...e,
        pthQty: 0,
        newCtQty: e.qty,
        closePTHQty: 0,
        ctTradeDate
      };
      dataMap[key].push(item);
    });
    if (!_.isEmpty(holdings)) {
      holdings.forEach(r => {
        const {
          fundCode,
          bookCode,
          ticker,
          custodianAccountCode,
          quantityStart
        } = r;
        const key = `${fundCode}-${bookCode}-${ticker}-${custodianAccountCode}`;
        if (!_.isEmpty(dataMap[key])) {
          const dataList = dataMap[key];
          let pthQty = Math.abs(quantityStart);
          dataList.forEach(e => {
            const { qty } = e;
            if (qty >= pthQty) {
              e.pthQty = pthQty;
              e.closePTHQty = pthQty - (pthQty % 100);
              e.newCtQty = e.qty - e.closePTHQty;
              e.closePTHDate = e.closePTHQty > 0 ? workDate : null;
              pthQty = 0;
            } else if (qty < pthQty) {
              e.pthQty = qty;
              e.closePTHQty = e.pthQty;
              e.newCtQty = e.qty - e.closePTHQty;
              e.closePTHDate = workDate;
              pthQty = pthQty - qty;
            }
          });
        }
      });
    }
    const contractList = [];
    Object.values(dataMap).forEach(r => contractList.push(...r));
    return _.orderBy(contractList, ['ticker'], ['asc']);
  };

  _getPthHoldings = holdings => {
    if (holdings) {
      const filteredHoldings = holdings.filter(h => isInternalFund(h.fundCode));
      return filteredHoldings.filter(h => h.positionTypeCode === 'PTH');
    }

    return [];
  };

  _afterCellChange = (value, action) => {
    if (_.isEmpty(value)) return;
    const realChanges = value.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (_.isEmpty(realChanges)) return;
    this._validate();
  };

  _validate = () => {
    const { contractList } = this.state;
    if (_.isEmpty(contractList)) return false;
    const warnings = [];
    contractList.forEach((r, index) => {
      if (
        !_.isEmpty(r.newMaturityDate) &&
        !_.isNil(r.borrowRate) &&
        r.newCtQty > 0 &&
        _.isEmpty(r.contractId)
      ) {
        warnings.push(`[${index}] Contract Id is null`);
      }
    });
    this.setState({
      warningMsgs: warnings
    });
  };

  _createErrorsPanel = () => {
    const { warningMsgs } = this.state;

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(warningMsgs) && (
          <Message warning list={warningMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  _createTable = () => {
    const { contractList, gridWrapperStyle, settings, isInited } = this.state;

    return (
      <>
        <Spin spinning={!isInited}>
          <div style={gridWrapperStyle}>
            {!_.isEmpty(contractList) && (
              <HotTable
                ref={this.hotTblRef}
                data={contractList}
                manualColumnResize={true}
                {...settings}
                columnSorting={true}
                afterChange={this._afterCellChange}
              />
            )}
          </div>
        </Spin>
      </>
    );
  };

  _createFilter = () => {
    const { fundOptions, custodianOptions } = this.state;
    const { date, selectedFunds, selectedCustodians } = this.state.params;
    return (
      <div style={{ textAlign: 'right' }}>
        <Select
          showSearch
          mode="multiple"
          style={{ marginLeft: '5px', width: '280px' }}
          value={selectedFunds}
          onChange={value => {
            this._onInputChange({
              name: 'selectedFunds',
              value: value
            });
          }}
          placeholder="Select Fund"
        >
          {_createUIOptions(fundOptions)}
        </Select>
        <Select
          showSearch
          mode="multiple"
          style={{ marginLeft: '5px', width: '280px' }}
          value={selectedCustodians}
          onChange={value => {
            this._onInputChange({
              name: 'selectedCustodians',
              value: value
            });
          }}
          placeholder="Select Custodian"
        >
          {_createUIOptions(custodianOptions)}
        </Select>
        <DatePicker
          value={moment(date, 'YYYY-MM-DD')}
          format="YYYY-MM-DD"
          style={{ width: '200px', marginLeft: '5px' }}
          //disabledDate={this.disabledDate}
          onChange={(date, dateString) =>
            this._onInputChange({
              name: 'date',
              value: dateString
            })
          }
        />
      </div>
    );
  };

  disabledDate = date => {
    return date < moment().subtract(1, 'days');
  };

  _onInputChange = ({ name, value }) => {
    const { params } = this.state;
    const updateParams = {
      ...params,
      [name]: value
    };
    this.setState(
      {
        params: updateParams
      },
      this._init
    );
  };

  _onSubmit = () => {
    const data = this._getSubmitContractRollover();
    if (_.isEmpty(data)) return;
    client
      .bookRequests(data)
      .then(() => {
        this.setState({ submitStatus: 'SUBMITTING' });
        this.closeDialog();
      })
      .catch(err => {
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _getSubmitContractRollover = () => {
    const { contractList } = this.state;
    const submitData = [];
    if (_.isEmpty(contractList)) return null;
    contractList.forEach(r => {
      if (
        !_.isEmpty(r.newMaturityDate) &&
        !_.isNil(r.borrowRate) &&
        r.newCtQty > 0
      ) {
        const data = this._buildRequests(
          r,
          r.newMaturityDate,
          r.newCtQty,
          'NC',
          'CONTRACT',
          'SS',
          r.ctTradeDate
        );
        submitData.push(data);
      }
      if (
        ((!_.isEmpty(r.newMaturityDate) && !_.isNil(r.borrowRate)) ||
          r.newCtQty === 0) &&
        r.closePTHQty > 0 &&
        !_.isEmpty(r.closePTHDate)
      ) {
        const data = this._buildRequests(
          r,
          null,
          r.closePTHQty,
          'CP',
          'PTH',
          'BC',
          r.closePTHDate
        );
        submitData.push(data);
      }
    });
    return submitData;
  };

  _buildRequests = (item, date, qty, suffix, reasonCode, side, tradeDate) => {
    const maturityDate = _.isEmpty(date)
      ? null
      : moment(date, 'YYYYMMDD').format('YYYY-MM-DD');
    return {
      tradeDate,
      maturityDate,
      side,
      bbgTicker: item.ticker,
      bookRequestId: `${item.externalRequestId}_${suffix}`,
      fund: item.fund,
      team: item.book,
      qtyShare: qty,
      avgPrice: item.avgPrice,
      counterParty: item.counterParty,
      custodian: item.custodian,
      settlementCondition: item.settlementCondition,
      cfd: item.cfd,
      settlementCurrency: item.settleCurrency,
      fxRate: item.fxRate,
      borrowRate:
        reasonCode === 'PTH' || _.isNil(item.borrowRate)
          ? null
          : _.round(item.borrowRate / 100, 4),
      strategy: item.strategy,
      reasonCode,
      remark: reasonCode === 'PTH' ? null : item.remark
    };
  };

  _exportToExcel = () => {
    const { excelConfig, params } = this.state;
    const data = this._buildExcelData();
    if (_.isEmpty(data)) return;
    toExcel({
      config: excelConfig['CITIC'],
      sourceData: data,
      fileName: `citic template_${params.date}`
    });
  };

  _buildExcelData = () => {
    const { contractList } = this.state;
    const sourceDataMap = {};
    contractList.forEach((r, index) => {
      const { contractId, ticker, newCtQty, newMaturityDate } = r;
      if (!_.isEmpty(newMaturityDate) && newCtQty > 0) {
        const tickerArr = ticker.split(' ');
        const tickerCode = tickerArr.length > 0 ? tickerArr[0] : ticker;
        const key = !_.isEmpty(contractId)
          ? `${contractId}-${tickerCode}`
          : index;
        if (!sourceDataMap[key]) {
          sourceDataMap[key] = {
            contractId,
            ticker: tickerCode,
            tickerName: '',
            qty: newCtQty,
            remark: '',
            maturityDate: newMaturityDate
          };
        } else {
          const item = sourceDataMap[key];
          sourceDataMap[key] = {
            ...item,
            qty: newCtQty + item.qty
          };
        }
      }
    });

    return Object.values(sourceDataMap);
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_CONTRACT_ROLLOVER);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    return (
      <Modal
        width={1750}
        maskClosable={false}
        title="Equity Contract Rollover"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          <Button
            key="exportExcel"
            type="primary"
            onClick={this._exportToExcel}
          >
            Export excel
          </Button>,
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        {this._createFilter()}
        {this._createTable()}
        {this._createErrorsPanel()}
      </Modal>
    );
  }
}

export default ContractRolloverDialog;
