import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const loadSettings = name => {
  return apiClient.get(`${config.api.PXY_URL}/settings/getSettings`);
};

const client = {
  loadSettings
};

export default client;
