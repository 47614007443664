import {
  longTextShowRenderer,
  lsfRenderer
} from 'common/ui/renderers/hotTable/renderers';

export const companyStrategyListGridColumns = [
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'keyword'
  }
];

export const companyStrategyDetailGridColumns = [
  {
    field: 'pairName',
    headerName: 'Name',
    cellClass: 'keyword',
    width: 40,
    enableRowGroup: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'non-number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        LONG: '#16ab39',
        SHORT: 'red'
      }
    }
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    width: 60,
    cellClass: 'keyword'
  },
  {
    field: 'tickerName',
    headerName: 'Name',
    width: 90,
    cellClass: 'non-number'
  },
  {
    field: 'reason',
    headerName: 'Reason',
    width: 280,
    cellClass: 'non-number'
  }
];

export const companyStrategyDetailTableColumns = [
  {
    data: 'type',
    headerName: ' ',
    type: 'dropdown',
    width: 50,
    renderer: lsfRenderer,
    source: ['LONG', 'SHORT']
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    type: 'text',
    width: 80
  },
  {
    data: 'tickerName',
    headerName: 'Name',
    type: 'text',
    width: 150
  },
  {
    data: 'reason',
    headerName: 'Reason',
    type: 'text',
    width: 200,
    renderer: longTextShowRenderer
  }
];
