import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { Button } from 'semantic-ui-react';
import { Switch, DatePicker, Divider, Radio, Row, Col } from 'antd';
import PercentBarComponent from '../../../../common/ui/renderers/agGrid/PercentBarComponent';
import TableTooltipComponent from '../../../../common/ui/renderers/agGrid/TableTooltipComponent';
import JsonTooltipComponent from '../../../../common/ui/renderers/agGrid/JsonTooltipComponent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import ClickLabelCompent from '../../../../common/ui/renderers/agGrid/ClickLabelCompent';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { OMS_VIEW_MODE_HIST, OMS_VIEW_MODE_LIVE } from '../../omsConstants';
import operations from './Operations';
import Options from '../../entities/Options';
import _ from 'lodash';
import numeral from 'numeral';
import AlgoCxlBar from './AlgoCxlBar';
import CrossInfoBar from './CrossInfoBar';
import client from '../../api/client';

const { RangePicker } = DatePicker;
class OrderGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.settings.orderGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 25,
        filter: 'agTextColumnFilter',
        checkboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelectionFilteredOnly: true,
        suppressMenu: true,
        sortable: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number'
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        tagComponent: TagComponent,
        tableTooltipComponent: TableTooltipComponent,
        jsonTooltipComponent: JsonTooltipComponent,
        clickLabelCompent: ClickLabelCompent
      },
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'order-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedOrderKeys = selectedRows.map(r => r.refId);

    this.props.selectOrders(selectedOrderKeys);
  };

  clearSelection = () => {
    this.gridApi.deselectAll();
  };

  getContextMenuItems = params => {
    if (params.node == null) return [];

    const {
      selectedOrders,
      ui: { viewMode, requestType }
    } = this.props;
    const ctx = { viewMode, requestType, orders: selectedOrders };

    const menuItems = [];
    operations.forEach(op => {
      op.subOps
        .filter(subOp => !subOp.disabledFn(ctx))
        .forEach(subOp =>
          menuItems.push({
            name: subOp.header,
            action: () => this.props.openDialog(subOp.dialogCode, subOp.info)
          })
        );

      menuItems.push('separator');
    });

    // Add built-in menu items.
    menuItems.push({
      name: 'Publish',
      action: () => this._publishFilledEvent()
    });
    menuItems.push('export');
    return menuItems;
  };

  _publishFilledEvent = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedOrderKeys = selectedRows.map(r => r.refId);
    if (!_.isEmpty(selectedOrderKeys)) {
      client
        .publishOrderFilledMsg(selectedOrderKeys)
        .then(resp => console.log(resp))
        .catch(err => console.log(err));
    }
  };

  _filterOrders = ({ orders, ui: { filters } }) => {
    const handleOrders = orders.map(r => {
      const filterLocates = !_.isEmpty(r.locates)
        ? r.locates.filter(r => r.filled > 0)
        : [];
      if (r.side === 'SHRT' && !_.isEmpty(filterLocates)) {
        const item = _.orderBy(filterLocates, ['borrowRate'], ['asc'])[0];
        return {
          ...r,
          bestLocate: `${item.broker}:${item.borrowRate}`
        };
      }
      return {
        ...r
      };
    });
    return handleOrders.filter(o =>
      Object.keys(filters).reduce(
        (acc, k) =>
          acc && (filters[k].length === 0 || filters[k].includes(o[k])),
        true
      )
    );
  };

  _createRadioButtons = codes => {
    return codes.map(c => (
      <Radio.Button key={c} value={c}>
        {c} ({this._getOrderLength(c)})
      </Radio.Button>
    ));
  };

  _getOrderLength = reqType => {
    const { ordersLengthByReqType } = this.props;
    let length = 0;
    ordersLengthByReqType.forEach(item => {
      if (item['reqType'] === reqType) {
        length = item['length'];
      }
    });
    return length;
  };

  _onChangeRequestType = e => {
    const { changeRequestType } = this.props;
    const requestType = e.target.value;
    changeRequestType(requestType);
  };

  onOpenChange = open => {
    if (open) {
      this.props.changeViewDate([]);
    }
  };

  _createSwitchSrv = () => {
    return (
      <Switch
        checked={this.props.ui.useOmsSrv}
        checkedChildren="NEW"
        unCheckedChildren="OLD"
        onChange={this.props.toggleUseOmsSrvMode}
      ></Switch>
    );
  };

  _createViewModeButtons = () => {
    const { ui, toggleViewMode, changeViewDate } = this.props;
    return (
      <span key="viewMode">
        {/*{ui.viewMode === OMS_VIEW_MODE_LIVE && this._createSwitchSrv()}*/}
        <Switch
          style={{ marginLeft: '5px' }}
          checked={ui.viewMode === OMS_VIEW_MODE_LIVE}
          checkedChildren="LIVE"
          unCheckedChildren="HIST"
          defaultChecked
          onChange={toggleViewMode}
        />

        {ui.viewMode === OMS_VIEW_MODE_HIST && (
          <RangePicker
            size="small"
            style={{ width: '220px' }}
            disabledDate={this.disabledDate}
            onCalendarChange={changeViewDate}
            value={ui.selectedDate}
            format={'YYYY-MM-DD'}
            allowClear={false}
            onOpenChange={this.onOpenChange}
            onChange={changeViewDate}
          />
        )}

        {ui.viewMode === OMS_VIEW_MODE_HIST && (
          <Button
            size="mini"
            icon="search"
            color="green"
            onClick={this.getOrderBlotter}
            style={{ marginBottom: '3px', marginTop: '3px' }}
          />
        )}
      </span>
    );
  };

  _createButtons = () => {
    const {
      selectedOrders,
      ui: { viewMode, requestType }
    } = this.props;
    const ctx = { viewMode, requestType, orders: selectedOrders };

    const buttons = [];
    operations.forEach(op => {
      const visibleSubOps = op.subOps.filter(
        subOp => (!subOp.hideFn || !subOp.hideFn(ctx)) && !subOp.menuOnly
      );

      visibleSubOps.forEach(subOp =>
        buttons.push(
          <Button
            size="mini"
            compact={true}
            key={subOp.key}
            content={subOp.header}
            color={subOp.color}
            onClick={() => this.props.openDialog(subOp.dialogCode, subOp.info)}
            disabled={subOp.disabledFn(ctx)}
            style={{ marginBottom: '4px', marginTop: '4px' }}
          />
        )
      );

      if (!_.isEmpty(visibleSubOps))
        buttons.push(<Divider key={op.key} type="vertical" />);
    });

    // Add view mode toggle buttons.
    buttons.push(this._createViewModeButtons());

    return buttons;
  };

  getOrderBlotter = () => {
    const { selectedDate } = this.props.ui;
    this.props.getOrderBlotter({
      startDate: selectedDate[0].format('YYYY-MM-DD'),
      endDate: selectedDate[1].format('YYYY-MM-DD')
    });
  };

  onCellContextMenu = evt => {
    if (evt.node && !evt.node.isSelected()) {
      this.gridApi.deselectAll();
      evt.node.setSelected(true);
    }
  };

  disabledDate = current => {
    const { ui } = this.props;
    if (!ui.selectedDate || ui.selectedDate.length === 0) {
      return false;
    }
    const tooLate =
      ui.selectedDate[0] && current.diff(ui.selectedDate[0], 'days') > 7;
    const tooEarly =
      ui.selectedDate[1] && ui.selectedDate[1].diff(current, 'days') > 7;
    return tooEarly || tooLate;
  };

  _createOperationBar = () => {
    const { ui } = this.props;
    return (
      <Row gutter={8} className="operation-bar">
        <Col span={4}>
          <Radio.Group
            size="small"
            onChange={this._onChangeRequestType}
            value={ui.requestType}
            buttonStyle={'solid'}
            style={{
              marginBottom: '3px',
              marginTop: '3px',
              marginLeft: '5px',
              float: 'left'
            }}
          >
            {this._createRadioButtons(Options.requestType)}
          </Radio.Group>
        </Col>
        <Col span={20}>
          {this._createRunInfoBars()}
          {this._createCrossInfoBars()}
          {this._createButtons()}
        </Col>
      </Row>
    );
  };

  _createRunInfoBars = () => {
    const { ui } = this.props;
    return (
      <>
        {ui.viewMode === OMS_VIEW_MODE_LIVE && (
          <div style={{ marginLeft: '5px', float: 'left' }}>
            <AlgoCxlBar {...this.props} />
          </div>
        )}
      </>
    );
  };

  _createCrossInfoBars = () => {
    return (
      <>
        {
          <div style={{ marginLeft: '5px', float: 'left' }}>
            <CrossInfoBar {...this.props} />
          </div>
        }
      </>
    );
  };

  _createOrderTable = () => {
    const orders = this._filterOrders(this.props);
    const summaryPannel = this._createSummaryPannel();
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className={`ag-theme-balham-dark`} style={{ flexGrow: 1 }}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            rowData={orders}
            suppressAggFuncInHeader={true}
            animateRows={true}
            deltaRowDataMode={true}
            enableRangeSelection={true}
            getRowNodeId={this.getRowNodeId}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            getContextMenuItems={this.getContextMenuItems}
            rowSelection="multiple"
            groupSelectsChildren={true}
            groupSelectsFiltered={true}
            frameworkComponents={this.state.frameworkComponents}
            floatingFilter={true}
            // groupDefaultExpanded={1}
            sideBar={this.state.sideBar}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            onCellContextMenu={this.onCellContextMenu}
          />
        </div>
        {summaryPannel}
      </div>
    );
  };

  _createSummaryPannel = () => {
    if (!this.gridApi) {
      return;
    }
    const selectedRows = this.gridApi.getSelectedRows();
    if (!_.isEmpty(selectedRows)) {
      const aggre = (t, g, value) => {
        const sign =
          g === 'GROSS' ? 1 : ['SELL', 'SHRT'].includes(t.side) ? -1 : 1;
        return value === undefined
          ? {
              count: 1,
              qtyUsd: sign * (t.qtyUsd || 0),
              quantity: sign * t.quantity
            }
          : {
              count: value.count + 1,
              qtyUsd: value.qtyUsd + sign * (t.qtyUsd || 0),
              quantity: value.quantity + sign * (t.quantity || 0)
            };
      };

      const tradeSummary = _.map(
        selectedRows.reduce((acc, t) => {
          [t.side, 'NET', 'GROSS'].forEach(g => (acc[g] = aggre(t, g, acc[g])));
          return acc;
        }, {}),
        (v, k) => {
          const sideStyle = ['BUY', 'COVR'].includes(k)
            ? 'long'
            : ['SELL', 'SHRT'].includes(k)
            ? 'short'
            : 'keyword';
          return (
            <span
              key={k}
              className={sideStyle}
              style={{ marginLeft: '10px' }}
            >{`${k}(${v.count}): ${numeral(v.quantity).format('0,0')}/${numeral(
              v.qtyUsd
            ).format('$0,0')};`}</span>
          );
        }
      );

      return (
        <div
          style={{
            textAlign: 'right',
            marginTop: '3px',
            marginRight: '10px',
            height: '20px'
          }}
        >
          {_.orderBy(tradeSummary, [
            s => (s.key === 'NET' ? 0 : s.key === 'GROSS' ? 1 : 2)
          ])}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="widget">
        {this._createOperationBar()}

        {this._createOrderTable()}
      </div>
    );
  }
}

export default OrderGrid;
