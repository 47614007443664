import { createSelector } from 'reselect';
import EntityMap from 'entities/EntityMap';

export const orderBlotterSelector = state => state.order;

export const isLoadedSelector = createSelector(
  orderBlotterSelector,
  o => {
    const viewMode = o.ui.viewMode;
    return o.viewState[viewMode].isLoaded;
  }
);

export const selectedTradeKeySelector = createSelector(
  orderBlotterSelector,
  o => {
    const viewMode = o.ui.viewMode;
    return o.viewState[viewMode].selectedTradeKey;
  }
);

export const selectedTradeKeysSelector = createSelector(
  orderBlotterSelector,
  o => {
    const viewMode = o.ui.viewMode;
    return o.viewState[viewMode].selectedTradeKeys;
  }
);

export const tradesSelector = createSelector(
  orderBlotterSelector,
  o => {
    const viewMode = o.ui.viewMode;
    return EntityMap.map(o.viewState[viewMode].trades);
  }
);

export const uiSelector = createSelector(
  orderBlotterSelector,
  o => o.ui
);
