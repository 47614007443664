import { createSelector } from 'reselect';
import { PORTFOLIO_VIEW_MODE_LIVE } from '../portfolio/portfolioConstants';

export const selectPortfolio = state => state.portfolio;

export const selectIsLivePortfolioLoaded = createSelector(
  selectPortfolio,
  portfolio => {
    return portfolio.viewState[PORTFOLIO_VIEW_MODE_LIVE].isLoaded;
  }
);
