import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';
import { connect } from 'react-redux';

import './App.css';
import 'antd/dist/antd.css';
import 'react-awesome-query-builder/css/styles.scss';
import 'ant-design-draggable-modal/dist/index.css';

import PrivateRoute from 'common/ui/PrivateRoute';
import LoginFormContainer from 'features/auth/containers/LoginFormContainer';
import LogoutContainer from 'features/auth/containers/LogoutContainer';
import ChgPwdFormContainer from 'features/auth/containers/ChgPwdFormContainer';
import PortfolioDashboardContainer from 'features/portfolio/containers/PortfolioDashboardContainer';
import OrderDashboardContainer from 'features/order/containers/OrderDashboardContainer';
import OrderBlotterContainer from 'features/oms/containers/OrderBlotterContainer';
import RiskDashboardContainer from 'features/risk/containers/RiskDashboardContainer';
import ResearchDashboardContainer from 'features/research/containers/ResearchDashboardContainer';
import TreasuryDashboardContainer from 'features/treasury/containers/TreasuryDashboardContainer';
import StockConferenceInfoPage from 'features/portfolio/components/StockConferenceInfoPage';
// import T0DashboardContainer from 'features/livedata/containers/T0DashboardContainer';
// import FirstLossDashboardContainer from 'features/firstloss/containers/FirstLossDashboardContainer';
import TopNavBar from 'features/layout/components/TopNavBar';
import SideNavBar from 'features/layout/components/SideNavBar';
import { toggleSidebar } from 'features/layout/layoutActions';
import {
  openHelpDialog,
  resetHelpDialog
} from 'features/settings/settingActions';
import HelpDialog from 'features/settings/components/HelpDialog';
import NotificationModal from 'features/notification/components/NotificationModal';
import NoPermission from 'common/ui/NoPermission';
import AnalystManagerContainer from 'features/auth/containers/AnalystManagerContainer';

const NoMatch = ({ location }) => (
  <div className="ui inverted red raised very padded text container segment">
    <strong>Error!</strong> No route found matching:
    <div className="ui inverted black segment">
      <code>{location.pathname}</code>
    </div>
  </div>
);

class App extends Component {
  render() {
    const {
      isSidebarOpen,
      toggleSidebar,
      settings,
      openHelpDialog,
      resetHelpDialog,
      auth: { isLoggedIn, accessToken }
    } = this.props;

    return (
      <div className="box">
        <Dimmer
          page
          inverted
          active={isSidebarOpen}
          onClick={toggleSidebar}
          style={{ zIndex: 100 }}
        />

        <SideNavBar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          routes={settings.routes}
        />

        <div className="row header">
          <TopNavBar
            toggleSideBar={toggleSidebar}
            openHelpDialog={openHelpDialog}
            isLoggedIn={isLoggedIn}
            user={settings.user || {}}
            accessToken={accessToken}
          />
        </div>

        <div className="row content">
          <Switch>
            <PrivateRoute
              path="/portfolio"
              component={PortfolioDashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/order"
              component={OrderDashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/research"
              component={ResearchDashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/risk"
              component={RiskDashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/oms"
              component={OrderBlotterContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/treasury"
              component={TreasuryDashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/show"
              component={StockConferenceInfoPage}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            {/* <PrivateRoute
              path="/t0"
              component={T0DashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            />
            <PrivateRoute
              path="/fl"
              component={FirstLossDashboardContainer}
              isLoggedIn={isLoggedIn}
              {...this.props}
            /> */}
            <Route path="/login" component={LoginFormContainer} />
            <Route path="/logout" component={LogoutContainer} />
            <Route path="/chgpwd" component={ChgPwdFormContainer} />
            <Route
              path="/manageMyAnalyst"
              component={AnalystManagerContainer}
            />
            <PrivateRoute
              exact
              path="/"
              isLoggedIn={isLoggedIn}
              component={NoPermission}
              {...this.props}
            />

            <Route component={NoMatch} />
          </Switch>

          <HelpDialog settings={settings} resetHelpDialog={resetHelpDialog} />
        </div>

        <NotificationModal />
        {/* <div className="row statusbar">
          <span className="statusInfo">当前日期: { moment().format('YYYY-MM-DD') }</span>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.layout,
    settings: state.settings,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    openHelpDialog: () => dispatch(openHelpDialog()),
    resetHelpDialog: () => dispatch(resetHelpDialog())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
