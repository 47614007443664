import { connect } from 'react-redux';
import OrderBlotter from '../components/OrderBlotter';
import {
  omsSelector,
  ordersSelector,
  routesSelector,
  allocationsSelector,
  runInfosSelector,
  crossInfosSelector,
  isBlotterLoadedSelector,
  selectedOrderKeysSelector,
  selectedRouteKeysSelector,
  ordersWithNotReqTypeSelector,
  holdingMapSelector
} from '../omsSelectors';
import { selectSettings } from '../../settings/settingSelectors';
// import { selectLiveHoldingMap } from '../../portfolio/portfolioSelectors';
import {
  selectOrders,
  selectRoutes,
  selectFunds,
  changeFilter,
  toggleViewMode,
  changeViewDate,
  getOrderBlotter,
  changeRequestType,
  toggleUseOmsSrvMode
} from '../omsActions';
import {
  openDialog,
  closeDialog,
  submitDialogSuccess
} from '../../common/commonActions';
import { dialogsSelector } from '../../common/commonSelectors';
import Options from '../entities/Options';
import { sendSubscription } from '../../portfolio/portfolioActions';
import { selectLiveRiskInfos } from '../../portfolio/portfolioSelectors';

const _filterOrders = (orders, { selectedFunds = [] }) => {
  return orders.filter(o => selectedFunds.includes(o.fundCode));
};

const mapStateToProps = state => {
  const settings = selectSettings(state);
  const ui = omsSelector(state).ui;
  const isBlotterLoaded = isBlotterLoadedSelector(state);

  const liveRiskInfos = selectLiveRiskInfos(state);

  // Must do the order filtering by fund here
  // since the order summary in FilterBar is calculated from those orders.
  const orders = _filterOrders(ordersSelector(state), ui);
  const selectedOrderKeys = selectedOrderKeysSelector(state);
  const selectedOrders = orders.filter(o =>
    (selectedOrderKeys || []).includes(o.key)
  );
  const selectedOrder = selectedOrders.find(
    o => o.key === selectedOrderKeys[0]
  );

  const routes = routesSelector(state).filter(r =>
    (selectedOrderKeys || []).includes(r.orderRefId)
  );

  const selectedRouteKeys = selectedRouteKeysSelector(state);
  const selectedRoutes = routes.filter(r =>
    (selectedRouteKeys || []).includes(r.key)
  );
  const selectedRoute = selectedRoutes.find(
    r => r.key === selectedRouteKeys[0]
  );

  const allocations = allocationsSelector(state).filter(a =>
    (selectedOrderKeys || []).includes(a.orderRefId)
  );

  const runInfos = runInfosSelector(state);
  const crossInfos = crossInfosSelector(state);

  const ordersLengthByReqType = Options.requestType.map(reqType => {
    let orderArr = ordersWithNotReqTypeSelector(state);
    orderArr = orderArr.filter(o => o.requestType === reqType);
    orderArr = _filterOrders(orderArr, ui);
    const length = orderArr ? orderArr.length : 0;
    return {
      reqType,
      length
    };
  });

  const dialogs = dialogsSelector(state);
  // const holdingMap = selectLiveHoldingMap(state);
  const holdingMap = holdingMapSelector(state);

  return {
    settings,
    isLoaded: isBlotterLoaded && settings.isLoaded,
    ui,

    orders,
    routes,
    allocations,
    runInfos,
    crossInfos,

    selectedOrders,
    selectedOrder,
    selectedRoutes,
    selectedRoute,
    ordersLengthByReqType,

    dialogs,
    holdingMap,

    liveRiskInfos
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSubscription: payload => dispatch(sendSubscription(payload)),
    selectFunds: funds => dispatch(selectFunds(funds)),
    changeFilter: payload => dispatch(changeFilter(payload)),
    selectOrders: orderKeys => dispatch(selectOrders(orderKeys)),
    selectRoutes: routeKeys => dispatch(selectRoutes(routeKeys)),

    openDialog: (dialogCode, info) => dispatch(openDialog(dialogCode, info)),
    closeDialog: dialogCode => dispatch(closeDialog(dialogCode)),
    submitDialogSuccess: (dialogCode, info) =>
      dispatch(submitDialogSuccess(dialogCode, info)),

    changeRequestType: reqType => dispatch(changeRequestType(reqType)),
    toggleViewMode: () => dispatch(toggleViewMode()),
    toggleUseOmsSrvMode: () => dispatch(toggleUseOmsSrvMode()),
    changeViewDate: date => dispatch(changeViewDate(date)),
    getOrderBlotter: query => dispatch(getOrderBlotter(query))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderBlotter);
