import React, { Component } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';
import { HotTable } from '@handsontable/react';
import { DIALOG_CANCEL_TRADE } from '../../orderConstants';
import client from '../../api/client';
import _ from 'lodash';
import hotTableUtils from 'common/ui/hotTableUtils';
import { cancelTradeTableColums } from './GridColumnMap';
import { roleTradeLabel } from 'common/utils/DomainUtils';

class CancelTradeModalForm extends Component {
  constructor(props) {
    super(props);

    const { selectedTrades, isCancelDisabled } = props;
    const canCancelList = selectedTrades.filter(r => !isCancelDisabled(r));
    const unCancelList = selectedTrades.filter(r => isCancelDisabled(r));
    this.state = {
      submitStatus: 'READY',
      canCancelList,
      unCancelList,
      tableSetting: hotTableUtils.createSettings({
        columns: cancelTradeTableColums,
        rowHeaders: true,
        contextMenu: {}
      }),
      gridWrapperStyle: {
        width: '100%',
        height: '300px',
        marginTop: '5px',
        border: 'solid 2px grey',
        padding: '2px'
      }
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _cancelTrade = () => {
    const { submitDialogSuccess } = this.props;
    const { canCancelList } = this.state;

    const cmds = canCancelList.map(r => ({
      refId: r.refId,
      tradeId: r.tradeId
    }));

    return client.cancelTrade(cmds).then(() => {
      submitDialogSuccess(DIALOG_CANCEL_TRADE, cmds.map(r => r.refId));
    });
  };

  _onSubmit = () => {
    const promises = [this._cancelTrade()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises)
        .then(() => {
          this.setState({ submitStatus: 'READY' });
        })
        .catch(() => this.setState({ submitStatus: 'ERROR' }));
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_CANCEL_TRADE);
  };

  _createUnCancelTable = () => {
    const { unCancelList, gridWrapperStyle, tableSetting } = this.state;
    const warningMsgs = ['The follow orders status cannot be cancel.'];
    return (
      !_.isEmpty(unCancelList) && (
        <>
          <Message warning list={warningMsgs} style={{ marginTop: '3px' }} />
          <div style={gridWrapperStyle}>
            <HotTable
              ref={this.hotTblRef}
              data={unCancelList}
              manualColumnResize={true}
              {...tableSetting}
            />
          </div>
        </>
      )
    );
  };

  render() {
    const { submitStatus: status, canCancelList } = this.state;
    const { user = {} } = this.props.settings;

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }
          `}
        </style>

        <Modal size="tiny" open={true}>
          <Header content={`Cancel ${roleTradeLabel(user)}`} />
          <Modal.Content>
            <p>Are you sure you want to cancel it?</p>
            {this._createUnCancelTable()}
          </Modal.Content>
          <Modal.Actions>
            {!_.isEmpty(canCancelList) &&
              {
                SUBMITTING: (
                  <Button secondary disabled loading content="Cancelling..." />
                ),
                ERROR: (
                  <Button
                    secondary
                    content="Fail - Retry?"
                    onClick={this._onSubmit}
                  />
                ),
                READY: (
                  <Button secondary content="Yes" onClick={this._onSubmit} />
                )
              }[status]}

            <Button secondary content="No" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default CancelTradeModalForm;
