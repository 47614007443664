import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import {
  loadNotificationList,
  openOrCloseNotificationModal
} from '../notificationActions';
import { connect } from 'react-redux';
import { Badge } from 'antd';

class NotificationIconBtn extends React.Component {
  openNotificationMoal = () => {
    this.props.openOrCloseNotificationModal(true);
  };

  render() {
    const { notificationList } = this.props;
    const length = notificationList ? notificationList.length : 0;
    return (
      <div className="icons-list" style={{ marginRight: '5px' }}>
        <BellOutlined
          style={{
            fontSize: '18px',
            color: 'white',
            verticalAlign: 'middle',
            marginTop: '10px'
          }}
          onClick={this.openNotificationMoal}
        />
        <Badge
          count={length}
          style={{ height: '13px', fontSize: '10px' }}
        ></Badge>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const user = state.settings.user;
  return {
    user,
    notificationList: state.notification.notificationList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadNotificationList: params => dispatch(loadNotificationList(params)),
    openOrCloseNotificationModal: params =>
      dispatch(openOrCloseNotificationModal(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationIconBtn);
