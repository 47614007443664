import { createReducer } from 'common/utils/reducerUtils';
import {
  LOAD_NOTIFICATION_LIST_SUCCESS,
  LOAD_NOTIFICATION_LIST_ERROR,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERROR,
  INIT_LOAD_NOTIFICATION_STATUS,
  ADD_NOTIFICATION_TO_LIST,
  REMINDER_ME_LATER,
  REMINDER_ME_LATER_ALL,
  OPEN_OR_CLOSE_NOTIFICATION_MODAL
} from './notificationConstants';

const initialState = {
  loadNotificationStatus: false,
  notificationList: [],
  showNotifitionModalStatus: false
};

export function loadNoticationListSuccess(state, result) {
  let showStatus = false;
  if (result && result.data && result.data.length > 0) {
    showStatus = true;
  }
  return {
    ...state,
    notificationList: result.data,
    loadNotificationStatus: true,
    showNotifitionModalStatus: showStatus
  };
}

export function loadNoticationListError(state, err) {
  return {
    ...state,
    loadNotificationStatus: true
  };
}

export function initLoadNotificationStatus(state) {
  return {
    ...state,
    loadNotificationStatus: false
  };
}

export function addNotificationToList(state, obj) {
  let updateData = [...state.notificationList];
  //判断是否存在uniqueId相同的数据，存在则不显示
  if (updateData) {
    let flag = false;
    for (var i = 0; i < updateData.length; i++) {
      if (updateData[i]['uniqueId'] === obj['uniqueId']) {
        flag = true;
      }
    }
    if (!flag) {
      updateData.push(obj);
    }
  } else {
    updateData = [obj];
  }
  return {
    ...state,
    notificationList: updateData,
    showNotifitionModalStatus: true
  };
}

export function removeNotificationToListSuccess(state, result) {
  let updateData = [...state.notificationList];
  //判断是否存在uniqueId相同的数据，存在则不显示
  let showNotifitionModalStatus = state.showNotifitionModalStatus;
  if (updateData) {
    const data = result['keys'];
    for (var j = 0; j < data.length; j++) {
      for (var i = 0; i < updateData.length; i++) {
        if (updateData[i]['uniqueId'] === data[j]) {
          updateData.splice(i, 1);
        }
      }
      var notShowNum = 0;
      for (var m = 0; m < updateData.length; m++) {
        if (updateData[m]['notShowFlag']) {
          notShowNum++;
        }
      }
      if (notShowNum === updateData.length) {
        showNotifitionModalStatus = false;
      }
    }
    if (!updateData || updateData.length === 0) {
      showNotifitionModalStatus = false;
    }
    return {
      ...state,
      notificationList: updateData,
      showNotifitionModalStatus: showNotifitionModalStatus
    };
  }
}

export function removeNotificationToListError(state, err) {
  return {
    ...state
  };
}

export function remindMeLater(state, obj) {
  let updateData = [...state.notificationList];
  let showNotifitionModalStatus = state.showNotifitionModalStatus;
  //判断是否存在uniqueId相同的数据，存在则不显示
  if (updateData) {
    var notShowNum = 0;
    for (var i = 0; i < updateData.length; i++) {
      if (updateData[i]['uniqueId'] === obj['uniqueId']) {
        updateData[i]['notShowFlag'] = true;
      }
      if (updateData[i]['notShowFlag']) {
        notShowNum++;
      }
    }
    if (notShowNum === updateData.length) {
      showNotifitionModalStatus = false;
    }
  }
  if (!updateData || updateData.length === 0) {
    showNotifitionModalStatus = false;
  }
  return {
    ...state,
    notificationList: updateData,
    showNotifitionModalStatus: showNotifitionModalStatus
  };
}

export function remindMeLaterAll(state) {
  return {
    ...state,
    notificationList: [],
    showNotifitionModalStatus: false
  };
}

export function openOrCloseNotificationModal(state, obj) {
  let updateData = [...state.notificationList];
  if (updateData) {
    for (var i = 0; i < updateData.length; i++) {
      updateData[i]['notShowFlag'] = null;
    }
  }
  return {
    ...state,
    showNotifitionModalStatus: obj,
    notificationList: updateData
  };
}

export default createReducer(initialState, {
  // Short Interest
  [LOAD_NOTIFICATION_LIST_SUCCESS]: loadNoticationListSuccess,
  [LOAD_NOTIFICATION_LIST_ERROR]: loadNoticationListError,
  [INIT_LOAD_NOTIFICATION_STATUS]: initLoadNotificationStatus,
  [ADD_NOTIFICATION_TO_LIST]: addNotificationToList,
  [DELETE_NOTIFICATION_SUCCESS]: removeNotificationToListSuccess,
  [DELETE_NOTIFICATION_ERROR]: removeNotificationToListError,
  [REMINDER_ME_LATER]: remindMeLater,
  [REMINDER_ME_LATER_ALL]: remindMeLaterAll,
  [OPEN_OR_CLOSE_NOTIFICATION_MODAL]: openOrCloseNotificationModal
});
