import React, { Component } from 'react';
import { Modal, Button, Spin, Row, Col, Dropdown, Menu, Radio } from 'antd';
import { PlusSquareFilled, DownOutlined } from '@ant-design/icons';
import { DIALOG_VIEW_RULES } from '../../omsConstants';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import client from '../../api/client';
import _ from 'lodash';
import { ruleGridColumns } from './GridColumnMap';
import RuleDialog from './RuleDialog';
import Options from '../../entities/Options';
import Rule from '../../entities/Rule';

class ViewRulesDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInitialized: false,
      ruleType: 'ROUTING',
      rules: [],
      selectedRule: null,
      emptyRule: null,
      createNew: true,
      showRuleDialog: false,

      options: {},

      rulesGridSettings: agGridUtils.createSettings({
        columnDefs: this._enrichRuleGridColumns(),
        rowGroupPanelShow: 'onlyWhenGrouping',
        getRowNodeId: data => data.refId,
        groupIncludeTotalFooter: false,
        rowSelection: 'single'
      }),
      rulesGridWrapperStyle: {
        width: '100%',
        height: '700px'
      }
    };
  }

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { rules, selectedRule } = this.state;
    if (prevState.rules !== rules) {
      const rule = rules.find(r => r.refId === (selectedRule || {}).refId);
      this.setState({ selectedRule: rule });
    }
  }

  _enrichRuleGridColumns = () => {
    return ruleGridColumns.map(c =>
      c.field !== 'isActive'
        ? c
        : { ...c, cellRendererParams: { onChange: this._onToggleRuleActive } }
    );
  };

  _init = () => {
    Promise.all([client.getRules(), client.getRuleOptions()])
      .then(([rules, options]) => {
        this.setState({
          isInitialized: true,
          rules,
          options
        });
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_VIEW_RULES);
  };

  _createRadioButtons = codes => {
    return codes.map(c => (
      <Radio.Button key={c} value={c}>
        {c}
      </Radio.Button>
    ));
  };

  _onRulesGridReady = params => {
    this.rulesGridApi = params.api;

    const COLUMNS_KEY = 'rules-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.rulesGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onRuleSelectionChanged = () => {
    const selectedRule = _.first(this.rulesGridApi.getSelectedRows());

    this.setState({ selectedRule });
  };

  _onCellContextMenu = evt => {
    if (evt.node && !evt.node.isSelected()) {
      this.rulesGridApi.deselectAll();
      evt.node.setSelected(true);
    }
  };

  _getContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      {
        name: 'Edit',
        action: () => this._onEditRuleClick()
      },
      {
        name: 'Clone',
        action: () => this._onCloneRuleClick()
      }
      // {
      //   name: 'Remove',
      //   action: () => this._onRemoveRuleClick()
      // }
    ];

    // Add built-in menu items.
    menuItems.push('export');
    return menuItems;
  };

  _createRulesGrid = () => {
    const {
      rulesGridSettings,
      rulesGridWrapperStyle,
      rules,
      ruleType
    } = this.state;
    const filteredRules = rules.filter(
      r => r.type === ruleType && r.isDelete !== 1
    );

    return (
      <div
        style={rulesGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={filteredRules}
          {...rulesGridSettings}
          // events
          onGridReady={this._onRulesGridReady}
          onCellContextMenu={this._onCellContextMenu}
          onSelectionChanged={this._onRuleSelectionChanged}
          getContextMenuItems={this._getContextMenuItems}
        />
      </div>
    );
  };

  _onSubmitRule = async rule => {
    const { rules } = this.state;
    const [{ refId }] = await client.submitRules([rule]);
    if (!rule.refId) {
      this.setState({
        rules: [
          ...rules,
          {
            ...rule,
            refId
          }
        ]
      });
    } else {
      this.setState({
        rules: rules.map(r => (r.refId === refId ? rule : r))
      });
    }

    this._toggleShowRuleDialog(false);
  };

  _toggleShowRuleDialog = show => {
    this.setState({ showRuleDialog: show });
  };

  _onChangeRuleType = e => {
    const ruleType = e.target.value;
    this.setState({
      ruleType
    });
  };

  _onToggleRuleActive = async (v, rule) => {
    const updatedRule = { ...rule, isActive: v };
    await this._onSubmitRule(updatedRule);
  };

  _onSubTypeMenuClick = ({ key }) => {
    const { ruleType } = this.state;
    const { user: { role, englishName } = {} } = this.props.settings;
    let target = null;
    if (ruleType === 'ROUTING') {
      target = {
        isOverride: false,
        destination:
          key === 'EB' || key === 'AXE'
            ? [{ broker: undefined, cfdType: undefined }]
            : [{ custodian: undefined }]
      };
    } else if (ruleType === 'ALLOCATION') {
      target = {
        method: 'MANUAL',
        destination: []
      };
    }

    const trader = role === 'Trader' ? englishName : undefined;

    const emptyRule = {
      ...Rule.emptyForm(),
      type: ruleType,
      subType: key,
      target,
      trader
    };
    this.setState({ emptyRule, createNew: true, showRuleDialog: true });
  };

  _onCloneRuleClick = () => {
    const { selectedRule } = this.state;
    const emptyRule = {
      ...selectedRule,
      refId: null,
      code: `Copy of ${selectedRule.code}`,
      isActive: false
    };
    this.setState({ emptyRule, createNew: true, showRuleDialog: true });
  };

  _onEditRuleClick = () => {
    this.setState({ createNew: false, showRuleDialog: true });
  };

  _onRemoveRuleClick = () => {
    const { selectedRule } = this.state;
    const rule = {
      ...selectedRule,
      isDelete: 1
    };
    this._onSubmitRule(rule);
  };

  _createSubTypeMenu = () => {
    const { ruleType } = this.state;
    if (['ROUTING'].includes(ruleType)) {
      return (
        <Menu onClick={this._onSubTypeMenuClick}>
          <Menu.Item key="EB">EB</Menu.Item>
          <Menu.Item key="PB">PB</Menu.Item>
          <Menu.Item key="AXE">AXE</Menu.Item>
        </Menu>
      );
    }

    if (['AUTO', 'ALLOCATION', 'CROSS', 'LOANRATE'].includes(ruleType)) {
      return (
        <Menu onClick={this._onSubTypeMenuClick}>
          <Menu.Item key="DEFAULT">DEFAULT</Menu.Item>
        </Menu>
      );
    }
  };

  _createOperationBar = () => {
    const { ruleType } = this.state;
    return (
      <Row gutter={8} style={{ marginBottom: '5px' }}>
        <Col span={8}>
          <Radio.Group
            onChange={this._onChangeRuleType}
            value={ruleType}
            buttonStyle={'solid'}
          >
            {this._createRadioButtons(Options.ruleType)}
          </Radio.Group>
        </Col>
        <Col span={13}></Col>
        <Col span={3}>
          <Dropdown overlay={this._createSubTypeMenu()}>
            <Button
              icon={<PlusSquareFilled />}
              type="primary"
              style={{ float: 'right' }}
            >
              Add <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    );
  };

  render() {
    const {
      isInitialized,
      selectedRule,
      showRuleDialog,
      createNew,
      emptyRule,
      options
    } = this.state;

    const rule = createNew ? emptyRule : selectedRule;

    return (
      <Modal
        width={1700}
        maskClosable={false}
        title={'Rules'}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          <Button
            key="close"
            type="primary"
            onClick={this.closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={!isInitialized}>
          {this._createOperationBar()}
          {this._createRulesGrid()}

          {showRuleDialog && (
            <RuleDialog
              centered={true}
              onSubmit={this._onSubmitRule}
              onCancel={() => this._toggleShowRuleDialog(false)}
              rule={rule}
              options={options}
            />
          )}
        </Spin>
      </Modal>
    );
  }
}

export default ViewRulesDialog;
