import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { Popover } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import TableTooltipComponent from '../../../../common/ui/renderers/agGrid/TableTooltipComponent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';

import './AlgoCxlBar.css';
import _ from 'lodash';
import { algoExecGridColumns } from './GridColumnMap';

class AlgoCxlBar extends Component {
  state = {
    defaultColDef: {
      enableCellChangeFlash: true,
      minWidth: 50,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true
    },
    frameworkComponents: {
      tableTooltipComponent: TableTooltipComponent,
      tagComponent: TagComponent
    }
  };

  _createStatisticItem = runInfos => {
    const errorList = runInfos.filter(r => r.algoExecCxlStatus === 'ERROR');
    const waringList = runInfos.filter(r => r.algoExecCxlStatus === 'WARNING');

    const value = !_.isEmpty(errorList)
      ? 'ERROR'
      : !_.isEmpty(waringList)
      ? 'WARNING'
      : 'NORMAL';
    const algoExecGrid = this._createAlgoExecGrid(runInfos);
    const divClass = value === 'ERROR' || value === 'WARNING' ? 'flash' : '';
    const className =
      value === 'ERROR'
        ? 'status-fail-ani'
        : value === 'WARNING'
        ? 'status-warn'
        : 'status-pass';
    return (
      <Popover title="Algo Exec" content={algoExecGrid}>
        <div className={`${divClass}`}>
          <span style={{ color: '#FF9E28' }}>Algo:</span>
          <span style={{ marginLeft: '5px' }} className={`${className}`}>
            {value}
          </span>
        </div>
      </Popover>
    );
  };

  _createAlgoExecGrid = runInfos => {
    const data = runInfos.map(r => ({
      ...r,
      algoExecCxlStatesCount: _.isEmpty(r.algoExecCxlStates)
        ? 0
        : r.algoExecCxlStates.length
    }));
    return (
      <div
        style={{ width: '500px', height: '300px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={algoExecGridColumns}
          defaultColDef={this.state.defaultColDef}
          rowData={data}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={false}
          frameworkComponents={this.state.frameworkComponents}
          // getRowNodeId={data => data.key}
          rowSelection="single"
          suppressCellSelection={true}
        />
      </div>
    );
  };

  render() {
    const { runInfos } = this.props;
    const statistic = this._createStatisticItem(runInfos);

    return (
      <Segment
        inverted
        style={{
          marginBottom: '2px',
          textAlign: 'right',
          marginTop: '1px'
        }}
      >
        {statistic}
      </Segment>
    );
  }
}

export default AlgoCxlBar;
