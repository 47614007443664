import spected from 'spected';

const STATUS_CHECKERS = {
  ACTIVATE: s => ['ARCHIVE'].includes(s),
  ARCHIVE: s => !['ARCHIVE', 'ARCHIVING', 'FINALIZING'].includes(s),
  DELETE: s => !['DELETING'].includes(s),
  FINALIZE: s => !['ARCHIVE', 'ARCHIVING', 'FINALIZING'].includes(s)
};

const _createSpec = ctx => {
  const { user = {}, operationType } = ctx;

  const statusChecker = STATUS_CHECKERS[operationType];
  const statusRule = [[v => statusChecker(v), 'Invalid Status']];
  const traderRule = [[v => v === user.englishName, 'Invalid Trader']];
  const traderRoleRule = [[v => user.role === 'Trader', 'Invalid Role']];

  return {
    requestStatus: statusRule,
    traderCode: operationType !== 'ACTIVATE' ? traderRule : traderRoleRule
  };
};

const _extractErrors = validationResult => {
  const FIRST_ERROR = 0;
  return Object.keys(validationResult).reduce((errors, field) => {
    return validationResult[field] !== true
      ? { ...errors, [field]: validationResult[field][FIRST_ERROR] }
      : errors;
  }, {});
};

const OrderValidator = {
  validate: (order, ctx) => {
    const spec = _createSpec(ctx);
    const validationResult = spected(spec, order);
    return _extractErrors(validationResult);
  }
};

export default OrderValidator;
