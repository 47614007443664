export const PORTFOLIO_SNAPSHOT = 'PORTFOLIO_SNAPSHOT';
export const PORTFOLIO_UPDATE = 'PORTFOLIO_UPDATE';
export const PORTFOLIO_BOOK_CHANGE = 'PORTFOLIO_BOOK_CHANGE';
export const PORTFOLIO_DATE_CHANGE = 'PORTFOLIO_DATE_CHANGE';
export const PORTFOLIO_VIEW_MODE_CHANGE = 'PORTFOLIO_VIEW_MODE_CHANGE';
export const PORTFOLIO_CASH_POSITION_CHANGE = 'PORTFOLIO_CASH_POSITION_CHANGE';
export const PORTFOLIO_GET_REQUEST = 'PORTFOLIO_GET_REQUEST';
export const PORTFOLIO_GET_SUCCESS = 'PORTFOLIO_GET_SUCCESS';
export const PORTFOLIO_GET_FAIL = 'PORTFOLIO_GET_FAIL';

export const RISK_CONTROL_OPEN = 'RISK_CONTROL_OPEN';
export const RISK_CONTROL_RESET = 'RISK_CONTROL_RESET';

export const PORTFOLIO_VIEW_MODE_LIVE = 'LIVE';
export const PORTFOLIO_VIEW_MODE_ACCT = 'ACCT';

export const DIALOG_VIEW_POSITIONS = 'viewPositionsDialog';
export const DIALOG_CB_DELTA_MODAL = 'editCbDeltaDialog';
export const DIALOG_TAG_MODAL = 'editTagDialog';
export const DIALOG_PRICE_TRACKING_MODAL = 'editPriceTrackingDialog';
export const DIALOG_PRICE_TRACKING_TRADES_MODAL = 'priceTrackingTradesDialog';

export const ON_POSITION_SELECTED = 'ON_POSITION_SELECTED';
export const DIALOG_REVIEW = 'DIALOG_REVIEW';
export const DIALOG_SENTIMENT = 'DIALOG_SENTIMENT';
export const DIALOG_COMPARE = 'DIALOG_COMPARE';
export const DIALOG_FX_TRADES_REPORT = 'DIALOG_FX_TRADES_REPORT';
