import React, { Component } from 'react';
import { Popover } from 'antd';
import _ from 'lodash';

export default class CompareFieldTooltipComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      renderParams: props.renderParams,
      value: props.valueFormatted || props.value
    };
  }

  render() {
    const { value, renderParams, data } = this.state;

    if (_.isEmpty(renderParams) || !data) return <span>{value}</span>;

    const arr = renderParams
      .map(
        r =>
          `${data[r['from']] ? data[r['from']] : ''}${
            data[r['to']] ? data[r['to']] : ''
          }`
      )
      .filter(r => !_.isEmpty(r));
    if (_.isEmpty(arr)) return <span>{value}</span>;
    const popoverContent = (
      <pre style={{ fontSize: '12px', lineHeight: 1.2, fontStyle: 'italic' }}>
        {renderParams.map(r => (
          <p>
            <b className="keyword">{r['name']}:</b> {data[r['from']]} ={'>'}{' '}
            {data[r['to']]}
          </p>
        ))}
      </pre>
    );

    return (
      <Popover content={popoverContent}>
        <span>{value}</span>
      </Popover>
    );
  }

  refresh(params) {
    this.setState({
      data: params.data,
      renderParams: params.renderParams,
      value: params.valueFormatted || params.value
    });
    return true;
  }
}
