import { LIVEDATA_SNAPSHOT, LIVEDATA_UPDATE } from './t0Constants';

export function initLiveData(payload) {
  return {
    type: LIVEDATA_SNAPSHOT,
    payload: payload
  };
}

export function updateLiveData(payload) {
  return {
    type: LIVEDATA_UPDATE,
    payload: payload
  };
}
