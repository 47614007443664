import React, { PureComponent } from 'react';
import { Modal, Button, Tabs } from 'antd';
import { DIALOG_BROKERS_INFO } from '../../omsConstants';
import client from '../../api/client';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { brokersInfoTableColumns } from './GridColumnMap';
import agGridUtils from '../../../../common/ui/agGridUtils';
import BrokerCommissionRuleModal from './BrokerCommissionRuleModal';
import BrokerCommissionResultModal from './BrokerCommissionResultModal';

class BrokersDialog extends PureComponent {
  state = {
    submitStatus: 'READY',
    extIdListTableColumns: [
      {
        data: 'refId',
        headerName: 'Ref Id',
        readOnly: true
      }
    ],
    gridSettings: agGridUtils.createSettings({
      columnDefs: brokersInfoTableColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    data: [],
    commissionModalData: {
      show: false,
      data: {}
    },
    commissionResultModalData: {
      show: false,
      data: {}
    }
  };

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({
      data: []
    });
    client.getBrokerRules({}).then(resp => {
      this.setState({
        data: resp
      });
    });
  };

  _onSubmit = saveData => {
    this.setState({
      submitStatus: 'SUBMITTING'
    });
    client
      .saveBrokerRule(saveData)
      .then(resp => {
        this._loadData();
        this._closeCommissionConfigModal();
        this.setState({
          submitStatus: 'READY'
        });
      })
      .catch(err => {
        this.setState({
          submitStatus: 'ERROR'
        });
      });
  };

  _gridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  _getContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      {
        name: 'Edit',
        action: () => this._onEditRuleClick(params.node.data)
      },
      {
        name: 'Commission Result',
        action: () => this._onCommissionResultClick(params.node.data)
      }
    ];

    // Add built-in menu items.
    return menuItems;
  };

  _onEditRuleClick = data => {
    this.setState({
      commissionModalData: {
        show: true,
        data: data
      }
    });
  };

  _onCommissionResultClick = data => {
    this.setState({
      commissionResultModalData: {
        show: true,
        data: data
      }
    });
  };

  _addCommissionRule = () => {
    this.setState({
      commissionModalData: {
        show: true,
        data: {
          type: 'COMMISSION',
          date: moment().format('YYYY'),
          value: this._commissionRuleDefaultData(),
          isActive: 1
        }
      }
    });
  };

  _commissionRuleDefaultData = () => {
    const data = {};
    const dataConfig = {
      CH: { PB: 5, EB: 4 },
      HK: { PB: 5, EB: 10 },
      JP: { EB: 7 },
      IN: { EB: 6 },
      IN_CASH: { EB: 2 }
    };
    Object.keys(dataConfig).forEach(r => {
      const config = dataConfig[r];
      const dataList = [];
      Object.keys(config).forEach(d => {
        const number = config[d];
        for (let i = 0; i < number; i++) {
          dataList.push({
            tier: d
          });
        }
      });
      data[r] = dataList;
    });
    return data;
  };

  _closeCommissionConfigModal = () => {
    this.setState({
      commissionModalData: {
        show: false,
        data: {}
      }
    });
  };

  _createCommissionConfigModal = () => {
    const { submitStatus } = this.state;
    const { show, data } = this.state.commissionModalData;
    return (
      show && (
        <BrokerCommissionRuleModal
          {...this.props}
          data={data}
          submitStatus={submitStatus}
          genDefaultData={this._commissionRuleDefaultData}
          onSubmit={this._onSubmit}
          closeDialog={this._closeCommissionConfigModal}
        />
      )
    );
  };

  _closeCommissionResultModal = () => {
    this.setState({
      commissionResultModalData: {
        show: false,
        data: {}
      }
    });
  };

  _onApply = saveData => {
    this.setState({
      submitStatus: 'SUBMITTING'
    });

    client
      .applyCommissionResult(saveData)
      .then(resp => {
        this.setState({
          submitStatus: 'READY',
          commissionResultModalData: {
            show: false,
            data: {}
          }
        });
      })
      .catch(err => {
        this.setState({
          submitStatus: 'READY'
        });
        console.log(err);
      });
  };

  _createCommissionResultModal = () => {
    const { submitStatus } = this.state;
    const { show } = this.state.commissionResultModalData;
    return (
      show && (
        <BrokerCommissionResultModal
          {...this.props}
          submitStatus={submitStatus}
          onSubmit={this._onApply}
          closeDialog={this._closeCommissionResultModal}
        />
      )
    );
  };

  _createOpList = () => {
    return (
      <div style={{ width: '100%', height: '30px' }}>
        <div style={{ float: 'right' }}>
          <Button size="small" type="primary" onClick={this._addCommissionRule}>
            Add
          </Button>
        </div>
      </div>
    );
  };

  _createBrokersGrid = () => {
    const { gridSettings, data } = this.state;

    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '400px' }}
      >
        <AgGridReact
          // properties
          rowData={data}
          {...gridSettings}
          // events
          onGridReady={this._gridReady}
          getContextMenuItems={this._getContextMenuItems}
        />
      </div>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_BROKERS_INFO);
  };

  render() {
    return (
      <Modal
        width={1000}
        maskClosable={false}
        title="Brokers"
        visible={true}
        bodyStyle={{ paddingTop: '6px' }}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <style>
          {`
            .ant-tabs-nav .ant-tabs-tab {
              color: black;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          
            .ant-tabs-nav .ant-tabs-tab-active {
              color: #1890ff;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          `}
        </style>
        <Tabs tabPosition="top" key="BrokerConfigTag">
          <Tabs.TabPane tab="Commission" key="Commission">
            {this._createOpList()}
            {this._createBrokersGrid()}
          </Tabs.TabPane>
        </Tabs>

        {this._createCommissionConfigModal()}
        {this._createCommissionResultModal()}
      </Modal>
    );
  }
}

export default BrokersDialog;
