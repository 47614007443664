import { Modal, List, Button, Row, Col } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import {
  addNotificationToList,
  loadNotificationList,
  removeNotificationToList,
  remindMeLater,
  remindMeLaterAll,
  openOrCloseNotificationModal
} from '../notificationActions';
import moment from 'moment';
import _ from 'lodash';
import { isNotPhone } from 'features/research/components/short/SystemAdjustUtil';
import AddOrUpdateDialog from 'features/research/components/pushVotes/AddOrUpdateDialog';
import researchClient from 'features/research/api/client';
import client from '../api/client';
import riskClient from 'features/risk/api/client';
import config from 'common/config';

class NotificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pushVotesModal: {
        isOpen: false,
        data: null,
        op: 'DETAIL'
      }
    };
  }

  checkNotNeedPop = () => {
    const pathname = window.location ? window.location.pathname : null;
    return pathname && pathname.includes('/show');
  };

  clickOkButtonHandle = uniqueId => {
    this.props.removeNotificationToList({ keys: [uniqueId] });
  };

  clickRemindMeLaterButtonHandle = uniqueId => {
    this.props.remindMeLater({ uniqueId: uniqueId });
    // document.getElementById(uniqueId).style.display="none";
    // const itemList = document.getElementsByClassName("notification-list-item");
    // for(var i=0;i<itemList.length;i++){
    //     if(itemList[i].style.display !== 'none'){
    //         return;
    //     }
    // }
    // this.props.openOrCloseNotificationModal(false);
  };

  clickRemindMeLaterAllButtonHandle = () => {
    this.props.openOrCloseNotificationModal(false);
  };

  itemIsDueSoon = obj => {
    const current = moment();
    const expireDate = moment(obj.expireDate);
    var duration = expireDate.diff(current, 'days', true);
    if (duration <= 1) {
      return 'visible';
    }
    return 'hidden';
  };

  handleCancel = () => {
    this.props.openOrCloseNotificationModal(false);
  };

  displayItem = obj => {
    if (obj) {
      const display = obj['notShowFlag'] ? 'none' : 'block';
      return display;
    }
    return 'block';
  };

  _showStockPitchModal = item => {
    const { pushVotesModal } = this.state;
    const { notifyUniqueId } = item;
    const arr = notifyUniqueId.split('_');
    const id = arr[arr.length - 1];
    Promise.all([
      researchClient.queryPushVotesByIdAndMarkRead({ id }),
      researchClient.queryPushVoteNoteList({ id })
    ]).then(resp => {
      let data = resp[0].data;
      data.pushVoteNoteData = resp[1].data;
      if (!_.isEmpty(data.attachVOList)) {
        data.attachVOList.forEach(e => {
          e.uid = e.id;
        });
      }
      this.setState({
        pushVotesModal: {
          ...pushVotesModal,
          data: data,
          isOpen: true
        },
        showItem: item
      });
    });
  };

  closePushVotesModal = () => {
    const { pushVotesModal, showItem } = this.state;
    if (showItem) this.clickOkButtonHandle(showItem.uniqueId);
    this.setState({
      pushVotesModal: {
        ...pushVotesModal,
        data: null,
        isOpen: false,
        item: null
      }
    });
  };

  createButtons = item => {
    const { sourceSystem, system, businessType } = item;
    if (
      [sourceSystem, system].indexOf('RESEARCH') > -1 &&
      businessType.endsWith('STOCK_PITCH')
    ) {
      return (
        <div>
          <Button
            type="primary"
            size="small"
            style={{
              marginRight: '5px',
              fontSize: '10px',
              height: '22px'
            }}
            onClick={() => {
              this.clickRemindMeLaterButtonHandle(item.uniqueId);
            }}
          >
            Remind me later
          </Button>
          <Button
            type="primary"
            size="small"
            style={{
              marginRight: '5px',
              fontSize: '10px',
              height: '22px'
            }}
            onClick={() => {
              this._showStockPitchModal(item);
            }}
          >
            Detail
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            type="primary"
            size="small"
            style={{
              marginRight: '5px',
              fontSize: '10px',
              height: '22px'
            }}
            onClick={() => {
              this.clickRemindMeLaterButtonHandle(item.uniqueId);
            }}
          >
            Remind me later
          </Button>
          <Button
            type="primary"
            size="small"
            style={{
              marginRight: '5px',
              fontSize: '10px',
              height: '22px'
            }}
            onClick={() => {
              this.clickOkButtonHandle(item.uniqueId);
            }}
          >
            OK
          </Button>
        </div>
      );
    }
  };

  downloadAtt = source => {
    try {
      client.downloadAtt(source);
    } catch (error) {
      console.log(error);
    }
  };

  previewAtt = (source, name) => {
    var type = name.substring(name.lastIndexOf('.'), name.length);
    let uri = config.api.RESEARCH_URL;
    let host = window.location.host;
    if (host.indexOf('ims') === 0) {
      host = 'https://' + host;
    } else {
      host = 'http://' + host;
    }
    uri = uri === '/rsrch' ? host + uri : uri;
    const logParams = { application: 'IMS_CLIENT', requestBody: name };
    if (type === '.pptx' || type === '.xlsx' || type === '.docx') {
      logParams.uri = 'downloadAtt';
      this._addAccessLog(logParams);
      let host = window.location.host;
      if (host.indexOf('ims') === 0) {
        host = 'https://' + host;
      } else {
        host = 'http://' + host;
      }
      const url = `https://view.officeapps.live.com/op/view.aspx?src=${uri}/file/downloadAtt/${source}`;
      window.open(url);
    } else {
      logParams.uri = 'previewAtt';
      this._addAccessLog(logParams);
      const url = `${uri}/file/previewAtt/${source}`;
      window.open(url);
    }
  };

  _addAccessLog = params => {
    riskClient
      .addAccessLog(params)
      .then(resp => {})
      .catch(err => {
        console.log(err);
      });
  };

  _createAttachments = attachments => {
    const attachmentList = JSON.parse(attachments);
    return (
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={attachmentList}
        renderItem={item => (
          <List.Item style={{ padding: '5px' }}>
            <Row gutter={24} style={{ width: '100%', margin: '0px' }}>
              <Col span={15} style={{ textAlign: 'left' }}>
                {item.fileName}
              </Col>
              <Col span={9} style={{ textAlign: 'right' }}>
                <a
                  href="#"
                  onClick={() => {
                    this.previewAtt(item.path, item.fileName);
                  }}
                >
                  Preview
                </a>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    );
  };

  render() {
    const { notificationList, showNotifitionModalStatus } = this.props;
    const dataList = _.orderBy(notificationList, ['expireDate'], ['asc']);
    const width = isNotPhone() ? '700px' : '330px';
    const listWidth = isNotPhone() ? '680px' : '310px';
    const listDivWidth = isNotPhone() ? '670px' : '300px';

    return (
      <div>
        <style>
          {`
            .notification-list-item{
                padding-right: 10px;
                padding-left: 10px;
                overflow: hidden;
                cursor: pointer;
                transition: all .3s;
            }

            .notificationModal{
                padding: 0px;
            }

            .notificationModal .ant-modal-body{
                padding: 10px;
            }

            .notification-list-item:hover {
                background: #e6f7ff;
            }

            .antd-pro-components-notice-icon-notice-list-extra {
                float: right;
                margin-top: -1.5px;
                margin-right: 0;
                color: rgba(0,0,0,.45);
                font-weight: 400;
              }
          `}
        </style>
        {!showNotifitionModalStatus || this.checkNotNeedPop() ? (
          ''
        ) : (
          <Modal
            id="notificationListModal"
            title="Notification Box"
            size="small"
            className="notificationModal"
            visible={showNotifitionModalStatus}
            onCancel={this.handleCancel}
            width={width}
            footer={[
              <div key="remindMeLaterAll" style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  onClick={() => {
                    this.clickRemindMeLaterAllButtonHandle();
                  }}
                >
                  All remind me later
                </Button>
              </div>
            ]}
            closable={true}
          >
            <div style={{ overflow: 'hidden', width: listDivWidth }}>
              <List
                style={{
                  maxHeight: '300px',
                  overflow: 'auto',
                  width: listWidth
                }}
                itemLayout="horizontal"
                dataSource={dataList}
                renderItem={item => (
                  <List.Item
                    className="notification-list-item"
                    style={{ display: this.displayItem(item) }}
                  >
                    <div style={{ width: '100%' }}>
                      <Row>
                        <Col
                          span={16}
                          style={{ textAlign: 'left', fontSize: '14px' }}
                        >
                          <b>{item.title}</b>
                        </Col>
                        <Col span={8}>
                          <div
                            className="antd-pro-components-notice-icon-notice-list-extra"
                            style={{
                              visibility: this.itemIsDueSoon(item),
                              marginRight: '15px'
                            }}
                          >
                            <span
                              className="ant-tag ant-tag-red"
                              style={{ marginRight: '0px' }}
                            >
                              Due soon
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '5px' }}>
                        <Col
                          span={24}
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></Col>
                      </Row>
                      {!_.isEmpty(item.attachments) && (
                        <Row style={{ marginTop: '5px' }}>
                          <Col span={24}>
                            {this._createAttachments(item.attachments)}
                          </Col>
                        </Row>
                      )}
                      <Row style={{ marginTop: '10px', marginRight: '5px' }}>
                        <Col span={24} style={{ textAlign: 'right' }}>
                          {this.createButtons(item)}
                        </Col>
                      </Row>
                    </div>
                  </List.Item>
                )}
              />
            </div>
            {this.state.pushVotesModal.data && (
              <AddOrUpdateDialog
                {...this.props}
                closePushVotesModal={this.closePushVotesModal}
                pushVotesModal={this.state.pushVotesModal}
                pushVOtesPMList={[]}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const user = state.settings.user;

  return {
    user,
    loadNotificationStatus: state.notification.loadNotificationStatus,
    notificationList: state.notification.notificationList,
    showNotifitionModalStatus: state.notification.showNotifitionModalStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNotificationToList: params => dispatch(addNotificationToList(params)),
    loadNotificationList: params => dispatch(loadNotificationList(params)),
    removeNotificationToList: params =>
      dispatch(removeNotificationToList(params)),
    remindMeLater: params => dispatch(remindMeLater(params)),
    remindMeLaterAll: () => dispatch(remindMeLaterAll()),
    openOrCloseNotificationModal: params =>
      dispatch(openOrCloseNotificationModal(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModal);
