import React, { Component } from 'react';
import {
  Upload,
  message,
  Modal,
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  Collapse
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Options from '../../entities/Options';
import TickerSearchField from 'common/ui/TickerSearchField';
import client from 'features/order/api/client';
import researchClient from 'features/research/api/client';
import config from 'common/config';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { HotTable } from '@handsontable/react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import {
  pushVoteNoteGridColumns,
  pushVoteResearchLogGridColumns
} from '../GridColumnMap';
import JsonTooltipComponent from '../../../../common/ui/renderers/agGrid/JsonTooltipComponent';

const { TextArea } = Input;
const { Option } = Select;
const _createOptions = values => {
  return values.map(v => ({
    key: v,
    text: v,
    value: v
  }));
};

const { Panel } = Collapse;
class AddOrUpdateDialog extends Component {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    this.state = {
      submitStatus: 'READY',
      modelOptions: [],
      errors: [],
      fileList: [],
      canEditFiledsMap: {
        CLOSE: ['closingReasons', 'closingSpecify'],
        ADJUST: [
          'type',
          'tickerShort',
          'tickerLong',
          'pmNotice'
          // 'targetPriceLong',
          // 'targetPriceShrt'
        ],
        SUBMIT_UPDATE: [
          'confidenceLevel',
          'confidenceLevelReason',
          'summary',
          'catalyst',
          'chips',
          'risks',
          'targetPriceLong',
          'targetPriceShrt',
          'expectedReturn',
          'lossLimit'
        ]
      },
      gridWrapperStyle: {
        width: '1250px',
        height: '150px',
        marginTop: '5px',
        overflow: 'auto'
      },
      contextMenu: [
        'row_above',
        'row_below',
        '---',
        'col_left',
        'col_right',
        '---',
        'remove_row',
        'remove_col',
        'undo',
        'redo'
      ],
      tickerList: [],
      needCheck: false,
      researchLog: []
    };
  }

  componentDidMount() {
    this._initTickerList();
    this._loadResearchLogs();
  }

  _initTickerList = () => {
    const { op } = this.props.pushVotesModal;
    if (op === 'DETAIL') return;
    researchClient
      .getLastCompanyList({ dayOffset: 1 })
      .then(resp => {
        const { tickerList, check } = resp.data;
        this.setState({
          tickerList: tickerList,
          needCheck: check
        });
      })
      .catch(err => console.log(err));
  };

  _loadResearchLogs = () => {
    const { op, data } = this.props.pushVotesModal;
    if (op === 'DETAIL') {
      researchClient
        .getResearchLog({
          refId: data.id,
          busType: 'STOCK_PITCH_SUBMIT_UPDATE'
        })
        .then(resp => {
          this.setState({
            researchLog: _.isEmpty(resp.data) ? [] : resp.data
          });
        })
        .catch(err => console.log(err));
    }
  };

  _getValidateMap = () => {
    return {
      type: true,
      tickerLong: true,
      startPriceLong: true,
      startPriceShrt: true,
      targetPriceLong: this._validateRule(this._showTickerLongTargetPrice),
      targetPriceShrt: this._validateRule(this._showTickerShrtTargetPrice),
      tickerShort: true,
      expectedReturn: this._validateRule(this._showExceptReturn),
      expectedDuration: true,
      //'shortingCost': true,
      sourceOfAlphaList: false,
      sourceOfAlphaSepcify: false,
      longModel: this._validateRule(this._validateLongModel),
      shrtModel: this._validateRule(this._validateShrtModel),
      closingReasons: this._validateClosingModel(),
      closingSpecify: this._validateClosingModel(),
      summary: true,
      catalyst: false,
      chips: false,
      risks: false,
      presentation: true,
      attachVOList: this._validateRule(this._showUploadContent),
      pmNotice: this._validateRule(this._validateIsObserverModel),
      scoreData: true,
      confidenceLevel: true,
      lossLimit: true,
      valuation: false
    };
  };

  _closeModal = () => {
    this.props.closePushVotesModal({ key: 'op', value: 'CLOSE' });
    this.setState({
      fileList: []
    });
  };

  _initConfig = () => {
    const { pushVOtesPMList = [] } = this.props;
    const pmList = pushVOtesPMList;
    return { pmList };
  };

  _showSepcify = () => {
    const { data } = this.props.pushVotesModal;
    const show =
      data.sourceOfAlphaList &&
      (data.sourceOfAlphaList.includes('Other') ||
        data.sourceOfAlphaList.includes('Combination of the above'));
    return show ? 'block' : 'none';
  };

  _getSecurity = (ticker, priceFiledName, nameField, tickerField) => {
    if (!ticker) {
      return;
    }

    this.setState({ isLoadingSecurity: true });
    client
      .getSecurities([{ ticker }])
      .then(([security]) => {
        this.onInputChange({ name: tickerField, value: security.ticker });
        this.onInputChange({ name: priceFiledName, value: security.price });
        this.onInputChange({ name: nameField, value: security.name });
      })
      .catch(_ => {});
  };

  onLongTickerSelect = ticker => {
    this._getSecurity(ticker, 'startPriceLong', 'tickerNameLong', 'tickerLong');
  };

  onShortTickerSelect = ticker => {
    this._getSecurity(
      ticker,
      'startPriceShrt',
      'tickerNameShort',
      'tickerShort'
    );
  };

  onInputChange = ({ name, value }) => {
    const { data } = this.props.pushVotesModal;
    const updateData = {
      ...data,
      [name]: value
    };
    if (
      name === 'type' &&
      ['Alpha Short', 'Pair Trade'].includes(value) &&
      !data.shortingCost
    ) {
      updateData['shortingCost'] = 6;
    } else if (name === 'type' && ['Alpha Long'].includes(value)) {
      updateData['shortingCost'] = null;
    }
    this.props.changePushVotesModalInput(updateData);
  };

  onUploadChange = info => {
    const { status } = info.file;
    let fileList = [...info.fileList];

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
        file.filePath = file.response.filePath;
      }
      return file;
    });

    this.setState({ fileList });
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  _readOnlyMode = field => {
    const { data } = this.props.pushVotesModal;
    const { op } = this.props.pushVotesModal;
    const { canEditFiledsMap } = this.state;
    if (canEditFiledsMap[op]) {
      if (op === 'CLOSE') return !(canEditFiledsMap[op].indexOf(field) >= 0);
      if (op === 'ADJUST') return canEditFiledsMap[op].indexOf(field) >= 0;
    }
    if (op === 'UPDATE' && data.status === 'SUBMIT') {
      return !(canEditFiledsMap[`SUBMIT_UPDATE`].indexOf(field) >= 0);
    }
    return op === 'DETAIL';
  };

  _showUploadContent = () => {
    const { data } = this.props.pushVotesModal;
    return data.presentation === 'Y' ? 'block' : 'none';
  };

  _onPresentationChange = () => {
    const { data } = this.props.pushVotesModal;
    const value = data.presentation === 'Y' ? 'N' : 'Y';
    this.onInputChange({ name: 'presentation', value });
  };

  _showTickerLongTargetPrice = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Long' ? 'block' : 'none';
  };

  _showTickerShrtTargetPrice = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Short' ? 'block' : 'none';
  };

  _showExceptReturn = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Pair Trade' ? 'block' : 'none';
  };

  _validateRule = fn => {
    const { op } = this.props.pushVotesModal;
    let require = true;
    let rules = false;
    if (fn) {
      const display = fn();
      require = display === 'block' || display === true;
    }
    if (require) {
      rules = !['CLOSE', 'ADJUST'].includes(op);
    }
    return rules;
  };

  _validateLongModel = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Long' || data.type === 'Pair Trade';
  };

  _validateShrtModel = () => {
    const { data } = this.props.pushVotesModal;
    return data.type === 'Alpha Short' || data.type === 'Pair Trade';
  };

  _validateIsObserverModel = () => {
    const { data } = this.props.pushVotesModal;
    return data.isObserver === false;
  };

  _validateClosingModel = () => {
    const { op } = this.props.pushVotesModal;
    return op === 'CLOSE';
  };

  onSaveBtnClicked = () => {
    this.saveData('NEW', 1);
  };

  onSubmitBtnClicked = () => {
    this.saveData('SUBMIT', 1);
  };

  saveData = (status, isActive) => {
    const { data } = this.props.pushVotesModal;
    const { fileList } = this.state;
    const submitData = {
      ...data,
      status,
      isActive
    };
    if (!_.isEmpty(fileList)) {
      const fileArr = fileList.map((e, index) => {
        const file = e;
        return {
          id: file.id,
          uid: 100 + index,
          name: file.name,
          uri: file.filePath,
          url: file.filePath
        };
      });
      submitData.attachVOList = fileArr;
    }
    const errors = this._validateData(submitData);
    this.setState({
      errors
    });
    if (!_.isEmpty(errors)) {
      return;
    }
    this.setState({
      submitStatus: 'SUBMITTING'
    });
    try {
      this.props.savePushVotes(submitData);
    } catch (err) {
      console.log(err);
    }
    this.setState({
      fileList: [],
      submitStatus: 'READY'
    });
  };

  _validateData = data => {
    const errors = [];
    const validateMap = this._getValidateMap();
    _.map(validateMap, (value, key) => {
      if (value && !data[key]) errors.push(`${key} cannot be null`);
    });
    return errors;
  };

  _createScoreTable = data => {
    const { gridWrapperStyle } = this.state;
    const currentData = data ? data : [[]];
    return (
      <div style={gridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={currentData}
          preventOverflow="horizontal"
          height={'145px'}
          width={'1240px'}
          contextMenu={this.state.contextMenu}
          afterChange={this._afterCellChangeHandle}
          licenseKey="non-commercial-and-evaluation"
          minSpareRows={1}
          minSpareCols={1}
          autoRowSize={true}
          //events
        ></HotTable>
      </div>
    );
  };

  _afterCellChangeHandle = async (value, action) => {
    if (
      !this.hotTblRef ||
      !this.hotTblRef.current ||
      ['loadData'].indexOf(action) > -1
    )
      return;
    const tableData = this.hotTblRef.current.hotInstance.getData();
    _.delay(() => {
      this.onInputChange({ name: 'score', value: tableData });
    }, 100);
  };

  _onPositionChange = value => {
    const { data } = this.props.pushVotesModal;
    const updateData = {
      ...data,
      longPosition: value,
      shrtPosition: value
    };
    this.props.changePushVotesModalInput(updateData);
  };

  _createLongTickerField = () => {
    const { tickerList, needCheck } = this.state;
    const { data } = this.props.pushVotesModal;
    if (!data) return;
    const { type } = data;
    if (!type) {
      return <Select disabled={true} />;
    }
    if (['Alpha Long', 'Pair Trade'].includes(type) && needCheck) {
      return (
        <Select
          disabled={this._readOnlyMode('tickerLong')}
          options={_createOptions(tickerList)}
          value={data.tickerLong}
          showSearch
          placeholder="Select Long Ticker"
          onChange={(e, { value }) => {
            this.onInputChange({ name: 'tickerLong', value: value });
            this.onLongTickerSelect(value);
          }}
        />
      );
    } else {
      return (
        <TickerSearchField
          name="tickerLong"
          value={data.tickerLong}
          onChange={({ name, value }) => {
            this.onInputChange({ name: 'tickerLong', value });
          }}
          selectTicker={this.onLongTickerSelect}
        />
      );
    }
  };

  _createShortTickerField = () => {
    const { tickerList, needCheck } = this.state;
    const { data } = this.props.pushVotesModal;
    if (!data) return;
    const { type } = data;
    if (!type) {
      return <Select disabled={true} />;
    }
    if (['Alpha Short', 'Pair Trade'].includes(type) && needCheck) {
      return (
        <Select
          disabled={this._readOnlyMode('tickerShort')}
          showSearch
          options={_createOptions(tickerList)}
          value={data.tickerShort}
          placeholder="Select Short Ticker"
          onChange={(e, { value }) => {
            this.onInputChange({ name: 'tickerShort', value: value });
            this.onShortTickerSelect(value);
          }}
        />
      );
    } else {
      return (
        <TickerSearchField
          name="tickerShort"
          value={data.tickerShort}
          onChange={({ name, value }) => {
            this.onInputChange({ name: 'tickerShort', value });
          }}
          selectTicker={this.onShortTickerSelect}
        />
      );
    }
  };

  _createForm = () => {
    const { data, op } = this.props.pushVotesModal;
    if (_.isEmpty(this.state.fileList) && data.attachVOList) {
      data.attachVOList.forEach(r => {
        if (r.id) {
          r.url = this.getUrlLink(r);
        }
      });

      this.setState({
        fileList: data.attachVOList
      });
    }
    const configData = this._initConfig();
    const { pmList } = configData;
    return (
      <Form id="pushVotesForm" onFinish={this.saveData} labelAlign="left">
        <style>
          {`
                #pushVotesForm .ui.input {
                  width: 100%
                }
                    
                #pushVotesForm .ant-tabs-bar {
                  margin: 0 0 8px 0;
                  color: black;
                }

                #pushVotesForm .ant-tabs-nav .ant-tabs-tab-active {
                  /* background-color: #FF9E28; */
                  color: black;
                }

                #pushVotesForm .anticon{
                  color: black;
                }

                #pushVotesForm .ant-input[disabled]{
                  color: black;
                }

                #pushVotesForm .ant-select-selector{
                  color: black;
                }

                #pushVotesForm .ant-input-number-disabled{
                  color: black;
                }
            `}
        </style>
        <Row span={24}>
          <Col span={24}>
            <Form.Item
              label="Type"
              required={this._validateRule() && !this._readOnlyMode('type')}
            >
              <Select
                disabled={this._readOnlyMode('type')}
                options={Options.typeList}
                value={data.type}
                placeholder="Select Type"
                onChange={(e, { value }) =>
                  this.onInputChange({ name: 'type', value: value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24}>
          <Col span={6}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('tickerLong')
              }
              label="Ticker Long"
            >
              <div className={'ui form field'}>
                {this._readOnlyMode('tickerLong') ? (
                  <Input disabled={true} value={data.tickerLong}></Input>
                ) : (
                  this._createLongTickerField()
                )}
              </div>
            </Form.Item>
          </Col>
          <Col span={9} style={{ marginLeft: '10px' }}>
            <Form.Item label="Name">
              <Input
                disabled={true}
                value={data.tickerNameLong}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={4} style={{ marginLeft: '10px' }}>
            <Form.Item label="Starting Price">
              <Input
                disabled={true}
                value={data.startPriceLong}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{
              marginLeft: '10px',
              display: this._showTickerLongTargetPrice()
            }}
          >
            <Form.Item
              required={
                this._validateRule(this._showTickerLongTargetPrice) &&
                !this._readOnlyMode('targetPriceLong')
              }
              label="Target Price"
            >
              <InputNumber
                disabled={this._readOnlyMode('targetPriceLong')}
                value={data.targetPriceLong}
                onChange={value =>
                  this.onInputChange({ name: 'targetPriceLong', value })
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row span={24}>
          <Col span={6}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('tickerShort')
              }
              label="Ticker Short"
            >
              <div className="ui form field">
                {this._readOnlyMode('tickerShort') ? (
                  <Input disabled={true} value={data.tickerShort} />
                ) : (
                  this._createShortTickerField()
                )}
              </div>
            </Form.Item>
          </Col>
          <Col span={9} style={{ marginLeft: '10px' }}>
            <Form.Item label="Name">
              <Input
                disabled={true}
                value={data.tickerNameShort}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={4} style={{ marginLeft: '10px' }}>
            <Form.Item label="Starting Price">
              <Input
                disabled={true}
                value={data.startPriceShrt}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{
              marginLeft: '10px',
              display: this._showTickerShrtTargetPrice()
            }}
          >
            <Form.Item
              label="Target Price"
              required={
                this._validateRule(this._showTickerShrtTargetPrice) &&
                !this._readOnlyMode('targetPriceShrt')
              }
            >
              <InputNumber
                disabled={this._readOnlyMode('targetPriceShrt')}
                value={data.targetPriceShrt}
                onChange={value =>
                  this.onInputChange({ name: 'targetPriceShrt', value })
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={5} style={{ display: this._showExceptReturn() }}>
            <Form.Item
              required={
                this._validateRule(this._showExceptReturn) &&
                !this._readOnlyMode('expectedReturn')
              }
              label="Expected Return %"
            >
              <InputNumber
                disabled={this._readOnlyMode('expectedReturn')}
                value={data.expectedReturn}
                formatter={value => `${value}%`}
                style={{ width: '100%' }}
                onChange={value =>
                  this.onInputChange({ name: 'expectedReturn', value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('lossLimit')
              }
              label="Loss Limit %"
            >
              <InputNumber
                disabled={this._readOnlyMode('lossLimit')}
                value={data.lossLimit}
                formatter={value => `${value}%`}
                style={{ width: '100%' }}
                onChange={value =>
                  this.onInputChange({
                    name: 'lossLimit',
                    value: value < 0 ? value : value === 0 ? -1 : -value
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('expectedDuration')
              }
              label="Expected Duration"
            >
              <Select
                disabled={this._readOnlyMode('expectedDuration')}
                options={Options.expectedDuration}
                value={data.expectedDuration}
                placeholder="Select Expected Duration"
                onChange={(e, { value }) =>
                  this.onInputChange({ name: 'expectedDuration', value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Shorting cost per year">
              <InputNumber
                disabled={this._readOnlyMode('shortingCost')}
                value={data.shortingCost}
                formatter={value => `${value}%`}
                style={{ width: '100%' }}
                onChange={value =>
                  this.onInputChange({ name: 'shortingCost', value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Source of Alpha">
              <Select
                disabled={this._readOnlyMode('sourceOfAlphaList')}
                mode="multiple"
                options={Options.sourceOfAlpha}
                value={data.sourceOfAlphaList}
                placeholder="Select Source of Alpha"
                onChange={value =>
                  this.onInputChange({ name: 'sourceOfAlphaList', value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Valuation">
              <Input
                disabled={this._readOnlyMode('valuation')}
                value={data.valuation}
                placeholder="Input valution"
                onChange={e =>
                  this.onInputChange({
                    name: 'valuation',
                    value: e.target.value
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: this._showSepcify() }}>
            <Form.Item
              required={
                this._validateRule(this._showSepcify) &&
                !this._readOnlyMode('sourceOfAlphaSepcify')
              }
              label="Sepcify"
            >
              <Input
                value={data.sourceOfAlphaSepcify}
                disabled={this._readOnlyMode('sourceOfAlphaSepcify')}
                onChange={e =>
                  this.onInputChange({
                    name: 'sourceOfAlphaSepcify',
                    value: e.target.value
                  })
                }
              />
            </Form.Item>
          </Col>
          {this._validateRule(this._validateLongModel) && (
            <Col span={24}>
              <Form.Item
                required={
                  this._validateRule(this._validateLongModel) &&
                  !this._readOnlyMode('longModel')
                }
                label="Long Model"
              >
                <Select
                  disabled={this._readOnlyMode('longModel')}
                  options={Options.longModelList}
                  value={data.longModel}
                  placeholder="Select Long Model"
                  onChange={(e, { value }) =>
                    this.onInputChange({ name: 'longModel', value })
                  }
                />
              </Form.Item>
            </Col>
          )}
          {this._validateRule(this._validateShrtModel) && (
            <Col span={24}>
              <Form.Item
                required={
                  this._validateRule(this._validateShrtModel) &&
                  !this._readOnlyMode('shrtModel')
                }
                label="Short Model"
              >
                <Select
                  disabled={this._readOnlyMode('shrtModel')}
                  options={Options.shortModelList}
                  value={data.shrtModel}
                  placeholder="Select Short Model"
                  onChange={(e, { value }) =>
                    this.onInputChange({ name: 'shrtModel', value })
                  }
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('confidenceLevel')
              }
              label="Confidence Level"
            >
              <Select
                disabled={this._readOnlyMode('confidenceLevel')}
                options={Options.confidenceLevelList}
                value={data.confidenceLevel}
                placeholder="confidenceLevel"
                onChange={(e, { value }) =>
                  this.onInputChange({ name: 'confidenceLevel', value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Confidence Level Reason">
              <Input
                disabled={this._readOnlyMode('confidenceLevelReason')}
                value={data.confidenceLevelReason}
                style={{ width: '100%' }}
                onChange={event =>
                  this.onInputChange({
                    name: 'confidenceLevelReason',
                    value: event.target.value
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Summary"
              labelCol={{
                flex: '80px'
              }}
              required={this._validateRule() && !this._readOnlyMode('summary')}
            >
              <Input.TextArea
                rows={4}
                disabled={this._readOnlyMode('summary')}
                value={data.summary}
                onChange={e => {
                  this.onInputChange({
                    name: 'summary',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Catalyst & Key Indicator"
              labelCol={{
                flex: '170px'
              }}
            >
              <Input.TextArea
                rows={4}
                disabled={this._readOnlyMode('catalyst')}
                value={data.catalyst}
                onChange={e => {
                  this.onInputChange({
                    name: 'catalyst',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Risks"
              labelCol={{
                flex: '80px'
              }}
            >
              <Input.TextArea
                rows={2}
                disabled={this._readOnlyMode('risks')}
                value={data.risks}
                onChange={e => {
                  this.onInputChange({
                    name: 'risks',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Chips and flows"
              labelCol={{
                flex: '170px'
              }}
            >
              <Input.TextArea
                rows={2}
                disabled={this._readOnlyMode('chips')}
                value={data.chips}
                onChange={e => {
                  this.onInputChange({
                    name: 'chips',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {!_.isEmpty(this.state.researchLog) && (
          <Collapse>
            <Panel header="Change Log" key="1">
              <Col span={24}>
                <div className={`grid-wrapper`} style={{ height: '160px' }}>
                  <AgGridReact
                    // properties
                    columnDefs={pushVoteResearchLogGridColumns}
                    rowData={this.state.researchLog}
                    suppressAggFuncInHeader={true}
                    deltaRowDataMode={true}
                    animateRows={false}
                    enableRangeSelection={true}
                    rowSelection="single"
                    frameworkComponents={{
                      jsonTooltipComponent: JsonTooltipComponent
                    }}
                  />
                </div>
              </Col>
            </Panel>
          </Collapse>
        )}

        <Row gutter={24} style={{ marginTop: '5px' }}>
          {/* <Col span={24}>
            <Form.Item
              required={
                this._validateRule() && !this._readOnlyMode('presentation')
              }
              label="Presentation"
            >
              <Checkbox
                value={data.presentation}
                checked={data.presentation === 'Y'}
                disabled={this._readOnlyMode('presentation')}
                onChange={this._onPresentationChange}
              />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item
              required={this._validateRule(this._showUploadContent)}
              label="Attachment"
            >
              {
                <Upload
                  fileList={this.state.fileList}
                  action={`${config.api.RESEARCH_URL}/file/upload`}
                  onChange={this.onUploadChange}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              }
            </Form.Item>
          </Col>
          {this._showPmNotice() && op !== 'DETAIL' && (
            <Col span={24}>
              <Form.Item
                required={
                  this._validateRule() && !this._readOnlyMode('pmNotice')
                }
                label="PMs notified"
              >
                <Select
                  mode="multiple"
                  disabled={this._readOnlyMode('pmNotice')}
                  value={data.pmNotice}
                  optionFilterProp="label"
                  onChange={selectedItems =>
                    this.onInputChange({
                      name: 'pmNotice',
                      value: selectedItems
                    })
                  }
                >
                  {pmList.map(item => (
                    <Option
                      key={item.userAccountId}
                      value={item.userAccountId}
                      label={item.englishName}
                    >
                      {item.englishName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {this._showNotes() && (
            <>
              <Col span={24}>
                <Form.Item
                  label="Notes"
                  labelCol={{
                    flex: '80px'
                  }}
                >
                  <div className={`grid-wrapper`} style={{ height: '160px' }}>
                    <AgGridReact
                      // properties
                      columnDefs={pushVoteNoteGridColumns}
                      defaultColDef={pushVoteNoteGridColumns}
                      rowData={data.pushVoteNoteData}
                      suppressAggFuncInHeader={true}
                      deltaRowDataMode={true}
                      animateRows={false}
                      enableRangeSelection={true}
                      rowSelection="single"
                    />
                  </div>
                </Form.Item>
              </Col>
            </>
          )}

          {this._showCloseReason() && (
            <>
              <Col span={24}>
                <Form.Item
                  required={!this._readOnlyMode('closingReasons')}
                  label="Close Reason"
                >
                  <Select
                    mode="multiple"
                    disabled={this._readOnlyMode('closingReasons')}
                    options={Options.closingReasonList}
                    value={data.closingReasons}
                    onChange={selectedItems =>
                      this.onInputChange({
                        name: 'closingReasons',
                        value: selectedItems
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <b>What did you learn? Were you wrong?</b>
              </Col>
              <Col span={24}>
                <Form.Item
                  label=""
                  colon={false}
                  required={!this._readOnlyMode('closingSpecify')}
                >
                  <TextArea
                    showCount
                    disabled={this._readOnlyMode('closingSpecify')}
                    maxLength={1000}
                    style={{ height: 100 }}
                    value={data.closingSpecify}
                    onChange={e =>
                      this.onInputChange({
                        name: 'closingSpecify',
                        value: e.target.value
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    );
  };

  getUrlLink = file => {
    if (!file) {
      return;
    }
    const name = file['name'];
    var type = name.substring(name.lastIndexOf('.'), name.length);
    let host = window.location.host;
    let uri = config.api.RESEARCH_URL;
    if (host.indexOf('ims') === 0) {
      host = 'https://' + host;
    } else {
      host = 'http://' + host;
    }
    uri = uri === '/rsrch' ? host + uri : uri;
    const id = file['id'];
    if (type === '.pptx' || type === '.xlsx' || type === '.docx') {
      const url = `https://view.officeapps.live.com/op/view.aspx?src=${uri}/file/review/${id}`;
      return url;
    } else {
      return `${uri}/file/review/${id}`;
      //message.error(`Preview only support pptx/xlsx/docx file`);
    }
  };

  onCloseBtnClicked = () => {
    this.saveData('SUBMIT', 0);
  };

  onAdjustBtnClicked = () => {
    const { data } = this.props.pushVotesModal;

    const { fileList } = this.state;

    const submitData = {
      ...data
    };
    if (!_.isEmpty(fileList)) {
      const fileArr = fileList.map((e, index) => {
        const file = e;
        return {
          id: file.id,
          uid: 100 + index,
          name: file.name,
          uri: file.url,
          url: file.url
        };
      });
      submitData.attachVOList = fileArr;
    }
    const errors = this._validateData(submitData);
    this.setState({
      errors
    });
    if (!_.isEmpty(errors)) {
      return;
    }
    try {
      this.props.adjustPushVotes(data);
    } catch (err) {
      console.log(err);
    }
    this.setState({
      fileList: []
    });
  };

  _showCloseReason = () => {
    const { data, op } = this.props.pushVotesModal;
    if (!['CLOSE', 'DETAIL'].includes(op)) return;
    if ('DETAIL' === op && data.isActive === 1) return;
    return true;
  };

  _showPmNotice = () => {
    const { data } = this.props.pushVotesModal;
    return data.isObserver === false;
  };

  _showNotes = () => {
    const { op } = this.props.pushVotesModal;
    if (['DETAIL'].includes(op)) return true;
    return false;
  };

  _createNotes = () => {
    const { op } = this.props.pushVotesModal;
    return (
      !['DETAIL', 'CLOSE'].includes(op) && (
        <div>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '18px' }}>Notes: </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '1. You can only add or adjust a stock pitch with a 24 hours Company Score update record. '
              }
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '2. Start price is T (Submit Date, 00:00 HKT) +1 close price, close price is T (Close date, 00:00 HKT)+1 close price. '
              }
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '3. Shorting cost is the cost of borrowing a stock. Our default shorting cost per year is 6%. Shorting cost = shorting cost per year * (today or close date - start date) / 365. '
              }
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '4. Check Short List function in our IMS system to find out shorting cost of specific stock. '
              }
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {'5. Total return = long retrun + short return - shorting cost. '}
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '6. The loss limit is the point at which you would close this position if your investment thesis is proven wrong. '
              }
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '7. Suggested Size calculation is based on Kelly Criterion, which is 1%*(-Wining probability/loss limit-(1-Wining probability)/expected return). Expected return=target price/starting price-1 or expected return of this pair trade. '
              }
            </b>
          </p>
          <p style={{ marginTop: '5px', marginBottom: '5px' }}>
            <b style={{ fontSize: '14px' }}>
              {
                '8. The win probability for confidence levels are as follows: 1 corresponds to 30%, 2 corresponds to 40%, 3 corresponds to 50%, 4 corresponds to 60%, and 5 corresponds to 70%.'
              }
            </b>
          </p>
        </div>
      )
    );
  };

  render() {
    const { pushVotesModal } = this.props;
    const { errors } = this.state;
    const { pushVotesSubmitStatus, pushVotesSubmitErrMsg } = this.props;
    const { op, data } = pushVotesModal;
    const errorList = [...errors, pushVotesSubmitErrMsg].filter(r => r);
    let concatShow = '';
    if (op === 'DETAIL' && data.updateTime) {
      concatShow = `${
        data.submitDate
          ? `, Submited: ${moment(data.submitDate).format('YYYY-MM-DD')}, `
          : ''
      }Updated: ${moment(data.updateTime).format('YYYY-MM-DD')}`;
    }

    return (
      <Modal
        size="large"
        visible={true}
        title={`Stock Pitch(${data.analystName}${
          concatShow ? concatShow : ''
        })`}
        width={1300}
        style={{ height: 500, top: 20 }}
        footer={[
          <div key="submitData" style={{ textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                this._closeModal();
              }}
            >
              Cancel
            </Button>
            {(op === 'ADD' || op === 'UPDATE') && data.status === 'NEW' && (
              <Button
                size="small"
                type="primary"
                disabled={pushVotesSubmitStatus !== 'READY'}
                onClick={() => {
                  this.onSaveBtnClicked();
                }}
              >
                {pushVotesSubmitStatus === 'READY' ? 'Save' : 'Saving'}
              </Button>
            )}
            {(op === 'ADD' || op === 'UPDATE') && (
              <Button
                size="small"
                type="primary"
                disabled={pushVotesSubmitStatus !== 'READY'}
                onClick={() => {
                  this.onSubmitBtnClicked();
                }}
              >
                {pushVotesSubmitStatus === 'READY' ? 'Submit' : 'Submitting'}
              </Button>
            )}
            {op === 'CLOSE' && (
              <Button
                size="small"
                type="primary"
                disabled={pushVotesSubmitStatus !== 'READY'}
                onClick={() => {
                  this.onCloseBtnClicked();
                }}
              >
                {pushVotesSubmitStatus === 'READY' ? 'Close' : 'Closing'}
              </Button>
            )}
            {op === 'ADJUST' && (
              <Button
                size="small"
                type="primary"
                disabled={pushVotesSubmitStatus !== 'READY'}
                onClick={() => {
                  this.onAdjustBtnClicked();
                }}
              >
                {pushVotesSubmitStatus === 'READY' ? 'Adjust' : 'Adjusting'}
              </Button>
            )}
          </div>
        ]}
        closable={false}
      >
        {this._createForm()}
        {!_.isEmpty(errorList) && (
          <Message error header="Errors" list={errorList} />
        )}
        {this._createNotes()}
      </Modal>
    );
  }
}

export default AddOrUpdateDialog;
