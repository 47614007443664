import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Popover, Tabs } from 'antd';
import _ from 'lodash';
import PercentBarComponent from 'common/ui/renderers/agGrid/PercentBarComponent';
import HyperlinkCompent from 'common/ui/renderers/agGrid/HyperlinkCompent';
import TagComponent from 'common/ui/renderers/agGrid/TagComponent';
import RatingBarComponent from 'common/ui/renderers/agGrid/RatingBarComponent';
import portfolioClient from 'features/portfolio/api/client';
import { gptNewsColumns, gptConferenceColumns } from './GridColumnMap';

export default class PortfolioTabTooltipComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.valueFormatted || props.value,
      data: props.data || {},
      tooltipField: props.tooltipField,
      tooltipTitle: props.tooltipTitle,
      tooltipColumnDefs: props.tooltipColumnDefs,
      tooltipWidth: props.tooltipWidth || '300px',
      tooltipHeight: props.tooltipHeight || '150px',
      tooltiGridHeight: props.tooltiGridHeight || '100px',
      recordOpenTime: props.recordOpenTime,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        filter: 'agTextColumnFilter',
        sortable: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number',
        width: 120
      },
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        hyperlinkCompent: HyperlinkCompent,
        tagComponent: TagComponent,
        ratingBarComponent: RatingBarComponent
      },
      openTime: 0
    };
  }

  _createGrid = (rows, settings) => {
    const { tooltipWidth, tooltiGridHeight } = this.state;
    return (
      <div
        style={{ width: tooltipWidth, height: tooltiGridHeight }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          {...settings}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={rows}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={false}
          groupDefaultExpanded={-1}
          rowGroupPanelShow="always"
          getRowNodeId={data => data.key}
          rowSelection="single"
          suppressCellSelection={true}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  };

  _createNewsGrid = data => {
    return this._createGrid(data, { columnDefs: gptNewsColumns });
  };

  _createCofferenceGrid = data => {
    return this._createGrid(data, { columnDefs: gptConferenceColumns });
  };

  recordReadLog = (totalTime, data) => {
    const { stockNews = [], stockConfs = [] } = this.state.data.rsrchInfo;
    const time = totalTime / 1000;
    if (time >= 4 && time < 10)
      portfolioClient
        .addNewsReadRecord([{ Ticker: data.ticker }])
        .catch(err => console.log(err));
    if (time >= 10) {
      const newsData = stockNews
        .filter(r => !r.isRead)
        .map(r => ({ Id: r.Id, Ticker: r.Ticker }));
      const stockConfsData = stockConfs
        .filter(r => !r.isRead)
        .map(r => ({ Id: r.id, Ticker: r.ticker, type: 'CONFERENCE' }));
      portfolioClient
        .addNewsReadRecord([...newsData, ...stockConfsData])
        .catch(err => console.log(err));
    }
  };

  onOpenChange = open => {
    const { data, openTime } = this.state;
    const currentMs = Date.now();
    if (open) {
      this.setState({
        openTime: currentMs
      });
    } else {
      const totalOpenTime = currentMs - openTime;
      this.recordReadLog(totalOpenTime, data);
    }
  };

  _createTabs = () => {
    const { tooltipHeight, tooltipWidth } = this.state;
    const { stockNews = [], stockConfs = [] } = this.state.data.rsrchInfo;
    return (
      <div style={{ height: tooltipHeight, width: tooltipWidth }}>
        <Tabs
          tabPosition="top"
          key="PortfolioRschTab"
          className={`ag-theme-balham-dark grid-wrapper`}
        >
          {!_.isEmpty(stockNews) && (
            <Tabs.TabPane
              tab="News"
              key="news"
              className={`ag-theme-balham-dark grid-wrapper`}
            >
              {this._createNewsGrid(stockNews)}
            </Tabs.TabPane>
          )}
          {!_.isEmpty(stockConfs) && (
            <Tabs.TabPane
              tab="Conferences"
              key="conferences"
              className={`ag-theme-balham-dark grid-wrapper`}
            >
              {this._createCofferenceGrid(stockConfs)}
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  };

  render() {
    const { tooltipTitle, value, data, tooltipField } = this.state;
    const rsch = (data || {})[tooltipField] || {};

    if (_.isEmpty(rsch)) return <span>{value}</span>;
    const { stockNews = [], stockConfs = [] } = rsch;
    if (_.isEmpty(stockNews) && _.isEmpty(stockConfs)) return <span>N.A.</span>;
    const stockNewsNum = _.isEmpty(stockNews) ? 0 : `N:${stockNews.length}`;
    const stockConfNum = _.isEmpty(stockConfs) ? 0 : `C:${stockConfs.length}`;
    const newsArr = [stockNewsNum, stockConfNum].filter(e => e);

    const tabs = this._createTabs();
    return (
      <Popover
        title={tooltipTitle}
        content={tabs}
        onVisibleChange={this.onOpenChange}
      >
        <span>
          {newsArr.map((r, index) => {
            if (index !== newsArr.length - 1) return `${r},`;
            return r;
          })}
        </span>
      </Popover>
    );
  }

  refresh(params) {
    this.setState({
      value: params.valueFormatted || params.value,
      data: params.data
    });
    return true;
  }
}
