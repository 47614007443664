import React, { Component } from 'react';
import RiskGrid from './RiskGrid';
import BenchmarkBar from './BenchmarkBar';
import { Loader } from 'semantic-ui-react';
import './RiskDashboard.css';
import { Tabs } from 'antd';
import RiskAnalysisTab from './riskAnalysis/RiskAnalysisTab';
import ReturnAnalysisTab from './returnAnalysis/ReturnAnalysisTab';
import ReportTab from './report/ReportTab';

const TabPane = Tabs.TabPane;

class RiskDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRiskInfo: null
    };
  }

  onRiskInfoChanged = riskInfo => {
    this.setState({
      selectedRiskInfo: riskInfo
    });
  };

  componentDidMount() {
    const {
      settings: { user }
    } = this.props;
    if (!user) return;

    this.props.sendSubscription({
      view: 'RISK',
      userName: user.englishName
    });
  }

  componentDidUpdate(prevProps) {
    const {
      settings: { user: prevUser }
    } = prevProps;
    const {
      settings: { user }
    } = this.props;

    if (!prevUser && user) {
      this.props.sendSubscription({
        view: 'RISK',
        userName: user.englishName
      });
    }
  }

  render() {
    const { riskInfos, settings, isLoaded, benchmarks } = this.props;
    const role = settings.user ? settings.user.role : null;

    if (!isLoaded) return <Loader inverted active content="Loading..." />;

    return (
      <div className="riskDashboardWrapper">
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab="Summary" key="1">
            <BenchmarkBar benchmarks={benchmarks} />
            <RiskGrid
              riskInfos={riskInfos}
              riskGridColumns={settings.riskGridColumns}
              onRiskInfoChanged={this.onRiskInfoChanged}
            />
          </TabPane>
          <TabPane tab="Risk Analysis" key="2">
            <RiskAnalysisTab {...this.props} />
          </TabPane>
          {(role === 'Risk' || role === 'Trader' || role === 'FundMngr') && (
            <TabPane tab="Return Analysis" key="3">
              <ReturnAnalysisTab {...this.props} />
            </TabPane>
          )}
          <TabPane tab="Report" key="4">
            <ReportTab {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RiskDashboard;
