import React, { Component } from 'react';
import { Modal, Select, DatePicker, Button } from 'antd';
import 'ag-grid-enterprise';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import client from '../../api/client';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

const Option = Select.Option;
const { RangePicker } = DatePicker;

const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class ShortHistReviewDialog extends Component {
  constructor(props) {
    super(props);

    const startDate = moment().subtract(30, 'days');
    this.state = {
      loadStatus: 'READY',
      query: {
        date: [],
        fund: null
      },
      brokerOptions: [],
      fieldsFormat: {
        closePrice: {
          name: 'Price',
          format: '0,0',
          round: 0
        }
      },
      queryParams: {
        ticker: null,
        brokerList: [],
        date: [startDate, moment()]
      },
      data: [],
      colors: this._getRandomColor(100)
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    const { queryParams } = this.state;
    const { ticker, broker, brokerOptions } = this.props;
    this.setState(
      {
        brokerOptions,
        queryParams: {
          ...queryParams,
          brokerList: [broker],
          ticker
        }
      },
      this._loadData
    );
  }

  closeDialog = () => {};

  _loadData = query => {
    const { date, brokerList, ticker } = this.state.queryParams;
    this.setState({
      loadStatus: 'LOADING'
    });
    const params = {
      brokerList,
      ticker,
      startDate: date[0].format('YYYY-MM-DD'),
      endDate: date[1].format('YYYY-MM-DD')
    };
    client
      .getShortListHist(params)
      .then(resp => {
        const data = resp.data;
        this.setState({
          data,
          loadStatus: 'READY'
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadStatus: 'READY'
        });
      });
  };

  _changeReviewDate = dates => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        date: dates
      }
    });
  };

  onOpenChange = () => {
    this._changeReviewDate([]);
  };

  _onQueryChange = ({ name, value }) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        [name]: value
      }
    });
  };

  _createQueryHeader = () => {
    const { brokerOptions, queryParams } = this.state;
    return (
      <div>
        <RangePicker
          size="small"
          style={{ width: '220px' }}
          onCalendarChange={this._changeReviewDate}
          value={queryParams.date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onChange={this._changeReviewDate}
        />

        <Select
          mode="multiple"
          size="small"
          showSearch
          onChange={value => {
            this._onQueryChange({ name: 'brokerList', value });
          }}
          value={queryParams.brokerList}
          style={{ marginLeft: '5px', width: 550 }}
        >
          {_createUIOptions(brokerOptions)}
        </Select>

        <Button
          type="primary"
          size="small"
          onClick={this._loadData}
          style={{ marginLeft: '5px' }}
        >
          {' '}
          Reload
        </Button>
      </div>
    );
  };

  _buildData = (columns, data) => {
    return _.zipObject(columns, data);
  };

  _getRandomColor = count => {
    const brightnessThreshold = 150; // 亮度阈值，范围为 0 到 255
    const colors = [];

    while (colors.length < count) {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16); // 生成随机的颜色值，范围为 0 到 FFFFFF
      if (
        this.getBrightness(color) >= brightnessThreshold &&
        !colors.includes(color)
      ) {
        colors.push(color);
      }
    }

    return colors;
  };

  getBrightness = color => {
    const hexColor = color.replace('#', '');
    const red = parseInt(hexColor.substr(0, 2), 16); // 提取红色分量并转为十进制
    const green = parseInt(hexColor.substr(2, 2), 16); // 提取绿色分量并转为十进制
    const blue = parseInt(hexColor.substr(4, 2), 16); // 提取蓝色分量并转为十进制

    // 使用相对亮度算法计算亮度值
    return (red * 299 + green * 587 + blue * 114) / 1000;
  };

  getLineData = field => {
    const { data, queryParams } = this.state;
    if (_.isEmpty(data)) return { data: [], keyList: [] };
    const dataMap = [];
    const keyList = [];
    const defaultValue = {};
    queryParams.brokerList.forEach(r => {
      defaultValue[r] = 0;
    });
    data.forEach(r => {
      if (!dataMap[r.date]) {
        dataMap[r.date] = { ...defaultValue };
      }
      dataMap[r.date]['date'] = r.date.substr(0, 10);
      dataMap[r.date][r.broker] = r[field];
      if (!keyList.includes(r.broker)) keyList.push(r.broker);
    });
    return {
      data: _.orderBy(Object.values(dataMap), ['date'], ['asc']),
      keyList
    };
  };

  _createLineChart = field => {
    const { colors } = this.state;
    const { data, keyList } = this.getLineData(field);
    let format = '0.00';
    return (
      <ResponsiveContainer height={'90%'} width={'100%'}>
        <ComposedChart
          data={data}
          margin={{ top: 5, right: 30, left: 30 }}
          syncId="anyId"
        >
          <XAxis dataKey="date" />
          <YAxis
            tickFormatter={value => {
              return numeral(value).format(format);
            }}
          />
          <Tooltip
            formatter={(value, name, props) => {
              return [numeral(value).format(format), name];
            }}
          />
          <Legend />
          {keyList.map((r, index) => {
            return (
              <Line
                connectNulls
                type="monotone"
                dataKey={r}
                strokeWidth={2}
                stroke={colors[index]}
                dot={false}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  render() {
    return (
      <Modal
        width={'92%'}
        maskClosable={false}
        visible={true}
        onCancel={this.props.closeDialog}
        className="finReivewModalInnerClass"
        bodyStyle={{
          height: '80vh',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: '#FF9E28',
          padding: '12px'
        }}
        footer={null}
      >
        <div>
          <style>
            {` 

                  .finReivewModalInnerClass .ant-picker{
                    background-color:#FF9E28;
                  }

                  .finReivewModalInnerClass .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .finReivewModalInnerClass .ant-select-arrow{
                    color: gray !important;
                  }

                  .finReivewModalInnerClass .anticon {
                    color: gray !important;
                  }
                 
                `}
          </style>
        </div>
        {this._createQueryHeader()}
        <div style={{ height: '45%' }}>{this._createLineChart('rate')}</div>
        <div style={{ height: '45%' }}>
          {this._createLineChart('availableQty')}
        </div>
      </Modal>
    );
  }
}

export default ShortHistReviewDialog;
