import React from 'react';
class NoPermission extends React.Component {
  render() {
    return (
      <div
        style={{
          verticalAlign: 'middle',
          textAlign: 'center',
          color: 'white',
          width: '100%'
        }}
      >
        <div
          style={{
            verticalAlign: 'middle',
            fontSize: '20px',
            marginTop: '100px'
          }}
        >
          You do not have permission to view this page, please contact the
          system administrator
        </div>
      </div>
    );
  }
}

export default NoPermission;
