import React, { Component } from 'react';
import { Select, DatePicker, Button } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import SplitPane from 'react-split-pane';
import 'ag-grid-enterprise';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { themesColumn } from './../GridColumnMap';
import client from '../../api/client';
import _ from 'lodash';
import numeral from 'numeral';
import { getPreviousWorkingDay } from 'common/utils/DateUtils';

const Option = Select.Option;

const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class ThemesAnalysisPannel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataList: [],
      loadStatus: 'READY',
      query: {
        date: getPreviousWorkingDay(),
        themeList: [],
        offsetDay: 10
      },
      themeOptions: [],
      dayOffsetOptions: [5, 10, 15, 20, 30],
      dataMap: {},
      gridSettings: agGridUtils.createSettings({
        columnDefs: themesColumn,
        floatingFilter: false,
        defaultColDef: {
          enableCellChangeFlash: false,
          minWidth: 25,
          sortable: true,
          resizable: true,
          filter: true
        },
        groupIncludeTotalFooter: false,
        deltaRowDataMode: false,
        getRowNodeId: data => data.id,
        rowGroupPanelShow: false,
        pivotPanelShow: false,
        sideBar: {
          toolPanels: []
        }
      })
    };
  }

  componentDidMount() {
    this._loadAllThemes();
  }

  _loadAllThemes = () => {
    const { query } = this.state;
    this.setState({
      loadStatus: 'LOADING'
    });
    client
      .queryAllThemes()
      .then(resp => {
        this.setState(
          {
            themeOptions: resp.data,
            loadStatus: 'READY',
            query: {
              ...query,
              themeList: [resp.data[0], resp.data[1]]
            }
          },
          this._loadData
        );
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadStatus: 'READY'
        });
      });
  };

  _loadData = () => {
    const { date, themeList } = this.state.query;
    this.setState({
      loadStatus: 'LOADING'
    });
    const params = {
      date: date.format('YYYY-MM-DD'),
      themeList
    };
    client
      .queryThemeResult(params)
      .then(resp => {
        this.setState({
          dataMap: resp.data,
          loadStatus: 'READY'
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadStatus: 'READY'
        });
      });
  };

  onDateChange = date => {
    const { query } = this.state;
    const params = {
      ...query,
      date: date
    };
    this.setState(
      {
        query: params
      },
      this._loadData
    );
  };

  onSelectedChange = (selected, index) => {
    const { query } = this.state;
    query.themeList[index] = selected;
    const params = {
      ...query
    };
    this.setState(
      {
        query: params
      },
      this._loadData
    );
  };

  onDayOffsetChange = selected => {
    const { query } = this.state;
    const params = {
      ...query,
      offsetDay: selected
    };
    this.setState({
      query: params
    });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  _createDataGrid = data => {
    const { gridSettings } = this.state;
    return (
      !_.isEmpty(data) && (
        <div
          className={`ag-theme-balham-dark grid-wrapper`}
          style={{ height: '98%', width: '100%' }}
        >
          <AgGridReact
            {...gridSettings}
            rowData={data}
            rowSelection="multiple"
            onGridReady={this.onGridReady}
          />
        </div>
      )
    );
  };

  _createQueryHeader = () => {
    const { themeOptions, query, dayOffsetOptions } = this.state;
    return (
      <div style={{ width: '100%', textAlign: 'right' }}>
        <DatePicker
          size="small"
          style={{ width: '150px', marginLeft: '5px' }}
          value={query.date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onChange={this.onDateChange}
        />

        <Select
          allowClear={false}
          size="small"
          showSearch
          onChange={value => {
            this.onSelectedChange(value, 0);
          }}
          value={query.themeList[0]}
          style={{ marginLeft: '5px', width: 150 }}
        >
          {_createUIOptions(themeOptions)}
        </Select>
        <Select
          allowClear={false}
          size="small"
          onChange={value => {
            this.onSelectedChange(value, 1);
          }}
          value={query.themeList[1]}
          style={{ marginLeft: '5px', width: 150 }}
        >
          {_createUIOptions(themeOptions)}
        </Select>
        <Select
          allowClear={false}
          size="small"
          onChange={value => {
            this.onDayOffsetChange(parseInt(value));
          }}
          value={query.offsetDay}
          style={{ marginLeft: '5px', width: 100 }}
        >
          {_createUIOptions(dayOffsetOptions)}
        </Select>

        <Button
          size="small"
          type="primary"
          style={{ marginLeft: '5px' }}
          onClick={this._loadData}
        >
          {' '}
          Reload{' '}
        </Button>
      </div>
    );
  };

  _createLineDetailChart = (data, avgData, name) => {
    const { query } = this.state;
    return (
      <div style={{ width: '50%', float: 'left', height: '100%' }} key={name}>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            height: '20px',
            color: 'white'
          }}
        >
          <b>{name}</b>
        </div>
        <ResponsiveContainer height={'45%'} width={'100%'}>
          <LineChart data={data}>
            <XAxis
              dataKey="date"
              tickFormatter={value => {
                return value.substr(0, 10);
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              type="number"
              tickFormatter={value => {
                return numeral(value).format('0.00');
              }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              type="number"
              tickFormatter={value => {
                return numeral(value).format('0.00');
              }}
            />
            <Legend verticalAlign="top" wrapperStyle={{ color: 'white' }} />
            <Tooltip
              formatter={(value, name, props) => {
                return [numeral(value).format('0.0000'), name];
              }}
            />
            <Line
              yAxisId="left"
              stroke="#40d9ff"
              type="monotone"
              dataKey="accumReturn"
              name={`历史累计收益率`}
              strokeWidth={2}
              dot={props => {
                return null;
              }}
            />
            <Line
              yAxisId="right"
              type="monotone"
              stroke="rgb(226,74,51)"
              dataKey="ratio"
              name={`估计配置权重`}
              strokeWidth={2}
              dot={props => {
                return null;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            height: '20px',
            color: 'white'
          }}
        >
          <b>{name}</b>
        </div>
        <ResponsiveContainer height={'45%'} width={'100%'}>
          <LineChart data={avgData}>
            <XAxis
              dataKey="date"
              tickFormatter={value => {
                return value.substr(0, 10);
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              type="number"
              tickFormatter={value => {
                return numeral(value).format('0.00');
              }}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              type="number"
              tickFormatter={value => {
                return numeral(value).format('0.00');
              }}
            />
            <Legend verticalAlign="top" wrapperStyle={{ color: 'white' }} />
            <Tooltip
              formatter={(value, name, props) => {
                return [numeral(value).format('0.0000'), name];
              }}
            />
            <Line
              yAxisId="left"
              type="monotone"
              stroke="#40d9ff"
              dataKey="avgReturn"
              name={`${query.offsetDay}日收益率`}
              strokeWidth={2}
              dot={props => {
                return null;
              }}
            />
            <Line
              yAxisId="right"
              type="monotone"
              stroke="rgb(226,74,51)"
              dataKey="avgRatio"
              name={`${query.offsetDay}日估计配置权重均值`}
              strokeWidth={2}
              dot={props => {
                return null;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  _buildOffsetDaysData = data => {
    const { offsetDay } = this.state.query;
    data.forEach((e, index) => {
      if (index >= offsetDay) {
        const slicedArray = _.slice(data, index - offsetDay + 1, index + 1);
        const avgRatio = _.meanBy(slicedArray, 'ratio');
        const avgReturn =
          (1 + slicedArray[slicedArray.length - 1].accumReturn) /
            (1 + slicedArray[0].accumReturn) -
          1;
        e.avgRatio = avgRatio;
        e.avgReturn = avgReturn;
      } else {
        e.avgRatio = null;
        e.avgReturn = null;
      }
    });
  };

  _createChart = (data, name) => {
    if (_.isEmpty(data)) return;
    const soortedData = _.orderBy(data, ['date'], ['asc']);
    soortedData.forEach(e => {
      if (e.date) {
        e.date = e.date.substr(0, 10);
      }
    });
    this._buildOffsetDaysData(soortedData);
    const avgData = soortedData.filter(r => !_.isNil(r.avgRatio));
    return this._createLineDetailChart(soortedData, avgData, name);
  };

  render() {
    const { loadStatus, dataMap, query } = this.state;
    const { themeAnalysisMap = {}, selectedDateThemes } = dataMap;
    const { className = 'themesAnalystInnerClass' } = this.props;
    return (
      <div style={{ height: '100%' }}>
        <style>
          {` 
                  .themesAnalystInnerClass{
                    background-color: #2d3436 !important;
                    color: white;
                  }

                  .${className} .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .${className} .ant-select-arrow{
                    color: gray !important;
                  }

                  .${className} .anticon {
                    color: gray !important;
                  }

                  .${className} .ant-picker {
                    background: #FF9E28;
                  }

                `}
        </style>
        <SplitPane
          split="horizontal"
          defaultSize={'32px'}
          style={{ position: 'relative' }}
        >
          {this._createQueryHeader()}
          <SplitPane split="horizontal" defaultSize={'65%'}>
            <div style={{ width: '100%' }}>
              {query.themeList.map(r =>
                this._createChart(themeAnalysisMap[r], r)
              )}
            </div>
            <div style={{ width: '100%', height: '100%' }}>
              {loadStatus !== 'LOADING' &&
                this._createDataGrid(selectedDateThemes)}
            </div>
          </SplitPane>
        </SplitPane>
      </div>
    );
  }
}

export default ThemesAnalysisPannel;
