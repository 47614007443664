import React, { Component } from 'react';
import SearchField from './SearchField';
import securityApiClient from 'common/api/securityApiClient';

class TickerSearchField extends Component {
  state = {
    isLoadingTickers: false,
    tickers: [],
    lastTicker: ''
  };

  _searchTickers = term => {
    this.setState({ isLoadingTickers: true });
    securityApiClient
      .searchTickers(term)
      .then(resp => {
        const tickers = resp.tickers.map(t => ({ key: t, ticker: t }));
        this.setState({
          tickers,
          isLoadingTickers: false
        });
      })
      .catch(_ => {
        this.setState({
          tickers: [],
          isLoadingTickers: false
        });
      });
  };

  _onSelectTicker = ticker => {
    const { selectTicker } = this.props;

    // If both operation use same ticker, abort the next one.
    const { lastTicker } = this.state;
    if (lastTicker === ticker) return;

    this.setState({ lastTicker: ticker });

    selectTicker(ticker);
  };

  render() {
    const { name, value, onChange } = this.props;
    const { isLoadingTickers, tickers } = this.state;

    return (
      <div>
        <SearchField
          name={name}
          resultField="ticker"
          placeholder="Enter Ticker..."
          loading={isLoadingTickers}
          results={tickers}
          value={value}
          onChange={onChange}
          onSearch={this._searchTickers}
          onBlur={this._onSelectTicker}
          onResultSelect={this._onSelectTicker}
          resultRenderer={({ ticker }) => <label>{ticker}</label>}
        />
      </div>
    );
  }
}

export default TickerSearchField;
