import _ from 'lodash';
import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const submitTrade = trade => {
  let url = `${config.api.PXY_URL}/trade/addTrades`;
  if (trade.tradeId) url = `${config.api.PXY_URL}/trade/updateTrades`;

  console.log('To submit trade:', trade);
  return apiClient.post(url, [trade]).then(r => _.first(r));
};

const addTrades = trades => {
  const url = `${config.api.PXY_URL}/trade/addTrades`;

  console.log('To add trades:', trades);
  return apiClient.post(url, trades);
};

const cancelTrade = cmd => {
  const url = config.api.PXY_URL + '/trade/cancelTrades';

  console.log('To cancel trade:', cmd);
  return apiClient.post(url, cmd);
};

const searchTickers = term => {
  const url = `${config.api.REST_URL}/security/searchTickers?term=${term}`;
  return apiClient.get(url);
};

const getSecurities = (inputs, options = {}) => {
  const url = `${config.api.PXY_URL}/security/getSecurities`;
  return apiClient.post(url, { inputs, options });
};

const getSlimSecurities = (inputs, options = {}) => {
  const url = `${config.api.REST_URL}/security/getSlimSecurities`;
  return apiClient.post(url, { inputs, options });
};

const getTrades = searchQry => {
  const qs = new URLSearchParams(searchQry);
  const url = `${config.api.PXY_URL}/trade/getTrades?${qs}`;

  return apiClient.get(url);
};

const getCanIPOList = () => {
  const url = `${config.api.RESEARCH_URL}/ipoInfo/getCanIPOList`;
  return apiClient.post(url);
};

const getCachedGroupRefData = ({ tickers, groupType }) => {
  const url = `${config.api.REST_URL}/mkt/getCachedGroupRefData`;
  return apiClient.post(url, { tickers, groupType });
};

const getCBInfos = inputs => {
  const url = `${config.api.REST_URL}/security/getCBInfos`;
  return apiClient.post(url, { inputs });
};

const calcRollContext = input => {
  const url = `${config.api.REST_URL}/trade/calcRollContext`;
  return apiClient.post(url, input);
};

const client = {
  submitTrade,
  addTrades,
  cancelTrade,
  searchTickers,
  getSecurities,
  getTrades,
  getCanIPOList,
  getCachedGroupRefData,
  getSlimSecurities,
  getCBInfos,
  calcRollContext
};

export default client;
