import React, { Component } from 'react';
import { getCurrentQuarter } from 'common/utils/DateUtils';
import { numberStyle } from 'common/utils/styleChooser';
import { moneyFormatterOrPercentFomatDefaultNull } from 'common/utils/valueFormatters';
import moment from 'moment';
import _ from 'lodash';
import { Select, Collapse, Input, Checkbox, Button } from 'antd';
import {
  companySingleQuarterColumns,
  companyBottomLineColumns,
  companyFutureColumns,
  marketFeedbackColumns,
  investmentConclusionColumns,
  followUpColumns
} from './ReviewGridColumn';
import { AgGridReact } from 'ag-grid-react';
import AddNoteModal from './AddNoteModal';

const { Panel } = Collapse;

const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c.quarterKey}>
      {c.quarterName}
    </Option>
  ));
};

const _createNormalUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class CompanyReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      happenList: [
        'Preliminary results',
        'Results',
        'News',
        'Expert',
        'Company visit',
        'Event',
        'Other'
      ],
      quarterList: this._initQuarterDate(),
      syncStatus: 'READY',
      defaultBusData: [
        {
          id: 1,
          type: 'Revenue (million)',
          index: 'Revenue (million)',
          firstRow: true
        },
        {
          id: 2,
          type: 'Revenue (million)',
          index: 'YoY',
          disabled: true,
          isPercent: true
        },
        {
          id: 3,
          type: 'Revenue (million)',
          index: 'QoQ',
          disabled: true,
          isPercent: true
        },
        { id: 7, type: 'ASP', index: 'ASP', firstRow: true },
        { id: 8, type: 'ASP', index: 'YoY', disabled: true, isPercent: true },
        { id: 9, type: 'ASP', index: 'QoQ', disabled: true, isPercent: true },
        { id: 4, type: 'Volume', index: 'Volume', firstRow: true },
        {
          id: 5,
          type: 'Volume',
          index: 'YoY',
          disabled: true,
          isPercent: true
        },
        {
          id: 6,
          type: 'Volume',
          index: 'QoQ',
          disabled: true,
          isPercent: true
        },
        {
          id: 10,
          type: 'GP Margin',
          index: 'GP Margin',
          firstRow: true,
          isPercent: true
        },
        {
          id: 11,
          type: 'GP Margin',
          index: 'YoY(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 12,
          type: 'GP Margin',
          index: 'QoQ(ppt)',
          disabled: true,
          isPpt: true
        },
        { id: 13, type: 'Other metrics', index: 'Other metrics 1' }
      ],
      defaultAddRows: [
        {
          id: 14,
          type: 'Net profit',
          index: 'Net profit (million) ',
          firstRow: true,
          isPercent: true
        },
        {
          id: 15,
          type: 'Net profit',
          index: 'YoY',
          disabled: true
        },
        {
          id: 16,
          type: 'Net profit',
          index: 'QoQ',
          disabled: true
        }
      ],
      addNoteModalData: {
        show: false,
        data: {}
      },
      notesList: this.props.data.notesList | [],
      notesPopoverData: {
        show: false,
        content: []
      }
    };
  }

  _initQuarterDate = () => {
    const optionList = [];
    for (let i = 8; i >= -2; i--) {
      const date = moment().add(i, 'Q');
      const item = getCurrentQuarter(date);
      optionList.push(item);
    }
    return optionList;
  };

  _onCellEditStop = (event, current) => {
    const { syncStatus } = this.state;
    const { data, colDef } = event;
    if (syncStatus !== 'READY') {
      throw new Error('');
    }
    if (data && data.firstRow && colDef.needCal) {
      const field = colDef.field;
      const value = _.isEmpty(data[field])
        ? undefined
        : !_.isNaN(_.toNumber(data[field]))
        ? _.toNumber(data[field])
        : data[field];
      data[field] = value;
      const filterList = current.filter(
        r => r.type === data.type && r.id !== data.id
      );
      if (filterList && syncStatus === 'READY') {
        this.props.calData(field, data, value, current);
        event.api.refreshCells();
      }
    }
  };

  _calPercent = (value1, value2, index) => {
    if (index && index.endsWith('(ppt)')) return value1 - value2;
    if (value1 && value2) return _.round((value1 / value2 - 1) * 100, 1);
    return null;
  };

  _onGridReady = (params, key) => {
    if (!this.defaultGridApi) this.defaultGridApi = {};
    if (!this.defaultGridApi[key]) this.defaultGridApi[key] = [];
    this.defaultGridApi[key].push(params.api);
  };

  _getContextMenuItems = (params, key, desc) => {
    const { readOnly } = this.props;
    if (readOnly) return [];
    return [
      {
        name: `Notes`,
        action: () => this.addOrUpdateNotes(params, key, desc)
      }
    ];
  };

  addOrUpdateNotes = params => {
    const { addNoteModalData } = this.state;
    const { node, column } = params;
    if (node && column) {
      const { data } = node;
      const { colDef } = column;
      const paramsData = {
        col: colDef.field,
        isOverride: false,
        sourceValue: data[colDef.field]
      };
      const func = () => {
        params.api.refreshCells({ rowNodes: [node] });
      };
      this.setState({
        addNoteModalData: {
          ...addNoteModalData,
          show: true,
          data: paramsData,
          currentData: data,
          func
        }
      });
    }
  };

  _closeAddNoteModal = () => {
    this.setState({
      addNoteModalData: {
        show: false,
        data: {},
        errorMsg: null
      }
    });
  };

  _createAddNoteModal = () => {
    const { addNoteModalData } = this.state;
    return (
      addNoteModalData.show && (
        <AddNoteModal
          {...this.props}
          closeModal={this._closeAddNoteModal}
          data={addNoteModalData.data}
          errorMsg={addNoteModalData.errorMsg}
          currentData={addNoteModalData.currentData}
          callback={addNoteModalData.func}
          onSubmit={this._submitNote}
        />
      )
    );
  };

  _createNotesPopover = () => {
    const { notesPopoverData } = this.state;
    return (
      notesPopoverData.show &&
      !_.isEmpty(notesPopoverData.content) && (
        <div
          style={{
            position: 'absolute',
            width: '15%',
            height: '350px',
            backgroundColor: 'black',
            right: '0px',
            top: '10px'
          }}
        >
          <div
            style={{
              marginTop: '20px',
              marginLeft: '10px',
              height: '100%',
              overflow: 'auto',
              color: 'white'
            }}
          >
            {notesPopoverData.content.map(r => {
              return (
                <>
                  <p>
                    <b>{moment(r.updatedTime).format('YYYY-MM-DD HH:mm:ss')}</b>
                  </p>
                  {/* <p><b>Value:</b> {r.sourceValue}</p> */}
                  <p
                    style={{
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {r.value}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      )
    );
  };

  _onCellMouseDown = params => {
    const { node, column } = params;
    if (node && column) {
      const { notes = [] } = node.data;
      const { colDef } = column;
      const filterData = notes.filter(r => r.col === colDef.field);
      if (!_.isEmpty(filterData)) {
        this.setState({
          notesPopoverData: {
            show: true,
            content: filterData
          }
        });
      }
    }
  };

  _onCellMouseOut = () => {
    this.setState({
      notesPopoverData: {
        show: false,
        content: []
      }
    });
  };

  _createGrid = (data, columns, gridHeight, settings, key, desc) => {
    const gridSetting = settings ? settings : {};
    return (
      <div
        className={`ag-theme-balham-dark`}
        style={{
          marginTop: '5px',
          height: `${gridHeight}px`,
          width: '100%'
        }}
      >
        <AgGridReact
          // properties
          columnDefs={columns}
          defaultColDef={this.state.defaultColDef}
          rowData={data}
          suppressAggFuncInHeader={true}
          animateRows={true}
          enableRangeSelection={false}
          getRowNodeId={data => data.id}
          rowSelection="single"
          enableCellChangeFlash={true}
          getRowClass={params => {
            if (params && params.data && params.data.firstRow) {
              return 'mainContent';
            }
          }}
          onGridReady={params => {
            this._onGridReady(params, key);
          }}
          getContextMenuItems={params => {
            return this._getContextMenuItems(params, key, desc);
          }}
          // events
          onGridColumnsChanged={params => {
            params.api.sizeColumnsToFit();
          }}
          {...gridSetting}
          onCellMouseOver={params => {
            return this._onCellMouseDown(params, key, desc);
          }}
          onCellMouseOut={params => {
            return this._onCellMouseOut(params, key, desc);
          }}
        />
      </div>
    );
  };

  _buildCols = columns => {
    const { review } = this.props.data;
    const { quarterList } = this.state;
    const cols = [];
    if (!review.quanter) {
      review.quanter = quarterList[0].quarterKey;
    }
    const { quanter } = review;
    let year = _.toInteger(quanter.substr(0, 4));
    let currentQ = _.toInteger(quanter.substr(5, 1));
    // add year 預測
    cols.push({ field: `${year + 1}e`, headerName: `${year + 1}e` });
    cols.push({ field: `${year}e`, headerName: `${year}e` });
    cols.push({
      field: `${quanter}e`,
      headerName: `${year} Q${currentQ}e`
    });
    for (let i = 7; i >= 0; i--) {
      if (currentQ === 1) {
        year = year - 1;
        currentQ = 4;
      } else {
        currentQ = currentQ - 1;
      }

      if (i === 7) {
        cols.push({
          field: `${year}Q${currentQ}e`,
          headerName: `${year} Q${currentQ}e`
        });
      }
      cols.push({
        field: `${year}Q${currentQ}`,
        headerName: `${year} Q${currentQ}`
      });
    }

    this._addCols(cols, columns);
  };

  _addCols = (cols, columns) => {
    cols.forEach(r => {
      const isEFiled = r.field.endsWith('e');
      const width = isEFiled ? 50 : 40;
      columns.push({
        ...r,
        editable: true,
        cellEditor: 'agTextCellEditor',
        cellClassRules: numberStyle(),
        valueFormatter: moneyFormatterOrPercentFomatDefaultNull,
        needCal: true,
        width,
        lockPinned: r.lockPinned ? true : r.field.endsWith('e'),
        pinned: r.lockPinned || r.field.endsWith('e') ? 'left' : null,
        headerClass: 'text-align-right',
        suppressMenu: true
      });
    });

    columns.forEach(r => {
      r['cellClassRules'] = {
        'non-number': params =>
          !_.isNumber(params.value) && !this.hasNotes(params),
        'non-number hasNotes': params =>
          !_.isNumber(params.value) && this.hasNotes(params),
        'number-zero': params =>
          (params.value === undefined ||
            params.value === null ||
            params.value === 0) &&
          !this.hasNotes(params),
        'number-positive': params => params.value > 0 && !this.hasNotes(params),
        'number-negative': params => params.value < 0 && !this.hasNotes(params),
        'number-zero hasNotes': params =>
          (params.value === undefined ||
            params.value === null ||
            params.value === 0) &&
          this.hasNotes(params),
        'number-positive hasNotes': params =>
          params.value > 0 && this.hasNotes(params),
        'number-negative hasNotes': params =>
          params.value < 0 && this.hasNotes(params)
      };
    });
  };

  hasNotes = params => {
    const notes = params.data.notes;
    if (_.isEmpty(notes)) return false;
    const currentNotes = notes.filter(r => r.col === params.colDef.field);
    return !_.isEmpty(currentNotes);
  };

  _mergeData = (data, type) => {
    const { defaultTemplate } = this.props;
    defaultTemplate['defaultBusData'] = this.state.defaultBusData;
    if (defaultTemplate[type]) {
      const templateData = defaultTemplate[type];
      data.forEach(r => {
        let filterData = templateData.filter(
          e => e.index === r.index && e.type === r.type
        );
        filterData = _.isEmpty(filterData)
          ? templateData.filter(e => e.id === r.id)
          : filterData;
        if (!_.isEmpty(filterData)) {
          const item = filterData[0];
          const chineseIndex = item.chineseIndex;
          if (_.isEmpty(chineseIndex) || chineseIndex === r.index) {
            r.id = item.id;
            r.type = item.type;
            r.index = item.index;
            r.excludeMarkets = item.excludeMarkets;
            r.markets = item.markets;
            r.show = true;
          }
        } else {
          r.show = false;
        }
      });
    }
  };

  _createSingleQuarter = (data, soureData) => {
    const singleQuarterData = data.singleQuarterData;
    if (singleQuarterData) {
      this._mergeData(singleQuarterData, 'defaultSingleQData');
      // const list =
      //   singleQuarterData.filter(r => r['type'] === 'Net profit' && r['type'] !== 'NP Margin')
      // if(_.isEmpty(list)) singleQuarterData.splice(9, 0, ...this.state.defaultAddRows)
    }
    const columns = companySingleQuarterColumns.map(r => ({ ...r }));
    this._buildCols(columns);
    const settings = {
      onCellEditingStopped: event => {
        this._onCellEditStop(event, singleQuarterData);
      }
    };
    return (
      <Panel header="Quarterly results" key="1">
        {this._createGrid(
          singleQuarterData,
          columns,
          375,
          settings,
          1,
          'Quarterly results'
        )}
      </Panel>
    );
  };

  _createPerformanceSplit = (data, soureData) => {
    const companyBusinessList = _.isEmpty(this.props.companyBusinessList)
      ? soureData.companyBusinessList
      : this.props.companyBusinessList;
    const { performanceSplit } = data;
    data.performanceSplit = [];
    if (!_.isEmpty(companyBusinessList)) {
      companyBusinessList.forEach(r => {
        if (r) {
          const filterData = performanceSplit.filter(
            e => e.segment === r.segment
          );
          if (_.isEmpty(filterData)) {
            const list = this.state.defaultBusData.map(r => ({ ...r }));
            const item = { segment: r.segment, data: list };
            performanceSplit.push(item);
            data.performanceSplit.push(item);
          } else {
            data.performanceSplit.push(filterData[0]);
          }
        }
      });
    }
    return (
      <Panel header="Revenue breakdown" key="2">
        {data.performanceSplit.map(r => {
          // if(r.data) {
          //   this._mergeData(r.data, 'defaultBusData');
          // }
          const columns = companySingleQuarterColumns.map(r => ({ ...r }));
          this._buildCols(columns);
          columns[0].headerName = `${r.segment ? r.segment : ''}`;
          return this._createGrid(
            r.data,
            columns,
            405,
            {
              onCellEditingStopped: event => {
                this._onCellEditStop(event, r.data);
              }
            },
            2,
            r.segment
          );
        })}
      </Panel>
    );
  };

  _createBottomLine = data => {
    const { bottomLineData } = data;
    if (bottomLineData) {
      this._mergeData(bottomLineData, 'defaultBottomData');
    }
    const exch = this.props.getTickerExch();
    const columns = companyBottomLineColumns.map(r => ({ ...r }));
    const bottomLines = bottomLineData.filter(r => {
      return (
        r.id <= 3 ||
        (r.markets && r.markets.includes(exch)) ||
        (r.excludeMarkets && !r.excludeMarkets.includes(exch))
      );
    });
    this._buildCols(columns);
    return (
      <Panel header="Expenses" key="3">
        {this._createGrid(bottomLines, columns, 160, {}, 3, 'Expenses')}
      </Panel>
    );
  };

  _createFutureTrend = data => {
    const { futureTrendData } = data;
    return (
      <Panel header="Future trend" key="4">
        {this._createGrid(
          futureTrendData,
          companyFutureColumns,
          150,
          {},
          4,
          'Future trend'
        )}
      </Panel>
    );
  };

  _createMarketFeedback = data => {
    const { marketFeedback } = data;
    return (
      <Panel header="市场反馈" key="5">
        {this._createGrid(marketFeedback, marketFeedbackColumns, 65, {}, 5)}
      </Panel>
    );
  };

  _createInvestmentConclusion = data => {
    const { investmentConclusion } = data;
    return (
      <Panel header="Investment conclusion change" key="6">
        {this._createGrid(
          investmentConclusion,
          investmentConclusionColumns,
          65,
          {},
          6,
          'Investment conclusion change'
        )}
      </Panel>
    );
  };

  _createFollowUp = data => {
    const { followUp } = data;
    return (
      <Panel header="Follow up next quarter" key="7">
        {this._createGrid(
          followUp,
          followUpColumns,
          65,
          {},
          7,
          'Follow up next quarter'
        )}
      </Panel>
    );
  };

  _tabChange = key => {
    if (_.isEmpty(key)) return;
    const num = _.toNumber(key[key.length - 1]);
    if (this.defaultGridApi && this.defaultGridApi[num]) {
      this.defaultGridApi[num].forEach(r => {
        r.sizeColumnsToFit();
      });
    }
  };

  _createDetailPanel = () => {
    const { data } = this.props;
    const { review } = data;
    return (
      <Collapse defaultActiveKey={['1']} onChange={this._tabChange}>
        {this._createSingleQuarter(review, data)}
        {this._createPerformanceSplit(review, data)}
        {this._createBottomLine(review)}
        {this._createFutureTrend(review)}
        {this._createInvestmentConclusion(review)}
        {this._createFollowUp(review)}
      </Collapse>
    );
  };

  _onReviewDataChange = params => {
    const { onReviewDataChange } = this.props;
    onReviewDataChange(params);
  };

  onOverideSectorChange = e => {
    const { sectorData } = this.props;
    if (e.target.checked && sectorData) {
      this.props.onCompanyScoreDataChange({
        name: 'whatHappen',
        value: sectorData.whatHappen
      });
      _.delay(() => {
        this.props.onCompanyScoreDataChange({
          name: 'whatHappenDesc',
          value: sectorData.whatHappenDesc
        });
      }, 100);
    }
  };

  _syncData = async () => {
    this.setState({
      syncStatus: 'SYNCING'
    });
    await this.props.syncData();
    try {
      this.defaultGridApi[1][0].refreshCells();
      this.defaultGridApi[3][0].refreshCells();
    } catch (err) {}
    this.setState({
      syncStatus: 'READY'
    });
  };

  render() {
    const { readOnly } = this.props;
    const { data } = this.props;
    const { review } = data;
    return (
      <div id="companyScoreCompanyReview" style={{ marginTop: '15px' }}>
        <style>
          {`
                    .companyTypeKeyword {
                        /* color: rosybrown; */
                        color: #FF9E28;
                        font-weight: bold;
                        text-align: left;
                      }

                      .companyItemKeyword {
                        /* color: rosybrown; */
                        color: green;
                        font-weight: bold;
                        margin-left: 60px;
                      }

                      #companyScoreCompanyReview .mainContent {
                        font-size: 16px;
                        font-weight: bold;
                      }

                      #companyScoreCompanyReview .ant-collapse {
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                        color: white !important;
                        font-size: 14px;
                        font-variant: tabular-nums;
                        line-height: 1.5715;
                        list-style: none;
                        -webkit-font-feature-settings: 'tnum';
                        font-feature-settings: 'tnum', "tnum";
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9;
                        border-bottom: 0;
                        border-radius: 2px;
                    }

                    #companyScoreCompanyReview .ant-collapse > .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9;
                    }

                    #companyScoreCompanyReview .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                        position: relative;
                        padding: 5px 8px;
                        padding-left: 40px;
                        color: white;
                        line-height: 1.5715;
                        cursor: pointer;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                    }

                    #companyScoreCompanyReview .ant-collapse-content > .ant-collapse-content-box {
                        padding: 0px;
                    }

                    #companyScoreCompanyReview .ant-collapse-content {
                        overflow: hidden;
                        background-color: #2d3436;
                        border-top: 0px solid #d9d9d9;
                    }

                    #companyScoreCompanyReview .text-align-right .ag-header-cell-label {
                      text-align: right;
                      display: block;
                      margin-right: 5px;
                    }

                    #companyScoreCompanyReview .hasNotes {
                      border-left: 3px solid red;
                    }
                `}
        </style>
        <p
          style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
          className="site-page-header"
        >
          [
          <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
            Quarter:
            {readOnly ? (
              <span style={{ marginLeft: '10px' }}>{review.quanter}</span>
            ) : (
              <Select
                className="companyScoreSelect"
                style={{ width: '100px', marginLeft: '10px' }}
                value={review.quanter}
                onChange={value => {
                  this._onReviewDataChange({
                    name: 'quanter',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.quarterList)}
              </Select>
            )}
          </span>{' '}
          ]
          <span style={{ color: '#FF9E28', marginLeft: '5px' }}>
            What happened:
            <Select
              className="whatHappen"
              style={{ width: '200px', marginLeft: '10px' }}
              value={data.whatHappen}
              disabled={readOnly}
              onChange={value => {
                this.props.onCompanyScoreDataChange({
                  name: 'whatHappen',
                  value
                });
              }}
            >
              {_createNormalUIOptions(this.state.happenList)}
            </Select>
            <Input
              value={data.whatHappenDesc}
              readOnly={readOnly}
              placeholder={'Describe this event'}
              style={{ width: '500px', marginLeft: '10px' }}
              onChange={e => {
                this.props.onCompanyScoreDataChange({
                  name: 'whatHappenDesc',
                  value: e.target.value
                });
              }}
            />
            {readOnly ? (
              ''
            ) : (
              <Checkbox
                style={{
                  width: '150px',
                  marginLeft: '5px',
                  color: 'white',
                  fontWeight: 'normal'
                }}
                onChange={this.onOverideSectorChange}
              >
                Override sector
              </Checkbox>
            )}
            {readOnly ? (
              ''
            ) : (
              <div style={{ float: 'right' }}>
                <Button
                  size="small"
                  disabled={this.state.syncStatus !== 'READY'}
                  type="primary"
                  onClick={this._syncData}
                >
                  {this.state.syncStatus === 'READY' ? 'Sync' : 'Syncing'}
                </Button>
              </div>
            )}
          </span>
        </p>
        {this._createDetailPanel()}
        {this._createAddNoteModal()}
        {this._createNotesPopover()}
      </div>
    );
  }
}

export default CompanyReview;
