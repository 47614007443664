import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { DatePicker } from 'antd';
import moment from 'moment';

class PushVoteReturnQueryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockPitchPerfDate: moment().format('YYYY-MM-DD')
    };
  }

  componentDidMount() {
    this._loadData({});
  }

  _loadData = () => {
    this.props.loadPushVoteReturnData(this.state.stockPitchPerfDate);
  };

  dateOnChange = (date, dateString) => {
    this.setState({
      stockPitchPerfDate: dateString
    });
  };

  render() {
    const { isLoaded } = this.props;

    return (
      <div>
        <div
          style={{
            textAlign: 'right',
            backgroundColor: '#1B1C1D',
            marginBottom: '2px'
          }}
        >
          <DatePicker
            value={moment(this.state.stockPitchPerfDate, 'YYYY-MM-DD')}
            format="YYYY-MM-DD"
            style={{ width: '160px', marginRight: '5px' }}
            // size="small"
            allowClear={false}
            onChange={this.dateOnChange}
          />

          <Button
            size="mini"
            content="Refresh"
            color="green"
            disabled={!isLoaded}
            style={{ marginBottom: '4px', marginTop: '4px' }}
            onClick={this._loadData}
          />
        </div>
      </div>
    );
  }
}

export default PushVoteReturnQueryHeader;
