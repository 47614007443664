import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import _ from 'lodash';
import { priceTricingTradesColumns } from './GridColumnMap';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { DIALOG_PRICE_TRACKING_TRADES_MODAL } from '../portfolioConstants';
import { DIALOG_EXECUTE_QUANT_TRADES } from '../../order/orderConstants';
import client from '../api/client';

class GenPriceTrackingTradesDialog extends Component {
  state = {
    gridWrapperStyle: {
      width: '100%',
      height: '600px',
      marginTop: '2px'
    },
    submitStatus: 'READY',
    data: [],
    setting: null,
    priceTriceData: [],
    bookCode: null
  };

  componentDidMount() {
    const { positions, data } = this.props.info;
    const { user } = this.props.settings;
    const updateData = [];
    const tickerList = positions.map(r => r.ticker);
    const setting = hotTableUtils.createSettings({
      columns: priceTricingTradesColumns.map(d => {
        if (d.data === 'pairTicker') {
          return {
            ...d,
            source: tickerList
          };
        }
        return {
          ...d
        };
      }),
      contextMenu: ['remove_row']
    });
    let currentBook;
    data.forEach(r => {
      const { ticker, strategy, fundCode, bookCode } = r;
      const key = `${fundCode}-${bookCode}-${ticker}-${strategy}`;
      let item;
      currentBook = bookCode;
      const filterPositions = positions.filter(
        d => key === `${d.fundCode}-${d.bookCode}-${d.ticker}-${d.strategy}`
      );
      if (!_.isEmpty(filterPositions)) {
        const firstPos = filterPositions[0];
        const sectorPosList = positions;
        const notnlMktValBookPct = _.round(
          firstPos.notnlMktValBookPct * 100,
          3
        );
        item = {
          ...firstPos,
          ...r,
          sectorPosList,
          notnlMktValBookPct: notnlMktValBookPct,
          posnEndPct: notnlMktValBookPct,
          monitor: true
        };
      } else {
        item = {
          ...r,
          notnlMktValBookPct: 0,
          posnEndPct: 0,
          monitor: true,
          sectorPosList: []
        };
      }
      updateData.push(item);
    });

    currentBook = user.englishName === 'Wang Qiang' ? 'W' : currentBook;
    this._loadData(currentBook);
    this.setState({
      data: updateData,
      setting
    });
  }

  _loadData = bookCode => {
    Promise.all([client.loadTrackPriceList({ bookCode })])
      .then(([trackList]) => {
        this.setState({
          priceTriceData: trackList,
          bookCode
        });
      })
      .catch(err => console.log(err));
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, data } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Creating
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Create Trades
        </Button>
      )
    }[submitStatus];

    return _.isEmpty(data) ? '' : submitBtn;
  };

  _createPxAlertsBtn = handleSubmit => {
    const { submitStatus, data } = this.state;
    const filterData = data && data.filter(r => !r.monitor);
    const submitBtn = {
      SUBMITTING: (
        <Button key="submitPxAlert" type="primary" disabled loading>
          Saving
        </Button>
      ),
      ERROR: (
        <Button key="submitPxAlert" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submitPxAlert" type="primary" onClick={handleSubmit}>
          Save Px
        </Button>
      )
    }[submitStatus];

    return _.isEmpty(filterData) ? '' : submitBtn;
  };

  beforeChange = (changes, action) => {
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(realChanges);

    return false;
  };

  _handleChanges = (changes, holdingsByRouteKey = {}) => {
    const { data } = this.state;

    const updatedData = changes.reduce((prev, c) => {
      const [row, field, oldValue, newValue] = c;
      if (oldValue === newValue) return prev;

      return prev.map((r, i) => {
        if (i === row) {
          return this._calcPos(r, field, newValue);
        }
        return r;
      });
    }, data);

    _.delay(() => {
      this.setState({
        data: updatedData
      });
    }, 100);
  };

  _calcPos = (data, name, value) => {
    const { positions } = this.props.info;
    let updatedValue = value === '' ? null : value;
    if (name === 'pairTicker' && updatedValue) {
      const posList = positions.filter(r => r.ticker === updatedValue);
      const pos = !_.isEmpty(posList)
        ? posList[0]
        : {
            strategy: 'NONE',
            notnlMktValBookPct: 0,
            quantityDirection:
              data.quantityDirection === 'LONG' ? 'SHORT' : 'LONG'
          };
      const notnlMktValBookPct = _.round(pos.notnlMktValBookPct * 100, 3);
      return {
        ...data,
        [name]: updatedValue,
        pairStrategy: pos.strategy,
        pairQuantityDirection: pos.quantityDirection,
        pairNotnlMktValBookPct: notnlMktValBookPct,
        pairPosnEndPct: notnlMktValBookPct
      };
    }
    return {
      ...data,
      [name]: updatedValue
    };
  };

  onBeforeCellDown = (event, coords) => {
    const { row } = coords;
    if (row < 0) return;
    const { data } = this.state;
    const item = data[row];
    const setting = hotTableUtils.createSettings({
      columns: priceTricingTradesColumns.map(d => {
        if (d.data === 'pairTicker') {
          return {
            ...d,
            source: item.sectorPosList.map(r => r.ticker)
          };
        }
        return {
          ...d
        };
      }),
      contextMenu: ['remove_row']
    });
    this.setState({
      setting
    });
  };

  _createTradersTable = () => {
    const { gridWrapperStyle, data } = this.state;
    return (
      <div style={gridWrapperStyle}>
        <HotTable
          data={data}
          manualColumnResize={true}
          {...this.state.setting}
          rowHeaders={false}
          beforeOnCellMouseDown={this.onBeforeCellDown}
          beforeChange={this.beforeChange}
        ></HotTable>
      </div>
    );
  };

  openQuantTradesDialog = (trades, callback) => {
    this.props.openDialog(DIALOG_EXECUTE_QUANT_TRADES, {
      createNew: true,
      trades,
      callback
    });
  };

  _onSubmit = () => {
    const { data } = this.state;
    const trades = [];
    data.forEach(r => {
      const list = this.buildTrade(r);
      trades.push(...list);
    });
    if (!_.isEmpty(trades)) {
      this.openQuantTradesDialog(trades, this.savePricingTrack);
      this.closeDialog();
    }
  };

  savePricingTrack = () => {
    const { data, bookCode, priceTriceData } = this.state;
    const submitData = [];
    data
      .filter(
        r => !_.isNil(r.posnEndPct) && r.posnEndPct !== r.notnlMktValBookPct
      )
      .forEach(r => {
        const basePrice = r.lastPrice;
        const trackList = priceTriceData.filter(d => d.bbgTicker === r.ticker);
        if (!_.isEmpty(trackList)) {
          const trackData = trackList[0];
          submitData.push({
            ...trackData,
            basePrice,
            isRemoved: false
          });
        } else {
          const securityId = r.securityId;
          const bbgTicker = r.ticker;
          submitData.push({
            securityId,
            bbgTicker,
            strategy: r.strategy,
            quantityDirection: r.direction,
            displayCode: r.securityCode,
            basePrice,
            needCreated: true,
            isRemoved: false
          });
        }
      });
    this.setState({
      submitStatus: 'SUBMITTING'
    });
    client
      .saveTrackPriceList({
        fundBookList: [bookCode],
        data: submitData
      })
      .then(() => {
        this.setState({
          submitStatus: 'READY'
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({
          submitStatus: 'ERROR'
        });
      });
  };

  buildTrade = data => {
    const baseData = {
      fundCode: data.fundCode,
      bookCode: data.bookCode,
      algoCode: 'INLINE',
      lotSize: 1,
      orderType: 'MKT',
      pmReason: 'Px Alerts',
      pmRemark: 'INLINE 20%',
      qtyInput: '',
      requestType: 'TRD',
      tickerType: 'BB_TCM',
      timeInForce: 'DAY',
      tradeId: 0,
      tradeSource: 'IMS'
    };
    const trades = [];
    if (
      !_.isNil(data.posnEndPct) &&
      data.posnEndPct !== data.notnlMktValBookPct
    ) {
      trades.push({
        ...baseData,
        posnEndPct: data.posnEndPct,
        side: this.getTradeSide(data.posnEndPct, data.notnlMktValBookPct),
        strategy: data.strategy,
        ticker: data.ticker
      });
    }
    if (!_.isEmpty(data.pairTicker)) {
      trades.push({
        ...baseData,
        posnEndPct: data.pairPosnEndPct,
        side: this.getTradeSide(
          data.pairPosnEndPct,
          data.pairNotnlMktValBookPct
        ),
        strategy: data.pairStrategy,
        ticker: data.pairTicker
      });
    }
    return trades;
  };

  getTradeSide = (targetNet, net) => {
    const absTargetNet = Math.abs(targetNet);
    const absNet = Math.abs(net);
    if (targetNet < 0 && net > 0) {
      return 'SELL';
    } else if (targetNet > 0 && net < 0) {
      return 'COVR';
    } else if (absTargetNet > absNet && targetNet < 0) {
      return 'SHRT';
    } else if (absTargetNet > absNet && targetNet > 0) {
      return 'BUY';
    } else if (absTargetNet < absNet && targetNet < 0) {
      return 'COVR';
    } else if (absTargetNet < absNet && targetNet > 0) {
      return 'SELL';
    } else if (net < 0 && targetNet === 0) {
      return 'COVR';
    } else if (net > 0 && targetNet === 0) {
      return 'SELL';
    } else {
      return null;
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_PRICE_TRACKING_TRADES_MODAL);
  };

  render() {
    return (
      <div>
        <style>
          {`
                      .ui.modals {
                        display: flex !important;
                      }
                      .ui.modal {
                        margin-top: 0px !important;
                      }
                    `}
        </style>
        <Modal
          width={1200}
          height={700}
          maskClosable={false}
          title={`New Trades`}
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            this._createSubmitBtn(this._onSubmit),
            <Button
              key="close"
              type="primary"
              onClick={this.closeDialog}
              style={{ marginLeft: '10px' }}
            >
              Close
            </Button>
          ]}
        >
          {this._createTradersTable()}
        </Modal>
      </div>
    );
  }
}

export default GenPriceTrackingTradesDialog;
