import React, { Component } from 'react';

import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import { dateFormatter } from 'common/utils/valueFormatters';
import { Button, Divider, Empty } from 'antd';
import { Loader, Dimmer } from 'semantic-ui-react';
import moment from 'moment';
import DefaultPushUserManageModal from './DefaultPushUserManageModal';

class DailyReportManageGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadAllDataStatus: 'READY',
      loadDetailDataStatus: 'READY',
      rowGroupPanelShow: 'false',
      data: [],
      singleUserData: [],
      showDefaultConfigModal: false,
      saveStatus: 'READY',
      allDataColumn: [
        {
          field: 'createBy',
          headerName: 'User',
          enableRowGroup: true,
          rowGroup: true,
          cellClass: 'keyword',
          hide: true
        }
      ],
      singleUserDataColumn: [
        {
          field: 'date',
          headerName: 'Date',
          cellClass: 'keyword',
          valueFormatter: dateFormatter
        }
      ],
      currentData: {},
      autoGroupListColumnDef: {
        field: 'createBy',
        headerName: 'User',
        cellClass: 'keyword',
        valueFormatter: params => {
          if (params.data) {
            return `${params.data.date}(${params.data.dayOfWeek})`;
          }
        }
      }
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({
      loadAllDataStatus: 'LOADING'
    });
    client
      .getAllDailyList()
      .then(resp => {
        const resdata = _.isEmpty(resp.data)
          ? []
          : resp.data.map(r => ({
              ...r,
              date: _.toString(r.date).substring(0, 10),
              dayOfWeek: moment(r.date)
                .locale('en')
                .format('ddd')
            }));
        this.setState({
          data: resdata,
          loadAllDataStatus: 'READY',
          singleUserData: []
        });
      })
      .catch(err => {
        this.setState({
          loadAllDataStatus: 'READY'
        });
        console.log(err);
      });
  };

  _loadCurrentData = data => {
    this.setState({
      loadDetailDataStatus: 'LOADING'
    });
    client
      .getDailyWithId(data)
      .then(resp => {
        const data = resp.data;
        this.setState({
          currentData: {
            ...data,
            dataList: JSON.parse(data.data)
          },
          loadDetailDataStatus: 'READY'
        });
      })
      .catch(err => {
        this.setState({
          loadDetailDataStatus: 'READY'
        });
        console.log(err);
      });
  };

  onRowDataChanged = () => {
    if (this.gridApi) {
      const nodes = this.gridApi.getRenderedNodes();
      if (!_.isEmpty(nodes)) {
        const selectedNode = nodes[0];
        nodes.forEach(element => {
          if (element.id === selectedNode.id) {
            this.gridApi.selectNode(element, true);
          }
        });
      }
    }
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _onSingleGridReady = params => {
    this.singleGridApi = params.api;
    this.singleGolumnApi = params.columnApi;

    this.singleGridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows && selectedRows[0]) {
      _.delay(() => {
        this._loadCurrentData(selectedRows[0]);
      }, 100);
    }
  };

  onSingleSelectionChanged = () => {
    const selectedRows = this.singleGridApi.getSelectedRows();
    if (selectedRows && selectedRows[0]) {
      _.delay(() => {
        this._loadCurrentData(selectedRows[0]);
      }, 100);
    }
  };

  _createAllDataGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const sortedData = _.orderBy(data, ['createBy', 'date'], ['asc', 'desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.allDataColumn}
            rowData={sortedData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            getRowNodeId={data => data.id}
            rowSelection="single"
            onGridReady={this._onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            autoGroupColumnDef={this.state.autoGroupListColumnDef}
          />
        </div>
      </div>
    );
  };

  _createSingleUserDataGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { singleUserData } = this.state;
    const sortedData = _.orderBy(singleUserData, ['date'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.singleUserDataColumn}
            rowData={sortedData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            getRowNodeId={data => data.id}
            rowSelection="single"
            onGridReady={this._onSingleGridReady}
            onSelectionChanged={this.onSingleSelectionChanged}
          />
        </div>
      </div>
    );
  };

  _createOpBar = () => {
    const { loadAllDataStatus } = this.state;
    return (
      <div style={{ height: '30px', textAlign: 'right', width: '100%' }}>
        <Button
          size="small"
          type="primary"
          style={{ marginRight: '8px' }}
          onClick={this._openDefaultPushUserManageModal}
        >
          Default Push Manage
        </Button>

        <Button
          size="small"
          type="primary"
          style={{ marginRight: '8px' }}
          onClick={this._loadData}
          disabled={loadAllDataStatus !== 'READY'}
        >
          {loadAllDataStatus === 'READY' ? 'Load' : 'Loading'}
        </Button>
      </div>
    );
  };

  _createReviewPannel = () => {
    const { currentData, loadDetailDataStatus } = this.state;
    if (_.isEmpty(currentData)) {
      return <Empty />;
    }
    return loadDetailDataStatus !== 'READY' ? (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    ) : (
      <div id="capture-analyst-daily-show-element">
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '5px',
            verticalAlign: 'bottom'
          }}
        >
          <style>
            {`
                    #capture-analyst-daily-show-element {
                      height: 100%;
                      background-color: whitesmoke
                    }

                    #capture-analyst-daily-show-element img{
                        width: auto !important;
                        height: auto !important;
                        max-width: 100% !important;
                        max-height: 100% !important;	
                    }

                    #capture-analyst-daily-show-element table{
                      width: auto !important;
                      height: auto !important;
                      max-width: 100% !important;
                      max-height: 100% !important;	
                  }

                  #capture-analyst-daily-show-element table td{
                      border: 1px solid var(--w-e-textarea-border-color);	
                      height: 20px;
                      min-width: 20px;
                  }

                  #capture-analyst-daily-show-element table th{
                      background-color: var(--w-e-textarea-slight-bg-color);
                      border: 1px solid var(--w-e-textarea-border-color);	
                      height: 20px;
                      text-align: center;	
                  }
                `}
          </style>
          <div style={{ width: '100%' }}>
            {!_.isEmpty(currentData) &&
              currentData.dataList.map((r, index) =>
                index === 0 ? (
                  <div style={{ padding: '5px' }}>
                    <div
                      style={{ width: '100%', marginTop: '5px' }}
                      dangerouslySetInnerHTML={{ __html: r.content }}
                    ></div>
                  </div>
                ) : (
                  <div style={{ padding: '5px' }}>
                    <div style={{ width: '100%', marginTop: '5px' }}>
                      <span>
                        <b>Event:</b>
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        {_.join(r.events, '；')}
                      </span>
                    </div>
                    <div style={{ width: '100%', marginTop: '5px' }}>
                      <span>
                        <b>Sector:</b>
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        {_.join(r.sector, '；')}
                      </span>
                    </div>
                    <div style={{ width: '100%', marginTop: '5px' }}>
                      <span>
                        <b>Company:</b>
                      </span>
                      <span style={{ marginLeft: '10px' }}>
                        {_.join(r.company, '；')}
                      </span>
                    </div>
                    <div
                      style={{ width: '100%', marginTop: '5px' }}
                      dangerouslySetInnerHTML={{ __html: r.content }}
                    ></div>
                    <Divider />
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    );
  };

  _openDefaultPushUserManageModal = () => {
    this.setState({
      showDefaultConfigModal: true
    });
  };

  _closeDefaultPushUserManageModal = () => {
    this.setState({
      showDefaultConfigModal: false
    });
  };

  _createDefaultPushUserManageModal = () => {
    const { showDefaultConfigModal } = this.state;
    return (
      showDefaultConfigModal && (
        <DefaultPushUserManageModal
          {...this.props}
          closeModal={this._closeDefaultPushUserManageModal}
        />
      )
    );
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <div style={{ width: '100%', height: '40px' }}>
          {this._createOpBar()}
        </div>
        <div style={{ width: '10%', height: '100%', float: 'left' }}>
          {this._createAllDataGrid()}
        </div>
        <div
          style={{ width: '90%', height: `calc(100vh - 190px)`, float: 'left' }}
        >
          {this._createReviewPannel()}
        </div>
        {this._createDefaultPushUserManageModal()}
      </div>
    );
  }
}

export default DailyReportManageGrid;
