import React, { Component } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import moment from 'moment';

class RiskControlDialog extends Component {
  render() {
    const { isOpened } = this.props.riskControlModal;
    const { riskInfo } = this.props;
    const riskControlDate =
      riskInfo && riskInfo.riskControlDate
        ? moment(riskInfo.riskControlDate).format('YYYY-MM-DD')
        : '';

    const messageList =
      riskInfo &&
      riskInfo.riskControlMessage &&
      riskInfo.riskControlMessage
        .split(';')
        .filter(m => m)
        .map((m, i) => <li key={i}>{m}</li>);

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }
          `}
        </style>

        <Modal size="tiny" open={isOpened}>
          <Header content="Risk Control" />
          <Modal.Content>
            <p>
              1. You are under risk control since {`${riskControlDate}`}, please
              adjust your portfolio within two days.
            </p>
            <p>
              2. If you are still under risk controlled after two days, your
              portfolio will be adjusted automatically.
            </p>

            <p>The following are the risk limits you have breached:</p>
            <ul>{messageList}</ul>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              content="OK"
              onClick={this.props.resetRiskControlModal}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default RiskControlDialog;
