import { createReducer } from 'common/utils/reducerUtils';
import _ from 'lodash';

import {
  // All Tickers
  LOAD_ALL_TICKERS_SUCCESS,
  LOAD_ALL_TICKERS_FAILURE,

  // Short Interest
  LOAD_SHORT_INTEREST_SUCCESS,
  LOAD_SHORT_INTEREST_FAILURE,

  // Shortable Tickers
  LOAD_SHORTABLE_TICKERS_SUCCESS,
  LOAD_SHORTABLE_TICKERS_FAILURE,

  // axe list Tickers
  LOAD_AXELIST_TICKERS_SUCCESS,
  LOAD_AXELIST_TICKERS_FAILURE,

  // Research Company
  LOAD_COMPANY_RESEARCH_SUCCESS,
  LOAD_COMPANY_RESEARCH_FAILURE,
  COMPANY_RESEARCH_SUBMIT_REQUEST,
  COMPANY_RESEARCH_SUBMIT_SUCCESS,
  COMPANY_RESEARCH_SUBMIT_FAILURE,
  COMPANY_RESEARCH_VALIDATION_FAILURE,
  DELETE_COMPANY_RESEARCH_SUCCESS,
  DELETE_COMPANY_RESEARCH_FAILURE,
  OPEN_COMPANY_RESEARCH_NEW_DIALOG,
  OPEN_COMPANY_RESEARCH_UPDATE_DIALOG,
  COMPANY_RESEARCH_INPUT_CHANGE,

  // Broker Score
  LOAD_BROKER_SCORES_SUCCESS,
  LOAD_BROKER_SCORES_FAILURE,
  DELETE_BROKER_SCORES_SUCCESS,
  DELETE_BROKER_SCORES_FAILURE,
  BROKER_SCORES_SUBMIT_REQUEST,
  BROKER_SCORES_SUBMIT_SUCCESS,
  BROKER_SCORES_SUBMIT_FAILURE,
  OPEN_BROKER_SCORES_NEW_DIALOG,
  OPEN_BROKER_SCORES_UPDATE_DIALOG,
  BROKER_SCORES_INPUT_CHANGE,
  NEW_BROKER_SCORE_ROW_DATA,
  OPEN_BROKER_SCORES_DETAIL_DIALOG,
  RESET_BROKER_SCORES_DETAIL_DIALOG,
  OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG,
  RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG,
  LOAD_BROKER_SCORE_BY_BROKER_SUCCESS,
  LOAD_BROKER_SCORE_BY_BROKER_FAILURE,
  LOAD_BROKER_SCORE_LIST_SUCCESS,
  LOAD_BROKER_SCORE_LIST_FAILURE,
  SAVE_BROKER_SCORE_SUCCESS,
  SAVE_BROKER_SCORE_FAILURE,
  REMOVE_BROKER_SCORE_SUCCESS,
  REMOVE_BROKER_SCORE_FAILURE,
  INITIATE_WEIGHT_MAP_LOADER_STATUS,
  CHANGE_BROKER_QUERY_PARAMS,
  CHANGE_BROKER_SCORE_MODAL_INPUT,

  // Analyst Compliment
  LOAD_ANALYST_COMPLIMENT_SUCCESS,
  LOAD_ANALYST_COMPLIMENT_FAILURE,
  DELETE_ANALYST_COMPLIMENT_SUCCESS,
  DELETE_ANALYST_COMPLIMENT_FAILURE,
  ANALYST_COMPLIMENT_SUBMIT_REQUEST,
  ANALYST_COMPLIMENT_SUBMIT_SUCCESS,
  ANALYST_COMPLIMENT_SUBMIT_FAILURE,
  OPEN_ANALYST_COMPLIMENT_NEW_DIALOG,
  OPEN_ANALYST_COMPLIMENT_UPDATE_DIALOG,
  ANALYST_COMPLIMENT_INPUT_CHANGE,
  // Broker Names
  LOAD_BROKER_NAMES_SUCCESS,
  LOAD_BROKER_NAMES_FAILURE,
  // Boxed Position
  LOAD_BOXED_POSITION_SUCCESS,
  LOAD_BOXED_POSITION_FAILURE,
  // hide research control
  RESEARCH_CONTROL_RESET,
  // reset broker control
  BROKER_CONTROL_RESET,
  // reset analyst control
  ANALYST_CONTROL_RESET,

  // server error message
  SERVER_ERROR_MESSAGE,

  // initiate loader status
  INITIATE_LOADER_STATUS,
  TICKERS_SEARCH_REQUEST,
  TICKERS_SEARCH_SUCCESS,

  // monthly review
  LOAD_MONTHLY_BENCHMARK_SUCCESS,
  LOAD_MONTHLY_BENCHMARK_FAILURE,
  LOAD_MONTHLY_MY_PORTFOLIO_SUCCESS,
  LOAD_MONTHLY_MY_PORTFOLIO_FAILURE,
  LOAD_MONTHLY_REVIEW_DETAIL_SUCCESS,
  LOAD_MONTHLY_REVIEW_DETAIL_FAILURE,
  MONTHLY_REVIEW_DETAIL_INPUT_CHANGE,
  INITIATE_MONTHLY_REVIEW_LOADER_STATUS,
  LOAD_MONTHLY_REVIEW_LIST_SUCCESS,
  LOAD_MONTHLY_REVIEW_LIST_FAILURE,
  SAVE_MONTHLY_REVIEW_SUCCESS,
  SAVE_MONTHLY_REVIEW_FAILURE,
  SUBMIT_MONTHLY_REVIEW_SUCCESS,
  SUBMIT_MONTHLY_REVIEW_FAILURE,
  REMOVE_MONTHLY_REVIEW_SUCCESS,
  REMOVE_MONTHLY_REVIEW_FAILURE,
  INITIATE_MONTHLY_REVIEW_DETAIL_DATA,
  CHAGE_MONTHLY_REVIEW_QUERY_PARAMS,
  INITIATE_MONTHLY_REVIEW_LOADER_BENCHMARK_STATUS,
  INITIATE_MONTHLY_REVIEW_LOADER_MYPERFORMACNE_STATUS,
  LOAD_CORRESPONDENCE_LIST_SUCCESS,
  LOAD_CORRESPONDENCE_LIST_FAILURE,
  LOAD_SHORTABLE_LIST_SUCCESS,
  LOAD_SHORTABLE_LIST_FAILURE,
  LOAD_SHORTABLE_EXCHANGE_LIST_SUCCESS,
  LOAD_SHORTABLE_EXCHANGE_LIST_FAILURE,
  INIT_SHORTABLE_EXCHANGE_STATUS,
  LOAD_ANALYST_LIST_SUCCESS,
  LOAD_ANALYST_LIST_FAILURE,
  SAVE_ANALYST_SUCCESS,
  SAVE_ANALYST_FAILURE,
  DELETE_ANALYST_SUCCESS,
  DELETE_ANALYST_FAILURE,
  CHANGE_ANALYST_QUERY_PARAMS,
  CHANGE_ANALYST_MODAL_INPUT,
  CLOSE_ANALYST_MODAL_WIN,
  GET_BROKER_LIST_SUCCESS,
  GET_BROKER_LIST_ERROR,
  CHANGE_ANALYST_UPDATE_MODAL_INPUT,
  GET_ANALYST_NAME_LIST_SUCCESS,
  GET_ANALYST_NAME_LIST_ERROR,
  GET_ANALYST_SECTOR_LIST_SUCCESS,
  GET_ANALYST_SECTOR_LIST_ERROR,
  GET_ANALYST_DETAIL_SUCCESS,
  GET_ANALYST_DETAIL_ERROR,
  GET_GROSS_AND_BETA_BY_FUNDANDBOOK_SUCCESS,
  GET_GROSS_AND_BETA_BY_FUNDANDBOOK_FAILURE,
  GET_MANAGER_USER_LIST_SUCCESS,
  GET_MANAGER_USER_LIST_FAILURE,
  SAVE_BROKER_SCORE_RANK_SUCCESS,
  SAVE_BROKER_SCORE_RANK_FAILURE,
  QUERY_BROKER_SCORE_RANK_SUCCESS,
  QUERY_BROKER_SCORE_RANK_FAILURE,
  LOAD_YEAR_BROKER_SCORE_SUCCESS,
  LOAD_YEAR_BROKER_SCORE_FAILURE,
  LOAD_RESEARCH_REPORT_LIST_SUCCESS,
  LOAD_RESEARCH_REPORT_LIST_FAILURE,
  LOAD_RESEARCH_REPORT_DETAIL_SUCCESS,
  LOAD_RESEARCH_REPORT_DETAIL_FAILURE,
  INIT_LOAD_RESEARCH_REPORT_STATUS,
  INIT_LOAD_RESEARCH_REPORT_DETAIL_STATUS,
  CHANGE_RESEARCH_REPORT_DETAIL_PARAMS,
  OPEN_RESEARCH_REPORT_MODAL,
  CLOSE_RESEARCH_REPORT_MODAL,
  SEARCH_TICKER_SUCCESS,
  SEARCH_TICKER_FAILURE,
  SAVE_RESERCH_REPORT_SUCCESS,
  SAVE_RESERCH_REPORT_FAILURE,
  CHAGE_RESEARCH_REPORT_QUERY_PARAMS,
  COPY_RESERCH_REPORT_SUCCESS,
  COPY_RESERCH_REPORT_FAILURE,
  GET_RESERCH_PERSON_LIST_SUCCESS,
  GET_RESERCH_PERSON_LIST_FAILURE,
  GET_RESERCH_GICS_LIST_SUCCESS,
  GET_RESERCH_GICS_LIST_FAILURE,
  DELETE_RESERCH_ATTACH_SUCCESS,
  DELETE_RESERCH_ATTACH_FAILURE,
  RESERCH_REPORT_CELL_CHANGE,
  LOAD_SHORT_INTEREST_PERCENT_SUCCESS,
  LOAD_SHORT_INTEREST_PERCENT_FAILURE,
  GET_BLOCK_LIST_SUCCESS,
  GET_BLOCK_LIST_FAILURE,
  ADD_COMPANY_KEY_DATA_SUCCESS,
  ADD_COMPANY_KEY_DATA_FAILURE,
  UPDATE_COMPANY_KEY_DATA_SUCCESS,
  UPDATE_COMPANY_KEY_DATA_FAILURE,
  GET_COMPANY_KEY_DATA_SUCCESS,
  GET_COMPANY_KEY_DATA_FAILURE,
  ADD_COMPANY_MEETING_RECORD_SUCCESS,
  ADD_COMPANY_MEETING_RECORD_FAILURE,
  UPDATE_COMPANY_MEETING_RECORD_SUCCESS,
  UPDATE_COMPANY_MEETING_RECORD_FAILURE,
  GET_COMPANY_MEETING_RECORD_SUCCESS,
  GET_COMPANY_MEETING_RECORD_FAILURE,
  ADD_COMPANY_REPLAY_SCORE_SUCCESS,
  ADD_COMPANY_REPLAY_SCORE_FAILURE,
  UPDATE_COMPANY_REPLAY_SCORE_SUCCESS,
  UPDATE_COMPANY_REPLAY_SCORE_FAILURE,
  GET_COMPANY_REPLAY_SCORE_SUCCESS,
  GET_COMPANY_REPLAY_SCORE_FAILURE,
  GET_COMPANY_KEY_DETAIL_DATA_SUCCESS,
  GET_COMPANY_KEY_DETAIL_DATA_FAILURE,
  OPEN_COMPANY_KEY_DETAIL_DATA_MODAL,
  CLOSE_COMPANY_KEY_DETAIL_DATA_MODAL,
  GET_COMPANY_MEETING_RECORD_DETAIL_SUCCESS,
  GET_COMPANY_MEETING_RECORD_DETAIL_FAILURE,
  OPEN_COMPANY_MEETING_RECORD_DETAIL_MODAL,
  CLOSE_COMPANY_MEETING_RECORD_DETAIL_MODAL,
  GET_COMPANY_REPLAY_SCORE_DETAIL_SUCCESS,
  GET_COMPANY_REPLAY_SCORE_DETAIL_FAILURE,
  OPEN_COMPANY_REPLAY_SCORE_MODAL,
  CLOSE_COMPANY_REPLAY_SCORE_MODAL,
  FETCH_ALL_EXCELLENTCOMPANY_SUCCESS,
  FETCH_ALL_EXCELLENTCOMPANY_FAILURE,

  //push votes
  OPEN_ADD_UPDATE_PUSH_VOTES_MODAL,
  CLOSE_ADD_UPDATE_PUSH_VOTES_MODAL,
  CHANGE_PUSH_VOTES_MODAL_INPUT,
  LOAD_PUSH_VOTES_DATA_SUCCESS,
  LOAD_PUSH_VOTES_DATA_FAILURE,
  SAVE_PUSH_VOTES_DATA_SUCCESS,
  SAVE_PUSH_VOTES_DATA_FAILURE,
  OPEN_ADD_PUSH_VOTE_NOTE_MODAL,
  CHANGE_PUSH_VOTE_NOTE_MODAL_INPUT,
  CLOSE_ADD_PUSH_VOTE_NOTE_MODAL,
  GET_PUSH_VOTES_SUBSCRIPT_CONFIG_SUCCESS,
  GET_PUSH_VOTES_SUBSCRIPT_CONFIG_FAILURE,
  GET_PUSH_VOTES_PM_USER_LIST_SUCCESS,
  GET_PUSH_VOTES_PM_USER_LIST_FAILURE,
  GET_PUSH_VOTES_ANALYST_USER_LIST_SUCCESS,
  GET_PUSH_VOTES_ANALYST_USER_LIST_FAILURE,
  CHANGE_PUSH_VOTES_SELECT_ITEM,
  ADJUST_PUSH_VOTES_DATA_SUCCESS,
  ADJUST_PUSH_VOTES_DATA_FAILURE,
  INIT_PUSH_VOTES_STATUS,
  SET_PUSH_VOTES_RECORD_READED,

  //portfolio plan
  OPEN_ADD_UPDATE_PORTFOLIO_PLAN_MODAL,
  CLOSE_ADD_UPDATE_PORTFOLIO_PLAN_MODAL,
  LOAD_PUSH_VOTE_RETURN_DATA_SUCCESS,
  LOAD_PUSH_VOTE_RETURN_DATA_FAILURE
} from './researchConstants';

import {
  shortableTickerGridColumnMap,
  axeListGridColumnMap,
  companyResearchGridColumnMap,
  brokerScoreGridColumnMap,
  brokerScoreYearGridColumnMap,
  analystComplimentGridColumnMap,
  boxedPositionGridColumnMap,
  monthlyReviewBenchmarkGridColumnMap,
  monthlyReviewPortfolioGridColumnMap,
  monthlyReviewGridColumnsMap,
  shortListGridColumnMap,
  analystListGridColumnMap,
  researchReportGridColumnsMap,
  blockListGridColumnsMap,
  meetingRecordGridColumnsMap,
  companyKeyDataTrackColumnsMap,
  companyReplayScoreColumnsMap,
  allExcellentCompanyColumnsMap,
  pushVotesGridColumnsMap,
  analystMeetingReconGridColumnsMap
} from './components/GridColumnMap';
import EntityMap from 'entities/EntityMap';
import moment from 'moment';
import OperationtMode from 'common/utils/OperationtMode';

const initialState = {
  isLoaded: false,

  shortableTickers: [],
  shortableTickersGridColumns: EntityMap.map(shortableTickerGridColumnMap),

  axeListTickers: [],
  axeListGridColumns: EntityMap.map(axeListGridColumnMap),

  // View Research
  companyResearchGridColumns: EntityMap.map(companyResearchGridColumnMap),
  companyResearchs: [],
  researchControlModal: {
    isOpened: false,
    fields: {
      Id: '',
      BbgTicker: '',
      SecName: '',
      TargetPrice1: '',
      TgtPriceProb1: 0.5,
      TargetPrice2: '',
      TgtPriceProb2: 0.5,
      TargetPrice3: '0',
      InvestmentRationale: '',
      PersonalView: '',
      RiskFactor: '',
      ResearchDate: '',
      PMName: '',
      Attachment: ''
    },
    fieldErrors: {},
    // server err messages
    serverErrMsg: '',
    isLoadingTickers: false,
    tickers: [],
    submitStatus: 'READY',
    mode: OperationtMode.NEW,
    lastTicker: ''
  },

  // Broker Score
  brokerScoreGridColumns: EntityMap.map(brokerScoreGridColumnMap),
  brokerScoreYearGridColumns: EntityMap.map(brokerScoreYearGridColumnMap),
  brokerScores: [],
  brokerControlModal: {
    isOpened: false,
    fields: {
      id: '',
      pm_name: '',
      entry_date: '',
      weight: 0,
      broker_name: '',
      correspondance: '',
      score: 0,
      Team: ''
    },
    fieldErrors: {},
    // server err messages
    serverErrMsg: '',
    submitStatus: 'READY',
    mode: OperationtMode.NEW
  },
  brokerQueryParams: {
    pageIdex: 1,
    pageSize: 5000,
    votingMonthFrom: moment().add('month', -3),
    votingMonthFromStr: moment()
      .add('month', -3)
      .format('YYYY-MM'),
    votingMonthTo: moment(),
    votingMonthToStr: moment().format('YYYY-MM')
  },
  brokerScoreControlModal: {
    isOpened: false,
    // server err messages
    submitStatus: 'READY',
    mode: OperationtMode.NEW,
    pmName: null,
    votingMonth: moment(),
    weightMap: [{ name: '', code: '', weight: 0 }],
    weightMapLoaded: false,
    errorMsgMap: {}
  },
  brokerScoreListForDetail: [],

  brokerScoreYearControlModal: {
    submitStatus: 'READY',
    isOpened: false,
    errorMsgMap: {},
    votingYear: moment()
      .add('month', -10)
      .format('YYYY'),
    data: []
  },

  // Analyst Compliment
  analystComplimentGridColumns: EntityMap.map(analystComplimentGridColumnMap),
  analystListGridColumns: EntityMap.map(analystListGridColumnMap),
  analystMeetingReconGridColumns: EntityMap.map(
    analystMeetingReconGridColumnsMap
  ),
  analystListModalData: {
    isOpen: false,
    data: [{}],
    votingMonth: moment().subtract(7, 'days')
  },
  analystCompliments: [],
  analystList: [],
  analystQueryParams: {
    votingMonthTo: moment(),
    votingMonthFrom: moment().add('month', -3),
    pageSize: 500
  },
  analystControlModal: {
    isOpened: false,
    fields: {
      id: '',
      pm_name: '',
      entry_date: '',
      broker_name: '',
      analyst_name: '',
      sector: '',
      reasons: '',
      Team: ''
    },
    fieldErrors: {},
    // server err messages
    serverErrMsg: '',
    submitStatus: 'READY',
    mode: OperationtMode.NEW
  },
  analystUpdateModalParam: {
    data: {},
    isOpened: false
  },
  loadAnalystDetailStatus: false,
  analystNameList: [],
  analystSectorList: [],

  brokerList: [],
  brokerCodeList: [],
  brokerLabelValueList: [],
  loadBrokerListStatus: false,
  loadAnalystNameListStatus: false,
  loadAnalystSectorListStatus: false,
  brokerScoreYearRankList: [],

  // monthlyReviewBenchmarkGrid
  monthlyReviewBenchmarkGridColumns: EntityMap.map(
    monthlyReviewBenchmarkGridColumnMap
  ),
  monthlyReviewBenchmarkData: [],
  monthlyReviewBenchmarkDataReadonly: [],
  // monthlyReviewPortfolioGrid
  monthlyReviewPortfolioGridColumns: EntityMap.map(
    monthlyReviewPortfolioGridColumnMap
  ),
  monthlyReviewPortfolioGridData: [],
  monthlyReviewPortfolioGridDataReadonly: [],

  monthlyReviewGridColumns: EntityMap.map(monthlyReviewGridColumnsMap),
  monthliReviewData: [],
  monthlyReviewQueryParams: {
    monthlyReviewFromStr: moment()
      .clone()
      .add('month', -2)
      .format('YYYY-MM'),
    monthlyReviewToStr: moment().format('YYYY-MM'),
    queryPmList: []
  },
  monthlyReviewPmSelectList: [],
  monthlyReviewDetailData: {
    yearMonth: moment().format('YYYY-MM'),
    monthForEng: moment()
      .locale('en')
      .format('MMMM'),
    month: moment().format('M'),
    year: moment().format('GGGG'),
    nextMonth: moment()
      .locale('en')
      .add('month', 1)
      .format('MMMM'),
    grossMargin: 0,
    netValue: 0,
    status: 0,
    userId: 0,
    errorMsgList: [],
    modalShow: false
  },
  monthlyReviewDetailDataReadonly: {},
  monthlyReviewRemoveCallback: {},
  detailIsLoaded: true,
  monthlyReviewBenchmarkStatus: false,
  monthlyReviewMyPerformanceStatus: false,

  // Short Interest
  allTickers: [],
  shortInterest: [],
  shortInterestPercent: [],

  // Boxed Position
  boxedPositionGridColumns: EntityMap.map(boxedPositionGridColumnMap),
  boxedPositions: [],

  // Broker Name
  brokerNames: [],
  brokerNameOptions: [],

  //short list grid
  shortListGridColumns: EntityMap.map(shortListGridColumnMap),
  shortableList: [],
  shortableListTotal: 0,
  shortExchangeList: [],
  isLoadedShortExchangeStatus: false,

  //research report
  researchReportGridColumns: EntityMap.map(researchReportGridColumnsMap),
  researchReportList: [],
  researchReportQueryParams: {
    startDate: moment()
      .clone()
      .add('month', -2)
      .format('YYYY-MM-DD'),
    endDate: moment()
      .clone()
      .add('day', 1)
      .format('YYYY-MM-DD'),
    mode: true
  },
  researchReportPersonList: [],
  researchReportGicsList: [],
  isResearchReportLoaded: false,
  isResearchReportDetailLoaded: false,
  researchReportDetail: {
    modalShow: false,
    submitData: {},
    fileList: []
  },
  researchReportFirstPageDetail: {},

  blockList: [],
  blockListGridColumns: EntityMap.map(blockListGridColumnsMap),

  companyKeyDataTrackList: [],
  companyKeyDataTrackDetail: {},
  companyMeetingRecordList: [],
  companyMeetingRecordDetail: {},
  companyReplayScoreList: [],
  companyReplayScoreDetail: {},

  companyKeyDataTrackModal: {
    isOpen: false,
    data: {}
  },
  companyMeetingRecordModal: {
    isOpen: false,
    data: {}
  },
  companyReplayScoreModal: {
    isOpen: false,
    data: {}
  },
  meetingRecordGridColumns: EntityMap.map(meetingRecordGridColumnsMap),
  companyKeyDataTrackColumns: EntityMap.map(companyKeyDataTrackColumnsMap),
  companyReplayScoreColumns: EntityMap.map(companyReplayScoreColumnsMap),
  allExcellentCompanyColumns: EntityMap.map(allExcellentCompanyColumnsMap),
  allExcellentCompanyList: [],

  //push votes
  pushVotesGridColumns: EntityMap.map(pushVotesGridColumnsMap),
  pushVotesDataList: [],
  pushVOtesSubscriptionList: [],
  pushVOtesPMList: [],
  pushVOtesAnalystList: [],
  pushVotesSelectItem: null,
  pushVotesModal: {
    isOpen: false,
    data: {
      scoreData: [[]]
    }
  },
  pushVotesSubmitStatus: 'READY',
  pushVotesSubmitErrMsg: null,
  pushVoteNoteModal: {
    isOpen: false
  },
  //portfolio plan
  portfolioPlanModal: {
    isOpen: false
  },

  pushVoteReturnDataList: []
};

// All Tickers
export function loadAllTickersSuccess(state, payload) {
  return {
    ...state,
    allTickers: payload.rows
  };
}

export function loadAllTickersFailure(state, payload) {
  return {
    ...state
  };
}

// Short Interest
export function loadShortInterestSuccess(state, payload) {
  const shortInterest = payload.rows.map(item => {
    return {
      date: moment(item.date).format('YYYY/MM/DD'),
      ticker: item.bbg_ticker,
      Share: item.shares,
      Dollar: item.dollar_amt
    };
  });

  return {
    ...state,
    shortInterest
  };
}

export function loadShortInterestFailure(state, payload) {
  return {
    ...state
  };
}

// axe List Tickers
export function loadAxeListTickersSuccess(state, payload) {
  return {
    ...state,
    axeListTickers: payload.rows,
    isLoaded: true
  };
}

// Shortable Tickers
export function loadShortableTickersSuccess(state, payload) {
  return {
    ...state,
    shortableTickers: payload.rows,
    isLoaded: true
  };
}

export function loadAxeListTickersFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadShortableTickersFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

// 获取Company Research 数据
export function loadCompanyResearchSuccess(state, payload) {
  return {
    ...state,
    companyResearchs: payload.rows,
    isLoaded: true
  };
}

export function loadCompanyResearchFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function openCompanyResearchNewDialog(state) {
  return {
    ...state,
    researchControlModal: {
      ...initialState.researchControlModal,
      isOpened: true,
      mode: OperationtMode.NEW,
      submitStatus: 'READY'
    }
  };
}

export function openCompanyResearchUpdateDialog(state, payload) {
  const filterCompanyResearchs = state.companyResearchs.filter(item => {
    return item.Id === payload;
  });

  let fields = state.researchControlModal.fields;
  if (!!payload) {
    fields = filterCompanyResearchs[0];
  }

  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      fields,
      isOpened: true,
      mode: OperationtMode.UPDATE,
      submitStatus: 'READY'
    }
  };
}

// 更新/新增 Company Research
export function submitCompanyResearchRequest(state, payload) {
  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      submitStatus: 'SUBMITTING'
    }
  };
}

export function submitCompanyResearchSuccess(state, payload) {
  return {
    ...state,
    researchControlModal: initialState.researchControlModal
  };
}

export function submitCompanyResearchFailure(state, payload) {
  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      serverErrMsg: SERVER_ERROR_MESSAGE,
      submitStatus: 'ERROR'
    }
  };
}

// 删除Company Research
export function deleteCompanyResearchSuccess(state, payload) {
  const companyResearchs = state.companyResearchs.filter(r => r.Id !== payload);
  return {
    ...state,
    companyResearchs
  };
}

export function deleteCompanyResearchFailure(state, payload) {
  return {
    ...state
  };
}

export function sendValidationFailure(state, payload) {
  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      fieldErrors: payload
    }
  };
}

// reset research control
export function resetResearchControlModal(state) {
  return {
    ...state,
    researchControlModal: initialState.researchControlModal
  };
}

// reset research control
export function resetBrokerControlModal(state) {
  return {
    ...state,
    brokerControlModal: initialState.brokerControlModal
  };
}

// reset research control
export function resetAnalystControlModal(state) {
  return {
    ...state,
    analystControlModal: initialState.analystControlModal
  };
}

export function openBrokerScoresNewDialog(state) {
  return {
    ...state,
    brokerControlModal: {
      ...state.brokerControlModal,
      isOpened: true,
      mode: OperationtMode.NEW,
      submitStatus: 'READY'
    }
  };
}

export function openBrokerScoresUpdateDialog(state, payload) {
  const filterbrokerScores = state.brokerScores.filter(item => {
    return item.id === payload;
  });

  let fields = state.brokerControlModal.fields;
  if (!!payload) {
    fields = filterbrokerScores[0];
  }

  Object.keys(fields).forEach(key => {
    if (fields[key] === null) fields[key] = '';
  });

  return {
    ...state,
    brokerControlModal: {
      ...state.brokerControlModal,
      fields,
      isOpened: true,
      mode: OperationtMode.UPDATE,
      submitStatus: 'READY'
    }
  };
}

export function openBrokerScoresDetailDialog(state, votingMonth) {
  var date = moment();
  if (votingMonth) {
    date = moment(votingMonth, 'YYYY-MM');
  }
  return {
    ...state,
    brokerScoreControlModal: {
      ...state.brokerScoreControlModal,
      isOpened: true,
      mode: OperationtMode.NEW,
      submitStatus: 'READY',
      votingMonth: date,
      weightMap: [{ name: '', code: '', weight: 0 }],
      weightMapLoaded: false,
      errorMsgMap: {}
    }
  };
}

// reset BrokerDetail control
export function resetBrokerDetailControlModal(state) {
  const updatedFields = {
    ...initialState.brokerScoreControlModal,
    weightMap: state.brokerScoreControlModal.weightMap
  };
  return {
    ...state,
    brokerScoreControlModal: updatedFields
  };
}

export function openYearBrokerScoresDetailDialog(state) {
  return {
    ...state,
    brokerScoreYearControlModal: {
      submitStatus: 'READY',
      isOpened: true,
      errorMsgMap: {},
      votingYear: moment()
        .add('month', -10)
        .format('YYYY'),
      isLoaded: false
    }
  };
}

export function resetYearBrokerScoresDetailDialog(state) {
  return {
    ...state,
    brokerScoreYearControlModal: initialState.brokerScoreYearControlModal
  };
}

function loadBrokerScoresSuccess(state, payload) {
  const brokerScores = payload.rows.map(c => ({
    id: c.id,
    pm_name: c.pm_name,
    entry_date:
      c.entry_date === null ? '' : moment(c.entry_date).format('YYYY-MM-DD'),
    weight: c.weight,
    broker_name: c.broker_name,
    correspondance: c.correspondance,
    score: c.score,
    Team: c.Team
  }));

  return {
    ...state,
    brokerScores,
    isLoaded: true
  };
}

function loadBrokerScoresFailure(state) {
  return {
    ...state,
    isLoaded: true
  };
}

export function brokerScoreChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.brokerControlModal.fields,
    [name]: value
  };

  return {
    ...state,
    brokerControlModal: {
      ...state.brokerControlModal,
      fields: updatedFields
    }
  };
}

// 编辑/新增 Broker Score
export function submitBrokerScoreRequest(state) {
  return {
    ...state,
    brokerControlModal: {
      ...state.brokerControlModal,
      submitStatus: 'SUBMITTING'
    }
  };
}

export function submitBrokerScoreSuccess(state, resp) {
  return {
    ...state,
    brokerControlModal: initialState.brokerControlModal
  };
}

export function submitBrokerScoreFailure(state, err) {
  return {
    ...state,
    brokerControlModal: {
      ...state.brokerControlModal,
      serverErrMsg: SERVER_ERROR_MESSAGE,
      submitStatus: 'ERROR'
    }
  };
}

// 删除 Broker Score
export function deleteBrokerScoreSuccess(state, resp) {
  const brokerScores = state.brokerScores.filter(b => b.id !== resp);
  return {
    ...state,
    brokerScores
  };
}

export function deleteBrokerScoreFailure(state, err) {
  return {
    ...state
  };
}

// 新增一条broker score 行数据
export function newBrokerScoreRowData(state, brokerRowData) {
  return {
    ...state,
    brokerScores: [...state.brokerScores, brokerRowData]
  };
}

function loadAnalystComplimentSuccess(state, payload) {
  return {
    ...state,
    analystCompliments: payload.rows,
    isLoaded: true
  };
}

function loadAnalystComplimentFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function openAnalystComplimentNewDialog(state) {
  return {
    ...state,
    analystControlModal: {
      ...state.analystControlModal,
      isOpened: true,
      mode: OperationtMode.NEW,
      submitStatus: 'READY'
    }
  };
}

export function openAnalystComplimentUpdateDialog(state, payload) {
  const filterAnalystCompliments = state.analystCompliments.filter(item => {
    return item.id === payload;
  });

  let fields = state.analystControlModal.fields;
  if (!!payload) {
    fields = filterAnalystCompliments[0];
  }

  Object.keys(fields).forEach(key => {
    if (fields[key] === null) fields[key] = '';
  });

  return {
    ...state,
    analystControlModal: {
      ...state.analystControlModal,
      fields,
      isOpened: true,
      mode: OperationtMode.UPDATE,
      submitStatus: 'READY'
    }
  };
}

export function analystComplimentChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.analystControlModal.fields,
    [name]: value
  };

  return {
    ...state,
    analystControlModal: {
      ...state.analystControlModal,
      fields: updatedFields
    }
  };
}

// 编辑/新增 Analyst Compliment
export function submitAnalystComplimentRequest(state) {
  return {
    ...state,
    analystControlModal: {
      ...state.analystControlModal,
      submitStatus: 'SUBMITTING'
    }
  };
}

export function submitAnalystComplimentSuccess(state, resp) {
  return {
    ...state,
    analystControlModal: initialState.analystControlModal
  };
}

export function submitAnalystComplimentFailure(state, err) {
  return {
    ...state,
    analystControlModal: {
      ...state.analystControlModal,
      serverErrMsg: SERVER_ERROR_MESSAGE,
      submitStatus: 'ERROR'
    }
  };
}

// 删除 Analyst Compliment
export function deleteAnalystComplimentSuccess(state, resp) {
  const analystCompliments = state.analystCompliments.filter(
    a => a.id !== resp
  );
  return {
    ...state,
    analystCompliments
  };
}

export function deleteAnalystComplimentFailure(state, err) {
  return {
    ...state
  };
}

// Boxed Position
function loadBoxedPositionSuccess(state, payload) {
  return {
    ...state,
    isLoaded: true,
    boxedPositions: payload.rows
  };
}

function loadBoxedPositionFailure(state) {
  return {
    ...state,
    isLoaded: true
  };
}

export function companyResearchChangeInput(state, { name, value }) {
  const updatedFields = {
    ...state.researchControlModal.fields,
    [name]: value
  };

  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      fields: updatedFields
    }
  };
}

export function requestSearchTickers(state) {
  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      isLoadingTickers: true
    }
  };
}

export function searchTickersSuccess(state, payload) {
  // let ticker = state.researchControlModal.fields.BbgTicker;
  // if (payload && payload.length === 0) {
  //   ticker = '';
  // }

  return {
    ...state,
    researchControlModal: {
      ...state.researchControlModal,
      isLoadingTickers: false,
      tickers: payload
      // fields: {
      //   ...state.researchControlModal.fields,
      //   BbgTicker: ticker,
      // }
    }
  };
}

// Broker Names
function loadBrokerNamesSuccess(state, payload) {
  const brokerNameOptions = payload.rows.map(item => ({
    key: item.BrokerName,
    value: item.BrokerName,
    text: item.BrokerName
  }));

  const brokerOnlyNames = payload.rows.map(item => {
    return item.BrokerName;
  });

  const brokerScoreGridColumns = state.brokerScoreGridColumns.map(c => {
    if (c.field === 'broker_name') {
      c.cellEditorParams.values = brokerOnlyNames;
    }

    return c;
  });

  const analystComplimentGridColumns = state.analystComplimentGridColumns.map(
    c => {
      if (c.field === 'broker_name') {
        c.cellEditorParams.values = brokerOnlyNames;
      }

      return c;
    }
  );

  return {
    ...state,
    brokerNames: payload.rows,
    brokerNameOptions,
    brokerScoreGridColumns,
    analystComplimentGridColumns
  };
}

function loadBrokerNamesFailure(state, err) {
  return {
    ...state
  };
}

// initiate loader status
export function initiateLoaderStatus(state) {
  return {
    ...state,
    isLoaded: false
  };
}
//monthly review
export function loadMonthlyReviewBenchmarkSuccess(state, payload) {
  const updateMode = payload ? payload['updateMode'] : false;
  if (updateMode) {
    return {
      ...state,
      monthlyReviewBenchmarkData: payload.data,
      monthlyReviewBenchmarkStatus: true
    };
  } else {
    return {
      ...state,
      monthlyReviewBenchmarkDataReadonly: payload.data,
      monthlyReviewBenchmarkStatus: true
    };
  }
}

export function loadMonthlyReviewBenchmarkFailure(state, payload) {
  return {
    ...state
  };
}

export function loadMonthlyReviewMyPortfilioSuccess(state, payload) {
  const updateMode = payload ? payload['updateMode'] : false;
  if (updateMode) {
    return {
      ...state,
      monthlyReviewPortfolioGridData: payload.data,
      monthlyReviewMyPerformanceStatus: true
    };
  } else {
    return {
      ...state,
      monthlyReviewPortfolioGridDataReadonly: payload.data,
      monthlyReviewMyPerformanceStatus: true
    };
  }
}

export function loadMonthlyReviewMyPortfilioFailure(state, payload) {
  return {
    ...state
  };
}

export function loadMonthlyReviewDetailSuccess(state, payload) {
  const updateMode = payload ? payload['updateMode'] : false;
  if (updateMode) {
    return {
      ...state,
      monthlyReviewDetailData: payload.data,
      detailIsLoaded: true
    };
  } else {
    return {
      ...state,
      monthlyReviewDetailDataReadonly: payload.data,
      detailIsLoaded: true
    };
  }
}

export function loadMonthlyReviewDetailFailure(state, payload) {
  return {
    ...state,
    detailIsLoaded: true
  };
}

export function monthlyReviewDetailDataInit(state) {
  return {
    ...state,
    monthlyReviewDetailData: initialState.monthlyReviewDetailData
  };
}

export function monthlyReviewDetailChangeInput(
  state,
  { name, value, notDirty }
) {
  let updatedData = {};
  if (notDirty) {
    updatedData = {
      ...state.monthlyReviewDetailData,
      [name]: value
    };
  } else {
    updatedData = {
      ...state.monthlyReviewDetailData,
      [name]: value,
      isDirty: true
    };
  }

  return {
    ...state,
    monthlyReviewDetailData: updatedData
  };
}

export function initiateMonthlyReviewDetailLoaderStatus(state) {
  return {
    ...state,
    detailIsLoaded: false
  };
}

export function loadMonthlyReviewListFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadMonthlyReviewListSuccess(state, payload) {
  return {
    ...state,
    monthliReviewData: payload.data,
    isLoaded: true
  };
}

export function saveMonthlyReviewFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function saveMonthlyReviewSuccess(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function submitMonthlyReviewFailure(state, result) {
  const updateData = {
    ...state.monthlyReviewDetailData
  };
  updateData['errorMsgList'] = [result['message']];
  return {
    ...state,
    monthlyReviewDetailData: updateData,
    isLoaded: true
  };
}

export function submitMonthlyReviewSuccess(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function removeMonthlyReviewFailure(state, payload) {
  return {
    ...state,
    isLoaded: true
  };
}

export function removeMonthlyReviewSuccess(state, payload) {
  return {
    ...state,
    monthlyReviewDetailDataReadonly: {},
    isLoaded: true
  };
}

export function initMonthlyReviewBenchmarkStatus(state) {
  return {
    ...state,
    monthlyReviewBenchmarkStatus: false
  };
}

export function initMonthlyReviewMyPerformanceStatus(state) {
  return {
    ...state,
    monthlyReviewMyPerformanceStatus: false
  };
}

//broker
export function loadBrokerScoreListByScoreSuccess(state, result) {
  //handle reuslt
  if (result.data) {
    _.map(result.data, (value, key) => {
      if (value) {
        value.forEach(item => {
          const detailList = item.detailList;
          detailList.forEach(ele => {
            const code = ele['code'];
            item[code] = ele['score'];
          });
        });
      }
    });
  }
  return {
    ...state,
    isLoaded: true,
    brokerScoreListForDetail: result.data
  };
}

export function loadBrokerScoreListByScoreFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadBrokerScoreListSuccess(state, result) {
  //handle reuslt
  return {
    ...state,
    brokerScores: result.data,
    isLoaded: true
  };
}

export function loadBrokerScoreListFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function saveBrokerScoreSuccess(state, result) {
  //handle reuslt
  return {
    ...state,
    brokerScoreControlModal: initialState.brokerScoreControlModal,
    isLoaded: true
  };
}

export function saveBrokerScoreFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function removeBrokerScoreSuccess(state, result) {
  return {
    ...state,
    isLoaded: true
  };
}

export function removeBrokerScoreFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadCorrespondenceListSuccess(state, result) {
  return {
    ...state,
    brokerScoreControlModal: {
      ...state.brokerScoreControlModal,
      weightMap: result.data,
      weightMapLoaded: true
    }
  };
}

export function loadCorrespondenceListFailure(state, err) {
  return {
    ...state,
    brokerScoreControlModal: {
      weightMapLoaded: true
    }
  };
}

export function initiateWeightMapLoaded(state) {
  return {
    ...state,
    brokerScoreControlModal: {
      ...state.brokerScoreControlModal,
      weightMapLoaded: false
    }
  };
}

export function changeBrokerQureyParams(state, input) {
  const updateData = {
    ...state.brokerQueryParams
  };
  // eslint-disable-next-line array-callback-return
  input.map(item => {
    updateData[item.name] = item.value;
  });
  return {
    ...state,
    brokerQueryParams: updateData
  };
}

export function changeBrokerScoreControlModalInput(state, { name, value }) {
  const updatedFields = {
    ...state.brokerScoreControlModal,
    [name]: value
  };

  return {
    ...state,
    brokerScoreControlModal: updatedFields
  };
}

export function loadShortableListSuccess(state, result) {
  return {
    ...state,
    shortableList: result.data,
    shortableListTotal: result.total,
    isLoaded: true
  };
}

export function loadShortableListFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function loadShortExchangeListSuccess(state, result) {
  return {
    ...state,
    shortExchangeList: result.data,
    isLoadedShortExchangeStatus: true
  };
}

export function loadShortExchangeListFailure(state, err) {
  return {
    ...state,
    isLoadedShortExchangeStatus: true
  };
}

export function initiateLoaderShortExchangeStatus(state) {
  return {
    ...state,
    isLoadedShortExchangeStatus: false
  };
}

//analyst
export function loadAnalystListSuccess(state, result) {
  return {
    ...state,
    analystList: result.data,
    isLoaded: true
  };
}

export function loadAnalystListFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

//analyst
export function saveAnalystSuccess(state, result) {
  return {
    ...state,
    isLoaded: true,
    analystListModalData: initialState.analystListModalData
  };
}

export function saveAnalystFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

export function deleteAnalystSuccess(state, result) {
  return {
    ...state,
    isLoaded: true,
    analystUpdateModalParam: initialState.analystUpdateModalParam
  };
}

export function deleteAnalystFailure(state, err) {
  return {
    type: DELETE_ANALYST_FAILURE,
    payload: err,
    isLoaded: true
  };
}

export function changeAnalystQueryParams(state, { name, value }) {
  const updatedFields = {
    ...state.analystQueryParams,
    [name]: value
  };
  return {
    ...state,
    analystQueryParams: updatedFields
  };
}

export function changeAnalystModalInput(state, { name, value }) {
  const updatedFields = {
    ...state.analystListModalData,
    [name]: value
  };
  return {
    ...state,
    analystListModalData: updatedFields
  };
}

export function closeAnalystModal(state) {
  const updatedFields = {
    ...state.analystListModalData,
    isOpened: false
  };
  return {
    ...state,
    analystListModalData: updatedFields
  };
}

export function getBrokerListSuccess(state, result) {
  const brokerCodeList = [];
  const brokerLabelValueList = [];
  if (result && result.data) {
    for (var i = 0; i < result.data.length; i++) {
      brokerCodeList.push(result.data[i]['brokerName']);
      brokerLabelValueList.push({
        label: result.data[i]['brokerName'],
        value: result.data[i]['brokerCode']
      });
    }
  }
  return {
    ...state,
    brokerList: result.data,
    brokerCodeList: brokerCodeList,
    brokerLabelValueList: brokerLabelValueList,
    loadBrokerListStatus: true
  };
}

export function getBrokerListFailure(state, err) {
  return {
    ...state,
    loadBrokerListStatus: true
  };
}

export function initiateLoaderBrokerStatus(state) {
  return {
    ...state,
    loadBrokerListStatus: false
  };
}

export function changeAnalystUpdateModalInput(state, { name, value }) {
  const updatedFields = {
    ...state.analystUpdateModalParam,
    [name]: value
  };
  return {
    ...state,
    analystUpdateModalParam: updatedFields
  };
}

export function getAnalystNameEditListSuccess(state, result) {
  return {
    ...state,
    analystNameList: result.data,
    loadAnalystNameListStatus: true
  };
}

export function initiateLoaderAnalystNameListStatus(state) {
  return {
    ...state,
    loadAnalystNameListStatus: false
  };
}

export function getAnalystNameEditListFailure(state, err) {
  return {
    ...state,
    loadAnalystNameListStatus: true
  };
}

export function getAnalystSectorListSuccess(state, result) {
  return {
    ...state,
    analystSectorList: result.data,
    loadAnalystSectorListStatus: true
  };
}

export function getAnalystSectorListFailure(state, err) {
  return {
    ...state,
    loadAnalystSectorListStatus: true
  };
}

export function initiateLoaderAnalystSectorListStatus(state) {
  return {
    ...state,
    loadAnalystSectorListStatus: false
  };
}

export function getAnalystDetailListSuccess(state, result) {
  var data = result.data;
  if (!data) {
    data = [];
  }
  for (var i = data.length; i <= 20; i++) {
    data.push({});
  }
  const updatedFields = {
    ...state.analystListModalData,
    data: data
  };
  return {
    ...state,
    analystListModalData: updatedFields,
    loadAnalystDetailStatus: true
  };
}

export function getAnalystDetailListFailure(state, err) {
  return {
    ...state,
    loadAnalystDetailStatus: true
  };
}

export function initiateLoaderAnalystDetailListStatus(state) {
  return {
    ...state,
    loadAnalystDetailStatus: false
  };
}

export function changeMonthlyReviewQueryParams(state, { name, value }) {
  const updatedData = {
    ...state.monthlyReviewQueryParams,
    [name]: value
  };

  return {
    ...state,
    monthlyReviewQueryParams: updatedData
  };
}

function getGrossAndBetaByFundAndBookSuccess(state, result) {
  const readOnlyPage = result['readOnlyPage'];
  if (readOnlyPage) {
    const updatedData = { ...state.monthlyReviewDetailDataReadonly };
    updatedData['grossMargin'] = result.data['gross'];
    updatedData['netValue'] = result.data['beta'];
    updatedData['ytd'] = result.data['ytd'];
    updatedData['mtd'] = result.data['mtd'];
    return {
      ...state,
      monthlyReviewDetailDataReadonly: updatedData
    };
  } else {
    const updatedData = { ...state.monthlyReviewDetailData };
    updatedData['grossMargin'] = result.data['gross'];
    updatedData['netValue'] = result.data['beta'];
    updatedData['ytd'] = result.data['ytd'];
    updatedData['mtd'] = result.data['mtd'];
    return {
      ...state,
      monthlyReviewDetailData: updatedData
    };
  }
}

function getGrossAndBetaByFundAndBookFailure(state, err) {
  return {
    ...state
  };
}

function getManagerUserListSuccess(state, data) {
  return {
    ...state,
    monthlyReviewPmSelectList: data['data']
  };
}

function getManagerUserListFailure(state, err) {
  return {
    ...state
  };
}

function saveBrokerScoreRankListSuccess(state, data) {
  const updatedData = { ...state.brokerScoreYearControlModal };
  updatedData['isOpened'] = false;
  return {
    ...state,
    brokerScoreYearControlModal: updatedData
  };
}

function saveBrokerScoreRankListFailure(state, err) {
  return {
    ...state
  };
}

function queryBrokerScoreRankListByScoreSuccess(state, data) {
  const updatedData = { ...state.brokerScoreYearControlModal };
  updatedData['data'] = data['data'];
  updatedData['isLoaded'] = false;
  return {
    ...state,
    brokerScoreYearControlModal: updatedData
  };
}

function queryBrokerScoreRankListByScoreFailure(state, err) {
  return {
    ...state
  };
}

function loadYearBrokerScoresSuccess(state, data) {
  return {
    ...state,
    brokerScoreYearRankList: data['data'],
    isLoaded: true
  };
}

function loadYearBrokerScoresFailure(state, err) {
  return {
    ...state,
    isLoaded: true
  };
}

function loadResearchReportListSuccess(state, data) {
  let result = data ? data['data'] : null;
  return {
    ...state,
    researchReportList: result,
    researchReportFirstPageDetail: {},
    isResearchReportLoaded: true
  };
}

function loadResearchReportListFailure(state, err) {
  return {
    ...state,
    isResearchReportLoaded: true
  };
}

function loadResearchReportDetailSuccess(state, { data, params }) {
  let result = data ? data['data'] : {};
  if (params && params['source'] === 'listPage') {
    const updatedData = { ...state.researchReportFirstPageDetail };
    updatedData['data'] = result;
    return {
      ...state,
      researchReportFirstPageDetail: updatedData,
      isResearchReportDetailLoaded: true
    };
  } else {
    const updatedData = { ...state.researchReportDetail };
    updatedData['data'] = result;
    return {
      ...state,
      researchReportDetail: updatedData,
      isResearchReportDetailLoaded: true
    };
  }
}

function loadResearchReportDetailFailure(state, err) {
  return {
    ...state,
    isResearchReportDetailLoaded: true
  };
}

function initiateResearchReportStatus(state) {
  return {
    ...state,
    isResearchReportLoaded: false
  };
}

function initiateResearchReportDetailStatus(state) {
  return {
    ...state,
    isResearchReportDetailLoaded: false
  };
}

function changeResearchReportDetailParams(state, { name, value, notDirty }) {
  let updatedData = {};
  if (notDirty) {
    updatedData = {
      ...state.researchReportDetail,
      [name]: value
    };
  } else {
    updatedData = {
      ...state.researchReportDetail,
      [name]: value,
      isDirty: true
    };
  }

  return {
    ...state,
    researchReportDetail: updatedData
  };
}

export function openResearchReportDetailModal(state, op) {
  const updatedData = {
    ...state.researchReportDetail,
    modalShow: true,
    op
  };

  return {
    ...state,
    researchReportDetail: updatedData
  };
}

export function closeResearchReportDetailModal(state, op) {
  const updatedData = {
    ...state.researchReportDetail,
    modalShow: false,
    op: null,
    data: null,
    tickerData: null,
    submitData: {},
    fileList: []
  };

  return {
    ...state,
    researchReportDetail: updatedData
  };
}

export function searchTickerSuccess(state, data) {
  const updatedData = {
    ...state.researchReportDetail,
    tickerData: data['data'],
    tickerError: null
  };

  return {
    ...state,
    researchReportDetail: updatedData
  };
}

export function searchTickerFailure(state, error) {
  const updatedData = {
    ...state.researchReportDetail,
    tickerData: {},
    tickerError: error
  };

  return {
    ...state,
    researchReportDetail: updatedData
  };
}

export function saveResearchReportSuccess(state, data) {
  return {
    ...state
  };
}

export function saveResearchReportFailure(state, err) {
  return {
    ...state
  };
}

export function changeResearchReportQueryParams(state, { name, value }) {
  const updatedData = {
    ...state.researchReportQueryParams,
    [name]: value
  };

  return {
    ...state,
    researchReportQueryParams: updatedData
  };
}

export function copyResearchReportSuccess(state, data) {
  return {
    ...state
  };
}

export function copyResearchReportFailure(state, err) {
  return {
    ...state
  };
}

export function getGicsListSuccess(state, data) {
  return {
    ...state,
    researchReportGicsList: data['data']
  };
}

export function getGicsListFailure(state, err) {
  return {
    ...state
  };
}

export function getPersonListSuccess(state, data) {
  return {
    ...state,
    researchReportPersonList: data.data
  };
}

export function getPersonListFailure(state, err) {
  return {
    ...state
  };
}

export function deleteResearchReportAttchSuccess(state, data) {
  const updateData = {
    ...state.researchReportDetail
  };
  const attachVOList = updateData.data.attachVOList;
  if (updateData.data.attachVOList) {
    const id = data.params.id;
    const newAttchList = [];
    attachVOList.forEach((item, index) => {
      if (item.id !== id) {
        newAttchList.push(item);
      }
    });
    updateData.data['attachVOList'] = newAttchList;
  }
  return {
    ...state,
    researchReportDetail: updateData
  };
}

export function deleteResearchReportAttchFailure(state, err) {
  return {
    ...state
  };
}

export function researchReportCellChange(state) {
  return {
    ...state,
    isResearchReportDetailLoaded: true
  };
}

export function loadShortInterestPercentSuccess(state, data) {
  let shortInterest = [];
  if (data.data) {
    shortInterest = data.data.map(item => {
      return {
        date: moment(item.date).format('YYYY/MM/DD'),
        ticker: item.bbgTicker,
        Share: item.shares,
        ShortPercent: item.sharePercent
      };
    });
  }
  return {
    ...state,
    shortInterestPercent: shortInterest
  };
}

export function loadShortInterestPercentFailure(state, err) {
  return {
    ...state
  };
}

export function getBlockListSuccess(state, data) {
  return {
    ...state,
    blockList: data.data
  };
}

export function getBlockListFailure(state, err) {
  return {
    ...state
  };
}

export function addCompanyKeyDataTrackSuccess(state, data) {
  return {
    ...state
  };
}

export function addCompanyKeyDataTrackFailure(state, err) {
  return {
    ...state
  };
}

export function updateCompanyKeyDataTrackSuccess(state, data) {
  return {
    ...state
  };
}

export function updateCompanyKeyDataTrackFailure(state, err) {
  return {
    ...state
  };
}

export function getCompanyKeyDataTrackListSuccess(state, data) {
  return {
    ...state,
    companyKeyDataTrackList: data.data,
    companyKeyDataTrackDetail: {}
  };
}

export function getCompanyKeyDataTrackListFailure(state, err) {
  return {
    ...state
  };
}

export function addCompanyMeetingRecordSuccess(state, data) {
  return {
    ...state
  };
}

export function addCompanyMeetingRecordFailure(state, err) {
  return {
    ...state
  };
}

export function updateCompanyMeetingRecordSuccess(state, data) {
  return {
    ...state
  };
}

export function updateCompanyMeetingRecordFailure(state, err) {
  return {
    ...state
  };
}

export function getCompanyMeetingRecordListSuccess(state, data) {
  return {
    ...state,
    companyMeetingRecordList: data.data,
    companyMeetingRecordDetail: {}
  };
}

export function getCompanyMeetingRecordListFailure(state, err) {
  return {
    ...state
  };
}

export function addCompanyReplayScoreSuccess(state, data) {
  return {
    ...state
  };
}

export function addCompanyReplayScoreFailure(state, err) {
  return {
    ...state
  };
}

export function updateCompanyReplayScoreSuccess(state, data) {
  return {
    ...state
  };
}

export function updateCompanyReplayScoreFailure(state, err) {
  return {
    ...state
  };
}

export function getCompanyReplayScoreListSuccess(state, data) {
  return {
    ...state,
    companyReplayScoreList: data.data,
    companyReplayScoreDetail: {}
  };
}

export function getCompanyReplayScoreListFailure(state, err) {
  return {
    ...state
  };
}

export function getCompanyKeyDataTrackDetailSuccess(state, data) {
  return {
    ...state,
    companyKeyDataTrackDetail: data.data
  };
}

export function getCompanyKeyDataTrackDetailFailure(state, err) {
  return {
    ...state
  };
}

export function openCompanyKeyDataTrackModal(state, payload) {
  const updateData = {
    ...state.companyKeyDataTrackModal,
    isOpen: true,
    mode: payload
  };
  return {
    ...state,
    companyKeyDataTrackModal: updateData
  };
}

export function closeCompanyKeyDataTrackModal(state, payload) {
  const updateData = {
    ...state.companyKeyDataTrackModal,
    isOpen: false,
    data: {},
    companyKeyDataTrackDetail: {}
  };
  return {
    ...state,
    companyKeyDataTrackModal: updateData
  };
}

export function getCompanyMeetingRecordDetailSuccess(state, data) {
  return {
    ...state,
    companyMeetingRecordDetail: data.data
  };
}

export function getCompanyMeetingRecordDetailFailure(state, err) {
  return {
    ...state
  };
}

export function openCompanyMeetingRecordModal(state, payload) {
  const updateData = {
    ...state.companyMeetingRecordModal,
    isOpen: true,
    mode: payload
  };
  return {
    ...state,
    companyMeetingRecordModal: updateData
  };
}

export function closeCompanyMeetingRecordModal(state, payload) {
  const updateData = {
    ...state.companyMeetingRecordModal,
    isOpen: false,
    data: {}
  };
  return {
    ...state,
    companyMeetingRecordModal: updateData
  };
}

export function getCompanyReplayScoreDetailSuccess(state, data) {
  return {
    ...state,
    companyReplayScoreDetail: data.data
  };
}

export function getCompanyReplayScoreDetailFailure(state, err) {
  return {
    ...state
  };
}

export function openCompanyReplayScoreModal(state, payload) {
  const updateData = {
    ...state.companyReplayScoreModal,
    isOpen: true,
    mode: payload
  };
  return {
    ...state,
    companyReplayScoreModal: updateData
  };
}

export function closeCompanyReplayScoreModal(state, payload) {
  const updateData = {
    ...state.companyReplayScoreModal,
    isOpen: false,
    data: {},
    companyReplayScoreDetail: {}
  };
  return {
    ...state,
    companyReplayScoreModal: updateData
  };
}

function fetchAllExcellentCompanySuccess(state, data) {
  return {
    ...state,
    allExcellentCompanyList: data.data
  };
}

function fetchAllExcellentCompanyFailure(state, err) {
  return {
    ...state
  };
}

//push votes
export function initPushVotesSubmitStatus(state) {
  return {
    ...state,
    pushVotesSubmitStatus: 'SUBMITTING'
  };
}

export function setPushVotesRecordRead(state, id) {
  const updateData = state.pushVotesDataList.map(r => {
    if (r.id === id) {
      return { ...r, isRead: 1 };
    }
    return { ...r };
  });
  return {
    ...state,
    pushVotesDataList: updateData
  };
}

export function openPushVotesModal(state, { key, value }) {
  const updateData = {
    ...state.pushVotesModal,
    [key]: value,
    isOpen: true
  };
  return {
    ...state,
    pushVotesModal: updateData
  };
}

export function closePushVotesModal(state) {
  const updateData = {
    ...state.pushVotesModal,
    isOpen: false,
    data: {
      scoreData: [[]]
    }
  };
  return {
    ...state,
    pushVotesModal: updateData,
    pushVotesSubmitErrMsg: null
  };
}

export function changePushVotesModalInput(state, data) {
  const updateData = {
    ...state.pushVotesModal,
    data
  };

  return {
    ...state,
    pushVotesModal: updateData,
    pushVotesSubmitErrMsg: null
  };
}

export function loadPushVotesDataSuccess(state, data) {
  const dataList = data.data;
  if (dataList) {
    dataList.forEach(item => {
      const attachVOList = item.attachVOList;
      if (attachVOList) {
        attachVOList.forEach((r, index) => {
          r.uid = index + 1000;
          r.status = 'done';
          r.url = r.uri;
        });
      }
    });
  }
  return {
    ...state,
    pushVotesDataList: data.data
  };
}

export function loadPushVotesDataFailure(state, err) {
  return {
    ...state
  };
}

export function savePushVotesSuccess(state, data) {
  let pushVotesSubmitErrMsg = null;
  if (data && data.code === '99') {
    pushVotesSubmitErrMsg = data.message;
  }
  return {
    ...state,
    pushVotesSubmitStatus: 'READY',
    pushVotesSubmitErrMsg
  };
}

export function savePushVotesFailure(state, err) {
  return {
    ...state,
    pushVotesSubmitStatus: 'READY'
  };
}

export function openPushVoteNoteModal(state, { key, value }) {
  const updateData = {
    ...state.pushVoteNoteModal,
    [key]: value,
    isOpen: true
  };
  return {
    ...state,
    pushVoteNoteModal: updateData
  };
}

export function changePushVoteNoteModalInput(state, data) {
  const updateData = {
    ...state.pushVoteNoteModal,
    data
  };

  return {
    ...state,
    pushVoteNoteModal: updateData
  };
}

export function closePushVoteNoteModal(state) {
  const updateData = {
    ...state.pushVoteNoteModal,
    isOpen: false
  };
  return {
    ...state,
    pushVoteNoteModal: updateData
  };
}

export function querySubscriptionListSuccess(state, data) {
  return {
    ...state,
    pushVOtesSubscriptionList: data.data
  };
}

export function querySubscriptionListFailure(state, err) {
  return {
    ...state
  };
}

export function loadPushVotesPmListSuccess(state, data) {
  return {
    ...state,
    pushVOtesPMList: data.data
  };
}

export function loadPushVotesPmListFailure(state, err) {
  return {
    ...state
  };
}

export function loadPushVotesAnalystListSuccess(state, data) {
  return {
    ...state,
    pushVOtesAnalystList: data.data
  };
}

export function loadPushVotesAnalystListFailure(state, err) {
  return {
    ...state
  };
}

export function changePushVotesSelectItem(state, params) {
  return {
    ...state,
    pushVotesSelectItem: params
  };
}

export function adjustPushVotesSuccess(state, data) {
  return {
    ...state,
    pushVotesSubmitStatus: 'READY'
  };
}

export function adjustPushVotesFailure(state, err) {
  return {
    ...state,
    pushVotesSubmitStatus: 'READY'
  };
}

//portfolio plan
export function openPortfolioPlanDialog(state) {
  const updateData = {
    ...state.portfolioPlanModal,
    isOpen: true
  };
  return {
    ...state,
    portfolioPlanModal: updateData
  };
}

export function closePortfolioPlanDialog(state) {
  const updateData = {
    ...state.portfolioPlanModal,
    isOpen: false
  };
  return {
    ...state,
    portfolioPlanModal: updateData
  };
}

export function loadPushVoteReturnDataSuccess(state, data) {
  return {
    ...state,
    pushVoteReturnDataList: data.data,
    isLoaded: true
  };
}

export function loadPushVoteReturnDataFailure(state, err) {
  return {
    ...state
  };
}

export default createReducer(initialState, {
  // Short Interest
  [LOAD_ALL_TICKERS_SUCCESS]: loadAllTickersSuccess,
  [LOAD_ALL_TICKERS_FAILURE]: loadAllTickersFailure,
  [LOAD_SHORT_INTEREST_SUCCESS]: loadShortInterestSuccess,
  [LOAD_SHORT_INTEREST_FAILURE]: loadShortInterestFailure,

  // Shortable Tickers
  [LOAD_SHORTABLE_TICKERS_SUCCESS]: loadShortableTickersSuccess,
  [LOAD_SHORTABLE_TICKERS_FAILURE]: loadShortableTickersFailure,

  // axe list Tickers
  [LOAD_AXELIST_TICKERS_SUCCESS]: loadAxeListTickersSuccess,
  [LOAD_AXELIST_TICKERS_FAILURE]: loadAxeListTickersFailure,

  // Research Company
  [LOAD_COMPANY_RESEARCH_SUCCESS]: loadCompanyResearchSuccess,
  [LOAD_COMPANY_RESEARCH_FAILURE]: loadCompanyResearchFailure,

  [COMPANY_RESEARCH_SUBMIT_REQUEST]: submitCompanyResearchRequest,
  [COMPANY_RESEARCH_SUBMIT_SUCCESS]: submitCompanyResearchSuccess,
  [COMPANY_RESEARCH_SUBMIT_FAILURE]: submitCompanyResearchFailure,

  [COMPANY_RESEARCH_VALIDATION_FAILURE]: sendValidationFailure,

  [DELETE_COMPANY_RESEARCH_SUCCESS]: deleteCompanyResearchSuccess,
  [DELETE_COMPANY_RESEARCH_FAILURE]: deleteCompanyResearchFailure,

  [OPEN_COMPANY_RESEARCH_NEW_DIALOG]: openCompanyResearchNewDialog,
  [OPEN_COMPANY_RESEARCH_UPDATE_DIALOG]: openCompanyResearchUpdateDialog,
  [COMPANY_RESEARCH_INPUT_CHANGE]: companyResearchChangeInput,

  // hide research control
  [RESEARCH_CONTROL_RESET]: resetResearchControlModal,
  // hide broker control
  [BROKER_CONTROL_RESET]: resetBrokerControlModal,
  // hide analyst control
  [ANALYST_CONTROL_RESET]: resetAnalystControlModal,

  // Broker Score
  [LOAD_BROKER_SCORES_SUCCESS]: loadBrokerScoresSuccess,
  [LOAD_BROKER_SCORES_FAILURE]: loadBrokerScoresFailure,

  [OPEN_BROKER_SCORES_NEW_DIALOG]: openBrokerScoresNewDialog,
  [OPEN_BROKER_SCORES_UPDATE_DIALOG]: openBrokerScoresUpdateDialog,

  [DELETE_BROKER_SCORES_SUCCESS]: deleteBrokerScoreSuccess,
  [DELETE_BROKER_SCORES_FAILURE]: deleteBrokerScoreFailure,

  [BROKER_SCORES_SUBMIT_REQUEST]: submitBrokerScoreRequest,
  [BROKER_SCORES_SUBMIT_SUCCESS]: submitBrokerScoreSuccess,
  [BROKER_SCORES_SUBMIT_FAILURE]: submitBrokerScoreFailure,

  [BROKER_SCORES_INPUT_CHANGE]: brokerScoreChangeInput,

  [NEW_BROKER_SCORE_ROW_DATA]: newBrokerScoreRowData,
  [OPEN_BROKER_SCORES_DETAIL_DIALOG]: openBrokerScoresDetailDialog,
  [RESET_BROKER_SCORES_DETAIL_DIALOG]: resetBrokerDetailControlModal,
  [OPEN_YEAR_BROKER_SCORES_DETAIL_DIALOG]: openYearBrokerScoresDetailDialog,
  [RESET_YEAR_BROKER_SCORES_DETAIL_DIALOG]: resetYearBrokerScoresDetailDialog,

  // Analyst Compliment
  [LOAD_ANALYST_COMPLIMENT_SUCCESS]: loadAnalystComplimentSuccess,
  [LOAD_ANALYST_COMPLIMENT_FAILURE]: loadAnalystComplimentFailure,

  [ANALYST_COMPLIMENT_INPUT_CHANGE]: analystComplimentChangeInput,

  [DELETE_ANALYST_COMPLIMENT_SUCCESS]: deleteAnalystComplimentSuccess,
  [DELETE_ANALYST_COMPLIMENT_FAILURE]: deleteAnalystComplimentFailure,

  [ANALYST_COMPLIMENT_SUBMIT_REQUEST]: submitAnalystComplimentRequest,
  [ANALYST_COMPLIMENT_SUBMIT_SUCCESS]: submitAnalystComplimentSuccess,
  [ANALYST_COMPLIMENT_SUBMIT_FAILURE]: submitAnalystComplimentFailure,

  [OPEN_ANALYST_COMPLIMENT_NEW_DIALOG]: openAnalystComplimentNewDialog,
  [OPEN_ANALYST_COMPLIMENT_UPDATE_DIALOG]: openAnalystComplimentUpdateDialog,
  // Boxed Position
  [LOAD_BOXED_POSITION_SUCCESS]: loadBoxedPositionSuccess,
  [LOAD_BOXED_POSITION_FAILURE]: loadBoxedPositionFailure,

  // search tickers
  [TICKERS_SEARCH_REQUEST]: requestSearchTickers,
  [TICKERS_SEARCH_SUCCESS]: searchTickersSuccess,

  // Broker Names
  [LOAD_BROKER_NAMES_SUCCESS]: loadBrokerNamesSuccess,
  [LOAD_BROKER_NAMES_FAILURE]: loadBrokerNamesFailure,

  // initiate loader status
  [INITIATE_LOADER_STATUS]: initiateLoaderStatus,

  //Broker
  [LOAD_BROKER_SCORE_BY_BROKER_SUCCESS]: loadBrokerScoreListByScoreSuccess,
  [LOAD_BROKER_SCORE_BY_BROKER_FAILURE]: loadBrokerScoreListByScoreFailure,
  [LOAD_BROKER_SCORE_LIST_SUCCESS]: loadBrokerScoreListSuccess,
  [LOAD_BROKER_SCORE_LIST_FAILURE]: loadBrokerScoreListFailure,
  [SAVE_BROKER_SCORE_SUCCESS]: saveBrokerScoreSuccess,
  [SAVE_BROKER_SCORE_FAILURE]: saveBrokerScoreFailure,
  [REMOVE_BROKER_SCORE_SUCCESS]: removeBrokerScoreSuccess,
  [REMOVE_BROKER_SCORE_FAILURE]: removeBrokerScoreFailure,
  [LOAD_CORRESPONDENCE_LIST_SUCCESS]: loadCorrespondenceListSuccess,
  [LOAD_CORRESPONDENCE_LIST_FAILURE]: loadCorrespondenceListFailure,
  [INITIATE_WEIGHT_MAP_LOADER_STATUS]: initiateWeightMapLoaded,
  [CHANGE_BROKER_QUERY_PARAMS]: changeBrokerQureyParams,
  [CHANGE_BROKER_SCORE_MODAL_INPUT]: changeBrokerScoreControlModalInput,

  //shortable
  [LOAD_SHORTABLE_LIST_SUCCESS]: loadShortableListSuccess,
  [LOAD_SHORTABLE_LIST_FAILURE]: loadShortableListFailure,
  [LOAD_SHORTABLE_EXCHANGE_LIST_SUCCESS]: loadShortExchangeListSuccess,
  [LOAD_SHORTABLE_EXCHANGE_LIST_FAILURE]: loadShortExchangeListFailure,
  [INIT_SHORTABLE_EXCHANGE_STATUS]: initiateLoaderShortExchangeStatus,

  //analyst
  [LOAD_ANALYST_LIST_SUCCESS]: loadAnalystListSuccess,
  [LOAD_ANALYST_LIST_FAILURE]: loadAnalystListFailure,
  [SAVE_ANALYST_SUCCESS]: saveAnalystSuccess,
  [SAVE_ANALYST_FAILURE]: saveAnalystFailure,
  [DELETE_ANALYST_SUCCESS]: deleteAnalystSuccess,
  [DELETE_ANALYST_FAILURE]: deleteAnalystFailure,
  [CHANGE_ANALYST_QUERY_PARAMS]: changeAnalystQueryParams,
  [CHANGE_ANALYST_MODAL_INPUT]: changeAnalystModalInput,
  [CLOSE_ANALYST_MODAL_WIN]: closeAnalystModal,
  [GET_BROKER_LIST_SUCCESS]: getBrokerListSuccess,
  [GET_BROKER_LIST_ERROR]: getBrokerListFailure,
  [CHANGE_ANALYST_UPDATE_MODAL_INPUT]: changeAnalystUpdateModalInput,
  [GET_ANALYST_NAME_LIST_SUCCESS]: getAnalystNameEditListSuccess,
  [GET_ANALYST_NAME_LIST_ERROR]: getAnalystNameEditListFailure,
  [GET_ANALYST_SECTOR_LIST_SUCCESS]: getAnalystSectorListSuccess,
  [GET_ANALYST_SECTOR_LIST_ERROR]: getAnalystSectorListFailure,
  [GET_ANALYST_DETAIL_SUCCESS]: getAnalystDetailListSuccess,
  [GET_ANALYST_DETAIL_ERROR]: getAnalystDetailListFailure,
  [INIT_SHORTABLE_EXCHANGE_STATUS]: initiateLoaderShortExchangeStatus,

  //monthly review
  [LOAD_MONTHLY_BENCHMARK_SUCCESS]: loadMonthlyReviewBenchmarkSuccess,
  [LOAD_MONTHLY_BENCHMARK_FAILURE]: loadMonthlyReviewBenchmarkFailure,
  [LOAD_MONTHLY_MY_PORTFOLIO_SUCCESS]: loadMonthlyReviewMyPortfilioSuccess,
  [LOAD_MONTHLY_MY_PORTFOLIO_FAILURE]: loadMonthlyReviewMyPortfilioFailure,
  [LOAD_MONTHLY_REVIEW_DETAIL_FAILURE]: loadMonthlyReviewDetailFailure,
  [LOAD_MONTHLY_REVIEW_DETAIL_SUCCESS]: loadMonthlyReviewDetailSuccess,
  [MONTHLY_REVIEW_DETAIL_INPUT_CHANGE]: monthlyReviewDetailChangeInput,
  [INITIATE_MONTHLY_REVIEW_LOADER_STATUS]: initiateMonthlyReviewDetailLoaderStatus,
  [LOAD_MONTHLY_REVIEW_LIST_SUCCESS]: loadMonthlyReviewListSuccess,
  [LOAD_MONTHLY_REVIEW_LIST_FAILURE]: loadMonthlyReviewListFailure,
  [SAVE_MONTHLY_REVIEW_SUCCESS]: saveMonthlyReviewSuccess,
  [SAVE_MONTHLY_REVIEW_FAILURE]: saveMonthlyReviewFailure,
  [SUBMIT_MONTHLY_REVIEW_SUCCESS]: submitMonthlyReviewSuccess,
  [SUBMIT_MONTHLY_REVIEW_FAILURE]: submitMonthlyReviewFailure,
  [REMOVE_MONTHLY_REVIEW_SUCCESS]: removeMonthlyReviewSuccess,
  [REMOVE_MONTHLY_REVIEW_FAILURE]: removeMonthlyReviewFailure,
  [INITIATE_MONTHLY_REVIEW_DETAIL_DATA]: monthlyReviewDetailDataInit,
  [CHAGE_MONTHLY_REVIEW_QUERY_PARAMS]: changeMonthlyReviewQueryParams,
  [INITIATE_MONTHLY_REVIEW_LOADER_BENCHMARK_STATUS]: initMonthlyReviewBenchmarkStatus,
  [INITIATE_MONTHLY_REVIEW_LOADER_MYPERFORMACNE_STATUS]: initMonthlyReviewMyPerformanceStatus,
  [GET_GROSS_AND_BETA_BY_FUNDANDBOOK_SUCCESS]: getGrossAndBetaByFundAndBookSuccess,
  [GET_GROSS_AND_BETA_BY_FUNDANDBOOK_FAILURE]: getGrossAndBetaByFundAndBookFailure,
  [GET_MANAGER_USER_LIST_SUCCESS]: getManagerUserListSuccess,
  [GET_MANAGER_USER_LIST_FAILURE]: getManagerUserListFailure,

  [SAVE_BROKER_SCORE_RANK_SUCCESS]: saveBrokerScoreRankListSuccess,
  [SAVE_BROKER_SCORE_RANK_FAILURE]: saveBrokerScoreRankListFailure,
  [QUERY_BROKER_SCORE_RANK_SUCCESS]: queryBrokerScoreRankListByScoreSuccess,
  [QUERY_BROKER_SCORE_RANK_FAILURE]: queryBrokerScoreRankListByScoreFailure,
  [LOAD_YEAR_BROKER_SCORE_SUCCESS]: loadYearBrokerScoresSuccess,
  [LOAD_YEAR_BROKER_SCORE_FAILURE]: loadYearBrokerScoresFailure,

  //research report
  [LOAD_RESEARCH_REPORT_LIST_SUCCESS]: loadResearchReportListSuccess,
  [LOAD_RESEARCH_REPORT_LIST_FAILURE]: loadResearchReportListFailure,
  [LOAD_RESEARCH_REPORT_DETAIL_SUCCESS]: loadResearchReportDetailSuccess,
  [LOAD_RESEARCH_REPORT_DETAIL_FAILURE]: loadResearchReportDetailFailure,
  [INIT_LOAD_RESEARCH_REPORT_STATUS]: initiateResearchReportStatus,
  [INIT_LOAD_RESEARCH_REPORT_DETAIL_STATUS]: initiateResearchReportDetailStatus,
  [CHANGE_RESEARCH_REPORT_DETAIL_PARAMS]: changeResearchReportDetailParams,
  [OPEN_RESEARCH_REPORT_MODAL]: openResearchReportDetailModal,
  [CLOSE_RESEARCH_REPORT_MODAL]: closeResearchReportDetailModal,
  [SEARCH_TICKER_SUCCESS]: searchTickerSuccess,
  [SEARCH_TICKER_FAILURE]: searchTickerFailure,
  [SAVE_RESERCH_REPORT_SUCCESS]: saveResearchReportSuccess,
  [SAVE_RESERCH_REPORT_FAILURE]: saveResearchReportFailure,
  [CHAGE_RESEARCH_REPORT_QUERY_PARAMS]: changeResearchReportQueryParams,
  [COPY_RESERCH_REPORT_SUCCESS]: copyResearchReportSuccess,
  [COPY_RESERCH_REPORT_FAILURE]: copyResearchReportFailure,
  [GET_RESERCH_PERSON_LIST_SUCCESS]: getPersonListSuccess,
  [GET_RESERCH_PERSON_LIST_FAILURE]: getPersonListFailure,
  [GET_RESERCH_GICS_LIST_SUCCESS]: getGicsListSuccess,
  [GET_RESERCH_GICS_LIST_FAILURE]: getGicsListFailure,
  [DELETE_RESERCH_ATTACH_SUCCESS]: deleteResearchReportAttchSuccess,
  [DELETE_RESERCH_ATTACH_FAILURE]: deleteResearchReportAttchFailure,
  [RESERCH_REPORT_CELL_CHANGE]: researchReportCellChange,
  [LOAD_SHORT_INTEREST_PERCENT_SUCCESS]: loadShortInterestPercentSuccess,
  [LOAD_SHORT_INTEREST_PERCENT_FAILURE]: loadShortInterestPercentFailure,
  [GET_BLOCK_LIST_SUCCESS]: getBlockListSuccess,
  [GET_BLOCK_LIST_FAILURE]: getBlockListFailure,
  [ADD_COMPANY_KEY_DATA_SUCCESS]: addCompanyKeyDataTrackSuccess,
  [ADD_COMPANY_KEY_DATA_FAILURE]: addCompanyKeyDataTrackFailure,
  [UPDATE_COMPANY_KEY_DATA_SUCCESS]: updateCompanyKeyDataTrackSuccess,
  [UPDATE_COMPANY_KEY_DATA_FAILURE]: updateCompanyKeyDataTrackFailure,
  [GET_COMPANY_KEY_DATA_SUCCESS]: getCompanyKeyDataTrackListSuccess,
  [GET_COMPANY_KEY_DATA_FAILURE]: getCompanyKeyDataTrackListFailure,
  [ADD_COMPANY_MEETING_RECORD_SUCCESS]: addCompanyMeetingRecordSuccess,
  [ADD_COMPANY_MEETING_RECORD_FAILURE]: addCompanyMeetingRecordFailure,
  [UPDATE_COMPANY_MEETING_RECORD_SUCCESS]: updateCompanyMeetingRecordSuccess,
  [UPDATE_COMPANY_MEETING_RECORD_FAILURE]: updateCompanyMeetingRecordFailure,
  [GET_COMPANY_MEETING_RECORD_SUCCESS]: getCompanyMeetingRecordListSuccess,
  [GET_COMPANY_MEETING_RECORD_FAILURE]: getCompanyMeetingRecordListFailure,
  [ADD_COMPANY_REPLAY_SCORE_SUCCESS]: addCompanyReplayScoreSuccess,
  [ADD_COMPANY_REPLAY_SCORE_FAILURE]: addCompanyReplayScoreFailure,
  [UPDATE_COMPANY_REPLAY_SCORE_SUCCESS]: updateCompanyReplayScoreSuccess,
  [UPDATE_COMPANY_REPLAY_SCORE_FAILURE]: updateCompanyReplayScoreFailure,
  [GET_COMPANY_REPLAY_SCORE_SUCCESS]: getCompanyReplayScoreListSuccess,
  [GET_COMPANY_REPLAY_SCORE_FAILURE]: getCompanyReplayScoreListFailure,

  [GET_COMPANY_KEY_DETAIL_DATA_SUCCESS]: getCompanyKeyDataTrackDetailSuccess,
  [GET_COMPANY_KEY_DETAIL_DATA_FAILURE]: getCompanyKeyDataTrackDetailFailure,
  [OPEN_COMPANY_KEY_DETAIL_DATA_MODAL]: openCompanyKeyDataTrackModal,
  [CLOSE_COMPANY_KEY_DETAIL_DATA_MODAL]: closeCompanyKeyDataTrackModal,
  [GET_COMPANY_MEETING_RECORD_DETAIL_SUCCESS]: getCompanyMeetingRecordDetailSuccess,
  [GET_COMPANY_MEETING_RECORD_DETAIL_FAILURE]: getCompanyMeetingRecordDetailFailure,
  [OPEN_COMPANY_MEETING_RECORD_DETAIL_MODAL]: openCompanyMeetingRecordModal,
  [CLOSE_COMPANY_MEETING_RECORD_DETAIL_MODAL]: closeCompanyMeetingRecordModal,
  [GET_COMPANY_REPLAY_SCORE_DETAIL_SUCCESS]: getCompanyReplayScoreDetailSuccess,
  [GET_COMPANY_REPLAY_SCORE_DETAIL_FAILURE]: getCompanyReplayScoreDetailFailure,
  [OPEN_COMPANY_REPLAY_SCORE_MODAL]: openCompanyReplayScoreModal,
  [CLOSE_COMPANY_REPLAY_SCORE_MODAL]: closeCompanyReplayScoreModal,
  [FETCH_ALL_EXCELLENTCOMPANY_SUCCESS]: fetchAllExcellentCompanySuccess,
  [FETCH_ALL_EXCELLENTCOMPANY_FAILURE]: fetchAllExcellentCompanyFailure,

  //push votes
  [INIT_PUSH_VOTES_STATUS]: initPushVotesSubmitStatus,
  [OPEN_ADD_UPDATE_PUSH_VOTES_MODAL]: openPushVotesModal,
  [CLOSE_ADD_UPDATE_PUSH_VOTES_MODAL]: closePushVotesModal,
  [CHANGE_PUSH_VOTES_MODAL_INPUT]: changePushVotesModalInput,
  [LOAD_PUSH_VOTES_DATA_SUCCESS]: loadPushVotesDataSuccess,
  [LOAD_PUSH_VOTES_DATA_FAILURE]: loadPushVotesDataFailure,
  [SAVE_PUSH_VOTES_DATA_SUCCESS]: savePushVotesSuccess,
  [SAVE_PUSH_VOTES_DATA_FAILURE]: savePushVotesFailure,
  [OPEN_ADD_PUSH_VOTE_NOTE_MODAL]: openPushVoteNoteModal,
  [CHANGE_PUSH_VOTE_NOTE_MODAL_INPUT]: changePushVoteNoteModalInput,
  [CLOSE_ADD_PUSH_VOTE_NOTE_MODAL]: closePushVoteNoteModal,
  [GET_PUSH_VOTES_SUBSCRIPT_CONFIG_SUCCESS]: querySubscriptionListSuccess,
  [GET_PUSH_VOTES_SUBSCRIPT_CONFIG_FAILURE]: querySubscriptionListFailure,
  [GET_PUSH_VOTES_PM_USER_LIST_SUCCESS]: loadPushVotesPmListSuccess,
  [GET_PUSH_VOTES_PM_USER_LIST_FAILURE]: loadPushVotesPmListFailure,
  [GET_PUSH_VOTES_ANALYST_USER_LIST_SUCCESS]: loadPushVotesAnalystListSuccess,
  [GET_PUSH_VOTES_ANALYST_USER_LIST_FAILURE]: loadPushVotesAnalystListFailure,
  [CHANGE_PUSH_VOTES_SELECT_ITEM]: changePushVotesSelectItem,
  [ADJUST_PUSH_VOTES_DATA_SUCCESS]: adjustPushVotesSuccess,
  [ADJUST_PUSH_VOTES_DATA_FAILURE]: adjustPushVotesFailure,
  [SET_PUSH_VOTES_RECORD_READED]: setPushVotesRecordRead,
  //portfolio plan
  [OPEN_ADD_UPDATE_PORTFOLIO_PLAN_MODAL]: openPortfolioPlanDialog,
  [CLOSE_ADD_UPDATE_PORTFOLIO_PLAN_MODAL]: closePortfolioPlanDialog,

  [LOAD_PUSH_VOTE_RETURN_DATA_SUCCESS]: loadPushVoteReturnDataSuccess,
  [LOAD_PUSH_VOTE_RETURN_DATA_FAILURE]: loadPushVoteReturnDataFailure
});
