import React, { Component } from 'react';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Modal, Button, Spin, Badge, Switch } from 'antd';
import { DIALOG_MANUAL_LOCATES } from 'features/oms/omsConstants';
import client from '../../api/client';
import Locate from 'features/oms/entities/Locate';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import {
  manualLocateGridColumns,
  manualLocateTickerGridColumns,
  manualAllocateLocateGridColumns
} from './GridColumnMap';
import SplitPane from 'react-split-pane';
import copy from 'copy-to-clipboard';
import hyperid from 'hyperid';

const uniqidInstance = hyperid();
class ManualLocatesDialog extends Component {
  holdingsByTicker = {};
  shortListByTicker = {};
  options = {
    custodianMap: {}
  };

  state = {
    isInitialized: false,
    locates: [],
    allocateList: [],
    securitySumData: [],
    selectedSecuritySumData: null,
    brokerList: [],
    errors: [],

    submitStatus: 'READY',
    calculating: false,
    locatesGridWrapperStyle: {
      width: '100%',
      height: '350px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    locatesAllocateGridWrapperStyle: {
      width: '100%',
      height: '450px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    useOmsSrv: this.props.ui.useOmsSrv
  };

  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
  }

  componentDidMount() {
    this._init();
  }

  _init = () => {
    const { selectedOrders } = this.props;
    if (_.isEmpty(selectedOrders)) return;

    Promise.all([client.getLocateOptions()])
      .then(([{ funds, custodians }]) => {
        const fundList = [];
        funds.forEach(item => {
          if (item.baseCcyCode === 'CNY') fundList.push(item.fundCode);
        });
        const inputs = selectedOrders
          .filter(
            r =>
              (r.side === 'SHRT' || r.side === 'SELL') &&
              r.quantity > 0 &&
              fundList.indexOf(r.fundCode) >= 0
          )
          .map(o => ({
            orderRefId: o.refId,
            ticker: o.ticker,
            fundCode: o.fundCode,
            side: o.side,
            useOmsSrv: this.state.useOmsSrv
          }));
        this._initLocates(selectedOrders, inputs, custodians);
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _initLocates = (selectedOrders, inputs, custodians) => {
    Promise.all([client.getLocateOptions(), client.calcLocateContexts(inputs)])
      .then(([{ funds }, { ctxs }]) => {
        const fundList = [];
        funds.forEach(item => {
          if (item.baseCcyCode === 'CNY') fundList.push(item.fundCode);
        });
        const locates = _.zip(selectedOrders, ctxs).flatMap(
          ([order, ctx], i) => {
            if (!ctx) return [];
            const { orderInfo, locateInfos } = ctx;
            return this._createLocates(
              { ...order, ...orderInfo },
              locateInfos,
              i
            );
          }
        );

        const securitySumData = [];
        const routeLocateMap = _.groupBy(locates, 'ticker');
        _.map(routeLocateMap, (value, key) => {
          const fundList = [];
          const totalQty = _.reduce(
            value,
            function(sum, r) {
              if (fundList.indexOf(r.fundCode) < 0) {
                fundList.push(r.fundCode);
              }
              return sum + parseFloat(r.qty);
            },
            0
          );
          const broker = key.endsWith('CH Equity') ? 'ZZZB' : null;
          securitySumData.push({
            uniqid: uniqidInstance(),
            ticker: key,
            qty: totalQty,
            oriQty: totalQty,
            fund: _.join(fundList, ','),
            diff: 0,
            filled: totalQty,
            allocateQty: totalQty,
            broker
          });
        });

        const brokerMap = _.keyBy(custodians, c => c.custodianCode);

        const manualLocatesGridSetting = this._createManualLocatesGridSettings();
        const manualSecurityGridSettings = this._createManualSecurityGridSettings(
          Object.keys(brokerMap)
        );
        const manualAllocateGridSettings = this._createManualAllocateGridSettings();

        _.delay(() => {
          this.setState({
            isInitialized: true,
            locates,
            manualLocatesGridSetting,
            manualSecurityGridSettings,
            manualAllocateGridSettings,
            securitySumData
          });
        }, 150);
      })
      .catch(ex => {
        console.log(ex);
        this.setState({
          isInitialized: true,
          locates: [],
          submitStatus: 'ERROR'
        });
      });
  };

  _createLocates = (order, locateInfos, i) => {
    return locateInfos.map((l, j) => {
      return { ...Locate.emptyForm(order, l, 'MANUAL'), key: `${i}-${j}` };
    });
  };

  _createManualLocatesGridSettings = () => {
    const columns = manualLocateGridColumns.map(c => {
      return { ...c };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _createManualSecurityGridSettings = brokerMap => {
    const optionsMapping = {
      broker: brokerMap
    };
    const columns = manualLocateTickerGridColumns.map(c => {
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu: {
        items: {
          remove_row: {},
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneSecurityLocates(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _cloneSecurityLocates = () => {
    const { selectedSecuritySumData, securitySumData } = this.state;
    const rowIndex = securitySumData.findIndex(
      r => r.uniqid === selectedSecuritySumData.uniqid
    );

    if (!selectedSecuritySumData.allocateQty) return;

    const qty =
      selectedSecuritySumData.qty - selectedSecuritySumData.allocateQty;
    const cloneData = {
      ...selectedSecuritySumData,
      qty,
      allocateQty: null,
      diff: qty,
      broker: null,
      uniqid: uniqidInstance()
    };
    const updatedData = [
      ...securitySumData.slice(0, rowIndex + 1),
      ...[cloneData],
      ...securitySumData.slice(rowIndex + 1)
    ];
    updatedData[rowIndex] = {
      ...selectedSecuritySumData,
      qty: selectedSecuritySumData.allocateQty,
      diff: 0
    };

    this.setState({
      securitySumData: updatedData
    });
  };

  _onSecurityLocateSelectionChanged = row => {
    const { securitySumData } = this.state;
    const selectedSecuritySumData = securitySumData[row];

    if (selectedSecuritySumData === securitySumData) return;
    this.setState({
      selectedSecuritySumData
    });
  };

  _createManualAllocateGridSettings = () => {
    const columns = manualAllocateLocateGridColumns.map(c => {
      return { ...c };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _createManualLocatesGrid = () => {
    const {
      manualLocatesGridSetting,
      locatesGridWrapperStyle,
      locates
    } = this.state;

    return (
      !_.isEmpty(locates) && (
        <div style={locatesGridWrapperStyle}>
          <HotTable
            data={locates}
            manualColumnResize={true}
            {...manualLocatesGridSetting}
          ></HotTable>
        </div>
      )
    );
  };

  _createManualSecurityLocatesGrid = () => {
    const {
      manualSecurityGridSettings,
      locatesGridWrapperStyle,
      securitySumData
    } = this.state;

    return (
      !_.isEmpty(securitySumData) && (
        <div style={locatesGridWrapperStyle}>
          <HotTable
            data={securitySumData}
            manualColumnResize={true}
            afterSelectionEnd={this._onSecurityLocateSelectionChanged}
            //beforeChange={this._beforeCellChange}
            afterChange={this._afterCellChange}
            {...manualSecurityGridSettings}
          ></HotTable>
        </div>
      )
    );
  };

  _createManualAllocateGrid = () => {
    const {
      manualAllocateGridSettings,
      locatesAllocateGridWrapperStyle,
      allocateList
    } = this.state;

    return (
      <div style={locatesAllocateGridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={allocateList}
          manualColumnResize={true}
          {...manualAllocateGridSettings}
          licenseKey="non-commercial-and-evaluation"
        ></HotTable>
      </div>
    );
  };

  _beforeCellChange = (changes, source) => {
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(realChanges);

    return false;
  };

  _afterCellChange = (changes, op) => {
    if (!changes && op !== 'edit') return;
    const { securitySumData } = this.state;
    const updatedData = securitySumData.map(r => {
      const allocateQty = r.allocateQty ? r.allocateQty : 0;
      return {
        ...r,
        diff: r.qty - allocateQty
      };
    });
    this.setState({
      securitySumData: updatedData
    });
    return false;
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_MANUAL_LOCATES);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, errors } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    const count = errors.length;
    return (
      <Badge key="submitBadge" count={count} style={{ marginRight: '10px' }}>
        {submitBtn}
      </Badge>
    );
  };

  copyRequest = () => {
    const { locates } = this.state;
    let text = '';
    const tickerMap = _.groupBy(locates, 'ticker');
    _.map(tickerMap, (value, key) => {
      const totalQty = _.reduce(
        value,
        function(sum, r) {
          return sum + parseFloat(r.qty);
        },
        0
      );
      text += `[${key}] short: ${totalQty}`;
      text += '\r';
    });
    copy(text);
  };

  calc = () => {
    const { securitySumData, locates } = this.state;
    const allocateList = [];
    securitySumData.forEach(item => {
      const allocateQty = item['allocateQty'];
      if (!allocateQty) return;

      const totalQty = item['oriQty'];
      const fund = item['fund'];
      const allocateItems = locates.filter(
        r => r.ticker === item.ticker && fund && fund.indexOf(r.fundCode) >= 0
      );
      if (!allocateItems) return;

      const lotSize = allocateItems[0]['order']['lotSize'];
      const adjustSize = allocateQty / lotSize;
      let unAllocate = allocateQty;

      for (let i = 0; i < allocateItems.length; i++) {
        const item = allocateItems[i];
        const hasAllocateNum = this._getHasAllocateNums(allocateList, item);
        let itemQty = parseInt(adjustSize * (item.qty / totalQty)) * lotSize;
        const qty = item.qty - hasAllocateNum;
        itemQty = itemQty > qty ? qty : itemQty;
        item['allocateQty'] = itemQty;
        unAllocate -= itemQty;
      }
      if (unAllocate > 0) {
        allocateItems.forEach(item => {
          const qty = item.qty;
          const allocateQty = item['allocateQty'];
          const hasAllocateNum = this._getHasAllocateNums(allocateList, item);
          const unAllocateQty = qty - allocateQty - hasAllocateNum;
          if (unAllocate >= unAllocateQty && unAllocateQty > 0) {
            item['allocateQty'] = qty - hasAllocateNum;
            unAllocate = unAllocate - unAllocateQty;
          } else if (unAllocate < unAllocateQty && unAllocate >= lotSize) {
            item['allocateQty'] =
              allocateQty + (unAllocate / lotSize) * lotSize;
            unAllocate = 0;
          }
        });
      }
      allocateItems.forEach(r => {
        const itemData = {
          ...r,
          borrowRate: item.borrowRate,
          broker: item['broker'] ? item['broker'] : r['broker']
        };
        if (itemData.allocateQty > 0) allocateList.push(itemData);
      });
    });

    const updateLocates = [...locates];

    if (!_.isEmpty(allocateList)) {
      const alocateListMap = {};
      allocateList.forEach(e => {
        const key = `${e.orderRefId}-${e.fundCode}-${e.bookCode}`;
        const qty = alocateListMap[key] ? alocateListMap[key] : 0;
        alocateListMap[key] = qty + e.allocateQty;
      });
      updateLocates.forEach(r => {
        const key = `${r.orderRefId}-${r.fundCode}-${r.bookCode}`;
        if (alocateListMap[key]) {
          r.allocateQty = alocateListMap[key];
          r.diff = r.qty - alocateListMap[key];
        } else {
          r.allocateQty = null;
          r.diff = null;
        }
      });
    }
    const errors = this._validateLocates(allocateList);
    this.setState(
      {
        allocateList,
        errors,
        locates: updateLocates
      },
      this.copyAllocate
    );
  };

  _getHasAllocateNums = (allocateList, item) => {
    const key = `${item.orderRefId}-${item.fundCode}-${item.bookCode}`;
    let num = 0;

    allocateList.forEach(r => {
      const k = `${r.orderRefId}-${r.fundCode}-${r.bookCode}`;
      if (k === key) num += r.allocateQty;
    });

    return num;
  };

  copyAllocate = () => {
    const { allocateList } = this.state;
    if (allocateList) {
      let text = '';
      const brokerMap = _.groupBy(allocateList, 'broker');
      _.map(brokerMap, (value, key) => {
        text += key + '\r';
        const tickerMap = _.groupBy(value, 'ticker');
        _.map(tickerMap, (value1, key1) => {
          text += `[${key1}]`;
          const fundMap = _.groupBy(value1, 'fundCode');
          _.map(fundMap, (value2, key2) => {
            const totalQty = _.reduce(
              value2,
              function(sum, r) {
                return sum + parseFloat(r.allocateQty);
              },
              0
            );
            text += ` ${key2} -> ${totalQty}`;
          });
          text += '\r';
        });
        text += '\r';
      });
      copy(text);
    }
  };

  _validateLocates = data => {
    const { allocateList } = this.state;
    const validateData = data ? data : allocateList;
    const errors = [];

    validateData.forEach(r => {
      if (!r.broker && errors.length === 0) errors.push("Broker can't be null");
      if (_.isNil(r.borrowRate)) errors.push("BorrowRate can't be null");
    });

    return errors;
  };

  _onSubmit = () => {
    const { allocateList } = this.state;
    const { submitDialogSuccess } = this.props;

    if (_.isEmpty(allocateList)) return;
    const errors = this._validateLocates();

    if (!_.isEmpty(errors)) {
      this.setState({
        errors
      });
      return;
    }

    this.setState({ submitStatus: 'SUBMITTING' });
    const locates = allocateList.map(r => {
      return {
        ...r,
        qty: r.allocateQty,
        filled: r.allocateQty,
        status: 'FILLED'
      };
    });

    client
      .addLocates(locates)
      .then(() => {
        this.copyAllocate();
        this.setState({ submitStatus: 'READY' });
        submitDialogSuccess(DIALOG_MANUAL_LOCATES, {});
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _createErrorPanel = () => {
    const { errors } = this.state;
    const errorMsgs = Object.values(errors);

    return (
      <div style={{ marginTop: '10px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  onSwitchChange = () => {
    this.setState(
      {
        useOmsSrv: !this.state.useOmsSrv,
        isInitialized: false
      },
      () => {
        _.delay(this._init, 500);
      }
    );
  };

  _createSwitchSrv = () => {
    const { useOmsSrv } = this.state;
    return (
      <Switch
        checked={useOmsSrv}
        checkedChildren="NEW"
        unCheckedChildren="OLD"
        onChange={this.onSwitchChange}
      ></Switch>
    );
  };

  render() {
    const { isInitialized } = this.state;

    return (
      <Modal
        key={'manualLocatesDialog'}
        width={1600}
        maskClosable={false}
        title={`Manual Locates`}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          <Button
            type="primary"
            style={{ marginRight: '10px' }}
            onClick={this.copyAllocate}
          >
            Copy Locates
          </Button>,
          this._createSubmitBtn(this._onSubmit),
          <Button
            key="close"
            type="primary"
            onClick={this.closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <div style={{ marginTop: '10px', textAlign: 'right' }}>
          {/*{this._createSwitchSrv()}*/}
          <Button
            style={{ marginLeft: '10px' }}
            type="primary"
            onClick={this.copyRequest}
          >
            Copy Request
          </Button>
          <Button
            type="primary"
            onClick={this.calc}
            style={{ marginLeft: '10px' }}
          >
            Calc
          </Button>
        </div>
        <Spin tip="Initializing..." spinning={!isInitialized}>
          <SplitPane
            split="vertical"
            defaultSize="45%"
            style={{ position: 'relative' }}
          >
            {this._createManualLocatesGrid()}
            {this._createManualSecurityLocatesGrid()}
          </SplitPane>
          <div style={{ width: '100%', height: '420px', marginTop: '5px' }}>
            {this._createManualAllocateGrid()}
          </div>
          {this._createErrorPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default ManualLocatesDialog;
