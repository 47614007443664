import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Logout extends Component {
  constructor(props) {
    super(props);

    const { englishName } = props.user || {};
    this.props.logout(englishName);
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default Logout;
