import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button, Grid, Header, Segment } from 'semantic-ui-react';
import Field from 'common/ui/Field';
import './ChgPwdForm.css';

class ChgPwdForm extends Component {
  state = {
    fields: {
      oldPwd: '',
      newPwd: '',
      confirmedNewPwd: ''
    },
    fieldErrors: {},
    pwdReg: new RegExp(
      '(?=.*\\d)(?=.*[A-Za-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$'
    )
  };

  onFormSubmit = evt => {
    const pwdObj = this.state.fields;
    evt.preventDefault();

    if (this.validate()) return;

    if (pwdObj.newPwd !== pwdObj.confirmedNewPwd) {
      this.setState({
        fieldErrors: {
          ...this.state.fieldErrors,
          confirmedNewPwd: 'New passwords are not matched with each other.'
        }
      });
      return;
    }

    const { englishName: name } = this.props.user || {};

    this.props.onSubmit({
      ...pwdObj,
      name
    });
  };

  validatePassword = val => {
    const { pwdReg } = this.state;
    return pwdReg.test(val);
  };

  onInputChange = ({ name, value, error }) => {
    const user = this.state.fields;
    const fieldErrors = this.state.fieldErrors;

    this.setState({
      fields: { ...user, [name]: value },
      fieldErrors: { ...fieldErrors, [name]: error }
    });
  };

  validate = () => {
    const pwdObj = this.state.fields;
    const fieldErrors = this.state.fieldErrors;
    const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

    if (!pwdObj.oldPwd) return true;
    if (!pwdObj.newPwd) return true;
    if (!pwdObj.confirmedNewPwd) return true;
    if (errMessages.length) return true;

    return false;
  };

  render() {
    let status = this.props.chgPwdStatus;
    if (status === 'SUCCESS') {
      this.props.resetForm();
      return <Redirect to="/" />;
    } else {
      return (
        <Grid
          textAlign="center"
          style={{ height: '100%', width: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="grey" textAlign="center">
              Change password for your account
            </Header>

            <br />

            <Form onSubmit={this.onFormSubmit} size="large">
              <Segment.Group stacked>
                <Segment>
                  <Field
                    type="password"
                    placeholder="Old Password"
                    name="oldPwd"
                    value={this.state.fields.oldPwd}
                    error={this.state.fieldErrors.oldPwd}
                    onChange={this.onInputChange}
                    validate={val => (val ? false : 'Old password is required')}
                  />
                </Segment>
                <Segment>
                  <Field
                    type="password"
                    placeholder="New Password"
                    name="newPwd"
                    value={this.state.fields.newPwd}
                    error={this.state.fieldErrors.newPwd}
                    onChange={this.onInputChange}
                    validate={val => {
                      if (val) {
                        if (!this.validatePassword(val)) {
                          return 'Must contain English letters & symbols, numbers, and length must >= 8';
                        }
                        return false;
                      }
                      return 'New password is required';
                    }}
                  />
                </Segment>
                <Segment>
                  <Field
                    type="password"
                    placeholder="New Password Again"
                    name="confirmedNewPwd"
                    value={this.state.fields.confirmedNewPwd}
                    error={this.state.fieldErrors.confirmedNewPwd}
                    onChange={this.onInputChange}
                    validate={val => {
                      if (val) {
                        if (!this.validatePassword(val)) {
                          return 'Must contain English letters & symbols, numbers, and length must >= 8';
                        }
                        return false;
                      }
                      return 'Confirmed password is required';
                    }}
                  />
                </Segment>
                <Segment>
                  {
                    {
                      LOGINING: (
                        <Button
                          type="submit"
                          disabled
                          size="tiny"
                          color="green"
                        >
                          Change...
                        </Button>
                      ),
                      SUCCESS: (
                        <Button
                          type="submit"
                          disabled
                          size="tiny"
                          color="green"
                        >
                          Change Password Success
                        </Button>
                      ),
                      ERROR: (
                        <Button
                          type="submit"
                          size="tiny"
                          color="green"
                          disabled={this.validate()}
                        >
                          Change Password Fail - Retry?
                        </Button>
                      ),
                      READY: (
                        <Button
                          type="submit"
                          size="tiny"
                          color="green"
                          disabled={this.validate()}
                        >
                          Submit
                        </Button>
                      )
                    }[status]
                  }
                </Segment>
              </Segment.Group>
            </Form>
          </Grid.Column>
        </Grid>
      );
    }
  }
}

export default ChgPwdForm;
