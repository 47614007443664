import React, { Component } from 'react';
import { Divider, Row, Col } from 'antd';

export default class MonthlyReviewItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.data['id']
    };
  }

  onEditAnchorHrefClick = e => {
    this.props.context.gridComponent.props.loadMonthlyReviewDetail(
      this.state.id,
      true
    );
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.stopPropagation();
    return;
  };

  onDeleteAnchorHrefClick = e => {
    this.props.context.gridComponent.props.removeMonthlyReview(
      { id: this.state.id },
      this.props.context.gridComponent.props.monthlyReviewQueryParams
    );
    e.stopPropagation();
    return;
  };

  canEdit = () => {
    const { data } = this.props;
    //get current user id
    const { user } = this.props.context.gridComponent.props;
    const userAccountId = user['userAccountId'];
    if (data['userId'] && data['userId'] === userAccountId) {
      return 'visible';
    }
    return 'hidden';
  };

  showSubmitTime = () => {
    const { data } = this.props;
    //get current user id
    const { user } = this.props.context.gridComponent.props;
    const userAccountId = user['userAccountId'];
    if (data['userId'] && data['userId'] === userAccountId) {
      return 'hidden';
    }
    return 'visible';
  };

  getStatusDesc = value => {
    if (value === 0) {
      return 'Draft';
    }
    if (value === 1) {
      return 'Submited';
    }
  };

  getShortTextShow = value => {
    if (value && value.length > 70) {
      return value.substring(0, 70) + '.....';
    }
    return value;
  };

  _getHeaderTitle = (monthForEng, year) => {
    if ('December' === monthForEng) {
      return `${year} Year Review`;
    } else {
      return `${monthForEng} ${year} Monthly Review`;
    }
  };

  render() {
    const { data } = this.props;

    const item = data;
    let canEditFlag = this.canEdit();
    let showSubmitTimeFlag = canEditFlag !== 'visible';
    return (
      <div style={{ width: '100%' }} value={item.id}>
        <style>
          {` 
            .ant-anchor-wrapper{
              background-color:#2d3436
            }

            .monthlyReviewListLabel{
              color:#FF9E28
            }
          `}
        </style>
        <Row>
          <Col span={24} style={{ textAlign: 'center', fontSize: 14 }}>
            {this._getHeaderTitle(data.monthForEng, data.year)}
          </Col>
        </Row>
        <Row style={{ marginTop: '1px', marginLeft: '5px' }}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <span className="monthlyReviewListLabel">Mainly gain: </span>
            {this.getShortTextShow(item.gainPortfolio)}
          </Col>
        </Row>
        <Row style={{ marginTop: '2px', marginLeft: '5px' }}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <span className="monthlyReviewListLabel">Mainly loss: </span>
            {this.getShortTextShow(item.lossPortfolio)}
          </Col>
        </Row>
        <Row style={{ marginTop: '5px', marginLeft: '5px' }}>
          {/* <Col span={12} style={{textAlign:"left"}}><span className="monthlyReviewListLabel">Last Update Time: </span>{item.updateTimeStr}</Col> */}
          <Col span={24} style={{ textAlign: 'left' }}>
            <span className="monthlyReviewListLabel">PM: </span>{' '}
            <span>{item.createBy}</span>
            {showSubmitTimeFlag ? (
              <div style={{ float: 'right' }}>
                <span className="monthlyReviewListLabel">Submit Time: </span>{' '}
                <span>{item.createTimeStr}</span>
              </div>
            ) : (
              <div style={{ float: 'right', visibility: canEditFlag }}>
                <a
                  href={() => {
                    return;
                  }}
                  onClick={this.onEditAnchorHrefClick}
                >
                  Edit
                </a>
                <Divider type="vertical" />
                <a
                  href={() => {
                    return;
                  }}
                  onClick={this.onDeleteAnchorHrefClick}
                >
                  Delete
                </a>
              </div>
            )}
          </Col>
          {/* <Col span={6} style={{textAlign:"left"}}><span className="monthlyReviewListLabel">Status: </span> {this.getStatusDesc(item.status)}</Col> */}
        </Row>
      </div>
    );
  }
}
