import pick from 'lodash/pick';
import _ from 'lodash';

const REG_PERCENT = /(\d+)%/i;

const FIELDS = [
  'tradeId',
  'refId',

  'fundCode',
  'bookCode',
  'ticker',
  'side',
  'orderType',
  'lotSize',
  'quantity',
  'qtyUsd',
  'qtyPct',
  'qtyInput',
  'limitPriceLocal',
  'pmReason',
  'pmRemark',
  'requestTime',
  'strategy',
  'pmStatus',
  'traderStatus',
  'requestStatus',
  'orderStatus',
  'tradeSource',
  'timeInForce',
  'tradeDate',

  'prevFilledQuantity', // Used for validation.
  'algoCode',
  'requestType'
];

const FX_CLASS = ['FX_SPOT', 'FX_NDF', 'FX_FORWARD'];

const Trade = {
  isFXTrade(txnClass) {
    return FX_CLASS.includes(txnClass);
  },
  toForm(data) {
    if (!data) {
      return {};
    }

    return {
      ...pick(data, Trade.formFields()),
      qtyPct: _.round(data.qtyPct * 100, 3)
    };
  },
  toModel(fields, security, isRiskControlled) {
    return {
      refId: fields.refId,
      tradeId: fields.tradeId,

      ticker: fields.ticker,
      securityCode: security.name,
      side: fields.side,
      lotSize: fields.lotSize || 1,
      quantity: parseInt(fields.quantity, 10),
      qtyUsd: parseInt(fields.qtyUsd, 10),
      qtyPct: _.round(fields.qtyPct / 100, 5),
      qtyInput: fields.qtyInput,
      limitPriceLocal:
        fields.orderType === 'LMT' ? parseFloat(fields.limitPriceLocal) : null,
      orderType: fields.orderType,
      entryPriceBook: null,
      fundCode: fields.fundCode,
      bookCode: fields.bookCode,
      pmStatus: fields.tradeId ? 'MODIFY' : 'SUBMIT',
      pmRemark: fields.pmRemark,
      pmReason: fields.pmReason,
      requestStatus: 'ACTIVE',
      tradeSource: fields.tradeSource || 'IMS',
      strategy: fields.strategy,
      isRiskControlled,
      prevFilledQuantity: 0,
      timeInForce: fields.timeInForce,
      requestType: fields.requestType,
      tradeDate: fields.tradeDate,
      basket: fields.basket,
      secCcy: fields.secCcy,
      maturityDate: fields.maturityDate,
      settleDate: fields.settleDate,
      txnClass: fields.txnClass,
      algo: {
        code: fields.algoCode
      },
      preRoutes: fields.preRoutes
    };
  },
  emptyForm(requestType = 'TRD') {
    return {
      tradeId: 0,
      refId: null,

      fundCode: '',
      bookCode: '',
      ticker: '',
      side: 'BUY',
      orderType: 'MKT',
      quantity: 0,
      qtyUsd: 0,
      qtyPct: 0,
      qtyInput: '',
      limitPriceLocal: null,
      pmReason: '',
      pmRemark: '',
      lotSize: 1,
      strategy: 'NONE',
      tradeSource: 'IMS',
      timeInForce: 'DAY',

      algoCode: 'INLINE',

      requestType
    };
  },
  formFields() {
    return FIELDS;
  },
  parseSignedDayQty({ quantity, side, prevFilledQuantity = 0 }) {
    return ['BUY', 'COVR'].includes(side)
      ? Number(quantity - prevFilledQuantity)
      : Number(quantity - prevFilledQuantity) * -1;
  },
  parseTradeSign(side) {
    return ['BUY', 'COVR'].includes(side) ? 1 : -1;
  },
  parsePosKey(t) {
    return `${t.fundCode}|${t.bookCode}|${t.strategy}|${
      t.ticker
    }|${this.parseDirection(t)}`;
  },
  parseDirection(t) {
    return ['BUY', 'SELL'].includes(t.side) ? 'LONG' : 'SHORT';
  },
  isOpen(t) {
    return ['BUY', 'SHRT'].includes(t.side);
  },
  normalizePmRemark(t) {
    if (['TWAP', 'VWAP'].includes(t.algoCode))
      return (t.pmRemark || '').replace(REG_PERCENT, '');
    return t.pmRemark;
  }
};

export default Trade;
