import {
  threeDPFormatter,
  moneyFormatter,
  nullFormatter
} from 'common/utils/valueFormatters';
import { sideStyle } from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';

const allocationGridColumns = [
  {
    field: 'refId',
    headerName: 'Ref Id',
    cellClass: 'keyword'
  },
  {
    field: 'orderRefId',
    headerName: 'Order Ref Id',
    cellClass: 'keyword'
  },
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true
  },
  {
    field: 'txnId',
    headerName: 'Txn Id',
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'lockDate',
    headerName: 'Lock Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short'
  },
  {
    field: 'qty',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'avgPrice',
    headerName: 'Avg Price',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'fxRate',
    headerName: 'Fx Rate',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    cellClass: 'number',
    valueFormatter: threeDPFormatter
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'broker',
    headerName: 'Broker',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'handlingInstruction',
    headerName: 'Handl Instr',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'cfdType',
    headerName: 'CfdType',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'settlementCcy',
    headerName: 'Setl Ccy',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'borrowRate',
    headerName: 'Borrow Rate',
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'status',
    headerName: 'Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        ACTIVE: 'steelblue',
        FINALIZED: 'lightseagreen',
        ARCHIVE: 'lightslategrey'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'bookStatus',
    headerName: 'Book Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        SENT: 'steelblue',
        PASS: 'lightseagreen',
        FAIL: 'crimson'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'notes',
    headerName: 'Reason',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'venue',
    headerName: 'Venue',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'algoCode',
    headerName: 'Algo',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'notes1',
    headerName: 'Notes1',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'notes2',
    headerName: 'Notes2',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'custodianAccount',
    headerName: 'Account',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  }
];

export const allocationGridColumnMap = EntityMap.fromArray(
  allocationGridColumns,
  'field'
);
