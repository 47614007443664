const _createOptions = values => {
  return values.map(v => ({
    key: v,
    text: v,
    value: v
  }));
};

const Options = {
  correspondence: _createOptions([
    'Group Meeting',
    'Personal Meeting',
    'Phone Conference',
    'Sales Call'
  ]),
  typeList: _createOptions(['Alpha Long', 'Alpha Short', 'Pair Trade']),
  recommendedSizingList: [
    { key: 0.5, value: 0.5, label: '0.5%' },
    { key: 1, value: 1, label: '1%' },
    { key: 1.5, value: 1.5, label: '1.5%' },
    { key: 2, value: 2, label: '2%' },
    { key: 2.5, value: 2.5, label: '2.5%' }
  ],
  expectedDuration: _createOptions(['<1M', '<3M', '>3M']),
  sourceOfAlpha: _createOptions([
    'Stand up high and see afar',
    'Detailed analysis',
    'Tracking closely',
    'Combination of the above',
    'Other'
  ]),
  longModelList: _createOptions([
    'Monopoly/ Network Effect',
    'Price to Performance/ Low cost',
    'Brand',
    'Technology Advantage',
    'Short Term/Catalyst Driven',
    'Other'
  ]),
  shortModelList: _createOptions([
    'Short term oriented',
    'Funding short',
    'Hyper growth nonprofitable decelerating',
    'Long only giving up',
    'Crowded trade reversal after catalyst',
    'Market share loss',
    'Fake company busting',
    'No3 in Internet',
    'Becoming obsolete',
    'Cyclical peak',
    'Others'
  ]),
  closingReasonList: _createOptions([
    'Meet expectations/take profit',
    'Stop loss/risk management',
    'Fundamental changed',
    'Not as good as expected',
    'Find better idea/stock',
    'Tapping the ball',
    'Market sentiments changed',
    'others'
  ]),
  confidenceLevelList: _createOptions([1, 2, 3, 4, 5])
};

export default Options;
