import React, { Component } from 'react';
import CompanyStrategyGrid from './CompanyStrategyGrid';

import './CompanyStrategyTab.css';

class CompanyStrategyTab extends Component {
  render() {
    return (
      <div className="companyStrategyTabWrapper">
        <CompanyStrategyGrid {...this.props} />
      </div>
    );
  }
}

export default CompanyStrategyTab;
