import React, { Component } from 'react';

class Field extends Component {
  onChange = evt => {
    const name = this.props.name;
    const value = evt.target.value;
    const error = this.props.validate ? this.props.validate(value) : false;

    this.props.onChange({ name, value, error });
  };

  render() {
    return (
      <div className="field">
        <div className="ui fluid">
          <input
            type={this.props.type}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.onChange}
          />
          <span style={{ color: 'red' }}>{this.props.error}</span>
        </div>
      </div>
    );
  }
}

export default Field;
