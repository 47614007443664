import React, { Component } from 'react';
import _ from 'lodash';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import { Modal, Button } from 'antd';
import copy from 'copy-to-clipboard';

class RouteLocateAllocateDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickerLocateList: [],
      routeLocateMap: {},
      routesLocateGridSettings: null,
      routesLocateGridWrapperStyle: {
        width: '100%',
        height: '390px',
        marginTop: '5px',
        border: 'solid 2px grey',
        padding: '2px'
      },
      columns: [
        {
          data: 'ticker',
          headerName: 'Ticker',
          readOnly: true,
          allowEmpty: false
        },
        {
          data: 'qty',
          headerName: 'Qty',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0'
          },
          readOnly: true,
          allowEmpty: false
        },
        {
          data: 'allocation',
          headerName: 'Allocate Qty',
          type: 'numeric',
          numericFormat: {
            pattern: '0,0'
          },
          readOnly: false,
          allowEmpty: true
        }
      ]
    };
  }

  componentDidMount() {
    this._init();
  }

  _init = () => {
    const { routes } = this.props;

    const routesLocateGridSettings = this._createRoutesLocateGridSettings();
    const tickerLocateList = [];
    const filterRoutes = routes.filter(r => r.side === 'SHRT' && r.qty !== 0);
    if (_.isEmpty(filterRoutes)) return;

    const routeLocateMap = _.groupBy(filterRoutes, 'ticker');
    _.map(routeLocateMap, (value, key) => {
      const totalQty = _.reduce(
        value,
        function(sum, r) {
          return sum + parseFloat(r.qty);
        },
        0
      );
      tickerLocateList.push({
        ticker: key,
        qty: totalQty
      });
    });

    this.setState({
      routeLocateMap,
      tickerLocateList,
      routesLocateGridSettings
    });
  };

  _allocate = () => {
    const { tickerLocateList, routeLocateMap } = this.state;
    tickerLocateList.forEach(item => {
      const allocateQty = item['allocation'];
      const totalQty = item['qty'];
      const allocateItems = routeLocateMap[item.ticker];
      if (allocateQty) {
        const lotSize = allocateItems[0]['order']['lotSize'];
        const adjustSize = allocateQty / lotSize;
        let unAllocate = allocateQty;
        for (let i = 0; i < allocateItems.length; i++) {
          const item = allocateItems[i];
          let itemQty = parseInt(adjustSize * (item.qty / totalQty)) * lotSize;
          itemQty = itemQty > item.qty ? item.qty : itemQty;
          item['allocateQty'] = itemQty;
          unAllocate -= itemQty;
        }
        if (unAllocate > 0) {
          allocateItems.forEach(item => {
            const qty = item.qty;
            const allocateQty = item['allocateQty'];
            const unAllocateQty = qty - allocateQty;
            if (unAllocate >= unAllocateQty) {
              item['allocateQty'] = qty;
              unAllocate = unAllocate - unAllocateQty;
            } else if (unAllocate < unAllocateQty && unAllocate >= lotSize) {
              item['allocateQty'] =
                allocateQty + (unAllocate / lotSize) * lotSize;
              unAllocate = 0;
            }
          });
        }
      } else {
        allocateItems.forEach(item => (item['allocateQty'] = null));
      }
    });
  };

  _apply = () => {
    const { routeLocateMap } = this.state;
    let data = [];
    _.map(routeLocateMap, value => {
      data = data.concat(value);
    });
    this.props.onSubmit(data);
  };

  _createRoutesLocateGridSettings = () => {
    const { columns } = this.state;
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _createHotTable = () => {
    const {
      routesLocateGridSettings,
      routesLocateGridWrapperStyle,
      tickerLocateList
    } = this.state;

    return (
      !_.isEmpty(tickerLocateList) && (
        <div style={routesLocateGridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={tickerLocateList}
            beforeChange={this._beforeCellChange}
            afterLoadData={this._afterLoadRoutes}
            beforeRemoveRow={this._beforeRemoveRow}
            afterSelectionEnd={this._onRouteSelectionChanged}
            beforeColumnSort={this._beforeColumnSort}
            beforeAutofill={this._beforeAutoFill}
            manualColumnResize={true}
            {...routesLocateGridSettings}
          ></HotTable>
        </div>
      )
    );
  };

  copyLocate = () => {
    const { tickerLocateList } = this.state;
    let text = '';
    tickerLocateList.forEach(item => {
      text += `[${item.ticker}] ->short: ${item.qty}`;
      text += '\r';
    });
    copy(text);
  };

  copyAllocation = () => {
    this._allocate();

    const { routeLocateMap, tickerLocateList } = this.state;
    let text = '';
    _.map(routeLocateMap, (value, key) => {
      const data = _.findLast(tickerLocateList, r => r.ticker === key);
      if (!data.allocation) return;

      text += `[${key}] ->allocate: `;
      const fundGroup = _.groupBy(value, 'fundCode');
      _.map(fundGroup, (value1, key1) => {
        let totalQty = 0,
          totalAllocte = 0;
        value1.forEach(item => {
          totalQty = totalQty + parseFloat(item.qty);
          totalAllocte = totalAllocte + parseFloat(item.allocateQty);
        });
        text += `${key1} ${totalAllocte} `;
      });
      text += '\r';
    });
    copy(text);
  };

  render() {
    return (
      <Modal
        width={1000}
        maskClosable={false}
        title={'Calc locates'}
        visible={true}
        onCancel={this.props.onCancel}
        footer={[
          <Button
            key="copyLocate"
            type="primary"
            onClick={this.copyLocate}
            style={{ marginLeft: '10px' }}
          >
            Copy Locate
          </Button>,
          <Button
            key="copyAllocate"
            type="primary"
            onClick={this.copyAllocation}
            style={{ marginLeft: '10px' }}
          >
            Copy Allocate
          </Button>,
          <Button
            key="apply"
            type="primary"
            onClick={this._apply}
            style={{ marginLeft: '10px' }}
          >
            Apply
          </Button>,
          <Button
            key="close"
            type="primary"
            onClick={this.props.onCancel}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        {this._createHotTable()}
      </Modal>
    );
  }
}

export default RouteLocateAllocateDialog;
