import React, { Component } from 'react';
import _ from 'lodash';
import { Search } from 'semantic-ui-react';

class SearchField extends Component {
  state = {
    lastSelectedValue: ''
  };

  componentWillMount() {
    this.innerSearch = _.debounce(this.props.onSearch, 500);
    this.setState({
      lastSelectedValue: ''
    });
  }

  handleSearchChange = (e, { value }) => {
    this.onChange(value);

    const { minCharacters = 3 } = this.props;
    if (minCharacters <= value.length) this.innerSearch(value);
  };

  handleResultSelect = (e, { result }) => {
    const f = this.props.resultField;

    this.onChange(result[f]);
    this.props.onResultSelect(result[f]);
  };

  handleSelectionChange = (e, { result }) => {
    const f = this.props.resultField;

    this.setState({
      lastSelectedValue: result[f]
    });
  };

  onChange = value => {
    this.setState({
      lastSelectedValue: ''
    });

    const name = this.props.name;
    this.props.onChange({ name, value });
  };

  onBlur = (evt, data) => {
    const value = this.state.lastSelectedValue || data.value;
    this.props.onBlur(value);
  };

  onFocus = (evt, data) => {
    this.setState({
      lastSelectedValue: ''
    });
  };

  render() {
    const {
      isLoading,
      results = [],
      minCharacters = 3,
      resultRenderer,
      placeholder,
      value
    } = this.props;

    return (
      <Search
        loading={isLoading}
        results={results}
        value={value}
        minCharacters={minCharacters}
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        onSelectionChange={this.handleSelectionChange}
        resultRenderer={resultRenderer}
        placeholder={placeholder}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
      />
    );
  }
}

export default SearchField;
