import React, { Component } from 'react';
import { Tabs } from 'antd';
import StudentPaperAnalystTab from './StudentPaperAnalystTab';
import CollegeOppsiteTradeAnalyst from './CollegeOppsiteTradeAnalyst';
const TabPane = Tabs.TabPane;
// const TabPane = Tabs.TabPane;
class PaperAnalystTabs extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab="Portfolio Return" key="1">
            <StudentPaperAnalystTab {...this.props} />
          </TabPane>
          <TabPane tab="Opposite Trade Report" key="2">
            <CollegeOppsiteTradeAnalyst {...this.props} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default PaperAnalystTabs;
