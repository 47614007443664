import React, { Component } from 'react';
import _ from 'lodash';
import { riskReportAssetColumns } from '../GridColumnMap';
import { AgGridReact } from 'ag-grid-react';
import { Spin } from 'antd';

class AssetGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: riskReportAssetColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      defaultBottomColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      context: {
        gridComponent: this
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
    //this.setPinnedTopRowData();
  };

  setPinnedTopRowData = () => {
    if (this.gridApi) {
      const { riskSelectedFactorItem, riskReportAssetList } = this.props;
      if (riskSelectedFactorItem && !_.isEmpty(riskReportAssetList)) {
        let exposure = 0,
          risk = 0;
        riskReportAssetList.forEach(element => {
          if (element['weight'] && element['exposure'])
            exposure += element['weight'] * element['exposure'];
          if (element['risk']) {
            risk += element['risk'] * element['weight'];
          }
        });
        exposure = _.ceil(exposure, 8);
        //const activeVolatility = riskSelectedFactorItem['activeVolatility'];
        risk = Math.abs(_.ceil(risk, 8));
        this.gridApi.setPinnedTopRowData([
          { ticker: 'Total', weight: '', risk: risk, mktValue: '', exposure }
        ]);
      } else {
        this.gridApi.setPinnedTopRowData([
          { ticker: 'Total', weight: '', risk: '', mktValue: '', exposure: '' }
        ]);
      }
    }
  };

  render() {
    const {
      riskReportAssetLoadingStatus,
      riskReportAssetList,
      className = 'grid-wrapper'
    } = this.props;
    const screenHeight = window.screen.height;
    let height = '90';
    if (screenHeight <= 768) {
      height = 85;
    }
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {riskReportAssetLoadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: `${height}%`
            }}
          >
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={riskReportAssetList}
              suppressAggFuncInHeader={true}
              deltaRowDataMode={false}
              enableRangeSelection={true}
              //rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              sideBar={this.state.sideBar}
              animateRows={true}
              // events
              onGridReady={this.onGridReady}
            />
          </div>
        )}
      </div>
    );
  }
}

export default AssetGrid;
