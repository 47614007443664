import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { Button, Spin, Tabs } from 'antd';
import client from '../../api/client';
import _ from 'lodash';
import { commissionRateGridColumns } from '../GridColumn';

// const { Option } = Select;
// const _createUIOptions = codes => {
//   return codes.map(c => (
//     <Option key={c || 'default'} value={c}>
//       {c}
//     </Option>
//   ));
// };
class CommissionRateReportGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),
      autoGroupColumnDef: {
        cellClass: 'keyword',
        width: 80
        // field: 'pairName',
        // headerName: 'Name',
      },
      loadingStatus: false,
      eqtyColumns: [
        'counterpartyCode',
        'instClassCode',
        'date',
        'ulyInstClassCode',
        'fundBookCode',
        'mktTypeCode',
        'mktCountryCode',
        'settCondCode',
        'buySellIndCode',
        'tariffTypeCode',
        'tariff'
      ],
      foColumns: [
        'tradeChargeTypeCode',
        'counterpartyCode',
        'date',
        'instClassCode',
        'mktTypeCode',
        'ulyInstClassCode',
        'fundBookCode',
        'mktCountryCode',
        'settCondCode',
        'buySellIndCode',
        'tariffTypeCode',
        'tariff'
      ],
      data: [],
      keyCols: [
        'tradeChargeTypeCode',
        'counterpartyCode',
        'giveInCptyCode',
        'clearingHouseCode',
        'tradeTypeCode',
        'securityId',
        'ulySecurityId',
        'instClassCode',
        'ulyInstClassCode',
        'marketCode',
        'mktCountryCode',
        'mktTypeCode',
        'settCcyCode',
        'settCondCode',
        'buySellIndCode',
        'buySellReasonCode',
        'commTypeCode',
        'reasonCode',
        'trader',
        'fundBookCode',
        'grossNetIndCode',
        'chrgCcyCode',
        'directionIndCode',
        'statusIndCode',
        'priority',
        'lowValue',
        'upValue'
      ]
    };
  }

  componentDidMount() {
    this._loadData();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  onEqtyGridReady = params => {
    this.eqtyGridApi = params.api;
    this.eqtyGridApi.sizeColumnsToFit();
  };

  onFoGridReady = params => {
    this.foGridApi = params.api;
    this.foGridApi.sizeColumnsToFit();
  };

  _loadData = () => {
    this.setState({
      loadingStatus: true
    });
    client
      .queryTradeChargeRuleList()
      .then(resp => {
        this.setState({
          data: resp.data,
          loadingStatus: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadingStatus: false
        });
      });
  };

  _createQueryHeader = () => {
    const { loadingStatus } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          size="small"
          style={{ marginRight: '5px' }}
          disabled={loadingStatus}
          onClick={() => {
            this._loadData();
          }}
        >
          {loadingStatus ? 'Loading' : 'Reload'}
        </Button>
      </div>
    );
  };

  _createGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { agGridSettings, loadingStatus, data } = this.state;

    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - 170) / screenHeight) * 100}%`;
    return (
      <>
        {loadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              height: gridDivHeight
            }}
          >
            <AgGridReact
              // properties
              rowData={data}
              {...agGridSettings}
              columnDefs={commissionRateGridColumns}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              animateRows={false}
              deltaRowDataMode={false}
              // events
              onGridReady={this.onGridReady}
            />
          </div>
        )}
      </>
    );
  };

  _genKey = (o, includeKeys) => {
    const entries = Object.entries(o);
    // 过滤掉需要排除的键
    const filteredEntries = entries.filter(([key]) =>
      includeKeys.includes(key)
    );
    // 将剩余的属性值拼成一个字符串，这里假设属性值是字符串或可以转换为字符串的类型
    const resultString = filteredEntries
      .map(([key, value]) => `${value}`)
      .join('_');
    return resultString;
  };

  _createEqtyCommissionGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data, loadingStatus, agGridSettings, eqtyColumns } = this.state;
    let showData = data.filter(
      r =>
        ['CMSNN', 'CMSN', 'CMSN2'].includes(r.tradeChargeTypeCode) &&
        !['PTF', 'ACF', 'ACAF', 'CACF'].includes(r.fundBookCode) &&
        _.isEmpty(r.reasonCode) &&
        (_.isEmpty(r.ulyInstClassCode) ||
          ['CONVBND'].includes(r.ulyInstClassCode)) &&
        ['BOND', 'CFD', 'CFD,FFS', 'CONVBND', 'ORD,DR', 'ORD,DR,CIS'].includes(
          r.instClassCode
        )
    );
    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - 170) / screenHeight) * 100}%`;
    showData = _.orderBy(showData, ['date'], ['desc']);

    const dataSet = {};
    showData = showData.forEach(r => {
      const key = this._genKey(r, this.state.keyCols);
      if (!dataSet[key]) dataSet[key] = r;
    });

    showData = _.orderBy(
      Object.values(dataSet),
      ['counterpartyCode', 'instClassCode', 'date'],
      ['asc', 'asc', 'desc']
    );

    const columns = commissionRateGridColumns.filter(r =>
      eqtyColumns.includes(r.field)
    );

    return (
      <>
        {loadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              height: gridDivHeight
            }}
          >
            <AgGridReact
              // properties
              rowData={showData}
              {...agGridSettings}
              columnDefs={columns}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              animateRows={false}
              deltaRowDataMode={false}
              // events
              onGridReady={this.onEqtyGridReady}
            />
          </div>
        )}
      </>
    );
  };

  _createFOCommissionGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data, loadingStatus, agGridSettings, foColumns } = this.state;
    let showData = data.filter(
      r =>
        (!['PTF', 'ACF', 'ACAF', 'CACF'].includes(r.fundBookCode) &&
          // eslint-disable-next-line no-mixed-operators
          [
            'BOND_FT',
            'CMDTY_FT',
            'EQINDX_FT',
            'EQINDX_FT,EQINDX_OP',
            'EQINDX_OP',
            'EQTY_FT',
            'EQTY_FT,EQINDX_FT',
            'EQTY_OP',
            'FX_FT',
            'IR_FT',
            'OP_IRFT'
          ].includes(r.instClassCode)) ||
        (['BOND', 'CFD', 'CFD,FFS', 'CONVBND', 'ORD,DR', 'ORD,DR,CIS'].includes(
          r.instClassCode
        ) &&
          !_.isEmpty(
            r.ulyInstClassCode && !['CONVBND'].includes(r.ulyInstClassCode)
          ))
    );
    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - 170) / screenHeight) * 100}%`;

    const dataSet = {};
    showData = showData.forEach(r => {
      const key = this._genKey(r, this.state.keyCols);
      if (!dataSet[key]) dataSet[key] = r;
    });

    showData = _.orderBy(
      Object.values(dataSet),
      ['counterpartyCode', 'instClassCode', 'date'],
      ['asc', 'asc', 'desc']
    );

    const columns = commissionRateGridColumns.filter(r =>
      foColumns.includes(r.field)
    );

    return (
      <>
        {loadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              height: gridDivHeight
            }}
          >
            <AgGridReact
              // properties
              rowData={showData}
              {...agGridSettings}
              columnDefs={columns}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              animateRows={false}
              deltaRowDataMode={false}
              // events
              onGridReady={this.onFoGridReady}
            />
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {this._createQueryHeader()}
        <Tabs tabPosition="top" key="RightTabs">
          <Tabs.TabPane tab="Equity Cmsn" key="Equity">
            {this._createEqtyCommissionGrid()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="F&O" key="F&O">
            {this._createFOCommissionGrid()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Detail" key="Detail">
            {this._createGrid()}
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default CommissionRateReportGrid;
