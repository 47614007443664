import {
  sideRenderer,
  orderTypeRenderer,
  timeInForceRenderer,
  booleanRenderer,
  numberPercentRenderer,
  numberFormatRenderer
} from 'common/ui/renderers/hotTable/renderers';
import { sideStyle } from 'common/utils/styleChooser';
import Options from '../../entities/Options';
import { moneyFormatter, nullFormatter } from 'common/utils/valueFormatters';

const commonTradeGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    readOnly: true,
    renderer: sideRenderer
  },
  {
    data: 'qtyPct',
    headerName: 'Qty %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false
  },
  {
    data: 'posnStartPct',
    headerName: 'Before %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'posnEndPct',
    headerName: 'After %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false
  },
  {
    data: 'quantity',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  {
    data: 'qtyUsd',
    headerName: 'Qty USD',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0 $'
    },
    allowEmpty: false
  },
  {
    data: 'orderType',
    headerName: 'Type',
    renderer: orderTypeRenderer
  },
  {
    data: 'limitPriceLocal',
    headerName: 'Lmt Price',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    }
  },
  {
    data: 'timeInForce',
    headerName: 'TIF',
    allowEmpty: false
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    allowEmpty: false
  },
  {
    data: 'algoCode',
    headerName: 'Algo',
    allowEmpty: false
  },
  {
    data: 'inlinePct',
    headerName: 'Inline %',
    type: 'numeric',
    allowEmpty: false,
    readOnly: false
  },
  {
    data: 'pmRemark',
    headerName: 'PM Remark'
  },
  {
    data: 'pmReason',
    headerName: 'PM Reason'
  }
];

const subTradeGridColumns = commonTradeGridColumns
  .filter(
    c =>
      ![
        // 'bookCode',
        'ticker',
        'limitPriceLocal',
        'strategy',
        'algoCode',
        'timeInForce',
        'pmRemark',
        'pmReason',
        'orderType'
      ].includes(c.data)
  )
  .map(f => {
    if (f.data === 'bookCode')
      return { ...f, readOnly: false, type: 'dropdown', source: [] };
    else if (f.data === 'side')
      return {
        ...f,
        readOnly: false,
        type: 'dropdown',
        source: Options.side.map(o => o.key)
      };
    return f;
  });

const tradeGridColumns = commonTradeGridColumns.filter(
  c => !['inlinePct'].includes(c.data)
);

const fxTradeGridColumns = [
  {
    data: 'fundCode',
    headerName: 'Fund',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'bookCode',
    headerName: 'Book',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'txnClass',
    headerName: 'TxnClass',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'symbol',
    headerName: 'Symbol',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'settleDate',
    headerName: 'SettleDate',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'side',
    headerName: 'Side',
    allowEmpty: false,
    readOnly: true,
    renderer: sideRenderer
  },
  {
    data: 'qtyPct',
    headerName: 'Qty %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false
  },
  {
    data: 'posnStartPct',
    headerName: 'Before %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'posnEndPct',
    headerName: 'After %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false
  },
  {
    data: 'quantity',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  {
    data: 'qtyUsd',
    headerName: 'Qty USD',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0 $'
    },
    allowEmpty: false
  },
  {
    data: 'limitPriceLocal',
    headerName: 'Lmt Price',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    }
  },
  {
    data: 'algoCode',
    headerName: 'Algo',
    allowEmpty: false
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    allowEmpty: false
  },
  {
    data: 'timeInForce',
    headerName: 'TIF',
    allowEmpty: false
  },
  {
    data: 'pmRemark',
    headerName: 'PM Remark'
  },
  {
    data: 'pmReason',
    headerName: 'PM Reason'
  },
  {
    data: 'basket',
    headerName: 'Basket',
    readOnly: false
  },
  {
    data: 'secCcy',
    headerName: 'Sec Ccy',
    readOnly: true
  },
  {
    data: 'maturityDate',
    headerName: 'Fixing Date',
    readOnly: true
  }
];

const posGridColumns = [
  {
    data: 'isLocked',
    headerName: 'Locked',
    type: 'checkbox'
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'nonTradableMvPct',
    headerName: 'Non-Tradable %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'mvPct',
    headerName: 'MV %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'tgtMvPct',
    headerName: 'TGT MV %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    }
  },
  {
    data: 'betaMvPct',
    headerName: 'β MV %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'tgtBetaMvPct',
    headerName: 'TGT β MV %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: true
  },
  {
    data: 'tradePct',
    headerName: 'Qty Pct %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    }
  },
  {
    data: 'tradeSide',
    headerName: 'Side',
    type: 'dropdown',
    source: Options.side.map(o => o.key),
    renderer: sideRenderer
  },
  {
    data: 'tradeQty',
    headerName: 'Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  // {
  //   data: 'roundLotSize',
  //   headerName: 'Lot Size',
  //   readOnly: true,
  //   type: 'numeric',
  //   numericFormat: {
  //     pattern: '0,0'
  //   },
  //   allowEmpty: false
  // },
  {
    data: 'orderType',
    headerName: 'Type',
    type: 'dropdown',
    source: Options.orderType.map(o => o.key),
    renderer: orderTypeRenderer
  },
  {
    data: 'limitPriceLocal',
    headerName: 'Lmt Price',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    }
  },
  {
    data: 'timeInForce',
    headerName: 'TIF',
    type: 'dropdown',
    source: Options.timeInForce.map(o => o.key),
    allowEmpty: false,
    renderer: timeInForceRenderer
  },
  {
    data: 'algoCode',
    headerName: 'Algo',
    type: 'dropdown',
    source: Options.algo.map(o => o.key),
    allowEmpty: false
  },
  {
    data: 'pmRemark',
    headerName: 'Remark'
  },
  {
    data: 'pmReason',
    headerName: 'Reason'
  },
  {
    data: 'hasErrors',
    headerName: 'Error',
    renderer: booleanRenderer,
    readOnly: true
  }
];

const rollPosGridColumns = [
  {
    data: 'rollPct',
    headerName: 'ROLL%',
    type: 'numeric',
    allowEmpty: true
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'maturityDate',
    headerName: 'Maturity',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'multiplier',
    headerName: 'MUL',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  {
    data: 'qtyStart',
    headerName: 'POS Start',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  {
    data: 'clsQty',
    headerName: 'POS Left',
    readOnly: true,
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  // {
  //   data: 'clsMvPct',
  //   headerName: 'MV %',
  //   type: 'numeric',
  //   numericFormat: {
  //     pattern: '0.000'
  //   },
  //   readOnly: true
  // },
  {
    data: 'closeTradeSide',
    headerName: 'Cls Side',
    type: 'dropdown',
    source: Options.side.map(o => o.key),
    renderer: sideRenderer,
    readOnly: true
  },
  {
    data: 'closeTradeQty',
    headerName: 'Cls Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  {
    data: 'openTicker',
    headerName: 'Open Ticker'
  },
  {
    data: 'openMultiplier',
    headerName: 'Open MUL',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  {
    data: 'openTradeSide',
    headerName: 'Open Side',
    type: 'dropdown',
    source: Options.side.map(o => o.key),
    renderer: sideRenderer,
    readOnly: true
  },
  {
    data: 'openTradeQty',
    headerName: 'Open Qty',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0'
    },
    allowEmpty: false
  },
  // {
  //   data: 'orderType',
  //   headerName: 'Type',
  //   type: 'dropdown',
  //   source: Options.orderType.map(o => o.key),
  //   renderer: orderTypeRenderer
  // },
  // {
  //   data: 'limitPriceLocal',
  //   headerName: 'Lmt Price',
  //   type: 'numeric',
  //   numericFormat: {
  //     pattern: '0.000'
  //   }
  // },
  {
    data: 'dvd',
    headerName: 'DVD',
    type: 'numeric',
    allowEmpty: true,
    width: 50
  },
  {
    data: 'spreadPct',
    headerName: 'Spd%',
    type: 'numeric',
    width: 50,
    readOnly: true
  },
  {
    data: 'avgHistSpreadPct',
    headerName: 'Avg Spd%',
    type: 'numeric',
    width: 50,
    readOnly: true
  },
  {
    data: 'timeInForce',
    headerName: 'TIF',
    type: 'dropdown',
    source: Options.timeInForce.map(o => o.key),
    allowEmpty: false,
    renderer: timeInForceRenderer
  },
  {
    data: 'algoCode',
    headerName: 'Algo',
    type: 'dropdown',
    source: Options.algo.map(o => o.key),
    allowEmpty: false
  },
  {
    data: 'pmRemark',
    headerName: 'Remark'
  },
  {
    data: 'pmReason',
    headerName: 'Reason'
  },
  {
    data: 'hasErrors',
    headerName: 'Error',
    renderer: booleanRenderer,
    readOnly: true
  }
];

const ipoTradeGridColumns = [
  {
    data: 'fundBook',
    headerName: 'Fund-Book',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown'
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown'
  },
  {
    data: 'securityCode',
    headerName: 'Security Name',
    readOnly: true,
    allowEmpty: false
  },
  {
    data: 'qtyUsd',
    headerName: 'Trade Val',
    type: 'numeric',
    numericFormat: {
      pattern: '0,0 $'
    },
    allowEmpty: false
  },
  {
    data: 'qtyPct',
    headerName: 'Qty %',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false
  },
  {
    data: 'orderType',
    headerName: 'Type',
    type: 'dropdown',
    source: Options.orderType.map(o => o.key),
    renderer: orderTypeRenderer
  },
  {
    data: 'limitPriceLocal',
    headerName: 'Lmt Price',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    }
  },
  {
    data: 'pmRemark',
    headerName: 'PM Remark'
  },
  {
    data: 'pmReason',
    headerName: 'PM Reason'
  }
];

const addCBTradesGridColumns = [
  {
    data: 'fundBook',
    headerName: 'Fund-Book',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown',
    width: 80
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    allowEmpty: false,
    width: 50
  },
  {
    data: 'ticker',
    headerName: 'Ticker',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown',
    width: 90
  },
  {
    data: 'parVal',
    headerName: 'Par Val',
    type: 'numeric',
    readOnly: false,
    allowEmpty: false,
    width: 50
  },
  {
    data: 'price',
    headerName: 'Price',
    type: 'numeric',
    allowEmpty: false,
    width: 50
  },
  {
    data: 'delta',
    headerName: '<span style="color: orange;font-weight: 500">Delta %</span>',
    type: 'numeric',
    renderer: numberPercentRenderer,
    allowEmpty: false,
    width: 50
  },
  {
    data: 'cvtPrice',
    headerName: '<span style="color: orange;font-weight: 500">Cvt Price</span>',
    type: 'numeric',
    allowEmpty: false,
    renderer: numberFormatRenderer,
    width: 50
  },
  {
    data: 'eqtyTicker',
    headerName: 'Eqty Ticker',
    allowEmpty: false,
    width: 90
  },
  {
    data: 'eqtyPrice',
    headerName: 'Eqty Price',
    type: 'numeric',
    allowEmpty: false,
    width: 50
  },
  {
    data: 'parity',
    headerName: 'Parity',
    type: 'numeric',
    numericFormat: {
      pattern: '0.0000'
    },
    allowEmpty: false,
    width: 50
  },
  {
    data: 'premium',
    headerName: 'Premium',
    type: 'numeric',
    numericFormat: {
      pattern: '0.0000'
    },
    allowEmpty: false,
    width: 50
  },
  {
    data: 'cbLongQty',
    headerName:
      '<span style="color: orange;font-weight: 500">CB Long Qty</span>',
    type: 'numeric',
    allowEmpty: false,
    width: 60
  },
  {
    data: 'eqtyShortQty',
    headerName: 'Eqty Short Qty',
    type: 'numeric',
    allowEmpty: false,
    width: 60
  }
];

const addEqtyTradesGridColumns = [
  {
    data: 'fundBook',
    headerName: 'Fund-Book',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown',
    width: 80
  },
  {
    data: 'strategy',
    headerName: 'Strategy',
    allowEmpty: false,
    width: 50
  },
  {
    data: 'tickerLong',
    headerName: 'Long Ticker',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown',
    width: 90
  },
  {
    data: 'longTradePct',
    headerName: 'Long Qty%',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false,
    width: 50
  },
  {
    data: 'longTradeBeforePct',
    headerName: 'Long Before%',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false,
    width: 50
  },
  {
    data: 'longTradeAfterPct',
    headerName: 'Long After%',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: false,
    allowEmpty: false,
    width: 50
  },
  {
    data: 'tickerShort',
    headerName: 'Short ticker',
    readOnly: false,
    allowEmpty: false,
    type: 'dropdown',
    width: 90
  },
  {
    data: 'shortTradePct',
    headerName: 'Short Qty%',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false,
    width: 50
  },
  {
    data: 'shortTradeBeforePct',
    headerName: 'Short Before%',
    type: 'numeric',
    readOnly: true,
    numericFormat: {
      pattern: '0.000'
    },
    allowEmpty: false,
    width: 50
  },
  {
    data: 'shortTradeAfterPct',
    headerName: 'Short After%',
    type: 'numeric',
    numericFormat: {
      pattern: '0.000'
    },
    readOnly: false,
    allowEmpty: false,
    width: 50
  }
];

const cancelTradeTableColums = [
  {
    data: 'refId',
    headerName: 'Ref Id',
    readOnly: true
  },
  {
    data: 'pmStatus',
    headerName: 'Pm Status',
    readOnly: true
  },
  {
    data: 'traderStatus',
    headerName: 'Trader Status',
    readOnly: true
  },
  {
    data: 'requestStatus',
    headerName: 'Req Status',
    readOnly: true
  }
];

const trackOrdGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword',
    lockPinned: true
  },
  {
    field: 'side',
    headerName: 'Side',
    enableRowGroup: true,
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short'
  },
  {
    field: 'requestStatus',
    headerName: 'Req Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        ACTIVE: 'steelblue',
        FINALIZED: 'lightseagreen',
        ARCHIVE: 'lightslategrey'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'orderStatus',
    headerName: 'Ord Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        ASSIGN: 'coral',
        SENT: 'lightseagreen',
        FILLED: '#FF9E28',
        PARTFILL: 'steelblue',
        REJECTED: 'crimson',
        CANCEL: '#f50',
        WORKING: '#87d068',
        EXPIRED: 'lightslategrey'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'pmStatus',
    headerName: 'PM Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        SUBMIT: '#FF9E28',
        WITHDRAW: 'crimson',
        MODIFY: 'coral'
      }
    },
    valueFormatter: nullFormatter
  },
  {
    field: 'quantity',
    headerName: 'Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'filledQuantity',
    headerName: 'Filled',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'dayQty',
    headerName: 'Day Qty',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  },
  {
    field: 'trackRatio',
    headerName: 'Ratio',
    cellClass: 'number'
  },
  {
    field: 'trackSignedQty',
    headerName: 'Track Qty',
    valueFormatter: moneyFormatter,
    cellClassRules: sideStyle()
  }
];

export {
  tradeGridColumns,
  subTradeGridColumns,
  posGridColumns,
  rollPosGridColumns,
  ipoTradeGridColumns,
  addCBTradesGridColumns,
  addEqtyTradesGridColumns,
  cancelTradeTableColums,
  trackOrdGridColumns,
  fxTradeGridColumns
};
