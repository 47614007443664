import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Switch, DatePicker } from 'antd';
import { TRADE_VIEW_MODE_LIVE, TRADE_VIEW_MODE_HIST } from '../orderConstants';
import _ from 'lodash';
import {
  isInternalUser,
  disabledDate,
  isAdminRole
} from '../../../common/utils/DomainUtils';

class OperationBar extends Component {
  _getTradeBlotter = () => {
    const {
      getTradeBlotter,
      ui: { selectedDate }
    } = this.props;

    getTradeBlotter({
      tradeDate: selectedDate.format('YYYY-MM-DD')
    });
  };

  _disabledDate = date => {
    const { user } = this.props;
    return disabledDate(date, user);
  };

  render() {
    const {
      user,
      selectedTrade,
      selectedTrades,
      openSingleTradeDialog,
      openMultiTradesDialog,
      openQuantTradesDialog,
      openIPOTradesDialog,
      openCBTradesDialog,
      openAdjustPortfolioDialog,
      openRollPositionsDialog,
      openCancelTradeModal,
      onEditButtonClick,
      canDoTrade,
      isEditDisabled,
      ui,
      toggleViewMode,
      changeViewDate
    } = this.props;

    const editDisabled = isEditDisabled(selectedTrade);
    const cancelDisabled = _.isEmpty(selectedTrades);
    const showTradeBar = canDoTrade(user);
    const canPlaceMultiTrades = isInternalUser(user);
    const canPlaceIPOTrades = isInternalUser(user);
    const canRollPositions = isAdminRole(user && user.role);

    return (
      <div className="operation-bar">
        {showTradeBar && (
          <span>
            <Button
              size="mini"
              content="Add"
              color="green"
              onClick={() => openSingleTradeDialog(true)}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
            {canPlaceMultiTrades && (
              <Button
                size="mini"
                content="Add Multi"
                color="green"
                onClick={() => openMultiTradesDialog(true)}
                style={{ marginBottom: '4px', marginTop: '4px' }}
              />
            )}
            <Button
              size="mini"
              content="Add Batch"
              color="green"
              onClick={() => openQuantTradesDialog(true)}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
            <Button
              size="mini"
              content="Add Pair"
              color="green"
              onClick={() => openCBTradesDialog(true)}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
            {canPlaceIPOTrades && (
              <Button
                size="mini"
                content="Add IPO"
                color="green"
                onClick={() => openIPOTradesDialog(true)}
                style={{ marginBottom: '4px', marginTop: '4px' }}
              />
            )}
            (
            <Button
              size="mini"
              content="Adjust Pos"
              color="blue"
              onClick={() => openAdjustPortfolioDialog(true)}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
            )
            {canRollPositions && (
              <Button
                size="mini"
                content="Roll Pos"
                color="blue"
                onClick={() => openRollPositionsDialog(true)}
                style={{ marginBottom: '4px', marginTop: '4px' }}
              />
            )}
            <Button
              size="mini"
              content="Edit"
              color="green"
              disabled={editDisabled}
              onClick={onEditButtonClick}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
            <Button
              size="mini"
              content="Withdraw"
              color="red"
              disabled={cancelDisabled}
              onClick={openCancelTradeModal}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
          </span>
        )}

        <span>
          <Switch
            style={{ marginLeft: '20px' }}
            checked={ui.viewMode === TRADE_VIEW_MODE_LIVE}
            checkedChildren="LIVE"
            unCheckedChildren="HIST"
            defaultChecked
            onChange={toggleViewMode}
          />

          {ui.viewMode === TRADE_VIEW_MODE_HIST && (
            <DatePicker
              value={ui.selectedDate}
              format={'YYYY-MM-DD'}
              allowClear={false}
              onChange={changeViewDate}
              disabledDate={this._disabledDate}
            />
          )}

          {ui.viewMode === TRADE_VIEW_MODE_HIST && (
            <Button
              size="mini"
              icon="search"
              color="green"
              onClick={this._getTradeBlotter}
              style={{ marginBottom: '3px', marginTop: '3px' }}
            />
          )}
        </span>
      </div>
    );
  }
}

export default OperationBar;
