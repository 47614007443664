import React, { Component } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { Row, Col, Divider, Input } from 'antd';
//import MonthlyReviewBenchmarkGrid from './MonthlyReviewBenchmarkGrid';
import MonthlyReviewMyPortfolioGrid from './MonthlyReviewMyPortfolioGrid';

const { TextArea } = Input;
class MonthlyReviewDetailPanel extends Component {
  loadBenchmarkData = () => {
    const { monthlyReviewDetailDataReadonly } = this.props;
    const queryParams = {
      yearMonth: monthlyReviewDetailDataReadonly['yearMonth'],
      year: monthlyReviewDetailDataReadonly['year']
    };
    queryParams['monthlyReviewId'] = monthlyReviewDetailDataReadonly['id'];

    // this.props.loadMonthlyReviewBenchmark(queryParams);
  };

  loadMyPortfolioData = () => {
    const { monthlyReviewDetailDataReadonly } = this.props;
    const queryParams = {
      yearMonth: monthlyReviewDetailDataReadonly['yearMonth'],
      year: monthlyReviewDetailDataReadonly['year']
    };
    queryParams['monthlyReviewId'] = monthlyReviewDetailDataReadonly['id'];
    this.props.loadMonthlyReviewMyPortfilio(queryParams);
  };

  _getHeaderTitle = (monthForEng, year) => {
    if ('December' === monthForEng) {
      return `${year} Year Review`;
    } else {
      return `${monthForEng} ${year} Monthly Review`;
    }
  };

  render() {
    const { monthlyReviewDetailDataReadonly, detailIsLoaded } = this.props;
    const screenHeight = window.screen.height;
    let height = '850px';
    if (screenHeight <= 768) {
      height = screenHeight * 0.61 + 'px';
    } else {
      height = screenHeight * 0.75 + 'px';
    }
    const data = monthlyReviewDetailDataReadonly;
    let gridShow = data && data['userId'];
    if (gridShow) {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <style>
            {`

                      #monthlyReviewDetailDiv .ant-input {
                        background-color: #242424;
                        color: white
                      }

                      #monthlyReviewDetailDiv .ant-input-number-input-wrap {
                        background-color: #242424;
                        color: white
                      }

                      #monthlyReviewDetailDiv .ant-input-group-addon {
                        background-color: #242424;
                        color:#FF9E28
                      }

                      .monthlyReviewDetailSector{
                        margin-top: 20px;
                      }


                  
                    `}
          </style>
          {!detailIsLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <div
              style={{
                width: '100%',
                height: '90%'
              }}
            >
              <Header
                inverted
                block
                style={{ background: '#242424', marginBottom: '0px' }}
              >
                <Row>
                  <Col span={24} style={{ fontSize: 20, textAlign: 'center' }}>
                    {this._getHeaderTitle(data.monthForEng, data.year)}
                  </Col>
                </Row>
              </Header>

              <div
                id="monthlyReviewDetailDiv"
                style={{ overflowY: 'auto', height: '95%', maxHeight: height }}
              >
                <div style={{ position: 'relative', height: '340px' }}>
                  <MonthlyReviewMyPortfolioGrid
                    {...this.props}
                    readOnlyPage={true}
                  />
                </div>

                <Divider />
                <div
                  style={{
                    color: '#FF9E28',
                    marginLeft: '10px',
                    fontSize: 14
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <span>
                        At the end of {data.monthForEng}{' '}
                        {'，My portfolio Gross is '}
                        <span style={{ color: 'white' }}>
                          <b>{data.grossMargin}%</b>
                        </span>
                        ，after beta net is{' '}
                        <span style={{ color: 'white' }}>
                          <b>{data.netValue}%.</b>
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Mainly gain from：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={3}
                        readOnly={true}
                        value={data.gainPortfolio}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Mainly loss from：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={3}
                        readOnly={true}
                        value={data.lossPortfolio}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Trading feedback for {data.monthForEng}：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={4}
                        readOnly={true}
                        value={data.feedback}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Trading idea suggestion for Mr. Wang Qiang：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        readOnly={true}
                        rows={3}
                        value={data.tradingSuggestion}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Trading idea for future：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        readOnly={true}
                        rows={4}
                        value={data.nextMonthTradingIdea}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Sector fundamental update in future：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        readOnly={true}
                        value={data.fundamental}
                        rows={4}
                      />
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row></Row>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default MonthlyReviewDetailPanel;
