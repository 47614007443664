import React, { Component } from 'react';
import { Switch } from 'antd';

export default class SwitchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.valueFormatted || props.value,
      data: props.data
    };
  }

  render() {
    const { onChange } = this.props;
    const { value, data } = this.state;

    return (
      <div>
        <style>
          {`
            .ant-switch-checked {
              background-color: #FF9E28;
              margin-right: 5px;
            }

            .ant-switch-inner {
              font-weight: 600;
            }
          `}
        </style>
        <Switch
          checked={value}
          checkedChildren="YES"
          unCheckedChildren="NO"
          onChange={v => onChange(v, data)}
        ></Switch>
      </div>
    );
  }

  refresh(params) {
    this.setState({
      value: params.valueFormatted || params.value,
      data: params.data
    });
    return true;
  }
}
