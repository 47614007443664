import React, { Component } from 'react';
import { Header, Modal, Message } from 'semantic-ui-react';
import { DatePicker, Row, Col, Button, Tabs, Select } from 'antd';
import 'ag-grid-enterprise';
import AnalystAddGrid from './AnalystAddGrid';
import AnalystMeetingListGrid from './AnalystMeetingListGrid';
const { MonthPicker } = DatePicker;

class AnalystListAddDialog extends Component {
  constructor(props) {
    super(props);
    const { brokerCodeList, analystNameList, analystSectorList } = this.props;
    this.state = {
      contextMenu: ['row_above', 'row_below', 'remove_row'],
      colWidths: [300, 300, 300, 300],
      errorMsgs: [],
      meetingList: [],
      selectedData: {}
    };
    this.columns = [
      {
        data: 'brokerCode',
        headerName: 'Broker',
        type: 'dropdown',
        source: brokerCodeList,
        validator: function(value, callback) {
          callback(true);
        }
      },
      {
        data: 'analystName',
        readOnly: false,
        allowEmpty: false,
        type: 'dropdown',
        source: analystNameList,
        validator: function(value, callback) {
          callback(true);
        }
      },
      {
        data: 'sector',
        readOnly: false,
        allowEmpty: false,
        type: 'dropdown',
        source: analystSectorList,
        validator: function(value, callback) {
          callback(true);
        }
      },
      {
        data: 'score',
        readOnly: false,
        allowEmpty: false,
        type: 'numeric',
        numericFormat: {
          pattern: '0',
          allowNegative: false
        }
      }
    ];
    this.nestedHeaders = [
      ['Broker Name', 'Analyst Name', 'Sector', 'Score(0-10)']
    ];
  }

  closeWindow = () => {
    this.props.closeAnalystModal();
  };

  saveData = () => {
    const { votingMonth } = this.props.analystListModalData;
    //check data
    var checkFlag = this.checkSaveData();
    if (!checkFlag) {
      return;
    }
    this.props.saveAnalyst(
      {
        dataList: this.props.analystListModalData.data,
        votingMonth: votingMonth.format('YYYY-MM')
      },
      this.props.analystQueryParams
    );
  };

  checkSaveData = () => {
    const data = this.props.analystListModalData.data;
    const errorMsg = [];
    for (var i = 0; i < data.length; i++) {
      const current = data[i];
      if (!current['op']) {
        continue;
      }
      if (
        !current['brokerCode'] &&
        !current['analystName'] &&
        !current['score'] &&
        !current['sector']
      ) {
        current['op'] = null;
        continue;
      }
      if (!current['brokerCode']) {
        errorMsg.push('Broker Name must not be empty');
      }
      if (!current['analystName']) {
        errorMsg.push('Analyst Name must not be empty');
      }
      if (this.checkNumber(current['score'])) {
        if (current['score'] < 0 || current['score'] > 10) {
          errorMsg.push('Score must not be [0-10]');
        }
      } else {
        errorMsg.push('Score must not be [0-10]');
      }
      if (errorMsg.length > 0) {
        this.setState({
          errorMsgs: errorMsg
        });
        return false;
      }
    }
    this.setState({
      errorMsgs: []
    });
    return true;
  };

  checkNumber = value => {
    var reg = /^[0-9]+.?[0-9]*$/;
    if (reg.test(value)) {
      return true;
    }
    return false;
  };

  addData = () => {
    const { data } = this.props.analystListModalData;
    data.push({});
    this.props.changeAnalystModalInput({ name: 'data', value: data });
  };

  votingMonthChange = date => {
    const brokerCode = this.props.analystUpdateModalParam.data.brokerCode;
    this.props.changeAnalystModalInput({
      name: 'votingMonth',
      value: date
    });

    this.props.getAnalystDetailList({
      votingMonth: date.format('YYYY-MM'),
      brokerCode: brokerCode
    });
  };
  onInputChange = ({ name, value }) => {
    const { data } = this.props.analystUpdateModalParam;
    const { votingMonth } = this.props.analystListModalData;
    data[name] = value;
    this.props.changeAnalystUpdateModalInput({
      name: 'data',
      value: data
    });
    this.props.getAnalystDetailList({
      votingMonth: votingMonth.format('YYYY-MM'),
      brokerCode: value
    });
  };
  afterChangeHandle = (value, action) => {
    const { data } = this.props.analystListModalData;
    if (action === 'edit') {
      const current = value[0];
      if (value) {
        const index = current[0];
        data[index]['op'] = 'U';
      }
    }
    if (action === 'Autofill.fill') {
      value.forEach(item => {
        const index = item[0];
        data[index]['op'] = 'U';
      });
    }
  };
  selectedDataChange = data => {
    this.setState({
      selectedData: data
    });
  };
  render() {
    const { isOpened, votingMonth } = this.props.analystListModalData;
    const {
      loadBrokerListStatus,
      loadAnalystNameListStatus,
      loadAnalystSectorListStatus,
      analystNameList,
      analystSectorList
    } = this.props;
    const { brokerLabelValueList } = this.props;
    const pmName = this.props.user['englishName'];
    // const { data } = this.props.analystUpdateModalParam;
    return (
      <div>
        <Modal size="small" open={isOpened}>
          <Header content="Analyst Score" />
          <Modal.Content>
            <Row style={{ marginBottom: '10px' }}>
              <Col span={7}>
                <label>Voting Month:</label>
                <MonthPicker
                  value={votingMonth}
                  format={'YYYY-MM'}
                  allowClear={false}
                  onChange={this.votingMonthChange}
                  style={{ marginLeft: '5px' }}
                />
              </Col>
              <Col span={7}>
                <label>Broker:</label>
                <Select
                  allowClear
                  options={brokerLabelValueList}
                  placeholder="Choose Broker Name"
                  // value={brokerCode}
                  // style={{ width: '100%', height: '37.8px', padding: '0px' }}
                  onChange={value => {
                    this.onInputChange({ name: 'brokerCode', value });
                  }}
                />
              </Col>
              <Col span={7}>
                <label>PM Name : </label>
                <span>{pmName}</span>
              </Col>
            </Row>
            {loadBrokerListStatus &&
            loadAnalystNameListStatus &&
            loadAnalystSectorListStatus ? (
              <AnalystAddGrid
                {...this.props}
                analystNameList={analystNameList}
                analystSectorList={analystSectorList}
                votingMonth={votingMonth}
                selectedDataChange={this.selectedDataChange}
              />
            ) : (
              ''
            )}
            {this.state.errorMsgs.length > 0 ? (
              <Message
                error
                header="Fix Below Errors"
                list={this.state.errorMsgs}
              />
            ) : (
              ''
            )}
            <div>
              <p style={{ marginBottom: '5px' }}>
                <b>Note: </b>
              </p>
              <p style={{ marginTop: '5px', marginBottom: '5px' }}>
                <b>{'1. 10 is the highest score, 0 is the lowest.'}</b>
              </p>
            </div>
            <Tabs tabPosition="top" key="LeftTabs">
              <Tabs.TabPane tab="MeetingRecord" key="MeetingRecord">
                {
                  <div style={{ height: '300px' }}>
                    <style>
                      {`
                    .ant-tabs-nav .ant-tabs-tab {
                      color: black;
                      font-weight: 700;
                      font-style: italic;
                      padding: 2px 10px;
                    }

                    .ant-tabs-nav .ant-tabs-tab-active {
                      color: #1890ff;
                      font-weight: 700;
                      font-style: italic;
                      padding: 2px 10px;
                    }
                  `}
                    </style>
                    <AnalystMeetingListGrid
                      {...this.props}
                      analystNameList={analystNameList}
                      analystSectorList={analystSectorList}
                      selectedData={this.state.selectedData}
                    />
                  </div>
                }
              </Tabs.TabPane>
            </Tabs>
          </Modal.Content>
          <Modal.Actions>
            <Button type="primary" onClick={this.saveData}>
              Submit
            </Button>
            <Button type="primary" onClick={this.closeWindow}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default AnalystListAddDialog;
