import React, { PureComponent } from 'react';
import { Modal, Button, Spin } from 'antd';
import { DIALOG_RECALL } from '../../omsConstants';
import { HotTable } from '@handsontable/react';
import {
  executeRouteHoldingGridColumns,
  recallTableColumns,
  recallTradesTableColumns,
  recallTargetRatioColumns
} from './GridColumnMap';
import hotTableUtils from 'common/ui/hotTableUtils';
import client from '../../api/client';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import _ from 'lodash';
import SplitPane from 'react-split-pane';
import orderClient from 'features/order/api/client';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import { DIALOG_EXECUTE_QUANT_TRADES } from 'features/order/orderConstants';
import config from 'common/config';
import { Message } from 'semantic-ui-react';

class RecallDialogs extends PureComponent {
  state = {
    isInitialized: false,
    headerData: [
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() },
      { id: _.uniqueId() }
    ],
    fundMap: {
      PLATUSD: ['PMSF', 'VCC'],
      PLATCNY: ['CVF', 'DCL']
    },
    revertFundMap: {
      PMSF: 'PLATUSD',
      VCC: 'PLATUSD',
      CVF: 'PLATCNY',
      DCL: 'PLATCNY'
    },
    trades: [],
    holdings: [],
    targetRatios: [],
    allocationPercents: [],
    selectedData: null,
    holdingsByTicker: {},
    buMappingMap: {},
    headerGridWrapperStyle: {
      width: '100%',
      height: '280px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    tradeGridWrapperStyle: {
      width: '100%',
      height: '280px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    headerGridSettings: null,
    holdingsGridSettings: agGridUtils.createSettings({
      columnDefs: executeRouteHoldingGridColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    tradeGridSetting: hotTableUtils.createSettings({
      columns: recallTradesTableColumns,
      rowHeaders: true,
      contextMenu: {}
    }),
    holdingsGridWrapperStyle: {
      width: '100%',
      height: '300px'
    },
    targetRatioGridSettings: agGridUtils.createSettings({
      columnDefs: recallTargetRatioColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    targetRatioGridWrapperStyle: {
      width: '100%',
      height: '300px'
    },
    secMap: {}
  };

  componentDidMount() {
    this._initData();
  }

  queryHoldings = tickers => {
    const rawTicker = tickers.map(r => {
      if (
        r.endsWith('C1 Equity') ||
        r.endsWith('C2 Equity') ||
        r.endsWith('H1 Equity') ||
        r.endsWith('H2 Equity')
      )
        return r
          .replace('C1 Equity', 'CH Equity')
          .replace('C2 Equity', 'CH Equity')
          .replace('H1 Equity', 'HK Equity')
          .replace('H2 Equity', 'HK Equity');
      return r;
    });
    client
      .queryHoldings(rawTicker)
      .then(resp => {
        const data = _.isEmpty(resp)
          ? []
          : resp.map(r => ({
              ...r,
              theoreticalQty: r.eod.theorQty,
              theoreticalQtyForClose: r.eod.availQty,
              theoQtyStartLeft: r.eod.availQtyStart
            }));
        const holdingsByTicker = _.isEmpty(data)
          ? {}
          : _.groupBy(data, 'ticker');
        this.setState(
          {
            holdingsByTicker
          },
          this._calcTrades
        );
      })
      .then(err => {
        console.log(err);
      });
  };

  _calcTrades = () => {
    const { headerData, holdingsByTicker } = this.state;
    const trades = [];
    if (_.isEmpty(headerData)) return;
    if (!_.isEmpty(headerData) && !_.isEmpty(holdingsByTicker)) {
      const updateData = [];
      headerData.forEach(r => {
        const rowData = { ...r };
        if (r.fundCode && r.custodian && r.ticker && r.qty) {
          const { qty, reshortQty, lotSize, ticker, id } = r;
          const holdings = this._filterHoldings(r);
          const allocations = this.distributeAmount(
            holdings.map(e => Math.abs(e.eod.availQty)),
            qty,
            lotSize
          );
          const reShortAllocations = this.distributeAmount(
            holdings.map(e => Math.abs(e.eod.availQty)),
            reshortQty,
            lotSize
          );
          rowData.leftCovrQty = qty - _.sum(allocations);
          rowData.leftShrtQty = reshortQty - _.sum(reShortAllocations);

          const datas = holdings.map((r, index) => {
            const allocationQty = allocations[index];
            const reShortQty = reShortAllocations[index];
            return {
              fundCode: r.fundCode,
              bookCode: r.bookCode,
              custodian: r.custodianCode,
              ticker: ticker,
              cfdType: r.cfdType,
              qty: allocationQty,
              reShortQty: reShortQty,
              strategy: r.strategyCode,
              sourceHeaderId: id,
              primaryTicker: r.primaryTicker
            };
          });
          trades.push(...datas);
          updateData.push(rowData);
        }
      });
      this.setState(
        {
          trades,
          headerData: updateData
        },
        () => {
          this._setSelectedDatas(headerData);
        }
      );
    }
  };

  distributeAmount = (weights, total, lotSize) => {
    let totalWeight = weights.reduce((acc, curr) => acc + curr, 0);

    let allocations = weights.map(weight => {
      let ratio = (weight / totalWeight) * total;
      let allocation = Math.floor(ratio / lotSize) * lotSize;
      allocation = Math.min(allocation, weight);
      return allocation;
    });

    let sum = allocations.reduce((acc, curr) => acc + curr, 0);
    let remaining = total - sum;
    if (remaining > 0) {
      let indices = allocations
        .map((_, index) => index)
        .filter(
          index =>
            allocations[index] < weights[index] &&
            weights[index] - allocations[index] >= lotSize
        );
      let fillCount = 0;
      while (remaining > 0 && fillCount < indices.length) {
        let index = indices[fillCount];
        let availableSpace = weights[index] - allocations[index];
        let addTo = Math.min(remaining, availableSpace, lotSize);
        allocations[index] += addTo;
        remaining -= addTo;
        fillCount++;
      }
    }

    return allocations;
  };

  _initData = () => {
    const { funds } = this.props.settings;
    Promise.all([client.getExecutionOptions(), client.getBuMapping()])
      .then(([{ custodians }, buMapping]) => {
        const headerGridSettings = this._createHeaderGridSettings(
          custodians.map(b => b.custodianCode),
          _.uniq(funds.map(c => c.name))
        );

        const buMappingMap = {};
        buMapping.forEach(
          e => (buMappingMap[`${e.slaveFundCode}_${e.slaveBookCode}`] = e)
        );

        _.delay(() => {
          this.setState({
            isInitialized: true,
            buMappingMap,
            headerGridSettings
          });
        }, 150);
      })
      .catch(ex => {
        console.log(ex);
        this.setState({
          isInitialized: true,
          submitStatus: 'ERROR'
        });
      });
  };

  _createHeaderGridSettings = (custodianCodes, funds) => {
    const optionsMapping = {
      custodian: custodianCodes,
      fundCode: funds
    };
    const columns = recallTableColumns.map(c => {
      const values = optionsMapping[c.data];
      return values
        ? {
            ...c,
            source: values
          }
        : c;
    });
    const contextMenu = {
      items: {
        remove_row: {},
        clone: {
          name: 'Clone row',
          callback: () => {
            // Must delay below operation, otherwise handsontable will throw exception.
            _.delay(() => this._cloneHeaderRow(), 100);
          }
        }
      }
    };
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _cloneHeaderRow = () => {
    const { headerData, selectedData } = this.state;

    const clonedData = {
      ...selectedData,
      id: _.uniqueId(),
      qty: null
    };

    const row = headerData.findIndex(r => r.id === headerData.id);
    const updatedData = [
      ...headerData.slice(0, row + 1),
      ...[clonedData],
      ...headerData.slice(row + 1)
    ];

    this.setState({ headerData: updatedData });
  };

  _applyChange = () => {
    const { headerData } = this.state;
    const tickers = headerData.map(r => r.ticker).filter(r => r);
    this.queryHoldings(tickers);
  };

  _onDataSelectionChanged = (row, col, row2, col2) => {
    const { headerData } = this.state;
    // const route = routes[row];
    // if (route === selectedRoute) return;

    const selectedData = _.slice(headerData, row, row2 + 1);

    this._setSelectedDatas(selectedData);
  };

  _beforeCellChange = (changes, source) => {
    if (source !== 'edit') return;
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) {
      return this._handleChanges(realChanges);
    }

    return true;
  };

  _afterTradesCellChange = (changes, source) => {
    if (source !== 'edit') return;
    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) {
      return this._handleTradesChanges(realChanges);
    }

    return true;
  };

  _normalizeField = (field, value) => {
    if (!value) return { [field]: value };
    if (field === 'fundCode') {
      return { [field]: value.replace(/\s+/g, '').toUpperCase() };
    }
    return { [field]: value };
  };

  _handleChanges = async realChanges => {
    const { headerData } = this.state;
    const updateData = await Promise.all(
      realChanges.map(async r => {
        const [row, field, oldValue, newValue] = r;
        const detail = headerData[row];
        let result = {
          ...detail,
          ...this._normalizeField(field, newValue)
        };
        if (
          oldValue !== newValue &&
          field === 'ticker' &&
          !_.isEmpty(newValue)
        ) {
          const ticker = newValue.endsWith('Equity')
            ? newValue
            : `${newValue} Equity`;
          const infos = await orderClient
            .getSecurities([{ ticker }])
            .catch(error => console.log(error));
          if (!_.isEmpty(infos) && !_.isEmpty(infos[0])) {
            const secInfo = infos[0];
            result = {
              ...result,
              lotSize: secInfo.lotSize
            };
          }
        }
        return result;
      })
    );
    const data = headerData.map(r => {
      const updateD = updateData.filter(e => e.id === r.id);
      if (!_.isEmpty(updateD)) {
        return {
          ...updateD[0]
        };
      }
      return { ...r };
    });
    _.delay(() => {
      this.setState({
        headerData: data
      });
    }, 100);
    return false;
  };

  _handleTradesChanges = () => {
    const { trades, headerData } = this.state;
    if (_.isEmpty(headerData)) return;
    const updateData = headerData.map(r => {
      const { qty, reshortQty, id } = r;
      const filterData = trades.filter(e => e.sourceHeaderId === id);
      const allocationQty = qty ? _.sum(filterData.map(item => item.qty)) : 0;
      const allocationShrtQty = reshortQty
        ? _.sum(filterData.map(item => item.reShortQty))
        : 0;
      return {
        ...r,
        leftCovrQty: qty ? qty - allocationQty : 0,
        leftShrtQty: reshortQty ? reshortQty - allocationShrtQty : 0
      };
    });
    _.delay(() => {
      this.setState({
        headerData: updateData
      });
    }, 200);
  };

  _filterHoldings = data => {
    const { holdingsByTicker, fundMap } = this.state;
    const { fundCode, custodian, ticker, cfdType } = data;
    const filterArr = [fundCode, custodian, ticker, cfdType].filter(r => r);
    if (_.isEmpty(filterArr)) return [];
    const fundArr = fundCode
      .split(',')
      .map(r => (fundMap[r] ? fundMap[r] : [r]));
    const funds = _.reduce(
      fundArr,
      (result, array) => _.concat(result, array),
      []
    );
    return _.sortBy(holdingsByTicker[ticker]).filter(
      r =>
        funds.includes(r.fundCode) &&
        r.cfdType === cfdType &&
        r.custodianCode === custodian &&
        r.eod &&
        r.eod.qty < 0
    );
  };

  _setSelectedDatas = selectedDatas => {
    if (_.isEmpty(selectedDatas)) {
      this.setState({
        selectedData: null,
        holdings: []
      });
      return;
    }

    const data = _.first(selectedDatas);

    this.setState({
      selectedData: data,
      holdings: this._filterHoldings(data)
    });
  };

  _createHeaderTable = () => {
    const {
      headerGridWrapperStyle,
      headerGridSettings,
      headerData
    } = this.state;

    return (
      <div style={headerGridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={headerData}
          manualColumnResize={true}
          beforeChange={this._beforeCellChange}
          afterSelectionEnd={this._onDataSelectionChanged}
          {...headerGridSettings}
          licenseKey="non-commercial-and-evaluation"
        ></HotTable>
      </div>
    );
  };

  _createTradeTable = () => {
    const { tradeGridWrapperStyle, tradeGridSetting, trades } = this.state;

    return (
      <div style={tradeGridWrapperStyle}>
        <HotTable
          ref={this.hotTblRef}
          data={trades}
          manualColumnResize={true}
          afterChange={this._afterTradesCellChange}
          {...tradeGridSetting}
          licenseKey="non-commercial-and-evaluation"
        ></HotTable>
      </div>
    );
  };

  _onHoldingsGridReady = params => {
    this.holdingsGridApi = params.api;

    const COLUMNS_KEY = 'recall-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onTargetRatioGridReady = params => {
    this.targetRatioGridApi = params.api;

    const COLUMNS_KEY = 'recall-ratio-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.targetRatioGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _createHoldingsGrid = () => {
    const {
      holdingsGridSettings,
      holdingsGridWrapperStyle,
      holdings
    } = this.state;
    const showHoldings = _.isEmpty(holdings)
      ? []
      : holdings.map(r => ({ ...r, strategy: r.strategyCode }));

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={showHoldings}
          {...holdingsGridSettings}
          onGridReady={this._onHoldingsGridReady}
        />
      </div>
    );
  };

  _createTargetRatioGrid = () => {
    const {
      targetRatioGridSettings,
      targetRatioGridWrapperStyle,
      targetRatios,
      selectedData,
      trades
    } = this.state;

    const books = _.isEmpty(trades)
      ? []
      : trades
          .filter(r => r.sourceHeaderId === selectedData.id)
          .map(r => r.bookCode);
    const platFundCode = config.system === 'OFFSHORE' ? 'PLATUSD' : 'PLATCNY';
    const rowData =
      _.isEmpty(selectedData) || _.isEmpty(targetRatios)
        ? []
        : targetRatios.filter(
            r =>
              books.includes(r.sourceBookCode) &&
              platFundCode === r.sourceFundCode &&
              platFundCode === r.targetFundCode
          );

    return (
      <div
        style={targetRatioGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          rowData={rowData}
          {...targetRatioGridSettings}
          onGridReady={this._onTargetRatioGridReady}
          getRowNodeId={data => data.sourceBookCode}
        />
      </div>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_RECALL);
  };

  openQuantTradesDialog = trades => {
    this.props.openDialog(DIALOG_EXECUTE_QUANT_TRADES, {
      createNew: true,
      trades
    });
  };

  submitData = () => {
    const { trades, buMappingMap } = this.state;
    if (_.isEmpty(trades)) return;
    const submitData = [];
    trades.forEach(r => {
      const preRoute = {
        fundCode: r.fundCode,
        bookCode: r.bookCode,
        cfdType: r.cfdType,
        ticker: r.ticker
      };
      const mapping = buMappingMap[`${r.fundCode}_${r.bookCode}`];
      const fundBook = mapping
        ? { fundCode: mapping.masterFundCode, bookCode: mapping.masterBookCode }
        : { fundCode: r.fundCode, bookCode: r.bookCode };
      const covrRoute = {
        ...r,
        ...fundBook,
        ticker: r.primaryTicker,
        strategy: r.strategy,
        quantity: r.qty,
        algoCode: 'INLINE',
        orderType: 'MKT',
        timeInForce: 'DAY',
        pmReason: '[RECALL]',
        side: 'COVR',
        preRoutes: [
          {
            ...preRoute,
            qty: r.qty,
            side: 'COVR',
            notes: 'RECALL',
            custodian: r.custodian,
            venue: 'MANUAL'
          }
        ]
      };
      if (r.qty > 0) {
        submitData.push(covrRoute);
      }
      if (r.reShortQty && r.reShortQty > 0) {
        const reShortRoute = {
          ...covrRoute,
          side: 'SHRT',
          quantity: r.reShortQty,
          preRoutes: [
            { ...preRoute, qty: r.reShortQty, side: 'SHRT', notes: null }
          ]
        };
        if (r.reShortQty > 0) {
          submitData.push(reShortRoute);
        }
      }
    });
    const submitTrades = Object.values(
      _.groupBy(
        submitData,
        r =>
          `${r.fundCode}_${r.bookCode}_${r.custodian}_${r.strategy}_${r.ticker}_${r.side}`
      )
    ).map(r => {
      if (r.length > 1) {
        const firstItem = r[0];
        for (let i = 1; i < r.length; i++) {
          firstItem.quantity += r[i].quantity;
          firstItem.preRoutes.push(...r[i].preRoutes);
        }
      }
      return r[0];
    });
    this.openQuantTradesDialog(submitTrades);
  };

  _createErrMsg = () => {
    const { headerData } = this.state;
    const warningData = headerData
      ? headerData.filter(r => r.leftCovrQty || r.leftShrtQty)
      : [];
    return (
      <>
        {!_.isEmpty(warningData) && (
          <Message warning list={['Exist unallocated quantity.']} />
        )}
      </>
    );
  };

  render() {
    const { isInitialized } = this.state;

    return (
      <Modal
        width={1800}
        maskClosable={false}
        title="Recall"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        bodyStyle={{ paddingTop: '5px' }}
        footer={[
          <Button key="submit" type="primary" onClick={this.submitData}>
            Submit
          </Button>,
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <div style={{ marginTop: '10px', textAlign: 'right' }}>
          <Button type="primary" size="small" onClick={this._applyChange}>
            Calc
          </Button>
        </div>
        <Spin tip="Initializing..." spinning={!isInitialized}>
          <SplitPane
            split="horizontal"
            defaultSize="50%"
            style={{ position: 'relative' }}
          >
            <SplitPane
              split="vertical"
              defaultSize="55%"
              style={{ position: 'relative' }}
            >
              {this._createHeaderTable()}
              {this._createTradeTable()}
            </SplitPane>
            {this._createHoldingsGrid()}
          </SplitPane>
          {this._createErrMsg()}
        </Spin>
      </Modal>
    );
  }
}

export default RecallDialogs;
