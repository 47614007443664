import React, { Component } from 'react';
import _ from 'lodash';
import { reportGridColumns } from '../GridColumnMap';
import { AgGridReact } from 'ag-grid-react';
import { Button, Select, DatePicker } from 'antd';
import JsonTooltipComponent from '../../../../common/ui/renderers/agGrid/JsonTooltipComponent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import omsClient from 'features/oms/api/client';
import client from '../../api/client';
import moment from 'moment';
import config from 'common/config';
import DetailDrawer from './DetailDrawer';
import { QuestionCircleOutlined } from '@ant-design/icons';

const Option = Select.Option;
const { RangePicker } = DatePicker;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c.name || 'default'} value={c.name}>
      {c.desc}
    </Option>
  ));
};
class ReportGrid extends Component {
  constructor(props) {
    super(props);
    const view = {
      headerName: 'View',
      cellClass: 'non-number',
      width: 80,
      cellRenderer: params => {
        const data = params.data;
        if (!data || data.status !== 'PASS') return '';
        let link = document.createElement('a');
        link.innerText = 'Preview';
        link.href = '#';
        link.onclick = () => {
          this._viewDetail(params.data);
        };
        return link;
      }
    };
    const colunms = [...reportGridColumns, view];

    this.state = {
      columnDefs: colunms,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      defaultBottomColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true,
        flex: 1
      },
      context: {
        gridComponent: this
      },
      frameworkComponents: {
        jsonTooltipComponent: JsonTooltipComponent,
        tagComponent: TagComponent
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      reportList: [],
      params: {
        reportName: '',
        selectedDate: [moment().subtract(90, 'days'), moment()]
      },
      data: [],
      detailDrawer: {
        isOpened: false,
        url: null
      }
    };
  }

  componentDidMount() {
    this._init();
  }

  _viewDetail = data => {
    if (!data) return;
    const { attachments } = data;
    if (_.isEmpty(attachments)) return;
    const name = attachments[0].path;
    const source = btoa(name);
    var type = name.substring(name.lastIndexOf('.'), name.length);
    let uri = config.api.RESEARCH_URL;
    let host = window.location.host;
    if (host.indexOf('ims') === 0) {
      host = 'https://' + host;
    } else {
      host = 'http://' + host;
    }
    uri = uri === '/rsrch' ? host + uri : uri;
    const logParams = { application: 'IMS_CLIENT', requestBody: name };
    if (type === '.pptx' || type === '.xlsx' || type === '.docx') {
      logParams.uri = 'downloadAttBase64';
      this._addAccessLog(logParams);
      let host = window.location.host;
      if (host.indexOf('ims') === 0) {
        host = 'https://' + host;
      } else {
        host = 'http://' + host;
      }
      const url = `https://view.officeapps.live.com/op/view.aspx?src=${uri}/file/downloadAttBase64/${source}`;
      this._openDetailDraw(url);
    } else {
      logParams.uri = 'previewAttBase64';
      this._addAccessLog(logParams);
      const url = `${uri}/file/previewAttBase64/${source}`;
      this._openDetailDraw(url);
    }
  };

  _addAccessLog = params => {
    client
      .addAccessLog(params)
      .then(resp => {})
      .catch(err => {
        console.log(err);
      });
  };

  _openDetailDraw = url => {
    this.setState({
      detailDrawer: {
        isOpened: true,
        url
      }
    });
  };

  _closeDetailDraw = url => {
    this.setState({
      detailDrawer: {
        isOpened: false,
        url: null
      }
    });
  };

  _createDetailDraw = () => {
    const { isOpened, url } = this.state.detailDrawer;
    return (
      isOpened && <DetailDrawer url={url} closeDrawer={this._closeDetailDraw} />
    );
  };

  _init = () => {
    const { params } = this.state;
    Promise.all([omsClient.getReportSpecs({ tags: ['ims'] })])
      .then(([tagsResp]) => {
        const reportList = tagsResp.map(r => ({
          name: r.name,
          desc: r.desc
        }));
        this.setState(
          {
            reportList,
            params: {
              ...params,
              reportName: reportList[0].name
            }
          },
          () => {
            _.delay(this._loadData, 100);
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  _loadData = () => {
    const { params } = this.state;
    const { selectedDate } = params;
    if (_.isEmpty(selectedDate) || selectedDate.length !== 2) return;
    const queryParams = {
      rptName: params.reportName,
      start: `${selectedDate[0].format('YYYY-MM-DD')} 00:00:00`,
      end: `${selectedDate[1].format('YYYY-MM-DD')} 23:59:59`
    };

    omsClient
      .getRptRuns(queryParams)
      .then(resp => {
        const data = _.isEmpty(resp)
          ? []
          : resp.map(r => {
              const params = r.rpt_params ? JSON.parse(r.rpt_params) : {};
              const attachments = r.attachments
                ? JSON.parse(r.attachments)
                : [];
              return {
                rptName: r.rpt_name,
                createTime: r.req_time,
                completeTime: r.done_time,
                status: r.status,
                createBy: r.run_by,
                team: params.team,
                startDate: params.start_date,
                endDate: params.end_date,
                params: params,
                attachments
              };
            });
        this.setState({
          data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
    //this.setPinnedTopRowData();
  };

  _onInputChange = ({ name, value }) => {
    const { params } = this.state;
    const updateParams = {
      ...params,
      [name]: value
    };
    this.setState({
      params: updateParams
    });
  };

  openHelpDocument = () => {
    window.open(config.api.RESEARCH_URL + '/file/viewHelpDocument');
  };

  _createQueryHeader = () => {
    const { reportList, params } = this.state;
    return (
      <div style={{ textAlign: 'right', marginBottom: '3px' }}>
        <Select
          size="small"
          style={{ marginRight: '5px', width: '250px' }}
          value={params.reportName}
          onChange={value => {
            this._onInputChange({
              name: 'reportName',
              value: value
            });
          }}
          placeholder="Select Report"
        >
          {_createUIOptions(reportList)}
        </Select>
        <RangePicker
          size="small"
          style={{ marginRight: '5px' }}
          disabledDate={this.disabledDate}
          onCalendarChange={dates => {
            this._onInputChange({
              name: 'selectedDate',
              value: dates
            });
          }}
          value={params.selectedDate}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onChange={dates => {
            this._onInputChange({
              name: 'selectedDate',
              value: dates
            });
          }}
        />
        <Button
          type="primary"
          size="small"
          style={{ marginRight: '5px' }}
          onClick={this._loadData}
        >
          Reload
        </Button>
        <QuestionCircleOutlined
          style={{ width: '28px', height: '28px', color: 'white' }}
          onClick={this.openHelpDocument}
        />
      </div>
    );
  };

  render() {
    const { data, className = 'grid-wrapper' } = this.state;

    const sortData = _.isEmpty(data)
      ? []
      : _.orderBy(data, ['createTime'], ['desc']);
    const screenHeight = window.screen.height;
    let height = 92;
    if (screenHeight <= 1200) {
      height = 90;
    }
    return (
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <div style={{ overflow: 'hidden', flexGrow: '1', height: '100%' }}>
          <div>{this._createQueryHeader()}</div>
          <div
            style={{
              height: `${height}%`,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              className={`ag-theme-balham-dark ${className}`}
              style={{ flexGrow: 1 }}
            >
              <AgGridReact
                // properties
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={sortData}
                suppressAggFuncInHeader={true}
                deltaRowDataMode={false}
                enableRangeSelection={true}
                rowGroupPanelShow={this.state.rowGroupPanelShow}
                frameworkComponents={this.state.frameworkComponents}
                pivotPanelShow={this.state.pivotPanelShow}
                rowSelection="single"
                sideBar={this.state.sideBar}
                animateRows={true}
                // events
                onGridReady={this.onGridReady}
              />
            </div>
          </div>
          {this._createDetailDraw()}
        </div>
      </div>
    );
  }
}

export default ReportGrid;
