import React, { Component } from 'react';
import { Button } from 'antd';
import _ from 'lodash';
import AddOrUpdateDialog from 'features/research/components/pushVotes/AddOrUpdateDialog';
import CompanyScoreDetailDrawerOnlyForDetail from 'features/research/components/companyScore/drawer/CompanyScoreDetailDrawerOnlyForDetail';

export default class HyperlinkCompent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      value: this.props.value,
      hyperlinkName: this.props.hyperlinkName,
      callbackFields: this.props.callbackFields,
      detailCompentParams: {
        isOpen: false,
        data: null,
        op: 'DETAIL'
      },
      detailCompents: {
        stockPitchDialog: AddOrUpdateDialog,
        companyScoreDetail: CompanyScoreDetailDrawerOnlyForDetail
      }
    };
  }

  openLink = () => {
    const { value, data, callbackFields } = this.state;
    const { onClickCallback, useDetailCompent } = this.props;
    if (useDetailCompent) {
      this._openDetailDialog();
      return;
    }

    if (onClickCallback) {
      const params = _.pick(data, callbackFields);
      onClickCallback(params);
      this.setState({
        data: { ...data, isRead: true }
      });
    }
    window.open(value);
  };

  _openDetailDialog = async () => {
    const { getDetailDataFunc, useDetailCompent, data } = this.props;
    if (!useDetailCompent) return;
    const result = await getDetailDataFunc(data);
    if (!result) return;
    this.setState({
      detailCompentParams: {
        isOpen: true,
        op: 'DETAIL',
        data: result
      }
    });
  };

  _closeDetailDialog = () => {
    const { useDetailCompent } = this.props;
    if (!useDetailCompent) return;
    this.setState({
      detailCompentParams: {
        isOpen: false,
        op: 'DETAIL',
        data: null
      }
    });
  };

  _createDetailComponent = () => {
    const { detailCompents, detailCompentParams } = this.state;
    const {
      useDetailCompent,
      detailCompentName,
      dataKey,
      closeModalFuncName
    } = this.props;
    if (!(useDetailCompent && detailCompentParams.isOpen)) return;
    const DetailCompent = detailCompents[detailCompentName];
    const dKey = dataKey ? dataKey : 'data';
    const closeModalName = closeModalFuncName
      ? closeModalFuncName
      : 'closeModal';
    const params = {
      [dKey]: detailCompentParams,
      [closeModalName]: this._closeDetailDialog
    };
    if (!DetailCompent) return;
    return <DetailCompent {...this.props} {...params} />;
  };

  render() {
    const { value, hyperlinkName, data } = this.state;
    const { valueColorFunc } = this.props;
    let color = 'blue';
    let fontWeight = '';
    if (valueColorFunc) {
      color = valueColorFunc(data);
    }
    if (data && data.isRead) {
      fontWeight = 'bold';
    }
    return (
      value && (
        <div>
          <Button
            size="small"
            type="link"
            style={{ fontSize: '12px', color, fontWeight }}
            onClick={this.openLink}
          >
            {hyperlinkName ? hyperlinkName : value}
          </Button>
          {this._createDetailComponent()}
        </div>
      )
    );
  }
}
