import React, { Component } from 'react';
import client from '../../api/client';
import { AgGridReact } from 'ag-grid-react';
import { manageUserGridColumns, userProcessGridColumns } from './GridColumnMap';
import { Modal, Button, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import SplitPane from 'react-split-pane';
import moment from 'moment';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

const FormItem = Form.Item;
const { MonthPicker } = DatePicker;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};
const Option = Select.Option;

class ApplyAnalystPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadManageStatus: false,
      manageData: [],
      selectedManageData: null,
      loadAllProcessStatus: false,
      processData: [],
      editData: {},
      readOnly: false,
      analystList: [],
      errorMsgs: [],
      isModalVisible: false
    };
  }

  componentDidMount() {
    this.onSearchBtnClick();
    this.getAllAnalyst();
  }

  getAllAnalyst = () => {
    client
      .getAllAnalyst()
      .then(resp => {
        const data = resp.data;
        if (data) {
          this.setState({
            analystList: data
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getAllManageUser = () => {
    this.setState({
      loadManageStatus: true
    });
    client
      .selectAllManageUser()
      .then(resp => {
        const data = resp.data;
        if (data) {
          this.setState({
            manageData: data,
            loadManageStatus: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loadManageStatus: false
        });
        console.log(err);
      });
  };

  getAllUserManageRelation = () => {
    this.setState({
      loadAllProcessStatus: true
    });
    client
      .getAllUserManageRelation()
      .then(resp => {
        const data = resp.data;
        if (resp.code === '0') {
          this.setState({
            processData: data,
            loadAllProcessStatus: false,
            isModalVisible: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loadAllProcessStatus: false,
          isModalVisible: false
        });
        console.log(err);
      });
  };

  onSearchBtnClick = () => {
    this.getAllManageUser();
    this.getAllUserManageRelation();
  };

  onManageGridReady = params => {
    this.manageGridApi = params.api;
    this.manageColumnApi = params.columnApi;

    this.manageGridApi.sizeColumnsToFit();
  };

  onProcessGridReady = params => {
    this.processGridApi = params.api;
    this.processColumnApi = params.columnApi;

    this.processGridApi.sizeColumnsToFit();
  };

  addBtnClick = () => {
    const data = {
      beginDate: moment()
        .add('month', 1)
        .format('YYYY-MM'),
      endDate: '9999-12',
      op: 'ADD'
    };
    this.createModal('Add', data);
  };

  createModal = (title, data, readOnly) => {
    this.setState({
      editData: data,
      errorMsgs: [],
      readOnly,
      title,
      isModalVisible: true
    });
  };

  closeModal = () => {
    this.setState({
      errorMsgs: [],
      isModalVisible: false
    });
  };

  saveApply = () => {
    this.setState({
      errorMsgs: []
    });
    client
      .applyUserManageRelationProcess(this.state.editData)
      .then(resp => {
        if (resp && resp.code === '0') {
          this.onSearchBtnClick();
        } else {
          const message = resp ? resp.message : 'System error';
          this.setState({
            errorMsgs: [message]
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    return {
      visible: true
    };
  };

  onInputChange = ({ name, value }) => {
    const { editData } = this.state;
    const updateDate = {
      ...editData,
      [name]: value
    };
    this.setState({
      editData: updateDate
    });
  };

  createAddForm = () => {
    const {
      editData,
      readOnly,
      analystList,
      errorMsgs,
      isModalVisible,
      title
    } = this.state;
    const data = editData;
    return (
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={this.saveApply}
        onCancel={this.closeModal}
      >
        <Form layout="horizontal">
          <Row gutter={24}>
            <Col span={24}>
              <FormItem {...formItemLayout} label="Analyst" required>
                <Select
                  onChange={value => {
                    this.onInputChange({ name: 'englishName', value });
                  }}
                  value={data.englishName}
                  disabled={readOnly}
                >
                  {analystList.map(t => (
                    <Option value={t.englishName} key={t.englishName}>
                      {t.englishName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <FormItem {...formItemLayout} label="Apply Date Range" required>
                <MonthPicker
                  allowClear={false}
                  defaultValue={moment(data.beginDate, 'YYYY-MM')}
                  size="small"
                  onChange={(date, dateString) => {
                    this.onInputChange({
                      name: 'beginDate',
                      value: dateString
                    });
                  }}
                  disabled={readOnly}
                />
                <MonthPicker
                  allowClear={false}
                  defaultValue={moment(data.endDate, 'YYYY-MM')}
                  size="small"
                  onChange={(date, dateString) => {
                    this.onInputChange({
                      name: 'endDate',
                      value: dateString
                    });
                  }}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <FormItem {...formItemLayout} label="Remark">
                <Input
                  value={data.remark}
                  onChange={e => {
                    this.onInputChange({
                      name: 'applyRemark',
                      value: e.target.value
                    });
                  }}
                />
              </FormItem>
            </Col>
          </Row>
          {!_.isEmpty(errorMsgs) && <Message error list={errorMsgs} />}
        </Form>
      </Modal>
    );
  };

  applyDelayBtnClick = () => {
    const { selectedManageData } = this.state;
    if (selectedManageData) {
      const data = {
        englishName: selectedManageData['englishName'],
        userAccountId: selectedManageData['userAccountId'],
        originalBegin: moment(selectedManageData['startDate']).format(
          'YYYY-MM'
        ),
        beginDate: moment(selectedManageData['startDate']).format('YYYY-MM'),
        originalEnd: moment(selectedManageData['endDate']).format('YYYY-MM'),
        endDate: moment(selectedManageData['endDate']).format('YYYY-MM'),
        op: 'DELAY'
      };
      this.createModal('Apply Delay', data, true);
    } else {
      Modal.info({
        title: 'Info',
        content: (
          <div>
            <p>Please select 1 analyst data to operation!</p>
          </div>
        ),
        onOk() {}
      });
    }
  };

  removeBtnClick = () => {
    const { selectedManageData } = this.state;
    if (selectedManageData) {
      const data = {
        englishName: selectedManageData['englishName'],
        userAccountId: selectedManageData['userAccountId'],
        originalBegin: moment(selectedManageData['startDate']).format(
          'YYYY-MM'
        ),
        beginDate: moment(selectedManageData['startDate']).format('YYYY-MM'),
        originalEnd: moment(selectedManageData['endDate']).format('YYYY-MM'),
        endDate: moment(selectedManageData['endDate']).format('YYYY-MM'),
        op: 'REMOVE'
      };
      this.createModal('Remove', data, true);
    } else {
      Modal.info({
        title: 'Info',
        content: (
          <div>
            <p>Please select 1 analyst data to operation!</p>
          </div>
        ),
        onOk() {}
      });
    }
  };

  onSelectionChanged = () => {
    const selectedRows = this.manageGridApi.getSelectedRows();

    this.setState({
      selectedManageData: selectedRows[0]
    });
  };

  render() {
    const { manageData, processData } = this.state;
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            width: '100%',
            textAlign: 'right',
            marginBottom: '10px',
            marginTop: '5px'
          }}
        >
          <Button
            type="primary"
            size="small"
            onClick={this.onSearchBtnClick}
            style={{ marginRight: '10px' }}
          >
            Search
          </Button>
          {/* <Button
            type="primary"
            size="small"
            onClick={this.addBtnClick}
            style={{ marginRight: '10px' }}
          >
            Apply
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={this.applyDelayBtnClick}
            style={{ marginRight: '10px' }}
          >
            Apply Delay
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={this.removeBtnClick}
            style={{ marginRight: '10px' }}
          >
            Remove
          </Button> */}
        </div>
        <SplitPane
          split="vertical"
          defaultSize="40%"
          style={{ position: 'relative', height: '95%' }}
        >
          <div className={`ag-theme-balham-dark grid-wrapper`}>
            <AgGridReact
              // properties
              columnDefs={manageUserGridColumns}
              rowData={manageData}
              suppressAggFuncInHeader={true}
              animateRows={false}
              deltaRowDataMode={false}
              rowGroupPanelShow="always"
              rowSelection="single"
              suppressCellSelection={true}
              onGridReady={this.onManageGridReady}
              onSelectionChanged={this.onSelectionChanged}
            />
          </div>
          <div className={`ag-theme-balham-dark grid-wrapper`}>
            <AgGridReact
              // properties
              columnDefs={userProcessGridColumns}
              rowData={processData}
              suppressAggFuncInHeader={true}
              animateRows={false}
              deltaRowDataMode={false}
              rowGroupPanelShow="always"
              rowSelection="single"
              suppressCellSelection={true}
              onGridReady={this.onProcessGridReady}
            />
          </div>
        </SplitPane>
        {this.createAddForm()}
      </div>
    );
  }
}

export default ApplyAnalystPage;
