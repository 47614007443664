import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import client from '../../api/client';
import { Spin } from 'antd';
import { gcDataColumns } from '../GridColumn';
import _ from 'lodash';

class GcRateGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSettings: agGridUtils.createSettings({
        columnDefs: gcDataColumns,
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          resizable: true
          // suppressMenu: true
        }
      }),
      data: [],
      loadStatus: 'READY'
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({
      loadStatus: 'LOAD'
    });
    client
      .getGCRateList()
      .then(resp => {
        this.setState({
          data: resp.data,
          loadStatus: 'READY'
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadStatus: 'READY'
        });
      });
  };

  _createGrid = () => {
    const { gridSettings, data, loadStatus } = this.state;
    const sortedData = _.orderBy(
      data,
      ['perRate', 'market', 'broker'],
      ['asc', 'asc', 'asc']
    );
    return loadStatus !== 'READY' ? (
      <div style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}>
        <Spin />
      </div>
    ) : (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '90%' }}
      >
        <AgGridReact
          // properties
          rowData={sortedData}
          {...gridSettings}
          groupIncludeTotalFooter={false}
          getRowNodeId={param =>
            `${param.broker}_${param.market}_${param.offShore}`
          }
          // events
          onGridReady={this._gridReady}
        />
      </div>
    );
  };

  _gridReady = params => {
    this.gridApi = params.api;
  };

  render() {
    return <div className="treasuryDashboardWrapper">{this._createGrid()}</div>;
  }
}

export default GcRateGrid;
