import React, { Component } from 'react';
import {
  Header,
  Modal,
  Button,
  Form,
  Rating,
  Dropdown,
  Message
} from 'semantic-ui-react';
import OperationtMode from 'common/utils/OperationtMode';
import moment from 'moment';
import { DatePicker } from 'antd';
import 'moment/locale/zh-cn';
import Options from '../entities/Options';

const { MonthPicker } = DatePicker;

class BrokerDialog extends Component {
  onInputChange = ({ name, value }) => {
    this.props.brokerScoreChangeInput({
      name,
      value
    });

    if (name === 'correspondance') {
      let weight = 0;
      switch (value) {
        case 'Group Meeting':
          weight = 0.3;
          break;
        case 'Personal Meeting':
          weight = 0.46;
          break;
        case 'Phone Conference':
          weight = 0.16;
          break;
        case 'Sales Call':
          weight = 0.08;
          break;
        default:
          break;
      }
      this.props.brokerScoreChangeInput({
        name: 'weight',
        value: weight
      });
    }
  };

  onFormSubmit = () => {
    const { fields } = this.props.brokerControlModal;

    const params = {
      id: fields.id,
      user: fields.pm_name,
      entryDate:
        fields.entry_date ||
        moment(new Date(new Date().setMonth(new Date().getMonth() - 1))).format(
          'YYYY-MM'
        ),
      weight: fields.weight || 0,
      brokerName: fields.broker_name,
      correspondance: fields.correspondance,
      score: fields.score || 0,
      Team: fields.Team
    };

    this.props.submitBrokerScore(params);
  };

  render() {
    const {
      isOpened,
      mode,
      fields,
      serverErrMsg,
      submitStatus: status
    } = this.props.brokerControlModal;

    const { brokerNameOptions } = this.props;

    const isNewMode = mode === OperationtMode.NEW;
    const isDetailMode = mode === OperationtMode.DETAIL;
    const isUpdateMode = mode === OperationtMode.UPDATE;

    const isReadOnly = isDetailMode;

    let modalHeaderContent = 'Broker';
    if (isNewMode) {
      modalHeaderContent = 'New Broker';
    }

    if (isUpdateMode) {
      modalHeaderContent = 'Edit Broker';
    }

    const errorMsgs = [];
    if (serverErrMsg) {
      errorMsgs.push(serverErrMsg);
    }

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

            .ant-calendar-picker {
              width: 100%;
            }

            .ant-input {
              height: 100%;
            }

            .ui.selection.dropdown > .dropdown.icon {
              line-height: 100%;
            }

            .buttonDiv {
              display: flex;
              justify-content: flex-end;
            }
          `}
        </style>
        <Modal size="tiny" open={isOpened}>
          <Header content={modalHeaderContent} />
          <Modal.Content>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Voting Month</label>
                  <MonthPicker
                    value={
                      fields.entry_date
                        ? moment(fields.entry_date)
                        : moment(
                            new Date(
                              new Date().setMonth(new Date().getMonth() - 1)
                            )
                          )
                    }
                    format={'YYYY-MM'}
                    allowClear={false}
                    onChange={(date, dateString) =>
                      this.onInputChange({
                        name: 'entry_date',
                        value: dateString
                      })
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <label>Broker Name</label>
                  <Dropdown
                    options={brokerNameOptions}
                    placeholder="Choose Broker Name"
                    selection
                    fluid
                    value={fields.broker_name}
                    onChange={(e, { value }) => {
                      this.onInputChange({ name: 'broker_name', value });
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Correspondence</label>
                  <Dropdown
                    options={Options.correspondence}
                    placeholder="Choose Correspondence"
                    selection
                    fluid
                    value={fields.correspondance}
                    onChange={(e, { value }) => {
                      this.onInputChange({ name: 'correspondance', value });
                    }}
                  />
                </Form.Field>
                <Form.Input
                  fluid
                  label="Weight"
                  placeholder={isReadOnly ? '' : 'Enter Weight...'}
                  value={fields.weight}
                  readOnly={true}
                />
              </Form.Group>
              <Form.Field>
                <label>Score</label>
                <Rating
                  icon="star"
                  defaultRating={fields.score}
                  maxRating={5}
                  disabled={isReadOnly}
                  onRate={(e, { rating }) =>
                    this.onInputChange({ name: 'score', value: rating })
                  }
                />
              </Form.Field>

              <Message
                error
                header="Fix Below Errors"
                visible={errorMsgs.length > 0}
                list={errorMsgs}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {!isReadOnly &&
              {
                SUBMITTING: (
                  <Button secondary disabled loading content="Submitting..." />
                ),
                ERROR: (
                  <Button
                    secondary
                    onClick={this.onFormSubmit}
                    content="Fail - Retry?"
                  />
                ),
                READY: (
                  <Button
                    secondary
                    onClick={this.onFormSubmit}
                    content="Submit"
                  />
                )
              }[status]}
            <Button
              secondary
              content="Cancel"
              onClick={this.props.resetBrokerControlModal}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default BrokerDialog;
