import spected from 'spected';
import _ from 'lodash';

const _createSpec = p => {
  // predicates
  const notEmpty = value => !_.isNil(value);
  const positiveNumber = value => parseFloat(value) > 0;
  const positiveNumberOrZero = value => parseFloat(value) >= 0;

  // error messages
  const notEmptyMsg = field => `${field} should not be empty.`;
  const positiveNumberMsg = field => `${field} should be greated than 0.`;
  const positiveNumberOrZeroMsg = field =>
    `${field} should be equal or greated than 0.`;

  const checkCloseTradeQty = v => {
    return Math.abs(p.clsQty) >= v;
  };

  const closeTradeQtyRule = [
    [notEmpty, notEmptyMsg('Close Trade Qty')],
    [positiveNumberOrZero, positiveNumberOrZeroMsg('Close Trade Qty')],
    [
      checkCloseTradeQty,
      `No enough position qty (${p.clsQty}) could be closed.`
    ]
  ];

  const openTradeQtyRule = [
    [notEmpty, notEmptyMsg('Open Trade Qty')],
    [positiveNumberOrZero, positiveNumberOrZeroMsg('Open Trade Qty')]
  ];

  const openMultiplierRule = [
    [notEmpty, notEmptyMsg('Open Multiplier')],
    [positiveNumber, positiveNumberMsg('Open Multiplier')]
  ];

  return {
    closeTradeQty: closeTradeQtyRule,
    openTradeQty: openTradeQtyRule,
    openMultiplier: openMultiplierRule
  };
};

const _extractErrors = validationResult => {
  const FIRST_ERROR = 0;
  return Object.keys(validationResult).reduce((errors, field) => {
    return validationResult[field] !== true
      ? { ...errors, [field]: validationResult[field][FIRST_ERROR] }
      : errors;
  }, {});
};

const RolledPosValidator = {
  validate: p => {
    const spec = _createSpec(p);
    const validationResult = spected(spec, p);
    return _extractErrors(validationResult);
  }
};

export default RolledPosValidator;
