const ua = navigator.userAgent.toLowerCase();
const iosPhoneAgents = ['iphone', 'ipad', 'ipod']; // 所有可能是移动端设备的字段
const agents = ['android', 'linux', 'windows phone'];

export function isIphone() {
  for (let i = 0; i < iosPhoneAgents.length; i++) {
    if (ua.indexOf(iosPhoneAgents[i]) !== -1) {
      return true;
    }
  }
  return false;
}

export function isOtherPhone() {
  for (let i = 0; i < agents.length; i++) {
    if (ua.indexOf(agents[i]) !== -1) {
      return true;
    }
  }
  return false;
}

export function isNotPhone() {
  return !(isIphone() || isOtherPhone());
}
