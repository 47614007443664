export const LOAD_NOTIFICATION_LIST_SUCCESS = 'LOAD_NOTIFICATION_LIST_SUCCESS';
export const LOAD_NOTIFICATION_LIST_ERROR = 'LOAD_NOTIFICATION_LIST_ERROR';
export const INIT_LOAD_NOTIFICATION_STATUS = 'INIT_LOAD_NOTIFICATION_STATUS';
export const ADD_NOTIFICATION_TO_LIST = 'ADD_NOTIFICATION_TO_LIST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';
export const REMINDER_ME_LATER = 'REMINDER_ME_LATER';
export const REMINDER_ME_LATER_ALL = 'REMINDER_ME_LATER_ALL';
export const OPEN_OR_CLOSE_NOTIFICATION_MODAL =
  'OPEN_OR_CLOSE_NOTIFICATION_MODAL';
