import { connect } from 'react-redux';

import Logout from '../components/Logout';
import { logout } from '../authActions';

function mapStateToProps(state) {
  return {
    user: state.settings.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: user => {
      dispatch(logout(user));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
