import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { returnReportInfoColumns } from '../GridColumnMap';
import { AgGridReact } from 'ag-grid-react';
import { DatePicker, Spin } from 'antd';

class ReportInfoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: returnReportInfoColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  componentDidMount() {
    this.loadReportInfoList();
  }

  loadReportInfoList = () => {
    const { loadReturnReportList, returnReportQueryParams } = this.props;
    loadReturnReportList(returnReportQueryParams);
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
    this.onRowDataChanged();
  };

  onSelectionChanged = () => {
    const { changeReturnReportAttribution } = this.props;
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedNode = selectedNodes[0];
    changeReturnReportAttribution(selectedRows[0]);
    const data = this.selectedNode.data;
    if (data) {
      this.props.changeReturnQueryParamsInput({
        name: 'fundCode',
        value: data.fund
      });
      this.props.changeReturnQueryParamsInput({
        name: 'bookCode',
        value: data.book
      });
    }
  };

  dateToInputOnChange = (name, dateString) => {
    const { loadReturnReportList, returnReportQueryParams } = this.props;
    this.props.changeReturnQueryParamsInput({ name: name, value: dateString });
    const queryParams = {
      ...returnReportQueryParams,
      [name]: dateString
    };
    this.selectedNode = null;
    loadReturnReportList(queryParams);
  };

  onSwitchChange = () => {
    const { returnReportQueryParams, loadReturnReportList } = this.props;
    const dataMode = !returnReportQueryParams.dataMode;
    this.props.changeReturnQueryParamsInput({
      name: 'dataMode',
      value: dataMode
    });
    const queryParams = {
      ...returnReportQueryParams,
      dataMode: dataMode
    };
    loadReturnReportList(queryParams);
  };

  onRowDataChanged = () => {
    if (this.gridApi) {
      const nodes = this.gridApi.getRenderedNodes();
      const selectedNode = this.selectedNode ? this.selectedNode : nodes[0];
      if (!_.isEmpty(nodes)) {
        nodes.forEach(element => {
          const data = element.data;
          if (data) {
            const fundBook = data.fund + '_' + data.book;
            if (fundBook === selectedNode.id) {
              this.gridApi.selectNode(element, true);
            }
          }
        });
      }
      this.selectedNode = selectedNode;
    }
  };

  disabledDate = current => {
    if (current) {
      const day = moment(current).day();
      return day === 0 || day === 6;
    }
    return false;
  };

  getRowNodeId = data => {
    return data.fund + '_' + data.book;
  };

  render() {
    const {
      returnReportInfoLoadingStatus,
      returnReportList,
      returnReportQueryParams,
      className = 'grid-wrapper'
    } = this.props;
    const sortedRows = _.orderBy(
      returnReportList,
      ['fund', 'book'],
      ['asc', 'asc']
    );

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <DatePicker
            style={{ marginRight: '5px', width: '200px' }}
            allowClear={false}
            format={'YYYY-MM-DD'}
            value={moment(returnReportQueryParams.fromDate, 'YYYY-MM-DD')}
            size="small"
            onChange={(date, dateString) => {
              this.dateToInputOnChange('fromDate', dateString);
            }}
            disabledDate={this.disabledDate}
          />
          <DatePicker
            style={{ marginRight: '5px', width: '200px' }}
            allowClear={false}
            format={'YYYY-MM-DD'}
            value={moment(returnReportQueryParams.toDate, 'YYYY-MM-DD')}
            size="small"
            onChange={(date, dateString) => {
              this.dateToInputOnChange('toDate', dateString);
            }}
            disabledDate={this.disabledDate}
          />
          {/* <Switch
                        checked={returnReportQueryParams.dataMode}
                        checkedChildren="Gross"
                        unCheckedChildren="Aum"
                        defaultChecked
                        onChange={this.onSwitchChange}
                    /> */}
        </div>
        {returnReportInfoLoadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{ width: '100%' }}
          >
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              deltaRowDataMode={false}
              enableRangeSelection={true}
              //rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              sideBar={this.state.sideBar}
              // events
              onSelectionChanged={this.onSelectionChanged}
              onGridReady={this.onGridReady}
              getRowNodeId={this.getRowNodeId}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ReportInfoGrid;
