import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Button, Loader } from 'semantic-ui-react';
import 'ag-grid-enterprise';
import { DatePicker } from 'antd';
import RatingBarComponent from '../../../common/ui/renderers/agGrid/RatingBarComponent';
import moment from 'moment';
import _ from 'lodash';
import ReportShowDialog from './brokerScore/ReportShowDialog';

const { MonthPicker } = DatePicker;
class BroderScoreGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.brokerScoreGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      autoGroupColumnDef: {
        headerName: 'Voting Month',
        field: 'votingMonth',
        cellClass: 'keyword'
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      frameworkComponents: {
        ratingBarComponent: RatingBarComponent
      },
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      brokerScoreId: null,
      votingMonth: null
    };
  }

  componentDidMount() {
    this.loadBrokerScores();
    this.loadCorrespondenceList();
  }

  loadCorrespondenceList = dataString => {
    const { votingMonthToStr } = this.props.brokerQueryParams;
    const votingMonth = dataString ? dataString : votingMonthToStr;
    this.props.loadCorrespondenceList({ votingMonth });
  };

  onReloadBrokerBtnClicked = () => {
    this.loadBrokerScores();
  };

  loadBrokerScores = params => {
    const queryParams = params ? params : this.props.brokerQueryParams;
    this.props.loadBrokerScoreList(queryParams);
  };

  loadBrokerScoresBak = () => {
    this.props.loadBrokerScores(moment().format('YYYY-MM-DD'));
    this.setState({
      brokerScoreId: null
    });
  };

  onNewBrokerBtnClicked = () => {
    this.props.openBrokerScoresNewDialog();
    this.setState({
      brokerScoreId: null
    });
  };

  onBrokerDetailBtnClicked = () => {
    var votingMonth = this.state.votingMonth;
    if (!this.state.votingMonth) {
      votingMonth = moment()
        .subtract(7, 'days')
        .format('YYYY-MM');
    }
    this.props.loadBrokerScoreListByMonth(
      this.props.user['englishName'],
      votingMonth
    );
    this.props.openBrokerScoresDetailDialog(votingMonth);
  };

  onYearBrokerDetailBtnClicked = () => {
    const { brokerScoreYearControlModal } = this.props;
    this.props.queryBrokerScoreRankListByScore({
      votingYear: brokerScoreYearControlModal.votingYear
    });
    this.props.openYearBrokerScoresDetailDialog();
  };

  showDataRowItemUpdateDialog = () => {
    this.props.openBrokerScoresUpdateDialog(this.state.brokerScoreId);
    this.setState({
      brokerScoreId: null
    });
  };

  deleteDataRowItem = () => {
    const params = { scoreIdList: [this.state.brokerScoreId] };
    this.props.removeBrokerScore(params, this.props.brokerQueryParams);
    this.setState({
      brokerScoreId: null
    });
  };

  // 新增一条数据
  newBrokerDataRow() {
    const params = {
      id: '',
      user: '',
      entryDate: moment().format('YYYY-MM-DD'),
      weight: 0,
      brokerName: '',
      correspondance: '',
      score: 0,
      Team: ''
    };

    this.props.submitBrokerScore(params);
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedKeys = selectedRows.map(r => r.id);
    const selectedVotingKeys = selectedRows.map(r => r.votingMonth);

    this.setState({
      brokerScoreId: selectedKeys.length ? selectedKeys[0] : null,
      votingMonth: selectedVotingKeys.length ? selectedVotingKeys[0] : null
    });
  };

  onCellValueChanged = params => {
    const brokerData = params.data;
    const brokerParams = {
      id: brokerData.id,
      user: brokerData.pm_name,
      entryDate: brokerData.entry_date || moment().format('YYYY-MM-DD'),
      weight: brokerData.weight || 0,
      brokerName: brokerData.broker_name,
      correspondance: brokerData.correspondance,
      score: brokerData.score || 0,
      Team: brokerData.Team
    };

    this.props.submitBrokerScore(brokerParams);
  };

  dateFromInputOnChange = (date, dateString) => {
    this.props.changeBrokerQureyParams([
      { name: 'votingMonthFrom', value: date },
      { name: 'votingMonthFromStr', value: dateString }
    ]);
  };

  dateToInputOnChange = (date, dateString) => {
    const { brokerQueryParams } = this.props;
    this.props.changeBrokerQureyParams([
      { name: 'votingMonthTo', value: date },
      { name: 'votingMonthToStr', value: dateString }
    ]);
    const queryParams = {
      ...brokerQueryParams,
      votingMonthToStr: dateString
    };
    this.loadCorrespondenceList(dateString);
    this.loadBrokerScores(queryParams);
  };

  _getGridColumn = () => {
    const { weightMap } = this.props.brokerScoreControlModal;
    const { columnDefs } = this.state;
    const columns = columnDefs.map(r => ({ ...r }));
    if (weightMap) {
      _.map(weightMap, (value, key) => {
        columns.push({
          field: value.code,
          headerName: value.name,
          cellRenderer: 'ratingBarComponent',
          cellClass: 'number'
        });
      });
    }
    columns.push({
      field: 'pmName',
      headerName: 'PM Name',
      cellClass: 'non-number',
      enableRowGroup: true
    });
    return columns;
  };

  _initDetailData = brokerScoreList => {
    if (brokerScoreList) {
      brokerScoreList.forEach(item => {
        const detailList = item.detailList;
        detailList.forEach(ele => {
          const code = ele['code'];
          item[code] = ele['score'];
        });
      });
    }
  };

  _showReport = () => {
    this.setState({
      showReport: true
    });
  };

  closeReportDialog = () => {
    this.setState({
      showReport: false
    });
  };

  _createReportDialog = () => {
    const { showReport } = this.state;
    return (
      showReport && (
        <ReportShowDialog
          {...this.props}
          closeDialog={this.closeReportDialog}
        />
      )
    );
  };

  render() {
    const {
      brokerScores,
      className = 'grid-wrapper',
      isLoaded,
      brokerQueryParams,
      user
    } = this.props;
    const { englishName } = user || {};
    const sortedRows = _.orderBy(brokerScores, ['votingMonth'], ['desc']);
    this._initDetailData(sortedRows);
    return (
      <div className={className}>
        <div>
          <style>
            {`
            .filter {
              display: flex;
              justify-content: flex-end;
              padding: 1px;
              background-color: #1B1C1D;
              margin-bottom: 2px;
            }

            .filter .ui.selection.dropdown {
              border: 1px solid #54C8FF;
              margin-right: 5px;
              margin-top: 5px;
              margin-bottom: 5px;
            }

            #brokerScoreGrid .ant-picker {
              margin-left:10px;
              background-color:#FF9E28;
            }

          `}
          </style>

          <div
            id="brokerScoreGrid"
            style={{
              textAlign: 'right',
              backgroundColor: '#1B1C1D',
              marginBottom: '2px'
            }}
          >
            <MonthPicker
              placeholder={'date start'}
              allowClear={'false'}
              value={brokerQueryParams.votingMonthFrom}
              size="small"
              onChange={this.dateFromInputOnChange}
            />

            <MonthPicker
              style={{ marginRight: '5px' }}
              placeholder={'date end'}
              allowClear={'false'}
              value={brokerQueryParams.votingMonthTo}
              size="small"
              onChange={this.dateToInputOnChange}
            />

            <Button
              size="mini"
              content="Reload"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.onReloadBrokerBtnClicked}
            />

            <Button
              size="mini"
              content="Add/Edit"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.onBrokerDetailBtnClicked}
            />
            {/* <Button
              size="mini"
              content="Add"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.onNewBrokerBtnClicked}
            />

            <Button
              size="mini"
              content="Edit"
              color="green"
              disabled={!isLoaded || !this.state.brokerScoreId}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.showDataRowItemUpdateDialog}
            /> */}

            <Button
              size="mini"
              content="Delete"
              color="red"
              disabled={!isLoaded || !this.state.brokerScoreId}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.deleteDataRowItem}
            />
            {['Cane.Shen'].includes(englishName) && (
              <Button
                size="mini"
                content="Report"
                color="green"
                style={{ marginBottom: '4px', marginTop: '4px' }}
                onClick={this._showReport}
              />
            )}
          </div>
        </div>

        <div className={`ag-theme-balham-dark ${className}`}>
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              // properties
              columnDefs={this._getGridColumn()}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              frameworkComponents={this.state.frameworkComponents}
              context={this.state.context}
              sideBar={this.state.sideBar}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
              onCellValueChanged={this.onCellValueChanged}
            />
          )}
        </div>
        {this._createReportDialog()}
      </div>
    );
  }
}

export default BroderScoreGrid;
