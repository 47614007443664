import React, { Component } from 'react';
import { Button, Modal, Select } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import _ from 'lodash';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';

const Option = Select.Option;
export default class DefaultPushUserManageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      userList: [],
      selectedData: null,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 25,
        checkboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
        resizable: true,
        filter: true
        // suppressMenu: true
      },
      columnDefs: [
        {
          field: 'userName',
          headerName: 'User',
          cellClass: 'keyword',
          enableRowGroup: true,
          rowGroup: true,
          hide: true,
          width: 50
        },
        {
          field: 'defaultUser',
          headerName: 'Default User',
          cellClass: 'non-number',
          width: 100,
          aggFunc: this._contactFiledValue
        }
      ],
      autoGroupColumnDef: {
        cellClass: 'keyword',
        field: 'userName',
        headerName: 'User',
        width: 25
      },
      submitData: {
        userList: [],
        defaultUserList: [],
        op: 'CONFIG'
      },
      hotTableSettings: hotTableUtils.createSettings({
        columns: [
          {
            data: 'userName',
            headerName: 'User',
            readOnly: true
          },
          {
            data: 'defaultUser',
            headerName: 'Default',
            readOnly: true
          }
        ]
      })
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _contactFiledValue = params => {
    return _.join(params, ',');
  };

  _loadData = () => {
    Promise.all([
      client.getUserDefaultPushConfig(),
      client.getAllCanConfigUser()
    ])
      .then(([resp, reps1]) => {
        this.setState({
          data: resp.data,
          userList: reps1.data
        });
      })
      .catch(err => console.log(err));
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();

    this.setState({
      selectedData: selectedRows
    });
  };

  _createConfigGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const dt = data ? data : [];
    const showData = [...dt];
    return (
      <div className={className} style={{ height: '95%' }}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '100%' }}
        >
          <AgGridReact
            // properties
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.state.columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            rowData={showData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            getRowNodeId={data => data.id}
            rowSelection="multiple"
            onGridReady={this._onGridReady}
            onSelectionChanged={this.onSelectionChanged}
            groupSelectsChildren={true}
            groupSelectsFiltered={true}
            filter={true}
          />
        </div>
      </div>
    );
  };

  _onInputChange = (name, value) => {
    this.setState({
      submitData: {
        ...this.state.submitData,
        [name]: value
      }
    });
  };

  onOkBtnClick = () => {
    const { submitData } = this.state;
    client
      .addConfig(submitData)
      .then(resp => {
        this._loadData();
        this.setState({
          submitData: {
            userList: [],
            defaultUserList: [],
            op: 'CONFIG'
          }
        });
      })
      .catch(err => console.log(err));
  };

  _onAddBtnClick = () => {
    const { userList } = this.state;
    Modal.confirm({
      title: '',
      content: (
        <div>
          <Select
            onChange={data => {
              this._onInputChange('userList', data);
            }}
            mode="multiple"
            style={{ marginLeft: '5px', width: '300px' }}
            allowClear={true}
            placeholder={'Select User'}
            optionFilterProp="label"
          >
            {userList.map(t => (
              <Option
                value={t.userAccountId}
                key={t.userAccountId}
                label={t.userName}
              >
                {t.userName}
              </Option>
            ))}
          </Select>
          <Select
            onChange={data => {
              this._onInputChange('defaultUserList', data);
            }}
            mode="multiple"
            style={{ marginLeft: '5px', width: '300px', marginTop: '8px' }}
            allowClear={true}
            placeholder={'Select Releation User'}
            optionFilterProp="label"
          >
            {userList.map(t => (
              <Option
                value={t.userAccountId}
                key={t.userAccountId}
                label={t.userName}
              >
                {t.userName}
              </Option>
            ))}
          </Select>
        </div>
      ),
      onOk: this.onOkBtnClick
    });
  };

  onRemoveBtnClick = () => {
    const { selectedData } = this.state;
    if (_.isEmpty(selectedData)) return;

    const deleteIds = selectedData.map(r => r.id);
    client
      .removeConfigUser({ deleteIds })
      .then(resp => {
        this._loadData();
        this.setState({
          selectedData: []
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _onRemoveBtnClick = () => {
    const { selectedData } = this.state;
    if (_.isEmpty(selectedData)) return;
    Modal.confirm({
      title: '',
      content: (
        <div style={{ height: '400px' }}>
          <p>Are you sure want to remove follows?</p>
          <HotTable
            data={selectedData}
            {...this.state.hotTableSettings}
          ></HotTable>
        </div>
      ),
      onOk: this.onRemoveBtnClick
    });
  };

  _createOpBar = () => {
    return (
      <div style={{ textAlign: 'right', marginBottom: '3px' }}>
        <Button size="small" type="primary" onClick={this._onAddBtnClick}>
          Add
        </Button>
        <Button
          size="small"
          style={{ marginLeft: '5px' }}
          type="primary"
          onClick={this._onRemoveBtnClick}
        >
          Remove
        </Button>
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        title={`Push List`}
        width={1000}
        height={650}
        onCancel={closeModal}
        onOk={closeModal}
        bodyStyle={{ height: '600px', overflow: 'auto' }}
        closable={true}
        footer={[]}
      >
        {this._createOpBar()}
        {this._createConfigGrid()}
      </Modal>
    );
  }
}
