import {
  moneyFormatter,
  percentFormatter,
  nullFormatter
} from 'common/utils/valueFormatters';
import { percentStyle, sideStyleWithGroup } from 'common/utils/styleChooser';

export const riskGridColumns = [
  {
    field: 'englishName',
    headerName: 'Name',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    cellClass: 'keyword',
    pinned: 'left'
  },
  {
    field: 'aum',
    headerName: 'GAV',
    valueFormatter: moneyFormatter,
    cellClass: 'non-number'
  },
  {
    headerName: 'MTD',
    children: [
      {
        field: 'mtdAvgNames',
        headerName: 'Names',
        cellClass: 'number'
      },
      {
        field: 'mtdAvgGross',
        headerName: 'Gross',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'mtdReturn',
        headerName: 'Return',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'mtdReturnScale',
        headerName: 'Return Scaled',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'mtdAnnVol',
        headerName: 'AnnVol',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'mtdVolScaled',
        headerName: 'AnnVol Scaled',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'mtdTurnover',
        headerName: 'Turn Over',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      }
    ]
  },
  {
    headerName: 'YTD',
    children: [
      {
        field: 'ytdAvgNames',
        headerName: 'Names',
        cellClass: 'number'
      },
      {
        field: 'ytdAvgGross',
        headerName: 'Gross',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdReturn',
        headerName: 'Return',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdReturnScale',
        headerName: 'Return Scaled',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdDD',
        headerName: 'YTD DD',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdDDScale',
        headerName: 'YTD DD Scaled',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'maxDrawdown',
        headerName: 'MaxDD',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'maxDrawdownScaled',
        headerName: 'MaxDD Scaled',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdAnnVol',
        headerName: 'AnnVol',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdVolScaled',
        headerName: 'AnnVol Scaled',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'ytdTurnover',
        headerName: 'Turn Over',
        valueFormatter: percentFormatter,
        cellClass: 'non-number',
        cellClassRules: percentStyle()
      },
      {
        field: 'netPct',
        headerName: 'Net',
        cellClass: 'number',
        valueFormatter: percentFormatter,
        enableValue: true,
        cellClassRules: percentStyle()
      },
      {
        field: 'top5WinPercent',
        headerName: 'Top 5 win/Total win Pnl',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        cellClass: 'number',
        valueFormatter: percentFormatter,
        enableValue: true,
        cellClassRules: percentStyle()
      },
      {
        field: 'top5LossPercent',
        headerName: 'Top 5 loss/Total loss Pnl',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        cellClass: 'number',
        valueFormatter: percentFormatter,
        enableValue: true,
        cellClassRules: percentStyle()
      },
      {
        field: 'top10WinPercent',
        headerName: 'Top 10 win/Total win Pnl',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        cellClass: 'number',
        valueFormatter: percentFormatter,
        enableValue: true,
        cellClassRules: percentStyle()
      },
      {
        field: 'top10LossPercent',
        headerName: 'Top 10 loss/Total loss Pnl',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        cellClass: 'number',
        valueFormatter: percentFormatter,
        enableValue: true,
        cellClassRules: percentStyle()
      }
    ]
  },
  {
    field: 'lastYearReturn',
    headerName: 'Last Year Return',
    valueFormatter: percentFormatter,
    cellClass: 'non-number',
    cellClassRules: percentStyle()
  }
];

export const collegeTradeGridColumns = [
  {
    field: 'fundCode',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'bookCode',
    headerName: 'Book',
    enableRowGroup: true,
    cellClass: 'non-number',
    lockPinned: true,
    valueFormatter: nullFormatter
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'securityCode',
    headerName: 'Security',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'tradeDate',
    headerName: 'Trade Date',
    enableRowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'side',
    headerName: 'Side',
    enableRowGroup: true,
    cellClass: params =>
      ['BUY', 'COVR'].includes(params.value) ? 'long' : 'short',
    width: 60
  },
  {
    field: 'qtyPct',
    headerName: 'AUM %',
    // cellRenderer: 'agAnimateShowChangeCellRenderer',
    valueFormatter: percentFormatter,
    cellClassRules: sideStyleWithGroup(),
    width: 60
  },
  {
    field: 'filledPct',
    headerName: 'Filled %',
    cellRenderer: 'percentBarComponent',
    cellClass: 'number',
    width: 60
  },
  {
    field: 'pmCode',
    headerName: 'PM Name',
    enableRowGroup: true,
    rowGroup: true,
    cellClass: 'keyword',
    valueFormatter: nullFormatter
  },
  {
    field: 'complianceStatus',
    headerName: 'Cpl Status',
    enableRowGroup: true,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        FAIL: 'crimson',
        SKIP: '#f50',
        PASS: '#87d068',
        WARN: '#eb9234'
      }
    },
    valueFormatter: nullFormatter,
    width: 60
  },
  {
    field: 'complianceError',
    headerName: 'Cpl Error',
    cellClass: 'comment',
    valueFormatter: nullFormatter,
    width: 300
  },
  {
    field: 'pmRemark',
    headerName: 'Remark',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  },
  {
    field: 'pmReason',
    headerName: 'Reason',
    enableRowGroup: true,
    cellClass: 'comment',
    valueFormatter: nullFormatter
  }
];
