import React, { Component } from 'react';
import { Modal } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import _ from 'lodash';

export default class PushLogDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columnDefs: [
        {
          field: 'createTime',
          headerName: 'Date',
          cellClass: 'non-number',
          width: 100
        },
        {
          field: 'notifyResult',
          headerName: 'Result',
          cellClass: 'non-number',
          width: 30,
          valueFormatter: params => {
            if (params.data) {
              const data = params.data;
              let formatValue = data.notifyResult;
              if (formatValue === '0') {
                formatValue = 'SUCCESS';
              } else if (formatValue === '999') {
                formatValue = 'FAIL';
              }
              return formatValue;
            }
          }
        },
        {
          field: 'notifyReceiver',
          headerName: 'Receiver',
          cellClass: 'non-number',
          width: 150
        }
      ]
    };
  }

  componentDidMount() {
    this._loadHist();
  }

  _loadHist = () => {
    const { id } = this.props.data;
    client
      .getHistLog({ id })
      .then(resp => {
        this.setState({
          data: resp.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createHistLogGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const sortedData = _.orderBy(data, ['createTime'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={sortedData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            getRowNodeId={data => data.id}
            rowSelection="single"
            onGridReady={this._onGridReady}
          />
        </div>
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        title={`Push Logs`}
        width={750}
        height={600}
        onCancel={closeModal}
        onOk={closeModal}
        bodyStyle={{ height: '550px', overflow: 'auto' }}
        closable={true}
      >
        {this._createHistLogGrid()}
      </Modal>
    );
  }
}
