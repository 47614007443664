import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import PercentBarComponent from '../../../common/ui/renderers/agGrid/PercentBarComponent';
import TableTooltipComponent from '../../../common/ui/renderers/agGrid/TableTooltipComponent';
import PortfolioTabTooltipComponent from './PortfolioTabTooltipComponent';
import ResearchInfoToolTipComponent from './ResearchInfoToolTipComponent';
import StateSynchronizer from '../../../common/utils/StateSynchronizer';
import _ from 'lodash';

class PositionGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.posGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 25,
        sortable: true,
        filter: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number'
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        tableTooltipComponent: TableTooltipComponent,
        portfolioTabTooltipComponent: PortfolioTabTooltipComponent,
        researchInfoToolTipComponent: ResearchInfoToolTipComponent
      },
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      getContextMenuItems: params => {
        // const defaultItems = params.defaultItems.slice(0);
        if (params.node == null) return [];

        const row = params.node.data;
        return props.getContextMenuItems ? props.getContextMenuItems(row) : [];
      }
    };
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'pos-grid-col-state';
    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  onCellContextMenu = evt => {
    if (evt.node && !evt.node.isSelected()) {
      this.gridApi.deselectAll();
      evt.node.setSelected(true);
    }
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();

    if (selectedRows && selectedRows.length === 1) {
      this.props.onPositionSelected(selectedRows[0]);
    }
  };

  _calRsrchInfo = r => {
    const { userAccountId } = this.props.user || {};
    const rsrchInfo = r.rsrchInfo;
    if (!_.isEmpty(rsrchInfo)) {
      const {
        stockNews = [],
        companyScores = [],
        stockConfs = [],
        stockPitches = []
      } = rsrchInfo;

      stockNews.forEach(e => {
        e.isRead = e && e.UserIds && e.UserIds.includes(userAccountId);
        e.key = e.Id;
      });
      rsrchInfo.stockConfs = _.isEmpty(stockConfs)
        ? []
        : _.orderBy(
            stockConfs.map(e => {
              return {
                ...e,
                key: e.id,
                ticker: r.ticker,
                oldUrl: e.url,
                uri: `/show/${e.id}_${this.generateRandomString(32)}}`,
                isRead: e && e.userIds && e.userIds.includes(userAccountId)
              };
            }),
            ['dt'],
            ['desc']
          );

      r.stockNewsNum = `${stockNews.length}_${stockNews.filter(
        r => r.isRead
      )}_${stockConfs.length}_${stockConfs.filter(r => r.isRead)}`;
      r.companyScoreNums = `${companyScores.length}_${stockPitches.length}`;
    }
  };

  generateRandomString = length => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  render() {
    const { positions, className = 'grid-wrapper' } = this.props;
    const positionData = positions.map(r => {
      const absNotnlMktValBookPct = r.notnlMktValBookPct
        ? Math.abs(r.notnlMktValBookPct)
        : r.notnlMktValBookPct;
      this._calRsrchInfo(r);
      const stockPitch = r.suggestedSize ? r.suggestedSize : {};
      return {
        ...r,
        absNotnlMktValBookPct,
        suggestedSize: stockPitch.suggestedSize,
        stockPitches: _.isEmpty(stockPitch) ? [] : stockPitch.stockPitches
      };
    });

    return (
      <div
        className={`ag-theme-balham-dark ${className}`}
        style={{
          overflow: 'hidden',
          display: 'flex',
          flexGrow: '1',
          flexDirection: 'column'
        }}
      >
        <AgGridReact
          // properties
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={positionData}
          suppressAggFuncInHeader={true}
          animateRows={true}
          deltaRowDataMode={true}
          enableRangeSelection={true}
          getRowNodeId={this.getRowNodeId}
          rowGroupPanelShow={this.state.rowGroupPanelShow}
          pivotPanelShow={this.state.pivotPanelShow}
          getContextMenuItems={this.state.getContextMenuItems}
          rowSelection="single"
          pivotRowTotals="after"
          groupIncludeTotalFooter={true}
          frameworkComponents={this.state.frameworkComponents}
          sideBar={this.state.sideBar}
          // events
          onSelectionChanged={this.onSelectionChanged}
          onGridReady={this.onGridReady}
          onCellContextMenu={this.onCellContextMenu}
        />
      </div>
    );
  }
}

export default PositionGrid;
