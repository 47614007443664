import {
  moneyFormatter,
  threeDPFormatter,
  dateFormatterWithNotGroup
} from 'common/utils/valueFormatters';
import { numberStyle } from 'common/utils/styleChooser';
import _ from 'lodash';
import agGridUtils from 'common/ui/agGridUtils';
import numeral from 'numeral';

export const marginReportGridColumns = [
  {
    field: 'custodian',
    headerName: 'Custodian',
    enableRowGroup: true,
    cellClass: 'non-number',
    rowGroup: true,
    hide: true,
    width: 50
  },
  {
    field: 'fund',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'keyword',
    rowGroup: true,
    hide: true,
    width: 120
  },
  {
    field: 'assetClass',
    headerName: 'Asset Class',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    width: 60
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    width: 80
  },
  {
    field: 'tickerName',
    headerName: 'Name',
    enableRowGroup: true,
    width: 150
  },
  {
    field: 'localCcy',
    headerName: 'Local Ccy',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'localExp',
    headerName: 'Local Exp',
    width: 60,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle()
  },
  {
    field: 'baseCcy',
    headerName: 'Base Ccy',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'baseExp',
    headerName: 'Base Exp(Net)',
    cellClassRules: numberStyle(),
    width: 80,
    valueGetter: params => {
      const { group, childrenAfterFilter, key, leafGroup, data } = params.node;
      if (group && childrenAfterFilter) {
        const allFilterNodes = [];
        if (!leafGroup) {
          const getAllFilterChildren = childrenAfterFilterData => {
            childrenAfterFilterData.forEach(e => {
              const { leafGroup, childrenAfterFilter } = e;
              if (!leafGroup) {
                getAllFilterChildren(childrenAfterFilter);
              } else {
                allFilterNodes.push(...childrenAfterFilter);
              }
            });
          };
          getAllFilterChildren(childrenAfterFilter);
        } else {
          allFilterNodes.push(...childrenAfterFilter);
        }

        let sum = 0;
        allFilterNodes.forEach(r => {
          const { assetClass, baseExp } = r.data;
          if (assetClass !== 'CCY' || key === 'CCY') {
            sum += baseExp;
          }
        });
        return _.round(sum, 0);
      } else {
        return data['baseExp'];
      }
    },
    valueFormatter: params => {
      if (Math.abs(params.value) < 0.01) return '0';
      return numeral(params.value).format('0,0');
    }
    // aggFunc: (params, a, b) => {
    //   let sum = 0;
    //   //params.values.forEach((value) => (sum += value));
    //   return sum;
    // },
  },
  {
    field: 'baseExpGross',
    headerName: 'Base Exp(Gross)',
    cellClassRules: numberStyle(),
    width: 80,
    valueGetter: params => {
      const { group, childrenAfterFilter, key, leafGroup, data } = params.node;
      if (group && childrenAfterFilter) {
        const allFilterNodes = [];
        if (!leafGroup) {
          const getAllFilterChildren = childrenAfterFilterData => {
            childrenAfterFilterData.forEach(e => {
              const { leafGroup, childrenAfterFilter } = e;
              if (!leafGroup) {
                getAllFilterChildren(childrenAfterFilter);
              } else {
                allFilterNodes.push(...childrenAfterFilter);
              }
            });
          };
          getAllFilterChildren(childrenAfterFilter);
        } else {
          allFilterNodes.push(...childrenAfterFilter);
        }

        let sum = 0;
        allFilterNodes.forEach(r => {
          const { assetClass, baseExpGross } = r.data;
          if (assetClass !== 'CCY' || key === 'CCY') {
            sum += baseExpGross;
          }
        });
        return _.round(sum, 0);
      } else {
        return data['baseExpGross'];
      }
    },
    valueFormatter: params => {
      if (Math.abs(params.value) < 0.01) return '0';
      return numeral(params.value).format('0,0');
    }
  },
  {
    field: 'qty',
    headerName: 'Qty',
    valueFormatter: moneyFormatter,
    enableRowGroup: true,
    width: 60,
    cellClassRules: numberStyle()
  },
  {
    field: 'price',
    headerName: 'Price',
    enableRowGroup: true,
    width: 70,
    hide: true
  },
  {
    field: 'baseRequirement',
    headerName: 'Requirement',
    aggFunc: 'sum',
    cellClassRules: numberStyle(),
    valueFormatter: moneyFormatter,
    width: 60
  },
  {
    field: 'reqPercent',
    headerName: 'Req %',
    valueFormatter: params => {
      let value = params.value;
      if (
        agGridUtils.isNonAggColInGroupRow(params) &&
        params.node &&
        params.node.aggData
      ) {
        const { baseRequirement } = params.node.aggData;
        const { group, childrenAfterFilter, key, leafGroup } = params.node;
        let sum = 0;
        if (group && childrenAfterFilter) {
          const allFilterNodes = [];
          if (!leafGroup) {
            const getAllFilterChildren = childrenAfterFilterData => {
              childrenAfterFilterData.forEach(e => {
                const { leafGroup, childrenAfterFilter } = e;
                if (!leafGroup) {
                  getAllFilterChildren(childrenAfterFilter);
                } else {
                  allFilterNodes.push(...childrenAfterFilter);
                }
              });
            };
            getAllFilterChildren(childrenAfterFilter);
          } else {
            allFilterNodes.push(...childrenAfterFilter);
          }

          allFilterNodes.forEach(e => {
            const { assetClass, baseExpGross, baseCcy, localCcy } = e.data;
            if (
              assetClass !== 'CCY' ||
              (key === 'CCY' && !(baseCcy === 'USD' && localCcy === 'USD'))
            ) {
              sum += baseExpGross;
            }
          });
          sum = _.round(sum, 0);
        }
        value = sum === 0 ? null : _.round(baseRequirement / sum, 4);
      }
      if (value === undefined || value === null) return 'N.A.';
      if (Math.abs(value) < 0.0001) return '0%';
      return numeral(value).format('0.00%');
    },
    cellClass: 'number-positive',
    width: 70
  }
];

export const commissionGridColumns = [
  {
    field: '',
    headerName: 'Expense',
    children: [
      {
        field: 'Expense-Commission',
        headerName: 'Commission',
        cellClass: 'number',
        valueFormatter: moneyFormatter,
        cellClassRules: numberStyle()
      },
      {
        headerName: 'Swap',
        children: [
          {
            field: 'Expense-Long Swap Benchmark',
            headerName: 'L-Swap Benchmark',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-Long Swap Spread',
            headerName: 'L-Swap Spread',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-FFS Benchmark',
            headerName: 'FFS Benchmark',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-FFS Spread',
            headerName: 'FFS Spread',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-Swap Stock Loan',
            headerName: 'Swap Stock Loan',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-CFD INT OLD',
            headerName: 'CFD INT OLD',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-SWAP',
            headerName: 'Total Swap',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          }
        ]
      },
      {
        field: 'Expense-Cash stock Loan',
        headerName: 'Cash Stock Loan',
        cellClass: 'number',
        valueFormatter: moneyFormatter,
        cellClassRules: numberStyle()
      },
      {
        field: 'Expense-All',
        headerName: 'All',
        cellClass: 'number',
        valueFormatter: moneyFormatter,
        cellClassRules: numberStyle()
      }
    ]
  },
  {
    field: '',
    headerName: 'Income',
    children: [
      {
        headerName: 'Interest',
        children: [
          {
            field: 'Income-Cash Interest',
            headerName: 'Cash Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-MMF Interest',
            headerName: 'MMF Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-Short Sell Interest',
            headerName: 'S-Sell Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-Total Interest',
            headerName: 'Total Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          }
        ]
      },
      {
        headerName: 'Swap',
        children: [
          {
            field: 'Income-Short Benchmark Rebate',
            headerName: 'S-Benchmark Rebate',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-CFD INT OLD',
            headerName: 'CFD INT OLD',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-SWAP',
            headerName: 'Total Swap',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          }
        ]
      },
      {
        field: 'Income-All',
        headerName: 'All',
        valueFormatter: moneyFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      }
    ]
  },
  {
    headerName: 'All',
    cellClassRules: numberStyle(),
    children: [
      {
        field: 'All-',
        headerName: '',
        valueFormatter: moneyFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      }
    ]
  }
];

export const commissionRateGridColumns = [
  {
    field: 'ruleId',
    headerName: 'Rule Id',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'tradeChargeTypeCode',
    headerName: 'Charge Type',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'counterpartyCode',
    headerName: 'Counterparty',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'giveInCptyCode',
    headerName: 'Give In Cpty',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'clearingHouseCode',
    headerName: 'Clearing House',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'tradeTypeCode',
    headerName: 'Trade Type',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'securityId',
    headerName: 'Security',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'ulySecurityId',
    headerName: 'Uly Security',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'instClassCode',
    headerName: 'InstClass',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'ulyInstClassCode',
    headerName: 'Uly InstClass',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'marketCode',
    headerName: 'Market',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'mktCountryCode',
    headerName: 'Mkt Country',
    cellClass: 'non-number',
    rowGroup: true,
    hide: true,
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'mktTypeCode',
    headerName: 'Mkt Type',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'settCcyCode',
    headerName: 'Sett Ccy',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'settCondCode',
    headerName: 'Sett Cond',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'buySellIndCode',
    headerName: 'BuySell Ind',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'buySellReasonCode',
    headerName: 'BuySell Reason',
    cellClass: 'non-number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'commTypeCode',
    headerName: 'CommType',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'reasonCode',
    headerName: 'Reason',
    cellClass: 'non-number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'trader',
    headerName: 'Trader',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'fundBookCode',
    headerName: 'FundBook',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'grossNetIndCode',
    headerName: 'GrossNetInd',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'chrgCcyCode',
    headerName: 'Chrg Ccy',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'directionIndCode',
    headerName: 'DirectionInd',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'statusIndCode',
    headerName: 'StatusInd',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'priority',
    headerName: 'Priority',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'lowValue',
    headerName: 'Low Value',
    cellClass: 'number',
    width: 50
  },
  {
    field: 'upValue',
    headerName: 'Up Value',
    cellClass: 'number',
    width: 50
  },
  {
    field: 'tariffTypeCode',
    headerName: 'Tariff Type',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'tariff',
    headerName: 'Tariff',
    cellClass: 'number',
    valueFormatter: threeDPFormatter,
    width: 50
  },
  {
    field: 'roundMethodCode',
    headerName: 'Round Method',
    cellClass: 'non-number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'roundMethodCode',
    headerName: 'Round Method',
    cellClass: 'non-number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      if (params.value) {
        return params.value;
      }
      return '*';
    },
    width: 50
  },
  {
    field: 'roundPrecision',
    headerName: 'Round Precision',
    cellClass: 'number',
    width: 50
  },
  {
    field: 'comment',
    headerName: 'Comment',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'priceBiggerThan',
    headerName: 'Price Bigger Than',
    cellClass: 'number',
    width: 50
  }
];

export const finDataGridColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    cellClass: 'keyword',
    width: 100,
    enableRowGroup: true
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    cellClass: 'non-number',
    width: 50,
    enableRowGroup: true
  },
  {
    field: 'marketCountry',
    headerName: 'Market Country',
    cellClass: 'non-number',
    width: 50,
    enableRowGroup: true
  },
  {
    field: 'swapType',
    headerName: 'Swap Type',
    cellClass: 'non-number',
    width: 40
  },
  {
    field: 'swapCcy',
    headerName: 'SwapCcy',
    cellClass: 'non-number',
    width: 40
  },
  // {
  //   field: 'side',
  //   headerName: 'Side',
  //   cellClass: 'non-number',
  //   width: 40
  // },
  {
    field: 'spread',
    headerName: 'Spread',
    cellClass: 'number',
    width: 50
  },
  {
    field: 'benchmarkDesc',
    headerName: 'Benchmark',
    cellClass: 'non-number',
    width: 80
  },
  {
    field: 'benchmarkTicker',
    headerName: 'Ticker(B)',
    cellClass: 'non-number',
    width: 70
  },
  {
    field: 'dayCount',
    headerName: 'dayCount',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'benchmarkNotionalBasis',
    headerName: 'NotionalBasis(B)',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'spreadNotionalBasis',
    headerName: 'NotionalBasis(S)',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'resetSchedule',
    headerName: 'Reset Schedule',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'initResetDate',
    headerName: 'Init Reset Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatterWithNotGroup,
    width: 70
  },
  {
    field: 'resetDate',
    headerName: 'Reset Date',
    cellClass: 'non-number',
    width: 40
  },
  {
    field: 'resetDateType',
    headerName: 'Reset DateType',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'resetSettleDate',
    headerName: 'Reset Settle Date',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'resetSettleDateType',
    headerName: 'Reset Settle Date Type',
    cellClass: 'non-number',
    width: 50
  },
  {
    field: 'openUnwind',
    headerName: 'Open Unwind',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'holidayProcess',
    headerName: 'Holiday Process',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'comment',
    headerName: 'Comment',
    cellClass: 'non-number',
    width: 50
  }
];

export const gcDataColumns = [
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'keyword',
    width: 150,
    enableRowGroup: true
  },
  {
    field: 'market',
    headerName: 'Market',
    cellClass: 'keyword',
    width: 150,
    enableRowGroup: true,
    rowGroup: true
  },
  {
    field: 'offShore',
    headerName: 'Off Shore',
    cellClass: 'keyword',
    valueFormatter: params => {
      if (params.data) {
        const data = params.value;
        let formatValue;
        if (data === 0) {
          formatValue = 'ONSHORE';
        } else if (data === 1) {
          formatValue = 'OFFSHORE';
        } else if (data === 2) {
          formatValue = 'ALL';
        } else {
          formatValue = 'OFFSHORE';
        }
        return formatValue;
      }
    },
    width: 150
  },
  {
    field: 'perRate',
    headerName: '70Rate(%)',
    cellClass: 'number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      return `${numeral(params.value).format('0.00')}%`;
    },
    width: 150
  },
  {
    field: 'avgRate',
    headerName: 'Avg Rate(%)',
    cellClass: 'number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      return `${numeral(params.value).format('0.00')}%`;
    },
    width: 150
  },
  {
    field: 'midRate',
    headerName: 'Mid Rate(%)',
    cellClass: 'number',
    valueFormatter: params => {
      if (agGridUtils.isNonAggColInGroupRow(params)) return params.value;
      return `${numeral(params.value).format('0.00')}%`;
    },
    width: 150
  }
];

export const onShoreMarginGridColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    enableRowGroup: true,
    cellClass: 'keyword',
    rowGroup: true,
    hide: true,
    width: 60
  },
  {
    field: 'custodian',
    headerName: 'Custodian',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 60
  },
  {
    field: 'accountBroker',
    headerName: 'Account Broker',
    enableRowGroup: true,
    width: 120
  },
  {
    field: 'mkt',
    headerName: 'MKT',
    enableRowGroup: true,
    width: 40
  },
  {
    field: 'longNotional',
    headerName: 'Long Notional',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  },
  {
    field: 'shortNotional',
    headerName: 'Short Notional',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  },
  {
    field: 'notional',
    headerName: 'Notional',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  }
];
