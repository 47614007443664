export const LOAD_REPORT_LIST_SUCCESS = 'LOAD_REPORT_LIST_SUCCESS';
export const LOAD_REPORT_LIST_FAILURE = 'LOAD_REPORT_LIST_FAILURE';

export const LOAD_REPORT_ATTRIBUTION_SUCCESS =
  'LOAD_REPORT_ATTRIBUTION_SUCCESS';
export const LOAD_REPORT_ATTRIBUTION_FAILURE = 'LOAD_SHORT_INTEREST_FAILURE';

export const LOAD_FACTOR_ASSET_SUCCESS = 'LOAD_FACTOR_ASSET_SUCCESS';
export const LOAD_FACTOR_ASSET_FAILURE = 'LOAD_FACTOR_ASSET_FAILURE';

export const CHANGE_RISK_QUERY_PARAMS_INPUT = 'CHANGE_RISK_QUERY_PARAMS_INPUT';
export const CHANGE_RISK_SELECTED_ITEM = 'CHANGE_RISK_SELECTED_ITEM';

export const LOAD_RETURN_REPORT_LIST_SUCCESS =
  'LOAD_RETURN_REPORT_LIST_SUCCESS';
export const LOAD_RETURN_REPORT_LIST_FAILURE =
  'LOAD_RETURN_REPORT_LIST_FAILURE';

export const CHANGE_RETURN_REPORT_ATTRIBUTION =
  'CHANGE_RETURN_REPORT_ATTRIBUTION';

export const CHANGE_RETURN_QUERY_PARAMS_INPUT =
  'CHANGE_RETURN_QUERY_PARAMS_INPUT';

export const LOAD_RETURN_FACTOR_ASSET_SUCCESS =
  'LOAD_RETURN_FACTOR_ASSET_SUCCESS';
export const LOAD_RETURN_FACTOR_ASSET_FAILURE =
  'LOAD_RETURN_FACTOR_ASSET_FAILURE';
export const CHANGE_RETURN_SELECTED_ITEM = 'CHANGE_RETURN_SELECTED_ITEM';

export const INIT_RISK_REPORT_LOAD_STATUS = 'INIT_RISK_REPORT_LOAD_STATUS';
export const INIT_RISK_FACTOR_LOAD_STATUS = 'INIT_RISK_FACTOR_LOAD_STATUS';
export const INIT_RISK_ASSET_LOAD_STATUS = 'INIT_RISK_ASSET_LOAD_STATUS';
export const INIT_RETURN_REPORT_LOAD_STATUS = 'INIT_RETURN_REPORT_LOAD_STATUS';
export const INIT_RETURN_FACTOR_LOAD_STATUS = 'INIT_RETURN_FACTOR_LOAD_STATUS';
export const INIT_RETURN_ASSET_LOAD_STATUS = 'INIT_RETURN_ASSET_LOAD_STATUS';
