const defaultRoutes = [
  {
    code: 'portfolio',
    path: '/portfolio',
    exact: false,
    label: 'Portfolio',
    icon: 'line graph',
    visible: true
  },
  {
    code: 'order',
    path: '/order',
    exact: false,
    label: 'Trades',
    icon: 'credit card',
    visible: true
  },
  {
    code: 'research',
    path: '/research',
    exact: false,
    label: 'Research',
    icon: 'wpforms',
    visible: true
  },
  {
    code: 'risk',
    path: '/risk',
    exact: false,
    label: 'Risk',
    icon: 'calendar check',
    visible: true
  },
  {
    code: 'oms',
    path: '/oms',
    exact: false,
    label: 'OMS',
    icon: 'tasks',
    visible: true
  },
  {
    code: 'treasury',
    path: '/treasury',
    exact: false,
    label: 'Treasury',
    icon: 'dollar',
    visible: true
  }
  // {
  //   code: 't0',
  //   path: '/t0',
  //   exact: false,
  //   label: 'T0',
  //   icon: 'globe',
  //   visible: true
  // },
  // {
  //   code: 'fl',
  //   path: '/fl',
  //   exact: false,
  //   label: 'FL',
  //   icon: 'location arrow',
  //   visible: true
  // }
];

export default function configRoutes(routes = []) {
  return defaultRoutes
    .map(dr => {
      const matchedRoute = routes.find(r => r.code === dr.code);
      if (!matchedRoute) return null;

      return {
        ...dr,
        ...matchedRoute
      };
    })
    .filter(Boolean);
}
