import React, { PureComponent } from 'react';
import { Header, Modal, Button, Form } from 'semantic-ui-react';
import { DIALOG_REJECT_ORDERS } from '../../omsConstants';
import client from '../../api/client';

class RejectOrdersDialog extends PureComponent {
  state = {
    fields: {
      traderFeedback: ''
    },
    submitStatus: 'READY'
  };

  onInputChange = ({ name, value }) => {
    this.setState({
      fields: {
        [name]: value
      }
    });
  };

  _rejectOrders = () => {
    const { user } = this.props.settings;
    const { selectedOrders, submitDialogSuccess } = this.props;
    const { traderFeedback } = this.state.fields;

    const cmds = selectedOrders
      .filter(o => !['REJECT', 'REJECTING'].includes(o.traderStatus))
      .map(o => ({
        refId: o.refId,
        tradeId: o.tradeId,
        traderCode: user.englishName,
        traderFeedback
      }));

    if (cmds.length > 0) {
      return client.rejectOrders(cmds).then(results => {
        submitDialogSuccess(DIALOG_REJECT_ORDERS, results.map(r => r.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._rejectOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_REJECT_ORDERS);
  };

  render() {
    const { submitStatus: status } = this.state;
    const { traderFeedback } = this.state.fields;

    const { selectedOrders } = this.props;
    const rejectedOrderRefIds = selectedOrders
      .filter(o => ['REJECT', 'REJECTING'].includes(o.traderStatus))
      .map(o => o.refId)
      .join(',');

    return (
      <div>
        <Modal size="tiny" open={true}>
          <Header content="Reject Orders" />
          <Modal.Content>
            <p>Are you sure you want to reject the selected orders?</p>

            <Form>
              <Form.Group widths="equal">
                <Form.TextArea
                  placeholder="Enter feedback to PM if necessary..."
                  value={traderFeedback}
                  onChange={(e, { value }) =>
                    this.onInputChange({ name: 'traderFeedback', value })
                  }
                />
              </Form.Group>
            </Form>

            <p>
              {rejectedOrderRefIds &&
                `Note: Some orders are already rejected: ${rejectedOrderRefIds} which will be filtered out by system.`}
            </p>
          </Modal.Content>
          <Modal.Actions>
            {
              {
                SUBMITTING: (
                  <Button secondary disabled loading content="Submitting..." />
                ),
                ERROR: (
                  <Button
                    secondary
                    content="Fail - Retry?"
                    onClick={this._onSubmit}
                  />
                ),
                READY: (
                  <Button secondary content="Yes" onClick={this._onSubmit} />
                )
              }[status]
            }

            <Button secondary content="No" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default RejectOrdersDialog;
