export const ORDER_SNAPSHOT = 'ORDER_SNAPSHOT';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const TRADE_SELECT = 'TRADE_SELECT';
export const TRADES_SELECT = 'TRADES_SELECT';

export const TRADE_VIEW_MODE_LIVE = 'LIVE';
export const TRADE_VIEW_MODE_HIST = 'HIST';

export const TRADE_VIEW_MODE_CHANGE = 'TRADE_VIEW_MODE_CHANGE';
export const TRADE_VIEW_DATE_CHANGE = 'TRADE_VIEW_DATE_CHANGE';
export const TRADE_VIEW_USESRV_MODE_CHANGE = 'TRADE_VIEW_USESRV_MODE_CHANGE';

export const TRADE_BLOTTER_GET_REQUEST = 'TRADE_BLOTTER_GET_REQUEST';
export const TRADE_BLOTTER_GET_SUCCESS = 'TRADE_BLOTTER_GET_SUCCESS';
export const TRADE_BLOTTER_GET_FAIL = 'TRADE_BLOTTER_GET_FAIL';

export const DIALOG_EXECUTE_SINGLE_TRADE = 'executeSingleTradeDialog';
export const DIALOG_CANCEL_TRADE = 'cancelTradeDialog';
export const DIALOG_EXECUTE_MULTI_TRADES = 'executeMultiTradesDialog';
export const DIALOG_EXECUTE_QUANT_TRADES = 'executeQuantTradesDialog';
export const DIALOG_EXECUTE_IPO_TRADES = 'executeIPOTradesDialog';
export const DIALOG_ADJUST_PORTFOLIO = 'adjustPortfolioDialog';
export const DIALOG_ROLL_POSITIONS = 'rollPositionsDialog';
export const DIALOG_EXECUTE_CB_TRADES = 'executeCBTradesDialog';
