import {
  OMS_VIEW_MODE_HIST,
  DIALOG_EXECUTE_ROUTES,
  DIALOG_CANCEL_ROUTES,
  DIALOG_EXECUTE_ROUTES_MODE,
  DIALOG_EXECUTE_IPO_ROUTES
} from '../../omsConstants';

const operations = [
  // {
  //   key: 'execute',
  //   subOps: [
  //     {
  //       key: 'newRoute',
  //       header: 'New Route',
  //       color: 'green',
  //       dialogCode: DIALOG_EXECUTE_ROUTE,
  //       disabledFn: ({ viewMode, selectedOrder }) =>
  //         viewMode === OMS_VIEW_MODE_HIST || !selectedOrder,
  //       info: { createNew: true }
  //     },
  //     {
  //       key: 'editRoute',
  //       header: 'Edit Route',
  //       color: 'green',
  //       dialogCode: DIALOG_EXECUTE_ROUTE,
  //       disabledFn: ({ viewMode, selectedRoute }) =>
  //         viewMode === OMS_VIEW_MODE_HIST || !selectedRoute,
  //       info: { createNew: false }
  //     }
  //   ]
  // },
  {
    key: 'multi-execute',
    requestType: 'TRD',
    subOps: [
      {
        key: 'newRoutes',
        header: 'New Routes',
        color: 'green',
        dialogCode: DIALOG_EXECUTE_ROUTES,
        disabledFn: ({ viewMode, selectedOrder }) =>
          viewMode === OMS_VIEW_MODE_HIST || !selectedOrder,
        info: { mode: DIALOG_EXECUTE_ROUTES_MODE.NEW }
      },
      {
        key: 'editRoutes',
        header: 'Edit Routes',
        color: 'green',
        dialogCode: DIALOG_EXECUTE_ROUTES,
        disabledFn: ({ viewMode, selectedRoute }) =>
          viewMode === OMS_VIEW_MODE_HIST || !selectedRoute,
        info: { mode: DIALOG_EXECUTE_ROUTES_MODE.UPDATE }
      },
      {
        key: 'cancelRoutes',
        header: 'Cancel',
        color: 'red',
        dialogCode: DIALOG_CANCEL_ROUTES,
        disabledFn: ({ viewMode, selectedRoute }) =>
          viewMode === OMS_VIEW_MODE_HIST || !selectedRoute
      }
    ]
  },
  {
    key: 'ipo-multi-execute',
    requestType: 'IPO',
    subOps: [
      {
        key: 'newRoutes',
        header: 'New Routes',
        color: 'green',
        dialogCode: DIALOG_EXECUTE_IPO_ROUTES,
        disabledFn: ({ viewMode, selectedOrder }) =>
          viewMode === OMS_VIEW_MODE_HIST || !selectedOrder,
        info: { mode: DIALOG_EXECUTE_ROUTES_MODE.NEW }
      },
      {
        key: 'editRoutes',
        header: 'Edit Routes',
        color: 'green',
        dialogCode: DIALOG_EXECUTE_IPO_ROUTES,
        disabledFn: ({ viewMode, selectedRoute }) =>
          viewMode === OMS_VIEW_MODE_HIST || !selectedRoute,
        info: { mode: DIALOG_EXECUTE_ROUTES_MODE.UPDATE }
      },
      {
        key: 'cancelRoutes',
        header: 'Cancel',
        color: 'red',
        dialogCode: DIALOG_CANCEL_ROUTES,
        disabledFn: ({ viewMode, selectedRoute }) =>
          viewMode === OMS_VIEW_MODE_HIST || !selectedRoute
      }
    ]
  }
  // {
  //   key: 'multi-execute-basket',
  //   requestType: 'BASKET',
  //   subOps: [
  //     {
  //       key: 'newRoutes',
  //       header: 'New Routes',
  //       color: 'green',
  //       dialogCode: DIALOG_EXECUTE_ROUTES,
  //       disabledFn: ({ viewMode, selectedOrder }) =>
  //         viewMode === OMS_VIEW_MODE_HIST || !selectedOrder,
  //       info: { mode: DIALOG_EXECUTE_ROUTES_MODE.NEW }
  //     },
  //     {
  //       key: 'editRoutes',
  //       header: 'Edit Routes',
  //       color: 'green',
  //       dialogCode: DIALOG_EXECUTE_ROUTES,
  //       disabledFn: ({ viewMode, selectedRoute }) =>
  //         viewMode === OMS_VIEW_MODE_HIST || !selectedRoute,
  //       info: { mode: DIALOG_EXECUTE_ROUTES_MODE.UPDATE }
  //     },
  //     {
  //       key: 'cancelRoutes',
  //       header: 'Cancel',
  //       color: 'red',
  //       dialogCode: DIALOG_CANCEL_ROUTES,
  //       disabledFn: ({ viewMode, selectedRoute }) =>
  //         viewMode === OMS_VIEW_MODE_HIST || !selectedRoute
  //     }
  //   ]
  // }
];

export default operations;
