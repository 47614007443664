import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button, Grid, Header, Segment } from 'semantic-ui-react';

import Field from 'common/ui/Field';
import './LoginForm.css';

class LoginForm extends Component {
  state = {
    fields: {
      name: '',
      pwd: ''
    },
    fieldErrors: {}
  };

  constructor(props) {
    super(props);

    const { englishName, domainName } = props.user || {};
    const name = domainName ? domainName : englishName;
    this.state = {
      fields: {
        name,
        pwd: ''
      },
      fieldErrors: {}
    };
  }

  onFormSubmit = evt => {
    const user = this.state.fields;
    evt.preventDefault();

    if (this.validate()) return;

    this.props.onSubmit(user);
  };

  onInputChange = ({ name, value, error }) => {
    const user = this.state.fields;
    const fieldErrors = this.state.fieldErrors;

    this.setState({
      fields: { ...user, [name]: value },
      fieldErrors: { ...fieldErrors, [name]: error }
    });
  };

  validate = () => {
    const user = this.state.fields;
    const fieldErrors = this.state.fieldErrors;
    const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

    if (!user.name) return true;
    if (!user.pwd) return true;
    if (errMessages.length) return true;

    return false;
  };

  render() {
    let status = this.props.loginStatus;

    if (status === 'SUCCESS') {
      this.props.resetForm();
      return <Redirect to="/" />;
    } else {
      return (
        <Grid
          textAlign="center"
          style={{ height: '100%', width: '100%' }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="grey" textAlign="center">
              Log-in to your account
            </Header>

            <br />

            <Form onSubmit={this.onFormSubmit}>
              <Segment.Group stacked>
                <Segment>
                  <Field
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={this.state.fields.name}
                    error={this.state.fieldErrors.name}
                    onChange={this.onInputChange}
                    validate={val => (val ? false : 'Name is required')}
                  />
                </Segment>
                <Segment>
                  <Field
                    type="password"
                    placeholder="Password"
                    name="pwd"
                    value={this.state.fields.pwd}
                    error={this.state.fieldErrors.pwd}
                    onChange={this.onInputChange}
                    validate={val => (val ? false : 'Password is required')}
                  />
                </Segment>
                <Segment>
                  {
                    {
                      LOGINING: (
                        <Button
                          type="submit"
                          color="green"
                          disabled
                          size="tiny"
                        >
                          Login...
                        </Button>
                      ),
                      SUCCESS: (
                        <Button
                          type="submit"
                          color="green"
                          disabled
                          size="tiny"
                        >
                          Login Success
                        </Button>
                      ),
                      ERROR: (
                        <Button
                          type="submit"
                          size="tiny"
                          color="green"
                          disabled={this.validate()}
                        >
                          Login Fail - Retry?
                        </Button>
                      ),
                      READY: (
                        <Button
                          type="submit"
                          size="tiny"
                          color="green"
                          disabled={this.validate()}
                        >
                          Submit
                        </Button>
                      )
                    }[status]
                  }
                </Segment>
              </Segment.Group>
            </Form>
          </Grid.Column>
        </Grid>
      );
    }
  }
}

export default LoginForm;
