import React, { Component } from 'react';
import { Modal, Button, Select } from 'antd';
import { Input } from 'antd';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';
import moment from 'moment';
import { getCurrentQuarter } from 'common/utils/DateUtils';

const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
const _createQuarterOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c.quarterKey}>
      {c.quarterName}
    </Option>
  ));
};

const _initQuarterDate = () => {
  const optionList = [];
  for (let i = 6; i >= -3; i--) {
    const date = moment().add(i, 'Q');
    const item = getCurrentQuarter(date);
    optionList.push(item);
  }
  return optionList;
};
class AddLinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: {
        url: null,
        name: null,
        type: 'ppt',
        yearQuarter: getCurrentQuarter(moment()).quarterKey
      },
      errors: [],
      typeList: ['ppt', 'xlsx', 'doc', 'oneNotes'],
      quarterList: _initQuarterDate()
    };
  }

  _onSubmit = () => {
    const { link } = this.state;
    if (this._validate()) {
      this.props.submitData(link);
    }
  };

  _validate = () => {
    const { link } = this.state;
    const errors = [];
    if (_.isEmpty(link.url)) {
      errors.push('Url must not be null');
    }
    if (
      !_.isEmpty(link.url) &&
      !link.url.includes('/personal/pinpoint_pinpointfund_com/')
    ) {
      errors.push(
        <div>
          该链接不符合我们的要求，请将该文件保存到PinPoint共享文档之下，重新粘贴。IT同事已经在OneDrive-PinPOINT设置了以你的名字命名的共享文档，请将文档保存到该处。
          <br />
          The link you pasted does not meet our requirements, please save the
          file under PinPoint shared documents and paste the link again. IT team
          has set up a shared file under OneDrive - PinPOINT - Your name, please
          save files in this fold.
          <br />
          <br />
          Click following link to the shared file, click ADD SHORCUT TO MY
          FILES, the shared file will show up in your PinPOINT OneDrive.
          <br />
          <a
            href="#"
            onClick={() =>
              window.open(
                'https://pamlhk-my.sharepoint.com/personal/pinpoint_pinpointfund_com/_layouts/15/onedrive.aspx?fromShare=true&ga=1'
              )
            }
          >
            Jump to shared floder
          </a>
        </div>
      );
    }
    if (_.isEmpty(link.type)) {
      errors.push('File type must not be null');
    }
    if (_.isEmpty(link.name)) {
      errors.push('Name must not be null');
    }
    this.setState({
      errors
    });
    return _.isEmpty(errors);
  };

  onInputChange = ({ name, value }) => {
    const { link } = this.state;
    const updateData = {
      ...link,
      [name]: value
    };
    this.setState(
      {
        link: updateData
      },
      this._validate
    );
  };

  _createInput = () => {
    const { link } = this.state;
    return (
      <>
        <div className={'ui form field'} style={{ height: '35px' }}>
          <div style={{ width: '29%', float: 'left' }}>
            <Select
              style={{ width: '100%' }}
              value={link.yearQuarter}
              allowClear={false}
              placeholder="select quarter"
              onChange={value => {
                this.onInputChange({ name: 'yearQuarter', value });
              }}
            >
              {_createQuarterOptions(this.state.quarterList)}
            </Select>
          </div>
          <div style={{ width: '70%', float: 'right' }}>
            <Input
              value={link.name}
              placeholder="input link name"
              onChange={e => {
                this.onInputChange({ name: 'name', value: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={'ui form field'} style={{ height: '35px' }}>
          <div style={{ width: '29%', float: 'left' }}>
            <Select
              style={{ width: '100%' }}
              value={link.type}
              allowClear={false}
              placeholder="select file type"
              onChange={value => {
                this.onInputChange({ name: 'type', value });
              }}
            >
              {_createUIOptions(this.state.typeList)}
            </Select>
          </div>
          <div style={{ width: '70%', float: 'right' }}>
            <Input
              value={link.url}
              placeholder="input link url"
              onChange={e => {
                this.onInputChange({ name: 'url', value: e.target.value });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { errors } = this.state;
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        mask={false}
        title={`Add Link`}
        width={950}
        footer={[
          <Button
            size="small"
            type="primary"
            onClick={() => {
              this._onSubmit();
            }}
          >
            Ok
          </Button>,
          <Button
            size="small"
            type="primary"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        ]}
        closable={false}
      >
        {this._createInput()}
        {!_.isEmpty(errors) && <Message error list={errors} />}
      </Modal>
    );
  }
}

export default AddLinkModal;
