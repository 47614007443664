import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import { DatePicker, Input, Select, Switch } from 'antd';
import OperationtMode from 'common/utils/OperationtMode';
import _ from 'lodash';

const { Option } = Select;
class ResearchReportHomePageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: []
    };
  }

  componentDidMount() {
    this.props.getPersonList({});
    this.props.getGicsList({});
    this.loadResearchReportList();
  }

  loadResearchReportList = () => {
    const { researchReportQueryParams } = this.props;
    this.props.loadResearchReportList(researchReportQueryParams);
    this.props.getCompanyKeyDataTrackList(researchReportQueryParams);
    this.props.getCompanyMeetingRecordList(researchReportQueryParams);
    this.props.getCompanyReplayScoreList(researchReportQueryParams);
  };

  initSelectData = () => {};

  initLoadReviewListData = () => {};

  onQueryParamsChange = ({ name, value }) => {
    this.props.changeResearchReportQueryParams({
      [name]: value
    });
  };

  loadReviewListData = () => {};

  onReloadMonthlyReviewBtnClicked = () => {};

  onAddBtnClicked = () => {
    const { activeTab } = this.props;
    if (activeTab === '1') {
      this.props.openCompanyMeetingRecordModal(OperationtMode.NEW);
    } else if (activeTab === '2') {
      this.props.openCompanyKeyDataTrackModal(OperationtMode.NEW);
    } else if (activeTab === '3') {
      this.props.openCompanyReplayScoreModal(OperationtMode.NEW);
    } else if (activeTab === '4') {
      this.props.openResearchReportDetailModal(OperationtMode.NEW);
    }
  };

  onUpdateBtnClicked = () => {
    const {
      activeTab,
      companyKeyDataTrackDetail,
      companyMeetingRecordDetail,
      companyReplayScoreDetail
    } = this.props;
    if (
      activeTab === '1' &&
      companyMeetingRecordDetail &&
      companyMeetingRecordDetail.status === 'NEW'
    ) {
      this.props.openCompanyMeetingRecordModal(OperationtMode.UPDATE);
    } else if (
      activeTab === '2' &&
      companyKeyDataTrackDetail &&
      companyKeyDataTrackDetail.status === 'NEW'
    ) {
      this.props.openCompanyKeyDataTrackModal(OperationtMode.UPDATE);
    } else if (
      activeTab === '3' &&
      companyReplayScoreDetail &&
      companyReplayScoreDetail.status === 'NEW'
    ) {
      this.props.openCompanyReplayScoreModal(OperationtMode.UPDATE);
    } else if (activeTab === '4') {
      this.updateResearchReport();
    }
  };

  updateResearchReport = () => {
    const { researchReportDetail } = this.props;
    if (!this.checkCanEdit(researchReportDetail.selectedItemData)) {
      return;
    }
    if (!researchReportDetail.selectedItem) {
      return;
    }
    this.props.loadResearchReportDetail({
      id: researchReportDetail.selectedItem,
      op: OperationtMode.UPDATE
    });
    this.props.openResearchReportDetailModal(OperationtMode.UPDATE);
  };

  onCopyBtnClick = () => {
    const {
      activeTab,
      researchReportQueryParams,
      companyKeyDataTrackDetail,
      companyReplayScoreDetail
    } = this.props;
    if (activeTab === '1') {
      return;
    } else if (activeTab === '2' && companyKeyDataTrackDetail) {
      this.props.copyCompanyKeyDataTrackDetail(
        companyKeyDataTrackDetail,
        researchReportQueryParams
      );
    } else if (activeTab === '3' && companyReplayScoreDetail) {
      this.props.copyCompanyReplayScoreDetail(
        companyReplayScoreDetail,
        researchReportQueryParams
      );
    } else if (activeTab === '4') {
      this.copyResearchReport();
    }
  };

  copyResearchReport = () => {
    const { researchReportDetail, researchReportQueryParams } = this.props;
    if (!researchReportDetail.selectedItem) {
      return;
    }
    this.props.copyResearchReport(
      {
        id: researchReportDetail.selectedItem
      },
      researchReportQueryParams
    );
  };

  onQueryParamsChange = ({ name, value }) => {
    this.props.changeResearchReportQueryParams({
      name,
      value
    });
  };

  checkCanEdit = data => {
    if (!data) {
      return false;
    }
    const { status, createBy } = data;
    const { user } = this.props;
    const englishName = user.userAccountName;
    if (status === 'NEW' && englishName === createBy) {
      return true;
    }
    return false;
  };

  _createPersonSelect = () => {
    const { researchReportPersonList, researchReportQueryParams } = this.props;
    if (_.isEmpty(researchReportPersonList)) {
      return '';
    }
    return (
      <Select
        mode="multiple"
        size="small"
        style={{ position: 'relative', width: '150px', marginRight: '5px' }}
        placeholder="select create by"
        onChange={selectedItems => {
          this.onQueryParamsChange({
            name: 'createByList',
            value: selectedItems
          });
        }}
        value={researchReportQueryParams.createByList}
      >
        {researchReportPersonList.map(t => {
          return (
            <Option value={t} key={t}>
              {t}
            </Option>
          );
        })}
      </Select>
    );
  };

  _createGICSSelect = () => {
    const { researchReportGicsList, researchReportQueryParams } = this.props;
    if (_.isEmpty(researchReportGicsList)) {
      return '';
    }
    return (
      <Select
        mode="multiple"
        size="small"
        style={{ position: 'relative', width: '210px', marginRight: '5px' }}
        placeholder="select gics"
        onChange={selectedItems => {
          this.onQueryParamsChange({
            name: 'gicsList',
            value: selectedItems
          });
        }}
        value={researchReportQueryParams.gicsList}
      >
        {researchReportGicsList.map(t => (
          <Option value={t.gicsCode} key={t.gicsCode}>
            {t.gicsName}
          </Option>
        ))}
      </Select>
    );
  };

  onSwitchChange = () => {
    const { researchReportQueryParams } = this.props;
    researchReportQueryParams.mode = !researchReportQueryParams.mode;
    this.props.loadResearchReportList(researchReportQueryParams);
  };

  btnNotShow = () => {
    const { activeTab } = this.props;
    return (
      activeTab === '5' ||
      activeTab === '6' ||
      activeTab === '7' ||
      activeTab === '8' ||
      activeTab === '9'
    );
  };

  render() {
    const { isLoaded, researchReportQueryParams } = this.props;
    return (
      <div>
        {this.btnNotShow() ? (
          ''
        ) : (
          <div>
            <div
              style={{
                textAlign: 'right'
              }}
            >
              <Switch
                checked={researchReportQueryParams.mode}
                checkedChildren="NEWEST"
                unCheckedChildren="HISTORY"
                defaultChecked
                onChange={this.onSwitchChange}
              />
            </div>
            <div
              style={{
                textAlign: 'right',
                backgroundColor: '#1B1C1D',
                marginBottom: '2px'
              }}
            >
              {this._createPersonSelect()}
              {this._createGICSSelect()}
              <Input
                placeholder="Input ticker..."
                size="small"
                style={{
                  width: '135px',
                  height: '22px'
                }}
                value={researchReportQueryParams.ticker}
                onChange={e => {
                  this.onQueryParamsChange({
                    value: e.target.value,
                    name: 'ticker'
                  });
                }}
                onPressEnter={this.loadResearchReportList}
              />

              <DatePicker
                placeholder={'month start'}
                allowClear={false}
                value={moment(
                  researchReportQueryParams.startDate,
                  'YYYY-MM-DD'
                )}
                size="small"
                onChange={(date, dateString) => {
                  this.onQueryParamsChange({
                    name: 'startDate',
                    value: dateString
                  });
                }}
              />
              {!researchReportQueryParams.mode ? (
                <DatePicker
                  placeholder={'month end'}
                  allowClear={false}
                  value={moment(
                    researchReportQueryParams.endDate,
                    'YYYY-MM-DD'
                  )}
                  size="small"
                  onChange={(date, dateString) => {
                    this.onQueryParamsChange({
                      name: 'startDate',
                      value: dateString
                    });
                  }}
                />
              ) : (
                ''
              )}

              <Button
                size="mini"
                content="Reload"
                color="green"
                disabled={!isLoaded}
                style={{ marginBottom: '4px', marginTop: '4px' }}
                onClick={this.loadResearchReportList}
              />

              <Button
                size="mini"
                content="Add"
                color="green"
                disabled={!isLoaded}
                style={{ marginBottom: '4px', marginTop: '4px' }}
                onClick={this.onAddBtnClicked}
              />

              <Button
                size="mini"
                content="Update"
                color="green"
                disabled={!isLoaded}
                style={{ marginBottom: '4px', marginTop: '4px' }}
                onClick={this.onUpdateBtnClicked}
              />

              <Button
                size="mini"
                content="Copy"
                color="green"
                disabled={!isLoaded}
                style={{ marginBottom: '4px', marginTop: '4px' }}
                onClick={this.onCopyBtnClick}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ResearchReportHomePageHeader;
