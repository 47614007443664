import React, { Component } from 'react';
import { Popover, Row, Col, Button } from 'antd';
import client from 'features/oms/api/client';
import numeral from 'numeral';

export default class LazyFormCompent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipField: props.tooltipField,
      tooltipTitle: props.tooltipTitle,
      tooltipWidth: props.tooltipWidth || '400px',
      tooltipHeight: props.tooltipHeight || '180px',
      value: props.valueFormatted || props.value,
      data: props.data,
      product: null,
      open: false
    };
  }

  _loadProductInfo = orderRefId => {
    const { data, tooltipField } = this.state;
    client.getOrderProduct({ orderRefId: data[tooltipField] }).then(resp => {
      if (resp.data) {
        this.setState({
          product: resp.data[0]['Json'],
          open: true
        });
      }
    });
  };

  _createForm = () => {
    const { product } = this.state;
    if (!product) return;
    const data = JSON.parse(product);
    const type = data['@type'];
    switch (type) {
      case 'Swap':
        return this._createSwapForm(data);
      default:
        return;
    }
  };

  _createFixLegDiv = (leg, name) => {
    return (
      <div style={{ width: '48%', float: 'left', paddingLeft: '10px' }}>
        <Row gutter={24}>
          <Col span={9}>{name}:</Col>
          <Col span={15} className="text-align-right">
            {leg.payReceive}
          </Col>
          <Col span={9}>Notional:</Col>
          <Col span={15} className="text-align-right">
            {numeral(leg.notionalSchedule.amount.initialValue).format('0,0')}
          </Col>
          <Col span={9}>Currency: </Col>
          <Col span={15} className="text-align-right">
            {leg.notionalSchedule.currency}
          </Col>
          <Col span={9}>Effective:</Col>
          <Col span={15} className="text-align-right">
            {leg.accrualSchedule.startDate}
          </Col>
          <Col span={9}>Maturity:</Col>
          <Col span={15} className="text-align-right">
            {leg.accrualSchedule.endDate}
          </Col>
          <Col span={9}>Coupon:</Col>
          <Col span={15} className="text-align-right">
            {numeral(leg.calculation.rate.initialValue).format('0.000%')}
          </Col>
          <Col span={9}>Pay Freq:</Col>
          <Col span={15} className="text-align-right">
            {leg.paymentSchedule.paymentFrequency}
          </Col>
          <Col span={9}>Day Count:</Col>
          <Col span={15} className="text-align-right">
            {leg.calculation.dayCount}
          </Col>
          <Col span={9}>Calc Basis:</Col>
          <Col span={15} className="text-align-right">
            {}
          </Col>
        </Row>
      </div>
    );
  };

  _createNotFixLegDiv = (leg, name) => {
    return (
      <div style={{ width: '48%', float: 'left', paddingLeft: '10px' }}>
        <Row gutter={24}>
          <Col span={9}>{name}:</Col>
          <Col span={15} className="text-align-right">
            {leg.payReceive}
          </Col>
          <Col span={9}>Notional:</Col>
          <Col span={15} className="text-align-right">
            {numeral(leg.notionalSchedule.amount.initialValue).format('0,0')}
          </Col>
          <Col span={9}>Currency: </Col>
          <Col span={15} className="text-align-right">
            {leg.notionalSchedule.currency}
          </Col>
          <Col span={9}>Effective:</Col>
          <Col span={15} className="text-align-right">
            {leg.accrualSchedule.startDate}
          </Col>
          <Col span={9}>Maturity:</Col>
          <Col span={15} className="text-align-right">
            {leg.accrualSchedule.endDate}
          </Col>
          <Col span={9}>Index:</Col>
          <Col span={5} className="text-align-right">
            {leg.calculation.index.tenor}
          </Col>
          <Col span={10} className="text-align-right">
            {leg.calculation.index.name}
          </Col>
          <Col span={9}>Spread:</Col>
          <Col span={15} className="text-align-right">
            {leg.calculation.spread}
          </Col>
          <Col span={9}>Leverage:</Col>
          <Col span={15} className="text-align-right">
            {}
          </Col>
          <Col span={9}>Reset Freg:</Col>
          <Col span={15} className="text-align-right">
            {}
          </Col>
          <Col span={9}>Pay Freq:</Col>
          <Col span={15} className="text-align-right">
            {leg.paymentSchedule.paymentFrequency}
          </Col>
          <Col span={9}>Day Count:</Col>
          <Col span={15} className="text-align-right">
            {leg.calculation.dayCount}
          </Col>
        </Row>
      </div>
    );
  };

  _createSwapForm = data => {
    const { tooltipHeight, tooltipWidth } = this.state;
    const [leg1, leg2] = data.legs;
    const payLeg = leg1.payReceive === 'PAY' ? leg1 : leg2;
    const recLeg = leg1.payReceive === 'RECEIVE' ? leg1 : leg2;
    return (
      <div style={{ width: tooltipWidth, height: tooltipHeight }}>
        {payLeg.calculation['@type'] === 'FixedRateCalculation'
          ? this._createFixLegDiv(payLeg, 'Leg 1')
          : this._createNotFixLegDiv(payLeg, 'Leg 1')}
        {recLeg.calculation['@type'] === 'FixedRateCalculation'
          ? this._createFixLegDiv(recLeg, 'Leg 2')
          : this._createNotFixLegDiv(recLeg, 'Leg 2')}
      </div>
    );
  };

  render() {
    const { tooltipTitle, value, data, tooltipField, open } = this.state;
    const item = (data || {})[tooltipField] || [];

    if (!item) return <span>{value}</span>;
    const content = this._createForm();
    return (
      <Popover
        title={tooltipTitle}
        trigger="click"
        open={open}
        content={content}
      >
        <Button type="link" onClick={this._loadProductInfo}>
          {value}
        </Button>
      </Popover>
    );
  }

  refresh(params) {
    this.setState({
      value: params.valueFormatted || params.value,
      data: params.data
    });
    return true;
  }
}
