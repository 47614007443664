import React, { Component } from 'react';
import client from '../api/client';
import config from 'common/config';
import SplitPane from 'react-split-pane';
import { Tabs, Typography, Button } from 'antd';
import {
  AudioOutlined,
  VideoCameraOutlined,
  FileTextOutlined
} from '@ant-design/icons';

const { Title } = Typography;
export default class StockConferenceInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unqId: null,
      data: null,
      url: null,
      isExpanded: true,
      mode: 'TEXT'
    };
  }

  componentDidMount() {
    const pathname = window.location ? window.location.pathname : null;
    if (pathname) {
      const arr = pathname.split('/show/');
      this.setState(
        {
          unqId: arr.length === 2 ? arr[1] : null
        },
        this.loadData
      );
    }
  }

  loadData = () => {
    const { unqId } = this.state;
    if (!unqId) return;
    const arr = unqId.split('_');
    client
      .getGptConferenceInfo(arr[0])
      .then(resp => {
        if (resp && resp.Content) {
          const str = resp.Content.substring(1, resp.Content.length - 1);
          const regex = /\('([^']*)', '([^']*)', '([^']*)'\)/g;
          const matches = [];
          let match;

          // 迭代所有匹配项
          while ((match = regex.exec(str)) !== null) {
            // match[1], match[2], match[3] 分别对应元组中的三个元素
            matches.push([match[1], match[2], match[3]]);
          }
          resp.contentArr = matches;
        }
        this.setState({
          data: resp,
          url: resp ? resp.URL : null,
          mode: resp && resp.URL ? 'VIDEO' : 'TEXT'
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  toggleExpand = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded
    });
  };

  createVideo = () => {
    const { data, url, mode } = this.state;
    if (!url) return '';
    const uri = data.URL
      ? `${config.api.REST_URL}/research/playVideo?videoPath=${url}&mode=${mode}`
      : '';
    return (
      data && (
        <video width="100%" controls autoPlay>
          <source src={uri} type="video/mp4" />
          Your broswer not support mp4.{' '}
        </video>
      )
    );
  };

  createAudio = () => {
    const { data, url, mode } = this.state;
    if (!url) return '';
    const uri = data.URL
      ? `${config.api.REST_URL}/research/playVideo?videoPath=${url}&mode=${mode}`
      : '';
    return (
      data && (
        <div
          style={{
            height: '25px',
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            padding: '0px 0',
            background: 'white',
            zIndex: '1000'
          }}
        >
          <audio
            width="100%"
            controls
            autoPlay
            style={{
              height: '25px',
              width: '100%',
              paddingLeft: '20px',
              paddingRight: '20px'
            }}
          >
            <source src={uri} type="video/mp4" />
            Your broswer not support mp4.
          </audio>
        </div>
      )
    );
  };

  _createAISummaryPanel = () => {
    const { data } = this.state;
    return (
      data && (
        <div
          height="100%"
          width="100%"
          style={{
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            paddingLeft: '5px',
            color: 'white',
            maxHeight: '90vh',
            padding: '10px'
          }}
        >
          <code>{data.AiSummary}</code>
        </div>
      )
    );
  };

  _createDetailPanel = () => {
    const { data } = this.state;
    if (!data) return;
    return (
      data.contentArr && (
        <div
          height="100%"
          width="100%"
          style={{
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            paddingLeft: '5px',
            color: 'white',
            maxHeight: '90vh',
            padding: '10px'
          }}
        >
          {data.contentArr.map(item => {
            return (
              <code>
                <Typography.Text mark>
                  [{item[0]}]{item[1]}
                </Typography.Text>{' '}
                {item[2]}
                <br />
                <br />
              </code>
            );
          })}
        </div>
      )
    );
  };

  _createVideoText = () => {
    return (
      <Tabs tabPosition="top" key="ConferenceDetail">
        <Tabs.TabPane tab="Conference Detail" key="ConferenceDetail">
          {this._createDetailPanel()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="AI Summary" key="AISummary">
          {this._createAISummaryPanel()}
        </Tabs.TabPane>
      </Tabs>
    );
  };

  _createText = () => {
    return (
      <SplitPane
        split="vertical"
        defaultSize="70%"
        style={{ position: 'relative' }}
      >
        <div>
          <Title
            level={3}
            style={{ color: 'white', marginTop: '10px', marginLeft: '10px' }}
          >
            Conference Detail
          </Title>
          {this._createDetailPanel()}
        </div>
        <div>
          <Title
            level={3}
            style={{ color: 'white', marginTop: '10px', marginLeft: '10px' }}
          >
            AI Summary
          </Title>
          {this._createAISummaryPanel()}
        </div>
      </SplitPane>
    );
  };

  _createAudioAndText = () => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this._createText()}
        {this.createAudio()}

        {/* <div style={{ width: `${isExpanded?'79%':'99%'}`, background: '#f0f0f0', padding: '0px' }}>  
              {this.createVideo()} 
            </div>  
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
                    width: '1%', cursor: 'pointer', height: '100%', background: '#f0f0f0',  float: 'left'}} onClick={this.toggleExpand}>  
                <Button type="link" style={{ padding: 0, color: 'green' }}> 
                    {isExpanded? '>>':'<<'} 
                </Button>  
              </div>  
            <div style={{ width: `${isExpanded?'20%':'0%'}`, transition: 'flex 1s ease', overflow: 'hidden' }}>  
              <div style={{ width: isExpanded ? '100%' : '0%', height: '100%', padding: '0px' }}>  
                {isExpanded && this._createOnlyText()}  
              </div>  
            </div>   */}
      </div>
    );
  };

  _createVideoAndText = () => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <SplitPane
          split="vertical"
          defaultSize="75%"
          style={{ position: 'relative' }}
        >
          {this.createVideo()}
          {this._createVideoText()}
        </SplitPane>

        {/* <div style={{ width: `${isExpanded?'79%':'99%'}`, background: '#f0f0f0', padding: '0px' }}>  
              {this.createVideo()} 
            </div>  
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
                    width: '1%', cursor: 'pointer', height: '100%', background: '#f0f0f0',  float: 'left'}} onClick={this.toggleExpand}>  
                <Button type="link" style={{ padding: 0, color: 'green' }}> 
                    {isExpanded? '>>':'<<'} 
                </Button>  
              </div>  
            <div style={{ width: `${isExpanded?'20%':'0%'}`, transition: 'flex 1s ease', overflow: 'hidden' }}>  
              <div style={{ width: isExpanded ? '100%' : '0%', height: '100%', padding: '0px' }}>  
                {isExpanded && this._createOnlyText()}  
              </div>  
            </div>   */}
      </div>
    );
  };

  changeMode = mode => {
    this.setState({
      mode
    });
  };

  _createContent = () => {
    const { mode } = this.state;
    switch (mode) {
      case 'VIDEO':
        return this._createVideoAndText();
      case 'AUDIO':
        return this._createAudioAndText();
      default:
        return this._createText();
    }
  };

  render() {
    const { mode, url } = this.state;
    return (
      <>
        <style>
          {`
                        .conference-floating-div {  
                              position: fixed; /* 固定在页面上 */  
                              left: 8px; /* 距离右边框20px */  
                              top: 8px; /* 距离顶部20px */  
                              width: auto; /* 宽度自适应内容 */  
                              padding: 0px; /* 内边距 */  
                              z-index: 1000; /* 提高层级，确保它显示在其他元素之上 */  
                              /* 可以添加更多的样式，如文本对齐、字体样式等 */  
                              text-align: center;  
                            }
                    `}
        </style>
        <div className="conference-floating-div">
          {url && (
            <Button
              type={mode === 'VIDEO' ? 'primary' : 'default'}
              size="small"
              onClick={() => this.changeMode('VIDEO')}
            >
              <VideoCameraOutlined />
            </Button>
          )}
          {url && (
            <Button
              type={mode === 'AUDIO' ? 'primary' : 'default'}
              size="small"
              onClick={() => this.changeMode('AUDIO')}
            >
              <AudioOutlined />
            </Button>
          )}
          <Button
            type={mode === 'TEXT' ? 'primary' : 'default'}
            size="small"
            onClick={() => this.changeMode('TEXT')}
          >
            <FileTextOutlined />
          </Button>
        </div>
        {mode && this._createContent()}
      </>
    );
  }
}
