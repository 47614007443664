import React, { PureComponent } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import { DIALOG_SPLIT_ORDERS } from '../../omsConstants';
import client from '../../api/client';

class SplitOrdersDialog extends PureComponent {
  state = {
    submitStatus: 'READY'
  };

  _splitOrders = () => {
    const { selectedOrders, submitDialogSuccess } = this.props;

    const cmds = selectedOrders.map(o => ({
      refId: o.refId
    }));

    if (cmds.length > 0) {
      return client.splitSubOrders(cmds).then(results => {
        submitDialogSuccess(DIALOG_SPLIT_ORDERS, results.map(r => r.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._splitOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_SPLIT_ORDERS);
  };

  render() {
    const { submitStatus: status } = this.state;

    return (
      <div>
        <Modal size="tiny" open={true}>
          <Header content="Split Orders" />
          <Modal.Content>
            <p>Are you sure you want to split the selected orders?</p>
          </Modal.Content>
          <Modal.Actions>
            {
              {
                SUBMITTING: (
                  <Button secondary disabled loading content="Submitting..." />
                ),
                ERROR: (
                  <Button
                    secondary
                    content="Fail - Retry?"
                    onClick={this._onSubmit}
                  />
                ),
                READY: (
                  <Button secondary content="Yes" onClick={this._onSubmit} />
                )
              }[status]
            }

            <Button secondary content="No" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default SplitOrdersDialog;
