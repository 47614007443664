import _ from 'lodash';

const FIELDS = [
  'key',

  'fundCode',
  'bookCode',
  'strategy',
  'positionTypeCode',
  'ticker',
  'multiplier',
  'roundLotSize',
  'endPriceLocal'
];

const _createEmptyTrade = (p, preference) => {
  // const timeInForce = TradeCalculator.calcTimeInForce(
  //   p.fundCode,
  //   preference.timeInForce
  // );
  // const algoCode = TradeCalculator.calcAlgo(preference.algoCode);

  return {
    tradePct: 0,
    tradeSide: null,
    tradeQty: 0,
    orderType: 'MKT',
    limitPriceLocal: null,
    timeInForce: 'DAY',
    algoCode: 'INLINE',
    pmRemark: null,
    pmReason: 'Adjust portfolio'
  };
};

const Portfolio = {
  createFrom(positions, riskInfo, preference) {
    if (!riskInfo) return {};

    const { nav, usdNav } = riskInfo;
    return _.groupBy(
      _.orderBy(
        positions
          .filter(
            p =>
              ![
                'TRD_CSH',
                'FX_PROP',
                'FWRD_PROP',
                'FWRD_HEDGE',
                'PTH'
              ].includes(p.positionTypeCode) && p.targetQuantityEnd !== 0
          )
          .map(p => {
            const mvPct = (p.targetNotnlMktValBook / nav) * 100;
            const betaMvPct = (p.targetBetaNotnlMktValBook / nav) * 100;

            const clsQty = p.targetQuantityEndForClose;
            const clsMvPct = (clsQty / p.targetQuantityEnd) * mvPct;
            const clsBetaMvPct = (clsQty / p.targetQuantityEnd) * betaMvPct;
            return {
              ..._.pick(p, FIELDS),
              dir: p.targetQuantityEnd > 0 ? 'LONG' : 'SHORT',
              mvPct,
              clsMvPct,
              nonTradableMvPct: mvPct - clsMvPct,
              lockedTgtMvPct: 0,
              tgtMvPct: mvPct,
              betaMvPct,
              clsBetaMvPct,
              lockedTgtBetaMvPct: 0,
              tgtBetaMvPct: betaMvPct,

              qty: p.targetQuantityEnd,
              clsQty,
              priceBook: p.endPriceBook,
              securityCode: p.issuerCode,

              isLocked: false,
              nav,
              usdNav,

              ..._createEmptyTrade(p, preference)
            };
          }),
        [p => Math.abs(p.mvPct)],
        ['desc']
      ),
      p => p.dir
    );
  },
  createTrades(portfolio) {
    return _.flatMap(portfolio, positions => {
      return positions
        .filter(p => p.tradeQty !== 0)
        .map(p => ({
          refId: null,
          tradeId: null,

          ticker: p.ticker,
          side: p.tradeSide,
          lotSize: p.roundLotSize,
          quantity: p.tradeQty,
          qtyPct: Math.abs(p.tradePct / 100),
          qtyUsd: p.usdNav * Math.abs(p.tradePct / 100),

          qtyInput: 'ByShare',
          limitPriceLocal:
            p.orderType === 'LMT' ? parseFloat(p.limitPriceLocal) : null,
          orderType: p.orderType,
          fundCode: p.fundCode,
          bookCode: p.bookCode,
          pmStatus: 'SUBMIT',
          pmRemark: p.pmRemark,
          pmReason: p.pmReason,
          requestStatus: 'ACTIVE',
          tradeSource: 'IMS',
          strategy: p.strategy,
          isRiskControlled: false,
          prevFilledQuantity: 0,
          timeInForce: (p.timeInForce || 'DAY').toUpperCase(),
          requestType: 'TRD',
          securityCode: p.securityCode,

          algo: {
            code: (p.algoCode || 'INLINE').toUpperCase()
          }
        }));
    });
  }
};

export default Portfolio;
