import {
  initPortfolio,
  updatePortfolio,
  sendSubscription
} from 'features/portfolio/portfolioActions';
import { initOrder, updateOrder } from 'features/order/orderActions';
import { initOms, updateOms, sendNotification } from 'features/oms/omsActions';
import { initLiveData, updateLiveData } from 'features/livedata/t0Action';
import { logout } from 'features/auth/authActions';
import { OMS_VIEW_MODE_LIVE } from 'features/oms/omsConstants';
import EntityMap from '../../entities/EntityMap';
import { isAdminRole, isInternalFund } from '../utils/DomainUtils';
import _ from 'lodash';
import { addNotificationToList } from 'features/notification/notificationActions';

const sendOmsNotification = (msgBody, dispatch, getState) => {
  const user = getState().settings.user;
  const orders = getState().oms.viewState[OMS_VIEW_MODE_LIVE].orders;
  if (user && isAdminRole(user.role)) {
    const filteredOrders = msgBody.payload
      .filter(
        u =>
          u.tableName === 'ORDER' &&
          u.type !== 'Delete' &&
          u.cellValues['traderStatus'] === 'PENDING' &&
          isInternalFund(u.cellValues['fundCode']) &&
          ('Add' === u.type ||
            ('Update' === u.type &&
              EntityMap.get(orders, u.key).traderStatus !== 'PENDING'))
      )
      .map(u => u.cellValues);

    if (filteredOrders.length) {
      const payload = filteredOrders.reduce((acc, o) => {
        const bookSummary = acc[o.bookCode] || {};
        const type = o.pmStatus;
        const value =
          bookSummary[type] === undefined ? 1 : bookSummary[type] + 1;
        acc[o.bookCode] = {
          ...bookSummary,
          [type]: value
        };
        return acc;
      }, {});

      console.log(payload);
      sendNotification({
        type: 'PENDING_ORDERS',
        payload
      });
    }
  }
};

const handleSubscriptionData = (msg, dispatch, getState) => {
  const { dsName, dataType, payload } = msg.body || {};
  switch (dsName) {
    case 'PositionModelView':
      if (dataType === 'SNAPSHOT') {
        dispatch(initPortfolio(payload));
        dispatch(initOrder(payload));
      } else if (dataType === 'UPDATE') {
        dispatch(updatePortfolio(payload));
        dispatch(updateOrder(payload));
      }
      break;
    case 'OrderBlotterView':
      if (dataType === 'SNAPSHOT') {
        dispatch(initOms(payload));
        dispatch(initLiveData(payload));
      } else if (dataType === 'UPDATE') {
        // Send notification before update oms data.
        sendOmsNotification(msg.body, dispatch, getState);

        dispatch(updateOms(payload));
        dispatch(updateLiveData(payload));
      }
      break;
    default:
      break;
  }
};

const handleCommand = (msg, dispatch, getState) => {
  const { subType } = msg;
  switch (subType) {
    case 'LOGOUT':
      const user = getState().settings.user;
      dispatch(logout(user.englishName));
      break;
    case 'NOTIFICATION':
      dispatch(addNotificationToList(msg.body));
      break;
    default:
      break;
  }
};

const createHandleMsgFunc = (dispatch, getState) => {
  const _handleSubscriptionData = _.partial(
    handleSubscriptionData,
    _,
    dispatch,
    getState
  );

  const _handleCommand = _.partial(handleCommand, _, dispatch, getState);

  return msg => {
    console.log(msg);

    switch (msg.type) {
      case 'SUBSCRIPTION_DATA':
        _handleSubscriptionData(msg);
        break;
      case 'COMMAND':
        _handleCommand(msg);
        break;
      default:
        break;
    }
  };
};

let lastSubscription = null;

const setupSocketHandler = (socket, eventName, dispatch, getState) => {
  socket.on('connect', () =>
    console.log('Socket-IO connection to server is done.')
  );
  socket.on('disconnect', reason =>
    console.log(
      'Socket-IO connection to server is disconnected.',
      reason || 'UNKNOWN REASON'
    )
  );
  socket.on('error', error => console.log('Socket-IO error is thrown.', error));
  socket.on('connect_error', error =>
    console.log('Socket-IO connection error: ', error)
  );
  socket.on('reconnect', () => {
    console.log('Socket-IO re-connection is done.');

    const {
      auth: { isLoggedIn },
      settings: { user = {} }
    } = getState();
    if (isLoggedIn && user.englishName && lastSubscription) {
      dispatch(sendSubscription(lastSubscription));
    }
  });
  const handleMsgFunc = createHandleMsgFunc(dispatch, getState);

  socket.on(eventName, msg => {
    handleMsgFunc(msg);
  });
};

const defaultExecute = (action, emit, next, dispatch) => {
  console.log('Socket-IO to send message for type: ', action.type);

  if (['subscription', 'unSubscription'].includes(action.type))
    lastSubscription = action.payload;

  emit(action.type, action.payload);
  return next(action);
};

export default function createSocketIoMiddleware(
  socket,
  { eventName = 'message', execute = defaultExecute } = {}
) {
  const emitBound = socket.emit.bind(socket);
  const evaluate = action => {
    if (action && action.meta && action.meta.socketio) return true;
    return false;
  };

  return ({ dispatch, getState }) => {
    setupSocketHandler(socket, eventName, dispatch, getState);

    return next => action => {
      if (evaluate(action)) {
        return execute(action, emitBound, next, dispatch);
      }

      return next(action);
    };
  };
}
