import { isAlphaCaptureFund } from 'common/utils/DomainUtils';

const Order = {
  isIdleQtyAbnormal(o) {
    return (
      o &&
      !isAlphaCaptureFund(o.fundCode) &&
      (o.idleQty < 0 ||
        (o.idleQty > 0 &&
          (['WORKING', 'PARTFILL', 'ASSIGN', 'SENT'].includes(o.orderStatus) ||
            o.locateDayFilledPct > 0) &&
          o.pmStatus !== 'WITHDRAW' &&
          o.traderStatus !== 'REJECT'))
    );
  },
  isFilledPctAbnormal(o) {
    return o && o.filledPct > 1;
  },
  isAbnormal(o) {
    return (
      this.isIdleQtyAbnormal(o) ||
      this.isFilledPctAbnormal(o) ||
      (o && o.hasPendingRouteErrors)
    );
  },
  calcLeftQty(o) {
    return Math.max(o.tdyQty - o.workingQuantity, 0);
  }
};

export default Order;
