import React, { PureComponent } from 'react';
import { Modal, Button, DatePicker, Spin, Select } from 'antd';
import { DIALOG_BROKERS_INFO } from '../../omsConstants';
import { brokerCommisionResultTableColumns } from './GridColumnMap';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import Handsontable from 'handsontable';
import ReactDOMServer from 'react-dom/server';
import client from '../../api/client';
import moment from 'moment';
import _ from 'lodash';
import { Message } from 'semantic-ui-react';

const { MonthPicker } = DatePicker;
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c.refId || 'default'} value={c.refId}>
      ({c.refId}) {c.code}
    </Option>
  ));
};
class BrokerCommissionResultModal extends PureComponent {
  state = {
    submitStatus: 'READY',
    isInited: true,
    yearMonth: moment()
      .subtract(1, 'months')
      .format('YYYY-MM'),
    data: {},
    rules: [],
    ruleConfig: {
      JP: 'R-2',
      HK: 'R-4',
      CH: 'R-132',
      IN_CASH: 'R-42',
      IN: 'R-102'
    },
    gridWrapperStyle: {
      width: '100%',
      height: '300px',
      marginTop: '20px'
    },
    colorMap: {
      PB: '#FFCC33',
      EB: '#FF6666'
    }
  };

  colorHtmlRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //get color
    var color = this.state.colorMap[value];
    const innerHtml = ReactDOMServer.renderToString(
      <p
        style={{
          backgroundColor: color,
          width: '100%',
          color: 'black',
          textAlign: 'center'
        }}
      >
        {value}
      </p>
    );

    Handsontable.renderers.HtmlRenderer.call(
      this,
      instance,
      td,
      row,
      col,
      prop,
      innerHtml,
      cellProperties
    );
  };

  componentDidMount() {
    this._loadData(this.state.yearMonth);
  }

  _loadData = date => {
    this.setState({
      isInited: false
    });
    Promise.all([
      client.getBrokerCommissionReuslt(date),
      client.getRules(),
      client.getScoreCounterpartys()
    ])
      .then(([resp, ruleResult, counterpartys]) => {
        const rules = ruleResult.filter(r => r.type === 'ROUTING');
        this.setState({
          data: resp.data,
          rules,
          counterpartys,
          isInited: true
        });
      })
      .catch(err => console.log(err));
  };

  _createHotTable = (data, height, headerName, key) => {
    const { gridWrapperStyle, counterpartys } = this.state;
    const columns = brokerCommisionResultTableColumns.map(r => {
      if (r.data === 'percent') {
        return {
          ...r,
          headerName
        };
      }
      if (r.data === 'tier') {
        r.renderer = this.colorHtmlRenderer;
      }
      if (r.data === 'counterpartyBrokerList' && !_.isEmpty(counterpartys)) {
        const brokerList = counterpartys.map(r => r.brokerName);
        r.source = brokerList;
      }
      return { ...r };
    });

    const onBeforeCellChange = (changes, source) => {
      const realChanges = changes.filter(
        ([, , oldValue, newValue]) => oldValue !== newValue
      );
      if (!_.isEmpty(realChanges)) this._handleChanges(realChanges, data, key);

      return false;
    };

    const style = {
      ...gridWrapperStyle,
      height
    };
    const hotTableSettings = hotTableUtils.createSettings({
      columns
    });
    return (
      <div style={style}>
        <HotTable
          data={data}
          manualColumnResize={true}
          beforeChange={onBeforeCellChange}
          {...hotTableSettings}
        ></HotTable>
      </div>
    );
  };

  _handleChanges = (changes, d, key) => {
    const { counterpartys, data } = this.state;
    const updatedDetailData = changes.reduce((prev, c) => {
      const [row, field, oldValue, newValue] = c;
      if (oldValue === newValue) return prev;

      return prev.map((r, i) => {
        if (i === row) {
          if (field === 'counterpartyBrokerList' && oldValue !== newValue) {
            const filterData = counterpartys.filter(
              e => e.brokerName === newValue
            );
            if (!_.isEmpty(filterData)) {
              const filterResult = d.filter(
                e => e.counterpartyBrokerList === oldValue
              );
              if (!_.isEmpty(filterResult)) {
                const {
                  brokerName,
                  beauchampName,
                  custodian,
                  markets
                } = filterData[0];
                let cfdType = null;
                if (!_.isEmpty(markets)) {
                  cfdType =
                    markets.includes('FFS') && key === 'CH'
                      ? 'FFS'
                      : markets.includes('CFD')
                      ? 'CFD'
                      : null;
                }

                return {
                  ...filterResult[0],
                  broker: beauchampName,
                  counterpartyBrokerList: brokerName,
                  custodian: ['CH', 'IN_CASH'].includes(key) ? custodian : null,
                  cfdType: ['CH', 'IN_CASH'].includes(key) ? cfdType : null
                };
              }
            }
          } else {
            if (i === row) {
              return {
                ...r,
                [field]: newValue
              };
            }
            return r;
          }
        }
        return r;
      });
    }, d);
    const updateData = { ...data };
    updateData[key] = updatedDetailData;
    this.setState({
      data: updateData
    });
  };

  monthChange = (date, dataString) => {
    this.setState({
      yearMonth: dataString
    });
    this._loadData(dataString);
  };

  _createDateSelect = () => {
    return (
      <div style={{ width: '100%', height: '36px' }}>
        <MonthPicker
          value={moment(this.state.yearMonth, 'YYYY-MM')}
          format={'YYYY-MM'}
          allowClear={false}
          onChange={this.monthChange}
        />
      </div>
    );
  };

  _onSelectChange = (key, value) => {
    const { ruleConfig } = this.state;
    const updatedData = {
      ...ruleConfig,
      [key]: value
    };
    this.setState({
      ruleConfig: updatedData
    });
  };

  _createRuleInput = market => {
    const { ruleConfig, rules } = this.state;
    return (
      <Select
        value={ruleConfig[market]}
        style={{ width: '100%' }}
        onChange={value => {
          this._onSelectChange(market, value);
        }}
      >
        {_createUIOptions(rules)}
      </Select>
    );
  };

  _checkSaveData = () => {
    const { data } = this.state;
    const errors = [];
    let flag = false;
    Object.keys(data).forEach(r => {
      const keyData = data[r];
      keyData.forEach(e => {
        if (
          e &&
          e.counterpartyBrokerList &&
          e.counterpartyBrokerList.indexOf(',') >= 0
        ) {
          flag = true;
        }
      });
    });
    if (flag) errors.push('EMSX Broker must be only one');
    return errors;
  };

  _createResultPage = () => {
    const { data } = this.state;
    const value = data;
    return (
      <div style={{ width: '100%', height: '800px' }}>
        <div style={{ width: '50%', height: '100%', float: 'left' }}>
          <div
            style={{
              width: '100%',
              height: '40%',
              border: '1px solid',
              padding: '10px'
            }}
          >
            {this._createRuleInput('CH')}
            {this._createHotTable(value['CH'], '330px', '% CH', 'CH')}
          </div>
          <div
            style={{
              width: '100%',
              height: '60%',
              border: '1px solid',
              borderTop: '0px',
              padding: '10px'
            }}
          >
            {this._createRuleInput('HK')}
            {this._createHotTable(value['HK'], '430px', '% HK', 'HK')}
          </div>
        </div>
        <div style={{ width: '50%', height: '100%', float: 'left' }}>
          <div
            style={{
              width: '100%',
              height: `${(1.6 / 4) * 100}%`,
              border: '1px solid',
              borderLeft: '0px',
              padding: '10px'
            }}
          >
            {this._createRuleInput('JP')}
            {this._createHotTable(value['JP'], '220px', '% JP', 'JP')}
          </div>
          <div
            style={{
              width: '100%',
              height: `${(1.3 / 4) * 100}%`,
              borderRight: '1px solid',
              padding: '10px'
            }}
          >
            {this._createRuleInput('IN')}
            {this._createHotTable(value['IN'], '200px', '% IN FnO', 'IN')}
          </div>
          <div
            style={{
              width: '100%',
              height: `${(1.1 / 4) * 100}%`,
              border: '1px solid',
              borderLeft: '0px',
              padding: '10px'
            }}
          >
            {this._createRuleInput('IN_CASH')}
            {this._createHotTable(value['IN_CASH'], '100px', '% IN', 'IN_CASH')}
          </div>
        </div>
      </div>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_BROKERS_INFO);
  };

  _onSubmit = () => {
    const errors = this._checkSaveData();
    if (!_.isEmpty(errors)) {
      this.setState({
        errors
      });
      return;
    }
    const { data, ruleConfig } = this.state;
    const { onSubmit } = this.props;
    const saveData = {};
    Object.keys(data).forEach(r => {
      saveData[r] = {
        ruleId: ruleConfig[r],
        detail: data[r]
      };
    });
    onSubmit({ data: saveData });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.props;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Applying
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Apply
        </Button>
      )
    }[submitStatus];
  };

  _createErrorsPanel = () => {
    const { errors } = this.state;
    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errors) && (
          <Message error list={errors} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  render() {
    const { isInited } = this.state;
    return (
      <Modal
        width={1200}
        maskClosable={false}
        title="Apply Rule"
        visible={true}
        bodyStyle={{ paddingTop: '6px' }}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin spinning={!isInited}>
          {this._createDateSelect()}
          {this._createResultPage()}
          {this._createErrorsPanel()}
        </Spin>
      </Modal>
    );
  }
}

export default BrokerCommissionResultModal;
