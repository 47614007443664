const PosCalculator = {
  calc(positions, riskInfo, posFilter) {
    if (!riskInfo) return [];

    const { fundCode, bookCode } = riskInfo;
    const filteredPositions = positions.filter(
      i =>
        i.fundCode === fundCode &&
        (fundCode === bookCode || i.bookCode === bookCode) &&
        posFilter(i)
    );

    if (!riskInfo.nav || riskInfo.nav === 0) return filteredPositions;

    return filteredPositions.map(p => this.calcSingle(p, riskInfo));
  },
  calcSingle(p, riskInfo) {
    if (!riskInfo || !riskInfo.nav || riskInfo.nav === 0) return p;

    return {
      ...p,
      notnlMktValBookPct: p['notnlMktValBook'] / riskInfo.nav,
      targetNotnlMktValBookPct: p['targetNotnlMktValBook'] / riskInfo.nav,
      betaNotnlMktValBookPct: p['betaNotnlMktValBook'] / riskInfo.nav,
      targetBetaNotnlMktValBookPct:
        p['targetBetaNotnlMktValBook'] / riskInfo.nav,
      dtdPnlPct: p['dtdPnl'] / riskInfo.nav,
      mtdPnlPct: p['mtdPnl'] / riskInfo.nav,
      ytdPnlPct: p['ytdPnl'] / riskInfo.nav,
      deltaNotnlMktValBookPct: p['deltaNotnlMktValBook'] / riskInfo.nav
    };
  }
};

export default PosCalculator;
