import { combineReducers } from 'redux';
import portfolioReducer from 'features/portfolio/portfolioReducer';
import orderReducer from 'features/order/orderReducer';
import authReducer from 'features/auth/authReducer';
import settingReducer from 'features/settings/settingReducer';
import layoutReducer from 'features/layout/layoutReducer';
import researchReducer from 'features/research/researchReducer';
import omsReducer from 'features/oms/reducers/omsReducer';
import commonReducer from 'features/common/reducers/commonReducer';
import notificationReducer from 'features/notification/notificationReducer';
import riskReducer from 'features/risk/riskReducer';
import treasuryReducer from 'features/treasury/treasuryReducer';
import t0Reducer from 'features/livedata/t0Reducer';

const rootReducer = combineReducers({
  portfolio: portfolioReducer,
  order: orderReducer,
  auth: authReducer,
  settings: settingReducer,
  layout: layoutReducer,
  research: researchReducer,
  oms: omsReducer,
  common: commonReducer,
  notification: notificationReducer,
  risk: riskReducer,
  treasury: treasuryReducer,
  t0: t0Reducer
});

export default rootReducer;
