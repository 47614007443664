import React, { Component } from 'react';
import SplitPane from 'react-split-pane';
import DashbordDataGrid from './DashbordDataGrid';
import DashbordSubDataGrid from './DashbordSubDataGrid';
import DashbordThirdDataGrid from './DashbordThirdDataGrid';
import GraphAnalysisPannel from './GraphAnalysisPannel';
import { Button, DatePicker, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import client from '../../api/client';
import { moneyFormatter } from 'common/utils/valueFormatters';
import { numberStyle } from 'common/utils/styleChooser';

const { Option } = Select;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class TreasuryDashbordPannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStatus: false,
      data: [],
      selectBroker: null,
      secondResult: [],
      viewModeList: ['BY_MONTH', 'BY_YEAR'],
      queryParams: {
        // mode: 'BY_MONTH',
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        fund: 'ALL',
        view: 'BY_BROKER'
      },
      secLevelParams: {
        mode: 'BY_COUNTRY',
        showColumns: ['Expense-All']
      },
      thirdLevelParams: {},
      fundList: [...this.props.fundList, 'ALL'],
      secLevelModeList: ['BY_COUNTRY', 'BY_ASSET'],
      secLevelColumnList: [
        'Expense-All',
        'Expense-Long Swap Benchmark',
        'Expense-Long Swap Spread',
        'Expense-FFS Benchmark',
        'Expense-FFS Spread',
        'Expense-Swap Stock Loan',
        'Expense-CFD INT OLD',
        'Expense-SWAP',
        'Expense-Commission'
      ],
      dataMap: {},
      histReviewData: [],
      selectedBrokerDatas: [],
      thirdSelectedBrokerDatas: []
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _onInputChange = ({ name, value }) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        [name]: value
      }
    });
  };

  onSelectedBrokerData = data => {
    this.setState({
      selectedBrokerDatas: data
    });
  };

  dateOnChange = (name, dateString) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        [name]: dateString
      }
    });
  };

  _onSecLevelChange = ({ name, value }) => {
    const { secLevelParams } = this.state;
    this.setState({
      secLevelParams: {
        ...secLevelParams,
        [name]: value
      }
    });
  };

  _onThirdLevelChange = ({ name, value }) => {
    const { thirdLevelParams } = this.state;
    this.setState({
      thirdLevelParams: {
        ...thirdLevelParams,
        [name]: value
      }
    });
  };

  _createQueryHeader = () => {
    const { loadingStatus } = this.state;
    const { startDate, endDate, fund } = this.state.queryParams;
    return (
      <div style={{ textAlign: 'right' }}>
        <Select
          style={{ marginRight: '5px', width: '120px' }}
          value={fund}
          size="small"
          onChange={value => {
            this._onInputChange({
              name: 'fund',
              value: value
            });
          }}
        >
          {_createUIOptions(this.state.fundList)}
        </Select>
        <DatePicker
          style={{ marginRight: '5px', width: '120px' }}
          allowClear={false}
          format={'YYYY-MM-DD'}
          value={moment(startDate, 'YYYY-MM-DD')}
          size="small"
          onChange={(date, str) => {
            this.dateOnChange('startDate', str);
          }}
        />
        <DatePicker
          style={{ marginRight: '5px', width: '120px' }}
          allowClear={false}
          format={'YYYY-MM-DD'}
          value={moment(endDate, 'YYYY-MM-DD')}
          size="small"
          onChange={(date, str) => {
            this.dateOnChange('endDate', str);
          }}
        />
        <Button
          type="primary"
          size="small"
          style={{ marginRight: '5px' }}
          disabled={loadingStatus}
          onClick={() => {
            this._loadData(false);
          }}
        >
          {loadingStatus ? 'Loading' : 'Reload'}
        </Button>
      </div>
    );
  };

  _loadData = () => {
    const { startDate, endDate, fund } = this.state.queryParams;

    const fundParams = fund === 'ALL' ? this.props.fundList : [fund];
    const params = {
      startDate,
      endDate,
      funds: _.join(fundParams, ',')
    };
    this.setState({
      loadingStatus: true
    });
    client
      .createCommissionReport({ name: 'ChargesReport', params })
      .then(resp => {
        this._loadHistData();
        const data =
          resp && resp.views ? this.getData(resp.views['BY_BROKER']) : [];
        const secondData =
          resp && resp.views
            ? this.getData(resp.views['BY_COUNTRY_ASSET'])
            : [];
        const result = data.filter(r => r['Expense-All'] || r['Income-All']);
        const secondResult = secondData.filter(
          r => r['Expense-All'] || r['Income-All']
        );
        this.setState({
          data: result,
          secondResult,
          loadingStatus: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadingStatus: false
        });
      });
  };

  _buildData = (columns, data, firstColData) => {
    return data.map((r, index) => {
      const item = [firstColData[index], ...r];
      return _.zipObject(columns, item);
    });
  };

  getData = viewData => {
    if (viewData) {
      const { columns, data, index } = viewData;
      const flatColumns = _.isEmpty(index) ? [] : ['Broker'];
      columns.forEach(e => {
        flatColumns.push(_.join(e, '-'));
      });
      const dataList = this._buildData(flatColumns, data, index);
      return dataList;
    }
    return [];
  };

  selectBroker = broker => {
    this.setState(
      {
        selectBroker: broker
      },
      this._loadHistData
    );
  };

  _loadHistData = () => {};

  _getDataByMonth = (data, month) => {
    return data.map(r => ({
      ...r,
      month
    }));
  };

  _createSecLevelGrid = () => {
    const {
      selectedBrokerDatas,
      secondResult,
      secLevelModeList,
      secLevelParams
    } = this.state;
    const config = this._getSecLevelData(
      secondResult,
      selectedBrokerDatas,
      secLevelModeList.indexOf(secLevelParams.mode)
    );
    return (
      <DashbordSubDataGrid
        {...this.props}
        params={secLevelParams}
        config={config}
        secLevelModeList={this.state.secLevelModeList}
        secLevelColumnList={this.state.secLevelColumnList}
        onInputChange={this._onSecLevelChange}
        onRowSelected={this._onSecBrokerSelected}
      />
    );
  };

  _getSecLevelData = (secondResult, selectedBrokerDatas, index) => {
    const { data, secLevelParams } = this.state;
    const { showColumns, optionFilter } = secLevelParams;
    const options = [];
    const brokerList = (!_.isEmpty(selectedBrokerDatas)
      ? selectedBrokerDatas
      : data
    ).map(r => r.Broker);
    const secData = secondResult
      .filter(
        r =>
          r.Broker && brokerList.includes(r.Broker[0]) && r.Broker[0] !== 'All'
      )
      .map(r => {
        const item = {
          ...r,
          Broker: r.Broker[0],
          SecFldName: r.Broker[index + 1]
        };
        if (!options.includes(item.SecFldName)) {
          options.push(item.SecFldName);
        }
        return item;
      })
      .filter(
        r => _.isEmpty(optionFilter) || optionFilter.includes(r.SecFldName)
      );

    const secResultData = [];
    const columns = [
      {
        field: 'Broker',
        headerName: 'Broker',
        cellClass: 'keyword',
        lockPinned: true,
        pinned: 'left'
      }
    ];
    secData.forEach(r => {
      const filterItems = secResultData.filter(e => e.Broker === r.Broker);
      const currentColumns = columns.filter(e => e.headerName === r.SecFldName);
      let currentColumn = {
        field: '',
        headerName: r.SecFldName,
        children: []
      };
      if (_.isEmpty(currentColumns)) {
        columns.push(currentColumn);
      } else {
        currentColumn = currentColumns[0];
      }
      let item = {
        Broker: r.Broker,
        SecFldName: r.SecFldName
      };
      if (_.isEmpty(filterItems)) {
        secResultData.push(item);
      } else {
        item = filterItems[0];
      }
      showColumns.forEach(e => {
        const fld = `${r.SecFldName}_${e}`;
        const currentChildColumns = currentColumn.children.filter(
          m => m.field === fld
        );
        if (_.isEmpty(currentChildColumns)) {
          currentColumn.children.push({
            field: fld,
            headerName: e,
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          });
        }
        item[fld] = (item[fld] ? item[fld] : 0) + r[e];
      });
    });
    return {
      data: secResultData,
      columns: columns,
      options
    };
  };

  _onSecBrokerSelected = data => {
    this.setState({
      thirdSelectedBrokerDatas: data
    });
  };

  _createThirdLevelGrid = () => {
    const {
      thirdSelectedBrokerDatas,
      secondResult,
      secLevelModeList,
      secLevelParams,
      thirdLevelParams
    } = this.state;
    const modes = secLevelModeList.filter(r => r !== secLevelParams.mode);
    const config = this._getThirdLevelData(
      secondResult,
      thirdSelectedBrokerDatas,
      secLevelModeList.indexOf(modes[0])
    );
    return (
      <DashbordThirdDataGrid
        {...this.props}
        params={thirdLevelParams}
        config={config}
        onInputChange={this._onThirdLevelChange}
      />
    );
  };

  _getThirdLevelData = (secondResult, selectedBrokerDatas, index) => {
    const { secLevelParams, secLevelModeList, thirdLevelParams } = this.state;
    const { showColumns } = secLevelParams;
    const { optionFilter } = thirdLevelParams;
    const brokerList = selectedBrokerDatas.map(r => r.Broker);

    const secResultData = [];
    const options = [];
    const fldKey =
      secLevelModeList[index] === 'BY_COUNTRY' ? 'Asset' : 'Country';
    const dataKey =
      secLevelModeList[index] === 'BY_COUNTRY' ? 'Country' : 'Asset';
    const secData = secondResult
      .filter(
        r =>
          r.Broker && brokerList.includes(r.Broker[0]) && r.Broker[0] !== 'All'
      )
      .map(r => {
        const item = { ...r, Country: r.Broker[1], Asset: r.Broker[2] };
        if (!options.includes(item[dataKey])) {
          options.push(item[dataKey]);
        }
        return item;
      })
      .filter(
        r => _.isEmpty(optionFilter) || optionFilter.includes(r[dataKey])
      );
    const columns = [
      {
        field: fldKey,
        headerName: fldKey,
        cellClass: 'keyword',
        lockPinned: true,
        pinned: 'left'
      }
    ];
    let i = 0;
    secData.forEach(r => {
      const filterItems = secResultData.filter(e => e[fldKey] === r[fldKey]);
      const currentColumns = columns.filter(e => e.headerName === r[dataKey]);
      let currentColumn = {
        field: '',
        headerName: r[dataKey],
        children: []
      };
      if (_.isEmpty(currentColumns)) {
        columns.push(currentColumn);
      } else {
        currentColumn = currentColumns[0];
      }
      let item = {
        id: i++,
        [fldKey]: r[fldKey],
        [dataKey]: r[dataKey]
      };
      if (_.isEmpty(filterItems)) {
        secResultData.push(item);
      } else {
        item = filterItems[0];
      }
      showColumns.forEach(e => {
        const fld = `${r[dataKey]}_${e}`;
        const currentChildColumns = currentColumn.children.filter(
          m => m.field === fld
        );
        if (_.isEmpty(currentChildColumns)) {
          currentColumn.children.push({
            field: fld,
            headerName: e,
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          });
        }
        item[fld] = (item[fld] ? item[fld] : 0) + r[e];
      });
    });
    return {
      data: secResultData,
      columns: columns,
      options
    };
  };

  render() {
    return (
      <div className="widget">
        {this._createQueryHeader()}
        {!_.isEmpty(this.state.data) && (
          <SplitPane
            split="horizontal"
            defaultSize="40%"
            style={{ position: 'relative', overflow: 'auto' }}
          >
            <SplitPane split="vertical" defaultSize="75%">
              <DashbordDataGrid
                {...this.props}
                onSelectedBrokerData={this.onSelectedBrokerData}
                data={this.state.data}
              />
              <GraphAnalysisPannel
                {...this.props}
                data={this.state.data}
                selectBrokerName={this.state.selectBroker}
                selectBroker={this.selectBroker}
              />
            </SplitPane>
            <SplitPane
              split="horizontal"
              defaultSize="50%"
              style={{ position: 'relative', overflow: 'auto' }}
            >
              <SplitPane split="vertical" defaultSize="100%">
                {this._createSecLevelGrid()}
                <GraphAnalysisPannel
                  {...this.props}
                  data={this.state.data}
                  selectBrokerName={this.state.selectBroker}
                  selectBroker={this.selectBroker}
                />
              </SplitPane>
              <SplitPane split="vertical" defaultSize="100%">
                {this._createThirdLevelGrid()}
                <GraphAnalysisPannel
                  {...this.props}
                  data={this.state.data}
                  selectBrokerName={this.state.selectBroker}
                  selectBroker={this.selectBroker}
                />
              </SplitPane>
            </SplitPane>
          </SplitPane>
        )}
      </div>
    );
  }
}

export default TreasuryDashbordPannel;
