import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {
  getReportList: params => {
    const url = `${config.api.RISK_URL}/reportInfo/getReportList`;
    return apiClient.post(url, params);
  },

  getAttributionReport: params => {
    const url = `${config.api.RISK_URL}/reportInfo/getAttributionReport`;
    return apiClient.post(url, params);
  },

  getFactorAsset: params => {
    const url = `${config.api.RISK_URL}/reportInfo/getFactorAsset`;
    return apiClient.post(url, params);
  },

  getReturnFactorAsset: params => {
    const url = `${config.api.RISK_URL}/reportInfo/getReturnFactorAsset`;
    return apiClient.post(url, params);
  },

  addAccessLog: params => {
    const url = `${config.api.RESEARCH_URL}/access/addAccessLog`;
    return apiClient.post(url, params);
  }
};

export default client;
