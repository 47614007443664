import _ from 'lodash';
import PosTradeValidator from '../validators/PosTradeValidator';
import { isAlmostEqual } from 'common/utils/MathUtils';

const POS_FIELD = {
  MV_PCT: 'mvPct',
  TGT_MV_PCT: 'tgtMvPct',
  CLS_MV_PCT: 'clsMvPct',
  LOCKED_TGT_MV_PCT: 'lockedTgtMvPct',
  BETA_MV_PCT: 'betaMvPct',
  TGT_BETA_MV_PCT: 'tgtBetaMvPct',
  CLS_BETA_MV_PCT: 'clsBetaMvPct',
  LOCKED_TGT_BETA_MV_PCT: 'lockedTgtBetaMvPct',

  TRD_PCT: 'tradePct',
  TRD_QTY: 'tradeQty',
  TRD_SIDE: 'tradeSide'
};

const MV_TYPE = {
  MV: 'MV',
  BETA_MV: 'BETA_MV'
};

const MV_GROUP = {
  LONG: 'LONG',
  SHORT: 'SHORT',
  GROSS: 'GROSS',
  NET: 'NET'
};

const MV_SOURCE = {
  CUR: 'CUR',
  TGT: 'TGT',
  CLS: 'CLS',
  LOCKED: 'LOCKED',
  FRZN: 'FRZN'
};

const POS_FIELD_CONFIG = {
  [MV_TYPE.MV]: {
    [MV_SOURCE.CUR]: POS_FIELD.MV_PCT,
    [MV_SOURCE.TGT]: POS_FIELD.TGT_MV_PCT,
    [MV_SOURCE.CLS]: POS_FIELD.CLS_MV_PCT,
    [MV_SOURCE.LOCKED]: POS_FIELD.LOCKED_TGT_MV_PCT
  },
  [MV_TYPE.BETA_MV]: {
    [MV_SOURCE.CUR]: POS_FIELD.BETA_MV_PCT,
    [MV_SOURCE.TGT]: POS_FIELD.TGT_BETA_MV_PCT,
    [MV_SOURCE.CLS]: POS_FIELD.CLS_BETA_MV_PCT,
    [MV_SOURCE.LOCKED]: POS_FIELD.LOCKED_TGT_BETA_MV_PCT
  }
};

const TRD_GROUP = {
  LONG: 'LONG',
  SHORT: 'SHORT'
};

const TRD_FACTOR = {
  COUNT: 'COUNT',
  TURNOVER: 'TURNOVER'
};

const POS_DIR = {
  LONG: 'LONG',
  SHORT: 'SHORT',
  FLAT: 'FLAT'
};

const REG_MV_ADJ_TYPE = /^(\w+)-([\w_]+)/i;

const _calcPosFrozenMvPct = (p, mvType) => {
  const curField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CUR];
  const clsField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CLS];
  const lockedField = POS_FIELD_CONFIG[mvType][MV_SOURCE.LOCKED];
  if (p.isLocked) return p[lockedField];
  return p[curField] - p[clsField];
};

const _calcWeight = (positions, mvType, mvPct, oldMvPct) => {
  const curField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CUR];
  const lockedField = POS_FIELD_CONFIG[mvType][MV_SOURCE.LOCKED];

  const totalNonLockedMvPct = _.sumBy(
    positions.map(p => (p.isLocked ? 0 : p[curField]))
  );
  const leftMvPct = mvPct - _.sumBy(positions.map(p => p[lockedField]));

  return positions.map(p => {
    const weight =
      mvPct === 0
        ? 0
        : (p[lockedField] +
            (totalNonLockedMvPct === 0
              ? 0
              : ((p.isLocked ? 0 : p[curField]) / totalNonLockedMvPct) *
                leftMvPct)) /
          mvPct;

    return {
      ...p,
      weight: isAlmostEqual(weight, 0) ? 0 : weight
    };
  });
};

const _calcPosOtherTgtMvPct = (p, field) => {
  const mvType = _.findKey(POS_FIELD_CONFIG, c => c[MV_SOURCE.TGT] === field);

  const curField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CUR];

  const otherMvType = mvType === MV_TYPE.MV ? MV_TYPE.BETA_MV : MV_TYPE.MV;
  const otherCurField = POS_FIELD_CONFIG[otherMvType][MV_SOURCE.CUR];
  const otherTgtField = POS_FIELD_CONFIG[otherMvType][MV_SOURCE.TGT];

  const curValue = p[curField];
  const tgtValue = p[field];
  const otherCurValue = p[otherCurField];

  return {
    [otherTgtField]: (tgtValue / curValue) * otherCurValue
  };
};

const _calcPosTgtMvPctByTrdPct = p => {
  const tgtMvPct = p.mvPct + p.tradePct;
  return {
    tgtMvPct
  };
};

const _calcPosTrdPctByTgtMvPct = p => {
  const tradePct = p.tgtMvPct - p.mvPct;
  return {
    tradePct
  };
};

const _calcTrdQtyByTrdPct = p => {
  const signedTradeQty = isAlmostEqual(p.tradePct + p.clsMvPct, 0)
    ? p.clsQty * -1
    : _.round(((p.tradePct / p.mvPct) * p.qty) / p.roundLotSize) *
      p.roundLotSize;

  const tradeSide =
    signedTradeQty === 0
      ? null
      : p.dir === POS_DIR.LONG
      ? signedTradeQty > 0
        ? 'BUY'
        : 'SELL'
      : signedTradeQty > 0
      ? 'COVR'
      : 'SHRT';

  return {
    tradePct: signedTradeQty === 0 ? 0 : p.tradePct,
    tradeSide,
    tradeQty: Math.abs(signedTradeQty)
  };
};

const _calcTrdPctByTrdQty = p => {
  const sign = ['BUY', 'COVR'].includes(p.tradeSide) ? 1 : -1;
  const tradePct =
    p.tradeQty === 0 ? 0 : Math.abs((p.tradeQty / p.qty) * p.mvPct) * sign;
  return {
    tradePct,
    tradeSide: tradePct === 0 ? null : p.tradeSide
  };
};

const POS_FIELD_CALC_CFG = {
  [POS_FIELD.TGT_BETA_MV_PCT]: [
    _.partial(_calcPosOtherTgtMvPct, _, POS_FIELD.TGT_BETA_MV_PCT),
    _calcPosTrdPctByTgtMvPct,
    _calcTrdQtyByTrdPct
  ],
  [POS_FIELD.TGT_MV_PCT]: [
    _.partial(_calcPosOtherTgtMvPct, _, POS_FIELD.TGT_MV_PCT),
    _calcPosTrdPctByTgtMvPct,
    _calcTrdQtyByTrdPct
  ],
  [POS_FIELD.TRD_PCT]: [
    _calcPosTgtMvPctByTrdPct,
    _.partial(_calcPosOtherTgtMvPct, _, POS_FIELD.TGT_MV_PCT),
    _calcTrdQtyByTrdPct
  ],
  [POS_FIELD.TRD_QTY]: [
    _calcTrdPctByTrdQty,
    _calcPosTgtMvPctByTrdPct,
    _.partial(_calcPosOtherTgtMvPct, _, POS_FIELD.TGT_MV_PCT)
  ],
  [POS_FIELD.TRD_SIDE]: [
    _calcTrdPctByTrdQty,
    _calcPosTgtMvPctByTrdPct,
    _.partial(_calcPosOtherTgtMvPct, _, POS_FIELD.TGT_MV_PCT)
  ]
};

const _calcPosition = (p, field) => {
  const updatedPos = (POS_FIELD_CALC_CFG[field] || []).reduce((prev, fn) => {
    return {
      ...prev,
      ...fn(prev)
    };
  }, p);

  const errors =
    updatedPos.tradeQty !== 0 ? PosTradeValidator.validate(updatedPos) : {};

  return {
    ...updatedPos,
    errors,
    hasErrors: !_.isEmpty(errors)
  };
};

const _calcPositions = (positions, mvType, valPct, oldValPct) => {
  if (_.isEmpty(positions)) return [];

  const curField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CUR];
  const clsField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CLS];
  const tgtField = POS_FIELD_CONFIG[mvType][MV_SOURCE.TGT];

  const weightedPositions = _calcWeight(positions, mvType, valPct, oldValPct);
  return weightedPositions.map(p => {
    const tgtValue = p.weight * valPct;
    const minTgtValue = p[curField] - p[clsField];

    return _calcPosition(
      {
        ...p,
        [tgtField]:
          p.dir === POS_DIR.LONG
            ? Math.max(tgtValue, minTgtValue)
            : Math.min(tgtValue, minTgtValue)
      },
      tgtField
    );
  });
};

const parseMvAdjType = (group, mvType, src) => {
  return `${group}-${mvType}-${src}`;
};

const _calcPortfolioMvs = portfolio => {
  const mvs = {};
  _.forEach([POS_DIR.LONG, POS_DIR.SHORT], dir => {
    const positions = portfolio[dir] || [];
    positions.forEach(p => {
      _.keys(MV_SOURCE).forEach(src =>
        _.keys(MV_TYPE).forEach(mvType => {
          const createMvAdjTypeByGroup = _.partial(
            parseMvAdjType,
            _,
            mvType,
            src
          );
          const field = POS_FIELD_CONFIG[mvType][src];
          const mvAdjType = createMvAdjTypeByGroup(dir);
          if (src === MV_SOURCE.FRZN) {
            mvs[mvAdjType] =
              (mvs[mvAdjType] || 0) + _calcPosFrozenMvPct(p, mvType);
          } else {
            mvs[mvAdjType] = (mvs[mvAdjType] || 0) + p[field];
          }

          // Calculate GROSS and NET.
          if (![MV_SOURCE.CUR, MV_SOURCE.TGT].includes(src)) return;

          if (mvType === MV_TYPE.MV) {
            const mvAdjType = createMvAdjTypeByGroup(MV_GROUP.GROSS);
            mvs[mvAdjType] = (mvs[mvAdjType] || 0) + Math.abs(p[field]);
          }

          if (mvType === MV_TYPE.BETA_MV) {
            const mvAdjType = createMvAdjTypeByGroup(MV_GROUP.NET);
            mvs[mvAdjType] = (mvs[mvAdjType] || 0) + p[field];
          }
        })
      );
    });
  });

  return mvs;
};

const parseTrdFactorType = (group, factor) => {
  return `${group}-${factor}`;
};

const _calcTrdSummary = portfolio => {
  let tradeSummary = {};
  _.forEach([POS_DIR.LONG, POS_DIR.SHORT], dir => {
    const positions = portfolio[dir] || [];
    const countType = `${dir}-${TRD_FACTOR.COUNT}`;
    const turnoverType = `${dir}-${TRD_FACTOR.TURNOVER}`;
    positions.forEach(p => {
      tradeSummary[countType] =
        (tradeSummary[countType] || 0) + (p.tradeQty !== 0 ? 1 : 0);
      tradeSummary[turnoverType] =
        (tradeSummary[turnoverType] || 0) + Math.abs(p.tradePct);
    });
  });

  return tradeSummary;
};

const _calcPortfolioSummary = portfolio => {
  return {
    portfolioMvs: _calcPortfolioMvs(portfolio),
    tradeSummary: _calcTrdSummary(portfolio)
  };
};

const PortfolioCalculator = {
  calcAll(portfolio, mvAdjType, valPct, oldValPct) {
    const matches = (mvAdjType || '').match(REG_MV_ADJ_TYPE);
    const [, dir, mvType] = matches || [];

    const positions = portfolio[dir] || [];
    const updatedPositions = _calcPositions(
      positions,
      mvType,
      valPct,
      oldValPct
    );

    const updatedPortfolio = {
      ...portfolio,
      [dir]: updatedPositions
    };

    const portfolioSummary = _calcPortfolioSummary(updatedPortfolio);

    return {
      portfolio: updatedPortfolio,
      portfolioSummary
    };
  },
  calcPortfolioSummary(portfolio) {
    return _calcPortfolioSummary(portfolio);
  },
  calcPosition(pos, tgtField) {
    return _calcPosition(pos, tgtField);
  },
  calcTargetMvPctByRelative(portfolio, dir, mvType, relativePct) {
    const curField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CUR];
    const clsField = POS_FIELD_CONFIG[mvType][MV_SOURCE.CLS];
    const lockedField = POS_FIELD_CONFIG[mvType][MV_SOURCE.LOCKED];
    return _.sumBy(
      portfolio[dir].map(p => {
        if (p.isLocked) return p[lockedField];

        const minTgtValue = p[curField] - p[clsField];
        const tgtValue = (1 + relativePct / 100) * p[curField];
        return p.dir === POS_DIR.LONG
          ? Math.max(tgtValue, minTgtValue)
          : Math.min(tgtValue, minTgtValue);
      })
    );
  }
};

export {
  PortfolioCalculator,
  MV_GROUP,
  MV_TYPE,
  MV_SOURCE,
  POS_DIR,
  parseMvAdjType,
  TRD_GROUP,
  TRD_FACTOR,
  parseTrdFactorType,
  POS_FIELD_CALC_CFG
};
