import React, { Component } from 'react';
import { Modal, Select, DatePicker, Button } from 'antd';
import 'ag-grid-enterprise';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Bar
} from 'recharts';
import client from '../../api/client';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

const Option = Select.Option;
const { RangePicker } = DatePicker;

const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class ExposureReviewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadStatus: 'READY',
      query: {
        date: [],
        fund: null
      },
      selectOptions: {
        fund: [],
        custodian: [],
        ccy: [],
        fields: []
      },
      lineDataParams: {
        custodianList: [],
        ccyList: [],
        fields: []
      },
      lineData: [],
      colors: this._getRandomColor(100)
    };
  }

  componentDidMount() {
    const { lineDataParams } = this.state;
    const { date, fund, selectOptions, selectedData } = this.props.data;
    const startDate = moment(date, 'YYYY-MM-DD').subtract(30, 'days');
    const query = {
      date: [startDate, moment(date, 'YYYY-MM-DD')],
      fund
    };
    this.setState(
      {
        query,
        selectOptions,
        lineDataParams: {
          ...lineDataParams,
          ...selectedData
        }
      },
      this._loadData
    );
  }

  closeDialog = () => {};

  _loadData = query => {
    const { date, fund } = this.state.query;
    this.setState({
      loadStatus: 'LOADING'
    });
    const params = {
      startDate: date[0].format('YYYY-MM-DD'),
      endDate: date[1].format('YYYY-MM-DD'),
      fund
    };
    client
      .getExposureSnapshotList(params)
      .then(resp => {
        const data = resp.data;
        const lineData = [];
        data.forEach(r => {
          const { date, fund, data } = r;
          const { columns, data: rowData } = JSON.parse(data);
          const flatColumns = [];
          columns.forEach(e => {
            flatColumns.push(...e);
          });
          rowData.forEach(e => {
            const item = this._buildData(flatColumns, e);
            lineData.push({ ...item, date, fund });
          });
        });
        this.setState({
          lineData,
          loadStatus: 'READY'
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadStatus: 'READY'
        });
      });
  };

  _changeReviewDate = dates => {
    const { query } = this.state;
    this.setState({
      query: {
        ...query,
        date: dates
      }
    });
  };

  onOpenChange = () => {
    this._changeReviewDate([]);
  };

  _onQueryChange = ({ name, value }) => {
    const { query } = this.state;
    this.setState({
      query: {
        ...query,
        [name]: value
      }
    });
  };

  _onLineDataChange = ({ name, value }) => {
    const { lineDataParams } = this.state;
    this.setState({
      lineDataParams: {
        ...lineDataParams,
        [name]: value
      }
    });
  };

  _createQueryHeader = () => {
    const { selectOptions, lineDataParams, query } = this.state;
    return (
      <div>
        <RangePicker
          size="small"
          style={{ width: '220px' }}
          onCalendarChange={this._changeReviewDate}
          value={query.date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onChange={this._changeReviewDate}
        />

        <Select
          allowClear={false}
          size="small"
          showSearch
          onChange={value => {
            this._onQueryChange({ name: 'fund', value });
          }}
          value={query.fund}
          style={{ marginLeft: '5px', width: 120 }}
        >
          {_createUIOptions(selectOptions.fund)}
        </Select>

        <Select
          mode="multiple"
          size="small"
          showSearch
          onChange={value => {
            this._onLineDataChange({ name: 'custodianList', value });
          }}
          value={lineDataParams.custodianList}
          style={{ marginLeft: '5px', width: 250 }}
        >
          {_createUIOptions(selectOptions.custodian)}
        </Select>

        <Select
          mode="multiple"
          size="small"
          showSearch
          onChange={value => {
            this._onLineDataChange({ name: 'ccyList', value });
          }}
          value={lineDataParams.ccyList}
          style={{ marginLeft: '5px', width: 250 }}
        >
          {_createUIOptions(selectOptions.ccy)}
        </Select>

        <Select
          mode="multiple"
          size="small"
          showSearch
          onChange={value => {
            this._onLineDataChange({ name: 'fields', value });
          }}
          value={lineDataParams.fields}
          style={{ marginLeft: '5px', width: 250 }}
        >
          {_createUIOptions(selectOptions.fields)}
        </Select>

        <Button
          type="primary"
          size="small"
          onClick={this._loadData}
          style={{ marginLeft: '5px' }}
        >
          {' '}
          Reload
        </Button>
      </div>
    );
  };

  _filterLineData = () => {
    const { lineDataParams, lineData, query } = this.state;
    const { custodianList, ccyList, fields } = lineDataParams;
    let data = [];
    const keyList = [];
    if (
      _.isEmpty(ccyList) &&
      _.isEmpty(custodianList) &&
      !_.isEmpty(lineData)
    ) {
      const groupData = _.groupBy(lineData, 'date');
      Object.keys(groupData).forEach(r => {
        const item = { date: r, Fund: query.fund };
        fields.forEach(f => (item[f] = _.sumBy(groupData[r], f)));
        data.push(item);
      });
    } else if (_.isEmpty(ccyList) && !_.isEmpty(lineData)) {
      //sum field
      const brokerGroup = _.groupBy(lineData, 'Broker');
      Object.keys(brokerGroup).forEach(e => {
        const groupData = _.groupBy(brokerGroup[e], 'date');
        Object.keys(groupData).forEach(r => {
          const item = { Broker: e, date: r };
          fields.forEach(f => (item[f] = _.sumBy(groupData[r], f)));
          data.push(item);
        });
      });
      data = data.filter(r => custodianList.includes(r.Broker));
    } else {
      data = lineData.filter(
        r => custodianList.includes(r.Broker) && ccyList.includes(r.CCY)
      );
    }
    if (!_.isEmpty(data)) {
      const dateGroup = _.groupBy(data, 'date');
      data = Object.keys(dateGroup).map(r => {
        const itemData = dateGroup[r];
        const item = { date: r.substring(0, 10) };
        itemData.forEach(e => {
          const key = `${e.Broker ? e.Broker : e.Fund}${
            e.CCY ? `_${e.CCY}` : ''
          }`;
          fields.forEach(f => {
            const detailKey = `${key}_${f}`;
            if (!keyList.includes(detailKey)) keyList.push(detailKey);
            item[detailKey] = _.round(e[f], 0);
          });
        });
        return item;
      });
    }
    return {
      data,
      keyList
    };
  };

  _buildData = (columns, data) => {
    return _.zipObject(columns, data);
  };

  _getRandomColor = count => {
    const brightnessThreshold = 150; // 亮度阈值，范围为 0 到 255
    const colors = [];

    while (colors.length < count) {
      const color = '#' + Math.floor(Math.random() * 16777215).toString(16); // 生成随机的颜色值，范围为 0 到 FFFFFF
      if (
        this.getBrightness(color) >= brightnessThreshold &&
        !colors.includes(color)
      ) {
        colors.push(color);
      }
    }

    return colors;
  };

  getBrightness = color => {
    const hexColor = color.replace('#', '');
    const red = parseInt(hexColor.substr(0, 2), 16); // 提取红色分量并转为十进制
    const green = parseInt(hexColor.substr(2, 2), 16); // 提取绿色分量并转为十进制
    const blue = parseInt(hexColor.substr(4, 2), 16); // 提取蓝色分量并转为十进制

    // 使用相对亮度算法计算亮度值
    return (red * 299 + green * 587 + blue * 114) / 1000;
  };

  _createLineChart = () => {
    const { colors } = this.state;
    const { data, keyList } = this._filterLineData();
    return (
      <ResponsiveContainer height={'90%'} width={'100%'}>
        <ComposedChart data={data} margin={{ top: 5, right: 30, left: 30 }}>
          <XAxis dataKey="date" />
          <YAxis
            tickFormatter={value => {
              return numeral(value).format('0,0');
            }}
          />
          <Tooltip
            formatter={(value, name, props) => {
              return [numeral(value).format('0,0'), name];
            }}
          />
          <Legend />
          {keyList.map((r, index) => {
            if (r.includes('Net')) {
              return <Bar dataKey={r} fill={colors[index]} />;
            } else {
              return (
                <Line
                  type="monotone"
                  dataKey={r}
                  strokeWidth={2}
                  stroke={colors[index]}
                />
              );
            }
          })}
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  render() {
    return (
      <Modal
        width={'92%'}
        maskClosable={false}
        visible={true}
        onCancel={this.props.closeDialog}
        className="exposureReivewModalInnerClass"
        bodyStyle={{
          height: '80vh',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        footer={null}
      >
        <div>
          <style>
            {` 

                  .exposureReivewModalInnerClass .ant-picker{
                    background-color:#FF9E28;
                  }

                  .exposureReivewModalInnerClass .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .exposureReivewModalInnerClass .ant-select-arrow{
                    color: gray !important;
                  }

                  .exposureReivewModalInnerClass .anticon {
                    color: gray !important;
                  }
                 
                `}
          </style>
        </div>
        {this._createQueryHeader()}
        {this._createLineChart()}
      </Modal>
    );
  }
}

export default ExposureReviewDialog;
