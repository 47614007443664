import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import SplitPane from 'react-split-pane';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import {
  companyStrategyListGridColumns,
  companyStrategyDetailGridColumns
} from './GridColumnMap';
import { Button } from 'antd';
import client from '../../api/client';
import CompanyStrategyModal from './CompanyStrategyModal';

class CompanyStrategyGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isDetailLoaded: false,
      submitStatus: 'READY',
      leftGridList: [],
      columnDefs: companyStrategyListGridColumns,
      rightDetailColumnDefs: companyStrategyDetailGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      detailFrameworkComponents: {
        tagComponent: TagComponent
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      autoGroupColumnDef: {
        cellClass: 'non-number',
        // field: 'pairName',
        // headerName: 'Name',
        width: 35,
        cellRendererParams: {
          suppressCount: true
        }
        // valueFormatter: params => {
        //     return '';
        // }
      },
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      selectedData: { data: [] },
      detailData: [],
      companyStrategyModalData: {
        show: false
      }
    };
  }

  componentDidMount() {
    this._init();
  }

  _init = () => {
    Promise.all([client.getCompanyStrategyList()])
      .then(([resp]) => {
        let data = [];
        if (!_.isEmpty(resp.data)) {
          const dataList = _.orderBy(resp.data, ['date'], ['desc']);
          data = dataList[0].data;
        }
        this.setState({
          leftGridList: resp.data,
          selectedData: { data }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _onLeftListGridReady = params => {
    this.leftListGridApi = params.api;
    this.leftListGridColumnApi = params.columnApi;

    this.leftListGridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.leftListGridApi.getSelectedRows();
    if (!_.isEmpty(selectedRows)) {
      this.setState({
        selectedData: {
          data: selectedRows[0].data
        }
      });
    }
  };

  _getDetailData = selectDate => {
    client
      .getCompanyStrategyDetail(selectDate)
      .then(resp => {
        const data = _.isEmpty(resp.data) ? this._initData() : resp.data;
        this.setState({
          selectedData: {
            data: data.data
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _createLeftListGrid = () => {
    const { leftGridList } = this.state;
    const { className = 'grid-wrapper' } = this.props;
    const data = _.orderBy(leftGridList, ['date'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={data}
            suppressAggFuncInHeader={true}
            animateRows={true}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={false}
            rowSelection="single"
            context={this.state.context}
            sideBar={this.state.sideBar}
            onSelectionChanged={this.onSelectionChanged}
            onGridReady={this._onLeftListGridReady}
          />
        </div>
      </div>
    );
  };

  onRightGridReady = params => {
    this.rightGridApi = params.api;
    this.rightGridColumnApi = params.columnApi;

    this.rightGridApi.sizeColumnsToFit();
  };

  _buildRightGridShowData = () => {
    const { data } = this.state.selectedData;
    if (_.isEmpty(data)) return [];
    const dataList = data ? data : [];
    const result = [];
    dataList.forEach(e => {
      const list = e.pairData.map(r => ({
        ...r,
        pairName: e.pairName
      }));
      result.push(...list);
    });
    return result;
  };

  _createRightGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const data = this._buildRightGridShowData();
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '100%' }}
        >
          <AgGridReact
            // properties
            columnDefs={companyStrategyDetailGridColumns}
            defaultColDef={this.state.defaultColDef}
            rowData={data}
            suppressAggFuncInHeader={true}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            animateRows={true}
            pivotPanelShow={false}
            groupDefaultExpanded={-1}
            frameworkComponents={this.state.detailFrameworkComponents}
            rowSelection="single"
            onGridReady={this.onRightGridReady}
          />
        </div>
      </div>
    );
  };

  _createOpBtns = () => {
    return (
      <>
        <Button
          size="small"
          style={{ marginRight: '5px' }}
          type="primary"
          onClick={this._init}
        >
          Reload
        </Button>
        <Button
          onClick={this._openCompanyStrategyModal}
          size="small"
          style={{ marginRight: '5px' }}
          type="primary"
        >
          Add/Update
        </Button>
      </>
    );
  };

  _createCompanyStrategyModal = () => {
    const { show } = this.state.companyStrategyModalData;
    if (show) {
      return (
        <CompanyStrategyModal
          {...this.props}
          closeDialog={this._closeCompanyStrategyModal}
          loadData={this._init}
        />
      );
    }
  };

  _closeCompanyStrategyModal = () => {
    this.setState({
      companyStrategyModalData: {
        show: false
      }
    });
  };

  _openCompanyStrategyModal = () => {
    this.setState({
      companyStrategyModalData: {
        show: true
      }
    });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <style>
          {`
          `}
        </style>
        <div style={{ textAlign: 'right', marginBottom: '5px' }}>
          {this._createOpBtns()}
        </div>
        <SplitPane
          split="vertical"
          defaultSize="15%"
          style={{ position: 'relative', height: '100%' }}
        >
          {this._createLeftListGrid()}
          {this._createRightGrid()}
        </SplitPane>
        {this._createCompanyStrategyModal()}
      </div>
    );
  }
}

export default CompanyStrategyGrid;
