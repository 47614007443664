import React, { Component } from 'react';
import { Tabs } from 'antd';
import client from '../../api/client';
import _ from 'lodash';
import SwapTradeParameterGrid from './SwapTradeParameterGrid';

const TabPane = Tabs.TabPane;
class SwapTradeParameterPannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      keyCols: [
        'fund',
        'custodian',
        'marketCountry',
        'swapType',
        'side',
        'dayCount',
        'benchmarkNotionalBasis',
        'spreadNotionalBasis',
        'resetSchedule',
        'initResetDate',
        'resetDate',
        'resetDateType',
        'resetSettleDateType'
      ]
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _genKey = (o, includeKeys) => {
    const entries = Object.entries(o);
    // 过滤掉需要排除的键
    const filteredEntries = entries.filter(([key]) =>
      includeKeys.includes(key)
    );
    // 将剩余的属性值拼成一个字符串，这里假设属性值是字符串或可以转换为字符串的类型
    const resultString = filteredEntries
      .map(([key, value]) => `${value}`)
      .join('_');
    return resultString;
  };

  _loadData = () => {
    client
      .querySwapTradeParameters()
      .then(resp => {
        const dataSet = {};
        _.orderBy(resp.data, ['date'], ['desc']).forEach(r => {
          const key = this._genKey(r, this.state.keyCols);
          if (!dataSet[key]) dataSet[key] = r;
        });
        this.setState({
          data: Object.values(dataSet)
        });
      })
      .catch(err => console.log(err));
  };

  _getData = type => {
    const { data } = this.state;
    if (_.isEmpty(data)) return [];
    if (type === 'LC') {
      return data.filter(
        r => r.side === 'LONG' && r.swapType.startsWith('CFD')
      );
    } else if (type === 'LF') {
      return data.filter(
        r => r.side === 'LONG' && r.swapType.startsWith('FFS')
      );
    } else if (type === 'SC') {
      return data.filter(
        r => r.side === 'SHORT' && r.swapType.startsWith('CFD')
      );
    }
  };

  render() {
    const { data } = this.state;
    return (
      <div className="treasuryDashboardWrapper">
        {!_.isEmpty(data) && (
          <Tabs defaultActiveKey="1" tabPosition={'top'}>
            <TabPane tab="Long CFD" key="1">
              <SwapTradeParameterGrid
                {...this.props}
                data={this._getData('LC')}
              />
            </TabPane>
            <TabPane tab="Long FFS" key="2">
              <SwapTradeParameterGrid
                {...this.props}
                data={this._getData('LF')}
              />
            </TabPane>
            <TabPane tab="Short CFD" key="3">
              <SwapTradeParameterGrid
                {...this.props}
                data={this._getData('SC')}
                excludeCols={['spread']}
              />
            </TabPane>
          </Tabs>
        )}
      </div>
    );
  }
}

export default SwapTradeParameterPannel;
