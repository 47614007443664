import { get, set, del } from 'idb-keyval';

export const save = (key, data, prefix) => {
  const value = JSON.stringify(data);
  const tiemstamp = Date.parse(new Date());
  set(`${prefix}_${key}`, value)
    .then(r => {
      const current = Date.parse(new Date());
      console.log(`cost time: ${(current - tiemstamp) / 1000}`);
    })
    .catch(err => console.log(err));
};

export const getValue = async (key, prefix) => {
  const text = await get(`${prefix}_${key}`);
  if (text) {
    try {
      return JSON.parse(text);
    } catch (err) {
      console.log(err);
    }
  }
  return {};
};

export const delKey = (key, prefix) => {
  del(`${prefix}_${key}`).catch(err => console.log(err));
};
