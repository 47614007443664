import React, { PureComponent } from 'react';
import { Button, Select, Form, Alert } from 'antd';
import { DIALOG_VIEW_POSITIONS } from '../portfolioConstants';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../common/ui/agGridUtils';
import { isInternalFund } from '../../../common/utils/DomainUtils';
import { DraggableModal } from 'ant-design-draggable-modal';
import StateSynchronizer from '../../../common/utils/StateSynchronizer';
import { moneyFormatter, percentFormatter } from 'common/utils/valueFormatters';
import PercentBarComponent from 'common/ui/renderers/agGrid/PercentBarComponent';
import PosCalculator from '../entities/calculators/PosCalculator';

const Option = Select.Option;

const summaryFieldMap = new Map([
  ['Qty', e => (e.primaryTickers.size > 1 ? null : 'quantityEnd')],
  ['MV Book %', e => (e.bookCodes.size > 1 ? null : 'notnlMktValBookPct')],
  [
    'Beta MV Book %',
    e => (e.bookCodes.size > 1 ? null : 'betaNotnlMktValBookPct')
  ]
]);

class ViewPositionsDialog extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      viewMode: 'Normal',
      selectedPosKeys: [],

      columnDefs: props.settings.posGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 25,
        checkboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter'
        // suppressMenu: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number'
      },
      frameworkComponents: {
        percentBarComponent: PercentBarComponent
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_VIEW_POSITIONS);
  };

  onGridReady = params => {
    this.gridApi = params.api;

    const COLUMNS_KEY = 'view-pos-grid-col-state';
    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  _calcPos = p => {
    const { liveRiskInfoMap } = this.props;
    const riskInfo = liveRiskInfoMap.byKey[`${p.fundCode}-${p.bookCode}`];
    return PosCalculator.calcSingle(p, riskInfo);
  };

  _calcPositions = () => {
    const { positions } = this.props;
    const { viewMode } = this.state;
    if (positions) {
      const filteredPositions = positions
        .filter(h => isInternalFund(h.fundCode))
        .map(p => this._calcPos(p));
      switch (viewMode) {
        case 'Normal':
          return filteredPositions.filter(h => h.positionTypeCode !== 'PTH');
        default:
          return [];
      }
    }

    return [];
  };

  _handleViewModeChange = viewMode => {
    this.setState({ viewMode, selectedPosKeys: [] });
  };

  getRowNodeId = data => {
    return data.key;
  };

  createPositionsGrid = positions => {
    return (
      <div
        style={{ width: '100%', height: '570px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={positions}
          suppressAggFuncInHeader={true}
          animateRows={true}
          deltaRowDataMode={true}
          enableRangeSelection={true}
          getRowNodeId={this.getRowNodeId}
          rowGroupPanelShow={this.state.rowGroupPanelShow}
          pivotPanelShow={this.state.pivotPanelShow}
          groupDefaultExpanded={-1}
          rowSelection="multiple"
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          frameworkComponents={this.state.frameworkComponents}
          sideBar={this.state.sideBar}
          floatingFilter={true}
          // events
          onGridReady={this.onGridReady}
          onSelectionChanged={this.onSelectionChanged}
        />
      </div>
    );
  };

  _calcSummary = positions => {
    const { selectedPosKeys } = this.state;
    if (selectedPosKeys && selectedPosKeys.length > 1) {
      const summaryData = positions
        .filter(p => selectedPosKeys.includes(p.key))
        .reduce((map, e) => {
          return {
            primaryTickers: new Set([
              ...(map.primaryTickers || []),
              e.primaryTicker
            ]),
            bookCodes: new Set([...(map.bookCodes || []), e.bookCode]),
            fundCodes: new Set([...(map.fundCodes || []), e.fundCode]),

            quantityEnd: e.quantityEnd + (map.quantityEnd || 0),
            // notnlMktValBook:
            //   e.notnlMktValBook + (map.notnlMktValBook || 0),
            notnlMktValBookPct:
              e.notnlMktValBookPct + (map.notnlMktValBookPct || 0),
            // betaNotnlMktValBook:
            //   e.betaNotnlMktValBook + (map.betaNotnlMktValBook || 0),
            betaNotnlMktValBookPct:
              e.betaNotnlMktValBookPct + (map.betaNotnlMktValBookPct || 0)
          };
        }, {});

      return [...summaryFieldMap.keys()]
        .map(k => ({ c: k, f: summaryFieldMap.get(k)(summaryData) }))
        .filter(cf => cf.f)
        .map(
          cf =>
            `${cf.c}: ${
              cf.c.includes('%')
                ? percentFormatter({ value: summaryData[cf.f] })
                : moneyFormatter({ value: summaryData[cf.f] })
            }`
        )
        .join(', ');
    }

    return null;
  };

  createSummaryPanel = summary => {
    return (
      <div style={{ marginTop: '5px', textAlign: 'right' }}>
        {summary && <Alert message={summary} type="info" />}
      </div>
    );
  };

  onSelectionChanged = () => {
    const selectedPosKeys = this.gridApi.getSelectedRows().map(r => r.key);
    this.setState({ selectedPosKeys });
  };

  render() {
    const positions = this._calcPositions();
    const summary = this._calcSummary(positions);

    return (
      <div>
        <DraggableModal
          width={1300}
          maskClosable={false}
          title="View Positions"
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            <Button key="close" type="primary" onClick={this.closeDialog}>
              Close
            </Button>
          ]}
        >
          <div className="omsDialogOperationBar">
            <div className="rightOperations">
              <Form layout="inline">
                <Form.Item label="View Mode">
                  <Select
                    defaultValue="Normal"
                    style={{ width: 120 }}
                    onChange={this._handleViewModeChange}
                  >
                    <Option value="Normal">Normal</Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>

          {this.createPositionsGrid(positions)}
          {this.createSummaryPanel(summary)}
        </DraggableModal>
      </div>
    );
  }
}

export default ViewPositionsDialog;
