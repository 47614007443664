import { connect } from 'react-redux';
import OrderDashboard from '../components/OrderDashboard';
import {
  isLoadedSelector,
  tradesSelector,
  selectedTradeKeySelector,
  selectedTradeKeysSelector,
  uiSelector
} from '../orderSelectors';
import { dialogsSelector } from '../../common/commonSelectors';
import {
  selectLiveRiskInfos,
  selectLiveRiskInfoMap,
  selectLivePositionMap
} from '../../portfolio/portfolioSelectors';
import { selectSettings } from '../../settings/settingSelectors';
import {
  selectTrade,
  selectTrades,
  toggleViewMode,
  getTradeBlotter,
  changeViewDate
} from '../orderActions';
import {
  openDialog,
  closeDialog,
  submitDialogSuccess
} from '../../common/commonActions';
import { sendSubscription } from '../../portfolio/portfolioActions';
import _ from 'lodash';

const mapStateToProps = state => {
  const isOrderLoaded = isLoadedSelector(state);
  const settings = selectSettings(state);
  const isSettingsLoaded = selectSettings(state).isLoaded;
  const trades = tradesSelector(state);
  const selectedTradeKey = selectedTradeKeySelector(state);
  const selectedTradesKey = selectedTradeKeysSelector(state);
  const selectedTrade = trades.find(t => t.key === selectedTradeKey);
  const selectedTrades = trades.filter(
    t => !_.isEmpty(selectedTradesKey) && selectedTradesKey.includes(t.key)
  );
  const user = selectSettings(state).user;
  const liveRiskInfos = selectLiveRiskInfos(state);
  const liveRiskInfoMap = selectLiveRiskInfoMap(state);
  const livePositionMap = selectLivePositionMap(state);
  const ui = uiSelector(state);

  const dialogs = dialogsSelector(state);

  return {
    isLoaded: isOrderLoaded && isSettingsLoaded,
    trades,
    selectedTrade,
    selectedTrades,
    settings,
    user,
    ui,

    liveRiskInfos,
    liveRiskInfoMap,
    livePositionMap,
    dialogs
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSubscription: payload => dispatch(sendSubscription(payload)),
    selectTrade: trade => dispatch(selectTrade(trade)),
    selectTrades: trade => dispatch(selectTrades(trade)),
    toggleViewMode: () => dispatch(toggleViewMode()),
    changeViewDate: date => dispatch(changeViewDate(date)),
    getTradeBlotter: qry => dispatch(getTradeBlotter(qry)),

    openDialog: (dialogCode, info) => dispatch(openDialog(dialogCode, info)),
    closeDialog: dialogCode => dispatch(closeDialog(dialogCode)),
    submitDialogSuccess: (dialogCode, info) =>
      dispatch(submitDialogSuccess(dialogCode, info))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDashboard);
