import React, { Component } from 'react';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import 'moment/locale/zh-cn';
import ReactDOMServer from 'react-dom/server';
import { Rating } from 'semantic-ui-react';
import _ from 'lodash';

class BrokerDialogGrid extends Component {
  constructor(props) {
    super(props);
    this.colorMap = {
      HK: '#0099FF',
      CN: '#FF3333',
      IN: '#339900',
      JP: '#FF9999',
      P: '#FFCC33',
      E: '#FF6666',
      DEFAULT: '#FF00FF'
    };
    this.nestedHeaders = [
      ['', 'Market', 'Broker Type'],
      ['Broker\\Weight', '', '']
    ];
    this.columns = [
      {
        data: 'brokerName',
        headerName: 'Broker',
        readOnly: true,
        allowEmpty: false
      },
      {
        data: 'market',
        headerName: 'Market',
        readOnly: true,
        allowEmpty: false,
        renderer: this.colorHtmlRenderer
      },
      {
        data: 'brokerType',
        headerName: 'Broker Type',
        readOnly: true,
        allowEmpty: false,
        renderer: this.colorHtmlRenderer
      }
    ];
    this.buildNestedHeader();
    this.nestedHeaders[0].push({ label: 'Total', colspan: 1 });
    this.nestedHeaders[1].push({ label: '', colspan: 1 });
    this.columns.push({
      data: 'totalScore',
      headerName: 'Total',
      allowEmpty: false,
      readOnly: true,
      renderer: this.totalScoreRenderer
    });
  }

  buildNestedHeader = () => {
    const { weightMap } = this.props.brokerScoreControlModal;
    // eslint-disable-next-line array-callback-return
    weightMap.map(item => {
      const col = { label: item['name'], colspan: 1 };
      const weightCol = { label: '(' + item['weight'] + ')', colspan: 1 };
      const dataCol = {
        data: item['code'],
        headerName: item['name'],
        type: 'numeric',
        numericFormat: {
          pattern: '0',
          allowNegative: false
        },
        readOnly: false,
        allowEmpty: false
      };
      this.nestedHeaders[0].push(col);
      this.nestedHeaders[1].push(weightCol);
      this.columns.push(dataCol);
    });
  };

  getWeightName = code => {
    const { weightMap } = this.props.brokerScoreControlModal;
    for (var i = 0; i < weightMap.length; i++) {
      const element = weightMap[i];
      if (element.code === code) {
        return element.name;
      }
    }
  };

  calculateTotal = row => {
    const { weightMap } = this.props.brokerScoreControlModal;
    if (this.data) {
      const data = this.data[row];
      var total = 0;
      // eslint-disable-next-line array-callback-return
      weightMap.map(item => {
        total += data[item.code] * item['weight'];
      });
      const totalScore = total.toFixed(2);
      data['totalScore'] = totalScore;
      return totalScore;
    }
    return 0;
  };

  totalScoreRenderer = (
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) => {
    const valueString = this.calculateTotal(row);
    Handsontable.renderers.TextRenderer.call(
      this,
      instance,
      td,
      row,
      col,
      prop,
      valueString,
      cellProperties
    );
  };

  colorHtmlRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //get color
    var color = this.colorMap[value];
    if (!color) {
      color = this.colorMap.DEFAULT;
    }
    const innerHtml = ReactDOMServer.renderToString(
      <p
        style={{
          backgroundColor: color,
          width: '100%',
          color: 'black',
          textAlign: 'center'
        }}
      >
        {value}
      </p>
    );

    Handsontable.renderers.HtmlRenderer.call(
      this,
      instance,
      td,
      row,
      col,
      prop,
      innerHtml,
      cellProperties
    );
  };

  starHtmlRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    const innerHtml = ReactDOMServer.renderToString(
      <Rating
        icon="star"
        defaultRating={value}
        maxRating={5}
        disabled={false}
      />
    );

    Handsontable.renderers.HtmlRenderer.call(
      this,
      instance,
      td,
      row,
      col,
      prop,
      innerHtml,
      cellProperties
    );
    this.calculateTotal(row);
  };

  checkFiledValue = (name, data, weightName) => {
    if (!this.props.checkFiledByFiledName) {
      return;
    }
    this.props.checkFiledByFiledName(name, data, weightName);
  };

  afterChangeHandle = (value, action) => {
    if (action === 'edit') {
      const current = value[0];
      this.checkFiledValue(
        current[1],
        this.data,
        this.getWeightName(current[1])
      );
      if (value) {
        const index = current[0];
        const data = this.data[index];
        data.op = 'U';
      }
    }
    if (action === 'Autofill.fill') {
      value.forEach(item => {
        const index = item[0];
        const data = this.data[index];
        data.op = 'U';
      });
      const current = value[0];
      this.checkFiledValue(
        current[1],
        this.data,
        this.getWeightName(current[1])
      );
    }
  };
  _onRouteSelectionChanged = (row, col, row2, col2) => {
    const { selectedDataChange } = this.props;
    const selectedRoutes = _.slice(this.data, row, row2 + 1);

    selectedDataChange(selectedRoutes[0]);
  };
  render() {
    const { data } = this.props;
    this.data = _.orderBy(data, ['market', 'brokerType'], ['desc', 'desc']);
    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

            .ant-calendar-picker {
              width: 100%;
            }

            .ant-input {
              height: 100%;
            }

            .ant-picker {
              margin-left:10px;
            }

            .ui.selection.dropdown > .dropdown.icon {
              line-height: 100%;
            }

            .buttonDiv {
              display: flex;
              justify-content: flex-end;
            }
            .ui.small.modal {
              width: 1500px;

            }
            .handsontable table.htCore {

                width: 1000px;

            }
              .ui.modal > .content {

                width: 1100px;
    
            }
          `}
        </style>
        <HotTable
          nestedHeaders={this.nestedHeaders}
          data={this.data}
          columns={this.columns}
          autoColumnSize={true}
          manualColumnResize={true}
          colHeaders={true}
          rowHeaders={false}
          afterChange={this.afterChangeHandle}
          dragToScroll={false}
          width="100%"
          height="430px"
          readOnly={true}
          licenseKey="non-commercial-and-evaluation"
          afterSelectionEnd={this._onRouteSelectionChanged}
        />
      </div>
    );
  }
}

export default BrokerDialogGrid;
