import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { Button } from 'semantic-ui-react';
import PercentBarComponent from '../../../../common/ui/renderers/agGrid/PercentBarComponent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';

class AllocationGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.settings.allocationGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 25,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        resizable: true,
        sortable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number'
      },
      rowGroupPanelShow: 'never',
      pivotPanelShow: 'always',
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        tagComponent: TagComponent
      },
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'allocation-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  onSelectionChanged = () => {
    const row = this.gridApi.getSelectedRows()[0];
    if (!row) return;

    // this.props.selectRoute(row.refId);
  };

  render() {
    const { allocations, className = 'grid-wrapper' } = this.props;

    return (
      <div className="widget">
        <div className="operation-bar">
          <Button
            size="mini"
            content="Edit"
            color="green"
            disabled={true}
            style={{
              marginBottom: '4px',
              marginTop: '4px',
              visibility: 'hidden'
            }}
          />
        </div>

        <div className={`ag-theme-balham-dark ${className}`}>
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            rowData={allocations}
            suppressAggFuncInHeader={true}
            animateRows={true}
            deltaRowDataMode={true}
            enableRangeSelection={true}
            getRowNodeId={this.getRowNodeId}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            getContextMenuItems={this.state.getContextMenuItems}
            rowSelection="single"
            frameworkComponents={this.state.frameworkComponents}
            floatingFilter={true}
            sideBar={this.state.sideBar}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        </div>
      </div>
    );
  }
}

export default AllocationGrid;
