const _aggregateErrors = (prev, key, errors) => {
  return Object.entries(errors).reduce((acc, [_, err]) => {
    const keys = acc[err] || [];
    return {
      ...acc,
      [err]: [...keys, key]
    };
  }, prev);
};

export const createErrorMsgs = errsByKey => {
  return Object.entries(
    Object.entries(errsByKey).reduce((acc, [key, errors]) => {
      return _aggregateErrors(acc, key, errors);
    }, {})
  ).map(([err, keys]) => `[${err}]: ${keys.join(',')}`);
};
