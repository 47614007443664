import React, { Component } from 'react';
import { Modal, Button, Spin, Input } from 'antd';
import client from 'features/research/api/client';
import _ from 'lodash';

class ChatGptModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoad: false
    };
  }

  componentDidMount() {
    const { ticker } = this.props;
    this.setState({
      isLoad: true
    });
    client
      .getCompanyChat({
        stock_name: ticker,
        question_type: 'research_cashflow'
      })
      .then(resp => {
        this.setState({
          data: resp.data,
          isLoad: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isLoad: false
        });
      });
  }

  _createInput = () => {
    const { data, isLoad } = this.state;
    if (!isLoad && _.isEmpty(data)) return;
    const { raw_data, analysis } = data;
    return (
      <Spin spinning={isLoad}>
        <Input.TextArea autoSize={true} value={analysis} />
        <div
          style={{ overflow: 'auto', maxHeight: '500px', marginTop: '10px' }}
          dangerouslySetInnerHTML={{ __html: raw_data }}
        />
      </Spin>
    );
  };

  render() {
    const { closeModal, ticker } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        mask={false}
        title={`${ticker}`}
        width={1400}
        footer={[
          <Button
            size="small"
            type="primary"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        ]}
        closable={false}
      >
        {this._createInput()}
      </Modal>
    );
  }
}

export default ChatGptModal;
