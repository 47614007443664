import { createReducer } from 'common/utils/reducerUtils';

import {
  LOAD_REPORT_LIST_SUCCESS,
  LOAD_REPORT_LIST_FAILURE,
  LOAD_REPORT_ATTRIBUTION_SUCCESS,
  LOAD_REPORT_ATTRIBUTION_FAILURE,
  CHANGE_RISK_QUERY_PARAMS_INPUT,
  CHANGE_RISK_SELECTED_ITEM,
  LOAD_FACTOR_ASSET_SUCCESS,
  LOAD_FACTOR_ASSET_FAILURE,
  LOAD_RETURN_REPORT_LIST_SUCCESS,
  LOAD_RETURN_REPORT_LIST_FAILURE,
  CHANGE_RETURN_QUERY_PARAMS_INPUT,
  LOAD_RETURN_FACTOR_ASSET_SUCCESS,
  LOAD_RETURN_FACTOR_ASSET_FAILURE,
  CHANGE_RETURN_SELECTED_ITEM,
  INIT_RISK_REPORT_LOAD_STATUS,
  INIT_RISK_FACTOR_LOAD_STATUS,
  INIT_RISK_ASSET_LOAD_STATUS,
  INIT_RETURN_REPORT_LOAD_STATUS,
  INIT_RETURN_FACTOR_LOAD_STATUS,
  INIT_RETURN_ASSET_LOAD_STATUS,
  CHANGE_RETURN_REPORT_ATTRIBUTION
} from './riskConstants';
import moment from 'moment';

const initialState = {
  riskReportList: [],
  riskReportQueryParams: {
    type: 'Risk',
    date: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
    dataMode: true
  },
  riskReportAttributionList: [],
  riskReportAssetList: [],
  riskSelectedFactorItem: null,
  riskReportInfoLoadingStatus: false,
  riskReportFactorLoadingStatus: false,
  riskReportAssetLoadingStatus: false,
  //return
  returnReportList: [],
  returnReportQueryParams: {
    type: 'Return',
    fromDate: moment()
      .subtract(31, 'days')
      .format('YYYY-MM-DD'),
    toDate: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
  },
  returnReportAttributionList: [],
  returnReportAssetList: [],
  returnSelectedFactorItem: null,
  returnReportInfoLoadingStatus: false,
  returnReportFactorLoadingStatus: false,
  returnReportAssetLoadingStatus: false
};

export function loadReportListSuccess(state, payload) {
  return {
    ...state,
    riskReportList: payload.data,
    riskReportAttributionList: [],
    riskReportAssetList: [],
    riskSelectedFactorItem: null,
    riskReportInfoLoadingStatus: false
  };
}

export function loadReportListFailure(state, payload) {
  return {
    ...state,
    riskReportList: [],
    riskReportAttributionList: [],
    riskReportAssetList: [],
    riskSelectedFactorItem: null,
    riskReportInfoLoadingStatus: false
  };
}

export function loadReportAttributionSuccess(state, payload) {
  return {
    ...state,
    riskReportAttributionList: payload.data.data,
    riskReportAssetList: [],
    riskSelectedFactorItem: null,
    riskReportFactorLoadingStatus: false
  };
}

export function loadReportAttributionFailure(state, payload) {
  return {
    ...state,
    riskReportAttributionList: [],
    riskReportAssetList: [],
    riskSelectedFactorItem: null,
    riskReportFactorLoadingStatus: false
  };
}

export function changeRiskQueryParamsInput(state, { name, value }) {
  const updatedFields = {
    ...state.riskReportQueryParams,
    [name]: value
  };

  return {
    ...state,
    riskReportQueryParams: updatedFields
  };
}

export function getFactorAssetSuccess(state, resp) {
  return {
    ...state,
    riskReportAssetList: resp.data,
    riskReportAssetLoadingStatus: false
  };
}

export function getFactorAssetFailure(state, err) {
  return {
    ...state,
    riskReportAssetList: [],
    riskReportAssetLoadingStatus: false
  };
}

export function loadReturnReportListSuccess(state, payload) {
  return {
    ...state,
    returnReportList: payload.data,
    returnReportAttributionList: [],
    returnReportAssetList: [],
    returnSelectedFactorItem: null,
    returnReportInfoLoadingStatus: false
  };
}

export function loadReturnReportListFailure(state, payload) {
  return {
    ...state,
    returnReportList: [],
    returnReportAttributionList: [],
    returnReportAssetList: [],
    returnSelectedFactorItem: null,
    returnReportInfoLoadingStatus: false
  };
}

export function changeReturnQueryParamsInput(state, { name, value }) {
  const updatedFields = {
    ...state.returnReportQueryParams,
    [name]: value
  };

  return {
    ...state,
    returnReportQueryParams: updatedFields
  };
}

export function getReturnFactorAssetSuccess(state, resp) {
  return {
    ...state,
    returnReportAssetList: resp.data,
    returnReportAssetLoadingStatus: false
  };
}

export function getReturnFactorAssetFailure(state, err) {
  return {
    ...state,
    returnReportAssetList: [],
    returnReportAssetLoadingStatus: false
  };
}

export function changeRiskSelectItemInput(state, input) {
  return {
    ...state,
    riskSelectedFactorItem: input
  };
}

export function changeReturnSelectItemInput(state, input) {
  return {
    ...state,
    returnSelectedFactorItem: input
  };
}

export function initiateRiskReportLoaderStatus(state) {
  return {
    ...state,
    riskReportInfoLoadingStatus: true
  };
}

export function initiateRiskFactorLoaderStatus(state) {
  return {
    ...state,
    riskReportFactorLoadingStatus: true
  };
}

export function initiateRiskAssetLoaderStatus(state) {
  return {
    ...state,
    riskReportAssetLoadingStatus: true
  };
}

export function initiateReturnReportLoaderStatus(state) {
  return {
    ...state,
    returnReportInfoLoadingStatus: true
  };
}

export function initiateReturnFactorLoaderStatus(state) {
  return {
    ...state,
    returnReportFactorLoadingStatus: true
  };
}

export function initiateReturnAssetLoaderStatus(state) {
  return {
    ...state,
    returnReportAssetLoadingStatus: true
  };
}

export function changeReturnReportAttribution(state, data) {
  return {
    ...state,
    returnReportAttributionList: data.returnAttributionVOList,
    returnReportAssetList: [],
    returnSelectedFactorItem: null,
    returnReportFactorLoadingStatus: false
  };
}

export default createReducer(initialState, {
  // Short Interest
  [LOAD_REPORT_LIST_SUCCESS]: loadReportListSuccess,
  [LOAD_REPORT_LIST_FAILURE]: loadReportListFailure,
  [LOAD_REPORT_ATTRIBUTION_SUCCESS]: loadReportAttributionSuccess,
  [LOAD_REPORT_ATTRIBUTION_FAILURE]: loadReportAttributionFailure,
  [CHANGE_RISK_QUERY_PARAMS_INPUT]: changeRiskQueryParamsInput,
  [LOAD_FACTOR_ASSET_SUCCESS]: getFactorAssetSuccess,
  [LOAD_FACTOR_ASSET_FAILURE]: getFactorAssetFailure,
  [LOAD_RETURN_REPORT_LIST_SUCCESS]: loadReturnReportListSuccess,
  [LOAD_RETURN_REPORT_LIST_FAILURE]: loadReturnReportListFailure,
  [CHANGE_RETURN_QUERY_PARAMS_INPUT]: changeReturnQueryParamsInput,
  [LOAD_RETURN_FACTOR_ASSET_SUCCESS]: getReturnFactorAssetSuccess,
  [LOAD_RETURN_FACTOR_ASSET_FAILURE]: getReturnFactorAssetFailure,
  [CHANGE_RISK_SELECTED_ITEM]: changeRiskSelectItemInput,
  [CHANGE_RETURN_SELECTED_ITEM]: changeReturnSelectItemInput,
  [INIT_RISK_REPORT_LOAD_STATUS]: initiateRiskReportLoaderStatus,
  [INIT_RISK_FACTOR_LOAD_STATUS]: initiateRiskFactorLoaderStatus,
  [INIT_RISK_ASSET_LOAD_STATUS]: initiateRiskAssetLoaderStatus,
  [INIT_RETURN_REPORT_LOAD_STATUS]: initiateReturnReportLoaderStatus,
  [INIT_RETURN_FACTOR_LOAD_STATUS]: initiateReturnFactorLoaderStatus,
  [INIT_RETURN_ASSET_LOAD_STATUS]: initiateReturnAssetLoaderStatus,
  [CHANGE_RETURN_REPORT_ATTRIBUTION]: changeReturnReportAttribution
});
