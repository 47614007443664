import React, { Component } from 'react';
import ThemesAnalysisPannel from './ThemesAnalysisPannel';

class ThemesAnalysisTab extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <ThemesAnalysisPannel {...this.props} />
      </div>
    );
  }
}

export default ThemesAnalysisTab;
