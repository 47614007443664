import {
  DIALOG_OPEN,
  DIALOG_CLOSE,
  DIALOG_SUBMIT_SUCCESS
} from './commonConstants';

export function openDialog(dialogCode, info) {
  return {
    type: DIALOG_OPEN,
    payload: { dialogCode, info }
  };
}

export function closeDialog(dialogCode) {
  return {
    type: DIALOG_CLOSE,
    payload: { dialogCode }
  };
}

export function submitDialogSuccess(dialogCode, info) {
  return {
    type: DIALOG_SUBMIT_SUCCESS,
    payload: {
      dialogCode,
      info
    }
  };
}
