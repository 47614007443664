import React, { PureComponent } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import { DIALOG_ASSIGN_ORDERS } from '../../omsConstants';
import client from '../../api/client';
import { Select } from 'antd';

const Option = Select.Option;

class AssignOrdersDialog extends PureComponent {
  state = {
    traders: [],
    selectedTraderName: null,
    submitStatus: 'READY'
  };

  componentDidMount() {
    const {
      settings: { user }
    } = this.props;

    client.getTraders().then(traders => {
      if (traders && traders.length > 0) {
        this.setState({ traders });
        const matchedTrader = traders.find(
          t => t.englishName.toUpperCase() === user.englishName.toUpperCase()
        );
        const defaultTraderName = matchedTrader
          ? matchedTrader.englishName
          : traders[0].englishName;
        this.setState({ selectedTraderName: defaultTraderName });
      }
    });
  }

  componentWillUnmount() {
    this.setState({
      traders: [],
      selectedTraderName: null
    });
  }

  _isValid = (order, user, assignedTraderName) => {
    if (!assignedTraderName) return false;

    const upperCaseOrderTrader =
      (order.traderCode && order.traderCode.toUpperCase()) || null;
    const upperCaseUser = user.englishName.toUpperCase();
    const upperCaseAssignedTrader = assignedTraderName.toUpperCase();

    return (
      ((!upperCaseOrderTrader || upperCaseOrderTrader !== upperCaseUser) &&
        upperCaseAssignedTrader === upperCaseUser) ||
      (upperCaseOrderTrader &&
        upperCaseOrderTrader === upperCaseUser &&
        upperCaseAssignedTrader !== upperCaseUser)
    );
  };

  _assignOrders = () => {
    const { selectedOrders, submitDialogSuccess } = this.props;
    const { selectedTraderName } = this.state;
    const {
      settings: { user }
    } = this.props;

    const cmds = selectedOrders
      .filter(o => this._isValid(o, user, selectedTraderName))
      .map(o => ({
        refId: o.refId,
        tradeId: o.tradeId,
        traderCode: selectedTraderName
      }));

    if (cmds.length > 0) {
      return client.assignOrders(cmds).then(() => {
        submitDialogSuccess(DIALOG_ASSIGN_ORDERS, cmds);
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._assignOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_ASSIGN_ORDERS);
  };

  changeTrader = traderName => {
    this.setState({
      selectedTraderName: traderName
    });
  };

  render() {
    const { submitStatus: status } = this.state;
    const {
      selectedOrders,
      settings: { user }
    } = this.props;
    const { traders, selectedTraderName } = this.state;

    const assignedOrderRefIds = selectedOrders
      .filter(o => !this._isValid(o, user, selectedTraderName))
      .map(o => o.refId)
      .join(',');

    return (
      <div>
        <Modal size="tiny" open={true}>
          <Header content="Assign Orders" />
          <Modal.Content>
            <div>
              Please select the trader you want to assign to:
              <Select
                onChange={this.changeTrader}
                value={selectedTraderName}
                style={{ marginLeft: '5px', width: 120 }}
              >
                {traders.map(t => (
                  <Option value={t.englishName} key={t.englishName}>
                    {t.englishName}
                  </Option>
                ))}
              </Select>
            </div>
            <p>
              {assignedOrderRefIds &&
                `Note: Some orders are not valid to be assigned to the specified trader: 
                ${assignedOrderRefIds} which will be filtered out by system.`}
            </p>
          </Modal.Content>
          <Modal.Actions>
            {
              {
                SUBMITTING: (
                  <Button secondary disabled loading content="Submitting..." />
                ),
                ERROR: (
                  <Button
                    secondary
                    content="Fail - Retry?"
                    onClick={this._onSubmit}
                  />
                ),
                READY: (
                  <Button secondary content="Yes" onClick={this._onSubmit} />
                )
              }[status]
            }

            <Button secondary content="No" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default AssignOrdersDialog;
