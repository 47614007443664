import TickerUtils from './TickerUtils';
import _ from 'lodash';
import Trade from 'features/order/entities/Trade';

const orderFields = [
  'ticker',
  'side',
  'quantity',
  'orderType',
  'limitPriceLocal'
];

const emptyOrder = orderFields.reduce(
  (prev, curr) => ({ ...prev, [curr]: null }),
  {}
);

const CATS_SIDE_MAP = new Map([
  [1, 'BUY'],
  [2, 'SELL'],
  ['FA', 'BUY'],
  ['FB', 'SHRT'],
  ['FC', 'COVR'],
  ['FD', 'SELL']
]);

const _parseCatsRows = rows => {
  if (_.isEmpty(rows)) return [];

  return rows.map(row => {
    const [, , , catsTicker, quantity, catsSide] = row;

    const side = CATS_SIDE_MAP.get(catsSide);

    return {
      ...emptyOrder,
      ticker: TickerUtils.convertCatsToBbg(catsTicker),
      quantity: quantity,
      side
    };
  });
};

const _parseDefaultRows = rows => {
  if (_.isEmpty(rows)) return [];

  const emptyTrade = Trade.emptyForm();
  return rows.map(row => {
    const orderType =
      row['Type'] || (_.isNil(row['Lmt Price']) ? 'MKT' : 'LMT');
    const qty =
      row['Qty'] && _.isString(row['Qty'])
        ? row['Qty'].replace(',', '')
        : row['Qty'];

    return {
      ...emptyTrade,
      fundCode: (row['Fund'] || '').toUpperCase(),
      bookCode: (row['Book'] || '').toUpperCase(),
      ticker: row['Ticker'],
      tickerType: (row['Ticker Type'] || 'BB_TCM').toUpperCase(),
      side: (row['Side'] || 'BUY').toUpperCase(),
      quantity: qty ? Math.abs(qty) : qty,
      qtyUsd: row['Usd Amt'] ? Math.abs(row['Usd Amt']) : row['Usd Amt'],
      limitPriceLocal: row['Lmt Price'],
      orderType: orderType.toUpperCase(),
      algoCode: (row['Algo'] || 'INLINE').toUpperCase(),
      strategy: (row['Strategy'] || 'NONE').toUpperCase(),
      timeInForce: (row['TIF'] || '').toUpperCase(),
      pmRemark: row['PM Remark'],
      pmReason: row['PM Reason'],
      basket: row['Basket'],
      secCcy: row['SecCcy'],
      maturityDate: row['Fixing Date']
    };
  });
};

const _parseFxTradeRows = rows => {
  if (_.isEmpty(rows)) return [];

  const emptyTrade = Trade.emptyForm();
  return rows.map(row => {
    const orderType =
      row['Type'] || (_.isNil(row['Lmt Price']) ? 'MKT' : 'LMT');
    const qty =
      row['Qty'] && _.isString(row['Qty'])
        ? row['Qty'].replace(',', '')
        : row['Qty'];

    return {
      ...emptyTrade,
      fundCode: (row['Fund'] || '').toUpperCase(),
      bookCode: (row['Book'] || '').toUpperCase(),
      txnClass: (row['TxnClass'] || '').toUpperCase(),
      symbol: row['Symbol'],
      settleDate: row['SettleDate'] ? `${row['SettleDate']}` : null,
      ticker:
        ['FX_NDF', 'FX_FORWARD'].includes(row['TxnClass']) && row['SettleDate']
          ? `${row['Symbol']} ${String(row['SettleDate'])}`
          : row['Symbol'],
      tickerType: (row['Ticker Type'] || 'BB_TCM').toUpperCase(),
      side: (row['Side'] || 'BUY').toUpperCase(),
      quantity: qty ? Math.abs(qty) : qty,
      qtyUsd: row['Usd Amt'] ? Math.abs(row['Usd Amt']) : row['Usd Amt'],
      limitPriceLocal: row['Lmt Price'],
      orderType: orderType.toUpperCase(),
      algoCode: (row['Algo'] || 'INLINE').toUpperCase(),
      strategy: (row['Strategy'] || 'NONE').toUpperCase(),
      timeInForce: (row['TIF'] || '').toUpperCase(),
      pmRemark: row['PM Remark'],
      pmReason: row['PM Reason'],
      basket: row['Basket'],
      secCcy: row['SecCcy'],
      maturityDate: row['Fixing Date'] ? `${row['Fixing Date']}` : null
    };
  });
};

const _parsers = {
  CATS: _parseCatsRows,
  DEFAULT: _parseDefaultRows,
  FX_TEMPLATE: _parseFxTradeRows
};

const createOrderFileParser = fileType => {
  return {
    parse: _parsers[fileType]
  };
};

export default createOrderFileParser;
