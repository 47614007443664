import '@wangeditor/editor/dist/css/style.css'; // 引入 css

import React, { useState, useEffect } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { i18nChangeLanguage } from '@wangeditor/editor';

i18nChangeLanguage('en');
function MyEditor({
  onChange,
  initText,
  showDesc,
  onEditorCreate,
  toolbarConfig = {},
  editorConfig = {}
}) {
  // editor 实例
  const [editor, setEditor] = useState(null); // JS 语法
  // 工具栏配置

  const onCreated = current => {
    if (onEditorCreate) onEditorCreate(current);
    setEditor(current);
    if (current && showDesc) {
      current.on('fullScreen', function(e) {
        showDesc(true);
      });
      current.on('unFullScreen', function(e) {
        showDesc(false);
      });
    }
  };

  // 及时销毁 editor ，重要！
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  return (
    <>
      <div style={{ border: '1px solid #ccc', zIndex: 100, height: '100%' }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: '1px solid #ccc' }}
        />
        <Editor
          ref={editor}
          defaultConfig={{
            ...editorConfig,
            MENU_CONF: {
              uploadImage: {
                base64LimitSize: 5 * 1024 * 1024 * 10
              }
            }
          }}
          value={initText}
          onCreated={onCreated}
          onChange={editor => {
            onChange(editor.getHtml());
          }}
          mode="default"
          style={{ height: '100%', overflowY: 'hidden' }}
        />
      </div>
    </>
  );
}

export default MyEditor;
