import React, { Component } from 'react';
import { Tag } from 'antd';

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }

  return false;
};

const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }

  // delete browser cache and hard reload
  window.location.reload();
};

const currentVersion = process.env.REACT_APP_VERSION;

class VersionTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `New version is found: ${latestVersion} (current version is ${currentVersion}), to force refresh.`
          );
          refreshCacheAndReload();
        } else {
          console.log(`The currect version is latest, no need to refresh.`);
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;

    const txt = loading ? 'checking version...' : currentVersion;
    return (
      <Tag
        color="#108ee9"
        style={{
          verticalAlign: 'middle',
          marginTop: '10px',
          height: '25px'
        }}
      >
        {txt}
      </Tag>
    );
  }
}

export default VersionTag;
