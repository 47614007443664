import React, { Component } from 'react';
import { Input } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import 'rc-pagination/assets/index.css';

const { Search } = Input;
class ShortListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.blockListGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      autoGroupColumnDef: {
        headerName: 'Ticker',
        field: 'ticker',
        cellClass: 'keyword'
      },
      ticker: null
    };
  }

  componentDidMount = () => {
    this.loadBlockListData();
  };

  queryButtonClick = () => {
    this.loadBlockListData();
  };

  loadBlockListData = () => {
    this.props.getBlockList({
      ticker: this.state.ticker
    });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onQueryParamsChange = (event, fieldName) => {
    const filedValue = event.target.value;
    this.setState({ [fieldName]: filedValue });
  };

  onValueParamsChangeNotQuery = (value, fieldName) => {
    this.setState({
      [fieldName]: value
    });
  };

  onValueParamsChange = (value, fieldName) => {
    this.setState({ [fieldName]: value }, () => {
      this.queryButtonClick();
    });
  };

  render() {
    const { isLoaded, blockList } = this.props;

    const sortedRows = _.orderBy(blockList, ['ticker'], ['asc']);
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <style>
          {`
                    #shortTickerSereach{
                        height: auto
                    }

                    .totalShortListLabel{
                        color: #0099CC;
                        margin-left:10px;
                        font-size:15px;
                        font-weight: bold
                    }

                    .totalShortListValue{
                        color: #969696;
                        font-size:15px;
                        font-weight: bold
                    }

                    .rc-pagination-item{
                        margin-right: '5px'
                    }

                `}
        </style>
        <div
          style={{
            width: '100%',
            textAlign: 'right',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              textAlign: 'right',
              width: '500px',
              float: 'right',
              marginBottom: '3px'
            }}
          >
            <Search
              id="blockTickerSereach"
              placeholder="Input ticker..."
              style={{
                width: '200px'
              }}
              value={this.state.ticker}
              onChange={e => {
                this.onQueryParamsChange(e, 'ticker');
              }}
              onPressEnter={this.queryButtonClick}
              onSearch={this.queryButtonClick}
            />
          </div>
        </div>
        <div
          className={`ag-theme-balham-dark`}
          style={{
            height: '90%',
            width: '100%'
          }}
        >
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              // getRowNodeId={this.getRowNodeId}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="multiple"
              context={this.state.context}
              sideBar={this.state.sideBar}
              groupDefaultExpanded={this.state.groupDefaultExpanded}
              // events
              onGridReady={this.onGridReady}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ShortListGrid;
