import { Component } from 'react';

class PushVotesItemComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.data['id']
    };
  }

  render() {
    return '';
  }
}

export default PushVotesItemComponent;
