import React, { PureComponent } from 'react';

import { Segment, Statistic } from 'semantic-ui-react';
import { Tooltip } from 'antd';
import { percentFormatter } from 'common/utils/valueFormatters';
import _ from 'lodash';

class BenchmarkBar extends PureComponent {
  _createStatisticItem = benchmark => {
    const value = `${percentFormatter({ value: benchmark.dtdPriceChange })}`;
    const label = benchmark.ticker.replace(' Index', '');
    const color = !benchmark.dtdPriceChange
      ? 'blue'
      : benchmark.dtdPriceChange > 0
      ? 'green'
      : 'red';

    const statistic = (
      <Statistic inverted color={color} size="mini" key={benchmark.ticker}>
        <Statistic.Value>{value}</Statistic.Value>
        <Statistic.Label
          style={{
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: '500'
          }}
        >
          {label}
        </Statistic.Label>
      </Statistic>
    );

    return <Tooltip title={benchmark.code}>{statistic}</Tooltip>;
  };

  render() {
    const { benchmarks } = this.props;
    const sortedBenchmarks = _.orderBy(benchmarks, ['sortOrder'], ['asc']);
    const statisticItems = sortedBenchmarks.map(b =>
      this._createStatisticItem(b)
    );

    return (
      <Segment
        inverted
        style={{
          marginBottom: '2px',
          textAlign: 'center',
          marginTop: '1px'
        }}
      >
        {statisticItems.map((item, i) => (
          <div className="statisticItem" key={i}>
            {item}
          </div>
        ))}
      </Segment>
    );
  }
}

export default BenchmarkBar;
