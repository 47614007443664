import {
  ORDER_SNAPSHOT,
  ORDER_UPDATE,
  TRADE_SELECT,
  TRADES_SELECT,
  TRADE_VIEW_MODE_CHANGE,
  TRADE_VIEW_DATE_CHANGE,
  TRADE_BLOTTER_GET_REQUEST,
  TRADE_BLOTTER_GET_SUCCESS,
  TRADE_BLOTTER_GET_FAIL
} from './orderConstants';

import client from './api/client';

export function initOrder(payload) {
  return {
    type: ORDER_SNAPSHOT,
    payload: payload
  };
}

export function updateOrder(payload) {
  return {
    type: ORDER_UPDATE,
    payload: payload
  };
}

export function selectTrade(tradeKey) {
  return {
    type: TRADE_SELECT,
    payload: tradeKey
  };
}

export function selectTrades(tradeKeys) {
  return {
    type: TRADES_SELECT,
    payload: tradeKeys
  };
}

export function toggleViewMode() {
  return {
    type: TRADE_VIEW_MODE_CHANGE
  };
}

export function changeViewDate(date) {
  return {
    type: TRADE_VIEW_DATE_CHANGE,
    payload: date
  };
}

export function getTradeBlotterRequest() {
  return {
    type: TRADE_BLOTTER_GET_REQUEST
  };
}

export function getTradeBlotterSuccess(payload) {
  return {
    type: TRADE_BLOTTER_GET_SUCCESS,
    payload
  };
}

export function getTradeBlotterFail(err) {
  return {
    type: TRADE_BLOTTER_GET_FAIL,
    payload: err
  };
}

export function getTradeBlotter(query) {
  return function(dispatch) {
    dispatch(getTradeBlotterRequest());
    client
      .getTrades(query)
      .then(payload => dispatch(getTradeBlotterSuccess(payload)))
      .catch(err => dispatch(getTradeBlotterFail(err)));
  };
}
