import React, { Component } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { DatePicker, Row, Col, Divider, Input } from 'antd';
// import MonthlyReviewBenchmarkGrid from './MonthlyReviewBenchmarkGrid';
import MonthlyReviewMyPortfolioGrid from './MonthlyReviewMyPortfolioGrid';
import moment from 'moment';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';

const { TextArea } = Input;
const { MonthPicker } = DatePicker;
const currentMonthFormat = 'MMMM';
class MonthlyReviewUpdateDetailPanel extends Component {
  onInputChangeEventHandle = (event, fieldName) => {
    const filedValue = event.target.value;
    this.props.monthlyReviewDetailChangeInput({
      name: fieldName,
      value: filedValue
    });
  };

  monthOnChage = (time, timeString) => {
    const { monthlyReviewDetailData } = this.props;
    if (!time) {
      return;
    }
    time.locale('en');
    var timeClone = time.clone();
    timeClone.locale('en');
    monthlyReviewDetailData['nextMonth'] = timeClone
      .add('month', 1)
      .format(currentMonthFormat);
    monthlyReviewDetailData['yearMonth'] = timeString;
    monthlyReviewDetailData['year'] = timeString.substring(0, 4);
    monthlyReviewDetailData['monthForEng'] = time.format(currentMonthFormat);
    this.props.monthlyReviewDetailChangeInput({
      name: 'month',
      value: time.format('M')
    });
    // this.loadBenchmarkData();
    this.loadMyPortfolioData();
  };

  onInputChange = ({ name, value }) => {
    this.props.monthlyReviewDetailChangeInput({
      name,
      value
    });
  };

  canEdit = data => {
    //get current user id
    const { user } = this.props;
    if (!user) {
      return 'hidden';
    }
    const userAccountId = user['userAccountId'];
    if (data['userId'] && data['userId'] === userAccountId) {
      return 'visible';
    }
    return 'hidden';
  };

  onSaveMonthlyReviewBtnClicked = () => {
    this.props.saveMonthlyReview(
      this.props.monthlyReviewDetailData,
      this.props.monthlyReviewQueryParams
    );
  };

  onSubmitMonthlyReviewBtnClicked = () => {
    this.props.submitMonthlyReview(
      this.props.monthlyReviewDetailData,
      this.props.monthlyReviewQueryParams
    );
  };

  loadBenchmarkData = () => {
    const { monthlyReviewDetailData } = this.props;
    const queryParams = {
      yearMonth: monthlyReviewDetailData['yearMonth'],
      year: monthlyReviewDetailData['year']
    };
    if (monthlyReviewDetailData['id']) {
      queryParams['monthlyReviewId'] = monthlyReviewDetailData['id'];
    } else {
      queryParams['addFlag'] = 'ADD';
    }
    // this.props.loadMonthlyReviewBenchmark(queryParams, true);
  };

  loadMyPortfolioData = () => {
    const { monthlyReviewDetailData } = this.props;
    const queryParams = {
      yearMonth: monthlyReviewDetailData['yearMonth'],
      year: monthlyReviewDetailData['year']
    };
    if (monthlyReviewDetailData['id']) {
      queryParams['monthlyReviewId'] = monthlyReviewDetailData['id'];
    } else {
      queryParams['addFlag'] = 'ADD';
    }
    this.props.loadMonthlyReviewMyPortfilio(queryParams, true);
  };

  _getHeaderTitle = (monthForEng, year) => {
    if ('December' === monthForEng) {
      return `${year} Year Review`;
    } else {
      return `${monthForEng} ${year} Monthly Review`;
    }
  };

  render() {
    const { monthlyReviewDetailData, detailIsLoaded } = this.props;

    const data = monthlyReviewDetailData;
    // let canEditFlag = this.canEdit(monthlyReviewDetailData);
    let gridShow = data['userId'];
    let divHeight = !_.isEmpty(monthlyReviewDetailData.errorMsgList)
      ? '90%'
      : '100%';
    const headerTitle = this._getHeaderTitle(data.monthForEng, data.year);
    if (gridShow) {
      return (
        <div
          id="monthlyReviewUpdatePanel"
          style={{ position: 'relative', width: '100%', height: '100%' }}
        >
          <style>
            {`

                      #monthlyReviewUpdatePanel #monthlyReviewDetailDiv .ant-input {
                        background-color: #242424;
                        color: white
                      }

                      #monthlyReviewUpdatePanel #monthlyReviewDetailDiv .ant-input-number-input-wrap {
                        background-color: '#242424';
                        color: white
                      }

                      #monthlyReviewUpdatePanel #monthlyReviewDetailDiv .ant-input-group-addon {
                        background-color: '#242424';
                        color:#FF9E28
                      }

                      #monthlyReviewUpdatePanel .monthlyReviewDetailSector{
                        margin-top: 20px;
                        color: black;
                      }
                  
                    `}
          </style>
          {!detailIsLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '95%'
              }}
            >
              <Header
                inverted
                block
                style={{ background: '#242424', marginBottom: '0px' }}
              >
                <Row>
                  <Col span={4} style={{ textAlign: 'left' }}>
                    <MonthPicker
                      size="middle"
                      allowClear={false}
                      value={moment(data.yearMonth, 'YYYY-MM')}
                      onChange={this.monthOnChage}
                    />
                  </Col>
                  <Col span={16} style={{ fontSize: 20, textAlign: 'center' }}>
                    {headerTitle}
                  </Col>
                </Row>
              </Header>

              <div
                id="monthlyReviewDetailDiv"
                style={{ overflowY: 'auto', height: divHeight }}
              >
                <div style={{ position: 'relative', height: '340px' }}>
                  <MonthlyReviewMyPortfolioGrid
                    {...this.props}
                    loadMyPortfolioData={this.loadMyPortfolioData}
                  />
                </div>
                <Divider style={{ marginBottom: '3px' }} />
                <div
                  style={{
                    color: '#FF9E28',
                    marginLeft: '10px',
                    fontSize: 14
                  }}
                >
                  <Row>
                    <Col span={24} className="monthlyReviewDetailSector">
                      <span>
                        At the end of {data.monthForEng}{' '}
                        {', my portfolio gross is '}
                        <span style={{ color: 'black', fontSize: 16 }}>
                          <b>{data.grossMargin}%</b>
                        </span>
                        ，after beta net is{' '}
                        <span style={{ color: 'black', fontSize: 16 }}>
                          <b>{data.netValue}%.</b>
                        </span>
                      </span>
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Mainly gain from：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={3}
                        value={data.gainPortfolio}
                        placeholder="Mainly gain from"
                        onChange={e => {
                          this.onInputChangeEventHandle(e, 'gainPortfolio');
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Mainly loss from：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={3}
                        value={data.lossPortfolio}
                        placeholder="Mainly loss from"
                        onChange={e => {
                          this.onInputChangeEventHandle(e, 'lossPortfolio');
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Trading feedback for {data.monthForEng}：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={4}
                        value={data.feedback}
                        placeholder="Mainly focus on the success and loss in your portfolio, which opportunity you missed, what is in line with your forecast, what is out of your forecast"
                        onChange={e => {
                          this.onInputChangeEventHandle(e, 'feedback');
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Trading idea suggestion for Mr. Wang Qiang：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={3}
                        value={data.tradingSuggestion}
                        onChange={e => {
                          this.onInputChangeEventHandle(e, 'tradingSuggestion');
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Trading idea for future：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        rows={4}
                        value={data.nextMonthTradingIdea}
                        onChange={e => {
                          this.onInputChangeEventHandle(
                            e,
                            'nextMonthTradingIdea'
                          );
                        }}
                        placeholder="Make your forecast on your own sector performance for the next month, mainly focus on the opportunity and risk you think."
                      />
                    </Col>
                  </Row>
                  <Row className="monthlyReviewDetailSector">
                    <Col span={24}>
                      <span style={{ fontSize: 16 }}>
                        <b>Sector fundamental update in future：</b>
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                      <TextArea
                        value={data.fundamental}
                        rows={4}
                        onChange={e => {
                          this.onInputChangeEventHandle(e, 'fundamental');
                        }}
                        placeholder="Write important updates for your sectors and companies in April
                                                Update the market consensus of your sector and companies, if it has"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              {!_.isEmpty(monthlyReviewDetailData.errorMsgList) && (
                <Message
                  error
                  header="Errors"
                  list={monthlyReviewDetailData.errorMsgList}
                />
              )}
            </div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default MonthlyReviewUpdateDetailPanel;
