const Locate = {
  emptyForm(order, locateInfo, venue) {
    const {
      refId: orderRefId,
      // fundCode,
      // bookCode,
      tradeDate,

      mmaTicker
    } = order;

    return {
      orderRefId,
      // fundCode,
      // bookCode,
      tradeDate,

      ...locateInfo,
      notes: null,

      mmaTicker,
      order,
      venue
    };
  }
};

export default Locate;
