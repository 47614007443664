import React, { useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Switch,
  TimePicker,
  Button,
  InputNumber
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import Options from '../../entities/Options';
import _ from 'lodash';
import moment from 'moment';

const { RangePicker } = TimePicker;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 20, offset: 4 }
};

const Option = Select.Option;

const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};

const _renderBuilder = props => {
  return (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );
};

const _enableChoseCustodian = (key, form) => {
  const value = form.getFieldsValue(true);
  if (!value) return;
  const { destination = [] } = value.target || {};
  return destination[key] && destination[key].cfdType;
};

const _onCfdTypeChange = (key, value, brokers, form) => {
  const data = form.getFieldsValue(true);
  if (!data) return;
  const { destination = [] } = data.target || {};
  const updateDestination = destination.map((d, index) => {
    if (index === key) {
      const updatedCfdType = value === '' || _.isNil(value) ? null : value;
      const custodian = updatedCfdType
        ? brokers.find(b => b.brokerCode === d.broker).custodianCode
        : null;
      return {
        ...d,
        cfdType: updatedCfdType,
        custodian
      };
    }
    return {
      ...d
    };
  });
  const updateData = {
    ...data,
    target: {
      ...data.target,
      destination: updateDestination
    }
  };
  form.setFieldsValue(updateData);
};

const _onBrokerChange = (key, value, brokers, form) => {
  const data = form.getFieldsValue(true);
  if (!data) return;
  const { destination = [] } = data.target || {};
  const updateDestination = destination.map((d, index) => {
    if (index === key) {
      const updatedCfdType =
        d.cfdType === '' || _.isNil(d.cfdType) ? null : d.cfdType;
      const custodian = updatedCfdType
        ? brokers.find(b => b.brokerCode === value).custodianCode
        : null;
      return {
        ...d,
        custodian,
        cfdType: updatedCfdType
      };
    }
    return {
      ...d
    };
  });
  const updateData = {
    ...data,
    target: {
      ...data.target,
      destination: updateDestination
    }
  };
  form.setFieldsValue(updateData);
};

const _renderRoutingTarget = (subType, options, form) => {
  if (subType === 'EB') {
    const { brokers, custodians } = options;
    const brokerOptions = _createUIOptions(brokers.map(b => b.brokerCode));
    const custodianOptions = _createUIOptions(
      custodians.map(b => b.custodianCode)
    );

    return (
      <div>
        <Form.Item
          name={['target', 'isOverride']}
          required={true}
          label="Override Existing"
          valuePropName="checked"
        >
          <Switch checkedChildren="YES" unCheckedChildren="NO" />
        </Form.Item>
        <Form.List name={['target', 'destination']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Destination' : ''}
                    style={{ marginBottom: 0 }}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      key={[field.key, 'broker']}
                      name={[field.name, 'broker']}
                      fieldKey={[field.fieldKey, 'broker']}
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                        margin: '0 8px'
                      }}
                      rules={[
                        { required: true, message: 'Missing broker name' }
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select broker"
                        onChange={value => {
                          _onBrokerChange(field.key, value, brokers, form);
                        }}
                      >
                        {brokerOptions}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      key={[field.key, 'custodian']}
                      name={[field.name, 'custodian']}
                      fieldKey={[field.fieldKey, 'custodian']}
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)',
                        margin: '0 8px'
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select custodian"
                        disabled={_enableChoseCustodian(field.key, form)}
                      >
                        {custodianOptions}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      key={[field.key, 'cfdType']}
                      name={[field.name, 'cfdType']}
                      fieldKey={[field.fieldKey, 'cfdType']}
                      style={{
                        display: 'inline-block',
                        width: 'calc(20% - 8px)',
                        margin: '0 8px'
                      }}
                    >
                      <Select
                        showSearch
                        placeholder="Select CFD type"
                        onChange={value => {
                          _onCfdTypeChange(field.key, value, brokers, form);
                        }}
                      >
                        {_createUIOptions(Options.cfdType)}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...field}
                      key={[field.key, 'weight']}
                      name={[field.name, 'weight']}
                      fieldKey={[field.fieldKey, 'weight']}
                      style={{
                        display: 'inline-block',
                        width: 'calc(25% - 8px)'
                      }}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                      />
                    </Form.Item>

                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        style={{
                          display: 'inline-block',
                          width: 'calc(2% - 8px)'
                        }}
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}

                <Form.Item label="-">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add broker
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </div>
    );
  }

  if (subType === 'PB') {
    const { custodians } = options;
    const custodianOptions = _createUIOptions(
      custodians.map(b => b.custodianCode)
    );

    return (
      <div>
        <Form.Item
          name={['target', 'isOverride']}
          required={true}
          label="Override Existing"
          valuePropName="checked"
        >
          <Switch checkedChildren="YES" unCheckedChildren="NO" />
        </Form.Item>
        <Form.List name={['target', 'destination']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0
                      ? formItemLayout
                      : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Destination' : ''}
                    style={{ marginBottom: 0 }}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      key={[field.key, 'custodian']}
                      name={[field.name, 'custodian']}
                      fieldKey={[field.fieldKey, 'custodian']}
                      style={{
                        display: 'inline-block',
                        width: 'calc(40% - 8px)',
                        margin: '0 8px'
                      }}
                      rules={[
                        { required: true, message: 'Missing custodian name' }
                      ]}
                    >
                      <Select showSearch placeholder="Select custodian">
                        {custodianOptions}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      {...field}
                      key={[field.key, 'weight']}
                      name={[field.name, 'weight']}
                      fieldKey={[field.fieldKey, 'weight']}
                      style={{
                        display: 'inline-block',
                        width: 'calc(28% - 8px)'
                      }}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                      />
                    </Form.Item>

                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        style={{
                          display: 'inline-block',
                          width: 'calc(2% - 8px)'
                        }}
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}

                <Form.Item label="-">
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add custodian
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </div>
    );
  }

  if (subType === 'AXE') {
    return _renderAXETarget(options);
  }
};

const _renderAllocationTarget = () => {
  return (
    <div>
      <Form.Item name={['target', 'method']} required={true} label="Method">
        <Select showSearch placeholder="Select method">
          {_createUIOptions(Options.allocMethod)}
        </Select>
      </Form.Item>
      <Form.List name={['target', 'destination']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Destination' : ''}
                  style={{ marginBottom: 0 }}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    key={[field.key, 'fund']}
                    name={[field.name, 'fund']}
                    fieldKey={[field.fieldKey, 'fund']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(40% - 8px)',
                      margin: '0 8px'
                    }}
                    rules={[{ required: true, message: 'Missing fund name' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    key={[field.key, 'weight']}
                    name={[field.name, 'weight']}
                    fieldKey={[field.fieldKey, 'weight']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(28% - 8px)'
                    }}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{
                        display: 'inline-block',
                        width: 'calc(2% - 8px)'
                      }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}

              <Form.Item label="-">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add fund
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

const _renderAXETarget = options => {
  const { brokers } = options;
  const brokerOptions = _createUIOptions(brokers.map(b => b.brokerCode));

  return (
    <div>
      <Form.Item
        name={['target', 'isOverride']}
        required={true}
        label="Override Existing"
        valuePropName="checked"
      >
        <Switch checkedChildren="YES" unCheckedChildren="NO" />
      </Form.Item>
      <Form.List name={['target', 'destination']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Destination' : ''}
                  style={{ marginBottom: 0 }}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    key={[field.key, 'broker']}
                    name={[field.name, 'broker']}
                    fieldKey={[field.fieldKey, 'broker']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(30% - 8px)',
                      margin: '0 8px'
                    }}
                    rules={[{ required: true, message: 'Missing broker name' }]}
                  >
                    <Select showSearch placeholder="Select broker">
                      {brokerOptions}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    key={[field.key, 'cfdType']}
                    name={[field.name, 'cfdType']}
                    fieldKey={[field.fieldKey, 'cfdType']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(22% - 8px)',
                      margin: '0 8px'
                    }}
                  >
                    <Select showSearch placeholder="Select CFD type">
                      {_createUIOptions(Options.cfdType)}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...field}
                    key={[field.key, 'minOrderValue']}
                    name={[field.name, 'minOrderValue']}
                    fieldKey={[field.fieldKey, 'minOrderValue']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(25% - 8px)'
                    }}
                  >
                    <Input placeholder="min order value (usd)" />
                  </Form.Item>

                  <Form.Item
                    {...field}
                    key={[field.key, 'weight']}
                    name={[field.name, 'weight']}
                    fieldKey={[field.fieldKey, 'weight']}
                    style={{
                      display: 'inline-block',
                      width: 'calc(18% - 8px)',
                      margin: '0 8px'
                    }}
                  >
                    <InputNumber
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{
                        display: 'inline-block',
                        width: 'calc(2% - 8px)'
                      }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}

              <Form.Item label="-">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add broker
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

const _renderTargetFields = (type, subType, options, form) => {
  if (type === 'ROUTING') {
    return _renderRoutingTarget(subType, options, form);
  }

  if (type === 'ALLOCATION') {
    return _renderAllocationTarget();
  }

  return <></>;
};

// const _createErrorsPanel = errors => {
//   return (
//     <div style={{ marginTop: '5px' }}>
//       {!_.isEmpty(errors) && (
//         <Message error list={errors} style={{ marginBottom: '3px' }} />
//       )}
//     </div>
//   );
// };

const _enrichRuleTarget = (type, subType, target, { brokers } = {}) => {
  if (_.isNil(target)) return target;

  if (type === 'ROUTING') {
    const { destination = [] } = target;
    const updatedDestination = destination.map(d => {
      if (subType === 'AXE') {
        const updatedCfdType =
          d.cfdType === '' || _.isNil(d.cfdType) ? null : d.cfdType;
        const custodian = updatedCfdType
          ? brokers.find(b => b.brokerCode === d.broker).custodianCode
          : null;
        return {
          ...d,
          custodian,
          cfdType: updatedCfdType
        };
      }

      if (subType === 'PB') {
        return {
          ...d,
          cfdType: null
        };
      }

      return d;
    });

    return {
      ...target,
      destination: updatedDestination
    };
  }

  return target;
};

// const _validate = rule => {
//   if (!rule.target) return [];
//   if (_.size(rule.target.destination) <= 1) return [];

//   const { destination } = rule.target;
//   const errors = [];
//   if (_.some(destination, v => v.cfdType !== ''))
//     errors.push('Multiple brokers only suitable for cash broker.');
//   if (_.sumBy(destination, v => v.weight) !== 100)
//     errors.push('Total weight should be 100%.');

//   return errors;
// };

const RuleDialog = ({ rule, options = {}, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const [submitStatus, setSubmitStatus] = useState('READY');
  const { criteriaConfigs, traders } = options;

  const { refId, type, subType, timeRange } = rule;
  const criteriaConfig = criteriaConfigs.find(c => c.type === type) || {};
  const config = { ...AntdConfig, fields: criteriaConfig.fields };

  const criteriaTree = rule.criteria
    ? QbUtils.checkTree(
        QbUtils.loadFromJsonLogic(rule.criteria, config),
        config
      )
    : QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }, config);

  const parsedTimeRange = (timeRange || []).map(r => moment(r, 'HH:mm:ss'));
  const initialValues = {
    ...rule,
    criteriaTree,
    timeRange: parsedTimeRange
  };

  const modalTitle = `${refId ? 'Edit' : 'New'} Rule: ${type}/${subType}`;
  const traderOptions = _createUIOptions(traders.map(t => t.englishName));
  const priorityOptions = _createUIOptions(_.range(0, 6));

  return (
    <Modal
      visible={true}
      width={1000}
      style={{ marginTop: '30px' }}
      title={modalTitle}
      okText="Submit"
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={submitStatus === 'SUBMITTING'}
      onOk={() => {
        setSubmitStatus('SUBMITTING');
        form
          .validateFields()
          .then(values => {
            form.resetFields();

            const { criteriaTree, target, timeRange } = values;
            const { brokers } = options;
            const { logic } = QbUtils.jsonLogicFormat(criteriaTree, config);
            const parsedTimeRange = _.isEmpty(timeRange)
              ? null
              : timeRange.map(v => v.format('HH:mm:ss'));

            const formattedValues = {
              ...values,
              refId,
              type,
              subType,
              criteria: logic,
              timeRange: parsedTimeRange,

              target: _enrichRuleTarget(type, subType, target, { brokers })
            };

            // const errs = _validate(formattedValues);
            // if (!_.isEmpty(errs)) {
            //   setErrors(errs);
            //   return;
            // }

            return onSubmit(formattedValues);
          })
          .catch(info => {
            console.log('Submit Failed:', info);
          })
          .finally(() => {
            setSubmitStatus('READY');
          });
      }}
    >
      <Form
        form={form}
        name="RuleForm"
        initialValues={initialValues}
        layout="horizontal"
        {...formItemLayout}
      >
        <Form.Item
          name="code"
          rules={[{ required: true, message: 'Missing rule name' }]}
          label="Name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="trader"
          rules={[{ required: true, message: 'Missing trader name' }]}
          label="Trader"
        >
          <Select showSearch>{traderOptions}</Select>
        </Form.Item>

        <Form.Item
          name="criteriaTree"
          rules={[{ required: true, message: 'Missing criteria' }]}
          label="Criteria"
        >
          <Query
            {...config}
            // value={this.state.tree}
            // onChange={this.onChange}
            renderBuilder={_renderBuilder}
          />
        </Form.Item>

        <Form.Item name="priority" required={true} label="Priority">
          <Select showSearch>{priorityOptions}</Select>
        </Form.Item>

        <Form.Item
          name="isActive"
          required={true}
          label="Is Active"
          valuePropName="checked"
        >
          <Switch checkedChildren="YES" unCheckedChildren="NO" />
        </Form.Item>

        {type === 'AUTO' && (
          <Form.Item name="timeRange" label="Time Range(HK)">
            <RangePicker format="HH:mm:ss" order={false} />
          </Form.Item>
        )}

        {_renderTargetFields(type, subType, options, form)}
      </Form>
    </Modal>
  );
};

export default RuleDialog;
