const _createOptions = values => {
  return values.map(v => ({
    key: v,
    text: v,
    value: v
  }));
};

const Options = {
  side: _createOptions(['BUY', 'SELL', 'SHRT', 'COVR']),
  algo: _createOptions(['VWAP', 'INLINE', 'TWAP', 'DMA']),
  qtyCalc: Object.entries({ ALL: 1, HALF: 0.5 }).map(kvp => ({
    key: kvp[0],
    text: kvp[0],
    value: kvp[1]
  })),
  orderType: Object.entries({ MKT: 'MKT', LMT: 'LMT' }).map(kvp => ({
    key: kvp[0],
    text: kvp[1],
    value: kvp[0]
  })),
  timeInForce: _createOptions(['DAY', 'GTC'])
};

export default Options;
