import React, { Component } from 'react';
import PushVotesGrid from './PushVotesGrid';
import PushVotesQueryHeader from './PushVotesQueryHeader';

import './PushVotesTab.css';

class PushVotesTab extends Component {
  render() {
    return (
      <div className="pushVotesWrapper">
        <PushVotesQueryHeader {...this.props} />
        <PushVotesGrid {...this.props} />
      </div>
    );
  }
}

export default PushVotesTab;
