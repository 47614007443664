import Order from 'features/oms/entities/Order';
import agGridUtils from 'common/ui/agGridUtils';

export function percentStyle() {
  return {
    'number-zero': params =>
      params.value === undefined ||
      params.value === null ||
      Math.abs(params.value) < 0.0001,
    'number-positive': params => params.value > 0,
    'number-negative': params => params.value < 0
  };
}

export function numberStyle() {
  return {
    'number-zero': params =>
      params.value === undefined || params.value === null || params.value === 0,
    'number-positive': params => params.value > 0,
    'number-negative': params => params.value < 0
  };
}

export function sideStyle() {
  return {
    'number-positive': params =>
      params.data && ['BUY', 'COVR', 'COVR_T'].includes(params.data.side),
    'number-negative': params =>
      params.data && ['SELL', 'SHRT', 'SELL_T'].includes(params.data.side)
  };
}

export function sideStyleWithGroup() {
  return {
    'number-positive': params =>
      params.data && ['BUY', 'COVR', 'COVR_T'].includes(params.data.side),
    'number-negative': params =>
      params.data && ['SELL', 'SHRT', 'SELL_T'].includes(params.data.side),
    'non-number': params => agGridUtils.isGroupRow(params)
  };
}

export function statusStyle() {
  return {
    'status-na': params => !params.value,
    'status-fail': params => params.value === 'FAIL',
    'status-skip': params => params.value === 'SKIP',
    'status-pass': params => params.value === 'PASS',
    'status-warn': params => params.value === 'WARN'
  };
}

export function idleQtyStyle() {
  return {
    flash: params => Order.isIdleQtyAbnormal(params.data),
    'number-positive': params =>
      params.data && ['BUY', 'COVR'].includes(params.data.side),
    'number-negative': params =>
      params.data && ['SELL', 'SHRT'].includes(params.data.side)
  };
}

export function filledPctStyle() {
  return {
    flash: params => Order.isFilledPctAbnormal(params.data),
    number: params => params.value <= 1
  };
}

export function pendingRouteErrorsStyle() {
  return {
    flash: params => params.value === 'Y',
    keyword: params => !params.value || params.value === 'N'
  };
}

export function returnStyle() {
  return {
    'number-zero': params =>
      params.value === undefined ||
      params.value === null ||
      Math.abs(params.value) < 0.0001,
    'number-positive': params => params.value > 0.01,
    'number-negative': params => params.value < -0.01
  };
}

export function tagStyle() {
  return {
    tag: params => true
  };
}
