import config from 'common/config';
import apiClient from './apiClient';

const searchTickers = term => {
  const url = `${config.api.REST_URL}/security/searchTickers?term=${term}`;
  return apiClient.get(url);
};

export default {
  searchTickers
};
