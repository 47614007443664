import React, { Component } from 'react';
import SplitPane from 'react-split-pane';
import ReportInfoGrid from './ReportInfoGrid';
import FactorGrid from './FactorGrid';
import AssetGrid from './AssetGrid';

class ReturnAnalysisTab extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <SplitPane
          split="horizontal"
          defaultSize="40%"
          style={{ position: 'relative' }}
        >
          <ReportInfoGrid {...this.props} />
          <SplitPane
            split="vertical"
            defaultSize="50%"
            style={{ position: 'relative' }}
          >
            <FactorGrid {...this.props} />
            <AssetGrid {...this.props} />
          </SplitPane>
        </SplitPane>
      </div>
    );
  }
}

export default ReturnAnalysisTab;
