import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NoPermission from 'common/ui/NoPermission';

const PrivateRoute = ({ path, component, isLoggedIn, ...rest }) => {
  if (path === '/' || path === '') {
    //get the first visable
    const firstVisablePath = getFirstVisablePath(rest, path);
    if (firstVisablePath !== '') {
      return (
        <Route
          {...rest}
          render={props => (
            <Redirect
              to={{
                pathname: firstVisablePath,
                state: { from: props.location }
              }}
            />
          )}
        />
      );
    }
  }
  const currentPathVisable = getRoutePath(rest, path);
  if (!currentPathVisable) {
    return (
      <Route
        {...rest}
        render={props => React.createElement(NoPermission, props)}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

function getRoutePath(rest, path) {
  if (rest && rest.settings && rest.settings.routes) {
    const arr = rest.settings.routes;
    //adjust current path exists
    for (var i = 0; i < arr.length; i++) {
      if (arr[i]['code'] === path.replace('/', '')) {
        return arr[i]['visible'];
      }
    }
  }
  return true;
}

function getFirstVisablePath(rest) {
  if (rest && rest.settings && rest.settings.routes) {
    const arr = rest.settings.routes;
    //adjust current path exists
    for (var i = 0; i < arr.length; i++) {
      if (arr[i]['visible']) {
        return arr[i]['code'];
      }
    }
  }
  return '';
}

export default PrivateRoute;
