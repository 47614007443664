import React, { Component } from 'react';
import SetimentPannel from 'features/portfolio/components/SetimentPannel';
import ThemesAnalysisTab from './themesAnalysis/ThemesAnalysisTab';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;
// const TabPane = Tabs.TabPane;
class MarketTab extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab="Themes Analysis" key="1">
            <ThemesAnalysisTab {...this.props} />
          </TabPane>
          <TabPane tab="Setiments" key="2">
            <div
              style={{ height: '95%', overflow: 'auto' }}
              className="sentimenGridInnerClass"
            >
              <SetimentPannel
                {...this.props}
                source={'RESEARCH'}
                className="sentimenGridInnerClass"
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default MarketTab;
