import React, { Component } from 'react';
import AnalystListComplimentGrid from './analyst/AnalystListComplimentGrid.js';
import AnalystListAddDialog from './analyst/AnalystListAddDialog';

class AnalystTab extends Component {
  componentDidMount = () => {
    this.props.getBrokerList({ isActive: 1 });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <AnalystListComplimentGrid {...this.props} />
        <AnalystListAddDialog {...this.props} />
      </div>
    );
  }
}

export default AnalystTab;
