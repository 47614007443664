import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import { Select, DatePicker } from 'antd';

const { MonthPicker } = DatePicker;
const { Option } = Select;
class MonthlyReviewQueryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      pmList: []
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this.initSelectData();
    this.props.changeMonthlyReviewQueryParams({
      name: 'queryPmList',
      value: [user['englishName']]
    });
    this.initLoadReviewListData();
  }

  initSelectData = () => {
    this.props.getManagerUserList();
  };

  initLoadReviewListData = () => {
    const { user } = this.props;
    const { monthlyReviewQueryParams } = this.props;
    monthlyReviewQueryParams['queryPmList'] = [user['englishName']];
    this.props.loadMonthlyReviewList(monthlyReviewQueryParams);
  };

  onQueryParamsChange = ({ name, value }) => {
    this.props.changeMonthlyReviewQueryParams({
      name,
      value
    });
  };

  loadReviewListData = () => {
    const { monthlyReviewQueryParams } = this.props;
    this.props.loadMonthlyReviewList(monthlyReviewQueryParams);
  };

  onReloadMonthlyReviewBtnClicked = () => {
    this.loadReviewListData();
  };

  onNewMonthlyReviewBtnClicked = () => {
    const { user } = this.props;
    this.props.monthlyReviewDetailDataInit();
    this.props.monthlyReviewDetailChangeInput({
      name: 'userId',
      value: user['userAccountId']
    });
    this.props.monthlyReviewDetailChangeInput({
      name: 'modalShow',
      value: true
    });
    // this.loadBenchmarkData();
    this.loadMyPortfolioData();
  };

  loadBenchmarkData = () => {
    // const { monthlyReviewDetailData } = this.props;
    // const queryParams = {
    //   yearMonth: monthlyReviewDetailData['yearMonth'],
    //   year: monthlyReviewDetailData['year'],
    //   addFlag: 'ADD'
    // };
    // this.props.loadMonthlyReviewBenchmark(queryParams, true);
  };

  loadMyPortfolioData = () => {
    const { monthlyReviewDetailData } = this.props;
    const queryParams = {
      yearMonth: monthlyReviewDetailData['yearMonth'],
      year: monthlyReviewDetailData['year'],
      addFlag: 'ADD'
    };
    this.props.loadMonthlyReviewMyPortfilio(queryParams, true);
  };

  render() {
    const {
      isLoaded,
      monthlyReviewQueryParams,
      monthlyReviewPmSelectList
    } = this.props;
    const reviewPmSelectList = monthlyReviewPmSelectList
      ? monthlyReviewPmSelectList
      : [];
    if (reviewPmSelectList.indexOf('ALL') < 0) {
      reviewPmSelectList.unshift('ALL');
    }
    return (
      <div>
        <div
          style={{
            textAlign: 'right',
            backgroundColor: '#1B1C1D',
            marginBottom: '2px'
          }}
        >
          <MonthPicker
            placeholder={'month start'}
            allowClear={false}
            value={moment(
              monthlyReviewQueryParams.monthlyReviewFromStr,
              'YYYY-MM'
            )}
            size="small"
            onChange={(date, dateString) => {
              this.onQueryParamsChange({
                name: 'monthlyReviewFromStr',
                value: dateString
              });
            }}
          />

          <MonthPicker
            placeholder={'month end'}
            allowClear={false}
            value={moment(
              monthlyReviewQueryParams.monthlyReviewToStr,
              'YYYY-MM'
            )}
            size="small"
            onChange={(date, dateString) => {
              this.onQueryParamsChange({
                name: 'monthlyReviewToStr',
                value: dateString
              });
            }}
          />

          <Select
            mode="multiple"
            size="small"
            style={{ position: 'relative', width: '150px', marginRight: '5px' }}
            placeholder="select pm"
            value={monthlyReviewQueryParams.queryPmList}
            onChange={selectedItems => {
              this.onQueryParamsChange({
                name: 'queryPmList',
                value: selectedItems
              });
            }}
          >
            {reviewPmSelectList.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>

          <Button
            size="mini"
            content="Reload"
            color="green"
            disabled={!isLoaded}
            style={{ marginBottom: '4px', marginTop: '4px' }}
            onClick={this.onReloadMonthlyReviewBtnClicked}
          />

          <Button
            size="mini"
            content="Add"
            color="green"
            disabled={!isLoaded}
            style={{ marginBottom: '4px', marginTop: '4px' }}
            onClick={this.onNewMonthlyReviewBtnClicked}
          />
        </div>
      </div>
    );
  }
}

export default MonthlyReviewQueryHeader;
