import React, { Component } from 'react';
import BlockListGrid from './BlockListGrid';

class BlockListTab extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <BlockListGrid {...this.props} />
      </div>
    );
  }
}

export default BlockListTab;
