import spected from 'spected';

const _createSpec = p => {
  // predicates
  const notEmpty = value => !!value;
  const positiveNumber = value => parseFloat(value) > 0;

  // error messages
  const notEmptyMsg = field => `${field} should not be empty.`;
  const positiveNumberMsg = field => `${field} should be greated than 0.`;

  const checkCloseTradeQty = v => {
    if (['BUY', 'SHRT'].includes(p.tradeSide)) return true;
    return Math.abs(p.clsQty) >= v;
  };

  const validSide = v => {
    if (p.dir === 'LONG' && ['BUY', 'SELL'].includes(v)) return true;
    if (p.dir === 'SHORT' && ['SHRT', 'COVR'].includes(v)) return true;
    return false;
  };

  const qtyRule = [
    [notEmpty, notEmptyMsg('Qty')],
    [positiveNumber, positiveNumberMsg('Qty')],
    [
      checkCloseTradeQty,
      `No enough position qty (${p.clsQty}) could be closed.`
    ]
  ];

  const sideRule = [
    [notEmpty, notEmptyMsg('Side')],
    [validSide, 'Might cause boxed holding under specified custodian.']
  ];

  return {
    tradeQty: qtyRule,
    tradeSide: sideRule
  };
};

const _extractErrors = validationResult => {
  const FIRST_ERROR = 0;
  return Object.keys(validationResult).reduce((errors, field) => {
    return validationResult[field] !== true
      ? { ...errors, [field]: validationResult[field][FIRST_ERROR] }
      : errors;
  }, {});
};

const PosTradeValidator = {
  validate: p => {
    const spec = _createSpec(p);
    const validationResult = spected(spec, p);
    return _extractErrors(validationResult);
  }
};

export default PosTradeValidator;
