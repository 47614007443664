import React, { Component } from 'react';
import { Select, Button } from 'antd';
import './StudentResearchManageTab.css';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { studentResearchManageGridColumns } from '../GridColumnMap';
import client from '../../api/client';
import SwitchComponent from 'common/ui/renderers/agGrid/SwitchComponent';
import TagComponent from 'common/ui/renderers/agGrid/TagComponent';
import TableTooltipComponent from 'common/ui/renderers/agGrid/TableTooltipComponent';

const Option = Select.Option;

class StudentResearchManageTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentList: [],
      data: [],
      queryParams: {
        bookCode: null,
        englishName: null,
        userAccountId: null
      },
      selectedItem: null,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      }
    };
  }

  componentDidMount() {
    this._loadStudentList();
  }

  _loadStudentList = () => {
    const { user } = this.props;
    client
      .getAllStudentInfos()
      .then(resp => {
        const studentList = resp.data;
        if (studentList && !this.isResearchManageRole()) {
          const student = studentList.filter(
            r => r.englishName === user.englishName
          );
          if (student) {
            this.setState(
              {
                queryParams: student[0],
                studentList
              },
              this._loadResearchInfo
            );
          }
        } else {
          this.setState({
            studentList
          });
        }
      })
      .then(err => console.log(err));
  };

  _loadResearchInfo = () => {
    const { queryParams = {} } = this.state;
    if (_.isEmpty(queryParams.englishName)) return;
    client
      .getCompanyResearchInfos(queryParams)
      .then(resp => {
        this.setState({
          data: resp.data
        });
      })
      .then(err => console.log(err));
  };

  onChange = item => {
    const arr = _.split(item, '_');
    this.setState(
      {
        queryParams: {
          bookCode: !arr[0] || arr[0] === 'null' ? null : arr[0],
          englishName: arr[1],
          userAccountId: arr[2]
        },
        selectedItem: item
      },
      this._loadResearchInfo
    );
  };

  _createOpBar = () => {
    const { selectedItem, studentList } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        {this.isResearchManageRole() && (
          <Select
            onChange={this.onChange}
            value={selectedItem}
            size="small"
            style={{ marginLeft: '5px', width: 160 }}
          >
            {studentList.map(t => (
              <Option
                value={`${t.bookCode}_${t.englishName}_${t.userAccountId}`}
                key={`${t.bookCode}_${t.englishName}_${t.userAccountId}`}
              >
                {t.englishName}
              </Option>
            ))}
          </Select>
        )}

        <Button
          size="small"
          type="primary"
          style={{ marginLeft: '5px' }}
          onClick={this._loadResearchInfo}
        >
          Reload
        </Button>
      </div>
    );
  };

  onManageGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _onToggleRuleActive = async (v, rule) => {
    //if (!this.isResearchManageRole()) return;
    const updatedRule = {
      ...rule,
      isImport: v ? 1 : 0,
      importCompany: v ? 1 : 0
    };
    await this._onSubmitData(updatedRule);
  };

  _enrichRuleGridColumns = () => {
    return studentResearchManageGridColumns.map(c =>
      c.field !== 'importCompany'
        ? c
        : { ...c, cellRendererParams: { onChange: this._onToggleRuleActive } }
    );
  };

  _onSubmitData = async result => {
    const { data } = this.state;
    const [{ ticker }] = await client.submitStudentManageData([result]);
    if (!result.ticker) {
      this.setState({
        data: [
          ...data,
          {
            ...result
          }
        ]
      });
    } else {
      this.setState({
        data: data.map(r => (r.ticker === ticker ? result : r))
      });
    }
  };

  _createDataGrid = () => {
    const { data } = this.state;
    const rowDatas = data
      ? data.map(r => {
          return {
            ...r,
            stockNewsNum: r.stockNews ? r.stockNews.length : null
          };
        })
      : [];
    const columns = this._enrichRuleGridColumns();
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '90%' }}
      >
        <AgGridReact
          // properties
          columnDefs={columns}
          rowData={rowDatas}
          defaultColDef={this.state.defaultColDef}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={true}
          frameworkComponents={{
            switchComponent: SwitchComponent,
            tagComponent: TagComponent,
            tableTooltipComponent: TableTooltipComponent
          }}
          getRowNodeId={data => data.ticker}
          rowGroupPanelShow="always"
          rowSelection="single"
          suppressCellSelection={true}
          onGridReady={this.onManageGridReady}
        />
      </div>
    );
  };

  isResearchManageRole = () => {
    const { user } = this.props;
    if (user) {
      return ['FundMngr', 'Admin', 'Supervisor'].includes(user.role);
    }
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {this._createOpBar()}
        {this._createDataGrid()}
      </div>
    );
  }
}

export default StudentResearchManageTab;
