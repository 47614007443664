import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';
import { DIALOG_RUN_COMPLIANCE } from '../../omsConstants';
import client from '../../api/client';

class RunComplianceDialog extends PureComponent {
  state = {
    submitStatus: 'READY'
  };

  componentDidMount() {
    const { selectedOrders } = this.props;

    const cmds = selectedOrders.map(o => ({
      refId: o.refId
    }));

    this.setState({
      cmds
    });
  }

  _runCompliance = () => {
    const { cmds } = this.state;
    const { submitDialogSuccess } = this.props;

    if (cmds.length > 0) {
      return client.runCompliance(cmds).then(() => {
        submitDialogSuccess(DIALOG_RUN_COMPLIANCE, cmds.map(c => c.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._runCompliance()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_RUN_COMPLIANCE);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    return (
      <Modal
        width={900}
        maskClosable={false}
        title="Run Compliance"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <p>
          Are you sure you want to run compliance against the selected orders?
        </p>
      </Modal>
    );
  }
}

export default RunComplianceDialog;
