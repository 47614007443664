import React, { Component } from 'react';
import { Divider, Input, Select, InputNumber } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import { isIphone, isOtherPhone, isNotPhone } from './SystemAdjustUtil';
import { moneyFormatter } from 'common/utils/valueFormatters';
import ShortHistReviewDialog from './ShortHistReviewDialog';

const { Search } = Input;
const { Option } = Select;
class ShortListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.shortListGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      autoGroupColumnDef: {
        headerName: 'Primary Ticker',
        field: 'shortableInfoVO.primaryTicker',
        cellClass: 'keyword'
      },
      ticker: null,
      exchange: 'CH',
      brokerType: null,
      rate: null,
      pageIndex: 1,
      pageSize: 200,
      selectedSumQty: 0,
      selectedSumMv: 0,
      showShortHistReviewDialog: false,
      selectedRows: []
    };
  }

  componentDidMount = () => {
    this.loadShortListData(this.state.pageIndex, this.state.pageSize);
    this.props.loadShortExchangeList();
  };

  queryButtonClick = () => {
    this.setState(
      {
        pageSize: 200,
        pageIndex: 1
      },
      () => {
        this.loadShortListData();
      }
    );
  };

  loadShortListData = (pageIndex, pageSize) => {
    if (pageIndex === null || pageIndex === undefined) {
      pageIndex = this.state.pageIndex;
    }
    if (pageSize === null || pageSize === undefined) {
      pageSize = this.state.pageSize;
    }
    this.props.loadShortableList({
      ticker: this.state.ticker,
      exchange: this.state.exchange,
      brokerType: this.state.brokerType,
      rate: this.state.rate,
      pageSize: pageSize,
      pageIndex: pageIndex
    });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    if (isNotPhone()) {
      this.gridApi.sizeColumnsToFit();
    }
  };

  onQueryParamsChange = (event, fieldName) => {
    const filedValue = event.target.value;
    this.setState({ [fieldName]: filedValue });
  };

  onValueParamsChangeNotQuery = (value, fieldName) => {
    this.setState({
      [fieldName]: value
    });
  };

  onValueParamsChange = (value, fieldName) => {
    this.setState({ [fieldName]: value }, () => {
      this.queryButtonClick();
    });
  };

  onPageChage = (current, pageSize) => {
    this.setState({
      pageIndex: current
    });
    this.loadShortListData(current, pageSize);
  };

  onSelectionChanged = () => {
    if (!isNotPhone()) {
      return;
    }
    let totalAvailableQty = 0;
    let totalMv = 0;
    const selectedRows = this.gridApi.getSelectedRows();
    for (var rowIndex in selectedRows) {
      const data = selectedRows[rowIndex];
      if (data === undefined || data == null) {
        continue;
      }
      const shortableInfoVO = data['shortableInfoVO'];
      if (shortableInfoVO === undefined || shortableInfoVO == null) {
        continue;
      }
      const price = data['price'] ? data['price'] : 0;
      const availableQty = shortableInfoVO['availableQty']
        ? shortableInfoVO['availableQty']
        : 0;
      totalAvailableQty += availableQty;
      totalMv += availableQty * price;
    }
    this.setState({
      selectedSumQty: moneyFormatter({ value: totalAvailableQty }),
      selectedSumMv: moneyFormatter({ value: totalMv }),
      selectedRows
    });
  };

  _createShortHistReviewDialog = () => {
    const { shortableList } = this.props;
    const { showShortHistReviewDialog, selectedRows } = this.state;
    if (
      _.isEmpty(selectedRows) ||
      _.isEmpty(shortableList) ||
      !showShortHistReviewDialog
    )
      return;
    const selectData = selectedRows[0];
    const brokerList = _.uniq(
      shortableList
        .filter(r => r.ticker === selectData.ticker)
        .map(r => r.shortableInfoVO.broker)
    );
    return (
      showShortHistReviewDialog && (
        <ShortHistReviewDialog
          brokerOptions={brokerList}
          ticker={selectData.ticker}
          broker={selectData.shortableInfoVO.broker}
          closeDialog={this.closeShortHistReviewDialog}
        />
      )
    );
  };

  _getContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      {
        name: 'Show Hist',
        action: () => this._onShowHistClick(params.node.data)
      }
    ];

    // Add built-in menu items.
    return menuItems;
  };

  _onShowHistClick = data => {
    this.setState({
      showShortHistReviewDialog: true
    });
  };

  closeShortHistReviewDialog = data => {
    this.setState({
      showShortHistReviewDialog: false
    });
  };

  render() {
    const {
      isLoaded,
      shortableList,
      shortableListTotal,
      shortExchangeList,
      isLoadedShortExchangeStatus
    } = this.props;

    const sortedRows = _.orderBy(
      shortableList,
      ['ticker', 'shortableInfoVO.rate'],
      ['asc', 'asc']
    );
    const sortShortExchangeList = _.orderBy(
      shortExchangeList,
      ['value'],
      ['asc']
    );
    const heightOffset = this.props.heightOffset
      ? this.props.heightOffset
      : 155;
    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - heightOffset) / screenHeight) *
      100}%`;

    const size = isIphone() || isOtherPhone() ? 'small' : 'middle';
    return (
      <div
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <style>
          {`
                    #shortTickerSereach{
                        height: auto
                    }

                    .totalShortListLabel{
                        color: #0099CC;
                        margin-left:10px;
                        font-size:15px;
                        font-weight: bold
                    }

                    .totalShortListValue{
                        color: #969696;
                        font-size:15px;
                        font-weight: bold
                    }

                    .rc-pagination-item{
                        margin-right: '5px'
                    }

                `}
        </style>
        <div
          style={{
            width: '100%',
            textAlign: 'right',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              textAlign: 'right',
              width: '500px',
              float: 'right',
              marginBottom: '3px'
            }}
          >
            <Search
              id="shortTickerSereach"
              placeholder="Input ticker..."
              size={size}
              style={{
                width: '135px'
              }}
              value={this.state.ticker}
              onChange={e => {
                this.onQueryParamsChange(e, 'ticker');
              }}
              onPressEnter={this.queryButtonClick}
              onSearch={this.queryButtonClick}
            />
            <Divider type="vertical" />
            {!isLoadedShortExchangeStatus ? (
              <div />
            ) : (
              <Select
                allowClear
                showSearch
                size={size}
                placeholder="Select a Exchange"
                value={this.state.exchange}
                options={sortShortExchangeList}
                onChange={(value, e) => {
                  this.onValueParamsChange(value, 'exchange');
                }}
              ></Select>
            )}
            <Divider type="vertical" />
            <Select
              allowClear
              showSearch
              size={size}
              placeholder="Broker Type"
              value={this.state.brokerType}
              onChange={(value, e) => {
                this.onValueParamsChange(value, 'brokerType');
              }}
            >
              <Option value={null}>All Type</Option>
              <Option value="1">AXE</Option>
              <Option value="2">LongSell</Option>
              <Option value="3">MKT_CONN</Option>
              <Option value="0,4">MKT</Option>
            </Select>
            <Divider type="vertical" />
            <InputNumber
              placeholder="Rate"
              value={this.state.rate}
              min={-100}
              max={100}
              size={size}
              step={0.1}
              style={{
                width: '80px'
              }}
              onChange={e => {
                this.onValueParamsChangeNotQuery(e, 'rate');
              }}
              onPressEnter={this.queryButtonClick}
            />
            {/* <Button
                            size="mini"
                            content="Reload"
                            color="green"
                            disabled={!isLoaded}
                            style={{ marginBottom: '4px', marginTop: '4px' ,marginLeft:'5px'}}
                            onClick={this.queryButtonClick}
                        /> */}
          </div>
        </div>
        <div
          className={`ag-theme-balham-dark`}
          style={{
            height: gridDivHeight,
            width: '100%'
          }}
        >
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              // getRowNodeId={this.getRowNodeId}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="multiple"
              context={this.state.context}
              sideBar={this.state.sideBar}
              // events
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
              getContextMenuItems={this._getContextMenuItems}
            />
          )}
        </div>
        <div style={{ marginTop: '3px' }}>
          {isNotPhone() ? (
            <div style={{ float: 'left' }}>
              <span className="totalShortListLabel">Qty Sum: </span>
              <span className="totalShortListValue">
                {this.state.selectedSumQty}
              </span>
              <span
                className="totalShortListLabel"
                style={{ marginLeft: '20px' }}
              >
                MV Sum:{' '}
              </span>
              <span className="totalShortListValue">
                {this.state.selectedSumMv}
              </span>
            </div>
          ) : (
            <span style={{ display: 'none' }}></span>
          )}

          <Pagination
            current={this.state.pageIndex}
            pageSize={this.state.pageSize}
            total={shortableListTotal}
            showTotal={(total, range) =>
              `${range[0]} - ${range[1]} of ${total} items `
            }
            style={{ color: 'white', float: 'right' }}
            onChange={this.onPageChage}
            showLessItems={true}
          />
        </div>
        {this._createShortHistReviewDialog()}
      </div>
    );
  }
}

export default ShortListGrid;
