import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Button, Loader } from 'semantic-ui-react';
import 'ag-grid-enterprise';
import { DatePicker } from 'antd';
import _ from 'lodash';

const { MonthPicker } = DatePicker;
class AnalystListComplimentGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.analystListGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      autoGroupColumnDef: {
        headerName: 'Voting Month',
        field: 'votingMonth',
        cellClass: 'keyword'
      },
      groupDefaultExpanded: 1,
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      analystComplimentId: null,
      selectedData: null
    };
  }

  componentDidMount() {
    this.loadAnalystList();
  }

  onReloadAnalystComplimentBtnClicked = () => {
    this.loadAnalystList();
  };

  loadAnalystList = () => {
    const { analystQueryParams } = this.props;
    const queryParams = {
      votingMonthFromStr: analystQueryParams.votingMonthFrom.format('YYYY-MM'),
      votingMonthToStr: analystQueryParams.votingMonthTo.format('YYYY-MM')
    };
    this.props.loadAnalystList(queryParams);
  };

  onNewAnalystComplimentBtnClicked = () => {
    const { votingMonth } = this.props.analystListModalData;
    this.props.getAnalystNameEditList({});
    this.props.getAnalystSectorList({});
    this.props.getAnalystDetailList({
      votingMonth: votingMonth.format('YYYY-MM')
    });
    this.props.changeAnalystModalInput({ name: 'isOpened', value: true });
  };

  onUpdateAnalystComplimentBtnClicked = () => {
    const data = Object.assign({}, this.state.selectedData);
    this.props.changeAnalystUpdateModalInput({ name: 'data', value: data });
    this.props.changeAnalystUpdateModalInput({ name: 'isOpened', value: true });
  };

  showRowItemUpdateDialog = () => {
    this.props.openAnalystComplimentUpdateDialog(
      this.state.analystComplimentId
    );
    this.setState({
      analystComplimentId: null
    });
  };

  deleteRowItem = () => {
    this.props.deleteAnalyst(
      { dataList: [{ id: this.state.analystComplimentId }] },
      this.props.analystQueryParams
    );
    this.setState({
      analystComplimentId: null,
      selectedData: null
    });
  };

  showDataRowItemUpdateDialog() {
    this.props.openAnalystComplimentUpdateDialog(
      this.state.analystComplimentId
    );
  }

  deleteDataRowItem() {
    this.props.deleteAnalystCompliment(this.state.analystComplimentId);
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedKeys = selectedRows.map(r => r.id);

    this.setState({
      analystComplimentId: selectedKeys.length ? selectedKeys[0] : null,
      selectedData: selectedRows[0]
    });
  };

  dateFromInputOnChange = date => {
    this.props.changeAnalystQueryParams({
      name: 'votingMonthFrom',
      value: date
    });
  };

  dateToInputOnChange = date => {
    this.props.changeAnalystQueryParams({ name: 'votingMonthTo', value: date });
  };

  render() {
    const {
      analystList,
      className = 'grid-wrapper',
      isLoaded,
      analystQueryParams
    } = this.props;

    const sortedRows = _.orderBy(analystList, ['votingMonth'], ['desc']);

    return (
      <div className={className}>
        <div>
          <style>
            {`
            .filter {
              display: flex;
              justify-content: flex-end;
              padding: 1px;
              background-color: #1B1C1D;
              margin-bottom: 2px;
            }

            .filter .ui.selection.dropdown {
              border: 1px solid #54C8FF;
              margin-right: 5px;
              margin-top: 5px;
              margin-bottom: 5px;
            }

          `}
          </style>

          <div
            style={{
              textAlign: 'right',
              backgroundColor: '#1B1C1D',
              marginBottom: '2px'
            }}
          >
            <MonthPicker
              placeholder={'date start'}
              allowClear={false}
              value={analystQueryParams.votingMonthFrom}
              size="small"
              onChange={this.dateFromInputOnChange}
            />

            <MonthPicker
              style={{ marginRight: '5px' }}
              placeholder={'date end'}
              allowClear={false}
              value={analystQueryParams.votingMonthTo}
              size="small"
              onChange={this.dateToInputOnChange}
            />

            <Button
              size="mini"
              content="Reload"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.onReloadAnalystComplimentBtnClicked}
            />

            <Button
              size="mini"
              content="Add/Edit"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.onNewAnalystComplimentBtnClicked}
            />

            <Button
              size="mini"
              content="Delete"
              color="red"
              disabled={!isLoaded || !this.state.analystComplimentId}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this.deleteRowItem}
            />
          </div>
        </div>
        <div className={`ag-theme-balham-dark ${className}`}>
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              groupDefaultExpanded={this.state.groupDefaultExpanded}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              enableRangeSelection={true}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              context={this.state.context}
              sideBar={this.state.sideBar}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AnalystListComplimentGrid;
