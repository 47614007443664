import React, { Component } from 'react';
import { Rate } from 'antd';

export default class RatingBarShowOnlyComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || 0,
      className: this.props.className || ''
    };
  }

  render() {
    const { value = 0 } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ width: '100%' }}>
          <Rate
            className={this.state.className}
            icon="star"
            style={{ fontSize: '15px', marginRight: '0px' }}
            value={value}
            allowHalf={true}
            defaultRating={value}
            count={5}
            disabled
          />
        </div>
      </div>
    );
  }
}
