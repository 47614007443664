import { createSelector } from 'reselect';
import EntityMap from 'entities/EntityMap';
import { PORTFOLIO_VIEW_MODE_LIVE } from './portfolioConstants';
import _ from 'lodash';

export const selectPortfolio = state => state.portfolio;

export const selectUi = createSelector(
  selectPortfolio,
  portfolio => portfolio.ui
);

export const selectPositions = createSelector(
  selectPortfolio,
  portfolio => {
    const viewMode = portfolio.ui.viewMode;
    return EntityMap.map(portfolio.viewState[viewMode].positions);
  }
);

export const selectRiskInfos = createSelector(
  selectPortfolio,
  portfolio => {
    const viewMode = portfolio.ui.viewMode;
    return EntityMap.map(portfolio.viewState[viewMode].riskInfos);
  }
);

export const selectRiskInfoMap = createSelector(
  selectPortfolio,
  portfolio => {
    const viewMode = portfolio.ui.viewMode;
    return portfolio.viewState[viewMode].riskInfos;
  }
);

export const selectLiveBenchmarks = createSelector(
  selectPortfolio,
  portfolio => {
    return EntityMap.map(
      portfolio.viewState[PORTFOLIO_VIEW_MODE_LIVE].benchmarks
    );
  }
);

export const selectLiveRiskInfos = createSelector(
  selectPortfolio,
  portfolio => {
    return EntityMap.map(
      portfolio.viewState[PORTFOLIO_VIEW_MODE_LIVE].riskInfos
    );
  }
);

export const selectLiveRiskInfoMap = createSelector(
  selectPortfolio,
  portfolio => {
    return portfolio.viewState[PORTFOLIO_VIEW_MODE_LIVE].riskInfos;
  }
);

// export const selectLiveHoldingMap = createSelector(
//   selectPortfolio,
//   portfolio => {
//     return portfolio.viewState[PORTFOLIO_VIEW_MODE_LIVE].holdings;
//   }
// );

export const selectLivePositionMap = createSelector(
  selectPortfolio,
  portfolio => {
    return portfolio.viewState[PORTFOLIO_VIEW_MODE_LIVE].positions;
  }
);

export const selectIsPortfolioLoaded = createSelector(
  selectPortfolio,
  portfolio => {
    const viewMode = portfolio.ui.viewMode;
    return portfolio.viewState[viewMode].isLoaded;
  }
);

export const isMobilePhone = () => {
  const sUserAgent = navigator ? navigator.userAgent : null;
  return (
    sUserAgent &&
    (sUserAgent.indexOf('Android') > -1 ||
      sUserAgent.indexOf('iPhone') > -1 ||
      sUserAgent.indexOf('iPad') > -1 ||
      sUserAgent.indexOf('iPod') > -1 ||
      sUserAgent.indexOf('Symbian') > -1)
  );
};

export const getEnableTxnFundBooks = settings => {
  const { funds } = settings || {};
  if (_.isEmpty(funds)) return [];
  const fundBooks = [];
  funds.forEach(f => {
    const books = f.books ? f.books.filter(r => r.enableTxn) : [];
    if (_.isEmpty(books)) return null;
    books.forEach(item => {
      fundBooks.push(`${f.name}-${item.name}`);
    });
  });
  return fundBooks;
};
