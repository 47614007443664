import { isInternalFund } from 'common/utils/DomainUtils';
import Trade from '../Trade';
import _ from 'lodash';

const _calcQtyByPct = (ctx, qtyPct, isFlat = false) => {
  const { usdNav, usdValuePerShare, lotSize = 1 } = ctx;

  // For flat trade, no need to do any calculation.
  if (isFlat) return Math.abs(ctx.theoreticalPosnForClose);

  return usdValuePerShare
    ? _.round(((qtyPct / 100.0) * usdNav) / usdValuePerShare / lotSize) *
        lotSize
    : 0;
};

const _calcPctByQty = (ctx, qty) => {
  const { usdNav, usdValuePerShare } = ctx;

  return usdNav
    ? _.round(parseFloat((qty * usdValuePerShare * 100) / usdNav), 3)
    : 0;
};

const _calcUsdByQty = (ctx, qty) => {
  const { usdValuePerShare } = ctx;

  return _.round(qty * usdValuePerShare);
};

const _calcQtyByUsd = (ctx, usd) => {
  const { lotSize, usdValuePerShare } = ctx;

  return usdValuePerShare
    ? _.round(usd / usdValuePerShare / lotSize) * lotSize
    : 0;
};

const _calcPosnStartPct = (ctx, isOpen) => {
  const {
    usdNav,
    usdValuePerShare,
    theoreticalPosn,
    theoreticalPosnForClose,
    prevFilledQuantity,
    side
  } = ctx;

  const openFlag = _.isNil(isOpen)
    ? ['BUY', 'SHRT'].includes(side)
      ? true
      : false
    : isOpen;
  const posStart = openFlag ? theoreticalPosn : theoreticalPosnForClose;

  // Must minus prevFilledQuantity if edit GTC order.
  return usdNav
    ? (((posStart - prevFilledQuantity * Trade.parseTradeSign(side)) *
        usdValuePerShare) /
        usdNav) *
        100
    : 0;
};

const _calcPosnEndPctByQtyPct = (ctx, qtyPct) => {
  const { side } = ctx;

  const posnStartPct = _calcPosnStartPct(ctx);
  const posnEndPct = posnStartPct + qtyPct * Trade.parseTradeSign(side);
  return { posnStartPct, posnEndPct };
};

const _calcQtyPctByPosnEndPct = (ctx, posnEndPct) => {
  const posnStartPctForOpen = _calcPosnStartPct(ctx, true);
  const posnStartPctForClose = _calcPosnStartPct(ctx, false);

  const posnStartPct =
    Math.abs(posnEndPct) < Math.abs(posnStartPctForClose)
      ? posnStartPctForClose
      : Math.abs(posnEndPct) > Math.abs(posnStartPctForOpen)
      ? posnStartPctForOpen
      : posnEndPct;

  const qtyPct = posnEndPct - posnStartPct;

  if (qtyPct === 0) {
    return {
      posnStartPct,
      side: 'N.A.',
      qtyPct: 0
    };
  }

  const _calcSide = (pe, ps) => {
    if (pe >= 0 && ps >= 0) {
      return qtyPct > 0 ? 'BUY' : 'SELL';
    } else if (pe <= 0 && ps <= 0) {
      return qtyPct < 0 ? 'SHRT' : 'COVR';
    } else {
      return ps > 0 ? 'SELL' : 'COVR';
    }
  };
  return {
    posnStartPct,
    side: _calcSide(posnEndPct, posnStartPct),
    qtyPct: Math.abs(qtyPct)
  };
};

const _buildCalcCtx = (trade, security, riskInfoMap, fundBooks) => {
  const {
    fundCode,
    bookCode,
    strategy,
    side,
    prevFilledQuantity = 0,
    limitPriceLocal,
    secCcy
  } = trade;
  const riskKey = `${fundCode}-${bookCode}`;
  const masterKey = _.get(fundBooks, [riskKey, 'masterFundBook']);
  const risk = riskInfoMap[riskKey] || riskInfoMap[masterKey];

  if (!security || !risk) {
    return null;
  }

  const {
    securityType2,
    undlPrice,
    price,
    usdFxRate,
    lotSize = 1,
    priceMultiplier = 1,
    txnClass,
    ticker
  } = security;

  const { usdNav } = risk;

  let usdValuePerShare = 0;
  if (Trade.isFXTrade(txnClass)) {
    const cp = ticker.split(' ')[0];
    const sharePrice = cp.startsWith(secCcy)
      ? 1
      : cp.endsWith(secCcy)
      ? 1 / price
      : null;
    usdValuePerShare = sharePrice * priceMultiplier * usdFxRate;
  } else {
    // Use market price even when place limit order.
    // Yet for IPO trade, the price is null, thus use limit price instead.
    // For option, just use undl price instead of price.
    usdValuePerShare =
      (securityType2 === 'Option' ? undlPrice : price || limitPriceLocal || 0) *
      priceMultiplier *
      usdFxRate;
  }

  const posKey = `${fundCode}-${bookCode}-${strategy}`;
  const { theoreticalPosn = 0, theoreticalPosnForClose = 0 } =
    security.positions[posKey] || {};

  return {
    usdNav,
    lotSize,
    usdValuePerShare,

    side,
    theoreticalPosn,
    theoreticalPosnForClose,

    prevFilledQuantity
  };
};

const QTY_INPUT_MAPPING = {
  quantity: 'ByShare',
  qtyUsd: 'ByUsd',
  qtyPct: 'ByPct',
  posnEndPct: 'ByPosPct',
  secCcy: 'ByShare'
};

const TradeCalculator = {
  calcAll(trade, security, riskInfoMap, fundBooks) {
    const ctx = _buildCalcCtx(trade, security, riskInfoMap, fundBooks);

    if (ctx) {
      let {
        quantity = 0,
        qtyPct = 0,
        qtyUsd = 0,
        posnEndPct = 0,
        posnStartPct = 0,
        side
      } = trade;

      const qtyInput = trade.qtyInput || 'ByShare';

      switch (qtyInput) {
        case 'ByShare':
          qtyPct = _calcPctByQty(ctx, quantity);
          qtyUsd = _calcUsdByQty(ctx, quantity);
          ({ posnStartPct, posnEndPct } = _calcPosnEndPctByQtyPct(ctx, qtyPct));
          break;
        case 'ByUsd':
          quantity = _calcQtyByUsd(ctx, qtyUsd);
          qtyPct = _calcPctByQty(ctx, quantity);
          ({ posnStartPct, posnEndPct } = _calcPosnEndPctByQtyPct(ctx, qtyPct));
          break;
        case 'ByPct':
          ({ posnStartPct, posnEndPct } = _calcPosnEndPctByQtyPct(ctx, qtyPct));
          quantity = _calcQtyByPct(ctx, qtyPct, parseFloat(posnEndPct) === 0);
          qtyUsd = _calcUsdByQty(ctx, quantity);
          break;
        case 'ByPosPct':
          ({ posnStartPct, qtyPct, side } =
            _calcQtyPctByPosnEndPct(ctx, posnEndPct) || {});
          quantity = _calcQtyByPct(ctx, qtyPct, parseFloat(posnEndPct) === 0);
          qtyUsd = _calcUsdByQty(ctx, quantity);
          break;
        default:
          break;
      }

      // const posnStartPct = _calcPosnStartPct(ctx);

      return {
        qtyInput,
        qtyPct,
        qtyUsd,
        quantity,
        side,
        posnStartPct,
        posnEndPct
      };
    }

    return {};
  },

  calcQtyByCloseOption(trade, security, closeOption) {
    if (!security) {
      return null;
    }

    const key = `${trade.fundCode}-${trade.bookCode}-${trade.strategy}`;
    const { theoreticalPosnForClose = 0 } = security.positions[key] || {};

    // If sell/cover ALL, then no need to round by lotsize.
    const lotSize = closeOption === 1 ? 1 : security.lotSize;

    const quantity = Math.abs(
      _.round((theoreticalPosnForClose * closeOption) / lotSize) * lotSize
    );

    return quantity;
  },

  calcTimeInForce(fund, defaultValue) {
    return !isInternalFund(fund) ? 'DAY' : defaultValue || 'GTC';
  },

  calcAlgo(defaultValue) {
    return defaultValue || 'INLINE';
  },

  calcOrderType(defaultValue) {
    return defaultValue || 'MKT';
  },

  getQtyInputByFieldName(name) {
    return QTY_INPUT_MAPPING[name];
  },

  calcQtyInputField(trade) {
    return _.first(
      Object.keys(QTY_INPUT_MAPPING).filter(k => !_.isNil(trade[k]))
    );
  },

  allocateInlinePct(trades, totalInlinePct) {
    const totalQtyUsd = _.sumBy(trades, t => t.qtyUsd);
    return trades.map(t => {
      return {
        ...t,
        inlinePct: totalQtyUsd
          ? _.round((t.qtyUsd / totalQtyUsd) * totalInlinePct) ||
            (t.qtyUsd === 0 ? 0 : 1)
          : 0
      };
    });
  }
};

export default TradeCalculator;
