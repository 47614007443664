import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import PushVotesItemComponent from './PushVotesItemComponent';
import { Loader } from 'semantic-ui-react';
import { pushVotesPMGridColumns } from '../GridColumnMap';
import { isPmRole } from 'common/utils/DomainUtils';
import AddOrUpdateDialog from './AddOrUpdateDialog';
import StateSynchronizer from 'common/utils/StateSynchronizer';
import AddNoteDialog from './AddNoteDialog';

class PushVotesGrid extends Component {
  constructor(props) {
    super(props);
    const user = this.props.user;
    this.state = {
      columnDefs: isPmRole(user.role)
        ? pushVotesPMGridColumns
        : props.pushVotesGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      frameworkComponents: {
        pushVotesItemComponent: PushVotesItemComponent
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      selectedItems: [],
      pmFields: [
        'type',
        'isRead',
        'tickerLong',
        'tickerNameLong',
        'tickerShort',
        'tickerNameShort',
        'submitDate'
      ]
    };
  }

  getRowHeight = () => {
    return 130;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'stock-pitch-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const data = {
      ...selectedRows[0]
    };
    this.props.changePushVotesSelectItem(data);
  };

  _createAddUpdateModal = () => {
    const { pushVotesModal } = this.props;
    return pushVotesModal.isOpen && <AddOrUpdateDialog {...this.props} />;
  };

  _createAddNoteModal = () => {
    const { pushVoteNoteModal } = this.props;
    return pushVoteNoteModal.isOpen && <AddNoteDialog {...this.props} />;
  };

  render() {
    const {
      pushVotesDataList,
      isLoaded,
      className = 'grid-wrapper'
    } = this.props;

    const sortedRows = _.orderBy(pushVotesDataList, ['updateTime'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '88%' }}
        >
          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              deltaRowDataMode={false}
              animateRows={true}
              enableRangeSelection={true}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              frameworkComponents={this.state.frameworkComponents}
              context={this.state.context}
              sideBar={this.state.sideBar}
              getRowNodeId={data => {
                return data.id;
              }}
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
            />
          )}
        </div>
        {this._createAddUpdateModal()}
        {this._createAddNoteModal()}
      </div>
    );
  }
}

export default PushVotesGrid;
