import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import numeral from 'numeral';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { treasuryDataColumns } from './GridColumns';
import _ from 'lodash';

class DashbordDataGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),
      autoGroupColumnDef: {
        cellClass: 'keyword',
        lockPinned: true
        // field: 'pairName',
        // headerName: 'Name',
      },
      data: this.props.data ? this.props.data : []
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  _onRangeSelectionChanged = () => {
    const gridApi = this.gridApi;
    const cellRanges = gridApi.getCellRanges();
    if (cellRanges) {
      let sum = 0;
      cellRanges.forEach(function(range) {
        // get starting and ending row, remember rowEnd could be before rowStart
        const startRow = Math.min(
          range.startRow.rowIndex,
          range.endRow.rowIndex
        );
        const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          // eslint-disable-next-line no-loop-func
          range.columns.forEach(column => {
            const rowModel = gridApi.getModel();
            const rowNode = rowModel.getRow(rowIndex);
            const value = gridApi.getValue(column, rowNode);
            if (_.isNumber(value)) {
              sum += value;
            }
          });
        }
      });
      this.setState(
        {
          sum
        },
        () => {
          this.props.onSelectedBrokerData(gridApi.getSelectedRows());
        }
      );
    }
  };

  _createGrid = () => {
    const { agGridSettings } = this.state;
    const { data } = this.props;
    const rowData = data ? data : [];
    return (
      <div className={`ag-theme-balham-dark`} style={{ flexGrow: 1 }}>
        <AgGridReact
          // properties
          {...agGridSettings}
          rowData={rowData}
          columnDefs={treasuryDataColumns}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          deltaRowDataMode={false}
          enableRangeSelection={true}
          onRangeSelectionChanged={this._onRangeSelectionChanged}
          // events
          onGridReady={this.onGridReady}
        />
      </div>
    );
  };

  _createSummaryPannel = () => {
    const { sum } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        <span
          style={{
            color: '#0099CC',
            marginLeft: '10px',
            fontSize: '15px',
            fontWeight: 'bold'
          }}
        >
          Summary:{' '}
        </span>
        <span
          style={{
            color: '#969696',
            fontSize: '15px',
            fontWeight: 'bold'
          }}
        >
          {numeral(sum).format('0,0')}
        </span>
      </div>
    );
  };

  render() {
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {this._createGrid()}
        {this._createSummaryPannel()}
      </div>
    );
  }
}

export default DashbordDataGrid;
