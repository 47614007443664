import React, { Component } from 'react';
import { HotTable } from '@handsontable/react';
import _ from 'lodash';
class AnalystAddGrid extends Component {
  constructor(props) {
    super(props);
    const { brokerCodeList, analystNameList, analystSectorList } = this.props;
    this.state = {
      contextMenu: ['row_above', 'row_below', 'remove_row'],
      colWidths: [140, 200, 200, 140],
      errorMsgs: []
    };
    this.columns = [
      {
        data: 'brokerCode',
        headerName: 'Broker',
        type: 'dropdown',
        source: brokerCodeList,
        validator: function(value, callback) {
          callback(true);
        }
      },
      {
        data: 'analystName',
        readOnly: false,
        allowEmpty: false,
        type: 'dropdown',
        source: analystNameList,
        validator: function(value, callback) {
          callback(true);
        }
      },
      {
        data: 'sector',
        readOnly: false,
        allowEmpty: false,
        type: 'dropdown',
        source: analystSectorList,
        validator: function(value, callback) {
          callback(true);
        }
      },
      {
        data: 'score',
        readOnly: false,
        allowEmpty: false,
        type: 'numeric',
        numericFormat: {
          pattern: '0',
          allowNegative: false
        }
      }
    ];
    this.nestedHeaders = [
      ['Broker Name', 'Analyst Name', 'Sector', 'Score(0-10)']
    ];
  }

  afterChangeHandle = (value, action) => {
    const { data } = this.props.analystListModalData;
    if (action === 'edit') {
      const current = value[0];
      if (value) {
        const index = current[0];
        data[index]['op'] = 'U';
      }
    }
    if (action === 'Autofill.fill') {
      value.forEach(item => {
        const index = item[0];
        data[index]['op'] = 'U';
      });
    }
  };
  _onRouteSelectionChanged = (row, col, row2, col2) => {
    const { selectedDataChange } = this.props;
    const { data } = this.props.analystListModalData;
    const selectedRoutes = _.slice(data, row, row2 + 1);
    selectedDataChange(selectedRoutes[0]);
  };
  render() {
    const { data } = this.props.analystListModalData;
    return (
      <div>
        <style>
          {`
            .ui.small.modal {
              width: 1500px;

          }
          .handsontable table.htCore {

              width: 1400px;

          }
          .ui.modal > .content {

            width: 1500px;

        }
          `}
        </style>
        <HotTable
          nestedHeaders={this.nestedHeaders}
          columns={this.columns}
          colWidths={this.state.colWidths}
          data={data}
          autoColumnSize={true}
          manualColumnResize={true}
          colHeaders={true}
          rowHeaders={false}
          dragToScroll={false}
          allowInsertRow={true}
          allowRemoveRow={true}
          contextMenu={this.state.contextMenu}
          afterChange={this.afterChangeHandle}
          afterSelectionEnd={this._onRouteSelectionChanged}
          width="100%"
          height="330px"
          licenseKey="non-commercial-and-evaluation"
        />
      </div>
    );
  }
}

export default AnalystAddGrid;
