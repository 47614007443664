import React, { Component } from 'react';
import ReportGrid from './ReportGrid';

class ReportTab extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <ReportGrid {...this.props} />
      </div>
    );
  }
}

export default ReportTab;
