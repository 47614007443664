import React, { PureComponent } from 'react';
import { Modal, DatePicker, Select, Spin, Dropdown, Menu } from 'antd';
import { Loader, Dimmer, Message } from 'semantic-ui-react';
import client from 'features/oms/api/client';
import { HotTable } from '@handsontable/react';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;
class ReportShowDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initQueryParams: { tags: ['oms'] },
      isInitialized: false,
      isLoad: false,
      queryParams: {},
      reportList: { names: null },
      reportConfig: [],
      currentReport: null,
      data: {},
      cache: {},
      reportData: [],
      selectSummary: {
        count: null,
        avg: null,
        total: null
      },
      customReport: [
        'Broker Score Report',
        'Broker Score(Q) Report',
        'NonPB Broker Score(Q)',
        'Commission Report'
      ]
    };
  }

  componentDidMount() {
    this.hotTblRef = React.createRef();
    this._init();
  }

  _init = () => {
    Promise.all([client.getReportSpecs(this.state.initQueryParams)])
      .then(([data]) => {
        let reportArr = [];
        let currentReport = null;
        if (data) {
          reportArr = data.map((item, index) => {
            const name = item['name'];
            const desc = item['desc'];
            if (index === 0) {
              currentReport = item['name'];
            }
            return {
              name,
              desc
            };
          });
        }
        const reportList = { names: reportArr };
        this.setState({
          isInitialized: true,
          isLoad: false,
          reportConfig: data,
          reportList,
          currentReport
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ isLoad: false, isInitialized: true });
      });
  };

  _putToCache = () => {
    const { currentReport, queryParams, data, cache } = this.state;
    const cacheData = {
      queryParams,
      data
    };
    const updateCache = {
      ...cache,
      [currentReport]: cacheData
    };
    this.setState({
      cache: updateCache
    });
  };

  _getFromCache = () => {
    const { currentReport } = this.state;
    const cacheData = this.state.cache[currentReport];
    if (cacheData) {
      const reportData = this._getCurrentReportData(
        cacheData['data'],
        cacheData['queryParams'],
        currentReport
      );
      this.setState({
        reportData,
        queryParams: cacheData['queryParams'],
        data: cacheData['data']
      });
    }
  };

  _createQueryPanel = () => {
    const { reportConfig, currentReport } = this.state;
    if (!currentReport) {
      return;
    }
    for (let i = 0; i < reportConfig.length; i++) {
      const item = reportConfig[i];
      if (item['name'] === currentReport) {
        return this._createQueryInput(item);
      }
    }
  };

  _createQueryInput = data => {
    if (data && data['spec'] && data['spec']['fields']) {
      const fields = data['spec']['fields'];
      const inputArr = [];
      for (let key in fields) {
        const input = this._createInputByInfo(key, fields[key]);
        if (input) {
          inputArr.push(input);
        }
      }
      const menu = (
        <Menu onClick={this._handleMenuClick}>
          <Menu.Item key="export">Export</Menu.Item>
        </Menu>
      );
      return (
        <div style={{ float: 'right' }}>
          {inputArr.map(item => item)}
          <Dropdown.Button
            type="primary"
            overlay={menu}
            onClick={this.doQuery}
            style={{ marginLeft: '5px' }}
          >
            Query
          </Dropdown.Button>
        </div>
      );
    }
  };

  _handleMenuClick = ({ key }) => {
    const { currentReport, queryParams } = this.state;
    if (key === 'export') {
      this._createReport(currentReport, queryParams, true);
    }
  };

  _createInputByInfo = (key, item) => {
    const { queryParams } = this.state;
    const type = item['type'];
    const defaultValue = item['defaultValue'];
    if (!this.state.queryParams[key]) {
      queryParams[key] = defaultValue;
    }
    // const label = item['label'];
    const fieldSettings = item['fieldSettings'];
    if (type === 'date') {
      const format = fieldSettings['format'];
      let picker;
      if ('YYYY-MM' === format) {
        picker = 'month';
      } else if ('YYYY-Q' === format) {
        picker = 'quarter';
      }
      if (picker) {
        return (
          <DatePicker
            defaultValue={defaultValue}
            value={moment(this.state.queryParams[key], format)}
            picker={picker}
            format={format}
            style={{ width: '200px', marginLeft: '5px' }}
            onChange={(date, dateString) =>
              this.onInputChange({
                name: key,
                value: dateString
              })
            }
          />
        );
      }
      return (
        <DatePicker
          defaultValue={defaultValue}
          value={moment(this.state.queryParams[key], format)}
          format={format}
          style={{ width: '200px', marginLeft: '5px' }}
          onChange={(date, dateString) =>
            this.onInputChange({
              name: key,
              value: dateString
            })
          }
        />
      );
    } else if (type === 'select') {
      const listValues = fieldSettings['listValues'];
      if (
        !defaultValue &&
        !this.state.queryParams[key] &&
        listValues.length > 0
      ) {
        queryParams[key] = listValues[0];
      }
      return (
        <Select
          onChange={data => {
            this.onInputChange({
              name: key,
              value: data
            });
          }}
          value={this.state.queryParams[key]}
          style={{ marginLeft: '5px', width: 200 }}
          allowClear={false}
        >
          {listValues.map(t => (
            <Option value={t} key={t}>
              {t}
            </Option>
          ))}
        </Select>
      );
    }
    return null;
  };

  onInputChange = ({ name, value }) => {
    const { queryParams } = this.state;
    const updateData = {
      ...queryParams,
      [name]: value
    };
    if (name === 'view') {
      const reportData = this._getCurrentReportData(
        this.state.data,
        updateData,
        this.state.currentReport
      );
      this.setState({
        queryParams: updateData,
        reportData
      });
    } else {
      this.setState({
        queryParams: updateData
      });
    }
  };

  onReportInputChange = value => {
    this._putToCache();
    this.setState(
      {
        currentReport: value,
        reportData: [],
        queryParams: {},
        selectSummary: {
          count: null,
          avg: null,
          total: null
        }
      },
      this._getFromCache
    );
  };

  _createHotTable = () => {
    const { data, queryParams, currentReport } = this.state;
    const view = queryParams['view'];
    if (!data || !data['views'] || currentReport !== data['name']) {
      return;
    }
    const views = data['views'];
    const viewReport = views[view];
    if (!viewReport) {
      return;
    }
    let columns = viewReport['columns'];
    columns = this._formatColumnSetting(columns);
    if (_.isEmpty(columns)) return;
    const nestedHeaders = this._createHotTableNestedHeaders(columns);
    const tableColumns = this._createHotTableColumns(nestedHeaders);
    let colHeaders;
    if (nestedHeaders.length === 1) {
      colHeaders = nestedHeaders[0].map(item => item['label']);
    }

    let rowHeaders = viewReport['index'];
    if (!rowHeaders) {
      rowHeaders = false;
    }

    const length = nestedHeaders[nestedHeaders.length - 1].length;
    let baseSize = 1630 / (length + 1);
    if (!rowHeaders) {
      baseSize = 1630 / length;
    }
    if (baseSize < 100) {
      baseSize = 100;
    }
    const rowSizeArr =
      length > 10 ? null : this._getHotTableColumnWidth(baseSize, length);

    const multiColumnSorting = !rowHeaders;

    const hotTableSetting = this._createHotTableSetting({
      colWidths: rowSizeArr
    });
    if (colHeaders) {
      return (
        <HotTable
          {...hotTableSetting}
          ref={this.hotTblRef}
          colHeaders={colHeaders}
          data={this.state.reportData}
          columns={tableColumns}
          rowHeaders={rowHeaders}
          afterSelectionEnd={this._onSelectionChanged}
          afterColumnSort={this._afterColumnSortHandle}
          rowHeaderWidth={baseSize}
          autoColumnSize={true}
          width="1650px"
          height="700px"
          multiColumnSorting={multiColumnSorting}
          filters={true}
          dropdownMenu={['filter_by_value', 'filter_action_bar']}
        />
      );
    }
    return (
      <HotTable
        {...hotTableSetting}
        ref={this.hotTblRef}
        nestedHeaders={nestedHeaders}
        colHeaders={true}
        data={this.state.reportData}
        columns={tableColumns}
        rowHeaders={rowHeaders}
        afterSelectionEnd={this._onSelectionChanged}
        rowHeaderWidth={baseSize}
        autoColumnSize={true}
        width="1650px"
        height="700px"
        multiColumnSorting={multiColumnSorting}
      />
    );
  };

  _formatColumnSetting = columns => {
    let depth = 1;
    columns.forEach(item => {
      if (_.isArray(item)) {
        depth = item.length;
      }
    });
    const columnsSetting = columns.map(item => {
      if (_.isArray(item)) {
        return item;
      } else {
        const arr = [];
        for (let i = 0; i < depth; i++) {
          if (i === depth - 1) {
            arr.push(item);
          } else {
            arr.push('');
          }
        }
        return arr;
      }
    });
    return columnsSetting;
  };

  _createHotTableSetting = params => {
    const readOnly = true;
    const licenseKey = 'non-commercial-and-evaluation';
    let setting = {};
    if (params.colWidths) {
      setting = {
        ...params
      };
    }
    return {
      ...setting,
      readOnly,
      autoColumnSize: true,
      manualColumnResize: true,
      dragToScroll: false,
      licenseKey
    };
  };

  _afterColumnSortHandle = () => {
    const { data, currentReport } = this.state;
    // const view = queryParams['view'];
    if (!data || !data['views'] || currentReport !== data['name']) {
      return;
    }
    const ref = this.hotTblRef;
    const reportData = ref.current.hotInstance.getData();
    this.setState({
      reportData
    });
  };

  _getCurrentReportData = (data, queryParams, currentReport) => {
    const view = queryParams['view'];
    if (!data || !data['views'] || currentReport !== data['name']) {
      return;
    }
    const views = data['views'];
    const viewReport = views[view];
    return viewReport['data'];
  };

  _onSelectionChanged = () => {
    const { reportData } = this.state;
    if (!reportData) {
      return;
    }
    const ref = this.hotTblRef;
    const selectArr = ref.current.hotInstance.getSelected();
    //cal summary
    let count = 0,
      avg = 0,
      total = 0;
    if (selectArr && selectArr.length > 0) {
      selectArr.forEach(item => {
        const startRow = item[0];
        const endRow = item[2];
        const index = _.toNumber(item[1]);
        count += endRow - startRow + 1;
        for (let i = startRow; i <= endRow; i++) {
          const row = reportData[i];
          const rowData = row[index];
          if (_.isNumber(rowData)) {
            total += _.toNumber(rowData);
          }
        }
      });
    }
    if (count > 0) {
      avg = _.round(total / count, 2);
      total = _.round(total, 2);
    }
    const selectSummary = {
      count,
      avg,
      total
    };
    this.setState({
      selectSummary
    });
  };

  _createSummaryPannel = () => {
    const { count, avg, total } = this.state.selectSummary;
    const showFlag = avg || count > 0 || total;
    if (!showFlag) {
      return '';
    }
    return (
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <Message info>
          <span>{avg ? 'Average: ' + avg : ''}</span>
          <span style={{ marginLeft: '10px' }}>
            {count > 0 ? 'Count: ' + count : ''}
          </span>
          <span style={{ marginLeft: '10px' }}>
            {total ? 'Sum:' + total : ''}
          </span>
        </Message>
      </div>
    );
  };

  _getHotTableColumnWidth = (baseSize, length) => {
    const rowSizeArr = [];
    for (let i = 0; i < length; i++) {
      rowSizeArr.push(baseSize);
    }
    return rowSizeArr;
  };

  _createHotTableColumns = nestedHeaders => {
    const reportConfig = this._getCurrentReportConfig();
    if (!reportConfig || !reportConfig['spec']) {
      return [];
    }
    let columns = reportConfig['spec']['columns'];
    let defaultSettings = reportConfig['spec']['defaultSettings'];
    defaultSettings = defaultSettings ? defaultSettings : {};
    if (!columns) {
      columns = {};
    }
    const lastHeadArr = nestedHeaders[nestedHeaders.length - 1];
    const tableColumns = lastHeadArr.map(item => {
      const key = item['key'];
      const keyArr = key.split('-');
      let columnSetting = {};
      keyArr.forEach(ele => {
        if (columns[ele]) {
          columnSetting = columns[ele];
        } else {
          if (_.isEmpty(columnSetting))
            columnSetting = this._getDefaultSetting(ele, defaultSettings);
        }
      });
      return columnSetting;
    });
    return tableColumns;
  };

  _getDefaultSetting = (col, defaultSettings) => {
    const { excludeColumns = [], defaultColSetting = {} } = defaultSettings;
    if (excludeColumns && excludeColumns.includes(col)) return {};
    return defaultColSetting ? defaultColSetting : {};
  };

  _getCurrentReportConfig = () => {
    const { currentReport, reportConfig } = this.state;
    for (let i = 0; i < reportConfig.length; i++) {
      const item = reportConfig[i];
      if (item['name'] === currentReport) {
        return item;
      }
    }
    return null;
  };

  _createHotTableNestedHeaders = columns => {
    const nestedHeaders = [];
    columns.forEach((element, index) => {
      if (index === 0) {
        const headerLength = element.length;
        for (let i = 0; i < headerLength; i++) {
          nestedHeaders.push([]);
        }
      }
      for (let j = 0; j < element.length; j++) {
        const key = this.getKey(element, j);
        const arr = nestedHeaders[j];
        const rowIndex = this.getHeadColByName(key, arr);
        if (rowIndex >= 0) {
          const headRecord = arr[rowIndex];
          headRecord['colspan'] = headRecord['colspan'] + 1;
        } else {
          arr.push({ label: element[j], colspan: 1, key: key });
        }
      }
    });
    return nestedHeaders;
  };

  getKey = (element, index) => {
    let key = '';
    for (let i = 0; i <= index; i++) {
      key += element[i] + '-';
    }
    return key;
  };

  getHeadColByName = (key, headArr) => {
    // for (let i = 0; i < headArr.length; i++) {

    // }
    if (!_.isEmpty(headArr)) {
      const lastIndex = headArr.length - 1;
      if (headArr[lastIndex]['key'] === key) {
        return lastIndex;
      }
    }
    return -1;
  };

  closeDialog = () => {
    this.props.closeDialog();
  };

  doQuery = () => {
    const { currentReport, queryParams } = this.state;
    this.setState(
      {
        isLoad: true
      },
      function() {
        this._createReport(currentReport, queryParams);
      }
    );
  };

  _getQueryParams = (currentReport, queryParams) => {
    const searchCondition = {
      ...queryParams
    };
    // const view = searchCondition['view'];
    delete searchCondition['view'];
    const params = { name: currentReport, params: searchCondition };
    return params;
  };

  _createReport = (currentReport, queryParams, download) => {
    const params = this._getQueryParams(currentReport, queryParams);
    if (download) {
      params['download'] = true;
    }
    if (!download) {
      client
        .createReport(params)
        .then(data => {
          const reportData = this._getCurrentReportData(
            data,
            queryParams,
            currentReport
          );
          this.setState({
            data,
            reportData: reportData,
            isLoad: false
          });
        })
        .catch(ex => {
          console.log(ex);
          this.setState({ isLoad: false, reportData: [], data: {} });
        });
    } else {
      client
        .createReport(params)
        .then(data => {})
        .catch(ex => {
          console.log(ex);
        });
    }
  };

  render() {
    const {
      isLoad,
      currentReport,
      reportList,
      isInitialized,
      customReport
    } = this.state;
    if (_.isEmpty(reportList) || _.isEmpty(reportList['names'])) return '';
    const selectList = reportList['names']
      ? reportList['names'].filter(r => customReport.includes(r.desc))
      : [];
    return (
      <Modal
        width={1700}
        maskClosable={false}
        title="Report View"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
      >
        {isInitialized ? (
          <Spin tip="Initializing..." spinning={!isInitialized}>
            <div style={{ height: '780px' }}>
              <div>
                <div style={{ float: 'left', marginBottom: '10px' }}>
                  <span>Report: </span>
                  <Select
                    onChange={this.onReportInputChange}
                    placeholder="Pls Select Report"
                    value={currentReport}
                    style={{ marginLeft: '5px', width: 200 }}
                  >
                    {selectList.map(t => (
                      <Option value={t.name} key={t.name}>
                        {t.desc}
                      </Option>
                    ))}
                  </Select>
                </div>
                {this._createQueryPanel()}
              </div>
              {isLoad ? (
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              ) : (
                <div>
                  {this._createHotTable()}
                  {this._createSummaryPannel()}
                </div>
              )}
            </div>
          </Spin>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

export default ReportShowDialog;
