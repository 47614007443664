import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { finDataGridColumns } from '../GridColumn';
import _ from 'lodash';
import FinReviewDialog from './FinReviewDialog';
import SpreadCompareDialog from './SpreadCompareDialog';

class SwapTradeParameterGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSettings: agGridUtils.createSettings({
        columnDefs: finDataGridColumns.filter(
          r =>
            _.isEmpty(this.props.excludeCols) ||
            !this.props.excludeCols.includes(r.field)
        ),
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
          // suppressMenu: true
        },
        deltaRowDataMode: false,
        floatingFilter: false
      }),
      data: this.props.data,
      selectedData: null,
      submitStatus: 'READY',
      showFinReviewDialog: false,
      showSpreadCompareDialog: false
    };
  }

  _openReviewModal = data => {
    if (data.aggData) {
      return;
    }
    this.setState({
      showFinReviewDialog: true
    });
  };

  _openSpreadCompareModal = data => {
    if (data.aggData) {
      return;
    }
    this.setState({
      showSpreadCompareDialog: true
    });
  };

  _closeSpreadCompareDialog = () => {
    this.setState({
      showSpreadCompareDialog: false
    });
  };

  _closeDialog = () => {
    this.setState({
      showFinReviewDialog: false
    });
  };

  _getContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      {
        name: 'Show Price(B)',
        action: () => this._openReviewModal(params.node)
      },
      {
        name: 'Spread Compare',
        action: () => this._openSpreadCompareModal(params.node)
      }
    ];

    // Add built-in menu items.
    return menuItems;
  };

  _createGrid = () => {
    const { gridSettings, data } = this.state;
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '90%' }}
      >
        <AgGridReact
          // properties
          rowData={data}
          {...gridSettings}
          rowSelection="single"
          getRowNodeId={param => param.id}
          enableRangeSelection={true}
          onSelectionChanged={this._onSelectionChanged}
          // events
          onGridReady={this._gridReady}
          getContextMenuItems={this._getContextMenuItems}
        />
      </div>
    );
  };

  _gridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  _onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    this.setState({
      selectedData: !_.isEmpty(selectedRows) ? selectedRows[0] : null
    });
  };

  _createFinReviewDialog = () => {
    const { showFinReviewDialog, selectedData, data } = this.state;
    if (selectedData == null) return;
    const indexList = [selectedData.benchmarkTicker];
    const indexOptions = _.uniq(data.map(r => r.benchmarkTicker)).filter(
      r => r && r.endsWith('Index')
    );
    return (
      showFinReviewDialog && (
        <FinReviewDialog
          data={{
            indexList,
            indexOptions
          }}
          closeDialog={this._closeDialog}
        />
      )
    );
  };

  _createSpreadCompareDialog = () => {
    const { showSpreadCompareDialog, data } = this.state;
    return (
      showSpreadCompareDialog && (
        <SpreadCompareDialog
          data={data}
          closeDialog={this._closeSpreadCompareDialog}
        />
      )
    );
  };

  render() {
    return (
      <div className="treasuryDashboardWrapper">
        {this._createGrid()}
        {this._createFinReviewDialog()}
        {this._createSpreadCompareDialog()}
      </div>
    );
  }
}

export default SwapTradeParameterGrid;
