import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { Route, Link } from 'react-router-dom';
import configRoutes from 'router/configRoutes';

const SideNavBar = ({ isOpen, toggleSidebar, routes = [] }) => (
  <nav className={isOpen ? 'open' : 'closed'} onClick={toggleSidebar}>
    <Menu fluid vertical icon="labeled" inverted>
      {configRoutes(routes)
        .filter(r => r.visible)
        .map(route => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            children={({ match }) => (
              <Menu.Item
                as={Link}
                to={route.link || route.path}
                active={!!match}
              >
                <Icon name={route.icon} />
                {route.label}
              </Menu.Item>
            )}
          />
        ))}
    </Menu>
  </nav>
);

export default SideNavBar;
