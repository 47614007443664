import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Popover } from 'antd';
import _ from 'lodash';
import PercentBarComponent from '../../../../common/ui/renderers/agGrid/PercentBarComponent';
import HyperlinkCompent from '../../../../common/ui/renderers/agGrid/HyperlinkCompent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import RatingBarComponent from '../../../../common/ui/renderers/agGrid/RatingBarComponent';

export default class TableTooltipComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.valueFormatted || props.value,
      data: props.data,
      tooltipField: props.tooltipField,
      tooltipTitle: props.tooltipTitle,
      tooltipColumnDefs: props.tooltipColumnDefs,
      tooltipWidth: props.tooltipWidth || '300px',
      tooltipHeight: props.tooltipHeight || '150px',
      recordOpenTime: props.recordOpenTime,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        filter: 'agTextColumnFilter',
        sortable: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number',
        width: 120
      },
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        hyperlinkCompent: HyperlinkCompent,
        tagComponent: TagComponent,
        ratingBarComponent: RatingBarComponent
      },
      openTime: 0
    };
  }

  _createGrid = rows => {
    const { tooltipWidth, tooltipHeight } = this.state;

    return (
      <div
        style={{ width: tooltipWidth, height: tooltipHeight }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={this.state.tooltipColumnDefs}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={rows}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={false}
          groupDefaultExpanded={-1}
          rowGroupPanelShow="always"
          // getRowNodeId={data => data.key}
          rowSelection="single"
          suppressCellSelection={true}
          frameworkComponents={this.state.frameworkComponents}
        />
      </div>
    );
  };

  onOpenChange = open => {
    const { recordOpenTime, data, openTime } = this.state;
    const { onPopoverClose } = this.props;
    if (recordOpenTime) {
      const currentMs = Date.now();
      if (open) {
        this.setState({
          openTime: currentMs
        });
      } else {
        const totalOpenTime = currentMs - openTime;
        if (onPopoverClose) onPopoverClose(totalOpenTime, data);
      }
    }
  };

  render() {
    const { tooltipTitle, value, data, tooltipField } = this.state;
    const rows = (data || {})[tooltipField] || [];

    if (_.isEmpty(rows)) return <span>{value}</span>;

    const grid = this._createGrid(rows);
    return (
      <Popover
        title={tooltipTitle}
        content={grid}
        onVisibleChange={this.onOpenChange}
      >
        <span>{value}</span>
      </Popover>
    );
  }

  refresh(params) {
    this.setState({
      value: params.valueFormatted || params.value,
      data: params.data
    });
    return true;
  }
}
