import React, { Component } from 'react';
import { Modal } from 'antd';
import SentimentPannel from './SetimentPannel';

class SentimentDialog extends Component {
  render() {
    return (
      <Modal
        width={'94%'}
        maskClosable={false}
        visible={true}
        closable={false}
        className="sentimentModalInnerClass"
        bodyStyle={{
          height: '88vh',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        style={{ top: 20 }}
        footer={null}
      >
        <SentimentPannel {...this.props} source={'PORTFOLIO'} />
      </Modal>
    );
  }
}

export default SentimentDialog;
