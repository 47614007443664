import { createReducer } from 'common/utils/reducerUtils';
import {
  PORTFOLIO_SNAPSHOT,
  PORTFOLIO_UPDATE,
  PORTFOLIO_BOOK_CHANGE,
  PORTFOLIO_VIEW_MODE_CHANGE,
  PORTFOLIO_DATE_CHANGE,
  RISK_CONTROL_OPEN,
  RISK_CONTROL_RESET,
  PORTFOLIO_VIEW_MODE_LIVE,
  PORTFOLIO_VIEW_MODE_ACCT,
  PORTFOLIO_GET_SUCCESS,
  PORTFOLIO_GET_FAIL,
  PORTFOLIO_GET_REQUEST,
  PORTFOLIO_CASH_POSITION_CHANGE,
  ON_POSITION_SELECTED
} from './portfolioConstants';
import { LOAD_SETTINGS_SUCCESS } from '../settings/settingConstants';
import { LOGOUT_SUCCESS } from '../auth/authConstants';
import EntityMap from 'entities/EntityMap';
import _ from 'lodash';
import moment from 'moment';

const _createInitialViewState = viewMode => {
  return {
    isLoaded: viewMode === PORTFOLIO_VIEW_MODE_LIVE ? false : true,
    positions: EntityMap.fromArray([]),
    riskInfos: EntityMap.fromArray([]),
    // holdings: EntityMap.fromArray([]),
    benchmarks: EntityMap.fromArray([])
  };
};

const initialState = {
  ui: {
    excludeCashPos: false,
    selectedFund: '',
    selectedBook: '',
    selectedDate: moment(),
    viewMode: PORTFOLIO_VIEW_MODE_LIVE,
    selectedPosition: null
  },

  viewState: {
    [PORTFOLIO_VIEW_MODE_LIVE]: _createInitialViewState(
      PORTFOLIO_VIEW_MODE_LIVE
    ),
    [PORTFOLIO_VIEW_MODE_ACCT]: _createInitialViewState(
      PORTFOLIO_VIEW_MODE_ACCT
    )
  },

  riskControlModal: {
    isOpened: false
  }
};

const POS_TABLE = 'POS';
const RISK_TABLE = 'RISK';
// const HOLDING_TABLE = 'HOLDING';
const BENCHMARK_TABLE = 'BENCHMARK';

const extractTblData = (snapshot, tblName, converter = obj => obj) => {
  return snapshot
    .filter(row => row.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));
};

const mergeUpdates = (target, tblName, updates, converter = obj => obj) => {
  const upsertedEntities = updates
    .filter(u => ['Update', 'Add'].includes(u.type) && u.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));

  const deletedEntityKeys = updates
    .filter(u => u.type === 'Delete' && u.tableName === tblName)
    .map(u => u.key);

  return EntityMap.remove(
    EntityMap.merge(target, upsertedEntities),
    deletedEntityKeys
  );
};

export function initPortfolio(state, payload) {
  const positions = extractTblData(payload, POS_TABLE);
  const riskInfos = extractTblData(payload, RISK_TABLE);
  // const holdings = extractTblData(payload, HOLDING_TABLE);
  const benchmarks = extractTblData(payload, BENCHMARK_TABLE);

  return {
    ...state,
    viewState: {
      ...state.viewState,
      [PORTFOLIO_VIEW_MODE_LIVE]: {
        isLoaded: true,
        positions: EntityMap.fromArray(positions),
        riskInfos: EntityMap.fromArray(riskInfos),
        // holdings: EntityMap.fromArray(holdings),
        benchmarks: EntityMap.fromArray(benchmarks)
      }
    }
  };
}

export function updatePortfolio(state, payload) {
  const {
    positions,
    riskInfos,
    // holdings,
    benchmarks,
    isLoaded
  } = state.viewState[PORTFOLIO_VIEW_MODE_LIVE];
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [PORTFOLIO_VIEW_MODE_LIVE]: {
        isLoaded,
        positions: mergeUpdates(positions, POS_TABLE, payload),
        riskInfos: mergeUpdates(riskInfos, RISK_TABLE, payload),
        // holdings: mergeUpdates(holdings, HOLDING_TABLE, payload),
        benchmarks: mergeUpdates(benchmarks, BENCHMARK_TABLE, payload)
      }
    }
  };
}

export function loadSettingsSuccess(state, payload) {
  const firstFund = _.first(payload.funds);
  const firstBook = _.first((firstFund && firstFund.books) || []);
  const selectedFund = !!state.ui.selectedFund
    ? state.ui.selectedFund
    : (firstFund && firstFund.name) || '';
  const selectedBook = !!state.ui.selectedBook
    ? state.ui.selectedBook
    : (firstBook && firstBook.name) || '';

  return {
    ...state,
    ui: {
      ...state.ui,
      selectedFund,
      selectedBook
    }
  };
}

export function logoutSuccess() {
  return initialState;
}

export function changeBook(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedFund: payload.selectedFund,
      selectedBook: payload.selectedBook
    }
  };
}

export function openRiskControlModal(state) {
  return {
    ...state,
    riskControlModal: {
      ...state.riskControlModal,
      isOpened: true
    }
  };
}

export function resetRiskControlModal(state) {
  return {
    ...state,
    riskControlModal: initialState.riskControlModal
  };
}

export function toggleViewMode(state) {
  return {
    ...state,
    ui: {
      ...state.ui,
      viewMode:
        state.ui.viewMode === PORTFOLIO_VIEW_MODE_LIVE
          ? PORTFOLIO_VIEW_MODE_ACCT
          : PORTFOLIO_VIEW_MODE_LIVE
    }
  };
}

export function toggleCashPosition(state) {
  return {
    ...state,
    ui: {
      ...state.ui,
      excludeCashPos: !state.ui.excludeCashPos
    }
  };
}

export function changeViewDate(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedDate: payload
    }
  };
}

export function requestGetPortfolio(state) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [PORTFOLIO_VIEW_MODE_ACCT]: {
        isLoaded: false,
        positions: EntityMap.fromArray([]),
        riskInfos: EntityMap.fromArray([])
      }
    }
  };
}

export function getPortfolioSuccess(state, payload) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [PORTFOLIO_VIEW_MODE_ACCT]: {
        isLoaded: true,
        positions: EntityMap.fromArray(payload.positions),
        riskInfos: EntityMap.fromArray(payload.riskInfos)
      }
    }
  };
}

export function getPortfolioFail(state, payload) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [PORTFOLIO_VIEW_MODE_ACCT]: {
        isLoaded: true,
        positions: EntityMap.fromArray([]),
        riskInfos: EntityMap.fromArray([])
      }
    }
  };
}

export function onPositionSelected(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedPosition: payload
    }
  };
}

export default createReducer(initialState, {
  [PORTFOLIO_SNAPSHOT]: initPortfolio,
  [PORTFOLIO_UPDATE]: updatePortfolio,
  [LOAD_SETTINGS_SUCCESS]: loadSettingsSuccess,
  [LOGOUT_SUCCESS]: logoutSuccess,
  [PORTFOLIO_BOOK_CHANGE]: changeBook,
  [RISK_CONTROL_OPEN]: openRiskControlModal,
  [RISK_CONTROL_RESET]: resetRiskControlModal,
  [PORTFOLIO_VIEW_MODE_CHANGE]: toggleViewMode,
  [PORTFOLIO_CASH_POSITION_CHANGE]: toggleCashPosition,
  [PORTFOLIO_DATE_CHANGE]: changeViewDate,
  [PORTFOLIO_GET_REQUEST]: requestGetPortfolio,
  [PORTFOLIO_GET_SUCCESS]: getPortfolioSuccess,
  [PORTFOLIO_GET_FAIL]: getPortfolioFail,
  [ON_POSITION_SELECTED]: onPositionSelected
});
