import React from 'react';
import { Button } from 'semantic-ui-react';
import {
  Cascader,
  Switch,
  DatePicker,
  Tooltip,
  Button as AntdButton
} from 'antd';
import {
  PORTFOLIO_VIEW_MODE_ACCT,
  DIALOG_VIEW_POSITIONS
} from '../portfolioConstants';
import { disabledDate } from 'common/utils/DomainUtils';
import { SearchOutlined } from '@ant-design/icons';

const FilterBar = ({
  ui,
  settings,
  user,
  isLoaded,
  changeBook,
  toggleViewMode,
  toggleCashPosition,
  changeViewDate,
  getPortfolio,
  openDialog,
  isMobile
}) => {
  const { funds } = settings;
  const canToggleViewMode = user && user.firm === 'PINPOINT';

  const fundBookOptions = funds.map(fundItem => {
    let fundBook = {};
    fundBook.value = fundItem.name;
    fundBook.label = fundItem.name;
    fundBook.children = fundItem.books.map(item => ({
      value: item.name,
      label: item.name
    }));
    return fundBook;
  });

  const hideFundBook =
    !funds ||
    !funds.length ||
    (funds.length === 1 && funds[0].books.length <= 1);

  // if (hideFundBook && !canToggleViewMode) return null;
  return (
    <div className="portfolioFilter">
      {!hideFundBook && (
        <Cascader
          value={[ui.selectedFund, ui.selectedBook]}
          options={fundBookOptions}
          onChange={changeBook}
          placeholder="Please select"
          allowClear={false}
        />
      )}

      {!hideFundBook && !isMobile && (
        <Tooltip title="search">
          <AntdButton
            style={{ marginLeft: '3px' }}
            type="primary"
            shape="circle"
            icon={<SearchOutlined />}
            onClick={() => openDialog(DIALOG_VIEW_POSITIONS)}
          />
        </Tooltip>
      )}

      <div className="liveDiv">
        {/* <Switch
          checked={ui.excludeCashPos}
          checkedChildren="EXCL-CASH"
          unCheckedChildren="INCL-CASH"
          defaultChecked
          onChange={toggleCashPosition}
        /> */}

        {canToggleViewMode && (
          <div>
            <Switch
              checked={ui.viewMode === 'LIVE'}
              checkedChildren="LIVE"
              unCheckedChildren="ACCT"
              defaultChecked
              onChange={toggleViewMode}
            />

            {ui.viewMode === PORTFOLIO_VIEW_MODE_ACCT && (
              <DatePicker
                value={ui.selectedDate}
                format={'YYYY-MM-DD'}
                allowClear={false}
                onChange={changeViewDate}
                disabledDate={date => disabledDate(date, user)}
              />
            )}

            {ui.viewMode === PORTFOLIO_VIEW_MODE_ACCT && (
              <Button
                icon="search"
                color="green"
                disabled={!isLoaded}
                onClick={getPortfolio}
                style={{ marginBottom: '3px', marginTop: '3px' }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
