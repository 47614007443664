import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend
} from 'recharts';
import './ShortInterestTab.css';

class ShortInterestTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: ''
    };
  }

  handleChange = (e, { value }) => {
    this.props.loadShortInterest({
      ticker: value
    });
    this.props.loadShortInterestByPerenct({
      bbgTicker: value
    });
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState({ searchQuery });
  };

  render() {
    const { allTickers, shortInterest, shortInterestPercent } = this.props;
    const { searchQuery } = this.state;

    const allTickerOptions = allTickers.map(c => ({
      key: c.bbg_ticker,
      text: `${c.bbg_ticker} / ${c.sec_name}`,
      value: c.bbg_ticker
    }));

    const defaultTicker = allTickers.length ? allTickers[0].bbg_ticker : '';
    if (defaultTicker && !shortInterest.length) {
      this.props.loadShortInterest({
        ticker: defaultTicker
      });
      this.props.loadShortInterestByPerenct({
        bbgTicker: defaultTicker
      });
    }
    return (
      <div>
        <div className="shortInterestfilter">
          <Dropdown
            onChange={this.handleChange}
            onSearchChange={this.handleSearchChange}
            options={allTickerOptions}
            search
            searchQuery={searchQuery}
            defaultValue={defaultTicker}
            selection
          />
        </div>
        <ResponsiveContainer width="98%" height={300}>
          <AreaChart
            data={shortInterest}
            width={750}
            height={400}
            margin={{ top: 20, right: 30, left: 25, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#FFFFFF" />
            <XAxis dataKey="date" stroke="#FFFFFF" tick={{ fontSize: 10 }} />
            <YAxis stroke="#FFFFFF" tick={{ fontSize: 10 }} />
            <Tooltip />
            <Legend wrapperStyle={{ color: '#8884d8' }} />
            <Area
              type="monotone"
              dataKey="Share"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart>
        </ResponsiveContainer>

        <ResponsiveContainer width="98%" height={300}>
          <AreaChart
            data={shortInterestPercent}
            width={750}
            height={400}
            margin={{ top: 20, right: 30, left: 25, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#FFFFFF" />
            <XAxis dataKey="date" stroke="#FFFFFF" tick={{ fontSize: 10 }} />
            <YAxis stroke="#FFFFFF" tick={{ fontSize: 10 }} />
            <Tooltip />
            <Legend wrapperStyle={{ color: '#ED7D31' }} />
            <Area
              type="monotone"
              dataKey="ShortPercent"
              fill="#ED7D31"
              stroke="#ED7D31"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default ShortInterestTab;
