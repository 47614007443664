import moment from 'moment';
import _ from 'lodash';

export function getPreviousWorkingDay(dateString = null) {
  const date = dateString ? moment(dateString) : moment();
  let dayDecrement = 1;

  switch (date.day()) {
    case 0:
      dayDecrement = 2;
      break;
    case 1:
      dayDecrement = 3;
      break;
    default:
      dayDecrement = 1;
  }

  return date.subtract(dayDecrement, 'days');
}

export function getNextWorkingDay(dateString = null) {
  const date = dateString ? moment(dateString) : moment();
  let dayIncrement = 1;

  switch (date.day()) {
    case 5:
      dayIncrement = 3;
      break;
    case 6:
      dayIncrement = 2;
      break;
    default:
      dayIncrement = 1;
  }

  return date.add(dayIncrement, 'days');
}

export function getCurrentWorkingDay(dateString = null) {
  const date = dateString ? moment(dateString) : moment();
  let dayDecrement = 0;

  switch (date.day()) {
    case 0:
      dayDecrement = 2;
      break;
    case 6:
      dayDecrement = 1;
      break;
    default:
      dayDecrement = 0;
  }

  return date.subtract(dayDecrement, 'days');
}

export function isBetweenDays(
  leftDateString,
  rightDateString = null,
  days = 1
) {
  const leftDate = moment(leftDateString);
  const rightDate = _.isNil(rightDateString)
    ? moment().utcOffset(8)
    : moment(rightDateString);
  return Math.abs(leftDate.diff(rightDate, 'days')) <= days;
}

export function getCurrentQuarter(date) {
  const currentDate = date ? date : moment();
  const year = currentDate.format('YYYY');
  const quarter = currentDate.quarter();
  const quarterKey = `${year}Q${quarter}`;
  const quarterName = `${year} Q${quarter}`;
  return {
    year,
    quarter: currentDate.quarter(),
    date,
    quarterKey,
    quarterName
  };
}

export function getYearByOffset(offset) {
  const date = moment().add(offset, 'years');
  return date.year();
}

export function getLastQuarter() {
  const date = moment().add(-1, 'Q');
  return getCurrentQuarter(date);
}

export function getNextQuarter() {
  const date = moment().add(1, 'Q');
  return getCurrentQuarter(date);
}

function getQuarter(date) {
  const month = date.month() + 1;
  return Math.ceil(month / 3);
}

export function getQuarterByOffset(offset) {
  const date = moment().add(offset, 'Q');
  let targetQuarter = getQuarter(date);
  targetQuarter = ((targetQuarter - 1 + 4) % 4) + 1;
  return `${date.year()}Q${targetQuarter}`;
}
