import React, { PureComponent } from 'react';
import { Button, Select, Form, Spin, DatePicker, Input, Modal } from 'antd';
import { DIALOG_VIEW_IPO_LIST } from '../../omsConstants';
import { ipoListGridColumns } from './GridColumnMap';
import _ from 'lodash';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import { Message } from 'semantic-ui-react';
import client from '../../api/client';
import moment from 'moment';
import hyperid from 'hyperid';
import orderClient from 'features/order/api/client';

const uniqidInstance = hyperid();

const { RangePicker } = DatePicker;
const Option = Select.Option;
const dateFormat = 'YYYY-MM-DD';
// Must inherit PureComponent, otherwise holdings grid will be refreshed even when no props are changed.
class ViewIPOListDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    const dateFrom = moment()
      .add('month', -1)
      .format(dateFormat);
    const dateTo = moment()
      .add('month', 1)
      .format(dateFormat);
    this.state = {
      viewMode: 'Normal',
      submitStatus: 'READY',
      dataChangeFlag: false,
      columns: ipoListGridColumns,
      defaultAnnouncedFrom: dateFrom,
      defaultAnnouncedTo: dateTo,
      isInitialized: false,
      errors: [],
      ipoListInit: [],
      ipoList: [],
      removeIPOList: [],
      securityMap: {},
      addRowCount: 0,
      updateRowCount: 0,
      removeRowCount: 0,
      queryParams: {
        pageIndex: 1,
        pageSize: 1000,
        announcedDateFrom: dateFrom,
        announcedDateTo: dateTo,
        status: 'PENDING'
      }
    };
  }

  componentDidMount() {
    this._init();
  }

  _init = () => {
    this.loadIPOList();
  };

  loadIPOList = () => {
    const { securityMap } = this.state;
    client
      .getIPOList(this.state.queryParams)
      .then(data => {
        const dataListInit = data['data'];
        const dataList = [];
        if (data['data']) {
          data['data'].forEach(item => {
            item['key'] = uniqidInstance();
            securityMap[item['issuerTicker']] = {
              name: item['issuerName'],
              rawTicker: item['issuerTicker']
            };
            dataList.push(item);
          });
        }
        this.setState({
          isInitialized: true,
          errors: [],
          ipoListInit: dataListInit,
          ipoList: dataList,
          addRowCount: 0,
          updateRowCount: 0,
          removeRowCount: 0,
          dataChangeFlag: false,
          submitStatus: 'READY'
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  submitData = () => {
    //const { closeDialog } = this.props;
    if (this.checkData()) {
      this.setState({ submitStatus: 'SUBMITTING' });
      let submitData = this.getData();
      if (!submitData || submitData.length === 0) {
        return;
      }
      submitData = submitData.map(item => {
        let offerSize = item['offerSize'];
        let marketCapAtOffer = item['marketCapAtOffer'];
        let reg = new RegExp(',', 'g');
        if (offerSize) {
          offerSize = String(offerSize).replace(reg, '');
        }
        if (marketCapAtOffer) {
          marketCapAtOffer = String(marketCapAtOffer).replace(reg, '');
        }
        return {
          ...item,
          offerSize,
          marketCapAtOffer
        };
      });
      client
        .updateIPOList({ data: submitData })
        .then(data => {
          this.setState({
            submitStatus: 'READY'
          });
          if (data && data['code'] === '0') {
            //this.loadIPOList();
            this.closeDialog();
          } else {
            if (data) {
              const errors = [];
              errors.push(data['message']);
              this.setState({
                errors: errors
              });
            }
          }
        })
        .catch(ex => {
          console.log(ex);
          this.setState({ submitStatus: 'ERROR' });
        });
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_VIEW_IPO_LIST);
  };

  onGridReady = params => {
    this.gridApi = params.api;

    const COLUMNS_KEY = 'ipo-list-grid-col-state';
    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  getRowNodeId = data => {
    return data.key;
  };

  _createIPOGridSettings = () => {
    const colHeaders = [];
    if (ipoListGridColumns) {
      ipoListGridColumns.forEach(function(item) {
        colHeaders.push(item['headerName']);
      });
    }
    return hotTableUtils.createSettings({
      colHeaders: colHeaders,
      rowHeaders: true,
      hiddenColumns: {
        columns: [0],
        indicators: true
      },
      contextMenu: {
        items: {
          approve: {
            name: 'APPROVE',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._updateSelectRow('APPROVE'), 100);
            }
          },
          pending: {
            name: 'PENDING',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._updateSelectRow('PENDING'), 100);
            }
          },
          reject: {
            name: 'REJECT',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._updateSelectRow('REJECT'), 100);
            }
          },
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneIPO(), 100);
            }
          },
          add: {
            name: 'Add row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._addRow(), 100);
            }
          },
          remove_row: {}
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _cloneIPO = () => {
    const { ipoList, selectedIPO } = this.state;
    const row = ipoList.findIndex(r => r.key === selectedIPO.key);
    const key = `${selectedIPO.key}_${uniqidInstance()}`;
    const cloneRow = {
      ...selectedIPO,
      key
    };
    cloneRow['id'] = null;
    cloneRow['op'] = 'I';
    const updatedIpoList = [
      ...ipoList.slice(0, row + 1),
      cloneRow,
      ...ipoList.slice(row + 1)
    ];
    this.setState({ ipoList: updatedIpoList, dataChangeFlag: true });
  };

  _addRow = () => {
    const { ipoList } = this.state;
    const key = `N_${uniqidInstance()}`;
    const newRow = {
      key
    };
    newRow['id'] = null;
    newRow['op'] = 'I';
    newRow['status'] = 'PENDING';
    const updatedIpoList = [newRow, ...ipoList];
    this.setState({ ipoList: updatedIpoList, dataChangeFlag: true });
  };

  _updateSelectRow = status => {
    this.batchOp = true;
    const hotTbl = this.hotTblRef.current.hotInstance || {};
    var selected = hotTbl.getSelected();
    for (var index = 0; index < selected.length; index += 1) {
      var item = selected[index];
      var startRow = Math.min(item[0], item[2]);
      var endRow = Math.max(item[0], item[2]);
      var startCol = 2;
      var endCol = 2;
      for (var rowIndex = startRow; rowIndex <= endRow; rowIndex += 1) {
        for (
          var columnIndex = startCol;
          columnIndex <= endCol;
          columnIndex += 1
        ) {
          hotTbl.setDataAtCell(rowIndex, columnIndex, status);
        }
      }
    }
    hotTbl.render();
    this.checkData();
    //this.updateRowUpdateCount();
    this.batchOp = false;
  };

  _onRowSelectionChanged = row => {
    const { ipoList, selectedIPO } = this.state;
    const ipoRow = ipoList[row];
    if (ipoRow === selectedIPO) return;
    this.setState({
      selectedIPO: ipoRow
    });
  };

  _onRowRemove = row => {
    this.updateRowUpdateCount();
  };

  _createIPOListGrid = () => {
    const { ipoList, isInitialized } = this.state;
    const settings = this._createIPOGridSettings();
    if (!isInitialized) {
      return '';
    }
    if (!ipoList || ipoList.length === 0) {
      return 'No Data';
    }
    this.hotTable = (
      <HotTable
        ref={this.hotTblRef}
        data={ipoList}
        columns={this.state.columns}
        manualColumnResize={true}
        autoColumnSize={true}
        afterChange={this.afterChangeHandle}
        afterSelectionEnd={this._onRowSelectionChanged}
        afterRemoveRow={this._onRowRemove}
        {...settings}
      />
    );
    return this.hotTable;
  };

  _createErrorsPanel = () => {
    const { errors } = this.state;
    const errorMsgs = Object.values(errors);
    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  _createSubmitBtn = () => {
    const { submitStatus, dataChangeFlag } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={this.submitData}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button
          key="submit"
          type="primary"
          onClick={this.submitData}
          disabled={!dataChangeFlag}
        >
          Submit
        </Button>
      )
    }[submitStatus];

    return submitBtn;
  };

  onDateChange = afterValue => {
    const { queryParams } = this.state;
    queryParams['announcedDateFrom'] = moment(afterValue[0]).format(dateFormat);
    queryParams['announcedDateTo'] = moment(afterValue[1]).format(dateFormat);
    this.setState(
      {
        queryParams: queryParams
      },
      this.loadIPOList()
    );
  };

  onStatusChange = value => {
    const { queryParams } = this.state;
    queryParams['status'] = value;
    this.setState(
      {
        queryParams: queryParams
      },
      this.loadIPOList()
    );
  };

  checkData = () => {
    const { queryParams } = this.state;
    const status = queryParams['status'];
    if (status === 'PENDING') {
      //  ipoList.forEach(item => {
      //     item['op'] = 'U';
      //  })
    }
    // const timestap = new Date().getTime();
    const data = this.getData();
    const errors = this.checkRequiredField(data);
    this.setState({
      errors: errors
    });
    return errors && errors.length === 0;
  };

  getData = data => {
    const { ipoList, ipoListInit } = this.state;
    if (!data) {
      data = ipoList;
    }
    const submitData = [];
    const idList = [];
    data.forEach((item, index) => {
      if (item['id']) {
        idList.push(item['id']);
      }
      if (item['op']) {
        item['rowIndex'] = index;
        submitData.push(item);
      }
    });
    if (ipoListInit && ipoListInit.length > 0) {
      ipoListInit.forEach(item => {
        if (idList.indexOf(item['id']) < 0) {
          item['op'] = 'D';
          submitData.push(item);
        }
      });
    }

    return submitData;
  };

  checkRequiredField = data => {
    const { ipoList, securityMap } = this.state;
    const tickerList = [];
    const errors = [];
    if (!data || data.length === 0) {
      errors.push('No data has been updated');
    }
    let tickerErrorMsg = '';
    data.forEach((element, index) => {
      if (element['issuerTicker'] && !securityMap[element['issuerTicker']]) {
        tickerErrorMsg += element['issuerTicker'] + ',';
      }
    });
    if (tickerErrorMsg !== '') {
      tickerErrorMsg = tickerErrorMsg.substring(0, tickerErrorMsg.length - 1);
      tickerErrorMsg += ' not correct ticker';
      errors.push(tickerErrorMsg);
    }
    data.forEach((element, index) => {
      let errorMsg = '';
      if (!element['announcedDate']) {
        errorMsg += 'Announced Date,';
      }
      if (!element['status']) {
        errorMsg += 'Status,';
      }
      if (!element['issuerTicker']) {
        errorMsg += 'Issuer Ticker,';
      }
      if (
        (element['op'] === 'U' || element['op'] === 'I') &&
        element['status'] === 'APPROVE'
      ) {
        if (!element['issuerName']) {
          errorMsg += 'Issuer Name,';
        }
        if (!element['industryGroup']) {
          errorMsg += 'Industry Group,';
        }
        if (!element['industrySector']) {
          errorMsg += 'Industry Sector,';
        }
        if (!element['bookbuildingEndDate']) {
          errorMsg += 'Bookbuilding End Date,';
        }
        // if(!element['expectedPricingDate']){
        //   errorMsg += "Expected Pricing Date,"
        // }
        if (!element['listingDate']) {
          errorMsg += 'Listing Date,';
        }
      }
      if (errorMsg !== '') {
        errorMsg =
          'Row ' +
          (element['rowIndex'] + 1) +
          ': ' +
          errorMsg +
          ' Cannot be null.';
        errors.push(errorMsg);
      }
    });
    ipoList.forEach((item, index) => {
      const issuerTicker = item['issuerTicker'];
      if (issuerTicker) {
        if (tickerList.indexOf(issuerTicker) >= 0) {
          errors.push('IssuerTicker=' + issuerTicker + ' Already exists');
        } else {
          tickerList.push(issuerTicker);
        }
      }
    });
    return errors;
  };

  onQueryParamsChange = (event, fieldName) => {
    const filedValue = event.target.value;
    const { queryParams } = this.state;
    queryParams[fieldName] = filedValue;
    this.setState({
      queryParams: queryParams
    });
  };

  afterChangeHandle = (value, action) => {
    const tickerList = [];
    if (action === 'edit') {
      const current = value[0];
      if (value) {
        const index = current[0];
        if (current[1] === 'issuerTicker' && current[3]) {
          tickerList.push({ ticker: current[3] });
        }
        const data = this.state.ipoList[index];
        if (data.op !== 'I') {
          data.op = 'U';
        }
        if (!this.batchOp) {
          this.checkData();
        }
      }
    }
    if (action === 'Autofill.fill') {
      value.forEach(item => {
        const index = item[0];
        if (item[1] === 'issuerTicker' && item[3]) {
          tickerList.push({ ticker: item[3] });
        }
        const data = this.state.ipoList[index];
        if (data.op !== 'I') {
          data.op = 'U';
        }
      });
      if (!this.batchOp) {
        this.checkData();
      }
    }
    if (tickerList.length > 0) {
      this.checkTickerExist(tickerList);
    }
    if (action === 'loadData') {
      this.updateRowUpdateCount();
    }
  };

  checkTickerExist = tickerData => {
    const { ipoList, securityMap } = this.state;
    const curentSecurityMap = {};
    orderClient
      .getSecurities(tickerData, { type: 'IPO' })
      .then(data => {
        if (data && data.length > 0) {
          data.forEach(item => {
            if (item) {
              curentSecurityMap[item['rawTicker']] = {
                name: item['name'],
                ticker: item['ticker']
              };
              securityMap[item['ticker']] = {
                name: item['name'],
                rawTicker: item['rawTicker']
              };
            }
          });
        }
        const updateData = ipoList.map(item => {
          const tickerItem = curentSecurityMap[item['issuerTicker']];
          if (tickerItem) {
            const issuerTicker = tickerItem['ticker'];
            const issuerName = tickerItem['name'];
            return {
              ...item,
              issuerTicker,
              issuerName
            };
          }
          return item;
        });
        const ipoData = this.getData(updateData);
        const errors = this.checkRequiredField(ipoData);
        //const errorTickers = tickerData.map(item=>item['ticker']).filter(r => !curentSecurityMap[r]);
        //const errors = errorTickers.join(",")+ ' not exists bbg';
        this.setState({
          ipoList: updateData,
          errors: errors
        });
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  updateRowUpdateCount = () => {
    let addRowCount = 0,
      updateRowCount = 0,
      removeRowCount = 0,
      dataChangeFlag = true;
    const data = this.getData();
    data.forEach(item => {
      const op = item['op'];
      if ('I' === op) {
        addRowCount++;
      } else if ('U' === op) {
        updateRowCount++;
      } else if ('D' === op) {
        removeRowCount++;
      }
    });
    if (addRowCount + updateRowCount + removeRowCount === 0) {
      dataChangeFlag = false;
    }
    this.setState({
      addRowCount: addRowCount,
      updateRowCount: updateRowCount,
      removeRowCount: removeRowCount,
      dataChangeFlag: dataChangeFlag
    });
  };

  render() {
    const {
      isInitialized,
      defaultAnnouncedFrom,
      defaultAnnouncedTo
    } = this.state;

    return (
      <div>
        <style>
          {`
            .requiredIPOHeader{
              color: #9b2be0
            } 
          `}
        </style>

        <Modal
          width={1650}
          maskClosable={false}
          title="IPO List"
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            <Button key="close" type="primary" onClick={this.closeDialog}>
              Close
            </Button>,
            this._createSubmitBtn()
          ]}
        >
          <div className="omsDialogOperationBar">
            <div className="rightOperations">
              <Form layout="inline">
                <Form.Item label="Issuer Ticker">
                  <Input
                    size="middle"
                    onChange={e => {
                      this.onQueryParamsChange(e, 'issuerTicker');
                    }}
                    onPressEnter={this.loadIPOList}
                  />
                </Form.Item>
                <Form.Item label="Announced Date">
                  <RangePicker
                    defaultValue={[
                      moment(defaultAnnouncedFrom, dateFormat),
                      moment(defaultAnnouncedTo, dateFormat)
                    ]}
                    format={dateFormat}
                    onChange={afterDate => {
                      this.onDateChange(afterDate);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Status">
                  <Select
                    style={{ width: 120 }}
                    defaultValue="PENDING"
                    onChange={value => {
                      this.onStatusChange(value);
                    }}
                  >
                    <Option value="ALL">ALL</Option>
                    <Option value="PENDING">PENDING</Option>
                    <Option value="APPROVE">APPROVE</Option>
                    <Option value="REJECT">REJECT</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button key="close" type="primary" onClick={this._addRow}>
                    Add
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <Spin tip="Initializing..." spinning={!isInitialized}>
            <div style={{ width: '100%', height: '540px', marginTop: '5px' }}>
              {this._createIPOListGrid()}
            </div>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <b>
                {' '}
                Change row count, ADD: {this.state.addRowCount}, UPDATE:{' '}
                {this.state.updateRowCount}, REMOVE: {this.state.removeRowCount}
                .
              </b>
            </div>
            {this._createErrorsPanel()}
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default ViewIPOListDialog;
