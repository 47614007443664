import React, { Component } from 'react';
import PositionGrid from './PositionGrid';
import FilterBar from './FilterBar';
import RiskBar from './RiskBar';
import { Loader } from 'semantic-ui-react';
import RiskControlDialog from './RiskControlDialog';
import SingleTradeDialog from '../../order/components/dialogs/SingleTradeDialog';
import QuantTradesDialog from '../../order/components/dialogs/QuantTradesDialog';
import './PortfolioDashboard.css';
import { canPlaceOrder } from '../../../common/utils/DomainUtils';
import {
  DIALOG_EXECUTE_SINGLE_TRADE,
  DIALOG_EXECUTE_QUANT_TRADES
} from '../../order/orderConstants';
import {
  DIALOG_VIEW_POSITIONS,
  DIALOG_CB_DELTA_MODAL,
  DIALOG_TAG_MODAL,
  DIALOG_PRICE_TRACKING_MODAL,
  DIALOG_PRICE_TRACKING_TRADES_MODAL,
  DIALOG_REVIEW,
  DIALOG_SENTIMENT,
  DIALOG_COMPARE,
  DIALOG_FX_TRADES_REPORT
} from '../portfolioConstants';
import PosCalculator from '../entities/calculators/PosCalculator';
import ViewPositionsDialog from './ViewPositionsDialog';
import { DraggableModalProvider } from 'ant-design-draggable-modal';
import CBDeltaDialog from './CBDeltaDialog';
import TagDialog from './TagDialog';
import PriceTrackingDialog from './PriceTrackingDialog';
import GenPriceTrackingTradesDialog from './GenPriceTrackingTradesDialog';
import OrderReviewDialog from './OrderReviewDialog';
import SentimentDialog from './SentimentDialog';
import CompareDialog from './CompareDialog';
import FxTradeReportDialog from './FxTradeReportDialog';
import { isAdminRole } from 'common/utils/DomainUtils';

class PortfolioDashboard extends Component {
  createNewTrade = (pos, side) => {
    const defaultFields = {
      fundCode: pos.fundCode,
      bookCode: pos.bookCode,
      strategy: pos.strategy,
      ticker: pos.ticker,
      side,
      quantity: ['BUY', 'SHRT'].includes(side) ? 0 : Math.abs(pos.quantityEnd)
    };

    this.props.openDialog(DIALOG_EXECUTE_SINGLE_TRADE, {
      createNew: true,
      defaultFields
    });
  };

  canDoTrade = () => {
    const { user } = this.props;
    return canPlaceOrder(user ? user.role : '');
  };

  _createContextMenuItems = (pos, enableTxnFundBooks) => {
    if (
      !this.canDoTrade() ||
      !enableTxnFundBooks.includes(`${pos.fundCode}-${pos.bookCode}`)
    ) {
      return [];
    }

    const { tradeLabel } = this.props.settings;
    if (pos.quantityDirection === 'LONG') {
      return [
        {
          name: `Create BUY ${tradeLabel}`,
          action: () => this.createNewTrade(pos, 'BUY')
        },
        {
          name: `Create SELL ${tradeLabel}`,
          action: () => this.createNewTrade(pos, 'SELL')
        },
        'separator'
      ];
    } else if (pos.quantityDirection === 'SHORT') {
      return [
        {
          name: `Create SHRT ${tradeLabel}`,
          action: () => this.createNewTrade(pos, 'SHRT')
        },
        {
          name: `Create COVR ${tradeLabel}`,
          action: () => this.createNewTrade(pos, 'COVR')
        },
        'separator'
      ];
    } else {
      return [];
    }
  };

  getContextMenuItems = pos => {
    const { ui, user, enableTxnFundBooks } = this.props;
    if (!pos) return;
    const menuItems = this._createContextMenuItems(pos, enableTxnFundBooks);

    // // Add bulit-in menu items if has permission.
    if (isAdminRole(user ? user.role : '')) {
      menuItems.push('export');
    }

    // menuItems.push('export');

    //inst class = CONVBND
    if (ui.viewMode === 'LIVE') {
      menuItems.push({
        name: `CB Delta`,
        action: () => this.editCBDelta()
      });
      if (
        ui.selectedFund !== ui.selectedBook ||
        user.englishName === 'Wang Qiang'
      ) {
        menuItems.push({
          name: `Tag`,
          action: () => this.editTag()
        });
        menuItems.push({
          name: `Track Price`,
          action: () => this.editPriceTracking()
        });
      }
    }

    menuItems.push(
      ...[
        {
          name: `Review`,
          action: () => this.review()
        },
        {
          name: `Pos Compare`,
          action: () => this.compare()
        },
        {
          name: `CCY Settle Pos`,
          action: () => this.fxTradeReport()
        }
      ]
    );

    this._addSentimentMenu(pos, menuItems);

    return menuItems;
  };

  _addSentimentMenu = (pos, menuItems) => {
    if (pos && pos.ticker && pos.ticker.endsWith('CH Equity')) {
      menuItems.push({
        name: `Sentiment`,
        action: () => this.sentiment()
      });
    }
  };

  sentiment = () => {
    this.props.openDialog(DIALOG_SENTIMENT, {});
  };

  review = () => {
    this.props.openDialog(DIALOG_REVIEW, {});
  };

  compare = () => {
    this.props.openDialog(DIALOG_COMPARE, {});
  };

  fxTradeReport = () => {
    this.props.openDialog(DIALOG_FX_TRADES_REPORT, {});
  };

  editCBDelta = () => {
    this.props.openDialog(DIALOG_CB_DELTA_MODAL, {});
  };

  editTag = () => {
    this.props.openDialog(DIALOG_TAG_MODAL, {});
  };

  editPriceTracking = () => {
    this.props.openDialog(DIALOG_PRICE_TRACKING_MODAL, {});
  };

  changeBook = value => {
    this.props.changeBook({
      selectedFund: value[0],
      selectedBook: value[1]
    });
  };

  toggleViewMode = () => {
    this.props.toggleViewMode();
  };

  toggleCashPosition = () => {
    this.props.toggleCashPosition();
  };

  changeViewDate = value => {
    this.props.changeViewDate(value);
  };

  getPortfolio = () => {
    const { selectedFund, selectedBook, selectedDate } = this.props.ui;
    this.props.getPortfolio({
      date: selectedDate.format('YYYY-MM-DD'),
      fundCode: selectedFund,
      bookCode: selectedBook
    });
  };

  _createDialog = (dialogCode, info, filteredPositions) => {
    const {
      closeDialog,
      submitDialogSuccess,
      settings,
      liveRiskInfos,
      liveRiskInfoMap,
      positions,
      ui,
      riskInfoMap
    } = this.props;

    switch (dialogCode) {
      case DIALOG_EXECUTE_SINGLE_TRADE:
        return (
          <SingleTradeDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_VIEW_POSITIONS:
        return (
          <ViewPositionsDialog
            key={dialogCode}
            closeDialog={closeDialog}
            positions={positions}
            settings={settings}
            liveRiskInfoMap={liveRiskInfoMap}
          />
        );
      case DIALOG_CB_DELTA_MODAL:
        return (
          <CBDeltaDialog
            key={dialogCode}
            closeDialog={closeDialog}
            positions={filteredPositions}
            settings={settings}
            liveRiskInfoMap={liveRiskInfoMap}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_TAG_MODAL:
        return (
          <TagDialog
            {...this.props}
            key={dialogCode}
            closeDialog={closeDialog}
            positions={positions}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_PRICE_TRACKING_MODAL:
        return (
          <PriceTrackingDialog
            {...this.props}
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            positions={positions}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_PRICE_TRACKING_TRADES_MODAL:
        return (
          <GenPriceTrackingTradesDialog
            {...this.props}
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_EXECUTE_QUANT_TRADES:
        return (
          <QuantTradesDialog
            key={dialogCode}
            info={info}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            liveRiskInfos={liveRiskInfos}
          />
        );
      case DIALOG_REVIEW:
        return (
          <OrderReviewDialog
            key={dialogCode}
            closeDialog={closeDialog}
            submitDialogSuccess={submitDialogSuccess}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_SENTIMENT:
        return (
          <SentimentDialog
            key={dialogCode}
            closeDialog={closeDialog}
            settings={settings}
            ui={ui}
          />
        );
      case DIALOG_COMPARE:
        return (
          <CompareDialog
            key={dialogCode}
            closeDialog={closeDialog}
            riskInfoMap={riskInfoMap}
            settings={settings}
            positions={positions}
          />
        );
      case DIALOG_FX_TRADES_REPORT:
        return (
          <FxTradeReportDialog
            key={dialogCode}
            closeDialog={closeDialog}
            settings={settings}
            ui={ui}
          />
        );
      default:
        return null;
    }
  };

  _createDialogs = filteredPositions => {
    const { dialogs } = this.props;
    return Object.keys(dialogs).map(dialogCode => {
      const dialog = dialogs[dialogCode];

      return dialog.isOpened
        ? this._createDialog(dialogCode, dialog.info, filteredPositions)
        : null;
    });
  };

  componentDidMount() {
    const { user } = this.props;
    if (!user) return;

    this.props.sendSubscription({
      view: 'PORTFOLIO',
      userName: user.englishName
    });
  }

  componentDidUpdate(prevProps) {
    const { user: prevUser } = prevProps;
    const { user } = this.props;

    if (!prevUser && user) {
      this.props.sendSubscription({
        view: 'PORTFOLIO',
        userName: user.englishName
      });
    }
  }

  render() {
    const {
      isLoaded,
      positions,
      riskInfoMap,
      ui,
      settings,
      onPositionSelected
    } = this.props;

    const currentFundBook = `${ui.selectedFund}-${ui.selectedBook}`;
    const tagFundBooks = settings.tagFundBooks ? settings.tagFundBooks : [];
    const isTagFundBook =
      tagFundBooks.filter(r => r.FundBookCode === currentFundBook).length > 0;
    const riskInfo = riskInfoMap.byKey[`${ui.selectedFund}-${ui.selectedBook}`];
    const filteredPositions = PosCalculator.calc(positions, riskInfo, p =>
      !ui.excludeCashPos
        ? true
        : !['TRD_CSH', 'FX_PROP', 'FWRD_PROP', 'FWRD_HEDGE', 'PTH'].includes(
            p.positionTypeCode
          )
    );

    const dialogs = this._createDialogs(filteredPositions);

    return (
      <div className="portfolioDashboardWrapper">
        <div className="widget">
          <FilterBar
            {...this.props}
            changeBook={this.changeBook}
            toggleViewMode={this.toggleViewMode}
            toggleCashPosition={this.toggleCashPosition}
            changeViewDate={this.changeViewDate}
            getPortfolio={this.getPortfolio}
          />

          {!isLoaded ? (
            <Loader active inverted content="Loading..." />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}
            >
              {!isTagFundBook && (
                <RiskBar
                  riskInfo={riskInfo}
                  positions={filteredPositions}
                  riskBarColumns={settings.riskBarColumns}
                  openRiskControlModal={this.props.openRiskControlModal}
                  openDialog={this.props.openDialog}
                  closeDialog={this.props.closeDialog}
                />
              )}

              <PositionGrid
                onPositionSelected={onPositionSelected}
                positions={filteredPositions}
                posGridColumns={settings.posGridColumns}
                getContextMenuItems={this.getContextMenuItems}
                user={this.props.user}
              />
              <RiskControlDialog {...this.props} riskInfo={riskInfo} />

              <DraggableModalProvider>{dialogs}</DraggableModalProvider>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PortfolioDashboard;
