import React, { Component } from 'react';
import PushVoteReturnGrid from './PushVoteReturnGrid';
import PushVoteReturnQueryHeader from './PushVoteReturnQueryHeader';

import './PushVoteReturnTab.css';

class PushVoteReturnTab extends Component {
  render() {
    return (
      <div className="pushVoteReturnWrapper">
        <PushVoteReturnQueryHeader {...this.props} />
        <PushVoteReturnGrid {...this.props} />
      </div>
    );
  }
}

export default PushVoteReturnTab;
