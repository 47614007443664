import config from 'common/config';
import apiClient from '../../../common/api/apiClient';

const client = {
  deleteNotification: params => {
    const url = `${config.api.PXY_URL}/notify/remove`;
    return apiClient.asyncPost(url, params);
  },

  queryNotification: params => {
    const url = `${config.api.PXY_URL}/notify/query`;
    return apiClient.post(url, params);
  },

  downloadAtt: path => {
    const url = `${config.api.RESEARCH_URL}/file/downloadAtt/${path}`;
    return apiClient.get(url);
  },

  previewAtt: path => {
    const url = `${config.api.RESEARCH_URL}/file/previewAtt/${path}`;
    return apiClient.get(url);
  }
};

export default client;
