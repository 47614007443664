import React, { Component } from 'react';
import { Button, DatePicker } from 'antd';
import './StudentPaperAnalystTab.css';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { collegeTradeGridColumns } from './GridColumn';
import client from '../../api/client';
import _ from 'lodash';
import PercentBarComponent from 'common/ui/renderers/agGrid/PercentBarComponent';
import TagComponent from 'common/ui/renderers/agGrid/TagComponent';

const { RangePicker } = DatePicker;
class CollegeOppsiteTradeAnalyst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        tagComponent: TagComponent
      },
      queryParams: {
        date: [moment().add(-30, 'days'), moment()]
      }
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    const { date } = this.state.queryParams;
    if (_.isEmpty(date) || !date[0] || !date[1]) return;

    client
      .getCollegeTrades({
        startDate: date[0].format('YYYY-MM-DD'),
        endDate: date[1].format('YYYY-MM-DD')
      })
      .then(resp => {
        this.setState({
          data: _.isEmpty(resp) ? [] : resp
        });
      })
      .catch(err => console.log(err));
  };

  _changeDate = dates => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        date: dates
      }
    });
  };

  disabledDate = current => {
    const { date } = this.state.queryParams;
    if (!date[0]) {
      return false;
    }
    const startDateMoment = moment(date[0]);
    const endDateMoment = moment(current);
    const duration = endDateMoment.diff(startDateMoment, 'days');

    return Math.abs(duration) > 30;
  };

  _onOpenChange = open => {
    if (open) {
      this._changeDate([]);
    }
  };

  _createOpBar = () => {
    const { queryParams } = this.state;
    return (
      <div style={{ textAlign: 'right' }}>
        <RangePicker
          size="small"
          style={{ width: '220px' }}
          disabledDate={this.disabledDate}
          onCalendarChange={this._changeDate}
          value={queryParams.date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onOpenChange={this._onOpenChange}
          onChange={this._changeDate}
        />
        <Button
          size="small"
          type="primary"
          style={{ marginLeft: '5px' }}
          onClick={this._loadData}
        >
          Reload
        </Button>
      </div>
    );
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createDataGrid = () => {
    const { data } = this.state;
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '90%' }}
      >
        <AgGridReact
          // properties
          columnDefs={collegeTradeGridColumns}
          rowData={data}
          defaultColDef={this.state.defaultColDef}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={true}
          getRowNodeId={row => row.refId}
          groupDefaultExpanded={-1}
          frameworkComponents={this.state.frameworkComponents}
          rowGroupPanelShow="always"
          rowSelection="single"
          suppressCellSelection={true}
          onGridReady={this.onGridReady}
        />
      </div>
    );
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {this._createOpBar()}
        {this._createDataGrid()}
      </div>
    );
  }
}

export default CollegeOppsiteTradeAnalyst;
