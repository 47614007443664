import { connect } from 'react-redux';

import { selectIsLivePortfolioLoaded } from '../riskSelectors';
import { selectSettings } from '../../settings/settingSelectors';
import RiskDashboard from '../components/RiskDashboard';
import {
  // selectLivePositionMap,
  selectLiveRiskInfos,
  selectLiveBenchmarks
} from 'features/portfolio/portfolioSelectors';
import { sendSubscription } from '../../portfolio/portfolioActions';
// import EntityMap from 'entities/EntityMap';
import {
  loadReportList,
  loadReportAttribution,
  changeRiskQueryParamsInput,
  getFactorAsset,
  loadReturnReportList,
  changeReturnReportAttribution,
  changeReturnQueryParamsInput,
  getReturnFactorAsset,
  changeRiskSelectItemInput,
  changeReturnSelectItemInput
} from '../../risk/riskAction';

const mapStateToProps = state => {
  const riskInfos = selectLiveRiskInfos(state);
  const settings = selectSettings(state);
  const isPortfolioLoaded = selectIsLivePortfolioLoaded(state);
  // const livePositions = EntityMap.map(selectLivePositionMap(state));
  const benchmarks = selectLiveBenchmarks(state);

  return {
    isLoaded: isPortfolioLoaded && settings.isLoaded,
    riskInfos,
    settings,
    // livePositions,
    benchmarks,
    riskReportList: state.risk.riskReportList,
    riskReportQueryParams: state.risk.riskReportQueryParams,
    riskReportAttributionList: state.risk.riskReportAttributionList,
    riskReportAssetList: state.risk.riskReportAssetList,
    riskSelectedFactorItem: state.risk.riskSelectedFactorItem,
    returnReportList: state.risk.returnReportList,
    returnReportQueryParams: state.risk.returnReportQueryParams,
    returnReportAttributionList: state.risk.returnReportAttributionList,
    returnReportAssetList: state.risk.returnReportAssetList,
    returnSelectedFactorItem: state.risk.returnSelectedFactorItem,
    riskReportInfoLoadingStatus: state.risk.riskReportInfoLoadingStatus,
    riskReportFactorLoadingStatus: state.risk.riskReportFactorLoadingStatus,
    riskReportAssetLoadingStatus: state.risk.riskReportAssetLoadingStatus,
    returnReportInfoLoadingStatus: state.risk.returnReportInfoLoadingStatus,
    returnReportFactorLoadingStatus: state.risk.returnReportFactorLoadingStatus,
    returnReportAssetLoadingStatus: state.risk.returnReportAssetLoadingStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendSubscription: payload => dispatch(sendSubscription(payload)),
    loadReportList: params => dispatch(loadReportList(params)),
    loadReportAttribution: params => dispatch(loadReportAttribution(params)),
    changeRiskQueryParamsInput: params =>
      dispatch(changeRiskQueryParamsInput(params)),
    getFactorAsset: params => dispatch(getFactorAsset(params)),
    loadReturnReportList: params => dispatch(loadReturnReportList(params)),
    changeReturnReportAttribution: data =>
      dispatch(changeReturnReportAttribution(data)),
    changeReturnQueryParamsInput: params =>
      dispatch(changeReturnQueryParamsInput(params)),
    getReturnFactorAsset: params => dispatch(getReturnFactorAsset(params)),
    changeRiskSelectItemInput: params =>
      dispatch(changeRiskSelectItemInput(params)),
    changeReturnSelectItemInput: params =>
      dispatch(changeReturnSelectItemInput(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskDashboard);
