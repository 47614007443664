import config from 'common/config';
import { localStorageClient } from '../../../common/api/localStorageClient';
import apiClient from '../../../common/api/apiClient';
import { LS_KEY_ACCESS_TOKEN } from '../authConstants';

const login = ({ name, pwd }) => {
  return apiClient
    .post(config.api.PXY_URL + '/auth/login', {
      userName: name,
      pwd: pwd
    })
    .then(resp => {
      if (resp.accessToken) {
        localStorageClient.saveUt(LS_KEY_ACCESS_TOKEN, resp.accessToken);
        return resp;
      } else throw new Error('Login failed.');
    });
};

const chgPwd = ({ name, oldPwd, newPwd }) => {
  return apiClient.post(config.api.PXY_URL + '/auth/chgPwd', {
    userName: name,
    oldPwd,
    newPwd
  });
};

const logout = () => {
  localStorageClient.removeUt(LS_KEY_ACCESS_TOKEN);
};

const getAllUserManageRelation = () => {
  const url = `${config.api.RESEARCH_URL}/userManageRelation/getAllUserManageRelation`;
  return apiClient.post(url, {});
};

const applyUserManageRelationProcess = params => {
  const url = `${config.api.RESEARCH_URL}/userManageRelation/apply`;
  return apiClient.post(url, params);
};

const processUserManageRelation = params => {
  const url = `${config.api.RESEARCH_URL}/userManageRelation/processUserManageRelation`;
  return apiClient.post(url, params);
};

const selectAllManageUser = params => {
  const url = `${config.api.RESEARCH_URL}/userManageRelation/selectAllManageUser`;
  return apiClient.post(url, {});
};

const getAllAnalyst = params => {
  const url = `${config.api.RESEARCH_URL}/userManageRelation/getAllAnalyst`;
  return apiClient.post(url, {});
};

const getUserManageRelationList = params => {
  const url = `${config.api.RESEARCH_URL}/userManageRelation/getUserManageRelationList`;
  return apiClient.post(url, params);
};

const client = {
  login,
  chgPwd,
  logout,
  getAllUserManageRelation,
  applyUserManageRelationProcess,
  processUserManageRelation,
  selectAllManageUser,
  getAllAnalyst,
  getUserManageRelationList
};

export default client;
