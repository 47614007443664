import { createReducer } from 'common/utils/reducerUtils';
import * as omsConstants from '../../oms/omsConstants';
import * as orderConstants from '../../order/orderConstants';
import * as portfolioConstants from '../../portfolio/portfolioConstants';
import {
  DIALOG_OPEN,
  DIALOG_CLOSE,
  DIALOG_SUBMIT_SUCCESS
} from '../commonConstants';

const _extractDialogCodes = constants => {
  return Object.entries(constants)
    .filter(([k, _]) => k.startsWith('DIALOG_'))
    .map(([_, v]) => v);
};

const initialState = [
  ..._extractDialogCodes(omsConstants),
  ..._extractDialogCodes(orderConstants),
  ..._extractDialogCodes(portfolioConstants)
].reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: {
      isOpened: false,
      info: {}
    }
  }),
  {}
);

export function openDialog(state, payload) {
  const { dialogCode, info = {} } = payload;

  return {
    ...state,
    [dialogCode]: {
      ...initialState[dialogCode],
      isOpened: true,
      info
    }
  };
}

export function closeDialog(state, payload) {
  const { dialogCode } = payload;

  return {
    ...state,
    [dialogCode]: initialState[dialogCode]
  };
}

export default createReducer(initialState, {
  [DIALOG_OPEN]: openDialog,
  [DIALOG_CLOSE]: closeDialog,
  [DIALOG_SUBMIT_SUCCESS]: closeDialog
});
