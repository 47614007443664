import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { Button } from 'semantic-ui-react';
import { Divider } from 'antd';
import PercentBarComponent from '../../../../common/ui/renderers/agGrid/PercentBarComponent';
import TableTooltipComponent from '../../../../common/ui/renderers/agGrid/TableTooltipComponent';
import TagComponent from '../../../../common/ui/renderers/agGrid/TagComponent';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import agGridUtils from '../../../../common/ui/agGridUtils';
import operations from './Operations';
import numeral from 'numeral';
import _ from 'lodash';
import client from '../../api/client';

class RouteGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.settings.routeGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 25,
        checkboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelectionFilteredOnly: true,
        filter: 'agTextColumnFilter',
        suppressMenu: true,
        sortable: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number'
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        tagComponent: TagComponent,
        tableTooltipComponent: TableTooltipComponent
      },
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'route-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedRouteKeys = selectedRows.map(r => r.refId);

    this.props.selectRoutes(selectedRouteKeys);
  };

  _createButtons = () => {
    const {
      ui: { viewMode, requestType },
      selectedOrder,
      selectedRoute
    } = this.props;

    const buttons = [];

    operations.forEach(op => {
      if (requestType === op.requestType) {
        op.subOps.forEach(subOp =>
          buttons.push(
            <Button
              compact={true}
              size="mini"
              key={subOp.key}
              content={subOp.header}
              color={subOp.color}
              onClick={() =>
                this.props.openDialog(subOp.dialogCode, subOp.info)
              }
              disabled={subOp.disabledFn({
                viewMode,
                selectedOrder,
                selectedRoute
              })}
              style={{ marginBottom: '4px', marginTop: '4px' }}
            />
          )
        );

        buttons.push(<Divider key={op.key} type="vertical" />);
      }
    });

    return buttons;
  };

  _createSummaryPannel = () => {
    if (!this.gridApi) {
      return;
    }
    const selectedRows = this.gridApi.getSelectedRows();
    if (!_.isEmpty(selectedRows)) {
      const aggre = (t, g, value) => {
        const sign =
          g === 'GROSS' ? 1 : ['SELL', 'SHRT'].includes(t.side) ? -1 : 1;
        return value === undefined
          ? {
              count: 1,
              qtyUsd: sign * (t.qtyUsd || 0),
              quantity: sign * t.qtyUsd
            }
          : {
              count: value.count + 1,
              qtyUsd: value.qtyUsd + sign * (t.qtyUsd || 0),
              quantity: value.qtyUsd + sign * (t.qtyUsd || 0)
            };
      };

      const tradeSummary = _.map(
        selectedRows.reduce((acc, t) => {
          ['NET', 'GROSS'].forEach(g => (acc[g] = aggre(t, g, acc[g])));
          return acc;
        }, {}),
        (v, k) => {
          const sideStyle = 'keyword';
          return (
            <span
              key={k}
              className={sideStyle}
              style={{ marginLeft: '10px' }}
            >{`${k}(${v.count}): ${numeral(v.quantity).format('0,0')}/${numeral(
              v.qtyUsd
            ).format('$0,0')};`}</span>
          );
        }
      );

      return (
        <div
          style={{
            textAlign: 'right',
            marginTop: '3px',
            marginRight: '10px',
            height: '20px'
          }}
        >
          {_.orderBy(tradeSummary, [
            s => (s.key === 'NET' ? 0 : s.key === 'GROSS' ? 1 : 2)
          ])}
        </div>
      );
    }
  };

  getContextMenuItems = params => {
    if (params.node == null) return [];
    const menuItems = [];
    // Add built-in menu items.
    menuItems.push({
      name: 'Refresh',
      action: () => this._fireEmsxRouteFilledMsg()
    });
    menuItems.push('export');
    return menuItems;
  };

  _fireEmsxRouteFilledMsg = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedRouteKeys = selectedRows
      .filter(r => r.venue === 'EMSX')
      .map(r => r.refId);
    if (!_.isEmpty(selectedRouteKeys)) {
      client
        .fireEmsxRouteFilledMsg(selectedRouteKeys)
        .then(resp => console.log(resp))
        .catch(err => console.log(err));
    }
  };

  render() {
    const { routes, className = 'grid-wrapper' } = this.props;

    return (
      <div className="widget">
        <div className="operation-bar">{this._createButtons()}</div>
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <div className={`ag-theme-balham-dark ${className}`}>
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              rowData={routes}
              suppressAggFuncInHeader={true}
              animateRows={true}
              deltaRowDataMode={true}
              enableRangeSelection={true}
              getRowNodeId={this.getRowNodeId}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              getContextMenuItems={this.getContextMenuItems}
              rowSelection="multiple"
              frameworkComponents={this.state.frameworkComponents}
              floatingFilter={true}
              sideBar={this.state.sideBar}
              // events
              onGridReady={this.onGridReady}
              onSelectionChanged={this.onSelectionChanged}
            />
          </div>
          <div>{this._createSummaryPannel()}</div>
        </div>
      </div>
    );
  }
}

export default RouteGrid;
