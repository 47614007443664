import React, { Component } from 'react';
import { Header, Loader } from 'semantic-ui-react';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { Tabs } from 'antd';

const TabPane = Tabs.TabPane;
class MonthlyReviewMyPortfolioGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: props.monthlyReviewPortfolioGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      dataArr: []
    };
  }

  onGridReady = params => {
    this.onTabsKeyChanged(0);
    params.api.sizeColumnsToFit();
  };

  getBottomRowData = () => {
    const data = this.getMonthlyReviewDetailData();
    const bottomRowObj = [
      { ticker: 'Portfolio performace', ytd: data['ytd'], mtd: data['mtd'] }
    ];
    return bottomRowObj;
  };

  getDataMap = () => {
    const monthlyReviewPortfolioGridData = this.getData();
    const dataMap = {};
    if (
      monthlyReviewPortfolioGridData &&
      monthlyReviewPortfolioGridData.length > 0
    ) {
      for (var i = 0; i < monthlyReviewPortfolioGridData.length; i++) {
        const item = monthlyReviewPortfolioGridData[i];
        if (!dataMap[item.fund]) {
          dataMap[item.fund] = [];
        }
        dataMap[item.fund].push(item);
      }
    }
    var dataArr = [];
    for (var key in dataMap) {
      dataArr.push(dataMap[key]);
    }
    return dataArr;
  };

  onTabsKeyChanged = key => {
    const { readOnlyPage } = this.props;
    const monthlyReviewPortfolioGridData = this.getData();
    const monthlyReviewDetailData = this.getMonthlyReviewDetailData();
    const dataArr = this.getDataMap(monthlyReviewPortfolioGridData);
    if (dataArr && dataArr.length > 0) {
      const fund = dataArr[key][0]['fund'];
      const book = monthlyReviewDetailData.bookCode;
      const date = monthlyReviewDetailData.yearMonth;
      this.props.getGrossAndBetaByFundAndBook({
        fund: fund,
        book: book,
        date: date,
        readOnlyPage: readOnlyPage
      });
    }
  };

  getData = () => {
    const {
      monthlyReviewPortfolioGridDataReadonly,
      monthlyReviewPortfolioGridData,
      readOnlyPage
    } = this.props;
    return readOnlyPage
      ? monthlyReviewPortfolioGridDataReadonly
      : monthlyReviewPortfolioGridData;
  };

  getMonthlyReviewDetailData = () => {
    const {
      monthlyReviewDetailDataReadonly,
      monthlyReviewDetailData,
      readOnlyPage
    } = this.props;
    return readOnlyPage
      ? monthlyReviewDetailDataReadonly
      : monthlyReviewDetailData;
  };

  _getHeaderTitle = () => {
    const monthlyReviewDetailData = this.getMonthlyReviewDetailData();
    const monthForEng = monthlyReviewDetailData['monthForEng'];
    if ('December' === monthForEng) {
      return `My Portfolio performance and valuation: TOP & BOTTOM 5 positions ranked by YTD%`;
    } else {
      return `My Portfolio performance and valuation: TOP & BOTTOM 5 positions ranked by MTD%`;
    }
  };

  _getData = data => {
    const monthlyReviewDetailData = this.getMonthlyReviewDetailData();
    const monthForEng = monthlyReviewDetailData['monthForEng'];
    if ('December' === monthForEng) {
      return _.orderBy(data, ['ytd'], ['desc']);
    } else {
      return _.orderBy(data, ['mtd'], ['desc']);
    }
  };

  render() {
    const {
      className = 'grid-wrapper',
      monthlyReviewMyPerformanceStatus
    } = this.props;
    const data = this.getData();
    const dataArr = this.getDataMap(data);
    const bottomData = this.getBottomRowData();
    return (
      <div className={`ag-theme-balham-dark ${className}`}>
        <Header
          style={{
            color: 'white',
            marginLeft: '5px',
            fontSize: '14px',
            marginTop: '7px',
            marginBottom: '5px'
          }}
        >
          {this._getHeaderTitle()}
        </Header>
        {!monthlyReviewMyPerformanceStatus ? (
          <Loader active inverted content="Loading..." />
        ) : (
          <Tabs
            defaultActiveKey="0"
            tabPosition={'top'}
            onChange={this.onTabsKeyChanged}
          >
            {dataArr.map((item, index) => (
              <TabPane tab={item[0]['fund']} key={index}>
                <div
                  className={`ag-theme-balham-dark ${className}`}
                  style={{ height: '78%', minHeight: '250px' }}
                >
                  <AgGridReact
                    // properties
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    groupDefaultExpanded={this.state.groupDefaultExpanded}
                    rowData={this._getData(item)}
                    suppressAggFuncInHeader={true}
                    animateRows={true}
                    deltaRowDataMode={false}
                    enableRangeSelection={true}
                    rowSelection="single"
                    pinnedBottomRowData={bottomData}
                    // events
                    onGridReady={this.onGridReady}
                  />
                </div>
              </TabPane>
            ))}
          </Tabs>
        )}
      </div>
    );
  }
}

export default MonthlyReviewMyPortfolioGrid;
