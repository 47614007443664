import { createSelector } from 'reselect';
import EntityMap from 'entities/EntityMap';

export const omsSelector = state => state.oms;

const omsViewStateSelector = state => {
  const { viewMode } = state.oms.ui;
  return state.oms.viewState[viewMode];
};

const omsReqTypeSelector = state => {
  return state.oms.ui.requestType;
};

export const isBlotterLoadedSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return viewState.isLoaded;
  }
);

export const ordersSelector = createSelector(
  [omsViewStateSelector, omsReqTypeSelector],
  (viewState, reqType) => {
    return EntityMap.map(viewState.orders).filter(o => {
      const reqTypes = reqType === 'TRD' ? ['TRD', 'BASKET'] : [reqType];
      return reqTypes.includes(o.requestType);
    });
  }
);

export const ordersWithNotReqTypeSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return viewState.orders ? EntityMap.map(viewState.orders) : [];
  }
);

export const selectedOrderKeysSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return viewState.selectedOrderKeys || [];
  }
);

export const routesSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return EntityMap.map(viewState.routes);
  }
);

export const allocationsSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return EntityMap.map(viewState.allocations);
  }
);

export const runInfosSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return EntityMap.map(viewState.runInfos);
  }
);

export const holdingMapSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return viewState.holdings;
  }
);

export const crossInfosSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return EntityMap.map(viewState.crossInfos);
  }
);

export const selectedRouteKeysSelector = createSelector(
  omsViewStateSelector,
  viewState => {
    return viewState.selectedRouteKeys || [];
  }
);
