import React, { Component } from 'react';
import DailyReportManageGrid from './DailyReportManageGrid';

import './DailyReportManageTab.css';

class DailyReportManageTab extends Component {
  render() {
    return (
      <div className="dailyReportManageTabWrapper">
        <DailyReportManageGrid {...this.props} />
      </div>
    );
  }
}

export default DailyReportManageTab;
