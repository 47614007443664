import React, { Component } from 'react';
import { Tabs } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import {
  pxTrackingAlertsColumnDefs,
  pairPxTrackingAlertsColumnDefs
} from './GridColumnMap';
import {
  DIALOG_PRICE_TRACKING_MODAL,
  DIALOG_PRICE_TRACKING_TRADES_MODAL
} from '../portfolioConstants';
import _ from 'lodash';
import client from '../api/client';

export default class PxTrackingAlertsGrid extends Component {
  state = {
    defaultColDef: {
      enableCellChangeFlash: true,
      minWidth: 50,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true
    },
    selectedData: [],
    genTrackingTradesModal: {
      isOpened: false
    }
  };

  _createPxTrackingGridMenu = () => {
    const contextMenus = [
      {
        name: 'Update (change price alert point)',
        action: () => {
          this._updatePxTrackingUpDown();
        }
      },
      // {
      //   name: 'Clear Alerts',
      //   action: () => {
      //     this._clearPxTracking();
      //   }
      // },
      'separator',
      {
        name: 'Create Trades',
        action: () => {
          this._genTrades();
        }
      }
    ];
    return contextMenus;
  };

  _genTrades = () => {
    const { positions } = this.props;
    const { selectedData } = this.state;
    this.props.openDialog(DIALOG_PRICE_TRACKING_TRADES_MODAL, {
      data: selectedData,
      positions
    });
  };

  onPxTrackingReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();

    this.setState({
      selectedData: selectedRows
    });
  };

  _clearPxTracking = () => {
    const { selectedData } = this.state;
    if (_.isEmpty(selectedData)) return;
    const bookList = [];
    const submitData = selectedData.map(r => {
      if (!bookList.includes(r.bookCode)) bookList.push(r.bookCode);
      const bbgTicker = r.ticker;
      const basePrice = r.basePrice;
      const upPct = null;
      const downPct = null;
      const direction = r.quantityDirection;
      return {
        bbgTicker,
        basePrice,
        upPct,
        downPct,
        direction
      };
    });

    client
      .saveTrackPriceList({
        fundBookList: bookList,
        data: submitData
      })
      .then(() => {})
      .catch(ex => {
        console.log(ex);
      });
  };

  _updatePxTrackingUpDown = () => {
    const { selectedData } = this.state;
    if (_.isEmpty(selectedData)) return;
    const filterTickers = selectedData.map(r => r.ticker);
    this.props.openDialog(DIALOG_PRICE_TRACKING_MODAL, { filterTickers });
  };

  _getWinFlag = data => {
    const { chgPct, upPct, downPct } = data;
    if (data.quantityDirection === 'LONG') {
      return chgPct * 100 >= upPct;
    } else if (data.quantityDirection === 'SHORT') {
      return -chgPct * 100 >= downPct;
    }
    return false;
  };

  render() {
    const { data, pairPxTrackingAlerts } = this.props;
    const sortData = _.orderBy(data, ['absChgPct'], ['desc']);
    return (
      <div>
        <Tabs
          tabPosition="top"
          style={{ width: '1000px', height: '300px' }}
          className={`ag-theme-balham-dark grid-wrapper`}
        >
          <Tabs.TabPane
            tab="Single"
            key="Single"
            style={{ width: '100%', height: '300px' }}
          >
            <AgGridReact
              // properties
              columnDefs={pxTrackingAlertsColumnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={sortData}
              suppressAggFuncInHeader={true}
              animateRows={false}
              deltaRowDataMode={true}
              getRowNodeId={data => data.key}
              rowSelection="multiple"
              suppressCellSelection={true}
              onGridReady={this.onPxTrackingReady}
              onSelectionChanged={this.onSelectionChanged}
              getContextMenuItems={this._createPxTrackingGridMenu}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Pair"
            key="Pair"
            style={{ width: '100%', height: '300px' }}
            className={`ag-theme-balham-dark grid-wrapper`}
          >
            <AgGridReact
              // properties
              columnDefs={pairPxTrackingAlertsColumnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={pairPxTrackingAlerts}
              suppressAggFuncInHeader={true}
              animateRows={false}
              deltaRowDataMode={true}
              getRowNodeId={data => data.key}
              rowSelection="multiple"
              suppressCellSelection={true}
              onGridReady={this.onPxTrackingReady}
              onSelectionChanged={this.onSelectionChanged}
              getContextMenuItems={this._createPxTrackingGridMenu}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}
