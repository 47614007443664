import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { DIALOG_CB_DELTA_MODAL } from '../portfolioConstants';
import _ from 'lodash';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import { cbDeltaGridColumns } from './GridColumnMap';
import client from '../api/client';

class CBDeltaDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cbDeltaGridWrapperStyle: {
        width: '100%',
        height: '450px',
        marginTop: '5px',
        border: 'solid 2px grey',
        padding: '2px'
      },
      submitStatus: 'READY',
      data: []
    };
  }

  componentDidMount() {
    const { positions } = this.props;
    const data = positions.filter(
      r => r.instrumentClass === 'CONVBND' && r.exchangeCountryCode !== 'CN'
    );
    this.setState({
      data
    });
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_CB_DELTA_MODAL);
  };

  _createRoutesGridSettings = () => {
    const columns = cbDeltaGridColumns.map(c => {
      return {
        ...c
      };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  createHotTable = data => {
    const { cbDeltaGridWrapperStyle } = this.state;
    const setting = this._createRoutesGridSettings();
    return (
      !_.isEmpty(data) && (
        <div style={cbDeltaGridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={data}
            manualColumnResize={true}
            {...setting}
          ></HotTable>
        </div>
      )
    );
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, data } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    return _.isEmpty(data) ? '' : submitBtn;
  };

  _onSubmit = () => {
    const { data } = this.state;
    if (_.isEmpty(data)) return;
    const valueTypeCode = 'DELTA';
    let currentBookCode = null;
    const submitData = data.map(r => {
      const bookCode = r.bookCode;
      currentBookCode = r.bookCode;
      const securityId = r.securityId;
      const tradeDelta = r.tradeDelta;
      return {
        bookCode,
        securityId,
        valueTypeCode,
        value: tradeDelta
      };
    });
    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .saveAnalyticTradeDataList({
        bookCode: currentBookCode,
        data: submitData
      })
      .then(() => {
        this.setState({ submitStatus: 'READY' });
        this.closeDialog();
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <style>
          {`
                .ui.modals {
                  display: flex !important;
                }
                .ui.modal {
                  margin-top: 0px !important;
                }
              `}
        </style>
        <Modal
          width={1600}
          maskClosable={false}
          title={`CB Delta`}
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            this._createSubmitBtn(this._onSubmit),
            <Button
              key="close"
              type="primary"
              onClick={this.closeDialog}
              style={{ marginLeft: '10px' }}
            >
              Close
            </Button>
          ]}
        >
          {this.createHotTable(data)}
        </Modal>
      </div>
    );
  }
}

export default CBDeltaDialog;
