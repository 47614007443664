import React, { Component } from 'react';
import { Tabs } from 'antd';
import './ResearchDashboard.css';

import ShortInterestTab from './ShortInterestTab';
import BrokerTab from './BrokerTab';
import AnalystListTab from './AnalystListTab';
import ShortListTab from './short/ShortListTab';
import MonthlyReviewTab from './monthlyReview/MonthlyReviewTab';
// import ResearchReportTab from './researchReport/ResearchReportTab';
import BlockListTab from './blockList/BlockListTab';
import ResearchTab from './researchReport/ResearchTab';
// import PortfolioPlanTab from './portfolioPlan/PortfolioPlanTab';
import CompanyScoreTab from './companyScore/CompanyScoreTab';
import MarketTab from './MarketTab';
import DailyReportTab from './dailyReport/DailyReportTab';
import StudentResearchManageTab from './studentResearchManage/StudentResearchManageTab';
import PaperAnalystTabs from './studentPaperAnalyst/PaperAnalystTabs';
const TabPane = Tabs.TabPane;

class ResearchDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultActiveTab: this._getDefaultActiveTab()
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (!user) return;

    this.props.sendSubscription({
      view: 'RESEARCH',
      userName: user.englishName
    });
  }

  _getDefaultActiveTab = () => {
    const pathname = window.location ? window.location.pathname : null;
    if (pathname) {
      const arr = pathname.split('/');
      return arr.length === 3 ? arr[2] : '1';
    }
    return '1';
  };

  componentDidUpdate(prevProps) {
    const { user: prevUser } = prevProps;
    const { user } = this.props;

    if (!prevUser && user) {
      this.props.sendSubscription({
        view: 'RESEARCH',
        userName: user.englishName
      });
    }
  }

  isResearchManageRole = () => {
    const { user } = this.props;
    if (user) {
      return [
        'FundMngr',
        'Admin',
        'Supervisor',
        'PortMngr',
        'Advisor',
        'Analyst'
      ].includes(user.role);
    }
  };

  isResearchMgrRole = () => {
    const { user } = this.props;
    if (user) {
      return ['FundMngr', 'Admin', 'Supervisor'].includes(user.role);
    }
  };

  render() {
    return (
      <div className="researchDashboardWrapper">
        <Tabs
          defaultActiveKey={this.state.defaultActiveTab}
          tabPosition={'top'}
        >
          {/* <TabPane tab="Company" key="1">
            <ViewResearchTab {...this.props} />
          </TabPane>
          <TabPane tab="Boxed Position" key="2">
            <BoxedPositionGrid {...this.props} />
          </TabPane> */}
          <TabPane tab="Short List" key="8">
            <ShortListTab {...this.props} />
          </TabPane>
          <TabPane tab="Short Interest" key="4">
            <ShortInterestTab {...this.props} />
          </TabPane>
          <TabPane tab="Broker" key="5">
            <BrokerTab {...this.props} />
          </TabPane>
          {/* <TabPane tab="Analyst" key="6">
            <AnalystTab {...this.props} />
          </TabPane> */}
          <TabPane tab="Analyst" key="10">
            <AnalystListTab {...this.props} />
          </TabPane>
          <TabPane tab="Block List" key="12">
            <BlockListTab {...this.props} />
          </TabPane>
          <TabPane tab="Monthly Review" key="9">
            <MonthlyReviewTab {...this.props} />
          </TabPane>
          <TabPane tab="Company Score" key="14">
            <CompanyScoreTab {...this.props} />
          </TabPane>
          {/* <TabPane tab="Portfolio Plan" key="11">
            <PortfolioPlanTab {...this.props} />
          </TabPane> */}
          <TabPane tab="Stock Pitch" key="13">
            <ResearchTab {...this.props} />
          </TabPane>
          <TabPane tab="Market" key="15">
            <MarketTab {...this.props} />
          </TabPane>
          <TabPane tab="Report" key="16">
            <DailyReportTab {...this.props} />
          </TabPane>
          {this.isResearchManageRole() && (
            <TabPane tab="Coverage" key="17">
              <StudentResearchManageTab {...this.props} />
            </TabPane>
          )}
          {this.isResearchManageRole() && (
            <TabPane tab="Paper Analyst" key="18">
              <PaperAnalystTabs {...this.props} />
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

export default ResearchDashboard;
