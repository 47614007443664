import React, { Component } from 'react';
import DailyReportGrid from './DailyReportGrid';
import { Tabs } from 'antd';
import './DailyReportTab.css';
import DailyReportManageTab from '../dailyReportManage/DailyReportManageTab';
import _ from 'lodash';

const TabPane = Tabs.TabPane;

class DailyReportTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1'
    };
  }

  tabChange = activeKey => {
    this.setState({
      activeTab: activeKey
    });
  };

  _isAdmin = () => {
    const { user } = this.props;
    if (!_.isEmpty(user)) {
      return (
        ['FundMngr', 'Admin', 'Supervisor'].includes(user.role) ||
        ['jin.qian'].includes(user.englishName)
      );
    }
    return false;
  };

  render() {
    return (
      <Tabs
        tabPosition={'top'}
        onChange={this.tabChange}
        activeKey={this.state.activeTab}
      >
        <TabPane tab="Daily Report" key="1">
          <div className="dailyReportTabWrapper">
            <DailyReportGrid {...this.props} />
          </div>
        </TabPane>
        {this._isAdmin() && (
          <TabPane tab="Daily Report Manage" key="2">
            <DailyReportManageTab {...this.props} />
          </TabPane>
        )}
      </Tabs>
    );
  }
}

export default DailyReportTab;
