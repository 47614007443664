import config from 'common/config';
import apiClient from 'common/api/apiClient';

const client = {
  getReportList: params => {
    const url = `${config.api.TREASURY_URL}/report/margin`;
    return apiClient.post(url, params);
  },

  createReport: params => {
    const url = `${config.api.TREASURY_URL}/create_report`;
    return apiClient.post(url, params);
  },

  getExposureSnapshotList: params => {
    const url = `${config.api.TREASURY_URL}/getExposureSnapshotList`;
    return apiClient.post(url, params);
  },

  getMarginSummary: params => {
    const url = `${config.api.TREASURY_URL}/report/marginSummary`;
    return apiClient.post(url, params);
  },

  createCommissionReport: params => {
    const url = `${config.api.REST_URL}/report/createReport`;
    return apiClient.post(url, params);
  },

  queryTradeChargeRuleList: () => {
    const url = `${config.api.TREASURY_URL}/charge/queryTradeChargeRuleList`;
    return apiClient.post(url);
  },

  querySwapTradeParameters: () => {
    const url = `${config.api.TREASURY_URL}/charge/querySwapTradeParameters`;
    return apiClient.post(url);
  },

  getPriceList: data => {
    const url = `${config.api.TREASURY_URL}/charge/getPriceList`;
    return apiClient.post(url, data);
  },

  getPriceListByDate: data => {
    const url = `${config.api.TREASURY_URL}/charge/getPriceListByDate`;
    return apiClient.post(url, data);
  },

  getGCRateList: () => {
    const url = `${config.api.TREASURY_URL}/charge/getGCRateList`;
    return apiClient.post(url);
  },

  getOnshoreMargin: data => {
    const url = `${config.api.TREASURY_URL}/report/getOnshoreMargin`;
    return apiClient.post(url, data);
  },

  queryTradeChargeSummary: params => {
    const url = `${config.api.TREASURY_URL}/charge/queryTradeChargeSummary`;
    return apiClient.post(url, params);
  }
};

export default client;
