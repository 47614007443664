import { createReducer } from 'common/utils/reducerUtils';
import EntityMap from '../../../entities/EntityMap';
import {
  OMS_SNAPSHOT,
  OMS_UPDATE,
  ORDERS_SELECT,
  OMS_FUNDS_SELECT,
  OMS_FILTER_CHANGE,
  DIALOG_APPROVE_ORDERS,
  DIALOG_ARCHIVE_ORDERS,
  DIALOG_DELETE_ORDERS,
  DIALOG_FINALIZE_ORDERS,
  DIALOG_REJECT_ORDERS,
  DIALOG_ASSIGN_ORDERS,
  DIALOG_EXECUTE_ROUTES,
  DIALOG_CANCEL_ROUTES,
  OMS_VIEW_MODE_HIST,
  OMS_VIEW_MODE_LIVE,
  OMS_VIEW_MODE_CHANGE,
  OMS_DATE_CHANGE,
  OMS_REQ_TYPE_CHANGE,
  OMS_ORDER_BLOTTER_GET_REQUEST,
  OMS_ORDER_BLOTTER_GET_SUCCESS,
  OMS_ORDER_BLOTTER_GET_FAIL,
  ROUTES_SELECT,
  DIALOG_ACTIVATE_ORDERS,
  OMS_VIEW_USESRV_MODE_CHANGE
} from '../omsConstants';

import { DIALOG_SUBMIT_SUCCESS } from '../../common/commonConstants';
import { localStorageClient } from '../../../common/api/localStorageClient';
import { isInternalFund } from '../../../common/utils/DomainUtils';

import { LOAD_SETTINGS_SUCCESS } from '../../settings/settingConstants';
import moment from 'moment';

const TABLE_ORDER = 'ORDER';
const TABLE_ROUTE = 'ROUTE';
const TABLE_ALLOCATION = 'ALLOCATION';
const TABLE_RUN_INFO = 'RUN_INFO';
const TABLE_CROSS_INFO = 'CROSS_INFO';
const TABLE_HOLDING = 'HOLDING';

const LS_KEY_OMS_SELECTED_FUNDS = 'oms-selected-funds';

const _createInitialViewState = viewMode => {
  return {
    isLoaded: viewMode === OMS_VIEW_MODE_LIVE ? false : true,

    orders: EntityMap.fromArray([]),
    routes: EntityMap.fromArray([]),
    allocations: EntityMap.fromArray([]),
    runInfos: EntityMap.fromArray([]),
    crossInfos: EntityMap.fromArray([]),
    holdings: EntityMap.fromArray([]),
    selectedOrderKeys: [],
    selectedRouteKeys: []
  };
};

const initialState = {
  ui: {
    selectedFunds: [],
    filters: {
      bookCode: [],
      tradeDate: [],
      tradercode: []
    },

    selectedDate: [moment().subtract(7, 'days'), moment()],
    viewMode: OMS_VIEW_MODE_LIVE,
    requestType: 'TRD',
    useOmsSrv: true
  },

  viewState: {
    [OMS_VIEW_MODE_LIVE]: _createInitialViewState(OMS_VIEW_MODE_LIVE),
    [OMS_VIEW_MODE_HIST]: _createInitialViewState(OMS_VIEW_MODE_HIST)
  }
};

const extractTblData = (snapshot, tblName, converter = obj => obj) => {
  return snapshot
    .filter(row => row.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));
};

const mergeUpdates = (target, tblName, updates, converter = obj => obj) => {
  const upsertedEntities = updates
    .filter(u => ['Update', 'Add'].includes(u.type) && u.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));

  const deletedEntityKeys = updates
    .filter(u => u.type === 'Delete' && u.tableName === tblName)
    .map(u => u.key);

  return EntityMap.remove(
    EntityMap.merge(target, upsertedEntities),
    deletedEntityKeys
  );
};

export function initOms(state, payload) {
  const { selectedOrderKeys, selectedRouteKeys } = state.viewState[
    OMS_VIEW_MODE_LIVE
  ];

  const orders = extractTblData(payload, TABLE_ORDER);
  const routes = extractTblData(payload, TABLE_ROUTE);
  const allocations = extractTblData(payload, TABLE_ALLOCATION);
  const runInfos = extractTblData(payload, TABLE_RUN_INFO);
  const crossInfos = extractTblData(payload, TABLE_CROSS_INFO);
  const holdings = extractTblData(payload, TABLE_HOLDING);

  return {
    ...state,
    viewState: {
      ...state.viewState,
      [OMS_VIEW_MODE_LIVE]: {
        isLoaded: true,

        orders: EntityMap.fromArray(orders),
        routes: EntityMap.fromArray(routes),
        allocations: EntityMap.fromArray(allocations),
        runInfos: EntityMap.fromArray(runInfos),
        crossInfos: EntityMap.fromArray(crossInfos),
        holdings: EntityMap.fromArray(holdings),
        selectedOrderKeys,
        selectedRouteKeys
      }
    }
  };
}

export function updateOms(state, payload) {
  const {
    isLoaded,
    orders,
    routes,
    allocations,
    runInfos,
    crossInfos,
    holdings,
    selectedOrderKeys,
    selectedRouteKeys
  } = state.viewState[OMS_VIEW_MODE_LIVE];

  const updatedOrders = mergeUpdates(orders, TABLE_ORDER, payload);
  const updatedRoutes = mergeUpdates(routes, TABLE_ROUTE, payload);
  const updateRunInfos = mergeUpdates(runInfos, TABLE_RUN_INFO, payload);
  const updateCrossInfos = mergeUpdates(crossInfos, TABLE_CROSS_INFO, payload);

  return {
    ...state,
    viewState: {
      ...state.viewState,
      [OMS_VIEW_MODE_LIVE]: {
        isLoaded,
        selectedOrderKeys,
        selectedRouteKeys,

        orders: updatedOrders,
        routes: updatedRoutes,
        allocations: mergeUpdates(allocations, TABLE_ALLOCATION, payload),
        runInfos: updateRunInfos,
        crossInfos: updateCrossInfos,
        holdings: mergeUpdates(holdings, TABLE_HOLDING, payload)
      }
    }
  };
}

export function selectOrders(state, payload) {
  const { viewMode } = state.ui;
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [viewMode]: {
        ...state.viewState[viewMode],
        selectedOrderKeys: payload
      }
    }
  };
}

export function selectRoutes(state, payload) {
  const { viewMode } = state.ui;
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [viewMode]: {
        ...state.viewState[viewMode],
        selectedRouteKeys: payload
      }
    }
  };
}

export function loadSettingsSuccess(state, payload) {
  let selectedFunds = localStorageClient.get(LS_KEY_OMS_SELECTED_FUNDS);

  if (!selectedFunds) {
    selectedFunds = payload.funds
      .map(f => f.name)
      .filter(fn => isInternalFund(fn));

    localStorageClient.save(LS_KEY_OMS_SELECTED_FUNDS, selectedFunds);
  }

  return {
    ...state,
    ui: {
      ...state.ui,
      selectedFunds
    }
  };
}

export function selectFunds(state, payload) {
  // Save to local storage first.
  localStorageClient.save(LS_KEY_OMS_SELECTED_FUNDS, payload);

  return {
    ...state,
    ui: {
      ...state.ui,
      selectedFunds: payload
    }
  };
}

export function changeFilter(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      filters: {
        ...state.ui.filters,
        [payload.field]: payload.values
      }
    }
  };
}

const _approveOrdersSuccess = (liveViewState, payload) => {
  const { info: orderKeys } = payload;
  const { orders } = liveViewState;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          traderStatus: 'APPROVING'
        };
      }

      return o;
    })
  };
};

const _rejectOrdersSuccess = (liveViewState, payload) => {
  const { info: orderKeys } = payload;
  const { orders } = liveViewState;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          traderStatus: 'REJECTING'
        };
      }

      return o;
    })
  };
};

const _archiveOrdersSuccess = (liveViewState, payload) => {
  const { info: orderKeys } = payload;
  const { orders } = liveViewState;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          requestStatus: 'ARCHIVING'
        };
      }

      return o;
    })
  };
};

const _deleteOrdersSuccess = (liveViewState, payload) => {
  const { info: orderKeys } = payload;
  const { orders } = liveViewState;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          requestStatus: 'DELETING'
        };
      }

      return o;
    })
  };
};

const _activateOrdersSuccess = (histViewState, payload) => {
  const { info: orderKeys } = payload;
  const { orders } = histViewState;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          requestStatus: 'ACTIVE'
        };
      }

      return o;
    })
  };
};

const _finalizeOrdersSuccess = (liveViewState, payload) => {
  const { info: orderKeys } = payload;
  const { orders } = liveViewState;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          requestStatus: 'FINALIZING'
        };
      }

      return o;
    })
  };
};

const _assignOrdersSuccess = (liveViewState, payload) => {
  const { info: cmds } = payload;
  const { orders } = liveViewState;

  const orderKeys = cmds.map(cmd => cmd.refId);
  const traderCode = cmds[0].traderCode;

  return {
    orders: EntityMap.apply(orders, (o, key) => {
      if (orderKeys.includes(key)) {
        return {
          ...o,
          traderCode
        };
      }

      return o;
    })
  };
};

const _mergeRoutes = (liveViewState, routes) => {
  const updatedRoutes = routes.map(r => {
    const existingRoute = EntityMap.get(liveViewState.routes, r.refId, null);
    return existingRoute
      ? {
          ...existingRoute,
          ...r
        }
      : { ...r, key: r.refId };
  });

  const mergedRoutes = EntityMap.merge(liveViewState.routes, updatedRoutes);
  return {
    routes: mergedRoutes
  };
};

const _submitRoutesSuccess = (liveViewState, payload) => {
  const {
    info: { routes }
  } = payload;

  return _mergeRoutes(liveViewState, routes);
};

const _cancelRoutesSuccess = (liveViewState, payload) => {
  const { info: routeKeys } = payload;
  const { routes } = liveViewState;

  return {
    routes: EntityMap.apply(routes, (r, key) => {
      if (routeKeys.includes(key)) {
        return {
          ...r,
          status: 'CANCEL'
        };
      }

      return r;
    })
  };
};

const _dialogSuccessHandlers = {
  [DIALOG_APPROVE_ORDERS]: { handler: _approveOrdersSuccess },
  [DIALOG_ARCHIVE_ORDERS]: { handler: _archiveOrdersSuccess },
  [DIALOG_FINALIZE_ORDERS]: { handler: _finalizeOrdersSuccess },
  [DIALOG_REJECT_ORDERS]: { handler: _rejectOrdersSuccess },
  [DIALOG_ASSIGN_ORDERS]: { handler: _assignOrdersSuccess },
  [DIALOG_DELETE_ORDERS]: { handler: _deleteOrdersSuccess },
  [DIALOG_ACTIVATE_ORDERS]: {
    handler: _activateOrdersSuccess,
    viewMode: OMS_VIEW_MODE_HIST
  },
  [DIALOG_EXECUTE_ROUTES]: { handler: _submitRoutesSuccess },
  [DIALOG_CANCEL_ROUTES]: { handler: _cancelRoutesSuccess }
};

export function submitDialogSuccess(state, payload) {
  const { dialogCode } = payload;

  if (!(dialogCode in _dialogSuccessHandlers)) {
    return state;
  }

  // TODO: Fix the below mentioned bug.
  // Note: for any order state change in dialog success handler, UI might display wrong status for a long time
  // like run compliance should change compliance status from FAIL to PENDING, but if the compliance check result is still fail
  // ims-pub will still think the order has no changes, thus UI will still display PENDING.
  // that's why no compliance dialog handler defined here.

  const { handler, viewMode = OMS_VIEW_MODE_LIVE } = _dialogSuccessHandlers[
    dialogCode
  ];
  const innerViewState = state.viewState[viewMode];

  return {
    ...state,

    viewState: {
      ...state.viewState,
      [viewMode]: {
        ...state.viewState[viewMode],
        ...handler(innerViewState, payload)
      }
    }
  };
}

export function toggleViewMode(state) {
  return {
    ...state,
    ui: {
      ...state.ui,
      viewMode:
        state.ui.viewMode === OMS_VIEW_MODE_LIVE
          ? OMS_VIEW_MODE_HIST
          : OMS_VIEW_MODE_LIVE
    }
  };
}

export function toggleUseOmsSrvMode(state) {
  return {
    ...state,
    ui: {
      ...state.ui,
      useOmsSrv: !state.ui.useOmsSrv
    }
  };
}

export function changeViewDate(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedDate: payload
    }
  };
}

export function changeRequestType(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      requestType: payload
    }
  };
}

export function requestGetOrderBlotter(state) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [OMS_VIEW_MODE_HIST]: {
        ...initialState.viewState[OMS_VIEW_MODE_HIST],
        isLoaded: false
      }
    },
    ui: {
      ...state.ui,
      filters: initialState.ui.filters
    }
  };
}

export function getOrderBlotterSuccess(state, payload) {
  const { orders, routes, allocations, crossInfos } = payload;
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [OMS_VIEW_MODE_HIST]: {
        isLoaded: true,

        orders: EntityMap.fromArray(orders),
        routes: EntityMap.fromArray(routes),
        allocations: EntityMap.fromArray(allocations),
        runInfos: EntityMap.fromArray([]),
        holdings: EntityMap.fromArray([]),
        crossInfos: EntityMap.fromArray(crossInfos),
        selectedOrderKeys: [],
        selectedRouteKeys: []
      }
    }
  };
}

export function getOrderBlotterFail(state, payload) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [OMS_VIEW_MODE_HIST]: {
        ...initialState.viewState[OMS_VIEW_MODE_HIST],
        isLoaded: true
      }
    }
  };
}

export default createReducer(initialState, {
  [LOAD_SETTINGS_SUCCESS]: loadSettingsSuccess,

  [OMS_SNAPSHOT]: initOms,
  [OMS_UPDATE]: updateOms,
  [ORDERS_SELECT]: selectOrders,
  [ROUTES_SELECT]: selectRoutes,

  [OMS_FUNDS_SELECT]: selectFunds,
  [OMS_FILTER_CHANGE]: changeFilter,

  [OMS_VIEW_MODE_CHANGE]: toggleViewMode,
  [OMS_VIEW_USESRV_MODE_CHANGE]: toggleUseOmsSrvMode,
  [OMS_DATE_CHANGE]: changeViewDate,
  [OMS_REQ_TYPE_CHANGE]: changeRequestType,

  [OMS_ORDER_BLOTTER_GET_REQUEST]: requestGetOrderBlotter,
  [OMS_ORDER_BLOTTER_GET_SUCCESS]: getOrderBlotterSuccess,
  [OMS_ORDER_BLOTTER_GET_FAIL]: getOrderBlotterFail,

  [DIALOG_SUBMIT_SUCCESS]: submitDialogSuccess
});
