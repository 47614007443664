import React, { Component } from 'react';
import { Modal, Divider, Button, Spin } from 'antd';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import config from 'common/config';
import fetch from 'isomorphic-fetch';
import moment from 'moment';

export default class DailyReviewModal extends Component {
  async componentDidMount() {
    const { saveImg, save } = this.props;
    const { autoSave } = saveImg;
    if (autoSave) {
      _.delay(async () => {
        const urlId = await this.genImgAndUpload();
        if (urlId) {
          save(urlId);
        }
      }, 500);
    }
  }

  _createReviewPannel = () => {
    const { data, user } = this.props;
    return (
      <div id="capture-analyst-daily-element">
        <div
          style={{
            width: '400px',
            height: '100%',
            overflow: 'auto',
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '5px',
            verticalAlign: 'bottom'
          }}
        >
          <style>
            {`
                    #capture-analyst-daily-element img{
                        width: auto !important;
                        height: auto !important;
                        max-width: 100% !important;
                        max-height: 100% !important;	
                    }

                    #capture-analyst-daily-element table{
                        width: auto !important;
                        height: auto !important;
                        max-width: 100% !important;
                        max-height: 100% !important;	
                    }

                    #capture-analyst-daily-element table td{
                        border: 1px solid black;	
                        height: 20px;
                        min-width: 20px;
                    }

                    #capture-analyst-daily-element table th{
                        background-color: rgb(245, 242, 240);
                        border: 1px solid black;	
                        height: 20px;
                        text-align: center;	
                    }
                `}
          </style>
          <div style={{ width: '100%', height: '30px' }}>
            <div style={{ width: '50%', float: 'left', fontSize: '16px' }}>
              <span>
                <b>Date:</b>
              </span>
              <span style={{ marginLeft: '10px' }}>
                {moment(data.date).format('YYYY-MM-DD')}
              </span>
            </div>
            <div
              style={{
                width: '50%',
                float: 'left',
                fontSize: '16px',
                textAlign: 'right'
              }}
            >
              <span>
                <b>CreateBy:</b>
              </span>
              <span style={{ marginLeft: '10px' }}>{user.englishName}</span>
            </div>
          </div>
          <div style={{ width: '100%', fontSize: '16px' }}>
            {data.dataList.map((r, index) =>
              index === 0 ? (
                <div
                  style={{
                    // border: '1px dashed',
                    padding: '5px'
                  }}
                >
                  <div
                    style={{ width: '100%', marginTop: '5px' }}
                    dangerouslySetInnerHTML={{ __html: r.content }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    // border: '1px dashed',
                    padding: '5px'
                  }}
                >
                  <div style={{ width: '100%', marginTop: '5px' }}>
                    <span>
                      <b>Event:</b>
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                      {_.join(r.events, '；')}
                    </span>
                  </div>
                  <div style={{ width: '100%', marginTop: '5px' }}>
                    <span>
                      <b>Sector:</b>
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                      {_.join(r.sector, '；')}
                    </span>
                  </div>
                  <div style={{ width: '100%', marginTop: '5px' }}>
                    <span>
                      <b>Company:</b>
                    </span>
                    <span style={{ marginLeft: '10px' }}>
                      {_.join(r.company, '；')}
                    </span>
                  </div>
                  <div
                    style={{ width: '100%', marginTop: '5px' }}
                    dangerouslySetInnerHTML={{ __html: r.content }}
                  ></div>
                  <Divider />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  };

  captureAndSaveAsImage = () => {
    const { data, user } = this.props;
    const targetElement = document.getElementById(
      'capture-analyst-daily-element'
    ); // 替换为要截取的元素的 ID
    targetElement.style.position = 'fixed';
    if (targetElement) {
      html2canvas(targetElement, {
        scale: 3,
        scrollX: 0,
        scrollY: 0,
        windowWidth: '400px',
        windowHeight: targetElement.scrollHeight + 'px'
      }).then(canvas => {
        // 将 canvas 转换为图像
        const image = canvas.toDataURL('image/png');
        // 创建一个下载链接
        const a = document.createElement('a');
        a.href = image;
        a.download = `${user.englishName}_${data.date}_daily.png`; // 图像文件的名称
        // 模拟点击下载链接
        a.click();
      });
    }
    targetElement.style.position = null;
  };

  genImgAndUpload = async () => {
    const targetElement = document.getElementById(
      'capture-analyst-daily-element'
    ); // 替换为要截取的元素的 ID
    targetElement.style.position = 'fixed';
    if (targetElement) {
      let htmlstr = `<!DOCTYPE html><html lang="en"><head> <meta charset="UTF-8"></head>
            <body><div id="capture-analyst-daily-element">${targetElement.innerHTML}</div></body></html>`;
      htmlstr = htmlstr.replace('width: 400px', 'width: 100%');
      htmlstr = htmlstr.replaceAll('font-size: 16px', 'font-size: 2.5rem');

      const blob = new Blob([htmlstr], { type: 'text/html' });
      let file2 = new File([blob], `${_.random()}.html`, { type: 'text/html' });
      let formData = new FormData();
      formData.append('file', file2);
      const resp = await fetch(`${config.api.RESEARCH_URL}/file/upload`, {
        method: 'POST',
        body: formData
      }).then(function(response) {
        return response.json();
      });
      try {
        if (resp != null) {
          targetElement.style.position = null;
          return resp.filePath;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  dataURLtoBlob = (dataurl, type) => {
    var binary = atob(dataurl.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: type });
  };

  render() {
    const { closeModal, saveStatus } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        title={`Generate Picture`}
        width={450}
        height={540}
        zIndex={100}
        onCancel={closeModal}
        bodyStyle={{ height: '550px', overflow: 'auto' }}
        footer={[
          <Button type="primary" onClick={this.captureAndSaveAsImage}>
            Generate
          </Button>
        ]}
        closable={true}
      >
        <Spin spinning={saveStatus === 'SAVING'}>
          {this._createReviewPannel()}
        </Spin>
      </Modal>
    );
  }
}
