import React, { Component } from 'react';
import { Drawer } from 'antd';

class DetailDrawer extends Component {
  state = {
    data: this.props.data,
    submitStatus: 'READY'
  };

  _onInputChange = ({ name, value }) => {
    const { data } = this.state;
    const updateData = {
      ...data,
      [name]: value
    };
    this.setState({
      data: updateData,
      changeFlag: true
    });
  };

  render() {
    const { closeDrawer, url } = this.props;
    return (
      <>
        <Drawer
          width={'75%'}
          placement="right"
          onClose={closeDrawer}
          maskClosable={true}
          closable={false}
          visible={true}
          bodyStyle={{ padding: '0px' }}
        >
          <iframe
            title="detail_drawer"
            src={url}
            style={{ width: '100%', height: '99%' }}
          />
        </Drawer>
      </>
    );
  }
}

export default DetailDrawer;
