import React from 'react';
import {
  threeDPFormatter,
  percentFormatter,
  dateFormatter,
  dateTimeFormatter,
  moneyFormatter,
  nullFormatter,
  dateFormatterWithNotGroup
} from 'common/utils/valueFormatters';
import { numberStyle, percentStyle } from 'common/utils/styleChooser';
import EntityMap from 'entities/EntityMap';
import config from 'common/config';
import numeral from 'numeral';
import _ from 'lodash';
import moment from 'moment';
import { getYearByOffset, getQuarterByOffset } from 'common/utils/DateUtils';
import portfolioClient from 'features/portfolio/api/client';

const gptNewsColumns = [
  {
    field: 'Summary',
    headerName: 'Summary',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 800
  },
  {
    field: 'Sentiment',
    headerName: 'Sentiment',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 80,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        利多: '#16ab39',
        利空: 'red',
        NEGATIVE: 'red',
        POSITIVE: '#16ab39'
      }
    }
  },
  {
    field: 'DateTime',
    headerName: 'Date',
    cellClass: 'non-number',
    valueFormatter: nullFormatter,
    width: 140
  },
  {
    field: 'URL',
    headerName: 'Detail',
    cellClass: 'non-number',
    cellRenderer: 'hyperlinkCompent',
    cellRendererParams: {
      hyperlinkName: 'Detail',
      onClickCallback: data => {
        portfolioClient.addNewsReadRecord([data]);
      },
      valueColorFunc: d => (d.isRead ? '#D3D3D3' : ''),
      callbackFields: ['Id', 'Ticker']
    },
    width: 80
  }
];

const axeListGridColumns = [
  {
    field: 'Broker',
    headerName: 'Broker',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'Ticker',
    headerName: 'Ticker',
    enableRowGroup: true,
    cellClass: 'keyword'
  },
  {
    field: 'AvailableQty',
    headerName: 'AvailableQty',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'Notional',
    headerName: 'notional',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'Rate',
    headerName: 'Rate',
    cellClass: 'number',
    sort: 'asc'
  },
  {
    field: 'Name',
    headerName: 'Name',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'ChineseName',
    headerName: 'Name(CN)',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'Date',
    headerName: 'Date',
    valueFormatter: dateFormatter,
    cellClass: 'non-number'
  },
  {
    field: 'Type',
    headerName: 'Type',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'Memo',
    headerName: 'Memo',
    cellClass: 'non-number'
  },
  {
    field: 'Market',
    headerName: 'Market',
    cellClass: 'non-number',
    hide: true
  },
  {
    field: 'SubIndustryName',
    headerName: 'Sector',
    cellClass: 'non-number'
  },
  {
    field: 'SubIndustryCNName',
    headerName: 'Sector(CN)',
    cellClass: 'non-number'
  },
  {
    field: 'SubIndustry',
    headerName: 'SectorCode',
    cellClass: 'non-number',
    hide: true
  }
];

const shortableTickerGridColumns = [
  {
    field: 'Ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    enableRowGroup: true
  },
  {
    field: 'Name',
    headerName: 'Name',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'MS_Avail_Qty',
    headerName: 'MS_Avail_Qty',
    cellClass: 'number'
  },
  {
    field: 'MS_Rate',
    headerName: 'MS_Rate',
    cellClass: 'number'
  },
  {
    field: 'MS_Date',
    headerName: 'MS_Date',
    valueFormatter: dateFormatter
  },
  {
    field: 'CICC_Avail_Qty',
    headerName: 'CICC_Avail_Qty',
    cellClass: 'non-number'
  },
  {
    field: 'CICC_Rate',
    headerName: 'CICC_Rate',
    cellClass: 'non-number'
  },
  {
    field: 'CICC_Date',
    headerName: 'CICC_Date',
    valueFormatter: dateFormatter,
    cellClass: 'non-number'
  },
  {
    field: 'JPM_Avail_Qty',
    headerName: 'JPM_Avail_Qty',
    cellClass: 'number'
  },
  {
    field: 'JPM_Rate',
    headerName: 'JPM_Rate',
    cellClass: 'number'
  },
  {
    field: 'JPM_Date',
    headerName: 'JPM_Date',
    valueFormatter: dateFormatter,
    cellClass: 'non-number'
  },
  {
    field: 'CITIC_Avail_Qty',
    headerName: 'CITIC_Avail_Qty',
    cellClass: 'number'
  },
  {
    field: 'CITIC_Rate',
    headerName: 'CITIC_Rate',
    cellClass: 'number'
  },
  {
    field: 'CITIC_Date',
    headerName: 'CITIC_Date',
    valueFormatter: dateFormatter,
    cellClass: 'non-number'
  },
  {
    field: 'BAML_Avail_Qty',
    headerName: 'BAML_Avail_Qty',
    cellClass: 'number'
  },
  {
    field: 'BAML_Rate',
    headerName: 'BAML_Rate',
    cellClass: 'number'
  },
  {
    field: 'BAML_Date',
    headerName: 'BAML_Date',
    valueFormatter: dateFormatter,
    cellClass: 'non-number'
  }
];

const companyResearchGridColumns = [
  {
    field: 'BbgTicker',
    headerName: 'Bbg Ticker',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true
  },
  {
    field: 'SecName',
    headerName: 'Sec Name',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'TargetPrice1',
    headerName: 'Target Price1',
    valueFormatter: threeDPFormatter,
    cellClass: 'number'
  },
  {
    field: 'TgtPriceProb1',
    headerName: 'Tgt Price Prob1',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'TargetPrice2',
    headerName: 'Target Price2',
    valueFormatter: threeDPFormatter,
    cellClass: 'number'
  },
  {
    field: 'TgtPriceProb2',
    headerName: 'Tgt Price Prob2',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'TargetPrice3',
    headerName: 'Target Price3',
    valueFormatter: threeDPFormatter,
    cellClass: 'number'
  },
  {
    field: 'InvestmentRationale',
    headerName: 'Investment Rationale',
    cellClass: 'non-number'
  },
  {
    field: 'PersonalView',
    headerName: 'Personal View',
    cellClass: 'non-number'
  },
  {
    field: 'RiskFactor',
    headerName: 'Risk Factor',
    cellClass: 'non-number'
  },
  {
    field: 'PMName',
    headerName: 'PM Name',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'ResearchDate',
    headerName: 'Research Date',
    valueFormatter: dateFormatter,
    cellClass: 'non-number'
  },
  {
    field: 'Attachment',
    headerName: 'Attachment',
    cellClass: 'non-number',
    cellRenderer: 'linkComponent',
    cellRendererParams: {
      server: `${config.api.REST_URL}/research/downloadFile`
    }
  },
  {
    field: 'Id',
    headerName: 'Id',
    cellClass: 'non-number',
    hide: true
  }
  // {
  //   field: 'Id',
  //   headerName: 'Operation',
  //   cellRenderer: 'researchOperationComponent'
  // }
];

const brokerScoreGridColumns = [
  {
    field: 'votingMonth',
    headerName: 'Voting Month',
    cellClass: 'non-number',
    enableRowGroup: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'brokerName',
    headerName: 'Broker Name',
    cellClass: 'keyword',
    enableRowGroup: true,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: []
    }
  },
  {
    field: 'totalScore',
    headerName: 'Total Score',
    cellClass: 'number'
  },
  // {
  //   field: 'personalMeeting',
  //   headerName: 'Personal Meeting Score',
  //   cellRenderer: 'ratingBarComponent',
  //   cellClass: 'number'
  // },
  // {
  //   field: 'groupMeeting',
  //   headerName: 'Group Meeting Score',
  //   cellRenderer: 'ratingBarComponent',
  //   cellClass: 'number'
  // },
  // {
  //   field: 'phoneConference',
  //   headerName: 'Phone Conference Score',
  //   cellRenderer: 'ratingBarComponent',
  //   cellClass: 'number'
  // },
  // {
  //   field: 'salesCall',
  //   headerName: 'Sales Call Score',
  //   cellRenderer: 'ratingBarComponent',
  //   cellClass: 'number'
  // },
  {
    field: 'id',
    headerName: 'Id',
    cellClass: 'number',
    hide: true
  }
  // {
  //   field: 'pmName',
  //   headerName: 'PM Name',
  //   cellClass: 'non-number',
  //   enableRowGroup: true
  // }
  // {
  //   field: 'id',
  //   headerName: 'Operation',
  //   cellRenderer: 'researchOperationComponent'
  // }
];

const brokerScoreYearGridColumns = [
  {
    field: 'votingYear',
    headerName: 'Voting Year',
    cellClass: 'non-number',
    enableRowGroup: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'brokerName',
    headerName: 'Broker Name',
    cellClass: 'keyword',
    enableRowGroup: true
  },
  {
    field: 'rank',
    headerName: 'Rank',
    cellClass: 'number'
  },
  {
    field: 'id',
    headerName: 'Id',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'pmName',
    headerName: 'PM Name',
    cellClass: 'non-number',
    enableRowGroup: true
  }
];

const analystComplimentGridColumns = [
  {
    field: 'entry_date',
    headerName: 'Voting Month',
    valueFormatter: dateFormatter,
    cellClass: 'non-number',
    enableRowGroup: true
    // rowGroup: true
  },
  {
    field: 'broker_name',
    headerName: 'Broker Name',
    cellClass: 'keyword',
    enableRowGroup: true,
    // editable: true,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: []
    }
  },
  {
    field: 'analyst_name',
    headerName: 'Analyst Name',
    // editable: true,
    cellClass: 'non-number'
  },
  {
    field: 'sector',
    headerName: 'Sector',
    // editable: true,
    cellClass: 'non-number'
  },
  {
    field: 'reasons',
    headerName: 'Reasons',
    // editable: true,
    cellClass: 'non-number'
    // cellEditor: "agLargeTextCellEditor",
  },
  {
    field: 'id',
    headerName: 'Id',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'pm_name',
    headerName: 'PM Name',
    cellClass: 'non-number'
  }
  // {
  //   field: 'Team',
  //   headerName: 'Team',
  //   cellClass: 'non-number',
  //   hide: true
  // }
  // {
  //   field: 'id',
  //   headerName: 'Operation',
  //   cellRenderer: 'researchOperationComponent'
  // }
];

const boxedPositionGridColumns = [
  {
    field: 'fund',
    headerName: 'Fund',
    cellClass: 'non-number'
  },
  {
    field: 'book',
    headerName: 'Book',
    cellClass: 'non-number'
  },
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true
  },
  {
    field: 'asset_class',
    headerName: 'Asset Class',
    cellClass: 'non-number'
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'number'
  },
  {
    field: 'posn',
    headerName: 'Posn',
    cellClass: 'non-number'
  }
];

const shortListGridColumns = [
  {
    field: 'shortableInfoVO.primaryTicker',
    headerName: 'Primary Ticker',
    cellClass: 'non-number',
    enableRowGroup: true,
    autoHeight: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'shortableInfoVO.ticker',
    headerName: 'Ticker',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'shortableInfoVO.broker',
    headerName: 'Broker',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 80
  },
  {
    field: 'shortableInfoVO.availableQty',
    headerName: 'AvailableQty',
    cellClass: 'number',
    enableRowGroup: true,
    valueFormatter: moneyFormatter,
    width: 100,
    aggFunc: 'sum'
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 70
  },
  {
    field: '',
    headerName: 'MV',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (params.data) {
        const data = params.data;
        var money = data.price * data.shortableInfoVO.availableQty;
        return moneyFormatter({ value: money });
      }
    },
    width: 100
  },
  {
    field: 'shortableInfoVO.rate',
    headerName: 'Rate',
    cellClass: 'number',
    width: 70,
    valueGetter: params => {
      if (!params.node.group) {
        return params.data;
      }
    },
    valueFormatter: params => {
      if (params.data) {
        if (params.data.shortableInfoVO.rate === null) {
          return 'N.A';
        }
        return params.data.shortableInfoVO.rate;
      }
    },
    aggFunc: values => {
      if (values && values.length > 0) {
        let sumQty = 0,
          sumQtyRate = 0;
        values.forEach(element => {
          if (
            element &&
            element.shortableInfoVO &&
            element.shortableInfoVO.rate !== null &&
            element.shortableInfoVO.availableQty !== null
          ) {
            sumQtyRate +=
              element.shortableInfoVO.availableQty *
              element.shortableInfoVO.rate;
            sumQty += element.shortableInfoVO.availableQty;
          }
        });
        if (sumQty > 0) {
          return threeDPFormatter({ value: sumQtyRate / sumQty });
        }
        return 'N.A.';
      }
    }
  },
  {
    field: 'shortableInfoVO.name',
    headerName: 'Name',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'tickerCnName',
    headerName: 'Name(CN)',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 100
  },
  {
    field: 'shortableInfoVO.date',
    headerName: 'Date',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: dateFormatterWithNotGroup
  },
  {
    field: 'shortableInfoVO.typeName',
    headerName: 'Type',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'shortableInfoVO.offShore',
    headerName: 'OffShore',
    cellClass: 'non-number',
    enableRowGroup: true,
    valueFormatter: params => {
      if (params.data) {
        const data = params.value;
        let formatValue;
        if (data === 0) {
          formatValue = 'ONSHORE';
        } else if (data === 1) {
          formatValue = 'OFFSHORE';
        } else if (data === 2) {
          formatValue = 'ALL';
        } else {
          formatValue = 'OFFSHORE';
        }
        return formatValue;
      }
    }
  },
  {
    field: 'subIndustryName',
    headerName: 'Sector',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'subIndustryCNName',
    headerName: 'Sector(CN)',
    cellClass: 'non-number',
    enableRowGroup: true
  },
  {
    field: 'exchange',
    headerName: 'Exchange',
    cellClass: 'non-number',
    enableRowGroup: true,
    hide: true
  }
];

const analystListGridColumns = [
  {
    field: 'votingMonth',
    headerName: 'Voting Month',
    cellClass: 'non-number',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    width: 100
  },
  {
    field: 'brokerName',
    headerName: 'Broker Name',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 100
  },
  {
    field: 'analystName',
    headerName: 'Analyst Name',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 150
  },
  {
    field: 'sector',
    headerName: 'Sector',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 150
  },
  {
    field: 'score',
    headerName: 'Score',
    cellClass: 'number',
    width: 80
  },
  {
    field: 'id',
    headerName: 'Id',
    cellClass: 'number',
    hide: true
  },
  {
    field: 'pmName',
    headerName: 'PM Name',
    cellClass: 'non-number',
    enableRowGroup: true,
    width: 100
  }
];

const monthlyReviewGridColumns = [
  {
    field: 'yearMonth',
    headerName: 'Monthly Review',
    cellRenderer: 'monthlyReviewItemComponent',
    cellClass: 'non-number'
  }
];

const monthlyReviewBenchmarkGridColumns = [
  {
    field: 'ticker',
    headerName: 'Security',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 150
  },
  {
    field: 'tickerName',
    headerName: 'Security Name',
    enableRowGroup: true,
    cellClass: 'non-number'
  },
  {
    field: 'weight',
    headerName: 'Weight',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'mtd',
    headerName: 'MTD Return',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  },
  {
    field: 'ytd',
    headerName: 'YTD Return',
    valueFormatter: percentFormatter,
    cellClass: 'number'
  }
];

const monthlyReviewPortfolioGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 120
  },
  {
    field: 'tickerName',
    headerName: 'Name',
    enableRowGroup: true,
    cellClass: 'non-number',
    width: 250
  },
  {
    field: 'weight',
    headerName: 'Weight',
    valueFormatter: params => {
      if (params.value === undefined || params.value === null) return '';
      if (Math.abs(params.value) < 0.0001) return '0%';
      return numeral(params.value).format('0.00%');
    },
    cellClassRules: numberStyle(),
    width: 80
  },
  {
    field: 'mtd',
    headerName: 'MTD Return',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 80
  },
  {
    field: 'ytd',
    headerName: 'YTD Return',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 80
  }
];

const researchReportGridColumns = [
  {
    field: 'companyCode',
    headerName: 'Research Report',
    cellClass: 'keyword',
    enableRowGroup: true,
    autoHeight: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'companyName',
    headerName: 'Company Name',
    cellClass: 'non-number'
  },
  {
    field: 'gicsName',
    headerName: 'Gics Name',
    cellClass: 'non-number'
  },
  {
    field: 'score',
    headerName: 'Score',
    cellClass: 'number',
    width: 80
  },
  {
    field: 'createBy',
    headerName: 'Create By',
    cellClass: 'non-number'
  },
  {
    field: 'updateTime',
    headerName: 'Submit Time',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  }
];

const blockListGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'non-number',
    enableRowGroup: true,
    autoHeight: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'broker',
    headerName: 'Broker',
    cellClass: 'non-number'
  },
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'non-number'
  },
  {
    field: 'discountOnPrevClose',
    headerName: 'Discount On Prev Close',
    cellClass: 'non-number'
  },
  {
    field: 'shares',
    headerName: 'Shares',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'cnynotional',
    headerName: 'Notional',
    cellClass: 'number',
    valueFormatter: moneyFormatter
  },
  {
    field: 'tradeDate',
    headerName: 'Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  }
];

const meetingRecordGridColumns = [
  {
    field: 'companyCode',
    headerName: 'Company',
    cellClass: 'keyword',
    enableRowGroup: true,
    autoHeight: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'title',
    headerName: 'Interview subject',
    cellClass: 'non-number'
  },
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  }
];

const companyKeyDataTrackColumns = [
  {
    field: 'companyCode',
    headerName: 'Company',
    cellClass: 'keyword',
    enableRowGroup: true,
    autoHeight: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'createBy',
    headerName: 'Create by',
    cellClass: 'non-number'
  },
  {
    field: 'date',
    headerName: 'Create Time',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  }
];

const companyReplayScoreColumns = [
  {
    field: 'companyCode',
    headerName: 'Company',
    cellClass: 'keyword',
    enableRowGroup: true,
    autoHeight: true,
    rowGroup: true,
    hide: true
  },
  {
    field: 'createBy',
    headerName: 'Create by',
    cellClass: 'non-number'
  },
  {
    field: 'date',
    headerName: 'Create Time',
    cellClass: 'non-number',
    valueFormatter: dateFormatter
  }
];

const allExcellentCompanyColumns = [
  {
    field: 'companyCode',
    headerName: 'Company',
    cellClass: 'keyword',
    width: 150
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 80
  },
  {
    field: 'change',
    headerName: 'Change',
    cellClassRules: numberStyle(),
    width: 80
  },
  {
    field: 'changePercent',
    headerName: '% Chg',
    cellClass: 'non-number',
    width: 80,
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  },
  {
    field: 'ytdChangePercent',
    headerName: '% YTD Chg',
    cellClass: 'non-number',
    width: 100,
    valueFormatter: percentFormatter,
    cellClassRules: percentStyle()
  }
];

const pushVotesReturnColumns = [
  {
    field: 'startPriceLong',
    headerName: 'Starting price(L)',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'startPriceShrt',
    headerName: 'Starting price(S)',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'currentLongPrice',
    headerName: 'Last Price(L)',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'currentShrtPrice',
    headerName: 'Last Price(S)',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'pricingDate',
    headerName: 'Pricing Date',
    cellClass: 'non-number',
    width: 70,
    hide: true
  },
  {
    field: 'mtdLongReturn',
    headerName: 'Mtd Return(L) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60,
    hide: true
  },
  {
    field: 'mtdShortReturn',
    headerName: 'Mtd Return(S) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60,
    hide: true
  },
  {
    field: 'mtdTotalCost',
    headerName: 'Mtd Cost %',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 50,
    hide: true
  },
  {
    field: 'mtdTotalReturn',
    headerName: 'Mtd Total Return %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60,
    hide: true
  },
  {
    field: 'ytdLongReturn',
    headerName: 'Ytd Return(L) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60,
    hide: true
  },
  {
    field: 'ytdShortReturn',
    headerName: 'Ytd Return(S) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60,
    hide: true
  },
  {
    field: 'ytdTotalCost',
    headerName: 'Ytd Cost %',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 50,
    hide: true
  },
  {
    field: 'ytdTotalReturn',
    headerName: 'Ytd Total Return %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60,
    hide: true
  }
];

const pushVotesGridColumns = [
  {
    field: 'isObserver',
    headerName: 'Observing Idea',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 80,
    valueGetter: params => {
      let formatValue = 'N';
      if (params.data) {
        const data = params.data;
        if (data.isObserver) {
          formatValue = 'Y';
        }
        return formatValue;
      }
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 60
  },
  {
    field: 'isRead',
    headerName: 'Read',
    cellClass: 'non-number',
    hide: true,
    valueGetter: params => {
      if (params.data) {
        const data = params.data;
        let formatValue;
        if (data.isRead === 0) {
          formatValue = 'N';
        } else if (data.isRead === 1) {
          formatValue = 'Y';
        }
        return formatValue;
      }
    },
    width: 30
  },
  {
    field: 'isActive',
    headerName: 'Status',
    cellClass: 'non-number',
    valueGetter: params => {
      if (params.data) {
        const data = params.data;
        let formatValue;
        if (data.isActive === 0) {
          formatValue = 'CLOSE';
        } else if (data.isActive === 1 && data.status === 'SUBMIT') {
          formatValue = 'OPEN';
        } else if (data.isActive === 1 && data.status !== 'SUBMIT') {
          formatValue = 'DRAFT';
        }
        return formatValue;
      }
    },
    width: 40
  },
  {
    field: 'tickerLong',
    headerName: 'Buy',
    cellClass: 'keyword',
    width: 90
  },
  {
    field: 'tickerNameLong',
    headerName: 'Buy Security',
    cellClass: 'non-number',
    width: 120
  },
  {
    field: 'tickerShort',
    headerName: 'Sell',
    cellClass: 'keyword',
    width: 90
  },
  {
    field: 'tickerNameShort',
    headerName: 'Sell Security',
    cellClass: 'non-number',
    width: 120
  },
  {
    field: 'submitDate',
    headerName: 'Open Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter,
    width: 60
  },
  {
    field: 'targetPrice',
    headerName: 'Target Price',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'grossPosition',
    headerName: 'Gross Position',
    cellClass: 'number',
    valueFormatter: params => {
      if (params.value) {
        return `${params.value}%`;
      }
      return params.value;
    },
    hide: true,
    width: 60
  },
  {
    field: 'expectedDuration',
    headerName: 'Expected close date',
    cellClass: 'non-number',
    width: 80
  },
  {
    field: 'expectedReturnShow',
    headerName: 'Expected return %',
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 70
  },
  {
    field: 'lossLimitShow',
    headerName: 'Loss limit %',
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 80
  },
  {
    field: 'confidenceLevel',
    headerName: 'CL',
    cellClass: 'number',
    width: 60
  },
  {
    field: 'preConfidenceLevel',
    headerName: 'Previous CL',
    cellClass: 'number',
    width: 80
  },
  {
    field: 'suggestedPositionShow',
    headerName: 'SuggestedPosition %',
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 110
  },
  {
    field: 'longReturn',
    headerName: 'Return(L) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60
  },
  {
    field: 'shortReturn',
    headerName: 'Return(S) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60
  },
  {
    field: 'totalCost',
    headerName: 'Cost %',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 50
  },
  {
    field: 'totalReturn',
    headerName: 'Total Return %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60
  },
  ...pushVotesReturnColumns,
  {
    field: 'presentation',
    headerName: 'Presentation',
    cellClass: 'non-number',
    hide: true,
    valueFormatter: params => {
      if (params.data) {
        const data = params.data;
        let formatValue;
        if (data.presentation === 'Y') {
          formatValue = 'WAITING';
        } else if (data.presentation === 'R') {
          formatValue = 'DONE';
        } else {
          formatValue = 'NO';
        }
        return formatValue;
      }
    }
  },
  {
    field: 'createBy',
    headerName: 'Author',
    enableRowGroup: true,
    cellClass: 'non-number',
    width: 70
  },
  {
    field: 'updateTime',
    headerName: 'Update Time',
    cellClass: 'non-number',
    valueFormatter: dateFormatter,
    width: 70
  }
];

export const pushVotesPMGridColumns = [
  {
    field: 'isObserver',
    headerName: 'Observing Idea',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 80,
    valueGetter: params => {
      let formatValue = 'N';
      if (params.data) {
        const data = params.data;
        if (data.isObserver) {
          formatValue = 'Y';
        }
        return formatValue;
      }
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'keyword',
    enableRowGroup: 'true',
    width: 60
  },
  {
    field: 'isRead',
    headerName: 'Read',
    cellClass: 'non-number',
    valueGetter: params => {
      if (params.data) {
        const data = params.data;
        let formatValue;
        if (data.isRead === 0) {
          formatValue = 'N';
        } else if (data.isRead === 1) {
          formatValue = 'Y';
        }
        return formatValue;
      }
    },
    width: 30
  },
  {
    field: 'isActive',
    headerName: 'Status',
    cellClass: 'non-number',
    valueGetter: params => {
      if (params.data) {
        const data = params.data;
        let formatValue;
        if (data.isActive === 0) {
          formatValue = 'CLOSE';
        } else if (data.isActive === 1 && data.status === 'SUBMIT') {
          formatValue = 'OPEN';
        } else if (data.isActive === 1 && data.status !== 'SUBMIT') {
          formatValue = 'DRAFT';
        }
        return formatValue;
      }
    },
    width: 40
  },
  {
    field: 'tickerLong',
    headerName: 'Buy',
    cellClass: 'keyword',
    width: 90
  },
  {
    field: 'tickerNameLong',
    headerName: 'Buy Security',
    cellClass: 'non-number',
    width: 120
  },
  {
    field: 'tickerShort',
    headerName: 'Sell',
    cellClass: 'keyword',
    width: 90
  },
  {
    field: 'tickerNameShort',
    headerName: 'Sell Security',
    cellClass: 'non-number',
    width: 120
  },
  {
    field: 'submitDate',
    headerName: 'Open Date',
    cellClass: 'non-number',
    valueFormatter: dateFormatter,
    width: 60
  },
  {
    field: 'grossPosition',
    headerName: 'Gross Position',
    cellClass: 'number',
    valueFormatter: params => {
      if (params.value) {
        return `${params.value}%`;
      }
      return params.value;
    },
    hide: true,
    width: 60
  },
  {
    field: 'expectedReturnShow',
    headerName: 'Expected return %',
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 80
  },
  {
    field: 'lossLimitShow',
    headerName: 'Loss limit %',
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 80
  },
  {
    field: 'confidenceLevel',
    headerName: 'CL',
    cellClass: 'number',
    width: 60
  },
  {
    field: 'preConfidenceLevel',
    headerName: 'Previous CL',
    cellClass: 'number',
    width: 80
  },
  {
    field: 'suggestedPositionShow',
    headerName: 'SuggestedPosition %',
    valueFormatter: percentFormatter,
    cellClass: 'number',
    width: 110
  },
  {
    field: 'startPrice',
    headerName: 'Starting price',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'targetPrice',
    headerName: 'Target Price',
    cellClass: 'number',
    hide: true,
    width: 70
  },
  {
    field: 'expectedDuration',
    headerName: 'Expected close date',
    cellClass: 'non-number',
    width: 80
  },
  {
    field: 'longReturn',
    headerName: 'Return(L) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60
  },
  {
    field: 'shortReturn',
    headerName: 'Return(S) %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 60
  },
  {
    field: 'totalCost',
    headerName: 'Cost %',
    cellClass: 'number',
    valueFormatter: percentFormatter,
    width: 50
  },
  {
    field: 'totalReturn',
    headerName: 'Total Return %',
    valueFormatter: percentFormatter,
    cellClassRules: numberStyle(),
    width: 70
  },
  ...pushVotesReturnColumns,
  {
    field: 'createBy',
    headerName: 'Author',
    enableRowGroup: true,
    cellClass: 'non-number',
    width: 70
  },
  {
    field: 'updateTime',
    headerName: 'Update Time',
    cellClass: 'non-number',
    valueFormatter: dateFormatter,
    width: 70
  }
];

export const pushVoteReturnGridColumns = [
  {
    headerName: '',

    children: [
      {
        field: 'englishName',
        headerName: 'Author',
        width: 90,
        pinned: 'left'
      },
      {
        field: 'date',
        headerName: 'Pricing Date',
        valueFormatter: dateFormatter,
        width: 80,
        pinned: 'left'
      }
    ]
  },
  {
    headerName: 'MTD',
    headerClass: 'ag-header-group',
    children: [
      {
        field: 'mtdOpenTotal',
        headerName: 'New Idea',
        width: 70
      },
      {
        field: 'mtdClosedTotal',
        headerName: 'Close Idea',
        width: 70
      },
      {
        field: 'mtdWinTotal',
        headerName: 'Win Idea',
        width: 60
      },
      {
        field: 'mtdLossTotal',
        headerName: 'Loss Idea',
        width: 60
      },
      {
        field: 'mtdWinningRate',
        headerName: 'Win Rate %',
        width: 70,
        valueFormatter: percentFormatter,
        cellClassRules: numberStyle()
      },
      {
        field: 'mtdReturnTotal',
        headerName: 'Total Return %',
        width: 90,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      }
    ]
  },
  {
    headerName: 'YTD',
    headerClass: 'ag-header-group',
    children: [
      {
        field: 'ytdTotal',
        headerName: 'Total Idea',
        width: 70
      },
      {
        field: 'ytdActiveTotal',
        headerName: 'Active Idea',
        width: 70
      },
      {
        field: 'ytdWinTotal',
        headerName: 'Win Idea',
        width: 60
      },
      {
        field: 'ytdLossTotal',
        headerName: 'Loss Idea',
        width: 60
      },
      {
        field: 'ytdWinningRate',
        headerName: 'Win Rate %',
        width: 70,
        valueFormatter: percentFormatter,
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdReturnTotal',
        headerName: 'Total Return %',
        width: 90,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdReturnLongTotal',
        headerName: 'Long Return %',
        width: 100,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdReturnShortTotal',
        headerName: 'Short Return %',
        width: 100,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdAvgReturnTotal',
        headerName: 'Avg Total Return %',
        width: 100,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdWinReturnTotal',
        headerName: 'Win Total Return %',
        width: 110,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdLossReturnTotal',
        headerName: 'Loss Total Return %',
        width: 110,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdWinLossRatio',
        headerName: 'P/L Ratio',
        width: 70,
        cellClass: 'number',
        valueFormatter: nullFormatter
      },
      {
        field: 'ytdAvgWinReturnTotal',
        headerName: 'Win Avg Return %',
        width: 90,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      },
      {
        field: 'ytdAvgLossReturnTotal',
        headerName: 'Loss Avg Return %',
        width: 90,
        valueFormatter: percentFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      }
    ]
  }
];

export const pushVoteNoteGridColumns = [
  {
    headerName: 'UpdateTime',
    field: 'updateTime',
    width: 160,
    valueFormatter: dateTimeFormatter,
    sort: 'asc'
  },
  {
    headerName: 'Note',
    field: 'note',
    width: 800,
    wrapText: true,
    autoHeight: true,
    cellStyle: {
      'white-space': 'normal !important;'
    }
  }
];

export const pushVoteResearchLogGridColumns = [
  {
    headerName: 'Log Time',
    field: 'createTime',
    width: 160,
    valueFormatter: dateTimeFormatter,
    sort: 'desc'
  },
  {
    headerName: 'Changes',
    field: 'data',
    width: 800,
    cellRenderer: 'jsonTooltipComponent'
  }
];

export const portfolioPlanGridColumns = [
  {
    field: 'yearMonth',
    headerName: 'Date',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    hide: true
  }
];

export const portfolioPlanDetailGridColumns = [
  {
    field: 'subSector',
    headerName: 'Sub Sector',
    cellClass: 'keyword',
    width: 50,
    cellRenderer: 'agGroupCellRenderer'
  },
  {
    field: 'industryScore',
    headerName: 'Score',
    cellClass: 'number',
    width: 30,
    cellRenderer: 'ratingBarComponent'
    // cellRendererParams: {
    //   colorMap: {
    //     1: 'red',
    //     2: 'red',
    //     3: 'yellow',
    //     4: '#16ab39',
    //     5: '#16ab39'
    //   }
    // }
  },
  {
    field: 'beta',
    headerName: 'Beta',
    cellClass: 'number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: '#16ab39',
        5: '#16ab39'
      }
    }
  },
  {
    field: 'busMode',
    headerName: 'Business Model',
    cellClass: 'number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: '#16ab39',
        5: '#16ab39'
      }
    }
  },
  {
    field: 'policyFactor',
    headerName: 'Policy',
    cellClass: 'number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: '#16ab39',
        5: '#16ab39'
      }
    }
  },
  {
    field: 'fitSentences',
    headerName: 'Model',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'Rationale',
    headerName: 'Rationale',
    cellClass: 'non-number',
    hide: true,
    width: 100
  },
  {
    field: 'Industry Trend',
    headerName: 'Trend',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'investRisk',
    headerName: 'Risk',
    cellClass: 'non-number',
    width: 100
  }
];

export const portfolioPlanCompanyDetailGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword'
  },
  {
    headerName: 'Long-term Factors',
    children: [
      {
        field: 'companyScore',
        headerName: 'Score',
        cellClass: 'number',
        cellRenderer: 'ratingBarComponent',
        cellRendererParams: {
          className: 'blueStar'
        }
      },
      {
        field: 'companyGovern',
        headerName: '公司治理',
        cellClass: 'number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      },
      {
        field: 'competitiveLandscape',
        headerName: '竞争格局',
        cellClass: 'number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      }
    ]
  },
  {
    headerName: 'Short-term Factors',
    children: [
      {
        field: 'companyShortScore',
        headerName: 'Score',
        cellClass: 'number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      },
      {
        field: 'Volume',
        headerName: 'Volume',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            '↑': '#16ab39',
            '—': 'yellow',
            '↓': 'red'
          }
        }
      },
      {
        field: 'Price',
        headerName: 'Price',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            '↑': '#16ab39',
            '—': 'yellow',
            '↓': 'red'
          }
        }
      },
      {
        field: 'Margin',
        headerName: 'Margin',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            '↑': '#16ab39',
            '—': 'yellow',
            '↓': 'red'
          }
        }
      },
      {
        field: 'Valuation',
        headerName: 'Valuation',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            High: '#16ab39',
            Med: 'yellow',
            Low: 'red'
          }
        }
      }
    ]
  }
];

export const companyScoreMyGridColumns = [
  {
    field: 'sector',
    headerName: 'Sector',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    hide: true
  }
];

export const companyScoreOthersGridColumns = [
  {
    field: 'publishDate',
    headerName: 'Publish Date',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    rowGroupIndex: 0
  },
  {
    field: 'createBy',
    headerName: 'Create By',
    enableRowGroup: true,
    hide: true
  }
];

export const companyScoreAllGridColumns = [
  {
    field: 'createBy',
    headerName: 'Create By',
    rowGroup: true,
    enableRowGroup: true,
    hide: true
  },
  {
    field: 'sector',
    headerName: 'Sector',
    cellClass: 'keyword',
    hide: true
  }
];

export const companyScoreDetailGridColumns = [
  {
    field: 'sector',
    headerName: 'Sector',
    cellClass: 'keyword',
    width: 50,
    cellRenderer: 'agGroupCellRenderer',
    cellClassRules: {
      'keyword hasNotes': params => {
        return (
          params.data &&
          (!_.isEmpty(params.data.noteIds) ||
            !_.isEmpty(params.data.attachList))
        );
      },
      keyword: params =>
        params.data &&
        _.isEmpty(params.data.noteIds) &&
        _.isEmpty(params.data.attachList)
    }
  },
  {
    field: 'score',
    headerName: 'Score',
    cellClass: 'non-number',
    width: 40,
    cellRenderer: 'ratingBarComponent'
    // cellRendererParams: {
    //   colorMap: {
    //     1: 'red',
    //     2: 'red',
    //     3: 'yellow',
    //     4: '#16ab39',
    //     5: '#16ab39'
    //   }
    // }
  },
  {
    field: 'beta',
    headerName: 'Beta',
    cellClass: 'non-number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: '#16ab39',
        5: '#16ab39'
      }
    }
  },
  {
    field: 'busModel',
    headerName: 'Business Model',
    cellClass: 'non-number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: '#16ab39',
        5: '#16ab39'
      }
    }
  },
  {
    field: 'policy',
    headerName: 'Policy',
    cellClass: 'non-number',
    width: 30,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'yellow',
        4: '#16ab39',
        5: '#16ab39'
      }
    }
  },
  {
    field: 'model',
    headerName: 'Model',
    cellClass: 'non-number',
    width: 60
  },
  {
    field: 'trend',
    headerName: 'Trend',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'risk',
    headerName: 'Risk',
    cellClass: 'non-number',
    width: 100
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'non-number',
    width: 40,
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        NEW: 'red',
        PUBLISH: '#FF9E28'
      }
    }
  },
  {
    field: 'refresh',
    headerName: 'Refresh',
    hide: true,
    cellClass: 'non-number',
    width: 100
  }
];

export const companyScoreCompanyDetailGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: params => {
      if (
        params.data &&
        ((params.data['oldWhatHappen'] &&
          params.data['oldWhatHappen'] !== params.data['whatHappen']) ||
          (params.data['oldWhatHappenDesc'] &&
            params.data['oldWhatHappenDesc'] !== params.data['whatHappenDesc']))
      ) {
        return 'warn-flash';
      }
      return 'keyword';
    },
    width: 120,
    cellRenderer: 'compareFieldTooltipComponent',
    cellRendererParams: {
      renderParams: [
        { name: 'What Happen', from: 'oldWhatHappen', to: 'whatHappen' },
        {
          name: 'What Happen Desc',
          from: 'oldWhatHappenDesc',
          to: 'whatHappenDesc'
        }
      ]
    },
    cellClassRules: {
      'keyword hasNotes': params => {
        return (
          params.data &&
          (!_.isEmpty(params.data.noteIds) ||
            !_.isEmpty(params.data.attachList))
        );
      },
      keyword: params =>
        params.data &&
        _.isEmpty(params.data.noteIds) &&
        _.isEmpty(params.data.attachList)
    }
  },
  {
    field: 'whatHappen',
    headerName: 'WhatHappen',
    width: 200,
    hide: true
  },
  {
    field: 'name',
    headerName: 'Security',
    width: 200
  },
  {
    headerName: 'Long-term Factors',
    children: [
      {
        field: 'score',
        headerName: 'Score',
        cellClass: 'non-number',
        cellRenderer: 'ratingBarComponent',
        cellRendererParams: {
          className: 'blueStar'
        },
        width: 120
      },
      {
        field: 'management',
        headerName: 'Governance',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 80,
        cellRendererParams: {
          colorMap: {
            0.5: 'red',
            1: 'red',
            1.5: 'red',
            2: 'red',
            2.5: 'red',
            3: 'yellow',
            3.5: '#16ab39',
            4: '#16ab39',
            4.5: '#16ab39',
            5: '#16ab39'
          }
        }
      },
      {
        field: 'competitionScore',
        headerName: 'Competitiveness',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 80,
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      }
    ]
  },
  {
    headerName: 'Short-term Factors',
    children: [
      {
        field: 'shortInvestmentConclusion',
        headerName: 'Investment Conclusion',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 120,
        cellRendererParams: {
          colorMap: {
            Long: '#16ab39',
            Short: 'red',
            Ignore: 'yellow',
            'No action': 'yellow'
          }
        }
      },
      {
        field: 'segment',
        headerName: 'Segment',
        width: 150,
        cellClass: 'keyword'
      },
      {
        field: 'volume',
        headerName: 'Volume',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 55,
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      },
      {
        field: 'price',
        headerName: 'Price',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 50,
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      },
      {
        field: 'margin',
        headerName: 'Margin',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 50,
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      },
      {
        field: 'valuation',
        headerName: 'Valuation',
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        width: 60,
        cellRendererParams: {
          colorMap: {
            1: 'red',
            2: 'red',
            3: 'yellow',
            4: '#16ab39',
            5: '#16ab39'
          }
        }
      }
    ]
  }
];

export const themesColumn = [
  {
    field: 'preDate',
    headerName: 'Date',
    cellClass: 'keyword',
    valueFormatter: dateFormatter
  },
  {
    field: 'themeName',
    headerName: 'Name',
    cellClass: 'keyword'
  },
  {
    headerName: 'Real',
    cellClass: 'keyword',
    children: [
      {
        field: 'accumReturn',
        headerName: 'Accum Return',
        cellClass: 'number',
        cellClassRules: numberStyle(),
        valueFormatter: percentFormatter
      },
      {
        field: 'preRatio',
        headerName: 'Ratio',
        cellClass: 'number',
        valueFormatter: percentFormatter
      },
      {
        field: 'percentile50',
        headerName: 'Percentile 50',
        cellClass: 'number',
        valueFormatter: percentFormatter
      },
      {
        field: 'percentile90',
        headerName: 'Percentile 90',
        cellClass: 'number',
        valueFormatter: percentFormatter
      }
    ]
  },
  {
    headerName: 'History',
    cellClass: 'keyword',
    children: [
      {
        field: 'maxAccumReturn',
        headerName: 'Max Accum Return',
        cellClass: 'number',
        cellClassRules: numberStyle(),
        valueFormatter: percentFormatter
      },
      {
        field: 'maxAccumDays',
        headerName: 'Max Accum Days',
        cellClass: 'number'
      },
      {
        field: 'ratioPreMaxReturn',
        headerName: 'Ratio Pre Max Return',
        cellClass: 'number',
        valueFormatter: percentFormatter
      }
    ]
  }
];
const analystMeetingReconGridColumns = [
  // {
  //   field: 'broker',
  //   headerName: 'Broker Name',
  //   cellClass: 'non-number',
  //   enableRowGroup: true,
  //   rowGroup: true,
  //   hide: true,
  //   width: 100
  // },

  {
    field: 'interactionDate',
    headerName: 'InteractionDate',
    cellClass: 'keyword',
    enableRowGroup: true,
    width: 50
  },
  {
    field: 'interactionSubject',
    headerName: 'InteractionSubject',
    cellClass: 'non-number',
    enableRowGroup: true,
    cellRenderer: 'jsonTooltipComponent',
    width: 150
  },
  {
    field: 'employee',
    headerName: 'AnalystName',
    cellClass: 'keyword',
    hide: true,
    enableRowGroup: true,
    width: 1
  },
  {
    field: 'interactionType',
    headerName: 'InteractionType',
    cellClass: 'non-number',
    enableRowGroup: true,
    hide: true,
    width: 80
  },
  {
    field: 'interactionPurpose',
    headerName: 'InteractionPurpose',
    cellClass: 'non-number',
    width: 80
  }
];
export const analystDailuColumn = [
  {
    field: 'date',
    headerName: 'Date',
    cellClass: 'keyword',
    valueFormatter: params => {
      if (params.value === null) {
        return params.value;
      }

      const formatTime = moment(params.value).format('YYYY-MM-DD');

      if (formatTime === 'Invalid date') {
        return params.value;
      }

      return `${formatTime}(${moment(params.value)
        .locale('en')
        .format('ddd')})`;
    }
  }
];

export const studentResearchManageGridColumns = [
  {
    field: 'ticker',
    headerName: 'Ticker',
    cellClass: 'keyword',
    width: 100
  },
  {
    field: 'stockNewsNum',
    headerName: 'NEWS',
    valueFormatter: nullFormatter,
    cellRenderer: 'tableTooltipComponent',
    cellRendererParams: {
      tooltipTitle: <b style={{ fontSize: '16px' }}>News</b>,
      tooltipField: 'stockNews',
      tooltipColumnDefs: gptNewsColumns,
      tooltipWidth: '1100px',
      tooltipHeight: '390px',
      recordOpenTime: true,
      onPopoverClose: (totalTime, data) => {
        if (totalTime / 1000 >= 2) {
          portfolioClient
            .addNewsReadRecord([{ Ticker: data.ticker }])
            .catch(err => console.log(err));
        }
      }
    },
    cellClass: 'keyword',
    width: 40
  },
  {
    field: 'companyDesc',
    headerName: 'Name',
    cellClass: 'non-number',
    width: 150
  },
  {
    field: 'inCompanyScore',
    headerName: 'Company Score',
    cellClass: 'non-number',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        0: 'red',
        '×': 'red',
        '√': '#16ab39',
        1: '#16ab39'
      }
    },
    valueGetter: params => {
      const value = params.data.inCompanyScore;
      let formatValue = '×';
      if (value === 0) {
        formatValue = '×';
      } else if (value === 1) {
        formatValue = '√';
      }
      return formatValue;
    },
    width: 50
  },
  {
    field: 'inStockPitch',
    headerName: 'Stock Pitch',
    cellClass: 'non-number',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        0: 'red',
        '×': 'red',
        '√': '#16ab39',
        1: '#16ab39'
      }
    },
    valueGetter: params => {
      const value = params.data.inStockPitch;
      let formatValue = '×';
      if (value === 0) {
        formatValue = '×';
      } else if (value === 1) {
        formatValue = '√';
      }
      return formatValue;
    },
    width: 50
  },
  {
    field: 'inPosition',
    headerName: 'Position',
    cellClass: 'non-number',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        0: 'red',
        '×': 'red',
        '√': '#16ab39',
        1: '#16ab39'
      }
    },
    valueGetter: params => {
      const value = params.data.inPosition;
      let formatValue = '×';
      if (value === 0) {
        formatValue = '×';
      } else if (value === 1) {
        formatValue = '√';
      }
      return formatValue;
    },
    width: 50
  },
  {
    field: 'mktValue',
    headerName: 'Mkt Cap($)',
    cellClass: 'number',
    valueFormatter: params => {
      if (params.data.mktValue) {
        const mktValue = params.data.mktValue / 1e6;
        return `${numeral(mktValue).format('0,0')}M`;
      }
    },
    width: 80
  },
  {
    field: 'totalScore',
    headerName: 'Score',
    cellClass: 'non-number',
    cellRenderer: 'tagComponent',
    cellRendererParams: {
      colorMap: {
        1: 'red',
        2: 'red',
        3: 'red',
        4: 'red',
        5: '#16ab39',
        6: '#16ab39'
      }
    },
    width: 50
  },
  {
    field: 'importCompany',
    headerName: 'Core coverage ',
    cellClass: 'non-number',
    cellRenderer: 'switchComponent',
    width: 50
  },
  {
    headerName: 'Predict',
    cellClass: 'non-number',
    groupId: 'companyScoreGroup',
    children: [
      {
        field: 'currentYearPredict',
        headerName: `${getYearByOffset(0)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.currentYearPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'lastYearPredict',
        headerName: `${getYearByOffset(-1)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.lastYearPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'lastQPredict',
        headerName: `${getQuarterByOffset(-1)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.lastQPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'last2QPredict',
        headerName: `${getQuarterByOffset(-2)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.last2QPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'currentQPredict',
        headerName: `${getQuarterByOffset(0)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.currentQPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'nextQPredict',
        headerName: `${getQuarterByOffset(1)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.nextQPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'next2QPredict',
        headerName: `${getQuarterByOffset(2)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.next2QPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 50
      },
      {
        field: 'next3QPredict',
        headerName: `${getQuarterByOffset(3)}e`,
        cellClass: 'non-number',
        columnGroupShow: 'open',
        cellRenderer: 'tagComponent',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.next3QPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 70
      },
      {
        field: 'next4QPredict',
        headerName: `${getQuarterByOffset(4)}e`,
        cellClass: 'non-number',
        cellRenderer: 'tagComponent',
        columnGroupShow: 'open',
        cellRendererParams: {
          colorMap: {
            0: 'red',
            '×': 'red',
            '√': '#16ab39',
            1: '#16ab39'
          }
        },
        valueGetter: params => {
          const value = params.data.next4QPredict;
          let formatValue = '×';
          if (value === 0) {
            formatValue = '×';
          } else if (value === 1) {
            formatValue = '√';
          }
          return formatValue;
        },
        width: 70
      }
    ]
  }
];

export const relPositionGridColumns = [
  {
    field: 'primaryTicker',
    headerName: 'Primary Ticker',
    cellClass: 'keyword',
    width: 150
  },
  {
    field: 'tickerName',
    headerName: 'Name',
    width: 280
  },
  {
    field: 'quantityDirection',
    headerName: 'LSF',
    cellClass: 'non-number',
    width: 100,
    enableRowGroup: true,
    rowGroup: true,
    cellClassRules: {
      long: params => params.value === 'LONG',
      short: params => params.value === 'SHORT',
      flat: params => params.value !== 'LONG' && params.value !== 'SHORT'
    }
  },
  {
    field: 'mv',
    headerName: 'MV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number',
    width: 100
  },

  {
    field: 'gmv',
    headerName: 'GMV %',
    cellRenderer: 'percentBarComponent',
    cellRendererParams: {
      factor: 7
    },
    aggFunc: 'sum',
    enableValue: true,
    cellClass: 'number',
    width: 100
  },
  {
    field: 'ytdPnl',
    headerName: 'YTD PNL%',
    valueFormatter: percentFormatter,
    aggFunc: 'sum',
    enableValue: true,
    cellClassRules: percentStyle(),
    width: 100
  }
];

export const axeListGridColumnMap = EntityMap.fromArray(
  axeListGridColumns,
  'field'
);
export const shortableTickerGridColumnMap = EntityMap.fromArray(
  shortableTickerGridColumns,
  'field'
);
export const companyResearchGridColumnMap = EntityMap.fromArray(
  companyResearchGridColumns,
  'field'
);
export const brokerScoreGridColumnMap = EntityMap.fromArray(
  brokerScoreGridColumns,
  'field'
);
export const brokerScoreYearGridColumnMap = EntityMap.fromArray(
  brokerScoreYearGridColumns,
  'field'
);
export const analystComplimentGridColumnMap = EntityMap.fromArray(
  analystComplimentGridColumns,
  'field'
);
export const boxedPositionGridColumnMap = EntityMap.fromArray(
  boxedPositionGridColumns,
  'field'
);

export const shortListGridColumnMap = EntityMap.fromArray(
  shortListGridColumns,
  'field'
);

export const analystListGridColumnMap = EntityMap.fromArray(
  analystListGridColumns,
  'field'
);

export const monthlyReviewBenchmarkGridColumnMap = EntityMap.fromArray(
  monthlyReviewBenchmarkGridColumns,
  'field'
);

export const monthlyReviewPortfolioGridColumnMap = EntityMap.fromArray(
  monthlyReviewPortfolioGridColumns,
  'field'
);

export const monthlyReviewGridColumnsMap = EntityMap.fromArray(
  monthlyReviewGridColumns,
  'field'
);

export const researchReportGridColumnsMap = EntityMap.fromArray(
  researchReportGridColumns,
  'field'
);

export const blockListGridColumnsMap = EntityMap.fromArray(
  blockListGridColumns,
  'field'
);

export const meetingRecordGridColumnsMap = EntityMap.fromArray(
  meetingRecordGridColumns,
  'field'
);
export const companyKeyDataTrackColumnsMap = EntityMap.fromArray(
  companyKeyDataTrackColumns,
  'field'
);

export const companyReplayScoreColumnsMap = EntityMap.fromArray(
  companyReplayScoreColumns,
  'field'
);

export const allExcellentCompanyColumnsMap = EntityMap.fromArray(
  allExcellentCompanyColumns,
  'field'
);

export const pushVotesGridColumnsMap = EntityMap.fromArray(
  pushVotesGridColumns,
  'field'
);
export const analystMeetingReconGridColumnsMap = EntityMap.fromArray(
  analystMeetingReconGridColumns,
  'field'
);
