import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { getPreviousWorkingDay } from '../../../../common/utils/DateUtils';
import { onShoreMarginGridColumns } from '../GridColumn';
import { Button, DatePicker, Spin } from 'antd';
import client from '../../api/client';
import moment from 'moment';
import _ from 'lodash';

class OnshoreMarginReportGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        columnDefs: onShoreMarginGridColumns,
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),
      autoGroupColumnDef: {
        cellClass: 'keyword',
        width: 40,
        headerName: 'Fund'
        // field: 'pairName',
        // headerName: 'Name',
      },
      loadingStatus: false,
      reportData: [],
      queryParams: {
        date: getPreviousWorkingDay().format('YYYY-MM-DD')
      },
      reviewModalData: {
        show: false,
        selectedData: {}
      }
    };
  }

  componentDidMount() {
    this._loadData();
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  dateOnChange = (date, dateString) => {
    const { queryParams } = this.state;
    this.setState({
      queryParams: {
        ...queryParams,
        date: dateString
      }
    });
  };

  _loadData = () => {
    const { queryParams } = this.state;
    this.setState({
      loadingStatus: true
    });
    client
      .getOnshoreMargin(queryParams)
      .then(resp => {
        this.setState({
          reportData: resp.data ? resp.data : [],
          loadingStatus: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadingStatus: false
        });
      });
  };

  _createQueryHeader = () => {
    const { date } = this.state.queryParams;
    return (
      <div style={{ textAlign: 'right' }}>
        <DatePicker
          style={{ marginRight: '5px', width: '200px' }}
          allowClear={false}
          format={'YYYY-MM-DD'}
          value={moment(date, 'YYYY-MM-DD')}
          size="small"
          onChange={this.dateOnChange}
        />
        <Button type="primary" size="small" onClick={this._loadData}>
          Reload
        </Button>
      </div>
    );
  };

  _createGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { agGridSettings, reportData, loadingStatus } = this.state;
    const screenHeight = window.screen.height;
    const gridDivHeight = `${((screenHeight - 100) / screenHeight) * 100}%`;
    const data = _.isEmpty(reportData)
      ? []
      : _.orderBy(reportData, ['custodian'], ['asc']);
    return (
      <>
        {loadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{
              height: gridDivHeight
            }}
          >
            <AgGridReact
              // properties
              rowData={data}
              {...agGridSettings}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              groupDefaultExpanded={-1}
              animateRows={false}
              deltaRowDataMode={false}
              getContextMenuItems={this._getContextMenuItems}
              // events
              onGridReady={this.onGridReady}
            />
          </div>
        )}
      </>
    );
  };

  _getContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      // {
      //   name: 'Review',
      //   action: () => this._openReviewModal(params.node)
      // }
    ];

    // Add built-in menu items.
    return menuItems;
  };

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {this._createQueryHeader()}
        {this._createGrid()}
      </div>
    );
  }
}

export default OnshoreMarginReportGrid;
