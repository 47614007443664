import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';
import { DIALOG_ACTIVATE_ORDERS } from '../../omsConstants';
import client from '../../api/client';
import { Message } from 'semantic-ui-react';
import OrderValidator from '../../entities/validators/OrderValidator';
import _ from 'lodash';
import { createErrorMsgs } from 'common/utils/ErrorMsgUtils';

class ActivateOrdersDialog extends PureComponent {
  state = {
    submitStatus: 'READY'
  };

  componentDidMount() {
    const { selectedOrders, settings } = this.props;

    const ctx = { user: settings.user, operationType: 'ACTIVATE' };
    const errsByKey = _.fromPairs(
      selectedOrders
        .map(o => [o.refId, OrderValidator.validate(o, ctx)])
        .filter(([key, errs]) => !_.isEmpty(errs))
    );

    const cmds = selectedOrders
      .filter(o => !Object.keys(errsByKey).includes(o.refId))
      .map(o => ({
        refId: o.refId,
        tradeId: o.tradeId
      }));

    const errorMsgs = createErrorMsgs(errsByKey);

    this.setState({
      cmds,
      errorMsgs
    });
  }

  _activateOrders = () => {
    const { cmds } = this.state;
    const { submitDialogSuccess } = this.props;

    if (cmds.length > 0) {
      return client.activateOrders(cmds).then(() => {
        submitDialogSuccess(DIALOG_ACTIVATE_ORDERS, cmds.map(c => c.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._activateOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_ACTIVATE_ORDERS);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    const { errorMsgs = [] } = this.state;

    return (
      <Modal
        width={900}
        maskClosable={false}
        title="Activate Orders"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <p>Are you sure you want to activate the selected orders?</p>
        {!_.isEmpty(errorMsgs) && (
          <Message
            error
            header="The following orders are invalid thus will be filtered out by system"
            list={errorMsgs}
          />
        )}
      </Modal>
    );
  }
}

export default ActivateOrdersDialog;
