import React, { Component } from 'react';
import { getCurrentQuarter } from 'common/utils/DateUtils';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import { Button, Select, Input, Collapse } from 'antd';
import AddCompanyModal from './AddCompanyModal';
import { numberStyle } from 'common/utils/styleChooser';
import { moneyFormatterOrPercentFomatDefaultNull } from 'common/utils/valueFormatters';
import moment from 'moment';
import { industryColumns } from './ReviewGridColumn';
import AddNoteModal from './AddNoteModal';
import { center } from 'react-dom-factories';

const { Panel } = Collapse;
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c.quarterKey}>
      {c.quarterName}
    </Option>
  ));
};

const _createNormalUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class IndustryReview extends Component {
  constructor(props) {
    super(props);
    this.defaultGridApi = {
      marketShare: null,
      wcRev: null,
      otherFinanceIndex: null,
      comments: null
    };
    this.state = {
      happenList: [
        'Preliminary results',
        'Results',
        'News',
        'Expert',
        'Company visit',
        'Event',
        'Other'
      ],
      quarterList: this._initQuarterDate(),
      data: this.props.data,
      gridWrapperStyle: {
        width: '100%',
        height: '300px',
        marginTop: '20px'
      },
      defaultTitleName: {
        marketShare: '市占率历史',
        wcRev: 'W.C./Rev.',
        otherFinanceIndex: '其他财务指标(自选)',
        comments: 'Comments (强弱变化及原因）'
      },
      companyModal: {
        show: false
      },
      syncStatus: 'READY',
      addNoteModalData: {
        show: false,
        data: {}
      },
      notesList: this.props.data.notesList | [],
      notesIndustryPopoverData: {
        show: false,
        content: []
      }
    };
  }

  _initQuarterDate = () => {
    const optionList = [];
    for (let i = 8; i >= -2; i--) {
      const date = moment().add(i, 'Q');
      const item = getCurrentQuarter(date);
      optionList.push(item);
    }
    return optionList;
  };

  _onGridReady = (params, key) => {
    this.defaultGridApi[key] = params.api;
  };

  _createGrid = (data, columns, gridHeight, key, desc) => {
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{
          marginTop: '10px',
          height: `${gridHeight}px`,
          width: '100%'
        }}
      >
        <AgGridReact
          // properties
          columnDefs={columns}
          rowData={data}
          suppressAggFuncInHeader={false}
          animateRows={true}
          enableRangeSelection={false}
          enableCellChangeFlash={true}
          getRowNodeId={data => data.ticker}
          rowSelection="single"
          // events
          onGridReady={params => {
            this._onGridReady(params, desc);
          }}
          onGridColumnsChanged={params => {
            params.api.sizeColumnsToFit();
          }}
          getContextMenuItems={params => {
            return this._getContextMenuItems(params, key, desc);
          }}
          onCellMouseOver={params => {
            return this._onCellMouseDown(params);
          }}
          onCellMouseOut={params => {
            return this._onCellMouseOut(params);
          }}
        />
      </div>
    );
  };

  _initData = (data, companyData, key, index, refresh) => {
    if (!_.isEmpty(companyData)) {
      companyData.forEach(r => {
        const filterData = data.filter(e => e && e.ticker === r.ticker);
        if (_.isEmpty(filterData)) {
          data.push({ ticker: r.ticker, name: r.name, index });
        } else {
          filterData[0].index = index;
          filterData[0].name = r.name;
        }
      });
    }
    if (!_.isEmpty(data)) {
      if (!_.isEmpty(companyData)) {
        data.forEach((r, index) => {
          if (r) {
            const filterData = companyData.filter(e => e.ticker === r.ticker);
            if (_.isEmpty(filterData)) delete data[index];
          }
        });
      } else {
        if (data.length === 1) delete data[0];
      }
    }
    if (this.defaultGridApi[key] && refresh) {
      this.defaultGridApi[key].setRowData(data);
    }
  };

  _spliceData = (data, item) => {
    data.forEach((r, index) => {
      if (r.ticker === item.ticker) {
        data.splice(index, 1);
      }
    });
  };

  _buildCols = columns => {
    const { review } = this.props.data;
    const { quarterList } = this.state;
    const cols = [];
    if (!review.quanter) {
      review.quanter = quarterList[0].quarterKey;
    }
    const { quanter } = review;
    let year = _.toInteger(quanter.substr(0, 4));
    let currentQ = _.toInteger(quanter.substr(5, 1));
    cols.push({
      field: `${quanter}e`,
      headerName: `${year} Q${currentQ}e`
    });
    for (let i = 7; i >= 0; i--) {
      if (currentQ === 1) {
        year = year - 1;
        currentQ = 4;
      } else {
        currentQ = currentQ - 1;
      }

      if (i === 7) {
        cols.push({
          field: `${year}Q${currentQ}e`,
          headerName: `${year} Q${currentQ}e`
        });
      }
      cols.push({
        field: `${year}Q${currentQ}`,
        headerName: `${year} Q${currentQ}`
      });
    }

    this._addCols(cols, columns);
  };

  _addCols = (cols, columns) => {
    cols.forEach(r => {
      const width = r.field.endsWith('e') ? 50 : 40;
      columns.push({
        ...r,
        cellClassRules: numberStyle(),
        editable: true,
        cellEditor: 'agTextCellEditor',
        valueFormatter: moneyFormatterOrPercentFomatDefaultNull,
        needCal: true,
        width,
        lockPinned: r.lockPinned ? true : r.field.endsWith('e'),
        pinned: r.lockPinned || r.field.endsWith('e') ? 'left' : null,
        headerClass: 'text-align-right',
        suppressMenu: true
      });
    });
    columns.forEach(r => {
      r['cellClassRules'] = {
        'non-number': params =>
          !_.isNumber(params.value) && !this.hasNotes(params),
        'non-number hasNotes': params =>
          !_.isNumber(params.value) && this.hasNotes(params),
        'number-zero': params =>
          (params.value === undefined ||
            params.value === null ||
            params.value === 0) &&
          !this.hasNotes(params),
        'number-positive': params => params.value > 0 && !this.hasNotes(params),
        'number-negative': params => params.value < 0 && !this.hasNotes(params),
        'number-zero hasNotes': params =>
          (params.value === undefined ||
            params.value === null ||
            params.value === 0) &&
          this.hasNotes(params),
        'number-positive hasNotes': params =>
          params.value > 0 && this.hasNotes(params),
        'number-negative hasNotes': params =>
          params.value < 0 && this.hasNotes(params)
      };
    });
  };

  hasNotes = params => {
    const notes = params.data ? params.data.notes : null;
    if (_.isEmpty(notes)) return false;
    const currentNotes = notes.filter(r => r.col === params.colDef.field);
    return !_.isEmpty(currentNotes);
  };

  _createMarketPercentGrid = (review, data) => {
    const { marketShare } = review;
    this._initData(marketShare, data.data, 'marketShare', 'marketShare%');
    const columns = industryColumns.map(r => ({ ...r }));
    columns[0].headerName = this.state.defaultTitleName['marketShare'];
    this._buildCols(columns);
    const leng = marketShare ? marketShare.filter(r => r).length : 0;
    const height = marketShare && leng > 1 ? (leng + 1) * 32 : 72;
    return (
      <Panel header="Market share" key="1">
        {this._createGrid(marketShare, columns, height, 1, 'marketShare')}
      </Panel>
    );
  };

  _createWCRevGrid = (review, data) => {
    const { wcRev } = review;
    this._initData(wcRev, data.data, 'wcRev', 'wcRev');
    const columns = industryColumns.map(r => ({ ...r }));
    columns[0].headerName = this.state.defaultTitleName['wcRev'];
    this._buildCols(columns);
    const leng = wcRev ? wcRev.filter(r => r).length : 0;
    const height = wcRev && leng > 1 ? (leng + 1) * 32 : 72;
    return (
      <Panel header="W.C./Rev." key="2">
        {this._createGrid(wcRev, columns, height, 2, 'wcRev')}
      </Panel>
    );
  };

  _createOtherFinalIndexGrid = (review, data) => {
    const { readOnly } = this.props;
    const { otherFinanceIndex, otherFinanceIndexName } = review;
    this._initData(
      otherFinanceIndex,
      data.data,
      'otherFinanceIndex',
      otherFinanceIndexName
    );
    const columns = industryColumns.map(r => ({ ...r }));
    columns[0].headerName = otherFinanceIndexName;
    columns[2].width = columns[1].width + columns[2].width;
    columns[1].hide = true;
    this._buildCols(columns);
    const leng = otherFinanceIndex
      ? otherFinanceIndex.filter(r => r).length
      : 0;
    const height = otherFinanceIndex && leng > 1 ? (leng + 1) * 32 : 72;
    return (
      <Panel header={otherFinanceIndexName} key="3">
        {readOnly ? (
          ''
        ) : (
          <Input
            value={otherFinanceIndexName}
            style={{ marginTop: '10px' }}
            onChange={event => {
              this._onReviewDataChange({
                name: 'otherFinanceIndexName',
                value: event.target.value
              });
            }}
          />
        )}
        {this._createGrid(
          otherFinanceIndex,
          columns,
          height,
          3,
          'otherFinanceIndex'
        )}
      </Panel>
    );
  };

  _createStrengthChangeGrid = (review, data) => {
    const { comments } = review;
    this._initData(comments, data.data, 'comments', 'comments');
    const columns = industryColumns.map(r => ({ ...r }));
    columns[0].headerName = this.state.defaultTitleName.comments;
    columns[2].width = columns[1].width + columns[2].width;
    columns[1].hide = true;
    this._buildCols(columns);
    const leng = comments ? comments.filter(r => r).length : 0;
    const height = comments && leng > 1 ? (leng + 1) * 32 : 72;
    return (
      <Panel header="Comments" key="4">
        {this._createGrid(comments, columns, height, 4, 'comments')}
      </Panel>
    );
  };

  _tabChange = key => {
    if (_.isEmpty(key)) return;
    const gridKey = ['marketShare', 'wcRev', 'otherFinanceIndex', 'comments'];
    const num = _.toNumber(key[key.length - 1]);
    if (this.defaultGridApi[gridKey[num - 1]]) {
      this.defaultGridApi[gridKey[num - 1]].sizeColumnsToFit();
    }
  };

  _createDetailPanel = () => {
    const { data } = this.props;
    const { review } = data;
    return (
      <Collapse defaultActiveKey={['1', '2']} onChange={this._tabChange}>
        {this._createMarketPercentGrid(review, data)}
        {this._createWCRevGrid(review, data)}
        {this._createOtherFinalIndexGrid(review, data)}
        {this._createStrengthChangeGrid(review, data)}
      </Collapse>
    );
  };

  _onCompanyAdd = tickerData => {
    const { onCompanyAdd } = this.props;
    onCompanyAdd(tickerData, this._refreshWcRev);
    this._closeCompanyModal();
  };

  _refreshWcRev = (review, data) => {
    if (this.defaultGridApi) {
      const {
        marketShare,
        wcRev,
        otherFinanceIndex,
        comments,
        otherFinanceIndexName
      } = review;
      this._initData(
        marketShare,
        data.data,
        'marketShare',
        'marketShare%',
        true
      );
      this._initData(wcRev, data.data, 'wcRev', 'wcRev', true);
      this._initData(
        otherFinanceIndex,
        data.data,
        'otherFinanceIndex',
        otherFinanceIndexName,
        true
      );
      this._initData(comments, data.data, 'comments', 'comments', true);
    }
  };

  _createAddCompanyModal = () => {
    const { companyModal } = this.state;

    return (
      companyModal.show && (
        <AddCompanyModal
          {...this.props}
          onSubmit={this._onCompanyAdd}
          closeModal={this._closeCompanyModal}
        />
      )
    );
  };

  _closeCompanyModal = () => {
    this.setState({
      companyModal: {
        show: false
      }
    });
  };

  _addCompany = () => {
    this.setState({
      companyModal: {
        show: true
      }
    });
  };

  _syncData = async () => {
    this.setState({
      syncStatus: 'SYNCING'
    });
    await this.props.syncData();
    try {
      this.defaultGridApi['wcRev'].refreshCells();
    } catch (err) {}
    this.setState({
      syncStatus: 'READY'
    });
  };

  _createOpBtns = () => {
    const { readOnly } = this.props;
    if (!readOnly) {
      return (
        <div style={{ textAlign: 'right', float: 'right', marginTop: '5px' }}>
          <Button
            disabled={this.state.syncStatus !== 'READY'}
            style={{ marginRight: '5px' }}
            type="primary"
            size="small"
            onClick={this._syncData}
          >
            {this.state.syncStatus === 'READY' ? 'Sync' : 'Syncing'}
          </Button>
          <Button type="primary" size="small" onClick={this._addCompany}>
            Add Company
          </Button>
        </div>
      );
    }
  };

  _onReviewDataChange = params => {
    const { onReviewDataChange } = this.props;
    onReviewDataChange(params);
  };

  _closeAddNoteModal = () => {
    this.setState({
      addNoteModalData: {
        show: false,
        data: {},
        errorMsg: null
      }
    });
  };

  _createAddNoteModal = () => {
    const { addNoteModalData } = this.state;
    return (
      addNoteModalData.show && (
        <AddNoteModal
          {...this.props}
          closeModal={this._closeAddNoteModal}
          data={addNoteModalData.data}
          currentData={addNoteModalData.currentData}
          errorMsg={addNoteModalData.errorMsg}
          callback={addNoteModalData.func}
          onSubmit={this._submitNote}
        />
      )
    );
  };

  _createNotesPopover = () => {
    const { notesIndustryPopoverData } = this.state;
    return (
      notesIndustryPopoverData.show &&
      !_.isEmpty(notesIndustryPopoverData.content) && (
        <div
          style={{
            position: 'absolute',
            width: '15%',
            height: '350px',
            backgroundColor: 'black',
            right: '0px',
            top: '10px'
          }}
        >
          <div
            style={{
              marginTop: '20px',
              marginLeft: '10px',
              height: '100%',
              overflow: 'auto',
              color: 'white'
            }}
          >
            <p style={{ textAlign: center }}>
              <b style={{ fontSize: 14 }}>Notes</b>
            </p>
            {notesIndustryPopoverData.content.map(r => {
              return (
                <>
                  <p>
                    <b>{moment(r.updatedTime).format('YYYY-MM-DD HH:mm:ss')}</b>
                  </p>
                  <p
                    style={{
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {r.value}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      )
    );
  };

  _onCellMouseDown = params => {
    const { node, column } = params;
    if (node && column && node.data) {
      const { notes = [] } = node.data;
      const { colDef } = column;
      const filterData = notes.filter(r => r.col === colDef.field);
      if (!_.isEmpty(filterData)) {
        this.setState({
          notesIndustryPopoverData: {
            show: true,
            content: filterData
          }
        });
      }
    }
  };

  _onCellMouseOut = () => {
    this.setState({
      notesIndustryPopoverData: {
        show: false,
        content: []
      }
    });
  };

  _getContextMenuItems = (params, key, desc) => {
    const { readOnly, data } = this.props;
    if (readOnly && !data.id) return [];
    return [
      {
        name: `Notes`,
        action: () => this.addOrUpdateNotes(params, key, desc)
      }
    ];
  };

  addOrUpdateNotes = params => {
    const { addNoteModalData } = this.state;
    const { node, column } = params;
    if (node && column) {
      const { data } = node;
      const { colDef } = column;
      const paramsData = {
        col: colDef.field,
        isOverride: false,
        sourceValue: data[colDef.field]
      };
      const func = () => {
        params.api.refreshCells({ rowNodes: [node] });
      };
      this.setState({
        addNoteModalData: {
          ...addNoteModalData,
          show: true,
          data: paramsData,
          currentData: data,
          func
        }
      });
    }
  };

  _createNotesPopover = () => {
    const { notesIndustryPopoverData } = this.state;
    return (
      notesIndustryPopoverData.show &&
      !_.isEmpty(notesIndustryPopoverData.content) && (
        <div
          style={{
            position: 'absolute',
            width: '15%',
            height: '400px',
            backgroundColor: 'black',
            right: '0px',
            top: '10px'
          }}
        >
          <div
            style={{
              marginTop: '20px',
              marginLeft: '10px',
              height: '100%',
              overflow: 'auto',
              color: 'white'
            }}
          >
            {notesIndustryPopoverData.content.map(r => {
              return (
                <>
                  <p>
                    <b>{moment(r.updatedTime).format('YYYY-MM-DD HH:mm:ss')}</b>
                  </p>
                  <p
                    style={{
                      whiteSpace: 'pre-line'
                    }}
                  >
                    {r.value}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      )
    );
  };

  render() {
    const { readOnly } = this.props;
    const { data } = this.props;
    const { review } = data;
    return (
      <div id="companyScoreIndustryReview">
        <style>
          {`  
                    #companyScoreIndustryReview .ant-collapse {
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                        color: white !important;
                        font-size: 14px;
                        font-variant: tabular-nums;
                        line-height: 1.5715;
                        list-style: none;
                        -webkit-font-feature-settings: 'tnum';
                        font-feature-settings: 'tnum', "tnum";
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9;
                        border-bottom: 0;
                        border-radius: 2px;
                    }

                    #companyScoreIndustryReview .ant-collapse > .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9;
                    }

                    #companyScoreIndustryReview .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                        position: relative;
                        padding: 5px 8px;
                        padding-left: 40px;
                        color: white;
                        line-height: 1.5715;
                        cursor: pointer;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                    }

                    #companyScoreIndustryReview .ant-collapse-content > .ant-collapse-content-box {
                        padding: 0px;
                    }

                    #companyScoreIndustryReview .ant-collapse-content {
                        overflow: hidden;
                        background-color: #2d3436;
                        border-top: 0px solid #d9d9d9;
                    }
                    
                    #companyScoreIndustryReview .text-align-right .ag-header-cell-label {
                      text-align: right;
                      display: block;
                      margin-right: 5px;
                    }

                    #companyScoreIndustryReview .hasNotes {
                      border-left: 3px solid red;
                    }
                `}
        </style>
        <p
          style={{ color: 'white', fontWeight: 'bold' }}
          className="site-page-header"
        >
          [
          <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
            Quarter:
            {readOnly ? (
              <span style={{ marginLeft: '10px' }}>{review.quanter}</span>
            ) : (
              <Select
                className="companyScoreSelect"
                style={{ width: '100px', marginLeft: '10px' }}
                value={review.quanter}
                onChange={value => {
                  this._onReviewDataChange({
                    name: 'quanter',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.quarterList)}
              </Select>
            )}
          </span>{' '}
          ]
          <span style={{ color: '#FF9E28', marginLeft: '5px' }}>
            What happened:
            <Select
              className="whatHappen"
              style={{ width: '200px', marginLeft: '10px' }}
              value={data.whatHappen}
              disabled={readOnly}
              onChange={value => {
                this.props.onIndustryDataChange({
                  name: 'whatHappen',
                  value
                });
              }}
            >
              {_createNormalUIOptions(this.state.happenList)}
            </Select>
            <Input
              value={data.whatHappenDesc}
              readOnly={readOnly}
              placeholder={'Describe this event'}
              style={{ width: '400px', marginLeft: '10px' }}
              onChange={e => {
                this.props.onIndustryDataChange({
                  name: 'whatHappenDesc',
                  value: e.target.value
                });
              }}
            />
          </span>
          {this._createOpBtns()}
        </p>
        {this._createDetailPanel()}
        {this._createAddCompanyModal()}
        {this._createAddNoteModal()}
        {this._createNotesPopover()}
      </div>
    );
  }
}

export default IndustryReview;
