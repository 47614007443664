import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { riskReportInfoColumns } from '../GridColumnMap';
import { AgGridReact } from 'ag-grid-react';
import { DatePicker, Spin, Switch } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import config from 'common/config';

class ReportInfoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: riskReportInfoColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      context: {
        gridComponent: this
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      sideBar: {
        toolPanels: ['columns', 'filters']
      }
    };
  }

  componentDidMount() {
    this.loadReportInfoList();
  }

  loadReportInfoList = () => {
    const { loadReportList, riskReportQueryParams } = this.props;
    loadReportList(riskReportQueryParams);
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
    this.onRowDataChanged();
  };

  onSelectionChanged = () => {
    const { loadReportAttribution, riskReportQueryParams } = this.props;
    const dataMode = riskReportQueryParams.dataMode;
    const selectedRows = this.gridApi.getSelectedRows();
    const selectedKeys = selectedRows.map(r => r.id);
    const selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedNode = selectedNodes[0];
    loadReportAttribution({
      reportId: selectedKeys[0],
      dataMode,
      type: 'Risk'
    });
  };

  dateToInputOnChange = (date, dateString) => {
    const { loadReportList, riskReportQueryParams } = this.props;
    this.props.changeRiskQueryParamsInput({ name: 'date', value: dateString });
    const queryParams = {
      ...riskReportQueryParams,
      date: dateString
    };
    loadReportList(queryParams);
  };

  onRowDataChanged = () => {
    if (this.gridApi) {
      const nodes = this.gridApi.getRenderedNodes();
      const selectedNode = this.selectedNode ? this.selectedNode : nodes[0];
      if (!_.isEmpty(nodes)) {
        nodes.forEach(element => {
          if (element.id === selectedNode.id) {
            this.gridApi.selectNode(element, true);
          }
        });
      }
      this.selectedNode = selectedNode;
    }
  };

  disabledDate = current => {
    if (current) {
      const day = moment(current).day();
      return day === 0 || day === 6;
    }
    return false;
  };

  onSwitchChange = () => {
    const { riskReportQueryParams, loadReportList } = this.props;
    const dataMode = !riskReportQueryParams.dataMode;
    this.props.changeRiskQueryParamsInput({
      name: 'dataMode',
      value: dataMode
    });
    const queryParams = {
      ...riskReportQueryParams,
      dataMode: dataMode
    };
    loadReportList(queryParams);
  };

  openHelpDocument = () => {
    window.open(config.api.RISK_URL + '/reportInfo/viewHelpDocument');
  };

  render() {
    const {
      riskReportInfoLoadingStatus,
      riskReportList,
      riskReportQueryParams,
      className = 'grid-wrapper'
    } = this.props;
    const sortedRows = _.orderBy(
      riskReportList,
      ['fund', 'book'],
      ['asc', 'asc']
    );

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ textAlign: 'right' }}>
          <DatePicker
            style={{ marginRight: '5px', width: '200px' }}
            allowClear={false}
            format={'YYYY-MM-DD'}
            value={moment(riskReportQueryParams.date, 'YYYY-MM-DD')}
            size="small"
            disabledDate={this.disabledDate}
            onChange={this.dateToInputOnChange}
          />
          <Switch
            checked={riskReportQueryParams.dataMode}
            checkedChildren="Gross"
            unCheckedChildren="Aum"
            defaultChecked
            onChange={this.onSwitchChange}
          />
          <QuestionCircleOutlined
            style={{ width: '28px', height: '28px', color: 'white' }}
            onClick={this.openHelpDocument}
          />
        </div>
        {riskReportInfoLoadingStatus ? (
          <div
            style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}
          >
            <Spin />
          </div>
        ) : (
          <div
            className={`ag-theme-balham-dark ${className}`}
            style={{ width: '100%' }}
          >
            <AgGridReact
              // properties
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={sortedRows}
              suppressAggFuncInHeader={true}
              animateRows={true}
              deltaRowDataMode={false}
              enableRangeSelection={true}
              //rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              rowSelection="single"
              sideBar={this.state.sideBar}
              // events
              onSelectionChanged={this.onSelectionChanged}
              onGridReady={this.onGridReady}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ReportInfoGrid;
