import React, { Component } from 'react';
import _ from 'lodash';
import SplitPane from 'react-split-pane';
import { Message } from 'semantic-ui-react';
import { Modal, Button, Spin, Tabs, Badge } from 'antd';
import { DIALOG_MARGIN_EXECUTE_LOCATES } from 'features/oms/omsConstants';
import { AgGridReact } from 'ag-grid-react';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import agGridUtils from '../../../../common/ui/agGridUtils';
import client from '../../api/client';
import {
  cashLocatesTableColumns,
  executeRouteHoldingGridColumns,
  cashLocateQuotaColumns
} from './GridColumnMap';
import { locateGridColumns as locateHistoryGridColumns } from '../order/GridColumnMap';
import Locate from 'features/oms/entities/Locate';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import LocateValidator, {
  CONTRACT_HARD_RULE,
  SOFT_RULE
} from 'features/oms/entities/validators/LocateValidator';
import hyperid from 'hyperid';

const uniqidInstance = hyperid();
class ContractApiLocateDialog extends Component {
  holdingsByTicker = {};
  shortListByTicker = {};
  extraHoldingsByTicker = {};
  pbHoldingsByTicker = {};
  options = {
    custodianMap: {}
  };

  state = {
    isInitialized: true,
    holdings: [],
    pthHoldings: [],
    pbHoldings: [],
    shortList: [],
    routes: [],
    selectedLocate: null,
    selectedLocates: [],
    cashLocates: [],

    submitStatus: 'READY',
    calculating: false,

    holdingsGridSettings: agGridUtils.createSettings({
      columnDefs: executeRouteHoldingGridColumns,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    pbHoldingsGridSettings: agGridUtils.createSettings({
      columnDefs: executeRouteHoldingGridColumns.filter(
        r =>
          ![
            'bookCode',
            'strategy',
            'custodianAccountCode',
            'securityCode',
            'currencyCode',
            'positionTypeCode',
            'loanQtyStart',
            'loanQtyEnd',
            'ctQtyStart',
            'ctQtyEnd',
            'availLoanTxns',
            'availLoanTxnsStr'
          ].includes(r.field)
      ),
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    holdingsGridWrapperStyle: {
      width: '100%',
      height: '390px'
    },
    cashLocatesGridSettings: hotTableUtils.createSettings({
      columns: cashLocatesTableColumns,
      rowHeaders: true,
      contextMenu: {
        items: {
          remove_row: {},
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneLocates(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    }),
    cashLocatesHistoryGridSettings: agGridUtils.createSettings({
      columnDefs: [
        ...locateHistoryGridColumns,
        {
          field: 'maturityDate',
          headerName: 'Maturity Date',
          cellClass: 'non-number',
          width: 120
        },
        {
          field: 'loanType',
          headerName: 'Loan Type',
          cellClass: 'non-number',
          width: 120
        }
      ],
      deltaRowDataMode: false,
      getRowNodeId: null,
      rowGroupPanelShow: 'onlyWhenGrouping',
      groupIncludeTotalFooter: false
    }),
    locatesGridWrapperStyle: {
      width: '100%',
      height: '350px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    cashLocatesDialogData: {
      isOpened: false
    },
    useOmsSrv: this.props.ui.useOmsSrv
  };

  constructor(props) {
    super(props);

    this.hotTblRef = React.createRef();
  }

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { locates, selectedLocate } = this.state;
    if (prevState.locates !== locates) {
      const locate = locates.find(r => r.key === (selectedLocate || {}).key);
      this._setSelectedLocate(locate);
    }
  }

  _setSelectedLocate = locate => {
    const {
      holdingsByTicker,
      extraHoldingsByTicker,
      pbHoldingsByTicker
    } = this;

    if (!locate) {
      this.setState({
        selectedLocate: null,
        holdings: [],
        shortList: [],
        pthHoldings: [],
        pbHoldings: []
      });
      return;
    }

    const {
      fundCode,
      bookCode,
      broker,
      order: { ticker }
    } = locate;

    // Sort the holdings and if has matched fund/book, would be the first one.
    const _holdingSorter = h => {
      if (h.fundCode === fundCode && h.bookCode === bookCode) {
        return 1;
      } else if (h.fundCode === fundCode) {
        return 2;
      } else {
        return 3;
      }
    };

    const _sortExtraInteratee = h => {
      if (
        h.fundCode === fundCode &&
        h.bookCode === bookCode &&
        h.custodianCode === broker
      ) {
        return 1;
      } else if (h.fundCode === fundCode && h.bookCode === bookCode) {
        return 2;
      } else if (h.bookCode === bookCode) {
        return 3;
      } else if (h.fundCode === fundCode) {
        return 4;
      } else if (h.custodianCode === broker) {
        return 5;
      } else {
        return 6;
      }
    };

    const holdings = _.sortBy(holdingsByTicker[ticker] || [], [_holdingSorter]);

    let pthHoldings = _.sortBy(extraHoldingsByTicker[ticker] || [], [
      _sortExtraInteratee
    ]);
    const pbHoldings = pbHoldingsByTicker[ticker] || [];
    if (pthHoldings) {
      pthHoldings = pthHoldings.filter(r => r.positionTypeCode === 'PTH');
      pthHoldings = pthHoldings.map((r, index) => {
        return { key: index, ...r };
      });
    }

    this.setState({
      selectedLocate: locate,
      holdings,
      pthHoldings,
      pbHoldings
    });
  };

  _cloneLocates = (brokers = []) => {
    const { locates, selectedLocate } = this.state;

    const clonedLocates = (_.isEmpty(brokers)
      ? [selectedLocate.broker]
      : brokers
    ).map(b => {
      const key = `${selectedLocate.key}_${uniqidInstance()}`;
      return {
        ...selectedLocate,
        broker: b,
        key
      };
    });

    const row = locates.findIndex(r => r.key === selectedLocate.key);
    const updatedLocates = this._validateLocates([
      ...locates.slice(0, row + 1),
      ...clonedLocates,
      ...locates.slice(row + 1)
    ]);

    this.setState({ locates: updatedLocates });
  };

  _createLocatesGridSettings = custodianCodes => {
    return hotTableUtils.createSettings({
      columns: cashLocatesTableColumns,
      rowHeaders: true,
      contextMenu: {
        items: {
          remove_row: {},
          clone: {
            name: 'Clone row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._cloneLocates(), 100);
            }
          },
          useMMA: {
            name: 'Toggle MMA',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._toggleMMA(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _createLocates = (order, locateInfos, i) => {
    return locateInfos.map((l, j) => {
      return {
        ...Locate.emptyForm(order, l, 'LOCATE'),
        key: `${i}-${j}`,
        sourceQty: l.sourceQty
      };
    });
  };

  _init = () => {
    const { selectedOrders } = this.props;
    if (_.isEmpty(selectedOrders)) return;
    this.setState({
      isInitialized: false
    });
    const inputs = selectedOrders.map(o => ({
      orderRefId: o.refId,
      ticker: o.ticker,
      fundCode: o.fundCode,
      side: o.side,
      useOmsSrv: this.state.useOmsSrv
    }));

    Promise.all([
      client.getLocateOptions(),
      client.calcCashLocateContexts(inputs)
    ])
      .then(
        ([
          { custodians },
          {
            ctxs,
            holdingsByTicker = {},
            shortListByTicker = {},
            extraHoldingsByTicker,
            pbHldsByTicker = {}
          }
        ]) => {
          const custodianMap = _.keyBy(
            custodians.filter(c => c.enableLocate),
            c => c.custodianCode
          );

          // Init some datas into this.
          this.holdingsByTicker = holdingsByTicker;
          this.shortListByTicker = shortListByTicker;
          this.extraHoldingsByTicker = extraHoldingsByTicker;
          this.pbHoldingsByTicker = pbHldsByTicker;
          this.options = {
            custodianMap
          };

          const locates = this._validateLocates(
            _.zip(selectedOrders, ctxs).flatMap(([order, ctx], i) => {
              if (!ctx) return [];
              const { orderInfo, locateInfos } = ctx;
              return this._createLocates(
                { ...order, ...orderInfo },
                locateInfos,
                i
              );
            })
          );

          _.delay(() => {
            this.setState({
              isInitialized: true,
              locates
            });
          }, 150);
        }
      )
      .catch(ex => {
        console.log(ex);
        this.setState({
          submitStatus: 'ERROR',
          locates: [],
          isInitialized: true
        });
      });
  };

  _onSubmit = () => {
    const { submitDialogSuccess } = this.props;
    const { locates } = this.state;

    if (_.isEmpty(locates)) return;

    const validatedLocates = this._validateLocates(locates);
    if (validatedLocates.some(r => !_.isEmpty(r.errors))) {
      this.setState({
        locates: validatedLocates
      });
      return;
    }

    this.setState({ submitStatus: 'SUBMITTING' });

    const locateData = locates.map(r => ({
      ...r,
      venue: 'LOCATE',
      loanType: 'CONTRACT',
      externalInfo: {
        term: r.term,
        feeRate: r.feeRate,
        preRate: r.preRate,
        postRate: r.postRate,
        disRate: r.disRate,
        bidFlag: r.bidFlag,
        exchangeType: r.exchangeType
      }
    }));
    client
      .addLocates(locateData)
      .then(() => {
        this.setState({ submitStatus: 'READY' });
        submitDialogSuccess(DIALOG_MARGIN_EXECUTE_LOCATES, {});
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_MARGIN_EXECUTE_LOCATES);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, locates = [] } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    const count = locates.reduce(
      (prev, r) => prev + _.size(r.errors) + _.size(r.warnings),
      0
    );
    return (
      <Badge key="submitBadge" count={count} style={{ marginRight: '10px' }}>
        {submitBtn}
      </Badge>
    );
  };

  _createLocatesSummaryPanel = () => {
    const { locates, isCalculating } = this.state;
    if (!locates) return <></>;

    const errorCount = locates.filter(r => !_.isEmpty(r.errors)).length;
    const warningCount = locates.filter(r => !_.isEmpty(r.warnings)).length;

    return (
      <div className="summary">
        Totally
        <span className="keyword">{` ${locates.length} `}</span>
        locates,
        <span className="error-keyword">{` ${errorCount} `}</span>
        contain errors,
        <span className="warning-keyword">{` ${warningCount} `}</span>
        contain warnings.
        {isCalculating && <Spin size="small" />}
      </div>
    );
  };

  _createOrderSummaryPanel = () => {
    const { selectedLocate, locates = [] } = this.state;
    if (!selectedLocate) return <></>;

    const {
      fundCode,
      bookCode,
      ticker,
      qty,
      broker,
      order,
      orderRefId
    } = selectedLocate;

    const { leftQuantity = 0, strategy = 'NULL STRATEGY', side } = order || {};
    const aggLocatesQty = locates
      .filter(l => l.orderRefId === orderRefId)
      .reduce((prev, l) => {
        return prev + l.qty;
      }, 0);
    const targetLeftQty = leftQuantity - (aggLocatesQty || 0);
    const sideStyle = ['BUY', 'COVR'].includes(side) ? 'long' : 'short';

    return (
      <div className="summary">
        <span className="comment">{`${fundCode}-${bookCode}-${strategy} `}</span>
        <span className={sideStyle}>{`${side} `}</span>
        <span className="keyword">{`${ticker} `}</span>
        with qty
        <span className={sideStyle}>{` ${qty || 0} `}</span>
        (Order left qty:{' '}
        <span
          className={sideStyle}
        >{` ${targetLeftQty}/${leftQuantity} `}</span>
        ) through
        <span className="comment">{` ${broker || 'NULL BROKER'}`}</span>
      </div>
    );
  };

  _afterLoadLocates = initialLoad => {
    // console.log("HotTableRef_afterLoadRoutes", initialLoad, this.hotTblRef.current);
    if (!this.hotTblRef.current) return;

    const { locates, selectedLocate } = this.state;
    if (selectedLocate) return;
    if (_.isEmpty(locates)) return;

    const hotTbl = this.hotTblRef.current.hotInstance || {};
    hotTbl.selectCell(0, 'broker');
  };

  _validateLocates = locates => {
    const {
      options: { custodianMap },
      holdingsByTicker,
      extraHoldingsByTicker
    } = this;

    return locates.map(l => {
      const {
        order: { ticker }
      } = l;
      const holdings = holdingsByTicker[ticker] || [];
      const extraHoldings = extraHoldingsByTicker[ticker] || [];

      const validateConfig = {
        errors: { ruleType: CONTRACT_HARD_RULE, custodianMap },
        warnings: {
          ruleType: SOFT_RULE,
          holdings,
          custodianMap,
          extraHoldings
        }
      };

      return Object.entries(validateConfig).reduce((prev, [k, ctx]) => {
        const validationResult = LocateValidator.validate(l, ctx);
        const hasValidationResultKey = _.camelCase(`has_${k}`);
        return {
          ...prev,
          [k]: validationResult,
          [hasValidationResultKey]: !_.isEmpty(validationResult)
        };
      }, l);
    });
  };

  _beforeRemoveRow = (row, count, rows, source) => {
    let { locates } = this.state;
    locates = this._validateLocates(
      locates.filter((_, i) => !rows.includes(i))
    );

    this.setState({ locates });

    return false;
  };

  _onLocateSelectionChanged = (row, col, row2, col2) => {
    const { locates, selectedLocate } = this.state;
    const locate = locates[row];
    const selectedLocates = _.slice(locates, row, row2 + 1);
    this.setState({
      selectedLocates
    });
    if (locate === selectedLocate) return;
    this._setSelectedLocate(locate);
  };

  _calcExecutionInfos = (locates, changes) => {
    const inputs = changes
      .map(c => {
        const [row, field] = c;

        const overrideFields = field === 'term' ? ['term'] : [];
        if (_.isEmpty(overrideFields)) return null;

        const r = locates[row];
        const { ticker } = r.order;

        return {
          fundCode: r.fundCode,
          broker: r.broker,
          ticker,
          term: r['term']
        };
      })
      .filter(Boolean);

    if (_.isEmpty(inputs)) return;

    this.setState({ isCalculating: true });
    client
      .locatePricing(inputs)
      .then(results => {
        const data = results.data;
        const updateLocates = locates.map(r => {
          const item = _.findLast(
            data,
            e =>
              e.fundCode === r.fundCode &&
              e.broker === r.broker &&
              e.ticker === r.ticker &&
              e.term === parseInt(r.term)
          );
          if (!item) return { ...r };
          const pricingData = item.pricingData;
          if (item && !_.isEmpty(pricingData)) {
            const pricingDataItem = pricingData[0];
            return {
              ...r,
              bidFlag: pricingDataItem.bidFlag,
              exchangeType: pricingDataItem.exchangeType,
              feeRate: pricingDataItem.feeRate,
              preRate: pricingDataItem.preRate,
              postRate: pricingDataItem.postRate,
              disRate: pricingDataItem.disRate,
              quotaData: item.quotaData,
              pricingData: item.pricingData,
              qty: r.qty ? r.qty : r.sourceQty
            };
          } else if (item && _.isEmpty(pricingData)) {
            return {
              ...r,
              bidFlag: null,
              exchangeType: null,
              feeRate: null,
              preRate: null,
              postRate: null,
              disRate: null,
              quotaData: item.quotaData,
              pricingData: null,
              qty: 0
            };
          }
          return { ...r };
        });
        this.setState({
          locates: updateLocates,
          isCalculating: false
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ isCalculating: false });
      });
  };

  _handleChanges = (changes, notNeedCalExeInfo) => {
    const { locates } = this.state;

    const updatedLocates = this._validateLocates(
      changes.reduce((prev, c) => {
        const [row, field, oldValue, newValue] = c;
        if (oldValue === newValue) return prev;

        return prev.map((l, i) => {
          if (i === row) {
            return {
              ...l,
              [field]: newValue
            };
          }
          return l;
        });
      }, locates)
    );

    this.setState({
      locates: updatedLocates
    });

    // Calc execution infos.
    if (notNeedCalExeInfo) return;
    this._calcExecutionInfos(updatedLocates, changes);
  };

  _beforeCellChange = (changes, source) => {
    // console.log(source, changes);
    // console.log("HotTableRef_beforeCellChange", this.hotTblRef.current);

    const realChanges = changes.filter(
      ([, , oldValue, newValue]) => oldValue !== newValue
    );
    if (!_.isEmpty(realChanges)) this._handleChanges(realChanges);

    return false;
  };

  _beforeColumnSort = (currSortConfigs, destSortConfigs) => {
    const columnSortPlugin = (
      this.hotTblRef.current.hotInstance || {}
    ).getPlugin('columnSorting');
    columnSortPlugin.setSortConfig(destSortConfigs);

    if (_.isEmpty(destSortConfigs)) return false;
    const firstSortConfig = _.first(destSortConfigs);
    if (!firstSortConfig) return false;

    const { locates } = this.state;
    const { column, sortOrder } = firstSortConfig;
    const field = (cashLocatesTableColumns[column] || {}).data;
    const sortedLocates = _.orderBy(locates, [field], [sortOrder]);

    this.setState({ locates: sortedLocates });

    return false;
  };

  _createCashLocatesGrid = () => {
    const {
      cashLocatesGridSettings,
      locatesGridWrapperStyle,
      locates
    } = this.state;

    return (
      !_.isEmpty(locates) && (
        <div style={locatesGridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={locates}
            beforeChange={this._beforeCellChange}
            afterLoadData={this._afterLoadLocates}
            beforeRemoveRow={this._beforeRemoveRow}
            afterSelectionEnd={this._onLocateSelectionChanged}
            beforeColumnSort={this._beforeColumnSort}
            manualColumnResize={true}
            {...cashLocatesGridSettings}
          ></HotTable>
        </div>
      )
    );
  };

  _onHoldingsGridReady = params => {
    this.holdingsGridApi = params.api;

    const COLUMNS_KEY = 'locates-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onPTHHoldingsGridReady = params => {
    this.pthHoldingsGridApi = params.api;

    const COLUMNS_KEY = 'locates-pth-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onPBHoldingsGridReady = params => {
    this.pbHoldingsGridApi = params.api;

    const COLUMNS_KEY = 'locates-pb-holding-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.holdingsGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _createHoldingsGrid = () => {
    const {
      holdingsGridSettings,
      holdingsGridWrapperStyle,
      holdings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={holdings}
          {...holdingsGridSettings}
          // events
          onGridReady={this._onHoldingsGridReady}
          // onSelectionChanged={this.onHoldingSelectionChanged}
        />
      </div>
    );
  };

  _createPTHHoldingsGrid = () => {
    const {
      holdingsGridSettings,
      holdingsGridWrapperStyle,
      pthHoldings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={pthHoldings}
          {...holdingsGridSettings}
          // events
          onGridReady={this._onPTHHoldingsGridReady}
          // onSelectionChanged={this.onHoldingSelectionChanged}
        />
      </div>
    );
  };

  _createPBHoldingsGrid = () => {
    const {
      pbHoldingsGridSettings,
      holdingsGridWrapperStyle,
      pbHoldings
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={pbHoldings}
          {...pbHoldingsGridSettings}
          // events
          onGridReady={this._onPBHoldingsGridReady}
          // onSelectionChanged={this.onHoldingSelectionChanged}
        />
      </div>
    );
  };

  _onShortListGridReady = params => {
    this.shortListGridApi = params.api;

    const COLUMNS_KEY = 'contract-locates-shortList-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.shortListGridSettings.columnDefs,
      COLUMNS_KEY
    );
  };

  _onCreateLocatesClick = () => {
    const {
      options: { custodianMap }
    } = this;
    const brokers = this.shortListGridApi
      .getSelectedRows()
      .map(r => r.broker)
      .filter(b => Object.keys(custodianMap).includes(b));

    this._cloneLocates(brokers);
  };

  _getShortListContextMenuItems = params => {
    if (params.node == null) return [];

    const menuItems = [
      {
        name: 'Create',
        action: () => this._onCreateLocatesClick()
      }
    ];

    return menuItems;
  };

  _createShortListGrid = () => {
    const {
      shortListGridSettings,
      holdingsGridWrapperStyle,
      shortList
    } = this.state;

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={shortList}
          {...shortListGridSettings}
          // events
          onGridReady={this._onShortListGridReady}
          getContextMenuItems={this._getShortListContextMenuItems}
        />
      </div>
    );
  };

  _onLocatesHistoryGridReady = params => {
    const COLUMNS_KEY = 'margin-locates-history-grid-col-state';
    StateSynchronizer.syncGrid(params, locateHistoryGridColumns, COLUMNS_KEY);
  };

  _onLocatesQuotasGridReady = params => {
    const COLUMNS_KEY = 'margin-locates-quotas-grid-col-state';
    StateSynchronizer.syncGrid(params, cashLocateQuotaColumns, COLUMNS_KEY);
  };

  _createErrorsPanel = () => {
    const { selectedLocate } = this.state;
    const { errors = {}, warnings = {} } = selectedLocate || {};

    const errorMsgs = Object.values(errors);
    const warningMsgs = Object.values(warnings);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
        {!_.isEmpty(warningMsgs) && (
          <Message warning list={warningMsgs} style={{ marginTop: '3px' }} />
        )}
      </div>
    );
  };

  _createOrderLocateTab = () => {
    const { isInitialized } = this.state;
    const { selectedOrders } = this.props;
    if (_.isEmpty(selectedOrders)) return;
    return (
      <Spin tip="Initializing..." spinning={!isInitialized}>
        {this._createLocatesSummaryPanel()}
        {this._createOrderSummaryPanel()}
        {this._createCashLocatesGrid()}

        <div style={{ width: '100%', height: '420px', marginTop: '5px' }}>
          <SplitPane
            split="vertical"
            defaultSize="65%"
            style={{ position: 'relative' }}
          >
            <Tabs tabPosition="top" key="LeftTabs">
              <Tabs.TabPane tab="Holdings" key="Holdings">
                {<div>{this._createHoldingsGrid()}</div>}
              </Tabs.TabPane>
              <Tabs.TabPane tab="PB Holdings" key="pbHoldings">
                {<div>{this._createPBHoldingsGrid()}</div>}
              </Tabs.TabPane>
              <Tabs.TabPane tab="PTH Holdings" key="pthHoldings">
                {<div>{this._createPTHHoldingsGrid()}</div>}
              </Tabs.TabPane>
            </Tabs>
            <Tabs tabPosition="top" key="RightTabs">
              <Tabs.TabPane tab="QuotaList" key="QuotaList">
                {<div>{this._createLocatesQuotaGrid()}</div>}
              </Tabs.TabPane>
              <Tabs.TabPane tab="History" key="History">
                {this._createLocatesHistoryGrid()}
              </Tabs.TabPane>
            </Tabs>
          </SplitPane>
        </div>
        {this._createErrorsPanel()}
      </Spin>
    );
  };

  _createLocatesQuotaGrid = () => {
    const { holdingsGridWrapperStyle, selectedLocate } = this.state;

    const data = _.get(selectedLocate, 'quotaData') || [];

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={cashLocateQuotaColumns}
          rowData={data}
          // events
          onGridReady={this._onLocatesQuotasGridReady}
        />
      </div>
    );
  };

  _createLocatesHistoryGrid = () => {
    const {
      cashLocatesHistoryGridSettings,
      holdingsGridWrapperStyle,
      selectedLocate
    } = this.state;

    const locatesHistory = _.get(selectedLocate, 'order.locates') || [];

    return (
      <div
        style={holdingsGridWrapperStyle}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          rowData={locatesHistory}
          {...cashLocatesHistoryGridSettings}
          // events
          onGridReady={this._onLocatesHistoryGridReady}
        />
      </div>
    );
  };

  render() {
    return (
      <Modal
        width={1600}
        maskClosable={false}
        title={`Contract Locates`}
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button
            key="close"
            type="primary"
            onClick={this.closeDialog}
            style={{ marginLeft: '10px' }}
          >
            Close
          </Button>
        ]}
      >
        <style>
          {`
            .ant-tabs-nav .ant-tabs-tab {
              color: black;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          
            .ant-tabs-nav .ant-tabs-tab-active {
              color: #1890ff;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          `}
        </style>
        {this._createOrderLocateTab()}
      </Modal>
    );
  }
}

export default ContractApiLocateDialog;
