import _ from 'lodash';

const REG_CATS_CH_EQ_POSTFIX = /\.(SH|SZ)$/i;
const REG_CATS_HK_EQ_POSTFIX = /\.(HKSZ|HKSH)$/i;
const REG_CATS_CH_INDX_FT_POSTFIX = /\.(CFFEX)$/i;
const REG_CATS_CH_FT_MONTH_YEAR = /(\d{1,2})(\d{2})/i;
const REG_CATS_CH_FT_TYPE = /^(\D*)/i;
const REG_EXCH = /([A-Z1-2]{2}) Equity/i;
const REG_EQTY = /^([^=\s]+) (\w{2}) (Equity)$/i;
const REG_ERR_EQTY = /^([^=\s]+) (\w{1}) (Equity)$/i;
const REG_EQTY_FUT = /^(.+=.+) (\w{2}) (Equity)$/i;
const REG_OPT = /\d{2}\/\d{2}\/\d{2} [CP]/i;

const YellowKey = {
  GOVT: 'Govt',
  CORP: 'Corp',
  MTGE: 'Mtge',
  M_MKT: 'M-Mkt',
  MUNI: 'Muni',
  PFD: 'Pfd',
  EQUITY: 'Equity',
  COMDTY: 'Comdty',
  CURNCY: 'Curncy',
  INDEX: 'Index'
};

const AssetClass = {
  EQTY: 'EQTY',
  EQTY_FUT: 'EQTY_FUT',
  INDEX_FUT: 'INDEX_FUT',
  COMDTY_FUT: 'COMDTY_FUT',
  OPT: 'OPT',
  BOND: 'BOND',
  NOT_SPECIFIED: 'NOT_SPECIFIED'
};

const CATS_CH_FT_TYPE_MAP = new Map([
  ['AG', 'SAI'],
  ['AL', 'AA'],
  ['AU', 'AUA'],
  ['BU', 'BIT'],
  ['CU', 'CU'],
  ['FU', 'FO'],
  ['HC', 'ROC'],
  ['NI', 'XII'],
  ['PB', 'PBL'],
  ['RB', 'RBT'],
  ['RU', 'RT'],
  ['SN', 'XOO'],
  ['WR', '???'],
  ['ZN', 'ZNA'],
  ['A', 'AK'],
  ['B', 'BP'],
  ['BB', '???'],
  ['C', 'AC'],
  ['CS', 'DCS'],
  ['FB', '???'],
  ['I', 'IOE'],
  ['J', 'KEE'],
  ['JD', 'DCE'],
  ['JM', 'CKC'],
  ['L', 'POL'],
  ['M', 'AE'],
  ['P', 'PAL'],
  ['PP', 'PYL'],
  ['V', 'PVC'],
  ['Y', 'SH'],
  ['CF', 'VV'],
  ['FG', 'FGL'],
  ['JR', '???'],
  ['LR', '???'],
  ['MA', 'ZME'],
  ['OI', 'ZRO'],
  ['PM', 'VOO'],
  ['RI', 'IRI'],
  ['RM', 'ZRR'],
  ['RS', 'ZRC'],
  ['SF', 'IRE'],
  ['SM', 'IMR'],
  ['SR', 'CB'],
  ['TA', 'PT'],
  ['WH', 'VN'],
  ['ZC', 'TRC'],
  ['IC', 'FFD'],
  ['IF', 'IFB'],
  ['IH', 'FFB']
]);
const CATS_CH_FT_MINTH_MAP = new Map([
  ['01', 'F'],
  ['02', 'G'],
  ['03', 'H'],
  ['04', 'J'],
  ['05', 'K'],
  ['06', 'M'],
  ['07', 'N'],
  ['08', 'Q'],
  ['09', 'U'],
  ['10', 'V'],
  ['11', 'X'],
  ['12', 'Z']
]);

const REG_C1C2_EQUITY = /(C1|C2) Equity/i;
const REG_H1H2_EQUITY = /(H1|H2) Equity/i;

const convertCatsToBbg = catsTicker => {
  if (!catsTicker) return null;

  if (catsTicker.match(REG_CATS_CH_EQ_POSTFIX))
    return catsTicker.replace(REG_CATS_CH_EQ_POSTFIX, ' CH Equity');

  if (catsTicker.match(REG_CATS_HK_EQ_POSTFIX))
    return catsTicker.replace(REG_CATS_HK_EQ_POSTFIX, ' HK Equity');

  // Otherwise assume its future ticker.
  const catsFutTypeMatches = catsTicker.match(REG_CATS_CH_FT_TYPE);
  if (catsFutTypeMatches) {
    const [catsFutType] = catsFutTypeMatches;
    const bbgFutType = CATS_CH_FT_TYPE_MAP.get(catsFutType);
    const yellowKey = catsTicker.match(REG_CATS_CH_INDX_FT_POSTFIX)
      ? 'Index'
      : 'Comdty';

    const monthYearMatches = catsTicker.match(REG_CATS_CH_FT_MONTH_YEAR);
    if (monthYearMatches) {
      const [, year, month] = monthYearMatches;
      const bbgMonth = CATS_CH_FT_MINTH_MAP.get(month);
      const bbgYear = year.slice(-1);

      return `${bbgFutType}${bbgMonth}${bbgYear} ${yellowKey}`;
    }
  }

  return null;
};

const replaceC1C2Ticker = bbgTicker => {
  if (!bbgTicker) return bbgTicker;
  return bbgTicker.replace(REG_C1C2_EQUITY, 'CH Equity');
};

const parseExch = bbgTicker => {
  if (!bbgTicker) return null;

  const equityMatches = bbgTicker.match(REG_EXCH);
  const [, exch] = equityMatches || [];
  return exch;
};

const parseYellowKey = ticker => {
  if (!ticker) return null;

  const splits = ticker.split(' ');
  return Object.values(YellowKey).find(
    v => v.toUpperCase() === _.last(splits).toUpperCase()
  );
};

const parseAssetClass = bbgTicker => {
  if (!bbgTicker) return AssetClass.NOT_SPECIFIED;

  const yk = parseYellowKey(bbgTicker);
  if (yk === YellowKey.CORP) return AssetClass.BOND;
  if (yk === YellowKey.INDEX) return AssetClass.INDEX_FUT;
  if (yk === YellowKey.COMDTY) return AssetClass.COMDTY_FUT;

  if (REG_EQTY.test(bbgTicker)) return AssetClass.EQTY;
  if (REG_OPT.test(bbgTicker)) return AssetClass.OPT;
  if (REG_EQTY_FUT.test(bbgTicker)) return AssetClass.EQTY_FUT;

  return AssetClass.NOT_SPECIFIED;
};

const isErrorEqty = bbgTicker => {
  return REG_ERR_EQTY.test(bbgTicker);
}

const parseNextMonthFutureTicker = bbgTicker => {
  const i = _.indexOf(bbgTicker, ' ');
  const [prefix, postfix] = [bbgTicker.slice(0, i), bbgTicker.slice(i + 1)];
  const monthYear = prefix.slice(-2);
  const [month, y] = monthYear.split('');
  const months = [...CATS_CH_FT_MINTH_MAP.values()];
  const monthIndex = _.indexOf(months, month);
  const year = Number(y);

  if (!_.isNumber(year) || monthIndex < 0) return bbgTicker;

  const nextMonthIndex = monthIndex + 1 > 11 ? 0 : monthIndex + 1;
  const nextMonth = months[nextMonthIndex];
  const nextYear =
    nextMonthIndex < monthIndex ? (year + 1 > 9 ? 0 : year + 1) : year;

  return `${prefix.slice(0, -2)}${nextMonth}${nextYear} ${postfix}`;
};

const toggleMMATicker = (bbgTicker, mmaTicker) => {
  const exch = parseExch(bbgTicker);
  if (!['CH', 'C1', 'C2', 'HK', 'H1', 'H2'].includes(exch)) return bbgTicker;
  if (!_.isEmpty(mmaTicker) && bbgTicker !== mmaTicker) return mmaTicker;
  return bbgTicker
    .replace(REG_C1C2_EQUITY, 'CH Equity')
    .replace(REG_H1H2_EQUITY, 'HK Equity');
};

const TickerUtils = {
  AssetClass,
  convertCatsToBbg,
  replaceC1C2Ticker,
  parseExch,
  parseAssetClass,
  toggleMMATicker,

  parseNextMonthFutureTicker,
  isErrorEqty
};

export default TickerUtils;
