import React, { Component } from 'react';
import { Tag, Button } from 'antd';
import AddLinkModal from './AddLinkModal';
import _ from 'lodash';
import { companyScoreAttColumns } from '../review/ReviewGridColumn';

class LinkPannel extends Component {
  constructor(props) {
    super(props);

    this._initAttachList();
    this.state = {
      attachList: props.data.attachList,
      linkModal: {
        show: false
      },
      columnDefs: companyScoreAttColumns,
      colorMap: {
        xlsx: 'green',
        xls: 'green',
        ppt: '#cd201f',
        pptx: '#cd201f',
        docx: '#3b5999',
        doc: '#3b5999',
        oneNotes: 'purple'
      }
    };
  }

  _initAttachList = () => {
    const props = this.props;
    if (!props.data.attachList) {
      props.data.attachList = [];
    }
  };

  _removeTags = item => {
    const { attachList } = this.state;
    const index = _.findIndex(attachList, r => r.url === item.url);
    attachList.splice(index, 1);
    this.setState({
      attachList
    });
  };

  _getColor = type => {
    const { colorMap } = this.state;
    return colorMap[type] ? colorMap[type] : 'gray';
  };

  _onLinkClick = r => {
    window.open(r.url);
  };

  _createUploadPannel = () => {
    const { attachList } = this.state;
    const groupByData = _.groupBy(attachList, r => r.yearQuarter);
    return Object.keys(groupByData).map(r => {
      return this._createUploadList(r, groupByData[r]);
    });
  };

  _createUploadList = (yearQuarter, attachList) => {
    const { readOnly } = this.props;
    return (
      <div
        style={{
          maxHeight: '100px',
          minHeight: '10px',
          overflow: 'auto',
          float: 'left',
          width: '90%',
          marginTop: '8px',
          backgroundColor: '#2d3436'
        }}
      >
        <div
          style={{
            marginLeft: '15px',
            width: '85%',
            backgroundColor: '#2d3436'
          }}
        >
          <span style={{ fontSize: '15px', marginRight: '15px' }}>
            {yearQuarter ? `${yearQuarter}:` : ''}
          </span>
          {attachList.map(r => {
            return (
              <Tag
                key={r.url}
                closable={readOnly ? false : true}
                style={{ marginTop: '5px' }}
                onClose={() => this._removeTags(r)}
                color={this._getColor(r.type)}
                onClick={() => this._onLinkClick(r)}
              >
                <a href="#">
                  {r.name.length > 120 ? `${r.name.substr(0, 120)}...` : r.name}
                  {_.isEmpty(r.type) ? '' : `.${r.type}`}
                </a>
              </Tag>
            );
          })}
        </div>
      </div>
    );
  };

  _addLink = () => {
    this._openLinkModal();
  };

  _openLinkModal = () => {
    this.setState({
      linkModal: {
        show: true
      }
    });
  };

  _submitLink = data => {
    const { attachList } = this.state;
    attachList.push(data);
    this.setState(
      {
        attachList
      },
      this._closeLinkModal
    );
  };

  _closeLinkModal = () => {
    this.setState({
      linkModal: {
        show: false
      }
    });
  };

  _createAddLinkModal = () => {
    const { linkModal } = this.state;
    return (
      linkModal.show && (
        <AddLinkModal
          closeModal={this._closeLinkModal}
          submitData={this._submitLink}
        />
      )
    );
  };

  _createAddLinkBtn = () => {
    const { readOnly } = this.props;
    return (
      !readOnly && (
        <div
          style={{
            textAlign: 'right',
            float: 'left',
            width: '10%',
            backgroundColor: '#2d3436'
          }}
        >
          <Button
            style={{ marginLeft: '5px' }}
            size="small"
            type="primary"
            onClick={this._addLink}
          >
            Add Link
          </Button>
        </div>
      )
    );
  };

  attachCellRender = data => {
    return <>{this._createUploadList(data)}</>;
  };

  render() {
    return (
      <div width={'100%'} style={{ border: '1px soild white' }}>
        {this._createUploadPannel()}
        {this._createAddLinkBtn()}
        {this._createAddLinkModal()}
      </div>
    );
  }
}

export default LinkPannel;
