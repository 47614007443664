import React, { Component } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import TickerSearchField from 'common/ui/TickerSearchField';
import client from 'features/order/api/client';
import { Input } from 'antd';
import _ from 'lodash';
import researchClient from '../../../api/client';

class AddCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticker: {
        code: null,
        name: null,
        isPercent: true,
        unList: false
      }
    };
  }

  _onSubmit = () => {
    const { ticker } = this.state;
    if (_.isEmpty(ticker.code)) return;
    this.props.onSubmit(ticker);
  };

  onInputChange = ({ name, value }) => {
    const { ticker } = this.state;
    const updateData = {
      ...ticker,
      [name]: value
    };
    this.setState({
      ticker: updateData
    });
  };

  _getSecurity = ticker => {
    if (!ticker) {
      return;
    }
    this.setState({ isLoadingSecurity: true });
    client
      .getSlimSecurities([{ ticker }])
      .then(([security]) => {
        const { ticker, name } = security;
        this.onInputChange({ name: 'code', value: ticker });
        this.onInputChange({ name: 'name', value: name });
        this._queryStockHolding(ticker);
      })
      .catch(_ => {});
  };

  _queryStockHolding = ticker => {
    if (_.isEmpty(ticker) || !ticker.endsWith('CH Equity')) return;
    const tickerPrefix = ticker.replace(' CH Equity', '');
    researchClient
      .queryStockHolding({ ticker: tickerPrefix })
      .then(resp => {
        const data = resp.data ? resp.data : null;
        if (data) {
          this.onInputChange({
            name: 'marketViewDesc',
            value: `Mutual fund holding/circulating market cap=${_.round(
              data.holdingPct,
              2
            )}%`
          });
        }
      })
      .catch(_ => {});
  };

  onTickerSelect = ticker => {
    this._getSecurity(ticker);
  };

  onTickerInput = value => {
    const { ticker } = this.state;
    const codeValue = value
      ? value.startsWith('[UnList]')
        ? value
        : `[UnList]${value}`
      : '';
    const valueStr = value
      ? value.startsWith('[UnList]')
        ? value.replace('[UnList]', '')
        : value
      : '';
    this.setState({
      ticker: {
        ...ticker,
        code: codeValue,
        name: valueStr
      }
    });
  };

  _createSelectCompany = () => {
    const { ticker } = this.state;
    return (
      <div className={'ui form field'} style={{ height: '40px' }}>
        <div style={{ width: '10%', float: 'left', marginTop: '5px' }}>
          <Checkbox
            value={ticker.unList}
            onChange={e => {
              this.onInputChange({ name: 'unList', value: !ticker.unList });
            }}
          >
            UnList
          </Checkbox>
        </div>
        <div style={{ width: '35%', float: 'left' }}>
          {ticker.unList ? (
            <Input
              style={{ height: '38px' }}
              value={ticker.code}
              onChange={e => {
                this.onTickerInput(e.target.value);
              }}
            />
          ) : (
            <TickerSearchField
              name="ticker"
              value={ticker.code}
              onChange={({ name, value }) => {
                this.onInputChange({ name: 'code', value });
              }}
              selectTicker={this.onTickerSelect}
            />
          )}
        </div>
        {!ticker.unList && (
          <div style={{ width: '54%', float: 'right' }}>
            <Input
              style={{ height: '38px' }}
              value={ticker.name}
              readOnly={true}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        mask={false}
        title={`Add Company, BBG ticker + Press [Tab]`}
        width={800}
        footer={[
          <Button
            size="small"
            type="primary"
            onClick={() => {
              this._onSubmit();
            }}
          >
            Ok
          </Button>,
          <Button
            size="small"
            type="primary"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        ]}
        closable={false}
      >
        {this._createSelectCompany()}
      </Modal>
    );
  }
}

export default AddCompanyModal;
