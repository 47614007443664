import { connect } from 'react-redux';

import PortfolioDashbard from '../components/PortfolioDashbard';
import {
  selectPortfolio,
  selectPositions,
  selectRiskInfos,
  selectUi,
  selectIsPortfolioLoaded,
  selectLiveRiskInfos,
  selectLiveRiskInfoMap,
  selectRiskInfoMap,
  isMobilePhone,
  getEnableTxnFundBooks
} from '../portfolioSelectors';
import { dialogsSelector } from '../../common/commonSelectors';
import { selectSettings } from '../../settings/settingSelectors';
import {
  changeBook,
  sendSubscription,
  openRiskControlModal,
  resetRiskControlModal,
  toggleViewMode,
  toggleCashPosition,
  changeViewDate,
  getPortfolio,
  onPositionSelected
} from '../portfolioActions';

import {
  openDialog,
  closeDialog,
  submitDialogSuccess
} from '../../common/commonActions';

const mapStateToProps = state => {
  const ui = selectUi(state);
  const isPortfolioLoaded = selectIsPortfolioLoaded(state);
  const riskControlModal = selectPortfolio(state).riskControlModal;
  const settings = selectSettings(state);
  const isSettingsLoaded = settings.isLoaded;
  const positions = selectPositions(state);
  const riskInfos = selectRiskInfos(state);
  const riskInfoMap = selectRiskInfoMap(state);

  // Used for TradeModalForm.
  const liveRiskInfos = selectLiveRiskInfos(state);
  const liveRiskInfoMap = selectLiveRiskInfoMap(state);
  const dialogs = dialogsSelector(state);
  const user = selectSettings(state).user;
  const isMobile = isMobilePhone();
  const enableTxnFundBooks = getEnableTxnFundBooks(settings);

  return {
    isLoaded: isPortfolioLoaded && isSettingsLoaded,
    positions,
    riskInfos,
    riskInfoMap,
    ui,
    settings,
    riskControlModal,

    liveRiskInfos,
    liveRiskInfoMap,
    dialogs,
    user,
    isMobile,
    enableTxnFundBooks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeBook: payload => dispatch(changeBook(payload)),
    sendSubscription: payload => dispatch(sendSubscription(payload)),
    openRiskControlModal: () => dispatch(openRiskControlModal()),
    resetRiskControlModal: () => dispatch(resetRiskControlModal()),
    toggleViewMode: () => dispatch(toggleViewMode()),
    toggleCashPosition: () => dispatch(toggleCashPosition()),
    changeViewDate: date => dispatch(changeViewDate(date)),
    getPortfolio: query => dispatch(getPortfolio(query)),

    openDialog: (dialogCode, info) => dispatch(openDialog(dialogCode, info)),
    closeDialog: dialogCode => dispatch(closeDialog(dialogCode)),
    submitDialogSuccess: (dialogCode, info) =>
      dispatch(submitDialogSuccess(dialogCode, info)),
    onPositionSelected: payload => dispatch(onPositionSelected(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioDashbard);
