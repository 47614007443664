import React, { PureComponent } from 'react';
import { Button, Select, Form, Alert } from 'antd';
import { DIALOG_VIEW_HOLDINGS } from '../../omsConstants';
import { AgGridReact } from 'ag-grid-react';
import { holdingGridColumns } from './GridColumnMap';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { moneyFormatter, percentFormatter } from 'common/utils/valueFormatters';
import StateSynchronizer from '../../../../common/utils/StateSynchronizer';
import { isInternalFund } from '../../../../common/utils/DomainUtils';
import EntityMap from 'entities/EntityMap';
import { DraggableModal } from 'ant-design-draggable-modal';

const Option = Select.Option;
const fxPositionTypeCodes = new Set([
  'FWRD_HEDGE',
  'FWRD_PROP',
  'FX_PROP',
  'NOSTRO'
]);
const summaryFieldMap = new Map([
  ['Qty Start', e => (e.primaryTickers.size > 1 ? null : 'quantityStart')],
  // ['MV Start', _ => 'deltaNotnlMktValUsdStart'],
  // [
  //   'MV Start Book %',
  //   e => (e.bookCodes.size > 1 ? null : 'deltaNotnlMktValUsdStartBookPct')
  // ],
  // [
  //   'MV Start Fund %',
  //   e => (e.fundCodes.size > 1 ? null : 'deltaNotnlMktValUsdStartFundPct')
  // ]
  ['Qty', e => (e.primaryTickers.size > 1 ? null : 'quantityEnd')],
  [
    'Qty Theo Start Left',
    e => (e.primaryTickers.size > 1 ? null : 'theoQtyStartLeft')
  ],
  [
    'Qty Theo Close',
    e => (e.primaryTickers.size > 1 ? null : 'theoreticalQtyForClose')
  ],
  ['MV USD', _ => 'notnlMktValUsd'],
  ['MV Book %', e => (e.bookCodes.size > 1 ? null : 'notnlMktValBookPct')],
  ['MV Fund %', e => (e.fundCodes.size > 1 ? null : 'notnlMktValFundPct')]
]);

// Must inherit PureComponent, otherwise holdings grid will be refreshed even when no props are changed.
class ViewHoldingsDialog extends PureComponent {
  state = {
    viewMode: 'Normal',
    selectedHoldingKeys: [],

    columnDefs: holdingGridColumns,
    defaultColDef: {
      enableCellChangeFlash: true,
      minWidth: 25,
      checkboxSelection: agGridUtils.isFirstColumn,
      headerCheckboxSelection: agGridUtils.isFirstColumn,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter'
      // suppressMenu: true
    },
    autoGroupColumnDef: {
      cellClass: 'non-number'
    },
    rowGroupPanelShow: 'always',
    pivotPanelShow: 'always',
    sideBar: {
      toolPanels: ['columns', 'filters']
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_VIEW_HOLDINGS);
  };

  onGridReady = params => {
    this.gridApi = params.api;

    const COLUMNS_KEY = 'holding-grid-col-state';
    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  _calcFxAggrHoldings = holdings => {
    return [
      ...holdings
        .filter(h => fxPositionTypeCodes.has(h.positionTypeCode))
        .reduce((map, e) => {
          const key = `${e.fundCode}-${e.custodianCode}-${e.custodianAccountCode}-${e.positionTypeCode}-${e.ticker}`;
          const item = map.get(key) || {};
          return map.set(key, {
            ...e,
            key,
            quantityStart: e.quantityStart + (item.quantityStart || 0),
            // deltaNotnlMktValUsdStart:
            //   e.deltaNotnlMktValUsdStart + (item.deltaNotnlMktValUsdStart || 0),
            // deltaNotnlMktValUsdStartBookPct: null,
            // deltaNotnlMktValUsdStartFundPct:
            //   e.deltaNotnlMktValUsdFundPct +
            //   (item.deltaNotnlMktValUsdFundPct || 0)
            quantityEnd: e.quantityEnd + (item.quantityEnd || 0),
            theoQtyStartLeft: e.theoQtyStartLeft + (item.theoQtyStartLeft || 0),
            theoreticalQtyForClose:
              e.theoreticalQtyForClose + (item.theoreticalQtyForClose || 0),
            notnlMktValUsd: e.notnlMktValUsd + (item.notnlMktValUsd || 0),
            notnlMktValBookPct: null,
            notnlMktValFundPct:
              e.notnlMktValFundPct + (item.notnlMktValFundPct || 0)
          });
        }, new Map())
        .values()
    ];
  };

  _calcHoldings = () => {
    const holdings = EntityMap.map(this.props.holdingMap);
    const { viewMode } = this.state;
    if (holdings) {
      const filteredHoldings = holdings.filter(h => isInternalFund(h.fundCode));
      switch (viewMode) {
        case 'Normal':
          return filteredHoldings.filter(h => h.positionTypeCode !== 'PTH');
        case 'Restricted':
          return filteredHoldings.filter(
            h => h.quantityStart !== h.quantityStartForClose
          );
        case 'FX Aggregated':
          return this._calcFxAggrHoldings(filteredHoldings);
        case 'PTH':
          return filteredHoldings.filter(h => h.positionTypeCode === 'PTH');
        case 'OTP':
          return filteredHoldings.filter(h => h.loanType === 'OTP');
        case 'CONTRACT':
          return filteredHoldings.filter(h => h.loanType === 'CONTRACT');
        default:
          return [];
      }
    }

    return [];
  };

  _handleViewModeChange = viewMode => {
    this.setState({ viewMode, selectedHoldingKeys: [] });
  };

  getRowNodeId = data => {
    return data.key;
  };

  createHoldingsGrid = holdings => {
    return (
      <div
        style={{ width: '100%', height: '570px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={holdings}
          suppressAggFuncInHeader={true}
          animateRows={true}
          deltaRowDataMode={true}
          enableRangeSelection={true}
          getRowNodeId={this.getRowNodeId}
          rowGroupPanelShow={this.state.rowGroupPanelShow}
          pivotPanelShow={this.state.pivotPanelShow}
          groupDefaultExpanded={-1}
          rowSelection="multiple"
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          sideBar={this.state.sideBar}
          floatingFilter={true}
          // events
          onGridReady={this.onGridReady}
          onSelectionChanged={this.onSelectionChanged}
        />
      </div>
    );
  };

  _calcSummary = holdings => {
    const { selectedHoldingKeys } = this.state;
    if (selectedHoldingKeys && selectedHoldingKeys.length > 1) {
      const summaryData = holdings
        .filter(h => selectedHoldingKeys.includes(h.key))
        .reduce((map, e) => {
          return {
            primaryTickers: new Set([
              ...(map.primaryTickers || []),
              e.primaryTicker
            ]),
            bookCodes: new Set([...(map.bookCodes || []), e.bookCode]),
            fundCodes: new Set([...(map.fundCodes || []), e.fundCode]),

            quantityStart: e.quantityStart + (map.quantityStart || 0),
            // deltaNotnlMktValUsdStart:
            //   e.deltaNotnlMktValUsdStart + (map.deltaNotnlMktValUsdStart || 0),
            // deltaNotnlMktValUsdStartBookPct:
            //   e.deltaNotnlMktValUsdStartBookPct +
            //   (map.deltaNotnlMktValUsdStartBookPct || 0),
            // deltaNotnlMktValUsdStartFundPct:
            //   e.deltaNotnlMktValUsdStartFundPct +
            //   (map.deltaNotnlMktValUsdStartFundPct || 0)
            quantityEnd: e.quantityEnd + (map.quantityEnd || 0),
            theoQtyStartLeft: e.theoQtyStartLeft + (map.theoQtyStartLeft || 0),
            theoreticalQtyForClose:
              e.theoreticalQtyForClose + (map.theoreticalQtyForClose || 0),
            notnlMktValUsd: e.notnlMktValUsd + (map.notnlMktValUsd || 0),
            notnlMktValBookPct:
              e.notnlMktValBookPct + (map.notnlMktValBookPct || 0),
            notnlMktValFundPct:
              e.notnlMktValFundPct + (map.notnlMktValFundPct || 0)
          };
        }, {});

      return [...summaryFieldMap.keys()]
        .map(k => ({ c: k, f: summaryFieldMap.get(k)(summaryData) }))
        .filter(cf => cf.f)
        .map(
          cf =>
            `${cf.c}: ${
              cf.c.includes('%')
                ? percentFormatter({ value: summaryData[cf.f] })
                : moneyFormatter({ value: summaryData[cf.f] })
            }`
        )
        .join(', ');
    }

    return null;
  };

  createSummaryPanel = summary => {
    return (
      <div style={{ marginTop: '5px', textAlign: 'right' }}>
        {summary && <Alert message={summary} type="info" />}
      </div>
    );
  };

  onSelectionChanged = () => {
    const selectedHoldingKeys = this.gridApi.getSelectedRows().map(r => r.key);
    this.setState({ selectedHoldingKeys });
  };

  render() {
    const holdings = this._calcHoldings();
    const summary = this._calcSummary(holdings);

    return (
      <div>
        <DraggableModal
          width={1600}
          maskClosable={false}
          title="View Holdings"
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            <Button key="close" type="primary" onClick={this.closeDialog}>
              Close
            </Button>
          ]}
        >
          <div className="omsDialogOperationBar">
            <div className="rightOperations">
              <Form layout="inline">
                <Form.Item label="View Mode">
                  <Select
                    defaultValue="Normal"
                    style={{ width: 120 }}
                    onChange={this._handleViewModeChange}
                  >
                    <Option value="Normal">Normal</Option>
                    <Option value="Restricted">Restricted</Option>
                    <Option value="PTH">PTH</Option>
                    <Option value="OTP">OTP</Option>
                    <Option value="CONTRACT">CONTRACT</Option>
                    <Option value="FX Aggregated">FX Aggregated</Option>
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>

          {this.createHoldingsGrid(holdings)}
          {this.createSummaryPanel(summary)}
        </DraggableModal>
      </div>
    );
  }
}

export default ViewHoldingsDialog;
