import React, { PureComponent } from 'react';
import { Modal, Button, Spin, Row, Col } from 'antd';
import { DIALOG_FIX_LOG_REVIEW } from '../../omsConstants';
import client from '../../api/client';
import { AgGridReact } from 'ag-grid-react';
import { fixLogReviewColumns } from './GridColumnMap';
import _ from 'lodash';

class FixLogReviewDialog extends PureComponent {
  state = {
    isInitialized: false,
    data: [],
    selectedRow: null,
    defaultColDef: {
      enableCellChangeFlash: true,
      minWidth: 50,
      width: 130,
      sortable: true,
      filter: true,
      resizable: true
    },
    autoGroupColumnDef: {
      cellClass: 'keyword',
      width: 75
    }
  };

  componentDidMount() {
    this._initData();
  }

  _initData = () => {
    const { selectedOrders, routes } = this.props;
    const orderRefIds = selectedOrders.map(r => r.refId);
    if (!_.isEmpty(orderRefIds) && !_.isEmpty(routes)) {
      const routeList = routes.filter(
        r =>
          !['EMSX', 'CICCIMS', 'XUNTOU', 'ATX', 'CATS'].includes(r.venue) &&
          orderRefIds.includes(r.orderRefId)
      );
      this._loadData(routeList);
    }
  };

  _loadData = routes => {
    const routeRefIds = routes.map(r => r.refId);
    client
      .getFixLogs({ routeRefIds })
      .then(resp => {
        const data = [];
        if (!_.isEmpty(resp)) {
          resp.forEach(r => {
            const routeRefId = r.routeRefId;
            const route = _.first(
              routes.filter(item => item.refId === routeRefId)
            );
            if (!_.isEmpty(r.logMsgs)) {
              r.logMsgs.forEach(e => {
                data.push({ routeRefId, ...e, ...route });
              });
            }
          });
        }
        this.setState({
          data,
          isInitialized: true
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isInitialized: true
        });
      });
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_FIX_LOG_REVIEW);
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();

    this.setState({
      selectedRow: selectedRows[0]
    });
  };

  _createDataGrid = () => {
    const { data } = this.state;
    const rowData = _.isEmpty(data)
      ? []
      : _.orderBy(data, ['sendingTime'], ['desc']);
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '300px' }}
      >
        <AgGridReact
          // properties
          columnDefs={fixLogReviewColumns}
          rowData={rowData}
          defaultColDef={this.state.defaultColDef}
          suppressAggFuncInHeader={true}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          animateRows={false}
          deltaRowDataMode={false}
          rowGroupPanelShow="always"
          rowSelection="single"
          suppressCellSelection={true}
          onGridReady={this.onGridReady}
          onSelectionChanged={this.onSelectionChanged}
        />
      </div>
    );
  };

  _createDetailPannel = () => {
    const { selectedRow } = this.state;
    return (
      selectedRow &&
      !_.isEmpty(selectedRow.flds) && (
        <Row gutter={24} style={{ marginTop: '5px' }}>
          {_.orderBy(selectedRow.flds, ['name'], ['asc']).map(r => (
            <>
              <Col span={3}>
                <span className="keyword">{r.name}</span>
              </Col>
              <Col span={5}>{r.value}</Col>
            </>
          ))}
        </Row>
      )
    );
  };

  render() {
    const { isInitialized } = this.state;

    return (
      <Modal
        width={1400}
        maskClosable={false}
        title="View Fix Logs"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin tip="Initializing..." spinning={!isInitialized}>
          {this._createDataGrid()}
          {this._createDetailPannel()}
        </Spin>
      </Modal>
    );
  }
}

export default FixLogReviewDialog;
