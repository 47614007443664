import React, { Component } from 'react';
import BrokerScoreGrid from './BrokerScoreGrid';
import BrokerDialog from './BrokerDialog';
import BrokerSaveDialog from './BrokerSaveDialog';
// import BrokerScoreYearDialog from './BrokerScoreYearDialog';
// import BroderScoreYearGrid from './BrokerScoreYearGrid';
// import { Tabs } from 'antd';

// const TabPane = Tabs.TabPane;
class BrokerTab extends Component {
  loadBrokerScoreListByMonth = (pmName, votingMonth) => {
    this.pmName = pmName;
    this.props.loadCorrespondenceList({ votingMonth: votingMonth });
    this.props.loadBrokerScoreListByScore({
      pmName: pmName,
      votingMonth: votingMonth
    });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {/* <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab="Month" key="1"> */}
        <BrokerScoreGrid
          {...this.props}
          loadBrokerScoreListByMonth={this.loadBrokerScoreListByMonth}
        />
        <BrokerDialog {...this.props} />
        <BrokerSaveDialog
          {...this.props}
          loadBrokerScoreListByMonth={this.loadBrokerScoreListByMonth}
          pmName={this.pmName}
        />
        {/* </TabPane>
          <TabPane tab="Year" key="2">
            <BroderScoreYearGrid {...this.props} />
            <BrokerScoreYearDialog {...this.props} />
          </TabPane>
        </Tabs> */}
      </div>
    );
  }
}

export default BrokerTab;
