import React, { Component } from 'react';
import { Segment, Statistic, Icon } from 'semantic-ui-react';
import { Popover } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import './RiskBar.css';

import client from '../api/client';
import moment from 'moment';
import _ from 'lodash';
import { getPreviousWorkingDay } from 'common/utils/DateUtils';
import { isAlphaCaptureFund } from 'common/utils/DomainUtils';
import {
  moneyFormatter,
  fourPrecisionPercentFormatter
} from 'common/utils/valueFormatters';
import { riskCheckGridColumns } from '../../risk/components/GridColumnMap';
import PxTrackingAlertsGrid from './PxTrackingAlertsGrid';
import { percentStyle } from 'common/utils/styleChooser';

class RiskBar extends Component {
  state = {
    isDownloadingReport: false,
    defaultColDef: {
      enableCellChangeFlash: true,
      minWidth: 75,
      filter: 'agTextColumnFilter',
      sortable: true,
      resizable: true
    },
    fxExpColumnDefs: [
      {
        field: 'key',
        headerName: 'CCY Pair',
        cellClass: 'keyword',
        width: 95
      },
      {
        field: 'exposure',
        headerName: 'Exposure',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        width: 150,
        cellClass: 'number',
        valueFormatter: moneyFormatter
      },
      {
        field: 'ret',
        headerName: 'DTD%',
        // cellRenderer: 'agAnimateShowChangeCellRenderer',
        width: 100,
        cellClass: 'number',
        valueFormatter: fourPrecisionPercentFormatter,
        cellClassRules: percentStyle()
      }
    ]
  };

  openRiskControlModal = () => {
    const { riskInfo } = this.props;
    if (riskInfo && !!riskInfo.riskControlLimit) {
      this.props.openRiskControlModal();
    }
  };

  downloadReport = () => {
    this.setState({
      isDownloadingReport: true
    });

    const { riskInfo } = this.props;
    client
      .downloadReport({
        reportGroup: 'AlphaCapture',
        report: 'PerformanceAnalysis',
        filter: {
          BOOK: riskInfo.bookCode,
          From: moment()
            .subtract(3, 'months')
            .startOf('month')
            .format('YYYY-MM-DD'),
          To: getPreviousWorkingDay().format('YYYY-MM-DD'),
          Fund: riskInfo.fundCode
        },
        format: 'pdf'
      })
      .finally(() => this.setState({ isDownloadingReport: false }));
  };

  _createStatisticItem = (riskInfo, f) => {
    this._buildPxTrackingAlerts(riskInfo, f);
    const fieldValue = riskInfo && f.valueFn && f.valueFn(riskInfo);
    const valueClassName = _.findKey(f.valueClassRules || {}, fn =>
      fn({ value: fieldValue })
    );
    const statisticItem = (
      <Statistic inverted size="mini" color="blue" key={f.field}>
        <Statistic.Value>
          <span className={valueClassName}>{fieldValue}</span>
        </Statistic.Value>
        <Statistic.Label
          style={{
            textTransform: 'none',
            fontSize: '0.9rem',
            fontWeight: '500'
          }}
        >
          {(riskInfo && f.labelFn && f.labelFn(riskInfo)) || f.label}
        </Statistic.Label>
      </Statistic>
    );

    if (f.field === 'dtdFxRet') {
      const fxExposureGrid = this._createFxExposureGrid(riskInfo);
      return (
        <Popover title="Fx Exposures" content={fxExposureGrid}>
          {statisticItem}
        </Popover>
      );
    }

    if (f.field === 'riskCheckStatus') {
      const riskCheckGrid = this._createRiskCheckGrid(riskInfo);
      const fieldValue = f.valueFn(riskInfo);
      const className =
        fieldValue === 'FAIL'
          ? 'flash'
          : fieldValue === 'WARN'
          ? 'warn-flash'
          : '';
      return (
        <Popover title="Risk Checks" content={riskCheckGrid}>
          <div className={className}>{statisticItem}</div>
        </Popover>
      );
    }

    if (f.field === 'pxTrackingAlerts') {
      const pxTrackingAlertsGrid = this._createPxTrackingAlertsGrid(riskInfo);
      const fieldValue = f.valueFn(riskInfo);
      const className = fieldValue > 0 ? 'warn-flash' : '';
      return (
        <Popover
          title="Price alerts
        (These are stocks you can Tapping the ball, please trade or change the 
        price alert point.)"
          content={pxTrackingAlertsGrid}
        >
          <div className={className}>{statisticItem}</div>
        </Popover>
      );
    }

    return statisticItem;
  };

  _getWinFlag = (data, chgPct) => {
    const { upPct, downPct } = data;
    if (data.quantityDirection === 'LONG') {
      return chgPct * 100 >= upPct;
    } else if (data.quantityDirection === 'SHORT') {
      return -chgPct * 100 >= downPct;
    }
    return false;
  };

  _buildPxTrackingAlerts = (riskInfo, f) => {
    if (!f || f.field !== 'pxTrackingAlerts' || _.isEmpty(riskInfo)) return;
    const { pxTrackingAlerts } = riskInfo;
    const data = pxTrackingAlerts || [];
    let dataList = data.map(r => {
      const chgPct = r.lastPrice / r.basePrice - 1;
      return {
        ...r,
        chgPct,
        absChgPct: Math.abs(chgPct),
        winFlag: this._getWinFlag(r, chgPct)
      };
    });
    dataList = !_.isEmpty(dataList)
      ? dataList.filter(r => r.winFlag || ['T60'].includes(r.bookCode))
      : [];
    riskInfo['pxTrackingAlerts'] = dataList;
  };

  _createRiskCheckGrid = riskInfo => {
    const riskCheckResults = riskInfo.riskCheckResults || [];
    return (
      <div
        style={{ width: '750px', height: '300px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={riskCheckGridColumns}
          defaultColDef={this.state.defaultColDef}
          rowData={riskCheckResults}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={false}
          getRowNodeId={data => data.checkType}
          rowSelection="single"
          suppressCellSelection={true}
        />
      </div>
    );
  };

  _createFxExposureGrid = riskInfo => {
    const fxExposures = riskInfo.riskFx || [];

    return (
      <div
        style={{ width: '355px', height: '500px' }}
        className={`ag-theme-balham-dark grid-wrapper`}
      >
        <AgGridReact
          // properties
          columnDefs={this.state.fxExpColumnDefs}
          defaultColDef={this.state.defaultColDef}
          rowData={fxExposures}
          suppressAggFuncInHeader={true}
          animateRows={false}
          deltaRowDataMode={true}
          getRowNodeId={data => data.key}
          rowSelection="single"
          suppressCellSelection={true}
        />
      </div>
    );
  };

  _createPxTrackingGridMenu = () => {
    // const contextMenus = [
    //   {
    //     name: 'Update Up&Down',
    //     action: () => {}
    //   },
    //   {
    //     name: 'Remove Alerts',
    //     action: () => {}
    //   },
    //   'separator',
    //   {
    //     name: 'Gen Trades',
    //     action: () => {}
    //   }
    // ];
    return;
  };

  onPxTrackingReady = params => {
    this.manageGridApi = params.api;
    this.manageColumnApi = params.columnApi;

    this.manageGridApi.sizeColumnsToFit();
  };

  _updatePxTrackingUpDown = () => {};

  _createPxTrackingAlertsGrid = riskInfo => {
    const { positions } = this.props;
    const pxTrackingAlerts = riskInfo.pxTrackingAlerts || [];
    const data = pxTrackingAlerts.map(r => ({ ...r }));
    const pairPxTrackingAlerts = riskInfo.pairPxTrackingAlerts || [];
    const pairPxTrackingAlertsCopy = pairPxTrackingAlerts.map(r => ({ ...r }));
    return (
      <PxTrackingAlertsGrid
        {...this.props}
        data={data}
        positions={positions}
        pairPxTrackingAlerts={pairPxTrackingAlertsCopy}
      />
    );
  };

  _showRiskColumn = (f, riskInfo) => {
    if (!riskInfo) return false;

    // if (f.field === 'riskCheckStatus') {
    //   return f.valueFn(riskInfo) !== 'PASS';
    // }

    const { fundCode, bookCode } = riskInfo;
    if (
      ['CPTF', 'CACF'].includes(fundCode) &&
      !['P190401'].includes(bookCode)
    ) {
      return !['maxDD', 'currDD'].includes(f.field);
    }

    if (['ZJNF'].includes(fundCode) && bookCode.charAt(0) !== 'W') {
      return !['maxDD', 'currDD'].includes(f.field);
    }

    return !['relMaxDD', 'relCurrDD', 'relYtdRet'].includes(f.field);
  };

  render() {
    const { riskInfo, riskBarColumns } = this.props;
    const statisticItems = riskBarColumns
      .filter(f => this._showRiskColumn(f, riskInfo))
      .map(f => this._createStatisticItem(riskInfo, f));

    return (
      <Segment
        // onClick={this.openRiskControlModal}
        // className={riskInfo && !!riskInfo.riskControlLimit ? 'flash' : ''}
        inverted
        style={{
          marginBottom: '2px',
          textAlign: 'center',
          marginTop: '1px'
        }}
      >
        {statisticItems.map((item, i) => (
          <div className="statisticItem" key={i}>
            {item}
          </div>
        ))}

        {riskInfo && isAlphaCaptureFund(riskInfo.fundCode) && (
          <div style={{ paddingLeft: '10px', paddingTop: '10px' }}>
            {this.state.isDownloadingReport ? (
              <Icon loading name="spinner" />
            ) : (
              <Icon name="download" onClick={this.downloadReport} />
            )}
          </div>
        )}
      </Segment>
    );
  }
}

export default RiskBar;
