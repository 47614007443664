import React, { PureComponent } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import { DIALOG_APPROVE_ORDERS } from '../../omsConstants';
import client from '../../api/client';

class ApproveOrdersDialog extends PureComponent {
  state = {
    submitStatus: 'READY'
  };

  _approveOrders = () => {
    const { user } = this.props.settings;
    const { selectedOrders, submitDialogSuccess } = this.props;

    const cmds = selectedOrders
      .filter(o => !['APPROVE', 'APPROVING'].includes(o.traderStatus))
      .map(o => ({
        refId: o.refId,
        tradeId: o.tradeId,
        traderCode: user.englishName
      }));

    if (cmds.length > 0) {
      return client.approveOrders(cmds).then(results => {
        submitDialogSuccess(DIALOG_APPROVE_ORDERS, results.map(r => r.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._approveOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_APPROVE_ORDERS);
  };

  render() {
    const { submitStatus: status } = this.state;

    const { selectedOrders } = this.props;
    const approvedOrderRefIds = selectedOrders
      .filter(o => ['APPROVE', 'APPROVING'].includes(o.traderStatus))
      .map(o => o.refId)
      .join(',');

    return (
      <div>
        <Modal size="tiny" open={true}>
          <Header content="Approve Orders" />
          <Modal.Content>
            <p>Are you sure you want to approve the selected orders?</p>
            <p>
              {approvedOrderRefIds &&
                `Note: Some orders are already approved: ${approvedOrderRefIds} which will be filtered out by system.`}
            </p>
          </Modal.Content>
          <Modal.Actions>
            {
              {
                SUBMITTING: (
                  <Button secondary disabled loading content="Submitting..." />
                ),
                ERROR: (
                  <Button
                    secondary
                    content="Fail - Retry?"
                    onClick={this._onSubmit}
                  />
                ),
                READY: (
                  <Button secondary content="Yes" onClick={this._onSubmit} />
                )
              }[status]
            }

            <Button secondary content="No" onClick={this.closeDialog} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default ApproveOrdersDialog;
