import React, { Component } from 'react';
import { Button, Modal, Select } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import SwitchComponent from 'common/ui/renderers/agGrid/SwitchComponent';

const Option = Select.Option;
export default class CustomPushUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      userList: [],
      selectedData: null,
      columnDefs: [
        {
          field: 'userName',
          headerName: 'User',
          cellClass: 'keyword',
          width: 100
        },
        {
          field: 'isDefault',
          headerName: 'Default',
          cellClass: 'non-number',
          width: 50,
          valueFormatter: params => {
            if (params.data) {
              const data = params.data;
              let formatValue = data.isDefault;
              if (formatValue === 1) {
                formatValue = 'Y';
              } else {
                formatValue = 'N';
              }
              return formatValue;
            }
          }
        },
        {
          field: 'isActive',
          headerName: 'Active',
          cellRenderer: 'switchComponent',
          cellRendererParams: { onChange: this._onToggleActive },
          cellClass: 'non-number',
          width: 70
          // valueFormatter: params => {
          //     if (params.data) {
          //       const data = params.data;
          //       let formatValue = data.isActive;
          //       if (formatValue === '0') {
          //         formatValue = 'N';
          //       } else if (formatValue === '1') {
          //         formatValue = 'Y';
          //       }
          //       return formatValue;
          //     }
          // }
        }
      ],
      selectedUserList: []
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _onToggleActive = async (v, data) => {
    if (data.isDefault === 1) return;
    const updatedData = { ...data, isActive: v ? 1 : 0 };
    client
      .updateConfig(updatedData)
      .then(resp => {
        this._loadData();
      })
      .catch(err => console.log(err));
  };

  _loadData = () => {
    Promise.all([client.getCustomConfig(), client.getConfigUserList()])
      .then(([resp, reps1]) => {
        this.setState({
          data: resp.data,
          userList: reps1.data
        });
      })
      .catch(err => console.log(err));
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();

    this.setState({
      selectedData: selectedRows[0]
    });
  };

  _createConfigGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const dt = data ? data : [];
    return (
      <div className={className} style={{ height: '95%' }}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '100%' }}
        >
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={dt}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            getRowNodeId={data => data.id}
            rowSelection="single"
            frameworkComponents={{
              switchComponent: SwitchComponent
            }}
            onGridReady={this._onGridReady}
          />
        </div>
      </div>
    );
  };

  _onInputChange = value => {
    this.setState({
      selectedUserList: value
    });
  };

  onOkBtnClick = () => {
    const { selectedUserList } = this.state;
    client
      .addConfig({ userList: selectedUserList })
      .then(resp => {
        this._loadData();
      })
      .catch(err => console.log(err));
  };

  _onAddBtnClick = () => {
    const { userList } = this.state;
    Modal.confirm({
      title: 'Select User',
      content: (
        <Select
          onChange={data => {
            this._onInputChange(data);
          }}
          mode="multiple"
          style={{ marginLeft: '5px', width: '300px' }}
          allowClear={true}
          optionFilterProp="label"
        >
          {userList.map(t => (
            <Option
              value={t.userAccountId}
              key={t.userAccountId}
              label={t.userName}
            >
              {t.userName}
            </Option>
          ))}
        </Select>
      ),
      onOk: this.onOkBtnClick
    });
  };

  _createOpBar = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button size="small" type="primary" onClick={this._onAddBtnClick}>
          Add
        </Button>
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        title={`Push List`}
        width={650}
        height={650}
        onCancel={closeModal}
        onOk={closeModal}
        bodyStyle={{ height: '600px', overflow: 'auto' }}
        closable={true}
        footer={[]}
      >
        {this._createOpBar()}
        {this._createConfigGrid()}
      </Modal>
    );
  }
}
