import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { Select } from 'antd';

const { Option } = Select;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class DashbordSubDataGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),
      autoGroupColumnDef: {
        cellClass: 'keyword',
        lockPinned: true
        // field: 'pairName',
        // headerName: 'Name',
      },
      data: this.props.data ? this.props.data : []
    };
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  _onRangeSelectionChanged = () => {
    this.props.onRowSelected(this.gridApi.getSelectedRows());
  };

  _createGrid = () => {
    const { agGridSettings } = this.state;
    const { data, columns } = this.props.config;
    const rowData = data ? data : [];
    return (
      <div className={`ag-theme-balham-dark`} style={{ flexGrow: 1 }}>
        <AgGridReact
          // properties
          {...agGridSettings}
          rowData={rowData}
          columnDefs={columns}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          deltaRowDataMode={true}
          getRowNodeId={data => data.Broker}
          enableRangeSelection={true}
          onSelectionChanged={this._onRangeSelectionChanged}
          // events
          onGridReady={this.onGridReady}
        />
      </div>
    );
  };

  _createOPBar = () => {
    const { mode, showColumns, optionFilter } = this.props.params;
    return (
      <div style={{ textAlign: 'right' }}>
        <Select
          style={{ marginRight: '5px', width: '250px' }}
          value={optionFilter}
          size="small"
          mode="multiple"
          onChange={value => {
            this.props.onInputChange({
              name: 'optionFilter',
              value: value
            });
          }}
        >
          {_createUIOptions(
            this.props.config.options ? this.props.config.options : []
          )}
        </Select>

        <Select
          style={{ marginRight: '5px', width: '150px' }}
          value={mode}
          size="small"
          onChange={value => {
            this.props.onInputChange({
              name: 'mode',
              value: value
            });
          }}
        >
          {_createUIOptions(this.props.secLevelModeList)}
        </Select>

        <Select
          style={{ marginRight: '5px', width: '500px' }}
          value={showColumns}
          size="small"
          mode="multiple"
          onChange={value => {
            this.props.onInputChange({
              name: 'showColumns',
              value: value
            });
          }}
        >
          {_createUIOptions(this.props.secLevelColumnList)}
        </Select>
      </div>
    );
  };

  render() {
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {this._createOPBar()}
        {this._createGrid()}
      </div>
    );
  }
}

export default DashbordSubDataGrid;
