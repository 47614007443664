import fetch from 'isomorphic-fetch';
import hyperid from 'hyperid';

const uniqidInstance = hyperid();

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(`HTTP Error ${response.status}`);
    error.status = response.status;
    error.response = response;
    console.log(error);
    throw error;
  }
};

const parseJson = response => {
  const contentType = response.headers.get('content-type') || '';
  if (contentType.includes('application/json')) return response.json();
  if (contentType.includes('application/x-xlsx')) return showFile(response);
  if (contentType.includes('application/force-download'))
    return showFile(response);

  return null;
};

const interceptors = [
  {
    response: parseJson
  },
  {
    response: checkStatus
  }
];

const intercept = (...args) => {
  let promise = Promise.resolve(args);
  const reversedInterceptors = interceptors.reduce(
    (array, interceptor) => [interceptor].concat(array),
    []
  );

  // Register request intercepters
  reversedInterceptors.forEach(({ request, requestError }) => {
    if (request || requestError) {
      promise = promise.then(args => request(...args), requestError);
    }
  });

  // Do fetch call
  promise = promise.then(args => fetch(...args));

  // Register response interceptors
  reversedInterceptors.forEach(({ response, responseError }) => {
    if (response || responseError) {
      promise = promise.then(response, responseError);
    }
  });

  return promise;
};

const post = async (url, body) => {
  return await intercept(url, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-access-uniqid': uniqidInstance()
    },
    body: JSON.stringify(body)
  });
};

const get = async url => {
  return await intercept(url, {
    method: 'get',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json'
    }
  });
};

const asyncPost = (url, body) => {
  return intercept(url, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-access-uniqid': uniqidInstance()
    },
    body: JSON.stringify(body)
  });
};

const register = interceptor => {
  interceptors.push(interceptor);
};

const showFile = async resp => {
  const blob = await resp.blob();
  const contentDisposition = resp.headers.get('content-disposition');

  const [, fileName = 'report'] =
    (contentDisposition || '').match(/filename="(.+)"/) || [];

  let newFileName = decodeURI(fileName);
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type: blob.type });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download = newFileName;
  link.click();

  setTimeout(() => window.URL.revokeObjectURL(data), 100);
};

const apiClient = {
  post,
  get,
  register,
  asyncPost
};

export default apiClient;
