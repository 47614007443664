import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';
import { DIALOG_FINALIZE_ORDERS } from '../../omsConstants';
import client from '../../api/client';
import { Message } from 'semantic-ui-react';
import OrderValidator from '../../entities/validators/OrderValidator';
import _ from 'lodash';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { orderGridColumns } from './GridColumnMap';

class FinalizeOrdersDialog extends PureComponent {
  state = {
    submitStatus: 'READY',
    gridWrapperStyle: {
      width: '100%',
      height: '300px',
      marginTop: '20px'
    },
    hotTableSettings: hotTableUtils.createSettings({
      columns: orderGridColumns,
      contextMenu: ['remove_row']
    })
  };

  componentDidMount() {
    const { selectedOrders, settings } = this.props;

    const ctx = { user: settings.user, operationType: 'FINALIZE' };
    const orders = selectedOrders.map(o => {
      const errors = OrderValidator.validate(o, ctx);
      return {
        ...o,
        errors,
        hasErrors: !_.isEmpty(errors)
      };
    });

    this.setState({
      orders
    });
  }

  _beforeRemoveRow = (row, count, rows, source) => {
    const { orders } = this.state;
    const removedOrders = orders.filter((_, i) => !rows.includes(i));

    this.setState({ orders: removedOrders });

    return false;
  };

  _onOrderSelectionChanged = row => {
    const { orders } = this.state;
    const selectedOrder = orders[row];

    this.setState({
      selectedOrder
    });
  };

  _createOrdersGrid = () => {
    const { hotTableSettings, gridWrapperStyle, orders } = this.state;

    return (
      !_.isEmpty(orders) && (
        <div style={gridWrapperStyle}>
          <HotTable
            data={orders}
            beforeRemoveRow={this._beforeRemoveRow}
            afterSelectionEnd={this._onOrderSelectionChanged}
            manualColumnResize={true}
            {...hotTableSettings}
          ></HotTable>
        </div>
      )
    );
  };

  _createSummaryPanel = () => {
    const { orders = [] } = this.state;

    const errorCount = orders.filter(r => !_.isEmpty(r.errors)).length;

    return (
      <div className="summary">
        Totally
        <span className="keyword">{` ${orders.length} `}</span>
        orders,
        <span className="error-keyword">{` ${errorCount} `}</span>
        contain errors (which will be filtered out automatically)
      </div>
    );
  };

  _createErrorsPanel = () => {
    const { selectedOrder } = this.state;
    const { errors = {} } = selectedOrder || {};

    const errorMsgs = Object.values(errors);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  _finalizeOrders = () => {
    const { orders } = this.state;
    const { submitDialogSuccess } = this.props;

    const cmds = orders
      .filter(o => _.isEmpty(o.errors))
      .map(o => ({
        refId: o.refId,
        tradeId: o.tradeId
      }));

    if (cmds.length > 0) {
      return client.finalizeOrders(cmds).then(() => {
        submitDialogSuccess(DIALOG_FINALIZE_ORDERS, cmds.map(c => c.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    const promises = [this._finalizeOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_FINALIZE_ORDERS);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    return (
      <Modal
        width={900}
        maskClosable={false}
        title="Finalize Orders"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        {this._createSummaryPanel()}
        {this._createOrdersGrid()}
        {this._createErrorsPanel()}
      </Modal>
    );
  }
}

export default FinalizeOrdersDialog;
