import React, { PureComponent } from 'react';
import { Modal, Button, DatePicker, Spin } from 'antd';
import { DIALOG_BROKERS_INFO } from '../../omsConstants';
import { brokerCommisionRuleTableColumns } from './GridColumnMap';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import Handsontable from 'handsontable';
import ReactDOMServer from 'react-dom/server';
import client from '../../api/client';
import moment from 'moment';
import _ from 'lodash';

const { YearPicker } = DatePicker;
class BrokerCommissionRuleModal extends PureComponent {
  state = {
    submitStatus: 'READY',
    data: this.props.data ? { ...this.props.data } : {},
    isInited: false,
    gridWrapperStyle: {
      width: '100%',
      height: '300px',
      marginTop: '20px'
    },
    colorMap: {
      PB: '#FFCC33',
      EB: '#FF6666'
    }
  };

  componentDidMount() {
    _.delay(this._loadData, 500);
  }

  _createDateSelect = () => {
    return (
      <div style={{ width: '100%', height: '36px' }}>
        <YearPicker
          value={moment(this.state.data.date, 'YYYY')}
          format={'YYYY'}
          allowClear={false}
          onChange={this.monthChange}
        />
      </div>
    );
  };

  _loadData = () => {
    const { genDefaultData } = this.props;
    const { data } = this.state;
    const { date } = data;
    if (!date) return;
    this.setState({
      isInited: false
    });
    client.getBrokerRules({}).then(resp => {
      if (!_.isEmpty(resp)) {
        const currentData = resp.filter(r => r.date === date);
        if (!_.isEmpty(currentData)) {
          this.setState({
            data: currentData[0],
            isInited: true
          });
        } else {
          this.setState({
            isInited: true,
            data: {
              type: 'COMMISSION',
              date: date,
              value: genDefaultData(),
              isActive: 1
            }
          });
        }
      }
    });
  };

  monthChange = (date, dataString) => {
    const { data } = this.state;
    this.setState(
      {
        data: {
          ...data,
          date: dataString
        }
      },
      this._loadData
    );
  };

  colorHtmlRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    //get color
    var color = this.state.colorMap[value];
    const innerHtml = ReactDOMServer.renderToString(
      <p
        style={{
          backgroundColor: color,
          width: '100%',
          color: 'black',
          textAlign: 'center'
        }}
      >
        {value}
      </p>
    );

    Handsontable.renderers.HtmlRenderer.call(
      this,
      instance,
      td,
      row,
      col,
      prop,
      innerHtml,
      cellProperties
    );
  };

  _createHotTable = (data, height, headerName) => {
    const { gridWrapperStyle } = this.state;
    const columns = brokerCommisionRuleTableColumns.map(r => {
      if (r.data === 'percent') {
        return {
          ...r,
          headerName
        };
      }
      if (r.data === 'tier') {
        r.renderer = this.colorHtmlRenderer;
      }
      return { ...r };
    });

    const style = {
      ...gridWrapperStyle,
      height
    };
    const hotTableSettings = hotTableUtils.createSettings({
      columns
    });
    return (
      <div style={style}>
        <HotTable
          data={data}
          manualColumnResize={true}
          contextMenu={['remove_row', 'row_below']}
          {...hotTableSettings}
        ></HotTable>
      </div>
    );
  };

  _createConfigPage = () => {
    const { value } = this.state.data;
    if (_.isEmpty(value)) return '';
    return (
      <div style={{ width: '100%', height: '800px' }}>
        <div style={{ width: '50%', height: '100%', float: 'left' }}>
          <div
            style={{
              width: '100%',
              height: '40%',
              border: '1px solid',
              padding: '10px'
            }}
          >
            {this._createHotTable(value['CH'], '330px', '% CH')}
          </div>
          <div
            style={{
              width: '100%',
              height: '60%',
              border: '1px solid',
              borderTop: '0px',
              padding: '10px'
            }}
          >
            {this._createHotTable(value['HK'], '430px', '% HK')}
          </div>
        </div>
        <div style={{ width: '50%', height: '100%', float: 'left' }}>
          <div
            style={{
              width: '100%',
              height: `${(1.6 / 4) * 100}%`,
              border: '1px solid',
              borderLeft: '0px',
              padding: '10px'
            }}
          >
            {this._createHotTable(value['JP'], '220px', '% JP')}
          </div>
          <div
            style={{
              width: '100%',
              height: `${(1.3 / 4) * 100}%`,
              borderRight: '1px solid',
              padding: '10px'
            }}
          >
            {this._createHotTable(value['IN'], '200px', '% IN FnO')}
          </div>
          <div
            style={{
              width: '100%',
              height: `${(1.1 / 4) * 100}%`,
              border: '1px solid',
              borderLeft: '0px',
              padding: '10px'
            }}
          >
            {this._createHotTable(value['IN_CASH'], '100px', '% IN')}
          </div>
        </div>
      </div>
    );
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_BROKERS_INFO);
  };

  _onSubmit = () => {
    const { data } = this.state;
    const { onSubmit } = this.props;
    onSubmit(data);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.props;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    const { isInited } = this.state;
    return (
      <Modal
        width={700}
        maskClosable={false}
        title="Add Rule"
        visible={true}
        bodyStyle={{ paddingTop: '6px' }}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <Spin spinning={!isInited}>
          {this._createDateSelect()}
          {this._createConfigPage()}
        </Spin>
      </Modal>
    );
  }
}

export default BrokerCommissionRuleModal;
