import PercentBarComponent from './renderers/agGrid/PercentBarComponent';
import TagComponent from './renderers/agGrid/TagComponent';
import TableTooltipComponent from './renderers/agGrid/TableTooltipComponent';
import SwitchComponent from './renderers/agGrid/SwitchComponent';
import JsonTooltipComponent from './renderers/agGrid/JsonTooltipComponent';

const isFirstColumn = params => {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
};

const isGroupRow = params => {
  const { node } = params;
  return node && node.group;
};

const isNonAggColInGroupRow = params => {
  return isGroupRow(params) && !params.colDef.aggFunc;
};

const DEFAULT_COL_DEF = {
  enableCellChangeFlash: true,
  minWidth: 25,
  filter: 'agTextColumnFilter',
  checkboxSelection: isFirstColumn,
  headerCheckboxSelection: isFirstColumn,
  headerCheckboxSelectionFilteredOnly: true,
  suppressMenu: true,
  resizable: true,
  sortable: true
};

const createSettings = (overrides = {}) => ({
  defaultColDef: {
    ...DEFAULT_COL_DEF
  },
  autoGroupColumnDef: {
    cellClass: 'non-number'
  },
  rowGroupPanelShow: 'always',
  pivotPanelShow: 'always',

  suppressAggFuncInHeader: true,
  sideBar: {
    toolPanels: ['columns', 'filters']
  },
  animateRows: true,
  deltaRowDataMode: true,
  enableRangeSelection: true,
  getRowNodeId: data => data.key,
  groupDefaultExpanded: -1,
  rowSelection: 'multiple',
  groupSelectsChildren: true,
  groupSelectsFiltered: true,
  floatingFilter: true,
  pivotRowTotals: 'after',
  groupIncludeTotalFooter: true,
  tooltipShowDelay: 0,

  frameworkComponents: {
    percentBarComponent: PercentBarComponent,
    tagComponent: TagComponent,
    tableTooltipComponent: TableTooltipComponent,
    switchComponent: SwitchComponent,
    jsonTooltipComponent: JsonTooltipComponent
  },

  ...overrides
});

export default {
  isFirstColumn,
  isGroupRow,
  isNonAggColInGroupRow,
  createSettings,
  DEFAULT_COL_DEF
};
