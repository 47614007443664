import React, { Component } from 'react';
import { Drawer, Form, Input, Row, Col, Select, Button, Collapse } from 'antd';
import TickerSearchField from 'common/ui/TickerSearchField';
import client from 'features/order/api/client';
import researchClient from '../../../api/client';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CompanyReview from '../review/CompanyReview';
import _ from 'lodash';
import { getNextQuarter } from 'common/utils/DateUtils';
import TickerUtils from 'common/utils/TickerUtils';
import NotesDrawer from './NotesDrawer';
import { getValue, save, delKey } from 'common/utils/IndexedDBUtils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import LinkPannel from './LinkPannel';
import ChatGptModal from './ChatGptModal';

const { Panel } = Collapse;
const Option = Select.Option;
const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class CompanyScoreDetailDrawer extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      scoreList: [1, 2, 3, 4, 5],
      loadNotesStatus: 'READY',
      conclusionList: ['Long', 'Short', 'No action'],
      competitionList: ['Intensifying', 'Weakening', 'No change'],
      marketViewList: ['High', 'Med', 'Low'],
      deltaList: ['Accelerate', 'Decelerate', 'No change'],
      companyBusinessList: [],
      data: this.props.data,
      lastVersionData: {},
      submitStatus: 'READY',
      defaultSingleQData: [
        {
          id: 1,
          type: 'Revenue (million)',
          chineseIndex: '单季营收 (百万)',
          index: 'Revenue (million)',
          firstRow: true
        },
        {
          id: 2,
          type: 'Revenue (million)',
          index: 'YoY',
          disabled: true,
          isPercent: true
        },
        {
          id: 3,
          type: 'Revenue (million)',
          index: 'QoQ',
          disabled: true,
          isPercent: true
        },
        {
          id: 4,
          type: 'GP Margin',
          index: 'GP Margin',
          firstRow: true,
          isPercent: true
        },
        {
          id: 5,
          type: 'GP Margin',
          index: 'YoY(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 6,
          type: 'GP Margin',
          index: 'QoQ(ppt)',
          disabled: true,
          isPpt: true
        },
        {
          id: 7,
          type: 'Net profit',
          index: 'Net profit (million) ',
          firstRow: true,
          isPercent: true
        },
        {
          id: 8,
          type: 'Net profit',
          index: 'YoY',
          disabled: true
        },
        {
          id: 9,
          type: 'Net profit',
          index: 'QoQ',
          disabled: true
        },
        {
          id: 10,
          type: 'Other metrics',
          chineseIndex: '其他指标1',
          index: 'Other metrics 1'
        },
        {
          id: 11,
          type: 'Other metrics',
          chineseIndex: '其他指标2',
          index: 'Other metrics 2'
        },
        {
          id: 12,
          type: 'Other metrics',
          chineseIndex: '其他指标3',
          index: 'Other metrics 3'
        }
      ],
      defaultBottomData: [
        { id: 1, index: 'Sales expense' },
        { id: 2, index: 'Admin expense' },
        { id: 3, index: 'R&D (absolute value)' },
        {
          id: 4,
          type: 'Net interest expense',
          index: 'Net interest expense',
          excludeMarkets: ['CH']
        },
        {
          id: 5,
          type: 'Finance expense',
          index: 'Finance expense',
          markets: ['CH']
        }
      ],
      defaultFutureTrendData: [
        { id: 1, index: 1 },
        { id: 2, index: 2 },
        { id: 3, index: 3 },
        { id: 4, index: 4 }
      ],
      happenList: [
        'Preliminary results',
        'Results',
        'News',
        'Expert',
        'Company visit',
        'Event',
        'Other'
      ],
      notesDraw: {
        show: false,
        name: ''
      },
      isDraft: false,
      showChatModal: false
    };
  }

  _createNotesDraw = () => {
    const { data, notesDraw } = this.state;
    return (
      notesDraw.show && (
        <div>
          <NotesDrawer
            {...this.props}
            data={data}
            name={notesDraw.name}
            readOnly={this._isReadOnly()}
            closeNotesDraw={this._closeNotesDraw}
            saveData={this._submitData}
            submitStatus={this.state.submitStatus}
          />
        </div>
      )
    );
  };

  _openNotesDraw = () => {
    const { data } = this.state;
    const name = (
      <>
        <span style={{ color: '#FF9E28' }}>
          {data.ticker ? data.ticker : ''}
        </span>
        <span>{data.name ? `(${data.name})` : ''}</span>
      </>
    );
    this.setState({
      notesDraw: {
        show: true,
        name: name
      }
    });
  };

  _closeNotesDraw = () => {
    this.setState({
      notesDraw: {
        show: false
      }
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  async componentDidMount() {
    this._getLastSanp();
    const cacheData = await this.getCache();
    if (!_.isEmpty(cacheData)) {
      this.setState({
        data: cacheData,
        isDraft: true
      });
    } else {
      this.loadNotes();
    }
    if (!this._isReadOnly()) {
      _.delay(this.autoSave, 1000);
    }
  }

  async componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  autoSave = () => {
    this.intervalId = setInterval(() => {
      const { data, submitStatus, loadNotesStatus } = this.state;
      if (
        data.id &&
        submitStatus !== 'SUBMITTING' &&
        loadNotesStatus === 'READY'
      ) {
        //save to indexDb
        const cacheData = {
          ...data,
          reviewDetail: JSON.stringify(data.review)
        };
        save(data.id, cacheData, 'companyScore');
      }
      if (
        !data.id &&
        !_.isEmpty(data.ticker) &&
        data.ticker.endsWith('Equity')
      ) {
        //auto save to db
        this._submitData(true);
      }
    }, 30000);
  };

  delCache = () => {
    const { data } = this.state;
    if (data.id) {
      delKey(data.id, 'companyScore');
    }
  };

  getCache = () => {
    const { data } = this.state;
    if (data.id) {
      return getValue(data.id, 'companyScore');
    }
  };

  loadNotes = () => {
    const { data } = this.state;

    if (data.noteIds) {
      this.setState({
        loadNotesStatus: 'LOADING'
      });
      researchClient
        .queryNotes(data.noteIds)
        .then(resp => {
          data.noteList = resp;
          this.setState({
            loadNotesStatus: 'READY'
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            loadNotesStatus: 'READY'
          });
        });
    }
  };

  _getLastSanp = () => {
    const { sectorData, data } = this.props;
    if (sectorData && sectorData.lastSnapshotId) {
      const lastVersionNum = sectorData.status === 'PUBLISH' ? 2 : 1;
      researchClient
        .getLastSnapshot({ id: sectorData.lastSnapshotId, lastVersionNum })
        .then(resp => {
          let insData = resp.data ? resp.data.data : [];
          insData = insData ? insData : [];
          const lastComData = insData.filter(r => r.id === data.id);
          const lastVersionData = _.isEmpty(lastComData) ? {} : lastComData[0];
          this.setState({
            lastVersionData
          });
        })
        .catch(err => console.log(err));
    }
  };

  _syncData = async () => {
    const { data } = this.state;
    const { review } = data;
    await this._contactAutoCompleteData(review, [data.ticker]);
    this.setState({
      data: {
        ...data
      }
    });
  };

  _contactAutoCompleteData = async (review, tickerList) => {
    try {
      const { data } = await this._getStockIndexList(
        review.quanter,
        tickerList
      );
      if (_.isEmpty(data)) return;
      data.forEach(e => {
        const key = `${e.year}${e.quarter}`;
        this._autoCompleteSingleQuarterData(key, e, review.singleQuarterData);
        this._autoCompleteBottomLineData(key, e, review.bottomLineData);
      });
    } catch (err) {
      console.log(err);
    }
  };

  _autoCompleteSingleQuarterData = (key, data, singleQuarterData) => {
    const map = {
      '单季营收 (百万)': 'revenue',
      'Revenue (million)': 'revenue',
      'GP Margin': 'gpMargin',
      'Net profit (million) ': 'netIncome'
    };
    singleQuarterData.forEach(r => {
      const { index } = r;
      if (map[index]) {
        const value = data[map[index]];
        r[key] = value;
        this._calData(key, r, value, singleQuarterData);
      }
    });
  };

  _autoCompleteBottomLineData = (key, data, bottomLineData) => {
    const map = {
      'sales expense': r => r['salesExpense'],
      'Admin expense': r => r['adminExpense'],
      'R&D (absolute value)': r => Math.abs(r['rdExpense']),
      'Net interest expense': r => r.netInterestExpense,
      'Finance expense': r => r['financeExpense']
    };
    bottomLineData.forEach(r => {
      const { index } = r;
      if (map[index]) {
        if (map[index]) {
          const value = map[index](data);
          r[key] = value === 0 ? null : value;
        }
      }
    });
  };

  _calData = (field, data, value, dataList) => {
    const yoyField = `${_.toInteger(field.substr(0, 4)) - 1}${field
      .substring(4)
      .replace('e', '')}`;
    const yoyFieldBefore = `${_.toInteger(field.substr(0, 4)) +
      1}${field.substring(4).replace('e', '')}`;
    const yoyeFieldBefore = `${_.toInteger(field.substr(0, 4)) +
      1}${field.substring(4).replace('e', '')}e`;
    const year = _.toInteger(field.substr(0, 4));
    const quarter = _.toInteger(field.substr(5, 1));
    const qoqField = `${quarter === 1 ? year - 1 : year}Q${
      quarter === 1 ? 4 : quarter - 1
    }`;
    const qoqFieldBefore = `${quarter === 4 ? year + 1 : year}Q${
      quarter === 4 ? 1 : quarter + 1
    }`;
    const qoqeFieldBefore = `${quarter === 4 ? year + 1 : year}Q${
      quarter === 4 ? 1 : quarter + 1
    }e`;
    const filterList = dataList.filter(
      r => r.type === data.type && r.id !== data.id
    );
    if (filterList) {
      filterList.forEach(r => {
        if (r.index.startsWith('YoY')) {
          const yoyFieldData = data[yoyField];
          r[field] = _.isNaN(value - yoyFieldData)
            ? null
            : this._calPercent(value, yoyFieldData, r.index);
          if (!field.endsWith('e')) {
            r[yoyeFieldBefore] = _.isNaN(data[yoyeFieldBefore] - value)
              ? undefined
              : this._calPercent(data[yoyeFieldBefore], value, r.index);
            r[yoyFieldBefore] = _.isNaN(data[yoyFieldBefore] - value)
              ? undefined
              : this._calPercent(data[yoyFieldBefore], value, r.index);
          }
        } else if (r.index.startsWith('QoQ')) {
          const qoqFieldData = data[qoqField];
          r[field] = _.isNaN(value - qoqFieldData)
            ? undefined
            : this._calPercent(value, qoqFieldData, r.index);
          if (!field.endsWith('e')) {
            r[qoqeFieldBefore] = _.isNaN(data[qoqeFieldBefore] - value)
              ? undefined
              : this._calPercent(data[qoqeFieldBefore], value, r.index);
            r[qoqFieldBefore] = _.isNaN(data[qoqFieldBefore] - value)
              ? undefined
              : this._calPercent(data[qoqFieldBefore], value, r.index);
          }
        }
      });
    }
  };

  _calPercent = (value1, value2, index) => {
    if (index && index.endsWith('(ppt)')) return _.round(value1 - value2, 2);
    if (value1 && value2) return _.round((value1 / value2 - 1) * 100, 1);
    return null;
  };

  _getStockIndexList = async (date, tickerList) => {
    const stockList = await researchClient.queryStockIndexInfoList({
      date,
      tickerList
    });

    return stockList ? stockList : [];
  };

  onInputChange = ({ name, value }) => {
    const { data } = this.state;
    const updateData = {
      ...data,
      [name]: value
    };
    this.setState({
      data: updateData
    });
  };

  _getSecurity = ticker => {
    if (!ticker) {
      return;
    }
    this.setState({ isLoadingSecurity: true });
    client
      .getSlimSecurities([{ ticker }])
      .then(([security]) => {
        const { ticker, name } = security;
        this.onInputChange({ name: 'ticker', value: ticker });
        this.onInputChange({ name: 'name', value: name });
        this._queryStockHolding(ticker);
      })
      .catch(_ => {});
  };

  _queryStockHolding = ticker => {
    if (_.isEmpty(ticker) || !ticker.endsWith('CH Equity')) return;
    const tickerPrefix = ticker.replace(' CH Equity', '');
    researchClient
      .queryStockHolding({ ticker: tickerPrefix })
      .then(resp => {
        const data = resp.data ? resp.data : null;
        if (data) {
          this.onInputChange({
            name: 'marketViewDesc',
            value: `Mutual fund holding/circulating market cap=${_.round(
              data.holdingPct,
              2
            )}%`
          });
        }
      })
      .catch(_ => {});
  };

  onTickerSelect = ticker => {
    this._getSecurity(ticker);
  };

  _isReadOnly = () => {
    const { mode } = this.props;
    return mode === 'DETAIL' ? true : false;
  };

  _unListTicker = ticker => {
    return ticker && ticker.startsWith('[UnList]');
  };

  onTickerInput = value => {
    const { data } = this.state;
    const codeValue = value
      ? value.startsWith('[UnList]')
        ? value
        : `[UnList]${value}`
      : '';
    const valueStr = value
      ? value.startsWith('[UnList]')
        ? value.replace('[UnList]', '')
        : value
      : '';
    this.setState({
      data: {
        ...data,
        ticker: codeValue,
        name: valueStr
      }
    });
  };

  _createCompanyDetailForm = () => {
    const { data, lastVersionData } = this.state;
    const readOnly = this._isReadOnly();
    const tickerLabel = `Ticker${readOnly ? '' : '(BBG ticker + Press [Tab])'}`;
    return (
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={data}
      >
        <Row gutter={24}>
          {!readOnly && (
            <>
              <Col span={24} style={{ marginBottom: '8px', height: '45px' }}>
                <Form.Item
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 21 }}
                  name="ticker"
                  label={tickerLabel}
                >
                  <div className={'ui form field'}>
                    <div style={{ width: '35%', float: 'left' }}>
                      {this._unListTicker(data.ticker) ? (
                        <Input
                          style={{ height: '38px' }}
                          value={data.ticker}
                          onChange={e => {
                            this.onTickerInput(e.target.value);
                          }}
                        />
                      ) : (
                        <TickerSearchField
                          name="ticker"
                          value={data.ticker}
                          onChange={({ name, value }) => {
                            this.onInputChange({ name: 'ticker', value });
                          }}
                          selectTicker={this.onTickerSelect}
                        />
                      )}
                    </div>
                    <div style={{ width: '64%', float: 'right' }}>
                      <Input
                        style={{ height: '38px' }}
                        value={data.name}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24} style={{ height: '30px' }}>
            <p
              style={{ color: 'white', fontWeight: 'bold' }}
              className="site-page-header"
            >
              Long-term Factors [
              <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
                Total Score:
                {readOnly ? (
                  <span
                    style={{
                      color: 'orangered',
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.score}
                  </span>
                ) : (
                  <Select
                    className="companyScoreSelect"
                    style={{ width: '60px' }}
                    value={data.score}
                    onChange={value => {
                      this.onInputChange({
                        name: 'score',
                        value
                      });
                    }}
                  >
                    {_createUIOptions(this.state.scoreList)}
                  </Select>
                )}
              </span>
              <span style={{ color: '#FF9E28', marginLeft: '10px' }}>
                Investment direction:
                {readOnly ? (
                  <span
                    style={{
                      color: 'orangered',
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.longInvestmentConclusion}
                  </span>
                ) : (
                  <Select
                    className="companyScoreSelect"
                    style={{ width: '120px' }}
                    value={data.longInvestmentConclusion}
                    onChange={value => {
                      this.onInputChange({
                        name: 'longInvestmentConclusion',
                        value
                      });
                    }}
                  >
                    {_createUIOptions(this.state.conclusionList)}
                  </Select>
                )}
              </span>
              ]
              {lastVersionData.score && (
                <span className="lastVersionDataClass">
                  VS. {lastVersionData.score}
                  {lastVersionData.longInvestmentConclusion
                    ? ` /${lastVersionData.longInvestmentConclusion}`
                    : ''}
                </span>
              )}
            </p>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              label="Governance"
            >
              <div style={{ width: '10%', float: 'left' }}>
                <Select
                  value={data.management}
                  readOnly={readOnly}
                  onChange={value => {
                    this.onInputChange({
                      name: 'management',
                      value
                    });
                  }}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.managementDesc}
                  readOnly={readOnly}
                  onChange={e => {
                    this.onInputChange({
                      name: 'managementDesc',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              label=""
            >
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: `24%`,
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      fontSize: '12px',
                      marginRight: '3px',
                      verticalAlign: 'middle'
                    }}
                  >
                    Shareholder Structure :{' '}
                  </span>
                </div>
                <div style={{ width: `76%`, float: 'left' }}>
                  <div style={{ width: `12%`, float: 'left' }}>
                    <Select
                      value={data.equityStructureScore}
                      readOnly={readOnly}
                      onChange={value => {
                        this.onInputChange({
                          name: 'equityStructureScore',
                          value
                        });
                      }}
                    >
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85.7%`, float: 'right' }}>
                    <Input
                      value={data.equityStructure}
                      readOnly={readOnly}
                      onChange={e => {
                        this.onInputChange({
                          name: 'equityStructure',
                          value: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: '17.25%',
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      fontSize: '12px',
                      marginRight: '3px',
                      verticalAlign: 'middle'
                    }}
                  >
                    Incentives :{' '}
                  </span>
                </div>
                <div style={{ width: `82.75%`, float: 'left' }}>
                  <div style={{ width: `12.5%`, float: 'left' }}>
                    <Select
                      value={data.stockBasedCompensationScore}
                      readOnly={readOnly}
                      onChange={value => {
                        this.onInputChange({
                          name: 'stockBasedCompensationScore',
                          value
                        });
                      }}
                    >
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85%`, float: 'right' }}>
                    <Input
                      value={data.stockBasedCompensation}
                      readOnly={readOnly}
                      onChange={e => {
                        this.onInputChange({
                          name: 'stockBasedCompensation',
                          value: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 0 }}
              wrapperCol={{ span: 24 }}
              label=""
            >
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: `24%`,
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      marginRight: '3px',
                      verticalAlign: 'middle',
                      fontSize: '12px'
                    }}
                  >
                    Outside Ownership :{' '}
                  </span>
                </div>
                <div style={{ width: `76%`, float: 'left' }}>
                  <div style={{ width: `12%`, float: 'left' }}>
                    <Select
                      value={data.assetsOfMajorScore}
                      readOnly={readOnly}
                      onChange={value => {
                        this.onInputChange({
                          name: 'assetsOfMajorScore',
                          value
                        });
                      }}
                    >
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85.7%`, float: 'right' }}>
                    <Input
                      value={data.assetsOfMajorShareholders}
                      readOnly={readOnly}
                      onChange={e => {
                        this.onInputChange({
                          name: 'assetsOfMajorShareholders',
                          value: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: `${(3.2 / 7) * 100}%`,
                  float: 'right',
                  textAlign: 'right'
                }}
              >
                <div
                  style={{
                    width: `17.25%`,
                    float: 'left',
                    textAlign: 'right'
                  }}
                >
                  <span
                    style={{
                      color: '#FF9E28',
                      fontSize: '12px',
                      marginRight: '3px',
                      verticalAlign: 'middle'
                    }}
                  >
                    Punishment :{' '}
                  </span>
                </div>
                <div style={{ width: `82.75%`, float: 'left' }}>
                  <div style={{ width: `12.5%`, float: 'left' }}>
                    <Select
                      value={data.punishmentScore}
                      readOnly={readOnly}
                      onChange={value => {
                        this.onInputChange({
                          name: 'punishmentScore',
                          value
                        });
                      }}
                    >
                      {_createUIOptions(this.state.scoreList)}
                    </Select>
                  </div>
                  <div style={{ width: `85%`, float: 'right' }}>
                    <Input
                      value={data.punishment}
                      readOnly={readOnly}
                      onChange={e => {
                        this.onInputChange({
                          name: 'punishment',
                          value: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Col>
          <Col span={24} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="competitionScore"
              label="Competitiveness"
            >
              <div style={{ width: '10%', float: 'left' }}>
                <Select
                  value={data.competitionScore}
                  readOnly={readOnly}
                  onChange={value => {
                    this.onInputChange({
                      name: 'competitionScore',
                      value
                    });
                  }}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              </div>
              <div style={{ width: '89%', float: 'right' }}>
                <Input
                  value={data.competition}
                  style={{ height: '32px' }}
                  readOnly={readOnly}
                  onChange={e => {
                    this.onInputChange({
                      name: 'competition',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  _onSegmentChange = () => {
    const form = this.form.current;
    const companyBusinessList = form.getFieldValue('companyBusinessList');
    this.setState({
      companyBusinessList
    });
  };

  _createCompanyShortDetailForm = () => {
    const { data, lastVersionData } = this.state;
    const readOnly = this._isReadOnly();
    return (
      <Form
        ref={this.form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={data}
      >
        <Row gutter={24}>
          <Col span={24}>
            <p
              style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
              className="site-page-header"
            >
              Short-term Factors [
              <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
                Investment Conclusion:
                {readOnly ? (
                  <span
                    style={{
                      color: 'orangered',
                      fontSize: '14px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      fontWeight: 'bold'
                    }}
                  >
                    {data.shortInvestmentConclusion}
                  </span>
                ) : (
                  <Select
                    className="companyConclusionSelect"
                    value={data.shortInvestmentConclusion}
                    onChange={value => {
                      this.onInputChange({
                        name: 'shortInvestmentConclusion',
                        value
                      });
                    }}
                  >
                    {_createUIOptions(this.state.conclusionList)}
                  </Select>
                )}
              </span>
              ]
              {lastVersionData.shortInvestmentConclusion && (
                <span className="lastVersionDataClass">
                  VS. {lastVersionData.shortInvestmentConclusion}
                </span>
              )}
            </p>
          </Col>
          <Col span={8} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              name="valuation"
              label="Valuation"
            >
              <Select
                readOnly={readOnly}
                value={data.valuation}
                onChange={value => {
                  this.onInputChange({
                    name: 'valuation',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="workingCapitalDivRevenue"
              label="W.C./Rev"
            >
              <Select
                readOnly={readOnly}
                value={data.workingCapitalDivRevenue}
                onChange={value => {
                  this.onInputChange({
                    name: 'workingCapitalDivRevenue',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginBottom: '8px', height: '41px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="competitionSecondDerivative"
              label="Competition"
            >
              <Select
                readOnly={readOnly}
                value={data.competitionSecondDerivative}
                onChange={value => {
                  this.onInputChange({
                    name: 'competitionSecondDerivative',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.competitionList)}
              </Select>
            </Form.Item>
          </Col>
          <Form.List name={['companyBusinessList']}>
            {(fields, { add, remove }) => {
              return (
                <Row style={{ width: '100%' }}>
                  {fields.map((field, index) => (
                    <Row style={{ width: '100%' }}>
                      <Col
                        span={3}
                        style={{
                          color: '#FF9E28',
                          width: '100%',
                          textAlign: 'right',
                          paddingRight: '2px',
                          alignItems: 'center',
                          fontSize: '12px'
                        }}
                      >
                        <span>Revenue breakdown:</span>
                      </Col>
                      <Col span={21} style={{ height: '41px' }}>
                        <div style={{ width: '23%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'segment']}
                            name={[field.name, 'segment']}
                            fieldKey={[field.fieldKey, 'segment']}
                            labelCol={{ span: 0 }}
                            wrapperCol={{ span: 24 }}
                            label=""
                          >
                            <Input
                              style={{ width: '98%', marginLeft: '5px' }}
                              readOnly={readOnly}
                              onPointerMove={this._onSegmentChange}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ width: '9%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'volume']}
                            name={[field.name, 'volume']}
                            fieldKey={[field.fieldKey, 'volume']}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            label="Volume"
                          >
                            <Select>
                              {_createUIOptions(this.state.scoreList)}
                            </Select>
                          </Form.Item>
                        </div>

                        <div style={{ width: '15%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'volumeSecondDerivative']}
                            name={[field.name, 'volumeSecondDerivative']}
                            fieldKey={[
                              field.fieldKey,
                              'volumeSecondDerivative'
                            ]}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Delta"
                          >
                            <Select>
                              {_createUIOptions(this.state.deltaList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '9%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'price']}
                            name={[field.name, 'price']}
                            fieldKey={[field.fieldKey, 'price']}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            label="Price"
                          >
                            <Select>
                              {_createUIOptions(this.state.scoreList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '15%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'priceSecondDerivative']}
                            name={[field.name, 'priceSecondDerivative']}
                            fieldKey={[field.fieldKey, 'priceSecondDerivative']}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Delta"
                          >
                            <Select>
                              {_createUIOptions(this.state.deltaList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '9%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'margin']}
                            name={[field.name, 'margin']}
                            fieldKey={[field.fieldKey, 'margin']}
                            labelCol={{ span: 11 }}
                            wrapperCol={{ span: 13 }}
                            label="Margin"
                          >
                            <Select>
                              {_createUIOptions(this.state.scoreList)}
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ width: '15%', float: 'left' }}>
                          <Form.Item
                            {...field}
                            key={[field.key, 'marginSecondDerivative']}
                            name={[field.name, 'marginSecondDerivative']}
                            fieldKey={[
                              field.fieldKey,
                              'marginSecondDerivative'
                            ]}
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 17 }}
                            label="Delta"
                          >
                            <Select>
                              {_createUIOptions(this.state.deltaList)}
                            </Select>
                          </Form.Item>
                        </div>
                        {!readOnly && (
                          <div
                            style={{
                              width: '40px',
                              height: '41px',
                              float: 'left'
                            }}
                          >
                            <PlusOutlined
                              style={{
                                paddingTop: '9px',
                                marginLeft: '3px',
                                display: 'inline-block',
                                width: '15px'
                              }}
                              onClick={() => add()}
                            />
                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{
                                  paddingTop: '9px',
                                  marginLeft: '3px',
                                  display: 'inline-block',
                                  width: '15px'
                                }}
                                onClick={() => {
                                  remove(field.name);
                                  this._onSegmentChange();
                                }}
                              />
                            ) : null}
                          </div>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Row>
              );
            }}
          </Form.List>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              name="otherFinancialIndex"
              label="Other Financial metrics"
            >
              <Input.TextArea
                rows={3}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'otherFinancialIndex',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="catalyst"
              label="Catalyst"
            >
              <Input.TextArea
                rows={3}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'catalyst',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              name="risk"
              label="Risk"
            >
              <Input.TextArea
                rows={3}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'risk',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ marginBottom: '8px', height: '70px' }}>
            <Form.Item
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 21 }}
              name="tracking"
              label="Key things to follow"
            >
              <Input.TextArea
                rows={3}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'tracking',
                    value: e.target.value
                  });
                }}
              />
            </Form.Item>
          </Col>
          {this._createMoodData(data, readOnly, lastVersionData)}
          {this._createChipFlow(data, readOnly, lastVersionData)}
        </Row>
      </Form>
    );
  };

  _createChipFlow = (data, readOnly, lastVersionData) => {
    return (
      <>
        <Col span={24} style={{ marginTop: '10px' }}>
          <div
            style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
            className="site-page-header"
          >
            Chip&flow [
            <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
              Score:
              {readOnly ? (
                <span
                  style={{
                    color: 'orangered',
                    fontSize: '14px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontWeight: 'bold'
                  }}
                >
                  {data.marketView}
                </span>
              ) : (
                <Select
                  readOnly={readOnly}
                  className="companyScoreSelect"
                  style={{ width: '60px' }}
                  value={data.marketView}
                  onChange={value => {
                    this.onInputChange({
                      name: 'marketView',
                      value
                    });
                  }}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              )}
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.marketViewDesc}
                readOnly={readOnly}
                style={{ width: '500px', marginLeft: '10px' }}
                onChange={e => {
                  this.onInputChange({
                    name: 'marketViewDesc',
                    value: e.target.value
                  });
                }}
              />
            </span>
            ]
            {lastVersionData.marketView && (
              <span className="lastVersionDataClass">
                VS. {lastVersionData.marketView}
              </span>
            )}
          </div>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="dividends"
            label="Dividends"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select
                value={data.dividendsScore}
                readOnly={readOnly}
                onChange={value => {
                  this.onInputChange({
                    name: 'dividendsScore',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.dividendsDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'dividendsDesc',
                    value: e.target.value
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name="shareBuyback"
            label="Share buyback/reduction"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select
                value={data.shareBuybackScore}
                readOnly={readOnly}
                onChange={value => {
                  this.onInputChange({
                    name: 'shareBuybackScore',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.shareBuybackDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'shareBuybackDesc',
                    value: e.target.value
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
      </>
    );
  };

  _createMoodData = (data, readOnly, lastVersionData) => {
    return (
      <>
        <Col span={24}>
          <div
            style={{ color: 'white', fontWeight: 'bold', marginTop: '10px' }}
            className="site-page-header"
          >
            Market Sentiments [
            <span style={{ color: '#FF9E28', marginLeft: '3px' }}>
              Score:
              {readOnly ? (
                <span
                  style={{
                    color: 'orangered',
                    fontSize: '14px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    fontWeight: 'bold'
                  }}
                >
                  {data.moodScore}
                </span>
              ) : (
                <Select
                  readOnly={readOnly}
                  className="companyScoreSelect"
                  style={{ width: '60px' }}
                  value={data.moodScore}
                  onChange={value => {
                    this.onInputChange({
                      name: 'moodScore',
                      value
                    });
                  }}
                >
                  {_createUIOptions(this.state.scoreList)}
                </Select>
              )}
            </span>
            ]
            {lastVersionData.moodScore && (
              <span className="lastVersionDataClass">
                VS. {lastVersionData.moodScore}
              </span>
            )}
          </div>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="publicFund"
            label="Mutual funds' view"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select
                value={data.publicFundScore}
                readOnly={readOnly}
                onChange={value => {
                  this.onInputChange({
                    name: 'publicFundScore',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.publicFundDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'publicFundDesc',
                    value: e.target.value
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name="privateFund"
            label="Hedge funds' view"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select
                value={data.privateFundScore}
                readOnly={readOnly}
                onChange={value => {
                  this.onInputChange({
                    name: 'privateFundScore',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.privateFundDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'privateFundDesc',
                    value: e.target.value
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="privateInvestor"
            label="Individual investors' view"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select
                value={data.privateInvestorScore}
                readOnly={readOnly}
                onChange={value => {
                  this.onInputChange({
                    name: 'privateInvestorScore',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.privateInvestorDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'privateInvestorDesc',
                    value: e.target.value
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={12} style={{ height: '41px' }}>
          <Form.Item
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            name="researchDeepth"
            label="Market research deepth"
          >
            <div style={{ width: '10%', float: 'left' }}>
              <Select
                value={data.researchDepthScore}
                readOnly={readOnly}
                onChange={value => {
                  this.onInputChange({
                    name: 'researchDepthScore',
                    value
                  });
                }}
              >
                {_createUIOptions(this.state.scoreList)}
              </Select>
            </div>
            <div style={{ width: '89%', float: 'right' }}>
              <Input
                addonAfter={
                  <QuestionCircleOutlined onClick={this._openChatGptModal} />
                }
                value={data.researchDepthDesc}
                style={{ height: '32px' }}
                readOnly={readOnly}
                onChange={e => {
                  this.onInputChange({
                    name: 'researchDepthDesc',
                    value: e.target.value
                  });
                }}
              />
            </div>
          </Form.Item>
        </Col>
      </>
    );
  };

  _closeDraw = (data = {}) => {
    const { closeDrawer } = this.props;
    this.clearIntervals();
    this.delCache();
    closeDrawer(data);
  };

  _onDrawClose = () => {
    const { data } = this.state;
    if (data.id && !this._isReadOnly()) {
      this._submitData();
    } else {
      this._closeDraw();
    }
  };

  _submitData = unCloseDrawer => {
    const { data } = this.state;
    const { reloadData } = this.props;
    const form = this.form.current;
    if (!form) {
      this.setState({
        submitStatus: 'ERROR'
      });
      return;
    }
    form
      .validateFields()
      .then(values => {
        const { companyBusinessList } = values;
        this.setState({
          submitStatus: 'SUBMITTING'
        });
        const submitData = {
          ...data,
          companyBusinessList,
          reviewDetail: JSON.stringify(data.review)
        };
        researchClient
          .saveCompanyScore(submitData)
          .then(resp => {
            const id = resp.data;
            this.setState({
              submitStatus: 'READY',
              data: {
                ...data,
                id
              }
            });
            if (!unCloseDrawer) {
              this._closeDraw({
                saveData: {
                  ...submitData,
                  id
                }
              });
            }
            reloadData();
          })
          .catch(err => {
            this.setState({
              submitStatus: 'ERROR'
            });
            console.log(err);
          });
      })
      .catch(info => {
        console.log('Submit Failed:', info);
      });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, isDraft } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" size="small" disabled loading>
          Saving
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" size="small" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" size="small" onClick={handleSubmit}>
          {isDraft ? 'UnSave Version' : 'Save'}
        </Button>
      )
    }[submitStatus];
  };

  _onReviewDataChange = ({ name, value }) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        review: {
          ...data.review,
          [name]: value
        }
      }
    });
  };

  _createCompanyReview = () => {
    const {
      data,
      defaultSingleQData,
      defaultBottomData,
      defaultFutureTrendData,
      companyBusinessList
    } = this.state;
    if (!data.reviewDetail && !data.review) {
      const nextQuanter = getNextQuarter();
      data.review = {
        quanter: nextQuanter.quarterKey,
        singleQuarterData: defaultSingleQData,
        performanceSplit: [],
        bottomLineData: defaultBottomData,
        futureTrendData: defaultFutureTrendData,
        marketFeedback: [{ id: 1 }],
        investmentConclusion: [{ id: 1 }],
        followUp: [{ id: 1 }]
      };
    } else {
      data.review = data.review ? data.review : JSON.parse(data.reviewDetail);
    }
    return (
      <CompanyReview
        {...this.props}
        companyBusinessList={companyBusinessList}
        onReviewDataChange={this._onReviewDataChange}
        onCompanyScoreDataChange={this.onInputChange}
        calData={this._calData}
        readOnly={this._isReadOnly()}
        syncData={this._syncData}
        getTickerExch={this._getTickerExch}
        defaultTemplate={{
          defaultSingleQData,
          defaultBottomData,
          defaultFutureTrendData
        }}
        data={data}
      />
    );
  };

  _getTickerExch = () => {
    const { ticker } = this.state.data;
    if (!ticker) return '';
    return TickerUtils.parseExch(ticker);
  };

  onSubmitBtnClick = () => {
    this._submitData();
  };

  _createAttachList = () => {
    const { data } = this.state;
    return (
      <LinkPannel {...this.props} data={data} readOnly={this._isReadOnly()} />
    );
  };

  _openChatGptModal = () => {
    this.setState({
      showChatModal: true
    });
  };

  _closeChatModal = () => {
    this.setState({
      showChatModal: false
    });
  };

  _createChatModal = () => {
    const { showChatModal } = this.state;
    const { ticker } = this.state.data;
    if (!ticker || !ticker.endsWith('CH Equity')) return;
    return (
      showChatModal && (
        <ChatGptModal ticker={ticker} closeModal={this._closeChatModal} />
      )
    );
  };

  render() {
    const { data, loadNotesStatus } = this.state;
    const readOnly = this._isReadOnly();
    const title = (
      <div>
        <div style={{ float: 'left', width: '40%', textAlign: 'left' }}>
          <span style={{ color: '#FF9E28' }}>
            {data.ticker ? data.ticker : ''}
          </span>
          <span>{data.name ? `(${data.name})` : ''}</span>
        </div>
        <div style={{ float: 'right', width: '60%', textAlign: 'right' }}>
          {!readOnly && this._createSubmitBtn(this.onSubmitBtnClick)}
          <Button
            size="small"
            disabled={loadNotesStatus === 'READY' ? false : true}
            onClick={this._openNotesDraw}
            style={{ marginLeft: '10px' }}
          >
            {loadNotesStatus === 'READY' ? 'Notes' : 'Loading Notes'}
          </Button>
          <Button
            size="small"
            onClick={this._onDrawClose}
            style={{ marginLeft: '10px' }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    return (
      <>
        <style>
          {`
                      .planDetailDrawerClass {
                      }

                      .planDetailDrawerClass  .ant-drawer-content{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass  .ant-drawer-header{
                        background-color: #2d3436;
                      }

                      .planDetailDrawerClass .ant-drawer-title {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-form-item-label > label{
                        color: #FF9E28;
                        font-size: 12px;
                      }

                      .planDetailDrawerClass .ant-input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                      }

                      .planDetailDrawerClass .ant-input-number-lg input{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 13px;
                        height: 30px;
                      }

                      .planDetailDrawerClass .ant-input-group-addon{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ant-page-header-heading-title{
                        color: white;
                        font-size: 15px;
                      }

                      .planDetailDrawerClass .ant-page-header{
                        padding: 5px;
                      }

                      .planDetailDrawerClass .anticon{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-divider{
                        margin-top: 0px;
                      }

                      .planDetailDrawerClass .ant-divider-plain.ant-divider-with-text{
                        color: white;
                      }

                      .planDetailDrawerClass .ant-col {
                        height: 48px;
                      }

                      .planDetailDrawerClass .ui.form input[type="text"] {
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                      }

                      .planDetailDrawerClass .ui.input {
                        width: 100%
                      }

                      .planDetailDrawerClass i.icon {
                        color: white;
                      }

                      .planDetailDrawerClass .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 1px solid #594e4a !important;
                        color: white !important;
                      }

                      .planDetailDrawerClass .companyScoreSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                      }

                      .planDetailDrawerClass .companyConclusionSelect .ant-select-selector{
                        background-color: #2d3436 !important;
                        border: 0px solid #594e4a !important;
                        color: white !important;
                        height: 28px;
                        width: 120px;
                      }
                      .planDetailDrawerClass .derivativeCalss {
                        margin-left: 5px;
                        color: #FF9E28;
                        font-size: 12px;
                      }
                      .planDetailDrawerClass .ant-select-arrow{
                        color: gray !important;
                      }

                      .planDetailDrawerClass .ant-input-number{
                        background-color: #2d3436;
                        color: white;
                        border-color: #594e4a;
                        font-size: 12px;
                        width: 100%;
                      }

                      .lastVersionDataClass{
                        color: green;
                        margin-left: 10px;
                      }

                      .planDetailDrawerClass .ant-collapse-header{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-header .ant-collapse-arrow{
                        color: white !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content-box{
                        border: 0px !important;
                        background-color: #2d3436;
                        color: white !important;
                        padding: 0px;
                      }

                      .planDetailDrawerClass .ant-collapse {
                        background-color: #2d3436;
                        border: 0px solid #d9d9d9 !important;
                        border-bottom: 0;
                        border-radius: 2px;
                      }

                      .planDetailDrawerClass .ant-collapse-item {
                        border-bottom: 0px solid #d9d9d9 !important;
                      }

                      .planDetailDrawerClass .ant-collapse-content {
                        overflow: hidden;
                        border-top: 0px solid #d9d9d9 !important;
                        background-color: #2d3436;
                      }
                    `}
        </style>
        <Drawer
          width={'100%'}
          title={title}
          placement="right"
          maskClosable={readOnly}
          onClose={this._onDrawClose}
          closable={false}
          visible={true}
          className="planDetailDrawerClass"
        >
          {this._createCompanyDetailForm()}
          {this._createCompanyShortDetailForm()}
          <Collapse defaultActiveKey={['1', '2']}>
            <Panel header="Attach List" key="1">
              {this._createAttachList()}
            </Panel>
            <Panel header="Review" key="2">
              {this._createCompanyReview()}
            </Panel>
          </Collapse>
          {this._createNotesDraw()}
          {this._createChatModal()}
        </Drawer>
      </>
    );
  }
}

export default CompanyScoreDetailDrawer;
