import Handsontable from 'handsontable';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

export function sideRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.DropdownRenderer.apply(this, arguments);

  if (['BUY', 'COVR', 'COVR_T'].includes(value)) {
    td.className = 'long';
  } else if (['SELL', 'SHRT', 'SELL_T'].includes(value)) {
    td.className = 'short';
  }

  return td;
}

export function orderTypeRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.DropdownRenderer.apply(this, arguments);

  if (['MKT'].includes(value)) {
    td.className = 'orderType-mkt';
  } else if (['LMT'].includes(value)) {
    td.className = 'orderType-lmt';
  }

  return td;
}

export function timeInForceRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.DropdownRenderer.apply(this, arguments);

  if (['DAY'].includes(value)) {
    td.className = 'timeInForce-day';
  } else if (['GTC'].includes(value)) {
    td.className = 'timeInForce-gtc';
  }

  return td;
}

const REG_MMA_TICKER = / (C1|C2|H1|H2) Equity/i;
export function mmaExchRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  const matches = value && value.match(REG_MMA_TICKER);
  const exch = matches ? matches[1] : '';
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    exch,
    cellProperties
  );

  return td;
}

// export function notEmptyRenderer(
//   instance,
//   td,
//   row,
//   col,
//   prop,
//   value,
//   cellProperties
// ) {
//   const token = _.isEmpty(value) ? 'N' : 'Y';
//   Handsontable.renderers.TextRenderer.call(
//     this,
//     instance,
//     td,
//     row,
//     col,
//     prop,
//     token,
//     cellProperties
//   );

//   if (token === 'Y') td.className = 'error-keyword';

//   return td;
// }

export function booleanRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  const token = _.isString(value)
    ? value === 'true'
      ? 'Y'
      : 'N'
    : !value
    ? 'N'
    : 'Y';
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    token,
    cellProperties
  );

  if (token === 'Y') td.className = 'error-keyword';

  return td;
}

export function arrayRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  const valueString = _.isEmpty(value)
    ? ''
    : value
        .filter(p => p.value)
        .map(p => `[${p.code}]:${p.value}`)
        .join(',');
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );

  return td;
}

export function objectRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  const valueString = _.isEmpty(value)
    ? ''
    : Object.entries(value)
        .filter(([, v]) => v)
        .map(([k, v]) => `[${k}]:${v}`)
        .join(',');
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );

  return td;
}

export function dateRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  let valueString = '';
  if (value) {
    valueString = moment(value).format('YYYY-MM-DD');
  }
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );
  return td;
}

export function dateTimeRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  let valueString = '';
  if (value) {
    valueString = moment(value).format('YYYY-MM-DD HH:MM:ss');
  }
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );
  return td;
}

export function numberRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  let valueString = '';
  if (value) {
    valueString = numeral(value).format('0,0');
  }
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );
  return td;
}

export function numberPercentRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  let valueString = '';
  if (value) {
    valueString = numeral(value).format('0.00%', Math.floor);
  }
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );
  return td;
}

export function numberFormatRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  let valueString = '';
  if (value) {
    valueString = numeral(value).format('0.0000', Math.floor);
  }
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );
  return td;
}

export function upperCaseRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  let valueString = '';
  if (value) {
    valueString = value.toUpperCase();
  }
  Handsontable.renderers.TextRenderer.call(
    this,
    instance,
    td,
    row,
    col,
    prop,
    valueString,
    cellProperties
  );
  return td;
}

export function lsfRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.DropdownRenderer.apply(this, arguments);

  if (['LONG'].includes(value)) {
    td.className = 'long';
  } else if (['SHORT'].includes(value)) {
    td.className = 'short';
  }

  return td;
}

export function planCellRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.DropdownRenderer.apply(this, arguments);

  if (['↑', 4, 5, 'High'].includes(value)) {
    td.className = 'planLong';
  } else if (['↓', 1, 2, 'Low'].includes(value)) {
    td.className = 'planShort';
  } else if (['—', 3, 'Med'].includes(value)) {
    td.className = 'planMed';
  }

  return td;
}

export function planCellTextRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.TextRenderer.apply(this, arguments);

  if (['↑', 4, 5, '4', '5', 'High'].includes(value)) {
    td.className = 'planLong';
  } else if (['↓', 1, 2, '1', '2', 'Low'].includes(value)) {
    td.className = 'planShort';
  } else if (['—', 3, '3', 'Med'].includes(value)) {
    td.className = 'planMed';
  }

  return td;
}

export function longTextShowRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.HtmlRenderer.apply(this, [
    instance,
    td,
    row,
    col,
    prop,
    `<div class="planLongTextShow" title="${value}">${
      _.isEmpty(value) ? '' : value
    }</div>`,
    cellProperties
  ]);
}
