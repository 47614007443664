import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import config from 'common/config';
import client from '../../api/client';
import _ from 'lodash';
import ShowRelPostionDialog from './ShowRelPostionDialog';

class PushVotesQueryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      showPosDialog: false
    };
  }

  componentDidMount() {
    this._loadData({});
    this.props.loadPushVotesPmList({ role: 'PortMngr' });
    this.props.loadPushVotesAnalystList({ role: 'Analyst' });
  }

  _getCurrentItemTicker = pushVotesSelectItem => {
    const tickerList = [];
    const { type, tickerLong, tickerShort } = pushVotesSelectItem;
    if (['Alpha Long', 'Pair Trade'].includes(type)) {
      tickerList.push(tickerLong);
    }
    if (['Alpha Short', 'Pair Trade'].includes(type)) {
      tickerList.push(tickerShort);
    }
    return tickerList;
  };

  _checkTickerList = async list => {
    try {
      const { data } = await client.getLastCompanyList({ dayOffset: 1 });
      const { tickerList, check } = data;
      if (!check) return true;
      if (_.isEmpty(tickerList)) return false;
      const filterData = tickerList.filter(r => list.includes(r));
      return filterData.length === list.length;
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  onQueryParamsChange = ({ name, value }) => {
    this.props.changeMonthlyReviewQueryParams({
      name,
      value
    });
  };

  _addBtnClicked = () => {
    const { user, pushVotesModal } = this.props;
    pushVotesModal.data.isObserver = false;
    const data = {
      ...pushVotesModal.data,
      analystName: user.englishName,
      presentation: 'N'
    };
    this.props.changePushVotesModalInput(data);
    this.props.openPushVotesModal({ key: 'op', value: 'ADD' });
  };

  _addObserverBtnClicked = () => {
    const { user, pushVotesModal } = this.props;
    pushVotesModal.data.isObserver = true;
    const data = {
      ...pushVotesModal.data,
      analystName: user.englishName,
      presentation: 'N'
    };
    this.props.changePushVotesModalInput(data);
    this.props.openPushVotesModal({ key: 'op', value: 'ADD' });
  };

  _updateBtnClicked = () => {
    const { pushVotesSelectItem } = this.props;
    if (!pushVotesSelectItem) return;
    this.props.changePushVotesModalInput(pushVotesSelectItem);
    this.props.openPushVotesModal({ key: 'op', value: 'UPDATE' });
  };

  _detailBtnClicked = () => {
    const { pushVotesSelectItem, setPushVotesRecordRead } = this.props;
    if (!pushVotesSelectItem) return;

    Promise.all([
      client.readRecordDetailInfo({ id: pushVotesSelectItem.id }),
      client.queryPushVoteNoteList(pushVotesSelectItem)
    ])
      .then(([recordRes, noteRes]) => {
        pushVotesSelectItem.pushVoteNoteData = noteRes.data;
        this.props.changePushVotesModalInput(pushVotesSelectItem);
        this.props.openPushVotesModal({ key: 'op', value: 'DETAIL' });
        setPushVotesRecordRead(pushVotesSelectItem.id);
      })
      .catch(err => {
        console.log(err);
        console.log('test test');
      });
  };

  _loadData = () => {
    this.props.loadPushVotesData({});
  };

  _showForPM = () => {
    // const { user } = this.props;
    // const role = user ? user.role : null;
    // return role === 'PortMngr';
    return false;
  };

  _closeBtnClicked = async () => {
    const { pushVotesSelectItem } = this.props;
    if (!pushVotesSelectItem) return;
    const tickerList = this._getCurrentItemTicker(pushVotesSelectItem);
    const flag = await this._checkTickerList(tickerList);
    if (!flag) {
      Modal.warn({
        width: '500px',
        title: 'Close forbid',
        content: `No company score record find for ${_.join(
          tickerList,
          '/'
        )}.Please update your Company Score.`
      });
      return;
    }
    this.props.changePushVotesModalInput(pushVotesSelectItem);
    this.props.openPushVotesModal({ key: 'op', value: 'CLOSE' });
  };

  _addNoteBtnClicked = async () => {
    const { pushVotesSelectItem } = this.props;
    if (!pushVotesSelectItem) return;

    const { user, pushVotesModal } = this.props;
    const data = {
      ...pushVotesModal.data,
      analystName: user.englishName,
      pushVoteInfoId: pushVotesSelectItem.id,
      tickerLong: pushVotesSelectItem.tickerLong,
      tickerShort: pushVotesSelectItem.tickerShort
    };
    this.props.changePushVoteNoteModalInput(data);
    this.props.openPushVoteNoteModal({ key: 'op', value: 'ADD' }, data);
  };

  openHelpDocument = () => {
    let host = window.location.host;
    let uri = config.api.RESEARCH_URL;
    if (host.indexOf('ims') === 0) {
      host = 'https://' + host;
    } else {
      host = 'http://' + host;
    }
    uri = uri === '/rsrch' ? host + uri : uri;

    const url = `https://view.officeapps.live.com/op/view.aspx?src=${uri}/pushVotes/viewHelpDocument`;
    window.open(url);
  };

  _adjustPositionBtnClicked = async () => {
    const { pushVotesSelectItem } = this.props;
    if (!pushVotesSelectItem) return;
    const tickerList = this._getCurrentItemTicker(pushVotesSelectItem);
    const flag = await this._checkTickerList(tickerList);
    if (!flag) {
      Modal.warn({
        width: '500px',
        title: 'Adjust forbid',
        content: `No company score record find for ${_.join(
          tickerList,
          '/'
        )}.Please update your Company Score.`
      });
      return;
    }
    this.props.changePushVotesModalInput(pushVotesSelectItem);
    this.props.openPushVotesModal({ key: 'op', value: 'ADJUST' });
  };

  enableCloseAndAdjustBtn = () => {
    const { pushVotesSelectItem } = this.props;
    return (
      pushVotesSelectItem &&
      pushVotesSelectItem.status === 'SUBMIT' &&
      pushVotesSelectItem.isActive === 1
    );
  };

  enableUpdateBtn = () => {
    const { pushVotesSelectItem } = this.props;
    return (
      pushVotesSelectItem &&
      ['NEW', 'SUBMIT'].includes(pushVotesSelectItem.status) &&
      pushVotesSelectItem.isActive === 1
    );
  };

  render() {
    const { isLoaded } = this.props;

    return (
      <div>
        <div
          style={{
            textAlign: 'right',
            backgroundColor: '#1B1C1D',
            marginBottom: '2px'
          }}
        >
          {/* {this._showForPM() && (
            <Select
              placeholder="Analyst"
              style={{ width: '150px', marginRight: '10px' }}
              onChange={(e, { value }) => this.onRecommendedSizingChange(value)}
            />
          )} */}

          <Button
            size="mini"
            content="Reload"
            color="green"
            disabled={!isLoaded}
            style={{ marginBottom: '4px', marginTop: '4px' }}
            onClick={this._loadData}
          />

          {!this._showForPM() && (
            <Button
              size="mini"
              content="Add"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this._addBtnClicked}
            />
          )}

          {!this._showForPM() && (
            <Button
              size="mini"
              content="Add Observing Idea"
              color="green"
              disabled={!isLoaded}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this._addObserverBtnClicked}
            />
          )}

          {!this._showForPM() && (
            <Button
              size="mini"
              content="Update"
              color="green"
              disabled={!isLoaded || !this.enableUpdateBtn()}
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this._updateBtnClicked}
            />
          )}

          {!this._showForPM() && (
            <Button
              disabled={!this.enableCloseAndAdjustBtn() || !isLoaded}
              size="mini"
              content="Close"
              color="green"
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this._closeBtnClicked}
            />
          )}

          {/* {!this._showForPM() && (
            <Button
              disabled={!this.enableCloseAndAdjustBtn() || !isLoaded}
              size="mini"
              content="Adjust Position"
              color="green"
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this._adjustPositionBtnClicked}
            />
          )} */}

          {!this._showForPM() && (
            <Button
              disabled={!isLoaded}
              size="mini"
              content="Add Note"
              color="green"
              style={{ marginBottom: '4px', marginTop: '4px' }}
              onClick={this._addNoteBtnClicked}
            />
          )}

          <Button
            size="mini"
            content="Detail"
            color="green"
            disabled={!isLoaded}
            style={{ marginBottom: '4px', marginTop: '4px' }}
            onClick={this._detailBtnClicked}
          />

          <QuestionCircleOutlined
            style={{
              width: '28px',
              height: '28px',
              fontSize: '20px',
              color: 'white'
            }}
            onClick={this.openHelpDocument}
          />
        </div>
      </div>
    );
  }
}

export default PushVotesQueryHeader;
