const Rule = {
  emptyForm() {
    return {
      code: undefined,
      trader: undefined,
      criteria: undefined,
      isActive: false,
      target: null,
      priority: 0
    };
  }
};

export default Rule;
