import React, { Component } from 'react';
import { Modal, Button, Checkbox, Input, InputNumber, Tabs } from 'antd';
import SplitPane from 'react-split-pane';
import { Message } from 'semantic-ui-react';
import { DIALOG_PRICE_TRACKING_MODAL } from '../portfolioConstants';
import _ from 'lodash';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import {
  priceTraceingColumns,
  pairPosColumns,
  pairPriceTraceingColumns
} from './GridColumnMap';
import client from '../api/client';
import orderClient from 'features/order/api/client';
import PosCalculator from '../entities/calculators/PosCalculator';

class PriceTrackingDialog extends Component {
  constructor(props) {
    super(props);
    this.hotTblRef = React.createRef();
    this.hotPairTblRef = React.createRef();
    this.hotPairPosTblRef = React.createRef();
    this.state = {
      closingLineGridWrapperStyle: {
        width: '100%',
        height: '450px',
        marginTop: '5px',
        border: 'solid 2px grey',
        padding: '2px'
      },
      pairGridWrapperStyle: {
        width: '100%',
        height: '100%',
        marginTop: '5px',
        border: 'solid 2px grey',
        padding: '2px'
      },
      submitStatus: 'READY',
      data: [],
      pairTrackingData: [],
      copyToAll: true,
      filterTicker: null,
      errors: [],
      selectedCell: {
        row: 0,
        col: 0
      },
      selectedPairCell: {
        row: 0,
        col: 0
      },
      defaultPctConfig: {
        upPct: 30,
        downPct: 30
      },
      activeTabKey: 'single',
      loadedSingle: false,
      loadedPair: false
    };
  }

  componentDidMount() {
    const params = this._initFundBook();
    this._initTrackPrice(params);
    this._initPairTrackPrice(params);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.loadedSingle || !this.state.loadedPair) {
      return true;
    }
    if (!_.isEqual(this.state, nextState)) {
      return true;
    }
    return false;
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_PRICE_TRACKING_MODAL);
  };

  _initFundBook = () => {
    const { positions, ui, settings, riskInfoMap } = this.props;
    const { fundBooks, user } = settings;
    let selectedFundBook = `${ui.selectedFund}-${ui.selectedBook}`;
    const manageFundBookMap = {};

    const values = _.values(fundBooks).filter(
      r =>
        (user.englishName === r.bookManager &&
          r.masterFundBook === null &&
          (user.englishName !== 'Wang Qiang' && r.fundBookType === null)) ||
        (user.englishName === 'Wang Qiang' &&
          r.masterFundBook === null &&
          r.fundBookType === 'W')
    );
    values.forEach(r => {
      const fundBook = r.fundBookType
        ? `${r.fundCode}-${r.fundBookType}`
        : r.code;
      selectedFundBook =
        selectedFundBook === r.code ? fundBook : selectedFundBook;
      manageFundBookMap[r.code] = {
        fundBook,
        fundCode: r.fundCode,
        bookCode: r.fundBookType ? r.fundBookType : r.bookCode
      };
    });

    const arr = selectedFundBook.split('-');
    const bookCode = arr[0] === arr[1] ? 'W' : arr[1];
    const currentFundBook = { fundCode: arr[0], bookCode };

    let filterPositionList = positions.filter(
      r =>
        manageFundBookMap[`${r.fundCode}-${r.bookCode}`] &&
        this._filterPosition(r) &&
        !_.isEmpty(r.ticker)
    );
    filterPositionList = filterPositionList.map(r => {
      const riskInfo = riskInfoMap.byKey[`${r.fundCode}-${r.bookCode}`];
      const pos = PosCalculator.calcSingle(r, riskInfo);
      const item = manageFundBookMap[`${r.fundCode}-${r.bookCode}`];
      return {
        ...pos,
        extBookCode: item.bookCode
      };
    });
    filterPositionList = filterPositionList.filter(
      r => r.extBookCode === currentFundBook.bookCode
    );

    return {
      selectedFundBook,
      filterPositionList,
      manageFundBookMap,
      currentFundBook
    };
  };

  _filterPosition = p => {
    const { ui } = this.props;
    return (
      p.fundCode === ui.selectedFund &&
      (!ui.excludeCashPos
        ? true
        : !['TRD_CSH', 'FX_PROP', 'FWRD_PROP', 'FWRD_HEDGE', 'PTH'].includes(
            p.positionTypeCode
          ))
    );
  };

  _initTrackPrice = positionData => {
    const {
      filterPositionList,
      currentFundBook,
      manageFundBookMap
    } = positionData;
    const { ui } = this.props;
    Promise.all([
      client.loadTrackPriceList(currentFundBook),
      client.loadDefaultTrackPriceConfig(currentFundBook)
    ])
      .then(async ([resp, defaultPctConfig]) => {
        const trackPriceMap = {};
        const selfMonitorData = [];
        if (resp && resp.length > 0) {
          resp.forEach(item => {
            if (item.securityId) {
              const key = item.securityId;
              trackPriceMap[key] = item;
            } else {
              selfMonitorData.push({
                ticker: item.bbgTicker,
                strategy: item.strategy,
                quantityDirection: item.direction,
                initBasePrice: item.basePrice,
                basePrice: item.basePrice,
                securityCode: item.displayCode,
                upPct: item.upPct,
                downPct: item.downPct
              });
            }
          });
        }
        if (!_.isEmpty(selfMonitorData)) {
          const bbgTickers = selfMonitorData.map(r => ({
            ticker: r.ticker
          }));
          const resp = await orderClient.getSecurities(bbgTickers);
          if (!_.isEmpty(resp)) {
            resp.forEach(r => {
              selfMonitorData.forEach(item => {
                if (item.ticker === r.ticker) {
                  item.endPriceLocal = r.price;
                }
              });
            });
          }
        }

        const selectedPosition = ui.selectedPosition;
        selectedPosition &&
          filterPositionList.forEach(item => {
            if (
              item.ticker === selectedPosition.ticker &&
              item.bookCode === selectedPosition.bookCode
            ) {
              item.sortWeight = 100;
            } else {
              item.sortWeight = 0;
            }
          });

        let data = _.orderBy(
          filterPositionList,
          ['sortWeight', 'bookCode', 'quantityDirection', 'quantityEnd'],
          ['desc', 'asc', 'asc', 'desc']
        );
        data.forEach(item => {
          const securityId = item.securityId;
          const detail = trackPriceMap[securityId];
          if (detail) {
            const basePrice = detail.basePrice
              ? _.round(detail.basePrice, 2)
              : detail.basePrice;
            item.basePrice = basePrice;
            item.initBasePrice = basePrice;
            item.upPct = detail.upPct;
            item.downPct = detail.downPct;
          } else {
            const basePrice = item.avgCostLocal
              ? _.round(item.avgCostLocal, 2)
              : item.avgCostLocal;
            item.basePrice = basePrice;
            item.initBasePrice = basePrice;
          }
        });

        const updateData = [...data, ...selfMonitorData].filter(r =>
          this.filterPosition(r)
        );
        this.setState({
          data: updateData,
          loadedSingle: true,
          defaultPctConfig,
          manageFundBookMap,
          currentFundBook
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _initPairTrackPrice = () => {
    Promise.all([
      client.loadPairTrackPriceList({ bookCode: this.props.ui.selectedBook })
    ])
      .then(async ([resp]) => {
        this.setState({
          loadedPair: true,
          pairTrackingData: resp
        });
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  filterPosition = r => {
    const { info = {} } = this.props;
    const { filterTickers = [] } = info;
    const ticker = r.ticker;
    return _.isEmpty(filterTickers) || filterTickers.indexOf(ticker) >= 0;
  };

  _createPairPriceTraceingGridSettings = () => {
    const columns = pairPriceTraceingColumns.map(c => {
      return {
        ...c
      };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu: {
        items: {
          add_row: {
            name: 'Add row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._addPairRow(), 100);
            }
          },
          remove_row: {
            name: 'Remove row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._removePairRow(), 100);
            }
          },
          clear_row: {
            name: 'Clear',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._clearPairUpDown(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _createPriceTraceingGridSettings = () => {
    const columns = priceTraceingColumns.map(c => {
      return {
        ...c
      };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      contextMenu: {
        items: {
          add_row: {
            name: 'Add row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._addRow(), 100);
            }
          },
          remove_row: {
            name: 'Remove row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._removeRow(), 100);
            }
          },
          clear_row: {
            name: 'Clear',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._clearUpDown(), 100);
            }
          }
        }
      },
      columnSorting: {
        indicator: false,
        headerAction: true
      }
    });
  };

  _addRow = () => {
    const { data } = this.state;
    const { row } = this.state.selectedCell;
    const rowData = {
      ticker: null,
      strategy: 'NONE',
      notnlMktValBookPct: null,
      avgCostLocal: null
    };
    const updateData = [...data];
    updateData.splice(row + 1, 0, rowData);
    this.setState({
      data: updateData
    });
  };

  _addPairFromPos = () => {
    const selectedRows = this.hotPairPosTblRef.current.hotInstance.getSelected();
    if (selectedRows.length === 2) {
      const { positions } = { ...this.props };
      let longRow = positions[selectedRows[0][0]];
      let shortRow = positions[selectedRows[1][0]];
      if (longRow.quantityEnd < 0 && shortRow.quantityEnd > 0) {
        [longRow, shortRow] = [shortRow, longRow];
      }
      // this._addPairRow();
      let pairTrackingData = [...this.state.pairTrackingData];
      pairTrackingData.splice(pairTrackingData.length, 0, {});
      let newRow = pairTrackingData[pairTrackingData.length - 1];
      newRow.strategy = longRow.strategy;
      newRow.longTicker = longRow.ticker;
      newRow.longSecurityCode = longRow.securityCode;
      newRow.longBasePrice = longRow.endPriceLocal;
      newRow.shortTicker = shortRow.ticker;
      newRow.shortSecurityCode = shortRow.securityCode;
      newRow.shortBasePrice = shortRow.endPriceLocal;
      newRow.longShortRatio = 1;
      newRow.valid = 1;
      newRow.bookCode = longRow.bookCode;
      newRow.upPct = this.state.defaultPctConfig.upPct;
      newRow.downPct = this.state.defaultPctConfig.downPct;
      this.setState({
        pairTrackingData: pairTrackingData
      });
    } else {
      const errors = ['Please select TWO rows to create pair price track!'];
      this.setState({
        errors
      });
    }
  };

  _addPairRow = () => {
    const { pairTrackingData } = this.state;
    const { row } = this.state.selectedPairCell;
    const rowData = {
      strategy: 'NONE',
      longShortRatio: 1,
      upPct: this.state.defaultPctConfig.upPct,
      downPct: this.state.defaultPctConfig.downPct
    };
    const updateData = [...pairTrackingData];
    updateData.splice(row + 1, 0, rowData);
    this.setState({
      pairTrackingData: updateData
    });
  };

  _removeRow = () => {
    const { data } = this.state;
    const { row } = this.state.selectedPairCell;
    const selectedRange = this._getSelectedRange(this.hotTblRef);
    const removeIndexList = selectedRange ? [...selectedRange, row] : [];
    const updateData = data.map((r, index) => {
      if (removeIndexList.indexOf(index) >= 0) {
        return {
          ...r,
          upPct: null,
          downPct: null,
          isRemoved: true
        };
      }
      return {
        ...r
      };
    });
    this.setState({
      data: updateData
    });
  };

  _removePairRow = () => {
    const { pairTrackingData } = this.state;
    const { row } = this.state.selectedPairCell;
    const selectedRange = this._getSelectedRange(this.hotPairTblRef);
    const removeIndexList = selectedRange ? [...selectedRange, row] : [];
    const updateData = pairTrackingData.map((r, index) => {
      if (removeIndexList.indexOf(index) >= 0) {
        return {
          ...r,
          upPct: null,
          downPct: null,
          isRemoved: true
        };
      }
      return {
        ...r
      };
    });
    this.setState({
      pairTrackingData: updateData
    });
  };

  _clearUpDown = () => {
    const { data } = this.state;
    const selectedRange = this._getSelectedRange(this.hotTblRef);
    const updateData = data.map((r, index) => {
      if (selectedRange.indexOf(index) >= 0) {
        return {
          ...r,
          upPct: null,
          downPct: null
        };
      }
      return {
        ...r
      };
    });
    this.setState({
      data: updateData
    });
  };

  _clearPairUpDown = () => {
    const { pairTrackingData } = this.state;
    const selectedRange = this._getSelectedRange(this.hotPairTblRef);
    const updateData = pairTrackingData.map((r, index) => {
      if (selectedRange.indexOf(index) >= 0) {
        return {
          ...r,
          upPct: null,
          downPct: null
        };
      }
      return {
        ...r
      };
    });
    this.setState({
      pairTrackingData: updateData
    });
  };

  _getSelectedRange = tableRef => {
    const selectedRange = tableRef.current.hotInstance.getSelectedRange();
    const selectedRows = [];
    if (!_.isEmpty(selectedRange)) {
      selectedRange.forEach(r => {
        const { from, to } = r;
        const startRow = from.row;
        const endRow = to.row;
        for (let start = startRow; start <= endRow; start++) {
          selectedRows.push(start);
        }
      });
    }
    return selectedRows;
  };

  _onCellMouseDown = (event, coords, TD) => {
    _.delay(() => {
      this.setState({
        selectedCell: coords
      });
    }, 100);
  };

  _onPairCellMouseDown = (event, coords, TD) => {
    _.delay(() => {
      this.setState({
        selectedPairCell: coords
      });
    }, 100);
  };

  createHotTable = data => {
    const { closingLineGridWrapperStyle } = this.state;
    const setting = this._createPriceTraceingGridSettings();
    return (
      !_.isEmpty(data) && (
        <div style={closingLineGridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={data}
            manualColumnResize={true}
            {...setting}
            afterChange={this._afterCellChange}
            afterOnCellMouseDown={this._onCellMouseDown}
          ></HotTable>
        </div>
      )
    );
  };

  _createPairPosGridSettings = () => {
    const columns = pairPosColumns.map(c => {
      return {
        ...c
      };
    });
    return hotTableUtils.createSettings({
      columns,
      rowHeaders: true,
      columnSorting: {
        indicator: false,
        headerAction: true
      },
      contextMenu: {
        items: {
          add_row: {
            name: 'Add Pair',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._addPairFromPos(), 100);
            }
          }
        }
      }
    });
  };

  createPairPosHotTable = data => {
    const { pairGridWrapperStyle } = this.state;
    const setting = this._createPairPosGridSettings();
    return (
      !_.isEmpty(data) && (
        <div style={pairGridWrapperStyle}>
          <HotTable
            ref={this.hotPairPosTblRef}
            data={data}
            manualColumnResize={true}
            {...setting}
          ></HotTable>
        </div>
      )
    );
  };

  createPairHotTable = data => {
    const { pairGridWrapperStyle } = this.state;
    const setting = this._createPairPriceTraceingGridSettings();
    return (
      <div style={pairGridWrapperStyle}>
        <HotTable
          ref={this.hotPairTblRef}
          data={data}
          manualColumnResize={true}
          {...setting}
          afterChange={this._afterPairCellChange}
          afterOnCellMouseDown={this._onPairCellMouseDown}
        ></HotTable>
      </div>
    );
  };

  _afterCellChange = async (changes, action) => {
    if (action === 'edit' || action === 'Autofill.fill') {
      const { data } = this.state;
      const updateData = [...data];
      const realChanges = changes.filter(
        ([, , oldValue, newValue]) => oldValue !== newValue
      );
      if (_.isEmpty(realChanges)) return;
      let newTicker = null;
      if (realChanges[0][1] === 'ticker') {
        newTicker = realChanges[0][3];
        if (newTicker === realChanges[0][2]) return;
        if (newTicker) {
          const [security] = await orderClient.getSecurities([
            { ticker: newTicker }
          ]);
          if (security && security.name) {
            updateData.forEach(r => {
              if (r.ticker === newTicker) {
                r.securityCode = security.name;
                r.endPriceLocal = security.price;
                r.basePrice = security.price;
              }
            });
          }
        }
      }

      const errors = this._validateData();
      this.setState({
        data: updateData,
        errors
      });
    }
  };

  _afterPairCellChange = async (changes, action) => {
    if (action === 'edit' || action === 'Autofill.fill') {
      const { pairTrackingData } = this.state;
      const updateData = [...pairTrackingData];
      const realChanges = changes.filter(
        ([, , oldValue, newValue]) => oldValue !== newValue
      );
      if (_.isEmpty(realChanges)) return;
      let newTicker = null;
      if (realChanges[0][1] === 'longTicker') {
        newTicker = realChanges[0][3];
        if (newTicker === realChanges[0][2]) return;
        if (newTicker) {
          const [security] = await orderClient.getSecurities([
            { ticker: newTicker }
          ]);
          if (security && security.name) {
            updateData.forEach(r => {
              if (r.longTicker === newTicker) {
                r.longSecurityCode = security.name;
                r.longBasePrice = security.price;
              }
            });
          }
        }
      } else if (realChanges[0][1] === 'shortTicker') {
        newTicker = realChanges[0][3];
        if (newTicker === realChanges[0][2]) return;
        if (newTicker) {
          const [security] = await orderClient.getSecurities([
            { ticker: newTicker }
          ]);
          if (security && security.name) {
            updateData.forEach(r => {
              if (r.shortTicker === newTicker) {
                r.shortSecurityCode = security.name;
                r.shortBasePrice = security.price;
              }
            });
          }
        }
      }

      const errors = this._validateData();
      this.setState({
        data: updateData,
        errors
      });
    }
  };

  _validateData = () => {
    const { data } = this.state;
    const errors = [];
    data.forEach(r => {
      if (
        !_.isEmpty(r.downPct) &&
        !_.isFinite(_.toNumber(r.downPct)) &&
        errors.indexOf('DownPct must be a number') < 0
      ) {
        errors.push('DownPct must be a number');
      }
      if (
        !_.isEmpty(r.upPct) &&
        !_.isFinite(_.toNumber(r.upPct)) &&
        errors.indexOf('UpPct must be a number') < 0
      ) {
        errors.push('UpPct must be a number');
      }
      if (
        r.downPct !== undefined &&
        r.downPct !== null &&
        r.downPct !== '' &&
        r.downPct <= 0 &&
        errors.indexOf('DownPct must be greater than 0') < 0
      ) {
        errors.push('DownPct must be greater than 0');
      }
      if (
        r.upPct !== undefined &&
        r.upPct !== null &&
        r.upPct !== '' &&
        r.upPct <= 0 &&
        errors.indexOf('UpPct must be greater than 0') < 0
      ) {
        errors.push('UpPct must be greater than 0');
      }
    });
    return errors;
  };

  _createErrorPanel = () => {
    const { errors } = this.state;
    const errorMsgs = Object.values(errors);

    return (
      <div style={{ marginTop: '10px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  onCopyToAllChange = e => {
    this.setState({
      copyToAll: e.target.checked
    });
  };

  _createCopyToAllCheckBox = () => {
    return (
      <Checkbox
        key="copyToAllCheckBox"
        defaultChecked
        onChange={this.onCopyToAllChange}
      >
        Copy to all books
      </Checkbox>
    );
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, data } = this.state;
    const submitBtn = {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];

    return _.isEmpty(data) ? '' : submitBtn;
  };

  _onSubmit = () => {
    const { data, activeTabKey, pairTrackingData } = this.state;
    if (activeTabKey === 'single') {
      this._submitSingle(data);
    } else {
      console.info('submit pair');
      this._submitPair(pairTrackingData);
    }
  };

  _submitPair = (data, defaultPctConfig) => {
    if (_.isEmpty(data)) return;
    const errors = this._validateData();
    if (!_.isEmpty(errors)) {
      this.setState({
        errors
      });
      return;
    }
    const fundBookList = this._buildSubmitFundBookList();
    const submitData = data;
    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .savePairTrackPriceList({
        fundBookList,
        data: submitData,
        defaultConfig: defaultPctConfig
      })
      .then(() => {
        this.setState({ submitStatus: 'READY' });
        this.closeDialog();
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _submitSingle = (data, defaultPctConfig) => {
    if (_.isEmpty(data)) return;
    const errors = this._validateData();
    if (!_.isEmpty(errors)) {
      this.setState({
        errors
      });
      return;
    }
    const fundBookList = this._buildSubmitFundBookList();
    const submitData = data.map(r => {
      const securityId = r.securityId;
      const bbgTicker = r.ticker;
      const displayCode = r.securityCode;
      const initBasePrice = r.initBasePrice;
      const basePrice = r.basePrice;
      const upPct = r.upPct ? r.upPct : null;
      const downPct = r.downPct ? r.downPct : null;
      const direction = r.quantityDirection;
      const strategy = r.strategy;
      const isRemoved = r.isRemoved;
      const type = _.isNil(r.notnlMktValBookPct) ? 'MANUAL' : 'POS';
      return {
        type,
        securityId,
        bbgTicker,
        displayCode,
        basePrice,
        upPct,
        downPct,
        direction,
        strategy,
        isRemoved,
        initBasePrice
      };
    });
    this.setState({ submitStatus: 'SUBMITTING' });
    client
      .saveTrackPriceList({
        fundBookList,
        data: submitData,
        defaultConfig: defaultPctConfig
      })
      .then(() => {
        this.setState({ submitStatus: 'READY' });
        this.closeDialog();
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ submitStatus: 'ERROR' });
      });
  };

  _buildSubmitFundBookList = () => {
    const { currentFundBook, manageFundBookMap, copyToAll } = this.state;
    if (copyToAll) {
      const fundBookMap = {};
      _.map(manageFundBookMap, (value, key) => {
        fundBookMap[value.bookCode] = null;
      });
      return _.keys(fundBookMap);
    } else {
      return [currentFundBook.bookCode];
    }
  };

  handleTabChange = key => {
    // Update the active tab key when a tab is changed
    this.setState({ activeTabKey: key });
  };

  onFilterTickerChange = e => {
    const value = e.target.value ? _.upperCase(e.target.value) : null;
    this.setState({
      filterTicker: value
    });
  };

  onInputChange = (value, name) => {
    const { defaultPctConfig } = this.state;
    const updateData = {
      ...defaultPctConfig,
      [name]: value
    };
    this.setState({
      defaultPctConfig: updateData
    });
  };

  _createFilterInput = () => {
    const { filterTicker, defaultPctConfig } = this.state;
    return (
      <div style={{ width: '100%', height: '30px' }}>
        <div style={{ textAlign: 'left', float: 'left', width: '20%' }}>
          <Input
            placeholder="filter by ticker"
            style={{ width: '250px' }}
            value={filterTicker}
            onChange={this.onFilterTickerChange}
          />
        </div>
        <div style={{ textAlign: 'right', float: 'right', width: '80%' }}>
          <span style={{ marginRight: '10px' }}>
            <b>
              Default price alert point is +/- 30% of base price, which is your
              trade filled price.
            </b>
          </span>
          <span>Change UpPct: </span>
          <InputNumber
            min={0}
            max={1000}
            value={defaultPctConfig.upPct}
            onChange={value => {
              this.onInputChange(value, 'upPct');
            }}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
          />

          <span style={{ marginLeft: '7px' }}>Change DwonPct: </span>
          <InputNumber
            min={0}
            max={1000}
            onChange={value => {
              this.onInputChange(value, 'downPct');
            }}
            value={defaultPctConfig.downPct}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
          />
        </div>
      </div>
    );
  };

  render() {
    const { data, filterTicker, pairTrackingData } = this.state;
    let filterData =
      data && filterTicker
        ? data.filter(r => _.upperCase(r.ticker).indexOf(filterTicker) >= 0)
        : data;
    filterData = filterData
      ? filterData.filter(r => !r.isRemoved || !_.isNil(r.notnlMktValBookPct))
      : [];
    const { positions } = { ...this.props };
    let filterPairTrackingData = pairTrackingData.filter(r => !r.isRemoved);
    return (
      <div>
        <style>
          {`
                .ui.modals {
                  display: flex !important;
                }
                .ui.modal {
                  margin-top: 0px !important;
                }
              `}
        </style>
        <Modal
          width={1600}
          maskClosable={false}
          title={`Chang price alert point (修改价格提醒点位)`}
          visible={true}
          onOk={this.closeDialog}
          onCancel={this.closeDialog}
          footer={[
            this._createCopyToAllCheckBox(),
            this._createSubmitBtn(this._onSubmit),
            <Button
              key="close"
              type="primary"
              onClick={this.closeDialog}
              style={{ marginLeft: '10px' }}
            >
              Close
            </Button>
          ]}
        >
          <Tabs
            tabPosition="top"
            style={{ width: '100%', height: '450px' }}
            onChange={this.handleTabChange}
          >
            <Tabs.TabPane
              tab={<span style={{ color: 'black' }}>Single</span>}
              key="single"
            >
              {this._createFilterInput()}
              {this.createHotTable(filterData)}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<span style={{ color: 'black' }}>Pair</span>}
              key="pair"
              style={{ width: '100%', height: '100%' }}
            >
              <div
                style={{ position: 'relative', width: '100%', height: '100%' }}
              >
                <SplitPane
                  split="horizontal"
                  defaultSize="50%"
                  style={{ width: '100%', height: '450px' }}
                >
                  {this.createPairPosHotTable(positions)}
                  {this.createPairHotTable(filterPairTrackingData)}
                </SplitPane>
              </div>
            </Tabs.TabPane>
          </Tabs>

          {this._createErrorPanel()}
        </Modal>
      </div>
    );
  }
}

export default PriceTrackingDialog;
