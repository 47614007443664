import spected from 'spected';
import _ from 'lodash';

export const HARD_RULE = 'HARD';
export const CONTRACT_HARD_RULE = 'CONTRACT_HARD';
export const SOFT_RULE = 'SOFT';

const _createSpec = (values, ctx) => {
  const {
    ruleType,
    custodianMap = {},
    holdings = [],
    extraHoldings = []
  } = ctx;

  // predicates
  const notEmpty = value => !!value;
  const positiveNumber = value => parseFloat(value) > 0;
  // const validInstruction = value =>
  //   values['venue'] !== 'CATS' ||
  //   ['INLINE', 'VWAP', 'TWAP']
  //     .map(v => value && value.toUpperCase().includes(v))
  //     .find(Boolean);

  // error messages
  const notEmptyMsg = field => `${field} should not be empty.`;
  const positiveNumberMsg = field => `${field} should be greated than 0.`;

  const validBroker = v => Object.keys(custodianMap).includes(v);

  // rules
  const brokerRule = [
    [notEmpty, notEmptyMsg('Broker')],
    [validBroker, 'Broker is not FTP supported.']
  ];

  const noBoxedAgainstCustodianHolding = v => {
    if (!values.broker) return true;

    // // If find matched holding, then ignore checking.
    // const holdingKey = Route.parseHoldingKey(values);
    // const matchedHolding = holdings.find(h => h.key === holdingKey);
    // if (matchedHolding) return true;

    const matchedOppositeHolding = holdings.find(
      h =>
        h.fundCode === values.fundCode &&
        h.custodianCode === values.broker &&
        h.ticker === values.ticker &&
        Math.abs(h.theoreticalQty) > 0 &&
        h.direction !== 'SHORT'
    );
    return !matchedOppositeHolding;
  };

  const sameBrokerShrtPTHHoldingCheck = v => {
    // Only check new open route.
    if (values.refId) return true;
    if (_.isEmpty(extraHoldings)) return true;
    if (!values.broker) return true;

    const matchedHolding = extraHoldings.find(
      h =>
        h.positionTypeCode === 'PTH' &&
        h.fundCode === values.fundCode &&
        h.custodianCode === values.broker &&
        h.bookCode === values.bookCode &&
        h.ticker === values.ticker
    );

    if (!_.isEmpty(matchedHolding)) return true;

    const matchedOppositeHolding = extraHoldings.find(
      h =>
        h.positionTypeCode === 'PTH' &&
        h.fundCode === values.fundCode &&
        h.custodianCode === values.broker &&
        h.bookCode !== values.bookCode &&
        h.ticker === values.ticker
    );
    return !matchedOppositeHolding;
  };

  const validCustodian = value => {
    const { fundCode } = values;
    const selectedCustodian = custodianMap[value];
    if (!selectedCustodian) return false;

    return (selectedCustodian.txnFunds || []).includes(fundCode);
  };

  const qtyRule = [
    [notEmpty, notEmptyMsg('Qty')],
    [positiveNumber, positiveNumberMsg('Qty')]
  ];

  const feeRateRule = [[notEmpty, notEmptyMsg('feeRate')]];

  const softBrokerRule = [
    [
      noBoxedAgainstCustodianHolding,
      'Might cause boxed holding under specified broker.'
    ],
    [
      validCustodian,
      `The broker might not be valid for fund ${values.fundCode}.`
    ],
    [
      sameBrokerShrtPTHHoldingCheck,
      `Might use the PTH positions of other teams.`
    ]
  ];

  const rules = {
    [HARD_RULE]: {
      broker: brokerRule,
      qty: qtyRule
    },
    [SOFT_RULE]: {
      broker: softBrokerRule
    },
    [CONTRACT_HARD_RULE]: {
      broker: brokerRule,
      qty: qtyRule,
      feeRate: feeRateRule
    }
  };

  return rules[ruleType];
};

const _extractErrors = validationResult => {
  const FIRST_ERROR = 0;
  return Object.keys(validationResult).reduce((errors, field) => {
    return validationResult[field] !== true
      ? { ...errors, [field]: validationResult[field][FIRST_ERROR] }
      : errors;
  }, {});
};

const LocateValidator = {
  validate: (values, ctx) => {
    const spec = _createSpec(values, ctx);
    const validationResult = spected(spec, values);
    return _extractErrors(validationResult);
  }
};

export default LocateValidator;
