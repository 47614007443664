import MonthlyReviewUpdateDetailPanel from './MonthlyReviewUpdateDetailPanel';
import { Modal, Button } from 'antd';
import React from 'react';

class MonthlyReviewUpdateModal extends React.Component {
  onSubmitMonthlyReviewBtnClicked = () => {
    this.props.submitMonthlyReview(
      this.props.monthlyReviewDetailData,
      this.props.monthlyReviewQueryParams
    );
  };

  handleCancel = () => {
    this.props.monthlyReviewDetailChangeInput({
      name: 'modalShow',
      value: false
    });
  };

  render() {
    const { monthlyReviewDetailData } = this.props;
    const screenHeight = window.screen.height;
    let height = '850px';
    if (screenHeight <= 768) {
      height = screenHeight * 0.65 + 'px';
    } else {
      height = screenHeight * 0.75 + 'px';
    }
    return (
      <div id="MonthlyReviewModal">
        <style>
          {`
                    .MonthlyReviewModal{
                    }

                    .MonthlyReviewModal .ant-modal-body{
                            height: ` +
            height +
            `}
                `}
        </style>
        <Modal
          size="large"
          visible={monthlyReviewDetailData.modalShow}
          width={1300}
          className={'MonthlyReviewModal'}
          style={{ height: height }}
          footer={[
            <div key="submitData" style={{ textAlign: 'right' }}>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  this.handleCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  this.onSubmitMonthlyReviewBtnClicked();
                }}
              >
                Submit
              </Button>
            </div>
          ]}
          closable={false}
        >
          <MonthlyReviewUpdateDetailPanel {...this.props} />
        </Modal>
      </div>
    );
  }
}

export default MonthlyReviewUpdateModal;
