import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

const _isGroupRow = params => {
  const { node } = params;
  return node && node.group;
};

const _isNonAggColInGroupRow = params => {
  return _isGroupRow(params) && !params.colDef.aggFunc;
};

export function nullFormatter(params) {
  if (_isGroupRow(params) || params.value) return params.value;
  if (params.value === '') return params.value;
  return 'N.A.';
}

export function notANumberFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  return _.isNil(params.value) ? 'NaN' : threeDPFormatter(params);
}

export function twoDPFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;

  if (Math.abs(params.value) < 0.0001) return '0';
  return numeral(params.value).format('0.00');
}

export function threeDPFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;

  if (Math.abs(params.value) < 0.0001) return '0';
  return numeral(params.value).format('0.000');
}

export function fxRateFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;

  return _.round(Number.parseFloat(params.value), 5);
}

export function moneyFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  if (params.value === '') return params.value;
  if (Math.abs(params.value) < 0.01) return '0';
  return numeral(params.value).format('0,0');
}

export function percentFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  if (params.value === '') return params.value;
  if (params.value === undefined || params.value === null) return 'N.A.';
  if (Math.abs(params.value) < 0.0001) return '0%';
  return numeral(params.value).format('0.00%');
}

export function percentFormatterWithSixPercent(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  if (params.value === '') return params.value;
  if (params.value === undefined || params.value === null) return 'N.A.';
  if (Math.abs(params.value) < 0.000001) return '0%';
  return numeral(params.value).format('0.000000%');
}

export function dateTimeFormatter(params) {
  if (params.value === null) {
    return params.value;
  }

  const formatTime = moment(params.value).format('YYYY-MM-DD HH:MM:ss');

  if (formatTime === 'Invalid date') {
    return params.value;
  }

  return formatTime;
}

export function dateFormatter(params) {
  if (params.value === null) {
    return params.value;
  }

  const formatTime = moment(params.value).format('YYYY/MM/DD');

  if (formatTime === 'Invalid date') {
    return params.value;
  }

  return formatTime;
}

export function riskControlLimitFormatter(params) {
  if (params.value === undefined) return null;
  return !!params.value ? 'Above' : 'Under';
}

export function nanFormatter(params) {
  return isNaN(params.value) || params.value === null
    ? params.value
    : numeral(params.value).format('0,0.00');
}

export function booleanFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  if (_.isNil(params.value)) return 'N.A.';
  return params.value ? 'Y' : 'N';
}

export function sizeBooleanFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  if (_.isNil(params.value)) return 'N.A.';
  return !_.isEmpty(params.value) ? 'Y' : 'N';
}

export function objectFormatter(params) {
  if (_isNonAggColInGroupRow(params)) return params.value;
  const stringValue = _.isEmpty(params.value)
    ? 'N.A.'
    : Object.entries(params.value)
        .map(([k, v]) => `[${k}]:${v}`)
        .join(',');

  return stringValue;
}
