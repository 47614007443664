import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../../common/ui/agGridUtils';
import { expensiveBorrowTableColumns } from 'features/oms/components/dialogs/GridColumnMap';
import NonOrderLocatesDialog from 'features/oms/components/dialogs/NonOrderLocatesDialog';
import client from 'features/oms/api/client';

class ExpBorrowGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSettings: agGridUtils.createSettings({
        columnDefs: expensiveBorrowTableColumns,
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          checkboxSelection: agGridUtils.isFirstColumn,
          headerCheckboxSelection: agGridUtils.isFirstColumn,
          headerCheckboxSelectionFilteredOnly: true,
          sortable: true,
          resizable: true,
          filter: 'agTextColumnFilter'
          // suppressMenu: true
        },
        deltaRowDataMode: false,
        getRowNodeId: data => data.id,
        showNonOrderLocateFlag: true
      }),
      data: [],
      rawData: [],
      dualList: [],
      nonDualList: [],
      locates: [],
      submitStatus: 'READY'
    };
  }

  componentDidMount() {
    this._loadData();
  }

  _loadData = () => {
    this.setState({
      data: null
    });
    client.getExpensiveBorrowRate({}).then(resp => {
      this.setState({
        data: resp,
        rawData: resp
      });
      this._parseData();
    });
    client.getAxePositionRate({}).then(resp => {
      this.setState({
        axeData: resp
      });
    });
  };

  _parseData = () => {
    for (let i = 0; i < this.state.rawData.length; ++i) {
      const d = this.state.rawData[i];
      const ticker = d.bb_TCM;
      const broker1Ticker = d.brokerRate1;
      let flag = false;
      if (broker1Ticker != null && broker1Ticker.includes(' Equity')) {
        const sTicker = broker1Ticker.split('/')[0];
        if (ticker !== sTicker) {
          flag = true;
          this.state.dualList.push(d);
        }
      }
      if (!flag) {
        this.state.nonDualList.push(d);
      }
    }
  };

  _createGrid = () => {
    const { gridSettings, data } = this.state;
    return (
      <div
        className={`ag-theme-balham-dark grid-wrapper`}
        style={{ height: '90%' }}
      >
        <AgGridReact
          // properties
          rowData={data}
          {...gridSettings}
          onSelectionChanged={this._onSelectionChanged}
          // events
          onGridReady={this._gridReady}
          onCellEditingStopped={this._onCellEditingStopped}
          getContextMenuItems={this._getExpensiveBorrowContextMenuItems}
        />
      </div>
    );
  };

  _onCellEditingStopped = params => {
    console.info(params);
    const newValue = params.value;
    if (
      newValue.indexOf(':') !== -1 &&
      params.data != null &&
      params.data.totalTradeFee != null
    ) {
      const borrowRate = parseFloat(newValue.split(':')[1].trim());
      const rateDiff = (params.data.borrowRate - borrowRate) * 0.01;
      const ratePerDay = rateDiff / 360;
      const daysNeeded = Math.ceil(params.data.totalTradeFee / ratePerDay);
      const feeBps = (params.data.totalTradeFee * 10000).toFixed(2);
      const outStr =
        params.value + '/' + feeBps + ' bps/' + daysNeeded + ' days';
      params.data.brokerRate1 = outStr;
      this.gridApi.refreshCells();
    }
  };

  _getExpensiveBorrowContextMenuItems = params => {
    const menuItems = [
      {
        name: 'Locate',
        action: () => this.setState({ showNonOrderLocateFlag: true })
      },
      {
        name: 'ShowDualListOnly',
        action: () => this._showDualListOnly()
      },
      {
        name: 'FilterDualList',
        action: () => this._filterDualList()
      },
      {
        name: 'ShowAll',
        action: () => this._showAll()
      }
    ];

    menuItems.push('export');
    return menuItems;
  };

  _showDualListOnly = params => {
    this.setState({ data: this.state.dualList });
    this._createGrid();
  };

  _filterDualList = params => {
    this.setState({ data: this.state.nonDualList });
    this._createGrid();
  };

  _showAll = params => {
    this.setState({ data: this.state.rawData });
    this._createGrid();
  };

  _gridReady = params => {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  };

  _onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    let locates = [];
    for (let i = 0; i < selectedRows.length; ++i) {
      const row = selectedRows[i];
      let locate = {
        ticker: row.bb_TCM,
        qty: row.qtyShare
      };
      this._parseBroker(row.brokerRate1, locate, row, locates);
      this._parseBroker(row.brokerRate2, locate, row, locates);
      this._parseBroker(row.brokerRate3, locate, row, locates);
    }
    this.setState({ locates: locates });
  };

  _parseBroker(broker, locate, row, locates) {
    if (broker == null) {
      return null;
    }
    const custodian = broker.split(':')[0];
    const infos = custodian.split('/');
    let eb = infos[0];
    if (infos.length > 1) {
      locate.ticker = infos[0];
      eb = infos[1];
    }
    const notes = 'EB_' + row.id + '_' + custodian;
    locates.push({ ...locate, broker: eb, notes: notes });
  }

  _closeNonLocatesDialog = () => {
    this.setState({ showNonOrderLocateFlag: false });
  };

  render() {
    return (
      <div className="treasuryDashboardWrapper">
        {this._createGrid()}
        {this.state.showNonOrderLocateFlag ? (
          <NonOrderLocatesDialog
            {...this.props}
            locates={this.state.locates}
            closeDialog={this._closeNonLocatesDialog}
          />
        ) : null}
      </div>
    );
  }
}

export default ExpBorrowGrid;
