import { connect } from 'react-redux';

import ApplyAnalystPage from '../components/analystManage/ApplyAnalystPage';

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyAnalystPage);
