import {
  moneyFormatter,
} from 'common/utils/valueFormatters';
import { numberStyle } from 'common/utils/styleChooser';

export const onShoreTradeSummmaryColumns = [
  {
    field: 'custodian',
    headerName: 'Custodian',
    enableRowGroup: true,
    cellClass: 'keyword',
    rowGroup: true,
    hide: true,
    width: 60
  },
  {
    field: 'book',
    headerName: 'Team',
    cellClass: 'keyword',
    enableRowGroup: true,
    rowGroup: true,
    width: 60
  },
  {
    field: 'exchange',
    headerName: 'Market',
    enableRowGroup: true,
    width: 60
  },
  {
    field: 'assetClass',
    headerName: 'AssetClass',
    enableRowGroup: true,
    width: 60
  },
  {
    field: 'account',
    headerName: 'Account',
    enableRowGroup: true,
    width: 60
  },
  {
    field: 'tradeAmount',
    headerName: 'Amount',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  },
  {
    field: 'tradeNums',
    headerName: 'Nums',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  },
  {
    field: 'commFee',
    headerName: 'Comm Fee',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  },
  {
    field: 'stampDutyFee',
    headerName: 'Stamp Fee',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  },
  {
    field: 'otherFee',
    headerName: 'Others',
    width: 80,
    valueFormatter: moneyFormatter,
    cellClassRules: numberStyle(),
    aggFunc: 'sum'
  }
];
