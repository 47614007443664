import React, { Component } from 'react';

import MonthlyReviewGrid from './MonthlyReviewGrid';
import MonthlyReviewDetailPanel from './MonthlyReviewDetailPanel';
import SplitPane from 'react-split-pane';
import MonthlyReviewUpdateModal from './MonthlyReviewUpdateModal';
import MonthlyReviewQueryHeader from './MonthlyReviewQueryHeader';

import './MonthlyReviewTab.css';

class MonthlyReviewTab extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <MonthlyReviewQueryHeader {...this.props} />
        <SplitPane
          split="vertical"
          defaultSize="20%"
          style={{ position: 'relative', height: '100%' }}
        >
          <div style={{ height: '100%' }}>
            <MonthlyReviewGrid {...this.props} />
          </div>
          <div style={{ height: '100%' }}>
            <MonthlyReviewDetailPanel {...this.props} />
          </div>
        </SplitPane>
        <MonthlyReviewUpdateModal {...this.props} />
      </div>
    );
  }
}

export default MonthlyReviewTab;
