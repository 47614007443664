import React, { Component } from 'react';

export default class TagComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
      colorMap: this.props.colorMap
    };
  }

  render() {
    // For group row, return null.
    const { node } = this.props;
    if (node && node.group) return null;

    const value = this.state.value || 'N.A.';
    const color = this.state.colorMap[value] || 'white';

    return (
      <div style={{ fontWeight: '700', fontStyle: 'italic', color }}>
        <span>{value}</span>
      </div>
    );
  }

  refresh(params) {
    this.setState({
      value: params.value
    });

    return true;
  }
}
