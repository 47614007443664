import React, { Component } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { Input } from 'antd';
import { Message } from 'semantic-ui-react';
import moment from 'moment';

class AddNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        ...this.props.data
      }
    };
  }

  _onSubmit = () => {
    const { currentData, closeModal, callback } = this.props;
    const { col, isOverride, value, sourceValue } = this.state.data;
    if (!currentData.notes) {
      currentData.notes = [];
    }
    const data = {
      col,
      value,
      sourceValue,
      createDate: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    if (isOverride && currentData.notes.length > 0) {
      currentData.notes[currentData.notes.length - 1] = data;
    } else {
      currentData.notes.push(data);
    }
    callback();
    closeModal();
  };

  onInputChange = ({ name, value }) => {
    const { data } = this.state;
    const updateData = {
      ...data,
      [name]: value
    };
    this.setState({
      data: updateData
    });
  };

  _createNotePanel = () => {
    const { data } = this.state;
    return (
      <div style={{ height: '90px' }}>
        <div style={{ width: '18%', float: 'left', marginTop: '5px' }}>
          <Checkbox
            value={data.isOverride}
            onChange={e => {
              this.onInputChange({
                name: 'isOverride',
                value: !data.isOverride
              });
            }}
          >
            Override last
          </Checkbox>
        </div>
        <div style={{ width: '78%', float: 'left' }}>
          <Input.TextArea
            showCount={true}
            value={data.value}
            style={{ height: 80 }}
            placeholder="input notes"
            onChange={e => {
              this.onInputChange({ name: 'value', value: e.target.value });
            }}
          />
        </div>
      </div>
    );
  };

  _createErrorPannel = () => {
    const { errorMsg } = this.state;
    if (errorMsg) {
      return <Message error list={[errorMsg]} />;
    }
  };

  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        size="large"
        visible={true}
        mask={false}
        title={`Add Notes`}
        width={800}
        footer={[
          <Button
            size="small"
            type="primary"
            onClick={() => {
              this._onSubmit();
            }}
          >
            Ok
          </Button>,
          <Button
            size="small"
            type="primary"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        ]}
        closable={false}
      >
        {this._createNotePanel()}
        {this._createErrorPannel()}
      </Modal>
    );
  }
}

export default AddNoteModal;
