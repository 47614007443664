import { createReducer } from 'common/utils/reducerUtils';
import {
  ORDER_SNAPSHOT,
  ORDER_UPDATE,
  TRADE_SELECT,
  TRADES_SELECT,
  TRADE_VIEW_MODE_HIST,
  TRADE_VIEW_MODE_LIVE,
  TRADE_VIEW_MODE_CHANGE,
  TRADE_BLOTTER_GET_REQUEST,
  TRADE_BLOTTER_GET_SUCCESS,
  TRADE_BLOTTER_GET_FAIL,
  DIALOG_EXECUTE_SINGLE_TRADE,
  DIALOG_CANCEL_TRADE,
  DIALOG_EXECUTE_MULTI_TRADES,
  DIALOG_EXECUTE_QUANT_TRADES,
  DIALOG_ADJUST_PORTFOLIO,
  TRADE_VIEW_DATE_CHANGE
} from './orderConstants';
import { DIALOG_SUBMIT_SUCCESS } from '../common/commonConstants';
import { LOGOUT_SUCCESS } from '../auth/authConstants';
import EntityMap from 'entities/EntityMap';
import { getPreviousWorkingDay } from 'common/utils/DateUtils';

const _createInitialViewState = viewMode => {
  return {
    isLoaded: viewMode === TRADE_VIEW_MODE_LIVE ? false : true,

    trades: EntityMap.fromArray([]),
    selectedTradeKey: null,
    selectedTradeKeys: null
  };
};

const initialState = {
  ui: {
    viewMode: TRADE_VIEW_MODE_LIVE,
    selectedDate: getPreviousWorkingDay()
  },

  viewState: {
    [TRADE_VIEW_MODE_LIVE]: _createInitialViewState(TRADE_VIEW_MODE_LIVE),
    [TRADE_VIEW_MODE_HIST]: _createInitialViewState(TRADE_VIEW_MODE_HIST)
  }
};

const TRADE_TABLE = 'TRADE';

const extractTblData = (snapshot, tblName, converter = obj => obj) => {
  return snapshot
    .filter(row => row.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));
};

const mergeUpdates = (target, tblName, updates, converter = obj => obj) => {
  const upsertedEntities = updates
    .filter(u => ['Update', 'Add'].includes(u.type) && u.tableName === tblName)
    .map(row => ({
      ...converter(row.cellValues)
    }));

  const deletedEntityKeys = updates
    .filter(u => u.type === 'Delete' && u.tableName === tblName)
    .map(u => u.key);

  return EntityMap.remove(
    EntityMap.merge(target, upsertedEntities),
    deletedEntityKeys
  );
};

export function initOrder(state, payload) {
  const { selectedTradeKey } = state.viewState[TRADE_VIEW_MODE_LIVE];
  const trades = extractTblData(payload, TRADE_TABLE);
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [TRADE_VIEW_MODE_LIVE]: {
        isLoaded: true,
        trades: EntityMap.fromArray(trades),
        selectedTradeKey
      }
    }
  };
}

export function updateOrder(state, payload) {
  const {
    isLoaded,
    trades,
    selectedTradeKey,
    selectedTradeKeys
  } = state.viewState[TRADE_VIEW_MODE_LIVE];

  const updatedTrades = mergeUpdates(trades, TRADE_TABLE, payload);

  return {
    ...state,
    viewState: {
      ...state.viewState,
      [TRADE_VIEW_MODE_LIVE]: {
        isLoaded,
        trades: updatedTrades,
        selectedTradeKey,
        selectedTradeKeys
      }
    }
  };
}

export function logoutSuccess() {
  return initialState;
}

export function selectTrade(state, payload) {
  const { viewMode } = state.ui;

  return {
    ...state,
    viewState: {
      ...state.viewState,
      [viewMode]: {
        ...state.viewState[viewMode],
        selectedTradeKey: payload
      }
    }
  };
}

export function selectTrades(state, payload) {
  const { viewMode } = state.ui;

  return {
    ...state,
    viewState: {
      ...state.viewState,
      [viewMode]: {
        ...state.viewState[viewMode],
        selectedTradeKeys: payload
      }
    }
  };
}

export function toggleViewMode(state) {
  return {
    ...state,
    ui: {
      ...state.ui,
      viewMode:
        state.ui.viewMode === TRADE_VIEW_MODE_LIVE
          ? TRADE_VIEW_MODE_HIST
          : TRADE_VIEW_MODE_LIVE
    }
  };
}

export function changeViewDate(state, payload) {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedDate: payload
    }
  };
}

export function requestGetTradeBlotter(state) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [TRADE_VIEW_MODE_HIST]: {
        ...initialState.viewState[TRADE_VIEW_MODE_HIST],
        isLoaded: false
      }
    }
  };
}

export function getTradeBlotterSuccess(state, payload) {
  const trades = payload;
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [TRADE_VIEW_MODE_HIST]: {
        isLoaded: true,

        trades: EntityMap.fromArray(trades),
        selectedTradeKey: null
      }
    }
  };
}

export function getTradeBlotterFail(state, payload) {
  return {
    ...state,
    viewState: {
      ...state.viewState,
      [TRADE_VIEW_MODE_HIST]: {
        ...initialState.viewState[TRADE_VIEW_MODE_HIST],
        isLoaded: true
      }
    }
  };
}

const _cancelTradeSuccess = (liveViewState, payload) => {
  const { info: tradeKeys } = payload;
  const { trades } = liveViewState;

  return {
    trades: EntityMap.apply(trades, (o, key) => {
      if (tradeKeys.includes(key)) {
        return {
          ...o,
          pmStatus: 'WITHDRAWING'
        };
      }

      return o;
    })
  };
};

const _mergeTrades = (liveViewState, createNew, rawTrades) => {
  const pmStatus = createNew ? 'SUBMITTING' : 'MODIFYING';
  const trades = EntityMap.merge(
    liveViewState.trades,
    rawTrades.map(t => ({
      ...t,
      key: t.refId,
      pmStatus,
      orderType: t.limitPriceLocal > 0 ? 'LMT' : 'MKT'
    }))
  );

  return {
    trades
  };
};

const _executeSingleTradeSuccess = (liveViewState, payload) => {
  const {
    info: { trade, createNew }
  } = payload;

  return _mergeTrades(liveViewState, createNew, [trade]);
};

const _executeMultiTradesSuccess = (liveViewState, payload) => {
  const {
    info: { trades, createNew }
  } = payload;

  return _mergeTrades(liveViewState, createNew, trades);
};

const _executeQuantTradesSuccess = (liveViewState, payload) => {
  const {
    info: { trades, createNew }
  } = payload;

  return _mergeTrades(liveViewState, createNew, trades);
};

const _adjustPortfolioSuccess = (liveViewState, payload) => {
  const {
    info: { trades }
  } = payload;

  return _mergeTrades(liveViewState, true, trades);
};

const _dialogSuccessHandlers = {
  [DIALOG_CANCEL_TRADE]: _cancelTradeSuccess,
  [DIALOG_EXECUTE_SINGLE_TRADE]: _executeSingleTradeSuccess,
  [DIALOG_EXECUTE_MULTI_TRADES]: _executeMultiTradesSuccess,
  [DIALOG_EXECUTE_QUANT_TRADES]: _executeQuantTradesSuccess,
  [DIALOG_ADJUST_PORTFOLIO]: _adjustPortfolioSuccess
};

export function submitDialogSuccess(state, payload) {
  const { dialogCode } = payload;
  const liveViewState = state.viewState[TRADE_VIEW_MODE_LIVE];

  if (!(dialogCode in _dialogSuccessHandlers)) {
    return state;
  }

  return {
    ...state,

    viewState: {
      ...state.viewState,
      [TRADE_VIEW_MODE_LIVE]: {
        ...state.viewState[TRADE_VIEW_MODE_LIVE],
        ..._dialogSuccessHandlers[dialogCode](liveViewState, payload)
      }
    }
  };
}

export default createReducer(initialState, {
  [ORDER_SNAPSHOT]: initOrder,
  [ORDER_UPDATE]: updateOrder,
  [LOGOUT_SUCCESS]: logoutSuccess,
  [TRADE_SELECT]: selectTrade,
  [TRADES_SELECT]: selectTrades,
  [TRADE_VIEW_MODE_CHANGE]: toggleViewMode,
  [TRADE_VIEW_DATE_CHANGE]: changeViewDate,
  [TRADE_BLOTTER_GET_REQUEST]: requestGetTradeBlotter,
  [TRADE_BLOTTER_GET_SUCCESS]: getTradeBlotterSuccess,
  [TRADE_BLOTTER_GET_FAIL]: getTradeBlotterFail,

  [DIALOG_SUBMIT_SUCCESS]: submitDialogSuccess
});
