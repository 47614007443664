import { moneyFormatter } from 'common/utils/valueFormatters';
import { numberStyle } from 'common/utils/styleChooser';
export const treasuryDataColumns = [
  {
    field: 'Broker',
    headerName: 'Broker',
    cellClass: 'keyword',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: '',
    headerName: 'Expense',
    children: [
      {
        field: 'Expense-Commission',
        headerName: 'Commission',
        cellClass: 'number',
        valueFormatter: moneyFormatter,
        cellClassRules: numberStyle()
      },
      {
        headerName: 'Swap',
        children: [
          {
            field: 'Expense-Long Swap Benchmark',
            headerName: 'L-Swap Benchmark',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-Long Swap Spread',
            headerName: 'L-Swap Spread',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-FFS Benchmark',
            headerName: 'FFS Benchmark',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-FFS Spread',
            headerName: 'FFS Spread',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-Swap Stock Loan',
            headerName: 'Swap Stock Loan',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-CFD INT OLD',
            headerName: 'CFD INT OLD',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          },
          {
            field: 'Expense-SWAP',
            headerName: 'Total Swap',
            valueFormatter: moneyFormatter,
            cellClass: 'number',
            cellClassRules: numberStyle()
          }
        ]
      },
      {
        field: 'Expense-Cash stock Loan',
        headerName: 'Cash Stock Loan',
        cellClass: 'number',
        valueFormatter: moneyFormatter,
        cellClassRules: numberStyle()
      },
      {
        field: 'Expense-All',
        headerName: 'All',
        cellClass: 'number',
        valueFormatter: moneyFormatter,
        cellClassRules: numberStyle()
      }
    ]
  },
  {
    field: '',
    headerName: 'Income',
    children: [
      {
        headerName: 'Interest',
        children: [
          {
            field: 'Income-Cash Interest',
            headerName: 'Cash Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-MMF Interest',
            headerName: 'MMF Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-Short Sell Interest',
            headerName: 'S-Sell Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-Total Interest',
            headerName: 'Total Interest',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          }
        ]
      },
      {
        headerName: 'Swap',
        children: [
          {
            field: 'Income-Short Benchmark Rebate',
            headerName: 'S-Benchmark Rebate',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-CFD INT OLD',
            headerName: 'CFD INT OLD',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          },
          {
            field: 'Income-SWAP',
            headerName: 'Total Swap',
            cellClass: 'number',
            valueFormatter: moneyFormatter,
            cellClassRules: numberStyle()
          }
        ]
      },
      {
        field: 'Income-All',
        headerName: 'All',
        valueFormatter: moneyFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      }
    ]
  },
  {
    headerName: 'All',
    cellClassRules: numberStyle(),
    children: [
      {
        field: 'All-',
        headerName: '',
        valueFormatter: moneyFormatter,
        cellClass: 'number',
        cellClassRules: numberStyle()
      }
    ]
  }
];
