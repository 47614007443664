import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import StateSynchronizer from '../../../common/utils/StateSynchronizer';
import { EXTERNAL_FUNDS } from '../../../common/utils/DomainUtils';
import agGridUtils from '../../../common/ui/agGridUtils';
import SplitPane from 'react-split-pane';
import _ from 'lodash';
import { Header } from 'semantic-ui-react';

class RiskGrid extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      agGridSettings: agGridUtils.createSettings({
        defaultColDef: {
          enableCellChangeFlash: true,
          minWidth: 25,
          sortable: true,
          filter: true,
          resizable: true
        },
        columnDefs: props.riskGridColumns,
        rowSelection: 'single',
        floatingFilter: false,
        groupIncludeTotalFooter: false
      }),

      selectedFunds: []
    };

    this.gridApis = {};
  }

  onGridReady = (level, params) => {
    this.gridApis[level] = params.api;

    const COLUMNS_KEY =
      level === 'FUND'
        ? 'risk-fund-grid-col-state'
        : 'risk-book-grid-col-state';
    StateSynchronizer.syncGrid(
      params,
      this.state.agGridSettings.columnDefs,
      COLUMNS_KEY
    );

    if (level === 'FUND') {
      params.api.selectIndex(0);
    }
  };

  onSelectionChanged = level => {
    const { onRiskInfoChanged } = this.props;
    if (level === 'FUND') {
      const selectedRows = this.gridApis[level].getSelectedRows();
      const selectedFunds = selectedRows.map(r => r.fundCode);
      this.setState({
        selectedFunds
      });
    }

    onRiskInfoChanged(
      _.first(this.gridApis['BOOK'].getSelectedRows()) ||
        _.first(this.gridApis['FUND'].getSelectedRows()) ||
        null
    );
  };

  _getFundRiskInfos = () => {
    const { riskInfos } = this.props;
    const fundRiskInfos = riskInfos.filter(r => r.aggregateLevel === 'FUND');

    const validExternalFunds = new Set(
      riskInfos
        .filter(r => EXTERNAL_FUNDS.includes(r.fundCode))
        .map(r => r.fundCode)
    );

    // Manually add external fund risks.
    validExternalFunds.forEach(f => {
      fundRiskInfos.push({
        aggregateLevel: 'FUND',
        fundCode: f,
        bookCode: f,
        key: `${f}-${f}`
      });
    });

    const sortFn = r => {
      if (validExternalFunds.has(r.bookCode)) {
        return `3_${r.bookCode}`;
      } else if (r.bookCode.charAt(0) === 'P') {
        return `1_${r.bookCode}`;
      } else {
        return `2_${r.bookCode}`;
      }
    };
    return _.orderBy(fundRiskInfos, [sortFn]);
  };

  _getBookRiskInfos = () => {
    const { riskInfos } = this.props;
    const { selectedFunds } = this.state;

    const bookRiskInfos = riskInfos.filter(
      r => r.aggregateLevel === 'BOOK' && selectedFunds.includes(r.fundCode)
    );

    const sortFn = r => {
      if (['W00', 'W08'].includes(r.bookCode)) {
        return `3_${r.bookCode}`;
      } else if (r.bookCode.charAt(0) === 'W') {
        return `1_${r.bookCode}`;
      } else {
        return `2_${r.bookCode}`;
      }
    };
    return _.orderBy(bookRiskInfos, [sortFn]);
  };

  _showRiskGrid = level => {
    const { className = 'grid-wrapper' } = this.props;
    const { agGridSettings } = this.state;
    let filteredRiskInfos =
      level === 'FUND' ? this._getFundRiskInfos() : this._getBookRiskInfos();
    if (!_.isEmpty(filteredRiskInfos)) {
      filteredRiskInfos.forEach(r => {
        if (r.strategy) r.strategy = _.upperCase(r.strategy);
      });
    }

    const onGridReady = _.partial(this.onGridReady, level);
    const onSelectionChanged = _.partial(this.onSelectionChanged, level);
    const screenHeight = window.screen.height;
    const height =
      level === 'FUND' ? '95%' : screenHeight <= 768 ? '70%' : '80%';
    return (
      <div
        className={`ag-theme-balham-dark ${className}`}
        style={{ overflow: 'hidden', flexGrow: '1', height: height }}
      >
        <AgGridReact
          // properties
          rowData={filteredRiskInfos}
          {...agGridSettings}
          // events
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    );
  };

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Header inverted block>
          Performance
        </Header>
        <SplitPane split="horizontal" defaultSize="35%">
          {this._showRiskGrid('FUND')}
          {this._showRiskGrid('BOOK')}
        </SplitPane>
      </div>
    );
  }
}

export default RiskGrid;
