import React from 'react';
import { Modal, Form, Input, InputNumber, TimePicker } from 'antd';
import _ from 'lodash';
import moment from 'moment';

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const AlgoParamsDialog = ({
  onSubmit,
  onCancel,
  algoParamInfos = [],
  algoParams = {}
}) => {
  const [form] = Form.useForm();

  const initialValues = _.fromPairs(
    algoParamInfos.map(p => {
      let paramValue = algoParams[p.code] || p.value;
      if (p.type === 'TIME' && paramValue) {
        paramValue = moment(paramValue, 'HH:mm:ss');
      }
      return [p.code, paramValue];
    })
  );

  const _createFormItem = p => {
    if (p.type === 'PERCENT') {
      return <InputNumber min={1} max={100} formatter={value => `${value}%`} />;
    }

    if (p.type === 'TIME') {
      return <TimePicker format="HH:mm:ss" />;
    }

    return <Input />;
  };

  return (
    <Modal
      visible={true}
      width={350}
      style={{ marginTop: '30px' }}
      title="Algo Parms"
      okText="Apply"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();

            const results = _.fromPairs(
              Object.entries(values)
                .filter(([, v]) => v && v !== '')
                .map(([k, v]) => [
                  k,
                  v instanceof moment ? v.format('HH:mm:ss') : v
                ])
            );
            onSubmit(results);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        name="algoParamsFrom"
        initialValues={initialValues}
        layout="horizontal"
        {...formItemLayout}
      >
        {algoParamInfos.map(p => (
          <Form.Item key={p.code} name={p.code} required={false} label={p.code}>
            {_createFormItem(p)}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default AlgoParamsDialog;
