import React, { Component } from 'react';
import { Rating } from 'semantic-ui-react';

export default class RatingBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || 0
    };
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ width: '100%' }}>
          <Rating
            icon="star"
            defaultRating={this.state.value}
            maxRating={5}
            disabled
          />
        </div>
      </div>
    );
  }
}
