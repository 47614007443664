import React, { Component } from 'react';
import numeral from 'numeral';
import _ from 'lodash';

export default class PercentBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      factor: props.factor || 1,
      positiveColor: props.positiveColor || 'green'
    };
  }

  render() {
    if (_.isNil(this.state.value)) return <div />;

    const positiveChange = {
      fill: this.state.positiveColor
    };

    const negativeChange = {
      fill: 'red'
    };

    const text = {
      position: 'absolute',
      top: 0,
      width: '100%',
      textAlign: 'right',
      paddingRight: '10px'
    };

    const value = this.state.value * this.state.factor;
    let percent = numeral(Math.abs(value)).format('0%');

    // For scientific notation number, in most case, the percent is very small value thus set as 0.
    if (percent === 'NaN%') {
      percent = '0%';
    }

    const barStyle = value >= 0 ? positiveChange : negativeChange;

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%', position: 'absolute', top: 0 }}>
          <svg width="100%" preserveAspectRatio="none">
            <rect
              x="0"
              y="0"
              width={percent}
              height="20px"
              rx="4"
              ry="4"
              style={barStyle}
            />
          </svg>
        </div>
        <div style={text}>{numeral(this.state.value).format('0.00%')}</div>
      </div>
    );
  }

  refresh(params) {
    this.setState({
      value: params.value
    });
    return true;
  }
}
