import {
  PORTFOLIO_SNAPSHOT,
  PORTFOLIO_UPDATE,
  PORTFOLIO_BOOK_CHANGE,
  PORTFOLIO_DATE_CHANGE,
  PORTFOLIO_VIEW_MODE_CHANGE,
  PORTFOLIO_GET_REQUEST,
  PORTFOLIO_GET_SUCCESS,
  PORTFOLIO_GET_FAIL,
  RISK_CONTROL_OPEN,
  RISK_CONTROL_RESET,
  PORTFOLIO_CASH_POSITION_CHANGE,
  ON_POSITION_SELECTED
} from './portfolioConstants';

import client from './api/client';

export function changeBook(payload) {
  return {
    type: PORTFOLIO_BOOK_CHANGE,
    payload
  };
}

export function getPortfolioRequest() {
  return {
    type: PORTFOLIO_GET_REQUEST
  };
}

export function getPortfolioSuccess(payload) {
  return {
    type: PORTFOLIO_GET_SUCCESS,
    payload
  };
}

export function getPortfolioFail(err) {
  return {
    type: PORTFOLIO_GET_FAIL,
    payload: err
  };
}

export function getPortfolio(query) {
  return function(dispatch) {
    dispatch(getPortfolioRequest());
    client
      .getPortfolio(query)
      .then(payload => dispatch(getPortfolioSuccess(payload)))
      .catch(err => dispatch(getPortfolioFail(err)));
  };
}

export function initPortfolio(payload) {
  return {
    type: PORTFOLIO_SNAPSHOT,
    payload: payload
  };
}

export function updatePortfolio(payload) {
  return {
    type: PORTFOLIO_UPDATE,
    payload: payload
  };
}

export function sendSubscription(payload) {
  return function(dispatch) {
    dispatch({
      type: 'subscription',
      payload,
      meta: {
        socketio: true
      }
    });
  };
}

export function sendUnSubscription() {
  return function(dispatch) {
    dispatch({
      type: 'unSubscription',
      payload: null,
      meta: {
        socketio: true
      }
    });
  };
}

export function openRiskControlModal() {
  return {
    type: RISK_CONTROL_OPEN
  };
}

export function resetRiskControlModal() {
  return {
    type: RISK_CONTROL_RESET
  };
}

export function toggleViewMode() {
  return {
    type: PORTFOLIO_VIEW_MODE_CHANGE
  };
}

export function toggleCashPosition() {
  return {
    type: PORTFOLIO_CASH_POSITION_CHANGE
  };
}

export function changeViewDate(date) {
  return {
    type: PORTFOLIO_DATE_CHANGE,
    payload: date
  };
}

export function onPositionSelected(payload) {
  return {
    type: ON_POSITION_SELECTED,
    payload: payload
  };
}
