import React, { Component } from 'react';

import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import client from '../../api/client';
import { analystDailuColumn } from '../GridColumnMap';
import DailyItem from './DailyItem';
import { Button, DatePicker, InputNumber } from 'antd';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import DailyReviewModal from './DailyReviewModal';
import CustomPushUserModal from './CustomPushUserModal';
import PushLogDialog from './PushLogDialog';
import { Loader } from 'semantic-ui-react';
import { localStorageClient } from 'common/api/localStorageClient';

const size_key = 'daily_report_draw_size';
class DailyReportGrid extends Component {
  constructor(props) {
    super(props);
    const windowSize = localStorageClient.get(size_key);
    this.state = {
      groupDefaultExpanded: -1,
      rowGroupPanelShow: 'false',
      data: [],
      selectedData: {},
      contextOptions: {},
      reviewModalShow: false,
      saveStatus: 'READY',
      showHistDialog: false,
      showCustomDialog: false,
      saveImg: {
        autoSave: false,
        mode: null
      },
      windowSize: windowSize ? windowSize : 8
    };
  }

  componentDidMount() {
    const currentDate = moment().format('YYYY-MM-DD');
    Promise.all([client.getDailyList(), client.getAnalystDailyContext()])
      .then(([resp, resp1]) => {
        const resdata = _.isEmpty(resp.data)
          ? []
          : resp.data.map(r => ({
              ...r,
              date: _.toString(r.date).substring(0, 10)
            }));
        const filterData = resdata.filter(r => r.date === currentDate);
        const defaultData = {
          date: currentDate,
          dataList: [{}, {}]
        };
        const selectData = _.isEmpty(filterData) ? defaultData : filterData[0];

        this.setState(
          {
            data: resdata,
            contextOptions: resp1.data
          },
          () => {
            this._getData(selectData.id, defaultData);
          }
        );
      })
      .catch(err => console.log(err));
  }

  _getData = (id, defaultData) => {
    if (!id) {
      this.setState({
        selectedData: defaultData
      });
    } else {
      client
        .getDailyWithId({ id })
        .then(resp => {
          this.setState({
            selectedData: resp.data ? resp.data : defaultData
          });
        })
        .catch(err => console.log(err));
    }
  };

  _loadData = () => {
    const { mode } = this.state.saveImg;
    if (mode !== 'REVIEW') {
      this._closeReview();
    }
    const { selectedData } = this.state;
    const currentDate = moment().format('YYYY-MM-DD');
    client
      .getDailyList()
      .then(resp => {
        const resdata = _.isEmpty(resp.data)
          ? []
          : resp.data.map(r => ({
              ...r,
              date: `${_.toString(r.date).substring(0, 10)}`
            }));
        let selectData = selectedData;
        if (_.isEmpty(selectedData)) {
          const filterData = resdata.filter(r => r.date === currentDate);
          const defaultData = {
            date: currentDate,
            dataList: [{}, {}]
          };
          selectData = _.isEmpty(filterData) ? defaultData : filterData[0];
        }

        this.setState({
          data: resdata,
          selectedData: selectData
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _getContext = () => {
    client
      .getAnalystDailyContext()
      .then(resp => {
        this.setState({
          contextOptions: resp.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _onGridReady = params => {
    this.gridApi = params.api;
    this.golumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    this.setState(
      {
        selectedData: null
      },
      () => {
        _.delay(() => {
          if (selectedRows.length > 0) {
            this._getData(selectedRows[0].id, {});
          }
        }, 500);
      }
    );
  };

  _createReportGrid = () => {
    const { className = 'grid-wrapper' } = this.props;
    const { data } = this.state;
    const sortedData = _.orderBy(data, ['date'], ['desc']);
    return (
      <div className={className}>
        <div
          className={`ag-theme-balham-dark ${className}`}
          style={{ height: '95%' }}
        >
          <AgGridReact
            // properties
            columnDefs={analystDailuColumn}
            rowData={sortedData}
            suppressAggFuncInHeader={true}
            animateRows={true}
            pivotPanelShow={false}
            getRowNodeId={data => data.id}
            rowSelection="single"
            onGridReady={this._onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        </div>
      </div>
    );
  };

  _onInputChange = ({ name, value }) => {
    const { selectedData } = this.state;
    this.setState({
      selectedData: {
        ...selectedData,
        [name]: value
      }
    });
  };

  _onDateChange = ({ name, value }) => {
    const { data, selectedData } = this.state;
    const filterData = data.filter(r => r.date === value);
    let updateData = {
      ...selectedData,
      date: value,
      id: null
    };
    if (!_.isEmpty(filterData)) {
      updateData = filterData[0];
    }
    this.setState(
      {
        selectedData: null
      },
      () => {
        _.delay(() => {
          const current = !_.isEmpty(filterData) ? filterData[0] : {};
          this._getData(current.id, updateData);
        }, 500);
      }
    );
  };

  _saveData = filePath => {
    const { selectedData, saveImg } = this.state;
    const saveData = {
      ...selectedData,
      data: JSON.stringify(selectedData.dataList),
      filePath,
      mode: saveImg.mode
    };
    client
      .saveAnalystDaily(saveData)
      .then(resp => {
        const id = resp.data;
        this.setState(
          {
            selectedData: {
              ...saveData,
              id
            },
            saveStatus: 'READY'
          },
          this._loadData
        );
      })
      .catch(err => {
        this.setState({
          saveStatus: 'ERROR'
        });
        console.log(err);
      });
  };

  _reviewHtml = () => {
    this._saveData();
    this._openReview();
  };

  _createPannel = () => {
    const { selectedData, contextOptions, saveStatus } = this.state;
    if (_.isEmpty(selectedData)) return;
    if (_.isEmpty(selectedData.data) && _.isEmpty(selectedData.dataList)) {
      selectedData.dataList = [{}, {}];
      selectedData.data = JSON.stringify(selectedData.dataList);
    } else {
      if (_.isEmpty(selectedData.dataList))
        selectedData.dataList = JSON.parse(selectedData.data);
    }
    const length = selectedData.dataList.length;
    return (
      <div
        id="analystDailyPannel"
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <style>
          {`
                #analystDailyPannel .ant-picker {
                    background-color: white
                }

                #analystDailyPannel .ant-select .ant-select-selector {
                    background-color: white
                }
            `}
        </style>
        <div style={{ width: '100%', height: '30px' }}>
          <div style={{ width: '40%', float: 'left' }}>
            <DatePicker
              value={moment(selectedData.date, 'YYYY-MM-DD')}
              format="YYYY-MM-DD"
              style={{ width: '200px', marginLeft: '15px' }}
              onChange={(date, dateString) =>
                this._onDateChange({
                  name: 'date',
                  value: dateString
                })
              }
            />
          </div>
          <div style={{ width: '60%', float: 'right', textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              disabled={saveStatus !== 'READY'}
              style={{ marginRight: '8px' }}
              onClick={() => {
                this._openAndSave('SAVE');
              }}
            >
              {saveStatus !== 'READY'
                ? 'Saving'
                : saveStatus === 'ERROR'
                ? 'Fail & Retry'
                : 'Save only'}
            </Button>

            <Button
              size="small"
              type="primary"
              disabled={saveStatus !== 'READY'}
              style={{ marginRight: '8px' }}
              onClick={() => {
                this._openAndSave('PUSH');
              }}
            >
              {saveStatus !== 'READY'
                ? 'Saving & Pushing'
                : saveStatus === 'ERROR'
                ? 'Fail & Retry'
                : 'Save and push to Wecom'}
            </Button>

            {selectedData.id && (
              <Button
                size="small"
                type="primary"
                style={{ marginRight: '8px' }}
                onClick={this._openHistReview}
              >
                {'Push Details'}
              </Button>
            )}

            <Button
              size="small"
              type="primary"
              style={{ marginRight: '8px' }}
              onClick={this._openCustomModal}
            >
              {'Push List'}
            </Button>
          </div>
        </div>
        {saveStatus !== 'READY' ? (
          <Loader active inverted content="Saving..." />
        ) : (
          <div style={{ width: '100%', height: '95%', marginTop: '8px' }}>
            <div
              style={{
                width: '92%',
                height: '95%',
                overflowX: 'scroll',
                overflowY: 'auto',
                float: 'left',
                whiteSpace: 'nowrap'
              }}
            >
              {selectedData.dataList.map((r, index) => (
                <div
                  style={{
                    width: `${96 / length > 31 ? 96 / length : 31}%`,
                    height: '90%',
                    minWidth: '31%',
                    marginLeft: '15px',
                    display: 'inline-block',
                    boxSizing: 'border-box'
                  }}
                >
                  <DailyItem
                    contextOptions={contextOptions}
                    data={r}
                    removeData={this._removeData}
                    index={index}
                  />
                </div>
              ))}
            </div>
            <div
              style={{
                width: `7%`,
                float: 'right',
                height: '100%',
                textAlign: 'center',
                marginLeft: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button
                size="large"
                style={{
                  width: '80px',
                  height: '80px',
                  backgroundColor: 'gray'
                }}
                icon={<PlusOutlined style={{ fontSize: '70px' }} />}
                shape="circle"
                onClick={this._addInnerContent}
              ></Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  _removeData = index => {
    const { selectedData } = this.state;
    const updateData = {
      ...selectedData
    };
    updateData.dataList.splice(index, 1);
    this.setState(
      {
        selectedData: {}
      },
      () => {
        _.delay(() => {
          this.setState({
            selectedData: updateData
          });
        }, 100);
      }
    );
  };

  _addInnerContent = () => {
    const { selectedData } = this.state;
    const updateData = {
      ...selectedData
    };
    updateData.dataList.push({});
    this.setState({
      selectedData: updateData
    });
  };

  _createOpBar = () => {
    const { windowSize } = this.state;
    return (
      <div style={{ height: '30px', textAlign: 'right', width: '100%' }}>
        <InputNumber
          placeholder="Right size"
          size="small"
          style={{ width: '55px' }}
          min={0}
          max={100}
          value={windowSize}
          formatter={value => `${value}%`}
          parser={value => value.replace('%', '')}
          onChange={this._onSizeChange}
        />

        <Button
          size="small"
          type="primary"
          style={{ marginRight: '8px', marginLeft: '5px' }}
          onClick={this.addDaily}
        >
          Add
        </Button>
      </div>
    );
  };

  _onSizeChange = value => {
    localStorageClient.save(size_key, value);
    this.setState({
      windowSize: value
    });
  };

  addDaily = () => {
    this.setState(
      {
        selectedData: null
      },
      () => {
        _.delay(() => {
          this.setState({
            selectedData: {
              date: moment().format('YYYY-MM-DD'),
              dataList: [{}, {}]
            }
          });
        }, 500);
      }
    );
  };

  _closeReview = () => {
    this.setState({
      reviewModalShow: false,
      saveImg: {
        autoSave: false
      }
    });
  };

  _openReview = () => {
    this.setState({
      reviewModalShow: true
    });
  };

  _openAndSave = mode => {
    this.setState({
      reviewModalShow: true,
      saveStatus: 'SAVING',
      saveImg: {
        autoSave: true,
        mode: mode ? mode : 'SAVE'
      }
    });
  };

  _createDailyReviewModal = () => {
    const { reviewModalShow, selectedData, saveImg } = this.state;
    const { user } = this.props;

    return (
      reviewModalShow && (
        <DailyReviewModal
          data={selectedData}
          user={user}
          closeModal={this._closeReview}
          save={this._saveData}
          saveStatus={this.state.saveStatus}
          saveImg={saveImg}
        />
      )
    );
  };

  _closeHistReview = () => {
    this.setState({
      showHistDialog: false
    });
  };

  _openHistReview = () => {
    this.setState({
      showHistDialog: true
    });
  };

  _createPushLogDialog = () => {
    const { showHistDialog, selectedData } = this.state;
    return (
      showHistDialog && (
        <PushLogDialog data={selectedData} closeModal={this._closeHistReview} />
      )
    );
  };

  _closeCustomModal = () => {
    this.setState({
      showCustomDialog: false
    });
  };

  _openCustomModal = () => {
    this.setState({
      showCustomDialog: true
    });
  };

  _createCustomPushUserModal = () => {
    const { showCustomDialog } = this.state;
    return (
      showCustomDialog && (
        <CustomPushUserModal
          {...this.props}
          closeModal={this._closeCustomModal}
        />
      )
    );
  };

  render() {
    const { windowSize } = this.state;
    return (
      <div style={{ height: '100%' }}>
        <div style={{ width: `${windowSize}%`, height: '100%', float: 'left' }}>
          {this._createOpBar()}
          {this._createReportGrid()}
        </div>
        <div
          style={{
            width: `${100 - windowSize}%`,
            height: '100%',
            float: 'left'
          }}
        >
          {this._createPannel()}
        </div>
        {this._createDailyReviewModal()}
        {this._createPushLogDialog()}
        {this._createCustomPushUserModal()}
      </div>
    );
  }
}

export default DailyReportGrid;
