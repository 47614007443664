import React from 'react';
import { Dropdown, Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import config from 'common/config';
import {
  isInternalUser,
  isPmRole,
  isSupervisorRole
} from 'common/utils/DomainUtils';
import NotificationIconBtn from 'features/notification/components/NotificationIconBtn';
import VersionTag from './VersionTag';

const billViewUrl = `${config.api.PRS_URL}/#/billView?accessToken=`;
const cmsPersonalAcctViewUrl = `${config.api.CMS_URL}/home/personal-trade-request?accessToken=`;
const cmsMeetingAndTraining = `${config.api.CMS_URL}/home/meeting-and-training?accessToken=`;
const cmsRestrictionViewUrl = `${config.api.CMS_URL}/home/restricted-security-request?accessToken=`;
const cmsCollege = `${config.api.CMS_ADMIN_URL}/home/pinpoint-college/meeting-training?accessToken=`;

const TopNavBar = ({
  toggleSideBar,
  openHelpDialog,
  isLoggedIn,
  user = {},
  accessToken
}) => {
  return (
    <Menu inverted attached="top" className="horizontal-nav-bar">
      <Menu.Item header>
        <Icon name="bars" onClick={toggleSideBar} />
        {config.title}
      </Menu.Item>
      <Menu.Menu position="right">
        <VersionTag />
        <NotificationIconBtn user={user} />
        <Dropdown item icon="setting" simple>
          <Dropdown.Menu>
            <Dropdown.Header>
              {isLoggedIn ? user.englishName : 'Anonymous'}
            </Dropdown.Header>
            {isLoggedIn && isInternalUser(user) && (
              <Dropdown.Item>
                <a
                  style={{ color: 'black' }}
                  href={`${billViewUrl}${accessToken}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>Budget & Bill</div>
                </a>
              </Dropdown.Item>
            )}
            {isLoggedIn && isInternalUser(user) && (
              <Dropdown.Item>
                <a
                  style={{ color: 'black' }}
                  href={`${cmsPersonalAcctViewUrl}${accessToken}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>PA Txn & Gift</div>
                </a>
              </Dropdown.Item>
            )}
            {isLoggedIn && isInternalUser(user) && (
              <Dropdown.Item>
                <a
                  style={{ color: 'black' }}
                  href={`${cmsRestrictionViewUrl}${accessToken}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>Restriction List</div>
                </a>
              </Dropdown.Item>
            )}
            {isLoggedIn &&
              !isSupervisorRole(user.role) &&
              isInternalUser(user) && (
                <Dropdown.Item>
                  <a
                    style={{ color: 'black' }}
                    href={`${cmsMeetingAndTraining}${accessToken}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>Training</div>
                  </a>
                </Dropdown.Item>
              )}

            {isLoggedIn && isSupervisorRole(user.role) && (
              <Dropdown.Item>
                <a
                  style={{ color: 'black' }}
                  href={`${cmsCollege}${accessToken}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>College Admin</div>
                </a>
              </Dropdown.Item>
            )}

            {isLoggedIn && isInternalUser(user) && (
              <Dropdown.Item>
                <a
                  style={{ color: 'black' }}
                  href={`https://portal.pinpointfund.com/sourceManage`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>Resources</div>
                </a>
              </Dropdown.Item>
            )}

            {isLoggedIn && isPmRole(user.role) && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item>
                  <Link to="/manageMyAnalyst" style={{ color: 'black' }}>
                    Manage Analyst
                  </Link>
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Divider />
            {isLoggedIn && (
              <Dropdown.Item>
                <Link to="/chgpwd" style={{ color: 'black' }}>
                  Change password
                </Link>
              </Dropdown.Item>
            )}
            <Dropdown.Item>
              {isLoggedIn ? (
                <Link to="/logout" style={{ color: 'black' }}>
                  Logout
                </Link>
              ) : (
                <Link to="/login" style={{ color: 'black' }}>
                  Login
                </Link>
              )}
            </Dropdown.Item>

            <Dropdown.Divider />
            <Dropdown.Item onClick={openHelpDialog}>Help</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default TopNavBar;
