import React, { Component } from 'react';
import { Modal, Select } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../common/ui/agGridUtils';
import 'ag-grid-enterprise';
import { DIALOG_COMPARE } from '../portfolioConstants';
import { compareColumn } from './GridColumnMap';
import _ from 'lodash';

const Option = Select.Option;
class CompareDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadStatus: 'READY',
      data: [],
      fundBooks: [null, null],
      gridSettings: agGridUtils.createSettings({
        columnDefs: compareColumn,
        floatingFilter: false,
        defaultColDef: {
          enableCellChangeFlash: false,
          minWidth: 25,
          sortable: true,
          resizable: true,
          filter: true
        },
        deltaRowDataMode: false,
        getRowNodeId: data => data.ticker,
        rowGroupPanelShow: false,
        pivotPanelShow: false,
        sideBar: {
          toolPanels: []
        }
      })
    };
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_COMPARE);
  };

  componentDidMount() {
    this._init();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // No re-rendering if props is updated.
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _init = () => {
    const { funds } = this.props.settings;
    const fundBooks = [];
    const fundBookOptions = [];
    funds.forEach(fundItem => {
      const books = fundItem.books
        ? fundItem.books.filter(r => r.enableTxn)
        : [];
      if (!_.isEmpty(books)) {
        let fundBook = {};
        fundBook.value = fundItem.name;
        fundItem.books.forEach(item => {
          if (item.enableTxn)
            fundBookOptions.push(fundItem.name + '-' + item.name);
        });
      }
    });

    if (_.isEmpty(fundBookOptions)) return;
    for (let i = 0; i <= 1 && i < fundBookOptions.length; i++) {
      fundBooks.push(fundBookOptions[i]);
      if (fundBookOptions.length === 1) {
        fundBooks.push(fundBookOptions[i]);
      }
    }
    this.setState(
      {
        fundBooks,
        fundBookOptions
      },
      this._buildData
    );
  };

  _buildData = () => {
    const { fundBooks } = this.state;
    const { positions, riskInfoMap } = this.props;
    const tickerMap = {};
    positions
      .filter(r => fundBooks.includes(`${r.fundCode}-${r.bookCode}`))
      .forEach(r => {
        const fundBook = `${r.fundCode}-${r.bookCode}`;
        const key = `${r.ticker}_${r.strategy}`;
        const index = _.findLastIndex(fundBooks, e => fundBook === e);
        const riskInfo = riskInfoMap.byKey[fundBook];
        if (_.isEmpty(riskInfo)) return;
        if (!tickerMap[key])
          tickerMap[key] = {
            ticker: r.ticker,
            strategy: r.strategy,
            securityCode: r.securityCode
          };
        const item = tickerMap[key];
        const notnlMktValBookPct = r['notnlMktValBook'] / riskInfo.nav;
        item[`fundBook${index + 1}`] = fundBook;
        item[`mvPct${index + 1}`] = notnlMktValBookPct;
        item['diff'] = item['mvPct1'] - item['mvPct2'];
        item['absDiff'] = item['diff'] ? Math.abs(item['diff']) : -1;
      });
    this.setState({
      data: _.orderBy(Object.values(tickerMap), ['absDiff'], ['desc'])
    });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  onInputChange = ({ index, value }) => {
    const { fundBooks } = this.state;
    const updatedData = [...fundBooks];
    updatedData[index] = value;
    this.setState(
      {
        fundBooks: updatedData
      },
      this._buildData
    );
  };

  _createOpBar = () => {
    const { fundBooks, fundBookOptions } = this.state;
    if (_.isEmpty(fundBookOptions)) return;
    return (
      <div style={{ textAlign: 'right', width: '100%' }}>
        <Select
          style={{ marginRight: '5px', width: '200px' }}
          onChange={value => {
            this.onInputChange({ index: 0, value });
          }}
          showSearch={true}
          value={fundBooks[0]}
        >
          {fundBookOptions.map(t => (
            <Option value={t} key={t}>
              {t}
            </Option>
          ))}
        </Select>
        <Select
          style={{ marginRight: '30px', width: '200px' }}
          onChange={value => {
            this.onInputChange({ index: 1, value });
          }}
          showSearch={true}
          value={fundBooks[1]}
        >
          {fundBookOptions.map(t => (
            <Option value={t} key={t}>
              {t}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  _createDataGrid = () => {
    const { data, gridSettings } = this.state;
    return (
      !_.isEmpty(data) && (
        <div
          className={`ag-theme-balham-dark grid-wrapper`}
          style={{ height: '90%', width: '100%' }}
        >
          <AgGridReact
            {...gridSettings}
            rowData={data}
            rowSelection="single"
            onGridReady={this.onGridReady}
          />
        </div>
      )
    );
  };

  render() {
    return (
      <Modal
        width={'1300px'}
        maskClosable={false}
        visible={true}
        onCancel={this.closeDialog}
        className="compareDialog"
        bodyStyle={{
          height: '80vh',
          overflowY: 'auto',
          backgroundColor: '#2d3436',
          color: 'white',
          padding: '12px'
        }}
        footer={null}
      >
        <style>
          {` 
                  .compareDialog .anticon {
                    color: gray !important;
                  }

                  .compareDialog .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }
                 
            `}
        </style>
        {this._createOpBar()}
        {this._createDataGrid()}
      </Modal>
    );
  }
}

export default CompareDialog;
