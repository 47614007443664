import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import PercentBarComponent from '../../../common/ui/renderers/agGrid/PercentBarComponent';
import TagComponent from '../../../common/ui/renderers/agGrid/TagComponent';
import StateSynchronizer from '../../../common/utils/StateSynchronizer';
import agGridUtils from '../../../common/ui/agGridUtils';
import _ from 'lodash';

class TradeGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.settings.tradeGridColumns,
      defaultColDef: {
        checkboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelection: agGridUtils.isFirstColumn,
        headerCheckboxSelectionFilteredOnly: true,
        enableCellChangeFlash: true,
        minWidth: 25,
        sortable: true,
        filter: true,
        resizable: true
      },
      autoGroupColumnDef: {
        cellClass: 'non-number'
      },
      rowGroupPanelShow: 'always',
      pivotPanelShow: 'always',
      frameworkComponents: {
        percentBarComponent: PercentBarComponent,
        tagComponent: TagComponent
      },
      sideBar: {
        toolPanels: ['columns', 'filters']
      },
      getContextMenuItems: params => {
        // const defaultItems = params.defaultItems.slice(0);
        if (params.node == null) return [];

        const row = params.node.data;
        return props.getContextMenuItems ? props.getContextMenuItems(row) : [];
      }
    };
  }

  getRowNodeId = data => {
    return data.key;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    const COLUMNS_KEY = 'trade-grid-col-state';

    StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (_.isEmpty(selectedRows)) {
      this.props.selectTrades([]);
      this.props.selectTrade(null);
      return;
    }
    const row = this.gridApi.getSelectedRows()[0];

    this.props.selectTrade(row.refId);
    this.props.selectTrades(selectedRows.map(r => r.refId));
  };

  onCellContextMenu = evt => {
    if (evt.node && !evt.node.isSelected()) {
      this.gridApi.deselectAll();
      evt.node.setSelected(true);
    }
  };

  render() {
    const { trades, className = 'grid-wrapper' } = this.props;

    return (
      <div className={`ag-theme-balham-dark ${className}`}>
        <AgGridReact
          // properties
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          autoGroupColumnDef={this.state.autoGroupColumnDef}
          rowData={trades}
          suppressAggFuncInHeader={true}
          animateRows={true}
          deltaRowDataMode={true}
          enableRangeSelection={true}
          getRowNodeId={this.getRowNodeId}
          rowGroupPanelShow={this.state.rowGroupPanelShow}
          pivotPanelShow={this.state.pivotPanelShow}
          groupSelectsChildren={true}
          groupSelectsFiltered={true}
          getContextMenuItems={this.state.getContextMenuItems}
          rowSelection="multiple"
          frameworkComponents={this.state.frameworkComponents}
          sideBar={this.state.sideBar}
          // events
          onGridReady={this.onGridReady}
          onSelectionChanged={this.onSelectionChanged}
          onCellContextMenu={this.onCellContextMenu}
        />
      </div>
    );
  }
}

export default TradeGrid;
