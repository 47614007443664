import { connect } from 'react-redux';
import TreasuryDashboard from '../components/TreasuryDashboard';
import {
  loadShortableList,
  loadShortExchangeList
} from 'features/research/researchActions';
import config from 'common/config';
const funds = {
  ONSHORE: ['CVF', 'SLHL', 'DCL', 'ZJNF'],
  OFFSHORE: ['PMSF', 'VCC', 'COP', 'PCF', 'MTP']
};
const mapStateToProps = state => {
  const settings = state.settings;
  return {
    settings,

    //short
    shortListGridColumns: state.research.shortListGridColumns,
    shortableList: state.research.shortableList,
    shortableListTotal: state.research.shortableListTotal,
    shortExchangeList: state.research.shortExchangeList,
    isLoadedShortExchangeStatus: state.research.isLoadedShortExchangeStatus,
    // isLoaded
    isLoaded: state.research.isLoaded,
    fundList: funds[config.system]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadShortableList: params => dispatch(loadShortableList(params)),
    loadShortExchangeList: () => dispatch(loadShortExchangeList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreasuryDashboard);
