import React, { Component } from 'react';
import SplitPane from 'react-split-pane';
import { Select, DatePicker, Button, Spin } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import agGridUtils from '../../../common/ui/agGridUtils';
import 'ag-grid-enterprise';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { DIALOG_SENTIMENT } from '../portfolioConstants';
import { sentimentColumn } from './GridColumnMap';
import client from '../api/client';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

const Option = Select.Option;
const { RangePicker } = DatePicker;

const _createUIOptions = codes => {
  return codes.map(c => (
    <Option key={c || 'default'} value={c}>
      {c}
    </Option>
  ));
};
class SentimentPannel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataList: [],
      loadStatus: 'READY',
      query: {
        date: [moment().subtract(12, 'months'), moment()],
        industryCodeList: [],
        selectedLines: ['情绪指数']
      },
      industryList: [],
      selectedData: [],
      lineOptions: ['情绪指数', '换手率', '北向资金', '融资'],
      lineConfig: {
        情绪指数: { color: '#C00000', field: 'totalScore' },
        换手率: { color: 'limegreen', field: 'turnoverScore' },
        北向资金: { color: '#54C8FF', field: 'northboundInflowScore' },
        融资: { color: '#FF9E28', field: 'financingScore' }
      },
      lineDetailConfig: {
        '换手率(成交金额/自由流通市值)': {
          color: 'limegreen',
          field: 'turnover'
        },
        '北向资金近5日净流入之和(百万)': {
          color: '#54C8FF',
          field: 'northboundInflow'
        },
        '融资(A股融资交易/成交额)': { color: '#FF9E28', field: 'financing' }
      },
      gridSettings: agGridUtils.createSettings({
        columnDefs: sentimentColumn,
        floatingFilter: false,
        defaultColDef: {
          enableCellChangeFlash: false,
          minWidth: 25,
          checkboxSelection: agGridUtils.isFirstColumn,
          headerCheckboxSelection: agGridUtils.isFirstColumn,
          headerCheckboxSelectionFilteredOnly: true,
          sortable: true,
          resizable: true,
          filter: true
        },
        groupIncludeTotalFooter: false,
        deltaRowDataMode: false,
        getRowNodeId: data => data.id,
        rowGroupPanelShow: false,
        pivotPanelShow: false,
        sideBar: {
          toolPanels: []
        }
      })
    };
  }

  closeDialog = () => {
    this.props.closeDialog(DIALOG_SENTIMENT);
  };

  _getIndustryList = () => {
    const { selectedPosition } = this.props.ui;
    const { query } = this.state;

    Promise.all([
      client.getIndustrySentimentIndustryList(),
      client.getIndustryByTicker({
        tickerSymbol: selectedPosition.ticker.split(' ')[0]
      })
    ])
      .then(([resp, industry]) => {
        this.setState(
          {
            industryList: resp.data,
            query: {
              ...query,
              industryCodeList: [industry.data]
            }
          },
          this._loadData
        );
      })
      .catch(err => console.log(err));
  };

  _loadIndustrySentimentIndustryList = () => {
    const { query } = this.state;
    client
      .getIndustrySentimentIndustryList()
      .then(resp => {
        this.setState(
          {
            industryList: resp.data,
            query: {
              ...query,
              industryCodeList: resp.data ? [resp.data[0].value] : []
            }
          },
          this._loadData
        );
      })
      .catch(err => console.log(err));
  };

  _loadData = () => {
    const { query } = this.state;
    this.setState({
      loadStatus: 'LOADING'
    });
    const params = {
      ...query,
      startDate: query.date[0].format('YYYY-MM-DD'),
      endDate: query.date[1].format('YYYY-MM-DD')
    };
    Promise.all([client.getIndustrySentimentScoreResult(params)])
      .then(([rsp]) => {
        this.setState({ dataList: rsp.data, loadStatus: 'READY' });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loadStatus: 'READY' });
      });
  };

  componentDidMount() {
    const { source } = this.props;
    if (source === 'PORTFOLIO') {
      this._getIndustryList();
    } else {
      this._loadIndustrySentimentIndustryList();
    }
  }

  onIndustryChange = selectedItems => {
    const { query } = this.state;
    const params = {
      ...query,
      industryCodeList: [selectedItems]
    };
    this.setState(
      {
        query: params
      },
      this._loadData
    );
  };

  _onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    let selectedData = [];
    if (!_.isEmpty(selectedRows)) {
      selectedData = selectedRows.map(r => r.id);
    }
    this.setState({ selectedData });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  _createDataGrid = () => {
    const { dataList, gridSettings } = this.state;
    const data = _.orderBy(dataList, ['tradeDate'], ['desc']);
    return (
      !_.isEmpty(dataList) && (
        <div
          className={`ag-theme-balham-dark grid-wrapper`}
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            {...gridSettings}
            rowData={data}
            rowSelection="multiple"
            onGridReady={this.onGridReady}
            onSelectionChanged={this._onSelectionChanged}
          />
        </div>
      )
    );
  };

  _onLineDataChange = values => {
    const { query } = this.state;
    this.setState({
      query: {
        ...query,
        selectedLines: values
      }
    });
  };

  _createQueryHeader = () => {
    const { source } = this.props;
    const { industryList, query, lineOptions } = this.state;
    return (
      <div className="securityFilter">
        <Select
          allowClear={false}
          showSearch
          onChange={this.onIndustryChange}
          value={query.industryCodeList}
          optionFilterProp="label"
          style={{ marginLeft: '5px', width: 180 }}
        >
          {industryList.map(t => (
            <Option value={t.value} key={t.value} label={t.label}>
              {t.label}
            </Option>
          ))}
        </Select>

        <RangePicker
          size="small"
          style={{ width: '240px', marginLeft: '5px' }}
          onCalendarChange={this._changeReviewDate}
          value={query.date}
          format={'YYYY-MM-DD'}
          allowClear={false}
          onChange={this._changeReviewDate}
        />

        <Select
          mode="multiple"
          showSearch
          onChange={value => {
            this._onLineDataChange(value);
          }}
          value={query.selectedLines}
          style={{ marginLeft: '5px', width: 300 }}
        >
          {_createUIOptions(lineOptions)}
        </Select>

        <Button
          type="primary"
          style={{ marginLeft: '5px' }}
          onClick={this._loadData}
        >
          {' '}
          Reload{' '}
        </Button>
        {source === 'PORTFOLIO' && (
          <Button
            type="dashed"
            style={{ marginLeft: '5px' }}
            onClick={this.closeDialog}
          >
            {' '}
            Close{' '}
          </Button>
        )}
      </div>
    );
  };

  _createFirstLineChart = () => {
    const {
      dataList,
      selectedData,
      query,
      lineConfig,
      loadStatus
    } = this.state;
    let data = _.orderBy(dataList, ['tradeDate'], ['asc']);
    let max = 3500,
      min = 2500;
    if (!_.isEmpty(data)) {
      const indexs = data.map(r => r.indexPrice);
      const maxValue = _.max(indexs);
      const minValue = _.min(indexs);
      max = Math.ceil((maxValue + maxValue * 0.1) / 100) * 100;
      min = Math.floor((minValue - minValue * 0.1) / 100) * 100;
    }
    return loadStatus === 'LOADING' ? (
      <div style={{ height: '100%', textAlign: 'center', marginTop: '50px' }}>
        <Spin />
      </div>
    ) : (
      <ResponsiveContainer height={'100%'} width={'100%'}>
        <LineChart data={data}>
          <XAxis
            dataKey="tradeDate"
            tickFormatter={value => {
              return value.substr(0, 10);
            }}
          />
          {/* <YAxis type="number" orientation="left" domain={[0, 10]}/> */}

          <YAxis yAxisId="right" orientation="right" domain={[0, 10]} />
          <YAxis
            yAxisId="left"
            orientation="left"
            type="number"
            domain={[min, max]}
          />
          <Legend verticalAlign="top" />
          {/* <YAxis /> */}
          <Tooltip />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="indexPrice"
            name={'行业指数'}
            stroke="#2016D2"
            strokeWidth={3}
            dot={props => {
              return null;
            }}
          />
          {!_.isEmpty(query.selectedLines) &&
            query.selectedLines.map(r => (
              <Line
                yAxisId="right"
                type="monotone"
                dataKey={lineConfig[r].field}
                name={r}
                stroke={lineConfig[r].color}
                strokeWidth={3}
                dot={props => {
                  const { cx, cy, payload } = props;

                  if (payload && selectedData.some(r => r === payload.id)) {
                    const size = 5;
                    const color = 'green';
                    const angle = -90;
                    return (
                      <g>
                        <path
                          d={`M${size} 0 L${size} 10 L0 5 Z`}
                          transform={`translate(${cx}, ${cy}) rotate(${angle})`}
                          fill={color}
                          stroke={lineConfig[r].color}
                        />
                        <text
                          x={cx}
                          y={cy - 10}
                          dy={-8}
                          fill={color}
                          textAnchor="middle"
                        >
                          {payload[lineConfig[r].field]}
                        </text>
                      </g>
                    );
                  }

                  return null;
                }}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  // _createDetailChart = () => {
  //     return ()
  // }

  _changeReviewDate = dates => {
    const { query } = this.state;
    this.setState({
      query: {
        ...query,
        date: dates
      }
    });
  };

  onOpenChange = () => {
    this._changeReviewDate([]);
  };

  _createLineDetailChart = (key, format) => {
    const { dataList, lineDetailConfig, loadStatus } = this.state;
    const data = _.orderBy(dataList, ['tradeDate'], ['asc']).map(r => ({
      ...r,
      northboundInflow: r.northboundInflow / 1000000
    }));
    let max = 0,
      min = 10;
    if (!_.isEmpty(data)) {
      const field = lineDetailConfig[key].field;
      const fieldData = data.map(r => r[field]);
      const maxValue = _.max(fieldData);
      const minValue = _.min(fieldData);
      if (key === '北向资金近5日净流入之和(百万)') {
        max = Math.ceil((maxValue + maxValue * 0.1) / 100) * 100;
        min = Math.floor((minValue - minValue * 0.1) / 100) * 100;
      } else {
        max = maxValue + maxValue * 0.1;
        min = minValue - minValue * 0.1;
      }
    }
    return (
      loadStatus !== 'LOADING' && (
        <ResponsiveContainer height={'90%'} width={'100%'}>
          <LineChart data={data} margin={{ top: 5 }}>
            <XAxis
              dataKey="tradeDate"
              tickFormatter={value => {
                return value.substr(0, 10);
              }}
            />
            {/* <YAxis type="number" orientation="left" domain={[0, 10]}/> */}
            <YAxis
              yAxisId="left"
              orientation="left"
              type="number"
              domain={[min, max]}
              tickFormatter={value => {
                return numeral(value).format(format);
              }}
            />
            <Legend verticalAlign="top" />
            {/* <YAxis /> */}
            <Tooltip
              formatter={(value, name, props) => {
                return [numeral(value).format(format), name];
              }}
            />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey={lineDetailConfig[key].field}
              name={key}
              stroke={lineDetailConfig[key].color}
              strokeWidth={3}
              dot={props => {
                return null;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )
    );
  };

  render() {
    const { loadStatus } = this.state;
    const { className = 'sentimentModalInnerClass' } = this.props;
    return (
      <div>
        <style>
          {` 
                  .sentimentModalInnerClass{
                    background-color: #2d3436 !important;
                    color: white;
                    border: solid 1px #54C8FF;
                  }

                  .sentimenGridInnerClass{
                    background-color: #2d3436 !important;
                    color: white;
                  }

                  .${className} .ant-modal-header,.ant-modal-footer{
                    background-color: #2d3436 !important;
                    color: white;
                  }

                  .${className} .ant-modal-header,.ant-modal-footer{
                    background-color: #2d3436 !important;
                    color: white;
                  }

                  .sentimentModalInnerClass .SplitPane {
                    width: 99% !important;
                  }

                  .sentimenGridInnerClass .SplitPane {
                    width: 100% !important;
                  }

                  .${className} .securityFilter {
                    display: flex;
                    justify-content: flex-end;
                     /* padding: 1px; */
                    line-height: 100%;
                    width: 100%;
                  }

                  .${className} .ant-select .ant-select-selector{
                    background-color:#FF9E28;
                  }

                  .${className} .ant-select-arrow{
                    color: gray !important;
                  }

                  .${className} .anticon {
                    color: gray !important;
                  }

                  .${className} .blue0 {
                    background-color: rgb(90, 138, 198);
                    color: black;
                  }

                  .${className} .blue1 {
                    background-color: rgb(122, 160, 209);
                    color: black;
                  }

                  .${className} .blue2 {
                    background-color: rgb(154, 183, 220);
                    color: black;
                  }

                  .${className} .blue3 {
                    background-color: rgb(154, 170, 191);
                    color: black;
                  }

                  .${className} .blue4 {
                    background-color: rgb(219, 229, 243);
                    color: black;
                  }

                  .${className} .blue5 {
                    background-color: whitesmoke;
                    color: black;
                  }


                  .${className} .red6 {
                    background-color: rgb(252, 223, 226);
                    color: black;
                  }

                  .${className} .red7 {
                    background-color: rgb(251, 194, 196);
                    color: black;
                  }

                  .${className} .red8 {
                    background-color: rgb(250, 164, 167);
                    color: black;
                  }

                  .${className} .red9 {
                    background-color: rgb(249, 135, 137);
                    color: black;
                  }

                  .${className} .red10 {
                    background-color: rgb(248, 105, 107);
                    color: black;
                  }

                  .${className} .ant-picker {
                    background: #FF9E28;
                  }

                  .sentimenGridInnerClass .mySplitPaneClass {
                    top: 45px !important;
                    min-height: 92% !important;
                    height: 92% !important;
                }
                 
                `}
        </style>
        <SplitPane
          id="first"
          split="horizontal"
          defaultSize={'32px'}
          height={'100%'}
          className="mySplitPaneClass"
        >
          {this._createQueryHeader()}
          <SplitPane split="vertical" defaultSize={`75%`} height={'100%'}>
            <div style={{ height: '100%', width: '100%' }}>
              <div style={{ height: '40%', width: '100%' }}>
                {' '}
                {this._createFirstLineChart()}
              </div>
              <div style={{ height: '18%', width: '100%' }}>
                {this._createLineDetailChart(
                  '换手率(成交金额/自由流通市值)',
                  '0.00%'
                )}
              </div>
              <div style={{ height: '18%', width: '100%' }}>
                {this._createLineDetailChart(
                  '北向资金近5日净流入之和(百万)',
                  '0,0'
                )}
              </div>
              <div style={{ height: '17%', width: '100%' }}>
                {this._createLineDetailChart(
                  '融资(A股融资交易/成交额)',
                  '0.00%'
                )}
              </div>
            </div>
            {loadStatus !== 'LOADING' && this._createDataGrid()}
          </SplitPane>
        </SplitPane>
      </div>
    );
  }
}

export default SentimentPannel;
