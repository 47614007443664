import React, { PureComponent } from 'react';
import { Modal, Button } from 'antd';
import { DIALOG_ENRICH_ORDERS } from '../../omsConstants';
import client from '../../api/client';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import { HotTable } from '@handsontable/react';
import hotTableUtils from 'common/ui/hotTableUtils';
import { enrichOrdersGridColumns } from './GridColumnMap';

class EnrichOrdersDialog extends PureComponent {
  state = {
    errors: [],
    submitStatus: 'READY',
    gridWrapperStyle: {
      width: '100%',
      height: '300px',
      marginTop: '20px'
    },
    hotTableSettings: hotTableUtils.createSettings({
      columns: enrichOrdersGridColumns,
      contextMenu: ['remove_row']
    })
  };

  componentDidMount() {
    const { selectedOrders } = this.props;

    const orders = selectedOrders.map(o => {
      return {
        ...o
      };
    });

    this.setState({
      orders
    });
  }

  _beforeRemoveRow = (row, count, rows, source) => {
    const { orders } = this.state;
    const removedOrders = orders.filter((_, i) => !rows.includes(i));

    this.setState({ orders: removedOrders });

    return false;
  };

  _onOrderSelectionChanged = row => {
    const { orders } = this.state;
    const selectedOrder = orders[row];

    this.setState({
      selectedOrder
    });
  };

  _createOrdersGrid = () => {
    const { hotTableSettings, gridWrapperStyle, orders } = this.state;

    return (
      !_.isEmpty(orders) && (
        <div style={gridWrapperStyle}>
          <HotTable
            data={orders}
            beforeRemoveRow={this._beforeRemoveRow}
            rowHeaders={true}
            afterSelectionEnd={this._onOrderSelectionChanged}
            manualColumnResize={true}
            {...hotTableSettings}
          ></HotTable>
        </div>
      )
    );
  };

  _createSummaryPanel = () => {
    const { orders = [] } = this.state;

    return (
      <div className="summary">
        Totally
        <span className="keyword">{` ${orders.length} `}</span>
        orders
      </div>
    );
  };

  _createErrorsPanel = () => {
    const { errors } = this.state;

    const errorMsgs = Object.values(errors);

    return (
      <div style={{ marginTop: '5px' }}>
        {!_.isEmpty(errorMsgs) && (
          <Message error list={errorMsgs} style={{ marginBottom: '3px' }} />
        )}
      </div>
    );
  };

  _enrichOrders = () => {
    const { orders } = this.state;
    const { submitDialogSuccess } = this.props;

    const cmds = orders
      .filter(o => _.isEmpty(o.errors))
      .map(o => ({
        refId: o.refId,
        tradeId: o.tradeId,
        tradeDate: o.tradeDate,
        traderNote: o.traderNote,
        symbol: o.symbol,
        mmaTicker: o.mmaTicker
      }));

    if (cmds.length > 0) {
      return client.enrichOrders(cmds).then(() => {
        submitDialogSuccess(DIALOG_ENRICH_ORDERS, cmds.map(c => c.refId));
      });
    }

    return null;
  };

  _onSubmit = () => {
    if (!this._checkData()) {
      return;
    }
    const promises = [this._enrichOrders()].filter(Boolean);

    if (promises.length > 0) {
      this.setState({ submitStatus: 'SUBMITTING' });

      Promise.all(promises).catch(() =>
        this.setState({ submitStatus: 'ERROR' })
      );
    } else {
      this.closeDialog();
    }
  };

  _checkData = () => {
    const { orders } = this.state;
    const errors = orders
      .filter(o => _.isNil(o.tradeDate) || _.isEmpty(o.tradeDate))
      .map((o, i) => `Row ${i + 1}: trade date cannot be null.`);

    this.setState({
      errors: errors
    });
    if (!_.isEmpty(errors)) {
      return false;
    }
    return true;
  };

  closeDialog = () => {
    this.props.closeDialog(DIALOG_ENRICH_ORDERS);
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus, orders } = this.state;
    const disabledFlag = (orders && orders.length) > 0 ? false : true;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={disabledFlag}
        >
          Submit
        </Button>
      )
    }[submitStatus];
  };

  render() {
    return (
      <Modal
        width={1200}
        maskClosable={false}
        title="Enrich Orders"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        {this._createSummaryPanel()}
        {this._createOrdersGrid()}
        {this._createErrorsPanel()}
      </Modal>
    );
  }
}

export default EnrichOrdersDialog;
