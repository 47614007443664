import { createReducer } from 'common/utils/reducerUtils';

import {} from './treasuryConstants';
// import moment from 'moment';

const initialState = {
  reportList: []
};

export default createReducer(initialState, {
  // Short Interest
});
