import React, { PureComponent } from 'react';
import { Modal, Button, Input, Spin, Tabs, DatePicker } from 'antd';
import _ from 'lodash';
import hotTableUtils from 'common/ui/hotTableUtils';
import { HotTable } from '@handsontable/react';
import { DIALOG_TXN_LIMITS } from '../../omsConstants';
import client from '../../api/client';
import { txnLimitsTableColumns, mktSuspensionColumns } from './GridColumnMap';
import { Message } from 'semantic-ui-react';
import moment from 'moment';

class TxnLimitsDialog extends PureComponent {
  state = {
    submitStatus: 'READY',
    isInitialized: false,
    filterTxnLimits: [],
    mktSuspensions: [],
    errors: [],
    txnLimits: [],
    mktSuspensionsBack: [],
    params: {
      searchTicker: null,
      searchSymbol: null
    },
    marketSuspensionQueryParams: {
      date: moment().format('YYYY-MM-DD')
    },
    gridWrapperStyle: {
      width: '100%',
      height: '390px',
      marginTop: '5px',
      border: 'solid 2px grey',
      padding: '2px'
    },
    TxnLimit: 'TxnLimit',
    MktSuspension: 'MktSuspension',
    activeTab: 'TxnLimit'
  };

  componentDidMount() {
    this._init();
  }

  _init = () => {
    this._loadTxnLimits();
    this._loadMktSuspension();
  };

  _loadTxnLimits = () => {
    Promise.all([client.getTxnLimits()])
      .then(([txnLimits]) => {
        this.setState(
          {
            txnLimits,
            isInitialized: true
          },
          this._filterData
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  _loadMktSuspension = () => {
    const { marketSuspensionQueryParams } = this.state;
    client
      .getMktSuspension(marketSuspensionQueryParams)
      .then(result => {
        for (let i = 0; i < result.length; ++i) {
          result[i].id = i + 1;
        }
        this.setState({
          mktSuspensions: result,
          mktSuspensionsBack: [...result]
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  _filterData = () => {
    const { txnLimits } = this.state;
    const filterData = txnLimits;
    const { searchTicker, searchSymbol } = this.state.params;
    const dataList = _.isEmpty(filterData)
      ? []
      : filterData.filter(r => {
          if (!_.isEmpty(searchTicker)) {
            if (_.isEmpty(r.ticker)) return false;
            return r.ticker.toUpperCase().includes(searchTicker.toUpperCase());
          }
          if (!_.isEmpty(searchSymbol)) {
            if (_.isEmpty(r.symbol)) return false;
            return r.symbol.toUpperCase().includes(searchSymbol.toUpperCase());
          }
          return true;
        });
    this.setState({
      filterTxnLimits: dataList
    });
  };

  _afterCellChange = (value, action) => {
    if (action !== 'edit') return;
    const realChanges = value.filter(
      ([, field, oldValue, newValue]) =>
        oldValue !== newValue &&
        (!_.isEmpty(oldValue) || !_.isEmpty(newValue) || _.isNumber(newValue))
    );
    if (_.isEmpty(realChanges)) return;
    this._validate();
  };

  _validate = () => {
    const { filterTxnLimits } = this.state;
    const errors = [];
    filterTxnLimits.forEach((r, index) => {
      const errorFld = [];
      if (_.isEmpty(r.ticker) && _.isEmpty(r.symbol))
        errorFld.push('[ticker/symbol]');
      if (_.isEmpty(r.type)) errorFld.push('[type]');
      if (_.isNil(r.value)) errorFld.push('[value]');
      if (!_.isEmpty(errorFld))
        errors.push(
          `[${index + 1}] field ${_.join(errorFld, ' ')} can't be null`
        );
    });
    _.delay(() => {
      this.setState({
        errors
      });
    }, 100);
    return _.isEmpty(errors);
  };

  _createGridSettings = () => {
    return hotTableUtils.createSettings({
      columns: txnLimitsTableColumns,
      rowHeaders: true,
      contextMenu: {
        items: {
          insert_row: {},
          remove_row: {}
        }
      }
    });
  };

  _createGridSettingsForMktSuspension = () => {
    return hotTableUtils.createSettings({
      columns: mktSuspensionColumns,
      rowHeaders: true,
      contextMenu: {
        items: {
          add: {
            name: 'Add row',
            callback: () => {
              // Must delay below operation, otherwise handsontable will throw exception.
              _.delay(() => this._addRow(), 100);
            }
          },
          remove_row: {}
        }
      }
    });
  };

  _createTable = () => {
    const { filterTxnLimits, gridWrapperStyle } = this.state;
    const settings = this._createGridSettings();

    return (
      !_.isEmpty(filterTxnLimits) && (
        <div style={gridWrapperStyle}>
          <HotTable
            ref={this.hotTblRef}
            data={filterTxnLimits}
            manualColumnResize={true}
            {...settings}
            columnSorting={true}
            afterChange={this._afterCellChange}
            afterCreateRow={this._validate}
            afterRemoveRow={this._validate}
          />
        </div>
      )
    );
  };

  _createTableForMktSuspension = () => {
    const { mktSuspensions, gridWrapperStyle } = this.state;
    const settings = this._createGridSettingsForMktSuspension();
    return (
      <div style={gridWrapperStyle}>
        <HotTable
          data={mktSuspensions}
          manualColumnResize={true}
          {...settings}
          columnSorting={true}
          afterChange={this._validateMktSuspendRow}
        />
      </div>
    );
  };

  _addRow = () => {
    const { marketSuspensionQueryParams, mktSuspensions } = this.state;
    const newRow = {
      tradeDate: marketSuspensionQueryParams.date
    };
    const updatedList = [newRow, ...mktSuspensions];
    this.setState({ mktSuspensions: updatedList });
  };

  _onInputChange = ({ name, value }) => {
    const { params } = this.state;
    const updateParams = {
      ...params,
      [name]: value
    };
    this.setState(
      {
        params: updateParams
      },
      this._filterData
    );
  };

  _createOP = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        <Input
          placeholder="input ticker"
          style={{ marginRight: '5px', width: '180px' }}
          onChange={event => {
            this._onInputChange({
              name: 'searchTicker',
              value: event.target.value
            });
          }}
        />
        <Input
          placeholder="input symbol"
          style={{ marginRight: '5px', width: '180px' }}
          onChange={event => {
            this._onInputChange({
              name: 'searchSymbol',
              value: event.target.value
            });
          }}
        />
        <Button type="primary" onClick={this._loadTxnLimits}>
          Reload
        </Button>
      </div>
    );
  };

  closeDialog = () => {
    this._resetParams();
    this.props.closeDialog(DIALOG_TXN_LIMITS);
  };

  _resetParams = () => {
    this.setState({
      isInitialized: false,
      filterTxnLimits: [],
      errors: [],
      txnLimits: [],
      params: {
        searchTicker: null,
        searchSymbol: null
      }
    });
  };

  _createSubmitBtn = handleSubmit => {
    const { submitStatus } = this.state;
    return {
      SUBMITTING: (
        <Button key="submit" type="primary" disabled loading>
          Submitting
        </Button>
      ),
      ERROR: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Fail - Retry?
        </Button>
      ),
      READY: (
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      )
    }[submitStatus];
  };

  _onTabChange = tab => {
    this.setState({ activeTab: tab });
  };

  _onSubmit = () => {
    this._submitTxnLimit();
    this._submitMktSuspend();
  };

  _submitMktSuspend = () => {
    if (this.state.activeTab === this.state.MktSuspension) {
      const { mktSuspensions, mktSuspensionsBack } = this.state;
      const data = mktSuspensions.filter(r => _.isNil(r.id));
      const dataIdSet = mktSuspensions.map(r => r.id);
      const removeData = mktSuspensionsBack.filter(
        r => !dataIdSet.includes(r.id)
      );
      const errMsg = this._validateMktSuspendRow(null, 'edit');
      if (errMsg.length === 0) {
        client
          .submitMktSuspension({ addData: data, removeData })
          .then(resp => {
            this.setState({
              submitStatus: 'READY'
            });
            this.closeDialog();
          })
          .catch(err => {
            console.log(err);
            this.setState({
              submitStatus: 'ERROR'
            });
          });
      }
    }
  };

  _validateMktSuspendRow = (d, action) => {
    if (action !== 'edit') return;
    const { mktSuspensions } = this.state;
    const data = mktSuspensions.filter(r => _.isNil(r.id));
    let errors = [];
    for (let i = 0; i < data.length; ++i) {
      const d = data[i];
      const tradeDate = d.tradeDate;
      const mkt = d.market;
      const SGT = 'SZSE_SEHK_CONN',
        HGT = 'SSE_SEHK_CONN';
      if (mkt !== SGT && mkt !== HGT) {
        errors.push('Market should be SZSE_SEHK_CONN or SSE_SEHK_CONN');
      }
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(tradeDate)) {
        errors.push('TradeDate must be 2023-04-10 format');
      }
    }
    this.setState({ errors });
    return errors;
  };

  _submitTxnLimit = () => {
    if (this.state.activeTab === this.state.TxnLimit && this._validate()) {
      const { filterTxnLimits } = this.state;
      const data = filterTxnLimits.filter(r => _.isNil(r.id));
      this.setState({
        submitStatus: 'SUBMITTING'
      });
      if (_.isEmpty(data)) return;
      const submitData = data.map(r => ({
        ...r,
        type: r.type.toUpperCase()
      }));
      client
        .addTxnLimits(submitData)
        .then(resp => {
          this.setState({
            submitStatus: 'READY'
          });
          this.closeDialog();
        })
        .catch(err => {
          console.log(err);
          this.setState({
            submitStatus: 'ERROR'
          });
        });
    }
  };

  _onDateChange = ({ name, value }) => {
    const { marketSuspensionQueryParams } = this.state;
    this.setState(
      {
        marketSuspensionQueryParams: {
          ...marketSuspensionQueryParams,
          [name]: value
        }
      },
      this._loadMktSuspension
    );
  };

  _createMarketOp = () => {
    const { date } = this.state.marketSuspensionQueryParams;
    return (
      <div style={{ textAlign: 'right' }}>
        <DatePicker
          value={moment(date, 'YYYY-MM-DD')}
          format={'YYYY-MM-DD'}
          allowClear={false}
          style={{ width: '200px', marginLeft: '15px' }}
          onChange={(date, dateString) =>
            this._onDateChange({
              name: 'date',
              value: dateString
            })
          }
        />

        <Button type="primary" onClick={this._loadMktSuspension}>
          Load
        </Button>
      </div>
    );
  };

  render() {
    const { isInitialized, errors } = this.state;
    return (
      <Modal
        width={1300}
        maskClosable={false}
        title="Txn Limit"
        visible={true}
        onOk={this.closeDialog}
        onCancel={this.closeDialog}
        footer={[
          this._createSubmitBtn(this._onSubmit),
          <Button key="close" type="primary" onClick={this.closeDialog}>
            Close
          </Button>
        ]}
      >
        <style>
          {`
            .ant-tabs-nav .ant-tabs-tab {
              color: black;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          
            .ant-tabs-nav .ant-tabs-tab-active {
              color: #1890ff;
              font-weight: 700;
              font-style: italic;
              padding: 2px 10px;
            }
          `}
        </style>
        <Tabs tabPosition="top" key="TxnLimitMain" onChange={this._onTabChange}>
          <Tabs.TabPane
            tab="Txn Limit"
            key={this.state.TxnLimit}
            label="TxnLimit"
          >
            {this._createOP()}
            <Spin tip="Initializing..." spinning={!isInitialized}>
              {this._createTable()}
            </Spin>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="MktSuspension"
            key={this.state.MktSuspension}
            label="MktSuspension"
          >
            {this._createMarketOp()}
            <Spin tip="Initializing..." spinning={!isInitialized}>
              {this._createTableForMktSuspension()}
            </Spin>
          </Tabs.TabPane>
        </Tabs>
        {!_.isEmpty(errors) && <Message error list={errors} />}
      </Modal>
    );
  }
}

export default TxnLimitsDialog;
