export const companySingleQuarterColumns = [
  {
    field: 'index',
    headerName: 'Quarterly results',
    cellClass: params =>
      params.value.startsWith('YoY') || params.value.startsWith('QoQ')
        ? 'companyItemKeyword'
        : 'companyTypeKeyword',
    editable: true,
    // uses the provided Text Cell Editor (which is the default)
    cellEditor: 'agTextCellEditor',
    width: 80,
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'comment',
    headerName: 'Comments, actual vs. estimates',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    width: 110,
    tooltipField: 'comment',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'error',
    headerName: 'Were you wrong?',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    width: 110,
    tooltipField: 'error',
    lockPinned: true,
    pinned: 'left'
  }
];

export const companyBottomLineColumns = [
  {
    field: 'index',
    headerName: 'Bottom Line',
    cellClass: 'keyword',
    editable: true,
    // uses the provided Text Cell Editor (which is the default)
    cellEditor: 'agTextCellEditor',
    width: 80,
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'comment',
    headerName: 'Comments, actual vs. estimates',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    width: 110,
    tooltipField: 'comment',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'error',
    headerName: 'Were you wrong?',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    width: 110,
    tooltipField: 'error',
    lockPinned: true,
    pinned: 'left'
  }
];

export const companyFutureColumns = [
  {
    field: 'index',
    headerName: '未来趋势',
    cellClass: 'keyword',
    editable: true,
    // uses the provided Text Cell Editor (which is the default)
    cellEditor: 'agTextCellEditor',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'point',
    headerName: '公司/专家最新观点',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'point',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'comment',
    headerName: '符合3.5句话吗？',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'comment',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'error',
    headerName: 'Were you wrong?',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'error',
    lockPinned: true,
    pinned: 'left'
  }
];

export const marketFeedbackColumns = [
  {
    field: 'index',
    headerName: '市场的反馈',
    cellClass: 'non-number',
    editable: true,
    // uses the provided Text Cell Editor (which is the default)
    cellEditor: 'agTextCellEditor',
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'researchDepth',
    headerName: '市场研究的深度',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'researchDepth',
    lockPinned: true,
    pinned: 'left'
    // cellEditorPopup: true,
    // cellEditorParams: {
    //   values: ['偏空','偏多', '博弈'],
    //   cellEditorPopup: true,
    // }
  },
  {
    field: 'error',
    headerName: 'Were you wrong?',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'error',
    lockPinned: true,
    pinned: 'left'
  }
];

export const investmentConclusionColumns = [
  {
    field: 'index',
    headerName: '投资结论',
    cellClass: 'non-number',
    editable: true,
    // uses the provided Text Cell Editor (which is the default)
    cellEditor: 'agTextCellEditor'
  },
  {
    field: 'beforeResult',
    headerName: '原有结论',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'beforeResult'
  },
  {
    field: 'currentResult',
    headerName: '现有结论',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'currentResult'
  },
  {
    field: 'positionChange',
    headerName: '仓位变化',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor'
  },
  {
    field: 'catalyst',
    headerName: '催化剂',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'catalyst'
  },
  {
    field: 'errors',
    headerName: 'Were you wrong?',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'errors'
  }
];

export const followUpColumns = [
  {
    field: 'index',
    headerName: '后续关注',
    cellClass: 'non-number',
    editable: true,
    // uses the provided Text Cell Editor (which is the default)
    cellEditor: 'agTextCellEditor'
  },
  {
    field: 'result',
    headerName: '公司指引/市场一致预期/订单能见度？',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'result'
  }
];

export const industryColumns = [
  {
    field: 'ticker',
    headerName: 'Tikcer',
    cellClass: 'keyword',
    width: 80,
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 100,
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'change',
    headerName: '强弱变化',
    cellClass: 'non-number',
    editable: true,
    cellEditor: 'agTextCellEditor',
    tooltipField: 'change',
    width: 100,
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'error',
    headerName: 'Were you wrong?',
    cellClass: 'non-number',
    editable: true,
    tooltipField: 'error',
    cellEditor: 'agTextCellEditor',
    width: 100,
    lockPinned: true,
    pinned: 'left'
  }
];

export const companyScoreAttColumns = [
  {
    field: 'yearQuarter',
    headerName: 'Tikcer',
    cellClass: 'keyword',
    width: 20,
    lockPinned: true,
    pinned: 'left'
  },
  {
    field: 'attachs',
    headerName: 'Attach',
    width: 100,
    cellRenderer: 'attachCellRender'
  }
];
